export enum ContainerColor {
  DarkBeige = 'RAL1001 Dark Beige',
  LightBeige = 'RAL1015 Light Beige',
  OxideRed = 'RAL3009 Oxide Red',
  GentianBlue = 'RAL5010 Gentian Blue',
  SlateGrey = 'RAL7015 Slate Grey',
  LightGrey = 'RAL7035 Light Grey',
  WindowGrey = 'RAL7040 Window Grey',
  TrafficGrey = 'RAL7042 Traffic Grey',
  SignalWhite = 'RAL9003 Singnal White',
  White = 'RAL9010 White',
}
