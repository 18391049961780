import { FC } from 'react'
import { Col, Row } from 'antd'

import { paymentTermsOptions, purchaseOrderLabels } from 'constant'
import { PurchaseOrderFormAccessor } from 'types'

import { FormItem, SelectSingle } from 'designSystem'

import { DateFields } from '../DateFields/DateFields'

export const StandardFields: FC = () => {
  return (
    <Row gutter={8}>
      <Col span={12}>
        <DateFields />
      </Col>
      <Col span={12}>
        <FormItem name={PurchaseOrderFormAccessor.PaymentTerms} label={purchaseOrderLabels.paymentTerms}>
          <SelectSingle options={paymentTermsOptions} placeholder="Select" />
        </FormItem>
      </Col>
    </Row>
  )
}
