import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { Contact, GetCustomerContactsResponse } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { deleteCustomerContact } from '../queryApi'

export const useDeleteCustomerContact = (id: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation({
    mutationFn: deleteCustomerContact,
    onSuccess: (data, { contactId }) => {
      dispatchAppEvent(AppEvent.CustomerContactDelete)

      queryClient.setQueryData(
        [QueryKey.GetCustomerContacts, id],
        (old: GetCustomerContactsResponse | undefined) =>
          ({
            ...old,
            data: old?.data.filter((contact: Contact) => contact.id !== contactId),
          }) as GetCustomerContactsResponse,
      )

      showNotification({ title: 'Contact deleted', type: 'success' })
    },
  })

  const deleteContact = useCallback(
    (contactId: number) => {
      mutate({ customerId: id, contactId: contactId })
    },
    [mutate, id],
  )

  return {
    deleteContact,
    ...rest,
  }
}
