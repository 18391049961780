import { FC } from 'react'
import type { LinkProps } from 'react-router-dom'

import * as Styled from './styles'

export type ReactLinkProps = LinkProps

export const ReactLink: FC<ReactLinkProps> = ({ target = '_blank', ...props }) => (
  <Styled.ReactLink target={target} {...props} />
)
