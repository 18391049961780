import * as Yup from 'yup'

import { FlatFee, PaymentTerms, TypeOfEquipment, VendorCarrierSettingsType, VendorType } from 'api'
import {
  addressValidation,
  contactValidationSchema,
  emailValidation,
  numberBasicValidation,
  phoneNumberValidation,
  resaleMarginValidation,
  scrappingSettingsValidation,
  uniqueEmailValidation,
  urlValidation,
} from 'utils'

export const vendorContactsSchema = {
  contacts: Yup.array(contactValidationSchema.required())
    .min(1, 'At least one contact is required')
    .max(20, 'Max length of 20 contacts')
    .required(),
}

export const vendorSchema = {
  ...addressValidation,
  isFavorite: Yup.boolean().default(false),
  companyName: Yup.string().max(100, 'Max length of 100 characters').required('Company name is required'),
  websiteUrl: urlValidation.nullable().notRequired(),
  type: Yup.array().default([] as VendorType[]),
  paymentTerms: Yup.mixed<PaymentTerms>().oneOf(Object.values(PaymentTerms)).required('Payment terms is required'),
}

export const vendorModificationsSettingsSchema = {
  scrappingSettings: scrappingSettingsValidation,
  resaleMargin: resaleMarginValidation,
}

export const vendorContainerSettingsSchema = {
  typeOfEquipment: Yup.array(Yup.mixed<TypeOfEquipment>()).default([]),
  scrappingSettings: scrappingSettingsValidation,
  resaleMargin: resaleMarginValidation,
}

export const vendorPartsSettingsSchema = {
  scrappingSettings: scrappingSettingsValidation,
  resaleMargin: resaleMarginValidation,
}

export const vendorDepotSettingsSchema = {
  locationCodeId: numberBasicValidation.required('Location is required'),
  code: Yup.string().max(100, 'Max length of 100 characters').required('Code is required'),
  accountNumber: Yup.string().max(50, 'Max length of 50 characters').nullable(),
  gateInFee: numberBasicValidation.nullable().positive('Gate in fee should be positive number'),
  gateOutFee: numberBasicValidation.nullable().positive('Gate out fee should be positive number'),
  liftFee: numberBasicValidation.nullable().positive('Lift fee should be positive number'),
  flatFee: Yup.mixed<FlatFee>().oneOf(Object.values(FlatFee)).nullable(),
  tiltBetPerMile: numberBasicValidation.nullable().positive('Tilt bet per mile should be positive number'),
  flatBetPerMile: numberBasicValidation.nullable().positive('Flat bet per mile should be positive number'),
  minimumDeliveryFee: numberBasicValidation.nullable().positive('Minimum delivery fee should be positive number'),
  dailyRatePerTEU: numberBasicValidation.nullable().positive('Daily rate per TEU should be positive number'),
  dailyRatePerX2TEU: numberBasicValidation.nullable().positive('Daily rate per X2 TEU should be positive number'),
  laborRatePerHour: numberBasicValidation.nullable().positive('Labor rate per hour should be positive number'),
  bookingEmails: Yup.array().of(uniqueEmailValidation).default([]),
  redeliveryEmails: Yup.array().of(uniqueEmailValidation).default([]),
  primaryEmails: Yup.array().of(uniqueEmailValidation).default([]),
  secondaryEmails: Yup.array().of(uniqueEmailValidation).default([]),
  acceptTransload: Yup.boolean().default(false),
  availableInCalculator: Yup.boolean().default(false),
  phoneNumber: phoneNumberValidation.nullable(),
  latitude: numberBasicValidation
    .min(-90, 'Latitude should be in [-90, 90] range')
    .max(90, 'Latitude should be in [-90, 90] range')
    .required('Latitude is required'),
  longitude: numberBasicValidation
    .min(-180, 'Longitude should be in [-180, 180] range')
    .max(180, 'Longitude should be in [-180, 180] range')
    .required('Longitude is required'),
  ...addressValidation,
}

export const vendorEquipmentSchema = Yup.object({
  title: Yup.string().max(100, 'Max length of 100 characters').required('Title is required'),
  email: emailValidation.nullable().notRequired(),
  phoneNumber: phoneNumberValidation.nullable().notRequired(),
  truckCodeId: numberBasicValidation.required('Truck code is required'),
  qty: numberBasicValidation.nullable().positive('Qty should be positive number').default(null),
})

export const vendorCarrierSettingsSchema = {
  type: Yup.array(Yup.mixed<VendorCarrierSettingsType>()).default([]),
  locations: Yup.array(
    Yup.object({
      locationCodeId: numberBasicValidation.required('Location is required'),
      boundary: numberBasicValidation
        .min(0, 'Min boundary should be more than 0 miles')
        .max(5000, 'Max boundary is 5000 miles')
        .required('Boundary is required'),
    }).required('At least one Location is required'),
  ),
  equipment: Yup.array(vendorEquipmentSchema),
}
