export enum VendorCarrierSettingsType {
  TiltBet = 'tilt_bet',
  TiltPickupFromPort = 'tilt_pickup_from_port',
  ContainerStorage = 'container_storage',
  CanPickup2_20 = 'can_pickup_2_20',
  DoRepairs = 'do_repairs',
  DeliveryOutOfState = 'delivery_out_of_state',

  FlatBet = 'flat_bet',
  FlatPickupFromPort = 'flat_pickup_from_port',
  PickupFromRailRoads = 'pickup_from_rail_roads',
}
