import { Address, api, EntityId } from 'api'

interface PostCustomerBillingAddressProps {
  customerId?: EntityId
  data?: Address
}

export const postCustomerBillingAddress = ({ customerId, data }: PostCustomerBillingAddressProps): Promise<Address> => {
  return api({
    method: 'post',
    url: `/api/v1/customers/${customerId}/billing-addresses`,
    data,
  })
}

interface PatchCustomerBillingAddressProps {
  customerId?: EntityId
  id?: EntityId
  data?: Address
}

export const patchCustomerBillingAddress = ({
  customerId,
  id,
  data,
}: PatchCustomerBillingAddressProps): Promise<Address> => {
  return api({
    method: 'patch',
    url: `/api/v1/customers/${customerId}/billing-addresses/${id}`,
    data,
  })
}

interface DeleteCustomerBillingAddressProps {
  customerId?: EntityId
  id?: EntityId
}

export const deleteCustomerBillingAddress = ({ customerId, id }: DeleteCustomerBillingAddressProps): Promise<void> => {
  return api({
    method: 'delete',
    url: `/api/v1/customers/${customerId}/billing-addresses/${id}`,
  })
}
