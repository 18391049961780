import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { EntityId } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'
import { PickupNumberFormPayload } from 'modules/references/types'

import { patchPickupNumber } from '../queryApi'

export const useUpdatePickupNumber = (id: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation({
    mutationFn: patchPickupNumber,
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.ReferenceNumberUpdate)
      queryClient.setQueryData([QueryKey.GetReferenceNumberById, id], data)
      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const update = useCallback(
    (payload: Partial<Omit<PickupNumberFormPayload, 'items'>>) => {
      mutate({ id, data: payload })
    },
    [mutate, id],
  )

  return {
    update,
    ...rest,
  }
}
