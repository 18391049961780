import isEqual from 'react-fast-compare'

import { VendorInventory } from 'api'

import { InventoryFormPayload } from '../types/inventoryCreateForm'

export const getPatchPayload = (payload: InventoryFormPayload, defaultData: VendorInventory[]) => {
  return payload.list?.map((inventory) => {
    const { id, title, price, resaleMargin, qty, sku, locationCodeId } = inventory
    const prev = defaultData.find((item) => item.id === id)

    const payload = {
      ...(prev?.title !== title && { title }),
      ...(prev?.price !== price && { price }),
      ...(prev?.qty !== qty && { qty }),
      ...(prev?.sku !== sku && { sku }),
      ...(prev?.locationCodeId !== locationCodeId && { locationCodeId }),
      ...(!isEqual(prev?.resaleMargin, resaleMargin) && { resaleMargin }),
    }

    return {
      id,
      payload,
    }
  })
}
