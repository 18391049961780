import { FC } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'

import { Container } from 'designSystem'

import { routes } from './constants'

export const Items: FC = () => {
  return (
    <>
      <Outlet />
      <Container fd="column">
        <Routes>
          {routes.map((settingRoute) => (
            <Route key={settingRoute.path} path={settingRoute.path} element={settingRoute.element} />
          ))}
        </Routes>
      </Container>
    </>
  )
}
