import { CSSProperties } from 'react'
import { styled } from 'styled-components'

import type { TooltipTagProps } from './TooltipTag'

interface WrapperProps extends Pick<TooltipTagProps, 'onClick'> {
  borderColor?: CSSProperties['borderColor']
  backgroundColor?: CSSProperties['backgroundColor']
}

export const Dot = styled.div<WrapperProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  padding: 2px;
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: ${({ onClick }) => !!onClick && 'pointer'};
  &:hover {
    border-color: ${({ borderColor }) => borderColor};
  }
`
