export const isValueUniqueInArray = (currentValue: string | undefined, array: string[], onlyOneValue?: boolean) => {
  let count = 0
  array.forEach((item) => {
    if (item === currentValue) count++
  })
  if (onlyOneValue) {
    return count === 0
  } else {
    return count <= 1
  }
}
