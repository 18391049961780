import { ItemCategory } from 'api'

export const removeCategory = (currentCategories: ItemCategory[], id: number): ItemCategory[] => {
  return currentCategories?.filter((item) => {
    if (item.id === id) return false
    else {
      if (item.children?.length) {
        item.children = item.children.filter((child) => child.id !== id)
        return true
      } else {
        return true
      }
    }
  })
}
