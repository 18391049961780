import type { ApiQueryParams, AssetRepair, EntityId } from 'api'
import { api } from 'api'
import { Pagination } from 'types'

import { RepairsPayload } from './types/repairsForm'

export interface GetRepairsResponse extends Pagination {
  data?: AssetRepair[]
}

export type GetRepairsQueryParams = ApiQueryParams & {
  assetId: EntityId
}

export const getRepairs = ({ assetId, ...params }: GetRepairsQueryParams): Promise<GetRepairsResponse> => {
  return api({
    method: 'get',
    url: `/api/v1/assets/${assetId}/repairs`,
    params,
  })
}

export const postRepairs = ({ assetId, data }: { assetId?: EntityId; data?: RepairsPayload }): Promise<AssetRepair> => {
  return api({
    method: 'post',
    url: `/api/v1/assets/${assetId}/repairs`,
    data,
  })
}

export const patchRepairs = ({
  assetId,
  id,
  data,
}: {
  assetId?: EntityId
  id?: EntityId
  data?: RepairsPayload
}): Promise<AssetRepair> => {
  return api({
    method: 'patch',
    url: `/api/v1/assets/${assetId}/repairs/${id}`,
    data,
  })
}

export const deleteRepairs = ({ assetId, id }: { assetId?: EntityId; id?: EntityId }): Promise<void> => {
  return api({
    method: 'delete',
    url: `/api/v1/assets/${assetId}/repairs/${id}`,
  })
}
