import { FC, useCallback } from 'react'

import { useForm } from 'hooks'
import { addressValidationSchema } from 'utils'

import { Button, Container, Modal, ModalProps } from 'designSystem'

import { NewAddress } from './types/newAddress'

import { FormItemAddress } from '../../FormElements'

interface AddAddressModalProps extends Omit<ModalProps, 'onOk' | 'onCancel'> {
  onSubmit: (values: NewAddress) => void
  onCancel?: () => void
}

export const AddAddressModal: FC<AddAddressModalProps> = ({
  width = 440,
  title = 'Add Address',
  open,
  footer = null,
  onSubmit,
  onCancel,
  ...modalProps
}) => {
  const { Form, handleSubmit, reset } = useForm<NewAddress>({
    validationSchema: addressValidationSchema,
    defaultValues: {
      country: undefined,
      city: undefined,
      state: undefined,
      street: undefined,
      zipCode: undefined,
    },
  })

  const onClickSubmit = useCallback(() => {
    handleSubmit(onSubmit)()
  }, [handleSubmit, onSubmit])

  return (
    <Modal
      {...modalProps}
      afterClose={reset}
      open={open}
      width={width}
      title={title}
      footer={footer}
      onCancel={onCancel}
    >
      <Form>
        <FormItemAddress bordered={false} pa={0} title={<></>} />
        <Container display="flex" jc="space-between" gap={8} mt={6}>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" fullwidth onClick={onClickSubmit}>
            Confirm
          </Button>
        </Container>
      </Form>
    </Modal>
  )
}
