import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { getTruckCodes, GetTruckCodesQueryParams } from 'api'
import { QueryKey } from 'enums'

export const useGetTruckCodes = (params: GetTruckCodesQueryParams) => {
  const { data, ...rest } = useQuery({
    queryKey: [QueryKey.GetTruckCodes, params],
    queryFn: () => getTruckCodes(params),
    placeholderData: keepPreviousData,
  })

  const { total, data: locationCodes } = data || {}

  return {
    data: locationCodes,
    total,
    ...rest,
  }
}
