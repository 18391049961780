import { styled } from 'styled-components'

import { Container } from 'designSystem'

export const FieldsWrapper = styled(Container)`
  height: 100%;
`

export const CreatedDateWrapper = styled.div`
  margin-top: auto;
`
