export enum Accessor {
  Index = 'index',
  AssetNumber = 'assetNumber',
  Status = 'status',
  LocationCode = 'locationCode',
  AssetHolder = 'assetHolder',
  AssetNumberType = 'assetNumberType',
  BirthmarkNumber = 'birthmarkNumber',
  BirthmarkSize = 'birthmarkSize',
  Condition = 'condition',
  ItemSku = 'items',
  PurchaseOrder = 'purchaseOrder',
  VendorReleaseReferenceNumber = 'vendorReleaseReferenceNumber',
  RedeliveryReferenceNumber = 'redeliveryReferenceNumber',
}
