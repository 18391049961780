import { getAccessorTitle } from '../utils/getAccessorTitle'
import { Accessor } from '../types/accessor'

export const modificationsNotSwitchableColumns = [Accessor.Actions]
export const modificationsRequiredColumns = [Accessor.Index, Accessor.Sku, Accessor.Name]

export const modificationsDefaultColumns = [
  Accessor.Index,
  Accessor.Sku,
  Accessor.Name,
  Accessor.Price,
  Accessor.ResaleMargin,
  Accessor.TotalCost,
]

export const modificationsVisibleColumnsOptions = Object.values(Accessor)
  .filter((accessor) => !modificationsNotSwitchableColumns.includes(accessor))
  .map((accessor) => ({
    value: accessor,
    label: getAccessorTitle(accessor),
    disabled: modificationsRequiredColumns.includes(accessor),
  }))
