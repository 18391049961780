import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Empty } from 'antd'

import { UserSettingsService } from 'services'
import { TableFilterRecordValue, useMemoCompare, useTableContext } from 'hooks'
import { AppModule } from 'types'

import { Button, Container, Spacer, Typography } from 'designSystem'
import { HeaderHeight } from 'components/Layout/AuthorizedLayout/elements/Header/styles'
import { TableFilterOption } from 'components/Table/types/tableFilterOption'

import * as Styled from './styles'

import { TableFavorite } from '../TableFavorite'
import { TableFilterCreatedByMe } from '../TableFilterCreatedByMe'
import { TableFilterOptions } from '../TableFilterOptions'

const drawerSettings = {
  maskStyle: {
    backgroundColor: 'transparent',
  },
  contentWrapperStyle: {
    top: HeaderHeight,
    boxShadow: '0px 5px 23px 0px rgba(0, 0, 0, 0.20)',
  },
}
type FilterOptionType = {
  value: string
  label?: string
  component?: JSX.Element
}

interface TableFilterDrawerProps {
  options: FilterOptionType[]
  setFilterOptions: (values: TableFilterOption['value'][]) => void
  filterOptions: TableFilterOption['value'][]
  favoriteFieldName?: string
  module: AppModule
  showCreatedByMe?: boolean
}

export const TableFilterDrawer: FC<TableFilterDrawerProps> = ({
  setFilterOptions,
  filterOptions,
  options,
  favoriteFieldName,
  module,
  showCreatedByMe,
}) => {
  const [open, setOpen] = useState(false)
  const [hasChanged, setHasChanged] = useState(false)
  const { clearFilters, getIsFilterApplied, applyFilters, resetDefaultFilters, hasFiltersChanged } = useTableContext()
  const isClearFilterButtonEnabled = getIsFilterApplied?.()
  const isFiltersChanged = hasFiltersChanged?.() || false

  useEffect(() => {
    setHasChanged(isFiltersChanged)
  }, [isFiltersChanged, hasFiltersChanged])

  const filters = UserSettingsService.getModuleTableSettings(module)?.filters

  const showDrawer = useCallback(() => {
    setOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setOpen(false)
    resetDefaultFilters?.()
  }, [resetDefaultFilters])

  const memoFilter = useMemoCompare(filters)

  const countActiveFilters = useMemo(() => {
    return Object.values(memoFilter || {}).reduce((acc, curr) => {
      if (Array.isArray(curr?.value) && curr?.value?.length) {
        curr.value.some((value) => value) && acc++
      } else if (
        curr.value &&
        Object.prototype.hasOwnProperty.call(curr.value, 'value') &&
        (curr.value as TableFilterRecordValue)?.value
      ) {
        acc++
      }
      return acc
    }, 0)
  }, [memoFilter])

  const filterComponents = filterOptions
    ?.map((filterValue) => options?.find(({ value }) => value === filterValue))
    .filter(Boolean)

  const handleApplyFilters = useCallback(() => {
    applyFilters?.()
    setHasChanged(false)
  }, [applyFilters])

  return (
    <>
      <Styled.Button icon="sliders" size="small" onClick={showDrawer} px={4}>
        Filters
        {!!countActiveFilters && <Styled.Counter>{countActiveFilters}</Styled.Counter>}
      </Styled.Button>
      <Styled.Drawer {...drawerSettings} width={364} onClose={onClose} open={open}>
        <Container ai="center" jc="space-between" mb={5}>
          <Typography fontWeight="xl" size="m">
            Filters
          </Typography>
          <TableFilterOptions
            title="Filter"
            icon="sliders"
            tooltipText="Select Filters"
            showTooltip={!filterComponents.length && open}
            options={options}
            value={filterOptions}
            onChange={setFilterOptions}
          />
        </Container>
        {filterComponents.length ? (
          <>
            <Container gap={16} ai="flex-start" fw="wrap" fd="column">
              {filterComponents.map((filter) => {
                if (!filter) return null
                const { value, label, component: Component } = filter
                return (
                  <Container fd="column" width="100%" key={value}>
                    <Typography fontWeight="l" size="xs">
                      {label}
                    </Typography>
                    <Spacer mb={1} />
                    {Component}
                  </Container>
                )
              })}
              {favoriteFieldName && <TableFavorite name={favoriteFieldName} />}
              {showCreatedByMe && <TableFilterCreatedByMe />}
            </Container>
            <Container fd="column" gap={8} mt={7}>
              <Button type="primary" fullwidth onClick={handleApplyFilters} disabled={!hasChanged}>
                Apply Filters
              </Button>
              <Button fullwidth onClick={clearFilters} disabled={!isClearFilterButtonEnabled}>
                Clear Filters
              </Button>
            </Container>
          </>
        ) : (
          <>
            {hasChanged ? (
              <Container mt={7}>
                <Button type="primary" fullwidth onClick={handleApplyFilters}>
                  Apply Filters
                </Button>
              </Container>
            ) : (
              <Styled.Container ai="center" jc="center">
                <Empty description={<Typography color="secondary">You didn't select any filters</Typography>} />
              </Styled.Container>
            )}
          </>
        )}
      </Styled.Drawer>
    </>
  )
}
