import { Col, Row } from 'antd'

import { MarginSettingMeasure, MarginSettingOperator, VendorInventory } from 'api'
import { useLocationCodes } from 'hooks'
import { currency, marginSettingsMeasureOptions, marginSettingsOperatorOptions } from 'constant'
import { getFormattedPriceWithCurrency } from 'utils'

import { EditFormItem, FormItem, Input, InputNumber, SelectSingle, Typography } from 'designSystem'
import {
  EditableTableActions,
  EditableTableIndex,
  FormItemEditInput,
  FormItemEditInputNumber,
  FormItemEditMarginSettings,
  FormItemEditSelectSingleAsync,
  FormItemSelectSingleAsync,
  getEditableColumnFormItemName,
  isEditableTableAddNewIndex,
  TableColumnKey,
  TableColumnsType,
} from 'components'
import { getTotalCost } from 'modules/vendors/routes/Vendor/utils/getTotalCost'

import { Accessor } from '../types/accessor'

import { getAccessorTitle } from './getAccessorTitle'

interface InventoryTableColumnsProps {
  offsetCount: number
  isAddNew: boolean
  onCancelNew: () => void
  onDelete: (id?: number) => void
  triggerSubmit: () => void
  addNewValues: Partial<VendorInventory> | undefined
}

export const getTableColumns = ({
  offsetCount,
  isAddNew,
  onCancelNew,
  onDelete,
  triggerSubmit,
  addNewValues,
}: InventoryTableColumnsProps): TableColumnsType<Partial<VendorInventory>> => [
  {
    title: getAccessorTitle(Accessor.Index),
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => {
      return <EditableTableIndex isAddNew={isAddNew} index={index} offsetCount={offsetCount} />
    },
  },
  {
    title: getAccessorTitle(Accessor.Title),
    key: Accessor.Title,
    dataIndex: Accessor.Title,
    width: 150,
    ellipsis: true,
    render: (value, record, index) =>
      isEditableTableAddNewIndex(index, isAddNew) ? (
        <FormItem name="item.title">
          <Input placeholder="Title" />
        </FormItem>
      ) : (
        <EditFormItem
          name={getEditableColumnFormItemName('list', Accessor.Title, index, isAddNew)}
          render={({ field }) => (
            <FormItemEditInput size="small" align="left" placeholder="Title" {...field} onBlur={triggerSubmit} />
          )}
        />
      ),
  },
  {
    title: getAccessorTitle(Accessor.LocationCodeId),
    key: Accessor.LocationCodeId,
    dataIndex: Accessor.LocationCodeId,
    width: 150,
    ellipsis: true,
    render: (locationCodeId, record, index) =>
      isEditableTableAddNewIndex(index, isAddNew) ? (
        <FormItemSelectSingleAsync name="item.locationCodeId" getItems={useLocationCodes} />
      ) : (
        <FormItemEditSelectSingleAsync
          size="small"
          fullwidth
          name={getEditableColumnFormItemName('list', Accessor.LocationCodeId, index, isAddNew)}
          onSubmit={triggerSubmit}
          defaultOption={{
            value: locationCodeId,
            label: record.locationCode?.code as string,
          }}
          getItems={useLocationCodes}
        />
      ),
  },
  {
    title: getAccessorTitle(Accessor.Sku),
    key: Accessor.Sku,
    dataIndex: Accessor.Sku,
    width: 150,
    ellipsis: true,
    render: (value, record, index) =>
      isEditableTableAddNewIndex(index, isAddNew) ? (
        <FormItem name="item.sku">
          <Input placeholder="SKU" uppercase />
        </FormItem>
      ) : (
        <EditFormItem
          name={getEditableColumnFormItemName('list', Accessor.Sku, index, isAddNew)}
          render={({ field }) => (
            <FormItemEditInput
              size="small"
              align="left"
              placeholder="SKU"
              {...field}
              onBlur={triggerSubmit}
              uppercase
            />
          )}
        />
      ),
  },
  {
    title: getAccessorTitle(Accessor.Qty),
    key: Accessor.Qty,
    dataIndex: Accessor.Qty,
    width: 80,
    ellipsis: true,
    render: (title, record, index) =>
      isEditableTableAddNewIndex(index, isAddNew) ? (
        <FormItem name="item.qty">
          <InputNumber placeholder="Qty" />
        </FormItem>
      ) : (
        <EditFormItem
          name={getEditableColumnFormItemName('list', Accessor.Qty, index, isAddNew)}
          render={({ field }) => (
            <FormItemEditInputNumber size="small" align="left" placeholder="Qty" {...field} onBlur={triggerSubmit} />
          )}
        />
      ),
  },
  {
    title: getAccessorTitle(Accessor.Price),
    key: Accessor.Price,
    dataIndex: Accessor.Price,
    width: 120,
    ellipsis: true,
    render: (title, record, index) =>
      isEditableTableAddNewIndex(index, isAddNew) ? (
        <FormItem name="item.price">
          <InputNumber placeholder="Price" prefix={currency} precision={2} />
        </FormItem>
      ) : (
        <EditFormItem
          name={getEditableColumnFormItemName('list', Accessor.Price, index, isAddNew)}
          render={({ field }) => (
            <FormItemEditInputNumber
              precision={2}
              prefix={currency}
              size="small"
              align="left"
              placeholder="Price"
              {...field}
              onBlur={triggerSubmit}
            />
          )}
        />
      ),
  },

  {
    title: getAccessorTitle(Accessor.ResaleMargin),
    key: Accessor.ResaleMargin,
    dataIndex: Accessor.ResaleMargin,
    width: 230,
    ellipsis: true,
    render: (value, record, index) =>
      isEditableTableAddNewIndex(index, isAddNew) ? (
        <Row gutter={8}>
          <Col span={6}>
            <FormItem name="item.resaleMargin.operator" defaultValue={MarginSettingOperator.Plus}>
              <SelectSingle placeholder="Select" options={marginSettingsOperatorOptions} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem name="item.resaleMargin.operand">
              <InputNumber placeholder="00.00" precision={2} />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem name="item.resaleMargin.measure" defaultValue={MarginSettingMeasure.Amount}>
              <SelectSingle placeholder="Select" options={marginSettingsMeasureOptions} />
            </FormItem>
          </Col>
        </Row>
      ) : (
        <FormItemEditMarginSettings
          size="small"
          popupContainer={document.body}
          name={getEditableColumnFormItemName('list', Accessor.ResaleMargin, index, isAddNew)}
          label=""
          onConfirm={triggerSubmit}
        />
      ),
  },
  {
    title: getAccessorTitle(Accessor.TotalCost),
    key: Accessor.TotalCost,
    dataIndex: Accessor.TotalCost,
    width: 100,
    ellipsis: true,
    render: (value, record, index) => {
      const currentData = index === 0 && isAddNew ? addNewValues : record
      const totalPrice = getTotalCost<VendorInventory>(currentData)
      return (
        <Typography fontWeight="xl" size="xs">
          {getFormattedPriceWithCurrency(totalPrice)}
        </Typography>
      )
    },
  },
  {
    title: getAccessorTitle(Accessor.Actions),
    key: TableColumnKey.Actions,
    width: 100,
    render: (value, record, index) => (
      <EditableTableActions
        isAddNew={isAddNew}
        index={index}
        onCancelNew={onCancelNew}
        onDelete={() => onDelete(record.id)}
      />
    ),
  },
]
