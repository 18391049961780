import { FC, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { useForm } from 'hooks'
import { route } from 'constant'
import { getRolesOptions } from 'utils'
import { useRoles } from 'store'

import { Button, Container, Divider, FormItem, Input, SelectMulti, Spacer, Typography } from 'designSystem'
import { Breadcrumbs, Card } from 'components'

import { useCreateUser } from './hooks/useCreateUser'
import { validationSchema } from './utils/validationSchema'
import { breadcrumbsData } from './constants/breadcrumbsData'
import { CreateUserForm } from './types/createUserForm'

import { CreateUserPayload } from './queryApi'

export const CreateUser: FC = () => {
  const { Form, handleSubmit } = useForm<CreateUserForm>({
    validationSchema,
  })
  const roles = useRoles()
  const { createUser, isPending } = useCreateUser()
  const rolesOptions = getRolesOptions(roles)

  const onSubmit = useCallback(
    (createUserData: CreateUserPayload) => {
      createUser(createUserData)
    },
    [createUser],
  )

  return (
    <>
      <Breadcrumbs breadcrumbItems={breadcrumbsData} />
      <Container jc="center" ai="center" mt={7} px={7}>
        <Container display="block" maxWidth={500}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Container fd="column">
              <Card mb={7}>
                <Typography fontWeight="xl" size="l">
                  Create User
                </Typography>
                <Spacer mb={7} />
                <Container jc="space-between" fg={1} gap={8} fw="wrap">
                  <Container display="block" fg={1}>
                    <FormItem name="firstName" label="First Name">
                      <Input placeholder="First Name" />
                    </FormItem>
                  </Container>
                  <Container display="block" fg={1}>
                    <FormItem name="lastName" label="Last Name">
                      <Input placeholder="Last Name" />
                    </FormItem>
                  </Container>
                </Container>
                <Divider my={4} />
                <FormItem name="email" label="Email">
                  <Input placeholder="Email" />
                </FormItem>
                <Spacer mb={3} />
                <Container jc="space-between" fg={1} gap={8} fw="wrap">
                  <Container display="block" fg={1}>
                    <FormItem name="phoneNumber" label="Phone Number">
                      <Input placeholder="+" type="phone" />
                    </FormItem>
                  </Container>
                  <Container display="block" fg={1}>
                    <FormItem name="phoneExtension" label="Extension">
                      <Input placeholder="Extension" />
                    </FormItem>
                  </Container>
                </Container>
                <Divider my={4} />
                <Container fd="column" style={{ width: '50%' }}>
                  <FormItem name="rolesIds" label="Role">
                    <SelectMulti options={rolesOptions} placeholder="Role" />
                  </FormItem>
                </Container>
              </Card>
              <Container jc="space-between">
                <Link to={route.userManagement}>
                  <Button type="default">Cancel</Button>
                </Link>
                <Button type="primary" htmlType="submit" loading={isPending}>
                  Create
                </Button>
              </Container>
            </Container>
          </Form>
        </Container>
      </Container>
    </>
  )
}
