export enum AssetCodeType {
  Dry = 'dry',

  Reefer = 'reefer',

  Office = 'office',

  FlatRack = 'flat_rack',

  OpenTop = 'open_top',

  Mini = 'mini',

  Combo = 'combo',
}
