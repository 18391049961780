import { FC, useCallback } from 'react'

import { typedMemo } from 'types'

import { Button, ButtonProps, showNotification } from 'designSystem'

interface CopyToClipboardProps extends ButtonProps {
  copyText: string | undefined | null
}

export const CopyToClipboardBase: FC<CopyToClipboardProps> = ({
  copyText,
  size = 'small',
  icon = 'copy',
  type = 'link',
  ...props
}) => {
  const handleCopyUrlToClipboard = useCallback(() => {
    if (copyText) {
      navigator.clipboard.writeText(copyText)
      showNotification({ type: 'info', title: 'Text copied to clipboard', duration: 1 })
    }
  }, [copyText])
  return (
    <Button size={size} type={type} icon={icon} {...props} onClick={handleCopyUrlToClipboard}>
      Copy
    </Button>
  )
}

export const CopyToClipboard = typedMemo(CopyToClipboardBase)
