import type { PaginationProps } from 'antd'

import { Container, SelectSingle } from 'designSystem'

import * as Styled from './styles'

interface TablePaginationProps extends PaginationProps {
  withoutDefaultPadding?: boolean
}

export const TablePagination = ({
  pageSizeOptions,
  onShowSizeChange,
  showSizeChanger,
  pageSize,
  withoutDefaultPadding,
  ...props
}: TablePaginationProps) => {
  const options =
    pageSizeOptions?.map((pageSize) => ({
      label: `${pageSize} / page`,
      value: `${pageSize}`,
    })) || undefined

  const onSizeChange = (value?: string | number | null) => {
    if (typeof value === 'string' && pageSize) {
      onShowSizeChange?.(pageSize, Number(value))
    }
  }

  return (
    <Container display="flex" jc="space-between" ai="center" py={4} px={withoutDefaultPadding ? 4 : undefined}>
      {showSizeChanger && (
        <Container display="flex" ai="center" gap={8}>
          <SelectSingle
            defaultValue={String(pageSize)}
            options={options}
            onChange={onSizeChange}
            width={100}
            placement="topLeft"
          />
        </Container>
      )}
      <Styled.Pagination {...props} pageSize={pageSize} showSizeChanger={false} />
    </Container>
  )
}
