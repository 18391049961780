import { PurchaseOrderItem } from 'api'
import { useEditableTable, useTable } from 'hooks'
import { AppModule } from 'types'

import { useGetPurchaseOrderItems } from './useGetPurchaseOrderItems'

export const usePurchaseOrderItems = (purchaseOrderId: number) => {
  const { onChange, getFieldSorter, ...props } = useTable<Partial<PurchaseOrderItem>>({
    module: AppModule.PurchaseOrderItems,
  })

  const {
    data = [],
    isLoading,
    isFetching,
  } = useGetPurchaseOrderItems({
    purchaseOrderId,
  })

  const editableTable = useEditableTable<PurchaseOrderItem>({
    data,
  })

  return {
    ...editableTable,
    data,
    getFieldSorter,
    onTableChange: onChange,
    isLoading: isLoading || isFetching,
    ...props,
  }
}
