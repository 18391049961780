import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { ApiHeader } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { hashRoute } from 'constant'
import { AppEvent } from 'types'
import { useSetPurchaseOrderData, useSetPurchaseOrderRequestId } from 'store'

import { showNotification } from 'designSystem'

import { PurchaseOrderPayload } from '../types/purchaseOrderCreateForm'

import { postPurchaseOrder } from '../queryApi'

export const useCreatePurchaseOrder = () => {
  const setPurchaseOrderData = useSetPurchaseOrderData()
  const setPurchaseOrderRequestId = useSetPurchaseOrderRequestId()
  const { dispatchAppEvent } = useDispatchAppEvent()
  const navigate = useNavigate()

  const { mutate, ...rest } = useMutation({
    mutationFn: postPurchaseOrder,
    onSuccess: ({ data, headers }, payload) => {
      setPurchaseOrderRequestId(headers[ApiHeader.RequestId])
      setPurchaseOrderData(data)
      dispatchAppEvent(AppEvent.PurchaseOrderCreated)
      queryClient.setQueryData([QueryKey.GetPurchaseOrderById, data.id], data)
      showNotification({ title: 'PO created', type: 'success' })
      navigate(hashRoute.po(data.id))

      const isNewContactExist = payload?.contacts?.some(({ id }) => !id)
      if (isNewContactExist) {
        queryClient.invalidateQueries({ queryKey: [QueryKey.GetVendorContacts, payload.vendorId] })
      }
    },
  })

  const create = useCallback(
    (data: PurchaseOrderPayload) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { contactsIds, contacts, ...rest } = data
      const contactsWithVendorIds = contacts?.map(({ id, ...rest }) => ({ ...rest, vendorContactId: id }))
      mutate({ ...rest, contacts: contactsWithVendorIds })
    },
    [mutate],
  )

  return {
    create,
    ...rest,
  }
}
