import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { QueryKey } from 'enums'

import { GetEquipmentQueryParams, getResaleRules } from '../queryApi'

export const useGetResaleRules = (params: GetEquipmentQueryParams) => {
  const { data, ...rest } = useQuery({
    queryKey: [QueryKey.GetResaleRules, params],
    queryFn: () => getResaleRules(params),
    placeholderData: keepPreviousData,
    enabled: !!params?.vendorId,
  })

  const { total, data: items } = data || {}

  return {
    data: items,
    total,
    ...rest,
  }
}
