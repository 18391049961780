import { create } from 'zustand'

import { PaymentTerms } from 'api'

import type { SelectSingleOption } from 'designSystem'
import { ReferenceNumberOptions } from 'modules/purchaseOrders/types'

const initialStore = {
  locations: undefined,
  depots: undefined,
  vendorPaymentTerm: undefined,
  referenceNumberOptions: undefined,
  isAddNew: false,
}

export type OptionsById = Record<SelectSingleOption['value'], SelectSingleOption>

export type PurchaseOrderOptionType = 'locations' | 'depots'

export type PurchaseOrderCreateStore = Record<PurchaseOrderOptionType, OptionsById | undefined> & {
  setOptions: (optionType: PurchaseOrderOptionType, data?: SelectSingleOption) => void
  setVendorPaymentTerm: (paymentTerm: PaymentTerms) => void
  setReferenceNumberOptions: (options?: ReferenceNumberOptions) => void
  clearStore: () => void
  vendorPaymentTerm?: PaymentTerms
  referenceNumberOptions?: ReferenceNumberOptions
  isAddNew: boolean
  setIsAddNew: (isAddNew: boolean) => void
}

const usePurchaseOrderStore = create<PurchaseOrderCreateStore>((set) => ({
  ...initialStore,
  setIsAddNew: (isAddNew: boolean) => set({ isAddNew }),
  setReferenceNumberOptions: (options?: ReferenceNumberOptions) =>
    set((state) => ({
      ...state,
      referenceNumberOptions: options,
    })),
  setOptions: (optionType: PurchaseOrderOptionType, data?: SelectSingleOption) =>
    set((prev: PurchaseOrderCreateStore) => {
      if (data?.value) {
        if (prev[optionType]?.[data.value]) {
          return prev
        } else {
          return {
            ...prev,
            [optionType]: {
              ...prev[optionType],
              [data.value]: data,
            },
          }
        }
      }
      return prev
    }),
  setVendorPaymentTerm: (paymentTerm: PaymentTerms) =>
    set((prev: PurchaseOrderCreateStore) => {
      return {
        ...prev,
        vendorPaymentTerm: paymentTerm,
      }
    }),
  clearStore: () => set(initialStore),
}))

export const useGetPurchaseOrderOptions = (optionType: PurchaseOrderOptionType) =>
  usePurchaseOrderStore((state) => state[optionType])

export const useSetPurchaseOrderOptions = () => usePurchaseOrderStore((state) => state.setOptions)

export const useClearPurchaseOrderStore = () => usePurchaseOrderStore((state) => state.clearStore)

export const useGetPurchaseOrderVendorPaymentTerm = () => usePurchaseOrderStore((state) => state.vendorPaymentTerm)

export const useSetPurchaseOrderVendorPaymentTerm = () => usePurchaseOrderStore((state) => state.setVendorPaymentTerm)

export const useGetReferenceNumberOptions = () => usePurchaseOrderStore((state) => state.referenceNumberOptions)

export const useSetReferenceNumberOptions = () => usePurchaseOrderStore((state) => state.setReferenceNumberOptions)

export const useSetIsAddNew = () => usePurchaseOrderStore((state) => state.setIsAddNew)

export const useGetIsAddNew = () => usePurchaseOrderStore((state) => state.isAddNew)
