import { memo } from 'react'
import type { GoogleMapProps as GoogleMapPropsBasic } from '@react-google-maps/api'
import { GoogleMap as GoogleMapBasic, Marker } from '@react-google-maps/api'

import { typedMemo } from 'types'

import { GoogleMapMarker } from './types/googleMapMarker'
import * as Styled from './styles'

interface GoogleMapProps extends GoogleMapPropsBasic {
  markers?: GoogleMapMarker[]
}

export const GoogleMapBase = memo(({ markers, center, zoom = 12, ...props }: GoogleMapProps) => {
  return (
    <Styled.GoogleMapWrapper>
      <GoogleMapBasic center={center} zoom={zoom} {...props}>
        {markers?.map(({ title, ...rest }) => <Marker position={rest} title={title} key={title} />)}
      </GoogleMapBasic>
    </Styled.GoogleMapWrapper>
  )
})

export const GoogleMap = typedMemo(GoogleMapBase)
