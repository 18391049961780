import { route } from 'constant'

export const breadcrumbsData = [
  {
    title: 'Truck Codes',
    url: route.truckCodes,
  },
  {
    title: 'Create Truck Code',
  },
]
