import { Link } from 'react-router-dom'

import { TruckCode } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import type { TableField, TableSortingOrder } from 'hooks'
import { route } from 'constant'
import { getUnitSuffixText } from 'utils'
import theme from 'styles/theme'

import { Container, Icon, Typography } from 'designSystem'
import { TableColumnKey, TableColumnsType } from 'components'

export const getTableColumns = (
  getFieldSorter: (field: TableField) => TableSortingOrder,
  offsetCount: number,
): TableColumnsType<TruckCode> => [
  {
    title: '#',
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => offsetCount + index + 1,
  },
  {
    title: 'Truck Code',
    key: 'code',
    dataIndex: 'code',
    sorter: true,
    ellipsis: true,
    width: '50%',
    sortOrder: getFieldSorter('code'),
    render: (value, record) => {
      const onClick = () => {
        queryClient.setQueryData([QueryKey.GetTruckCodeById, record.id], record)
      }

      return (
        <Link to={`${route.truckCodes}/${record.id}`} onClick={onClick}>
          <Typography color="primary" size="xs" fontWeight="l" lineEllipsis={1}>
            {value}
          </Typography>
        </Link>
      )
    },
  },
  {
    title: 'Title',
    key: 'title',
    dataIndex: 'title',
    width: '12.5%',
    sorter: true,
    sortOrder: getFieldSorter('title'),
    ellipsis: true,
  },
  {
    title: getUnitSuffixText('Bed Length'),
    key: 'bedLengthInFt',
    dataIndex: 'bedLengthInFt',
    sorter: true,
    sortOrder: getFieldSorter('bedLengthInFt'),
    width: '12.5%',
    ellipsis: true,
  },
  {
    title: 'Allow 5ft overhang',
    key: 'allow5ftOverhang',
    dataIndex: 'allow5ftOverhang',
    width: '12.5%',
    ellipsis: true,
    render: (value) => {
      return (
        <Container>
          {value ? (
            <Icon icon="check" color={theme.colors.success[400]} />
          ) : (
            <Icon icon="cross" color={theme.colors.error[400]} />
          )}
        </Container>
      )
    },
  },
  {
    title: 'Truck Code ID',
    key: 'id',
    dataIndex: 'id',
    width: '12.5%',
    sorter: true,
    sortOrder: getFieldSorter('id'),
    ellipsis: true,
  },
]
