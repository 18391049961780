import { FC, ReactNode } from 'react'
import { Tooltip } from 'antd'

import { typedMemo } from 'types'

import { getTooltipTagColors } from './utils/getTooltipTagColors'
import { TagType } from '../../types/tagType'
import * as Styled from './styles'

export interface TooltipTagProps {
  onClick?: () => void
  status: TagType
  text?: string | ReactNode
}

const TooltipTagBase: FC<TooltipTagProps> = ({ onClick, status, text }) => {
  const { color, backgroundColor, borderColor } = getTooltipTagColors(status)
  return (
    <Tooltip
      title={text}
      arrow={false}
      overlayInnerStyle={{
        color,
        backgroundColor,
      }}
    >
      <Styled.Wrapper borderColor={borderColor} onClick={onClick}>
        <Styled.Dot backgroundColor={backgroundColor} />
      </Styled.Wrapper>
    </Tooltip>
  )
}

export const TooltipTag = typedMemo(TooltipTagBase)
