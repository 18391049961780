import type { ApiQueryParams, EntityId, VendorCarrierSettingsEquipment } from 'api'
import { api } from 'api'
import { Pagination } from 'types'

import { EquipmentPayload } from './types/equipmentCreateForm'

export interface GetEquipmentResponse extends Pagination {
  data?: VendorCarrierSettingsEquipment[]
}

export type GetEquipmentQueryParams = ApiQueryParams & {
  vendorId: EntityId
}

export const getEquipment = ({ vendorId, ...params }: GetEquipmentQueryParams): Promise<GetEquipmentResponse> => {
  return api({
    method: 'get',
    url: `/api/v1/vendors/${vendorId}/types/carrier/equipment`,
    params: {
      join: ['truckCode'],
      ...params,
    },
  })
}

export const postEquipment = ({
  vendorId,
  data,
}: {
  vendorId?: EntityId
  data?: EquipmentPayload
}): Promise<VendorCarrierSettingsEquipment> => {
  return api({
    method: 'post',
    url: `/api/v1/vendors/${vendorId}/types/carrier/equipment`,
    data,
    params: {
      join: ['truckCode'],
    },
  })
}

export const patchEquipment = ({
  vendorId,
  equipmentId,
  data,
}: {
  vendorId?: EntityId
  equipmentId?: EntityId
  data?: EquipmentPayload
}): Promise<VendorCarrierSettingsEquipment> => {
  return api({
    method: 'patch',
    url: `/api/v1/vendors/${vendorId}/types/carrier/equipment/${equipmentId}`,
    data,
    params: {
      join: ['truckCode'],
    },
  })
}

export const deleteEquipment = ({
  vendorId,
  equipmentId,
}: {
  vendorId?: EntityId
  equipmentId?: EntityId
}): Promise<void> => {
  return api({
    method: 'delete',
    url: `/api/v1/vendors/${vendorId}/types/carrier/equipment/${equipmentId}`,
  })
}
