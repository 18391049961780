import { FC, useCallback } from 'react'

import { Contact, EntityId } from 'api'
import { useForm, useGetVendorContacts } from 'hooks'

import { Container, Spacer, Typography } from 'designSystem'
import { FormItemEditContacts, Spinner } from 'components'

import { useDeleteVendorContact } from './hooks/useDeleteContact'
import { usePatchVendorContact } from './hooks/usePatchVendorContact'
import { usePostVendorContact } from './hooks/usePostVendorContact'
import { validationSchema } from './constants/validationSchema'
import { maxContactsCount } from 'modules/vendors/constants/maxRecordsCount'
import { VendorContactsForm } from './types/vendorContactsForm'

interface VendorContactsProps {
  id: EntityId
}

export const VendorContacts: FC<VendorContactsProps> = ({ id }) => {
  const { data: contacts, isLoading } = useGetVendorContacts(id)
  const { post, isError: isErrorPost, isPending: isLoadingPost } = usePostVendorContact(id)
  const { patch, isError: isErrorPatch, isPending: isLoadingPatch } = usePatchVendorContact(id)
  const { deleteContact, isError: isErrorDelete } = useDeleteVendorContact(id)

  const { Form, watch } = useForm<VendorContactsForm>({
    validationSchema,
    mode: 'onChange',
    isSubmitError: isErrorPost || isErrorDelete || isErrorPatch,
    defaultValues: { contacts },
  })
  const contactsFormData: Contact[] = watch('contacts') as Contact[]

  const onSubmitPost = useCallback(
    (index: number) => {
      post(contactsFormData?.[index])
    },
    [contactsFormData, post],
  )

  const onSubmitPatch = useCallback(
    (index: number) => {
      patch(contactsFormData?.[index])
    },
    [contactsFormData, patch],
  )

  const onDelete = useCallback(
    (index: number) => {
      deleteContact(contactsFormData?.[index]?.id)
    },
    [contactsFormData, deleteContact],
  )

  return (
    <Form>
      <Typography fontWeight="xl">Business Contacts</Typography>
      <Spacer mb={3} />
      {isLoading && (
        <Container ai="center" jc="center" minHeight={200}>
          <Spinner size="large" />
        </Container>
      )}
      <FormItemEditContacts
        isLoading={isLoadingPatch || isLoadingPost}
        name="contacts"
        onSubmitPost={onSubmitPost}
        onSubmitPatch={onSubmitPatch}
        onDelete={onDelete}
        maxContacts={maxContactsCount}
        addButtonTitle="Add Business Person"
      />
    </Form>
  )
}
