import { FC, useCallback, useState } from 'react'

import { useFormContext, useWatch } from 'hooks'
import { typedMemo } from 'types'
import { getIsFormFieldViewOnly } from 'utils'

import { Checkbox, FormItem, Typography } from 'designSystem'

import { ConfirmationPopover } from '../../Popover'

interface FormItemCheckboxProps {
  name: string
  label: string
  confirmText?: string
  onConfirm?: (value?: boolean) => void
  showPopover?: boolean
  okText?: string
  disabled?: boolean
}

export const FormItemCheckboxBase: FC<FormItemCheckboxProps> = ({
  name,
  label,
  confirmText,
  onConfirm,
  showPopover,
  okText,
  disabled,
}) => {
  const { setValue, viewOnlyFields } = useFormContext()
  const [open, setOpen] = useState(false)
  const value = useWatch({ name })

  const handleOpenChange = useCallback((isOpen: boolean) => {
    setOpen(isOpen)
  }, [])

  const handleCancel = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleOk = useCallback(() => {
    setOpen(false)

    setValue(name, !value)
    onConfirm?.(!value)
  }, [onConfirm, setValue, value, name])

  const handleChange = useCallback(() => {
    if (!showPopover) {
      setValue(name, !value)
      onConfirm?.(!value)
    }
  }, [showPopover, onConfirm, value, setValue, name])

  const isFieldViewOnly = getIsFormFieldViewOnly(viewOnlyFields, name)

  return (
    <ConfirmationPopover
      open={false}
      {...(showPopover &&
        !isFieldViewOnly && {
          open,
          onOpen: handleOpen,
          onOpenChange: handleOpenChange,
          onCancel: handleCancel,
          shouldOpen: showPopover,
        })}
      text={confirmText}
      onOk={handleOk}
      placement="bottomLeft"
      okText={okText}
    >
      <FormItem name={name} disableOnChange={showPopover}>
        <Checkbox value={value} onChange={handleChange} disabled={disabled}>
          <Typography size="s" fontWeight="l">
            {label}
          </Typography>
        </Checkbox>
      </FormItem>
    </ConfirmationPopover>
  )
}

export const FormItemCheckbox = typedMemo(FormItemCheckboxBase)
