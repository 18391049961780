import { PurchaseOrder, PurchaseOrderStatus } from 'api'
import { getPurchaseOrderStatusLabel, hashRoute } from 'constant'
import { formatDate } from 'utils'

import { ReactLink, Typography } from 'designSystem'
import { TableColumnKey, TableColumnsType } from 'components'

export const tableColumns: TableColumnsType<PurchaseOrder> = [
  {
    title: '#',
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => index + 1,
  },
  {
    title: 'Created Date',
    key: 'createdAt',
    dataIndex: 'createdAt',
    width: '50%',
    ellipsis: true,
    render: (value: Date) => formatDate(value, 'StandardWithHours'),
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    width: '20%',
    ellipsis: true,
    render: (value: PurchaseOrderStatus) => getPurchaseOrderStatusLabel(value),
  },
  {
    title: 'PO Number',
    key: 'number',
    dataIndex: 'number',
    width: '20%',
    ellipsis: true,
    render: (value: string, record: PurchaseOrder) => (
      <ReactLink to={hashRoute.po(record.id)}>
        <Typography lineEllipsis={1} color="inherit" size="xs" fontWeight="l">
          {value}
        </Typography>
      </ReactLink>
    ),
  },
  {
    title: 'Qty.',
    key: 'allQty',
    dataIndex: 'allQty',
    width: '10%',
    ellipsis: true,
    align: 'right',
    render: (value) => value || '-',
  },
]
