import { api, EntityId, LocationCode } from 'api'

import { locationCodeJoinParams } from '../../constants/locationCodeJoinParams'

export const getLocationCodeById = (id?: EntityId): Promise<LocationCode> => {
  return api({
    method: 'get',
    url: `/api/v1/location-codes/${id}`,
    params: {
      join: locationCodeJoinParams,
    },
  })
}
