import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import isEmpty from 'lodash.isempty'
import { useMutation } from '@tanstack/react-query'

import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { route } from 'constant'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { CustomerPayload } from '../types/customerPayload'

import { postCustomer } from '../queryApi'

export const useCreateCustomer = () => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const navigate = useNavigate()

  const { mutate, ...rest } = useMutation({
    mutationFn: postCustomer,
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.CustomerCreate)

      const { shippingAddresses, contacts, ucc, ...rest } = data
      const { addresses, ...uccData } = ucc || {}

      queryClient.setQueryData([QueryKey.GetCustomerById, data.id], {
        ...rest,
        ...(ucc && { ucc: uccData }),
      })
      queryClient.setQueryData([QueryKey.GetCustomerContacts, data.id], { data: contacts })
      queryClient.setQueryData([QueryKey.GetCustomerShippingAddresses, data.id], shippingAddresses)
      queryClient.setQueryData([QueryKey.GetCustomerUccAddresses, data.id], addresses)
      showNotification({ title: 'Customer created', type: 'success' })
      navigate(`${route.customers}/${data.id}`)
    },
  })

  const create = useCallback(
    (payload: CustomerPayload) => {
      const { billingAddresses, shippingAddresses, ucc, ...restPayload } = payload
      mutate({
        ...restPayload,
        billingAddresses: billingAddresses?.filter((address) => !isEmpty(address)),
        shippingAddresses: shippingAddresses?.filter((address) => !isEmpty(address)),
        ucc: ucc ? { ...ucc, addresses: ucc?.addresses?.filter((address) => !isEmpty(address)) } : null,
      })
    },
    [mutate],
  )

  return {
    create,
    ...rest,
  }
}
