import { FC } from 'react'

import { useForm } from 'hooks'
import {
  currency,
  customerAccountValueOptions,
  customerBuyOptions,
  customerIndustryOptions,
  customerStatusOptions,
} from 'constant'
import { typedMemo } from 'types'

import { Container, Divider, EditFormItem, Icon } from 'designSystem'
import {
  ConfirmationRequiredPopover,
  CustomerStatusTag,
  FormItemEditDatePicker,
  FormItemEditInput,
  FormItemEditInputNumber,
  FormItemEditSelectSingle,
} from 'components'

import { CustomerDetailsPayload } from '../../types/customerDetailsPayload'
import { CustomerFormProps } from '../../types/customerFormProps'

const CustomerFieldsBase: FC<CustomerFormProps> = (formProps) => {
  const { Form, triggerSubmit } = useForm<CustomerDetailsPayload>(formProps)

  return (
    <Form>
      <Container fd="column" gap={16} fg={1}>
        <EditFormItem
          name="companyName"
          render={({ field }) => (
            <FormItemEditInput
              size="large"
              align="left"
              {...field}
              onConfirm={triggerSubmit}
              popover={<ConfirmationRequiredPopover withPopover />}
              placeholder="Enter Company Name"
            />
          )}
        />
        <Divider />
        <Container fd="column" gap={4}>
          <EditFormItem
            name="accountNumber"
            label="Account Number"
            render={({ field }) => (
              <FormItemEditInput placeholder="Enter Account Number" {...field} onBlur={triggerSubmit} />
            )}
          />
          <EditFormItem
            name="industry"
            label="Industry"
            render={({ field }) => (
              <FormItemEditSelectSingle {...field} onSubmit={triggerSubmit} options={customerIndustryOptions} />
            )}
          />
          <EditFormItem
            name="accountValue"
            label="Account Value"
            render={({ field }) => (
              <FormItemEditSelectSingle {...field} onSubmit={triggerSubmit} options={customerAccountValueOptions} />
            )}
          />
          <EditFormItem
            name="buys"
            label="Buys"
            render={({ field }) => (
              <FormItemEditSelectSingle {...field} onSubmit={triggerSubmit} options={customerBuyOptions} />
            )}
          />
          <EditFormItem
            name="website"
            label="Website"
            render={({ field }) => (
              <FormItemEditInput placeholder="Type Website..." {...field} onBlur={triggerSubmit} />
            )}
          />

          <EditFormItem
            name="creditLimit"
            label="Credit Limit"
            render={({ field }) => (
              <FormItemEditInputNumber
                placeholder="Enter Credit Limit"
                {...field}
                onBlur={triggerSubmit}
                precision={2}
                suffix={currency}
              />
            )}
          />
          <EditFormItem
            name="creditLimitExpirationDate"
            label="Exp. Date"
            render={({ field }) => <FormItemEditDatePicker {...field} onBlur={triggerSubmit} />}
          />
          <EditFormItem
            name="status"
            label="Status"
            render={({ field }) => (
              <FormItemEditSelectSingle
                customPreview={<CustomerStatusTag status={field.value} icon={<Icon icon="arrowDown" size={16} />} />}
                width={100}
                placeholder="Status"
                {...field}
                onSubmit={triggerSubmit}
                options={customerStatusOptions}
              />
            )}
          />
        </Container>
      </Container>
    </Form>
  )
}

export const CustomerFields = typedMemo(CustomerFieldsBase)
