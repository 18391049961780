export enum PurchaseOrderSubLineAccessor {
  AssetNumber = 'assetNumber',
  ExpectedDeliveryDate = 'expectedDeliveryDate',
  LocationCodeId = 'locationCodeId',
  VendorDepotId = 'vendorDepotId',
  IsReceived = 'isReceived',
  IsBilled = 'isBilled',
  ReferenceNumber = 'referenceNumber',
  BillingReference = 'billingReference',
  ReferenceType = 'referenceType',
}
