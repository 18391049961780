import { getDepotCodes, VendorDepotSettings } from 'api'
import { QueryKey } from 'enums'
import { AsyncSelectGetItemsProps } from 'types'

import type { SelectSingleOption } from 'designSystem'

import { useAsyncList } from './useAsyncList'

export const useDepotCodes = ({ valuePropName, withFullDataOption, fields, ...props }: AsyncSelectGetItemsProps) => {
  const { list, ...asyncListProps } = useAsyncList<VendorDepotSettings>({
    ...props,
    queryFn: getDepotCodes,
    queryKey: QueryKey.GetDepotCodesList,
    searchFieldName: 'code',
    limit: 10,
    fields: fields || 'id,code',
  })

  const options = list.map((depotCode) => ({
    value: valuePropName ? (depotCode[valuePropName as keyof VendorDepotSettings] as string | number) : depotCode.id,
    label: depotCode.code,
    ...(withFullDataOption && { data: depotCode }),
  })) satisfies SelectSingleOption[]

  return {
    options,
    ...asyncListProps,
  }
}
