import { CSSProperties, FC, MouseEvent, ReactNode, useCallback, useMemo } from 'react'

import { typedMemo } from 'types'

import type { EditFormItemSize, EditViewType, SelectSingleOption, TypographyProps } from 'designSystem'

import * as Styled from './styles'

import { Button } from '../../Button'
import { Icon } from '../../Icon'
import { ReactLink } from '../../ReactLink'
import { Typography } from '../../Typography'

export interface EditViewProps {
  value?: string | string[] | readonly string[] | number | number[] | SelectSingleOption[] | null
  disabled?: boolean
  error?: boolean
  onClick?: (event: MouseEvent<HTMLDivElement>) => void
  size?: EditFormItemSize
  as?: EditViewType
  href?: string
  withEdit?: boolean
  layout?: 'horizontal' | 'vertical'
  suffix?: ReactNode
  prefix?: ReactNode
  align?: CSSProperties['textAlign']
  lineEllipsis?: number
  onClear?: () => void
  nowrap?: boolean
}

export const EditViewBase: FC<EditViewProps> = ({
  value: valueControlled,
  disabled,
  error,
  layout = 'horizontal',
  align,
  onClick,
  size,
  as,
  href,
  withEdit,
  suffix,
  prefix,
  lineEllipsis = 1,
  nowrap,
  onClear,
}) => {
  const textSize = (size === 'small' && 'xs') || (size === 'large' && 'l') || 's'
  const textWeight = (size === 'large' && 'xl') || 'l'

  const isSelectLink = as === 'select-link' && !!href

  const displayValue = useMemo(() => {
    const typographyProps = {
      fontWeight: textWeight,
      size: textSize,
      nowrap: nowrap,
      lineEllipsis: lineEllipsis || layout === 'horizontal' ? 1 : undefined,
    } as TypographyProps

    if (typeof valueControlled === 'string' || typeof valueControlled === 'number') {
      return (
        <Typography {...typographyProps}>
          {prefix}
          {isSelectLink ? (
            <ReactLink to={href}>
              <Typography {...typographyProps} color="inherit">
                {valueControlled}
              </Typography>
            </ReactLink>
          ) : (
            valueControlled
          )}
          {suffix}
        </Typography>
      )
    }

    return valueControlled?.map((value, index) => {
      if (typeof value === 'object') {
        return (
          <Typography {...typographyProps} key={index}>
            {prefix}
            {isSelectLink ? (
              <ReactLink to={`${href}/${value.value}`}>
                <Typography {...typographyProps} color="inherit">
                  {value.label}
                </Typography>
              </ReactLink>
            ) : (
              value.label
            )}
            {suffix}
          </Typography>
        )
      }

      return (
        <Typography {...typographyProps} key={index}>
          {prefix}
          {isSelectLink ? (
            <ReactLink to={`${href}/${value}`}>
              <Typography {...typographyProps} color="inherit">
                {value}
              </Typography>
            </ReactLink>
          ) : (
            value
          )}
          {suffix}
        </Typography>
      )
    })
  }, [valueControlled, layout, textSize, textWeight, suffix, lineEllipsis, prefix, href, isSelectLink, nowrap])

  const isValue = valueControlled !== undefined && valueControlled !== null && !!valueControlled.toString().length

  const handleClick = useCallback(
    (event: any) => {
      if (!withEdit || !isValue) {
        if (disabled) {
          return
        }
        onClick?.(event)
      }
    },
    [disabled, onClick, withEdit, isValue],
  )

  return (
    <Styled.Wrapper error={error} disabled={disabled} $isSelectLink={isValue && isSelectLink} size={size}>
      <Styled.ContentWrapper onClick={handleClick} $isSelectLink={isValue && isSelectLink}>
        <Styled.ItemsWrapper layout={layout} align={align} isDirectionColumn={Array.isArray(valueControlled)}>
          {isValue ? (
            displayValue
          ) : (
            <Typography color="secondary" size={textSize} fontWeight={textWeight}>
              Empty
            </Typography>
          )}
        </Styled.ItemsWrapper>
        {as === 'select' && !disabled && (
          <Styled.SuffixWrapper>{<Icon icon="caretDown" size={12} />}</Styled.SuffixWrapper>
        )}
      </Styled.ContentWrapper>
      {as === 'link' && href && (
        <Styled.SuffixOutsideWrapper>
          <ReactLink to={href}>
            <Icon icon="open" size={size === 'small' ? 12 : 16} />
          </ReactLink>
        </Styled.SuffixOutsideWrapper>
      )}
      {withEdit && isValue && !disabled && (
        <Styled.SuffixOutsideWrapper onClick={onClick}>
          <Button extraSmall size="small" icon="edit" />
        </Styled.SuffixOutsideWrapper>
      )}
      {!!onClear && !disabled && (
        <Styled.SuffixOutsideWrapper>
          <Button extraSmall danger size="small" icon="cross" onClick={onClear} />
        </Styled.SuffixOutsideWrapper>
      )}
    </Styled.Wrapper>
  )
}

export const EditView = typedMemo(EditViewBase)
