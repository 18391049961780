import type { ActivityStreamline, ActivityStreamlineType } from 'api'
import { route } from 'constant'
import { formatDate } from 'utils'

import { ReactLink } from 'designSystem'

import { ActivityStreamLineRecord } from '../elements/ActivityStreamLineRecord'

import { TableColumnKey, TableColumnsType } from '../../Table'
import { getActivityStreamLineEntity } from './getActivityStreamLineEntity'

export const getTableColumns = (offsetCount: number): TableColumnsType<ActivityStreamline> => [
  {
    title: '#',
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => offsetCount + index + 1,
  },
  {
    title: 'Time',
    dataIndex: 'createdAt',
    ellipsis: true,
    width: 160,
    render: (value: Date) => formatDate(value, 'StandardWithHours'),
  },
  {
    title: 'User',
    dataIndex: 'performedBy',
    ellipsis: true,
    width: 200,
    render: (performedBy) => (
      <ReactLink to={`${route.userManagement}/${performedBy.id}`}>
        {performedBy.firstName} {performedBy.lastName}
      </ReactLink>
    ),
  },
  {
    title: 'Entity',
    dataIndex: 'type',
    width: 130,
    render: (type: ActivityStreamlineType) => getActivityStreamLineEntity(type),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    width: 400,
    render: (action, record: ActivityStreamline) => {
      return <ActivityStreamLineRecord data={record} />
    },
  },
]
