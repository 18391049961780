import { FC } from 'react'
import { Col, Divider, Row } from 'antd'

import { FormItem, Icon, Input, Spacer, TitleTag } from 'designSystem'

import * as Styled from './styles'

interface ContactProps {
  index?: number
  name?: string
  onDelete?: (index: number) => void
  fieldsLength?: number
  showTag?: boolean
  subTitle?: string
}

export const Contact: FC<ContactProps> = ({
  name,
  index = 0,
  onDelete,
  fieldsLength,
  showTag = true,
  subTitle = 'Contact Person',
}) => {
  return (
    <Styled.Wrapper>
      {index !== 0 && <Divider />}
      {name && showTag ? (
        <>
          <TitleTag
            title={`${subTitle} ${index + 1}`}
            prefix={<Icon icon="person" size={10} />}
            canDelete={fieldsLength !== 1}
            onDelete={() => onDelete?.(index)}
          />
          <Spacer mb={4} />
        </>
      ) : null}
      <Row gutter={8}>
        <Col span={12}>
          <FormItem name={name ? `${name}.${index}.firstName` : 'firstName'} label="First Name">
            <Input placeholder="Type First Name" />
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem name={name ? `${name}.${index}.lastName` : 'lastName'} label="Last Name">
            <Input placeholder="Type Last Name" />
          </FormItem>
        </Col>
      </Row>
      <Spacer mb={3} />
      <FormItem name={name ? `${name}.${index}.email` : 'email'} label="Email">
        <Input placeholder="Type Email" />
      </FormItem>
      <Spacer mb={3} />
      <Row gutter={8}>
        <Col span={12}>
          <FormItem name={name ? `${name}.${index}.phoneNumber` : 'phoneNumber'} label="Phone Number">
            <Input placeholder="+" type="phone" />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem name={name ? `${name}.${index}.phoneExtension` : 'phoneExtension'} label="Phone Number Extension">
            <Input placeholder="Type Phone Extension" />
          </FormItem>
        </Col>
      </Row>
      <Spacer mb={3} />
      <FormItem name={name ? `${name}.${index}.notes` : 'notes'} label="Notes">
        <Input placeholder="Leave some notes" />
      </FormItem>
    </Styled.Wrapper>
  )
}
