import { FC } from 'react'

import { Item } from 'api'
import { route } from 'constant'
import { AppModule } from 'types'

import {
  Table,
  TableFilterDrawer,
  TableFilterOptions,
  TableSearchSingle,
  TableTitle,
  useTableColumns,
} from 'components'

import { useItems } from './hooks/useItems'
import { getTableColumns } from './utils/getTableColumns'
import { itemsFilterOptions } from './constants/itemsFilterOptions'
import { itemsVisibleColumnsOptions } from './constants/itemsVisibleColumns'

export const ItemsList: FC = () => {
  const {
    data,
    isLoading,
    pagination,
    onTableChange,
    getFieldSorter,
    offsetCount,
    TableContextProvider,
    filterOptions,
    setFilterOptions,
  } = useItems()
  const allColumns = getTableColumns(getFieldSorter, offsetCount)
  const { visibleColumns, setVisibleColumns, columns } = useTableColumns<Item>({
    module: AppModule.Items,
    allColumns,
  })

  const dataSource: Item[] = data.map(({ id, ...rest }) => ({
    key: id.toString(),
    id,
    ...rest,
  }))

  return (
    <TableContextProvider>
      <TableTitle
        title="Items"
        total={pagination?.total}
        addRoute={route.itemsCreate}
        search={<TableSearchSingle searchFieldName={['name', 'sku', 'groupedSKUs.sku']} />}
        filter={
          <TableFilterDrawer
            setFilterOptions={setFilterOptions}
            filterOptions={filterOptions}
            options={itemsFilterOptions}
            module={AppModule.Items}
          />
        }
        columns={
          <TableFilterOptions
            title="Columns"
            icon="columns"
            options={itemsVisibleColumnsOptions}
            value={visibleColumns}
            onChange={setVisibleColumns}
          />
        }
      />
      <Table<Item>
        withBorderRadius={false}
        scroll={{ x: 750 }}
        dataSource={dataSource}
        loading={isLoading}
        columns={columns}
        pagination={pagination}
        onChange={onTableChange}
      />
    </TableContextProvider>
  )
}
