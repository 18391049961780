import * as Yup from 'yup'

import { passwordValidation } from 'utils'

export const validationSchema = Yup.object({
  password: passwordValidation,
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required!'),
})
