import { FC } from 'react'

import { ReferenceNumber, ReferenceNumberType } from 'api'
import { getReferenceTypeOptionLabel } from 'constant'

import { Container, Typography } from 'designSystem'
import { Table } from 'components'

import { tableColumns } from './constants/tableColumns'

interface RelatedReferenceNumberProps {
  type: ReferenceNumberType
  data?: ReferenceNumber | ReferenceNumber[] | null
}

export const RelatedReferenceNumber: FC<RelatedReferenceNumberProps> = ({ type, data }) => {
  if (!data || (Array.isArray(data) && !data?.length)) {
    return null
  }

  const title = getReferenceTypeOptionLabel(type)

  const dataSource = Array.isArray(data)
    ? data.map((reference) => ({
        ...reference,
        key: reference.id.toString(),
      }))
    : [{ ...data, key: data.id.toString() }]

  return (
    <Container fd="column" gap={8} width="100%">
      <Typography size="m" fontWeight="xl">
        {title}
      </Typography>
      <Table<ReferenceNumber> dataSource={dataSource} columns={tableColumns} scroll={{ x: 750 }} />
    </Container>
  )
}
