import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { RepairsPayload } from '../types/repairsForm'

import { patchRepairs } from '../queryApi'

export const usePatchRepairs = (assetId: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation({
    mutationFn: patchRepairs,
    onSuccess: () => {
      dispatchAppEvent(AppEvent.AssetRepairUpdate)

      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const patch = useCallback(
    (id: number, payload: RepairsPayload) => {
      mutate({ assetId, id, data: payload })
    },
    [mutate, assetId],
  )

  return {
    patch,
    ...rest,
  }
}
