import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { useForm, useMemoCompare } from 'hooks'

import { Container, Divider, EditFormItem, Spacer, Typography } from 'designSystem'
import { Card, FormItemEditDatePicker, FormItemEditTextArea, Spinner } from 'components'

import { useGetCustomerById } from './hooks/useGetCustomerById'
import { useUpdateCustomer } from './hooks/useUpdateCustomer'
import { validationSchema } from './constants/validationSchema'
import { CustomerDetailsPayload } from './types/customerDetailsPayload'
import { CustomerBillingAddress } from './elements/CustomerBillingAddress'
import { CustomerContacts } from './elements/CustomerContacts'
import { CustomerFields } from './elements/CustomerFields'
import { CustomerShippingAddresses } from './elements/CustomerShippingAddresses'
import { CustomerUccAddresses } from './elements/CustomerUccAddresses'
import { UccFields } from './elements/UccFields'

export const CustomerDetails: FC = () => {
  const { id } = useParams()

  const { data, isLoading: isGetLoading } = useGetCustomerById(Number(id))
  const { update, isError, isPending } = useUpdateCustomer(Number(id))

  const { billingAddresses, ...customerData } = data || {}

  const mainFormProps = useMemoCompare({
    mode: 'onChange' as const,
    validationSchema,
    onSubmit: update,
    isSubmitError: isError,
    defaultValues: customerData,
    viewOnlyFields: ['createdAt'],
  })

  const { Form, triggerSubmit } = useForm<CustomerDetailsPayload>(mainFormProps)

  if (isGetLoading) {
    return <Spinner />
  }

  return (
    <Container pa={4} gap={8} fw="wrap">
      <Container fd="column" gap={8} width={390}>
        <Card isLoading={isPending}>
          <CustomerFields {...mainFormProps} />
          <Divider my={4} />
          <CustomerBillingAddress customerId={Number(id)} billingAddresses={billingAddresses} />
          <Divider my={4} />
          <CustomerContacts id={Number(id)} />
          <Divider my={4} />
          <CustomerShippingAddresses customerId={Number(id)} />
        </Card>
        <Card>
          <Form>
            <Container fd="column" gap={4}>
              <EditFormItem
                name="createdAt"
                label="Created Time"
                render={({ field }) => <FormItemEditDatePicker format="StandardWithHours" {...field} />}
              />
            </Container>
          </Form>
        </Card>
      </Container>

      <Container fd="column" gap={8} width={390}>
        <Card>
          <UccFields {...mainFormProps} />
          <CustomerUccAddresses customerId={Number(id)} isUcc={data?.isUcc} />
        </Card>

        <Card>
          <Form>
            <Typography size="l" fontWeight="xl">
              Notes
            </Typography>
            <Spacer mb={3} />
            <EditFormItem
              name="notes"
              render={({ field }) => (
                <FormItemEditTextArea placeholder="Type Notes" {...field} onConfirm={triggerSubmit} />
              )}
            />
          </Form>
        </Card>
      </Container>
    </Container>
  )
}
