import * as Yup from 'yup'

import {
  customerStatusValidation,
  customerUCCValidation,
  customerValidation,
} from 'modules/customers/constants/validationSchema'

export const validationSchema = Yup.object({
  ...customerStatusValidation,
  ...customerValidation,
  ...customerUCCValidation,
})
