import { Checkbox as BaseCheckbox, Col as BaseCol } from 'antd'
import { styled } from 'styled-components'

export const Col = styled(BaseCol)`
  padding: ${({ theme }) => theme.spacers[3]};
`

export const Checkbox = styled(BaseCheckbox)`
  &.ant-checkbox-wrapper span:last-child {
    padding-inline-end: ${({ theme }) => theme.spacers[2]};
  }
`
