import { AssetRepairStatus } from 'api'
import { assetRepairStatusOptionLabel } from 'constant'

import { TagType } from 'designSystem'

interface AssetRepairStatusTagData {
  status: TagType
  text?: string
}

export const getTagDataByAssetRepairStatus = (status: AssetRepairStatus): AssetRepairStatusTagData => {
  const text = assetRepairStatusOptionLabel[status]

  const tagStatus = {
    [AssetRepairStatus.WaitingForRepair]: TagType.Default,
    [AssetRepairStatus.Done]: TagType.Success,
    [AssetRepairStatus.Canceled]: TagType.Error,
  }[status]

  return { status: tagStatus, text }
}
