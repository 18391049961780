import { FC, useCallback, useEffect } from 'react'

import { useFieldArray } from 'hooks'
import { typedMemo } from 'types'

import { Button, Container, Spacer, Typography } from 'designSystem'
import { Card } from 'components/Card'

import { Contact } from './elements/Contact'

import { FieldArrayItem } from '../FormElements'

interface ContactsPros {
  name?: string
  title?: string
  maxContactsCount?: number
  maxWidth?: number
  subTitle?: string
}

export const ContactsBase: FC<ContactsPros> = ({
  name = 'contacts',
  maxContactsCount,
  maxWidth,
  title = 'Contacts',
  subTitle,
}) => {
  const { fields, append, remove } = useFieldArray({ name })

  const insertField = useCallback(() => {
    append({}, { shouldFocus: false })
  }, [append])

  useEffect(() => {
    // Add one field item by default
    if (!fields.length) {
      insertField()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Card maxWidth={maxWidth}>
      <Typography fontWeight="xl" size="l">
        {title}
      </Typography>
      <Spacer mb={5} />
      {fields.map((field, index) => (
        <FieldArrayItem key={field.id} onDelete={remove}>
          <Contact name={name} subTitle={subTitle} onDelete={remove} index={index} fieldsLength={fields.length} />
        </FieldArrayItem>
      ))}
      {(!maxContactsCount || fields.length < maxContactsCount) && (
        <Container mt={3}>
          <Button type="link" icon="plus" onClick={insertField}>
            Add
          </Button>
        </Container>
      )}
    </Card>
  )
}

export const Contacts = typedMemo(ContactsBase)
