import { createGlobalStyle, styled } from 'styled-components'

interface AutocompletePacContainerStyleProps {
  $ml?: number
  $mr?: number
  $width?: number
}
export const AutocompletePacContainerStyle = createGlobalStyle<AutocompletePacContainerStyleProps>`
  & .pac-container {
    ${({ $width }) => $width && `width: ${$width}px !important`};
    margin-left: ${({ $ml, theme }) => ($ml ? `${$ml}px` : `-${theme.spacers[3]}`)};
  }
`

export const AutocompleteWrapper = styled.div<{ $width?: number }>`
  ${({ $width }) => $width && `width: ${$width}px`};
`
