import { route } from 'constant'

export const breadcrumbsData = [
  {
    title: 'Customers',
    url: route.customers,
  },
  {
    title: 'Create Customer',
  },
]
