import type { ApiQueryParams, EntityId, ReferenceNumber } from 'api'
import { Pagination } from 'types'

import { api } from '../api'

export interface GetReferenceNumbersResponse extends Pagination {
  data?: { reference: ReferenceNumber }[]
}

export type GetReferenceNumbersQueryParams = ApiQueryParams

export const getReferenceNumbers = (params?: GetReferenceNumbersQueryParams): Promise<GetReferenceNumbersResponse> => {
  return api({
    method: 'get',
    url: '/api/v1/references/',
    params,
  })
}

export type GetReferenceNumberByIdQueryParams = ApiQueryParams

export const getReferenceNumberById = (
  id?: EntityId,
  params?: GetReferenceNumberByIdQueryParams,
): Promise<{ reference: ReferenceNumber }> => {
  return api({
    method: 'get',
    url: `/api/v1/references/${id}`,
    params,
  })
}
