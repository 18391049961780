import { api, Customer } from 'api'

import { CustomerPayload } from './types/customerPayload'

export const postCustomer = (data: CustomerPayload): Promise<Customer> => {
  return api({
    method: 'post',
    url: '/api/v1/customers',
    data,
    params: {
      join: ['contacts', 'billingAddresses', 'shippingAddresses', 'uccAddresses'],
    },
  })
}
