import type { Pagination } from 'types'

import type { ApiQueryParams, UserNotification } from 'api/types'

import { api } from '../api'

export interface GetUserNotificationsResponse extends Pagination {
  data?: UserNotification[]
}

export type GetUserNotificationsParams = ApiQueryParams

export const getUserNotifications = (params?: GetUserNotificationsParams): Promise<GetUserNotificationsResponse> => {
  return api({
    method: 'get',
    url: '/api/v1/user-notifications',
    params,
  })
}
