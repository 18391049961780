import { TypeOfEquipment } from 'api/types/vendor/typeOfEquipment'

export const typeOfEquipmentLabel = {
  [TypeOfEquipment.New]: 'New',
  [TypeOfEquipment.Used]: 'Used',
  [TypeOfEquipment.Special]: 'Special',
}

export const typeOfEquipmentOptions = Object.keys(typeOfEquipmentLabel).map((key) => ({
  value: key as TypeOfEquipment,
  label: typeOfEquipmentLabel[key as TypeOfEquipment],
}))
