import {
  FocusEvent,
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  UIEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Select as SelectBase, Tooltip } from 'antd'
import { RefSelectProps, SelectProps as BaseSelectProps } from 'antd/lib/select'
import isEqual from 'react-fast-compare'

import { useMemoCompare } from 'hooks/useMemoCompare'
import { typedMemo } from 'types'
import theme from 'styles/theme'

import { SelectStyle } from './types/selectStyle'
import { SelectDropdown } from './elements/SelectDropdown'
import { SelectSpinner } from './elements/SelectSpinner'
import * as Styled from './styles'

import { Checkbox } from '../Checkbox'
import { Container } from '../Container'
import { Icon } from '../Icon'
import { SelectSingleOption } from '../SelectSingle'
import { Typography } from '../Typography'

export interface SelectMultiProps extends Omit<BaseSelectProps, 'mode' | 'onChange' | 'value'> {
  mode?: 'multiple'
  name?: string
  label?: string
  error?: string
  width?: number
  selectStyle?: SelectStyle
  suffixIcon?: ReactNode
  prefixIcon?: ReactNode
  value?: (number | string)[]
  onChange?: (value?: (number | string)[], options?: SelectSingleOption[]) => void
  allowClear?: boolean
  prefix?: ReactNode
  isEdit?: boolean
  ref?: Ref<RefSelectProps>
  onPopupScrollEnd?: () => void
  onFocusChange?: (focused: boolean) => void
  defaultOptions?: SelectSingleOption[]
  withCreateNew?: boolean
  onClickCreateNew?: () => void
  fullwidth?: boolean
}

export const SelectMultiBase = forwardRef(
  (
    {
      onFocusChange,
      options = [],
      defaultOptions = [],
      label,
      error,
      width,
      selectStyle,
      suffixIcon,
      prefixIcon,
      onChange,
      onSearch,
      onBlur,
      onFocus,
      isEdit,
      size = 'small',
      placeholder = 'Select',
      loading = false,
      onPopupScrollEnd,
      onClear,
      defaultValue,
      value,
      withCreateNew,
      onClickCreateNew,
      allowClear,
      ...props
    }: SelectMultiProps,
    ref: Ref<RefSelectProps>,
  ): ReactElement => {
    const optionFontSize = size === 'small' ? 'xs' : 's'
    const [isFocused, setIsFocused] = useState(false)

    const memoizedOptions = useMemoCompare(options) as SelectSingleOption[]
    const memoizedDefaultOptions = useMemoCompare(defaultOptions) as SelectSingleOption[]

    const [selectedOptions, setSelectedOptions] = useState<SelectSingleOption[] | undefined>(
      memoizedDefaultOptions || defaultValue,
    )

    const filteredOptions = useMemo(() => {
      const filteredOptionsValues = memoizedOptions.map(({ value }) => value)
      return [
        ...memoizedOptions,
        ...memoizedDefaultOptions.filter((option) => !filteredOptionsValues.includes(option.value)),
      ]
    }, [memoizedOptions, memoizedDefaultOptions])

    const ids = selectedOptions?.map((option) => option.value)
    const handleChange = useCallback(
      (value?: (string | number)[], options?: SelectSingleOption | SelectSingleOption[]) => {
        if (Array.isArray(options)) {
          setSelectedOptions(options)
          onChange?.(value, options)
        }
      },
      [onChange],
    )

    const handleBlur = useCallback(
      (e: FocusEvent<HTMLElement, Element>) => {
        onBlur?.(e)
        setIsFocused(false)
        onFocusChange?.(false)
      },
      [onBlur, onFocusChange],
    )

    const handleFocus = useCallback(
      (e: FocusEvent<HTMLElement, Element>) => {
        onFocus?.(e)
        setIsFocused(true)
        onFocusChange?.(true)
      },
      [onFocus, onFocusChange],
    )

    const handlePopupScroll = useCallback(
      (event: UIEvent) => {
        const target = event.target as HTMLDivElement

        if (!loading && target.scrollTop + target.offsetHeight === target.scrollHeight) {
          onPopupScrollEnd?.()
        }
      },
      [onPopupScrollEnd, loading],
    )

    const handleClear = useCallback(() => {
      onClear?.()
      onSearch?.('')
    }, [onClear, onSearch])

    useEffect(() => {
      const selectedValues = selectedOptions?.map(({ value }) => value) || []

      if (!isEqual(selectedValues, value || [])) {
        setSelectedOptions(
          filteredOptions?.filter((option) => option.value && value?.includes(option.value)) as SelectSingleOption[],
        )
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return (
      <Styled.SelectWrapper
        isEdit={!!isEdit}
        label={label}
        width={width}
        selectStyle={selectStyle}
        isOptionSelected={!!selectedOptions?.length}
        isFocused={isFocused}
      >
        {label && (
          <Styled.LabelWrapper>
            <Container mb={1}>
              <Typography as="label" size={optionFontSize} fontWeight="l" color={error ? 'error' : 'primary'}>
                <Styled.LabelItem>
                  {(props.prefix && <Styled.Prefix>{props.prefix}</Styled.Prefix>) || prefixIcon}
                  {label}
                </Styled.LabelItem>
              </Typography>
            </Container>
          </Styled.LabelWrapper>
        )}
        <SelectBase<(string | number)[], SelectSingleOption>
          {...props}
          defaultActiveFirstOption={false}
          defaultValue={defaultValue}
          onClear={handleClear}
          ref={ref}
          value={value}
          size="middle"
          mode="multiple"
          placeholder={placeholder}
          onChange={handleChange}
          onSearch={onSearch}
          status={error && 'error'}
          getPopupContainer={(trigger) => trigger.parentNode}
          onPopupScroll={handlePopupScroll}
          suffixIcon={
            loading ? <SelectSpinner /> : suffixIcon || <Icon icon="caretDown" color={theme.colors.primary} size={14} />
          }
          allowClear={allowClear && { clearIcon: <Icon icon="cancelFilled" size={14} /> }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          notFoundContent={loading ? <Container minHeight={18} /> : <Container jc="center">No result found</Container>}
          optionFilterProp="label"
          optionLabelProp="label"
          filterOption={!onSearch}
          loading={loading}
          open={isFocused}
          dropdownRender={(menu) => (
            <SelectDropdown
              menu={menu}
              isLoading={loading}
              withCreateNew={withCreateNew}
              onClickCreateNew={onClickCreateNew}
            />
          )}
        >
          {filteredOptions?.map((option) => (
            <SelectBase.Option
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={option.disabled}
              style={{ padding: 0 }}
            >
              <Tooltip title={option.label} destroyTooltipOnHide mouseLeaveDelay={0}>
                <Styled.Option disabled={option.disabled}>
                  <Container gap={2} style={{ minWidth: 0 }}>
                    {option.icon && <Container mr={2}>{option.icon}</Container>}
                    <Typography display="flex" fontWeight="l" size="xs" lineEllipsis={1}>
                      {option.label}
                    </Typography>
                  </Container>
                  <Checkbox checked={ids?.includes(option.value)} />
                </Styled.Option>
              </Tooltip>
            </SelectBase.Option>
          ))}
        </SelectBase>
      </Styled.SelectWrapper>
    )
  },
)

export const SelectMulti = typedMemo(SelectMultiBase)
