import { Link as BaseLink } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { styled } from 'styled-components'

export const HeaderHeight = 56

export const Header = styled(Layout.Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &&.ant-layout-header {
    height: ${({ theme }) => theme.spacers[12]};
    padding: 0 ${({ theme }) => theme.spacers[4]};
    background: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.lines};
    z-index: ${({ theme }) => theme.zIndexLevel[1]};
  }
`
export const Navigation = styled(Menu)`
  margin: 0 12px;
  height: ${HeaderHeight}px;
  min-width: 0;
  flex: auto;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lines};

  &&&& .ant-menu-item,
  &&&& .ant-menu-submenu {
    box-sizing: border-box;
    height: ${({ theme }) => theme.spacers[12]};
    display: flex;
    align-items: center;
    font-weight: ${({ theme }) => theme.fontWeight.l};
    font-size: ${({ theme }) => theme.fontSize.xs};
    line-height: ${({ theme }) => theme.lineHeight.s};
    color: ${({ theme }) => theme.colors.primary};
    padding: 0 ${({ theme }) => theme.spacers[3]};
    margin: 0 ${({ theme }) => theme.spacers[1]};
    svg {
      color: ${({ theme }) => theme.colors.primary};
      transition: all 0.3s;
    }

    &::after {
      left: 0;
      right: 0;
      border-bottom-color: transparent !important;
    }
  }

  .ant-menu-title-content {
    padding: 0 ${({ theme }) => theme.spacers[3]};
    transition: none;
  }
  &&&&&& .ant-menu-item-selected,
  &&&&&& .ant-menu-submenu-selected {
    .ant-menu-title-content {
      color: ${({ theme }) => theme.colors.accent[400]};
      text-shadow: 0.3px 0 0 currentColor;
    }
  }

  &&& .ant-menu-item-active,
  &&&& .ant-menu-submenu-active {
    .ant-menu-title-content {
      color: ${({ theme }) => theme.colors.accent[400]};
    }
    svg {
      transform: rotate(180deg);
      color: ${({ theme }) => theme.colors.accent[400]};
    }
  }
`

export const Link = styled(BaseLink)`
  display: flex;
  align-items: center;
`
