import { VendorCarrierSettingsType } from 'api/types/vendor/vendorCarrierSettings/VendorCarrierSettingsType'

export const vendorCarrierFlatBetOptionsLabels = {
  [VendorCarrierSettingsType.FlatBet]: 'Flat Bet',
  [VendorCarrierSettingsType.FlatPickupFromPort]: 'Pickup from Port',
  [VendorCarrierSettingsType.PickupFromRailRoads]: 'Pickup from Rail Roads',
}

export const vendorCarrierFlatBetOptions = [
  {
    value: VendorCarrierSettingsType.FlatBet,
    label: vendorCarrierFlatBetOptionsLabels[VendorCarrierSettingsType.FlatBet],
  },
  {
    value: VendorCarrierSettingsType.FlatPickupFromPort,
    label: vendorCarrierFlatBetOptionsLabels[VendorCarrierSettingsType.FlatPickupFromPort],
  },
  {
    value: VendorCarrierSettingsType.PickupFromRailRoads,
    label: vendorCarrierFlatBetOptionsLabels[VendorCarrierSettingsType.PickupFromRailRoads],
  },
]
