import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { getVendors, GetVendorsQueryParams } from 'api'
import { QueryKey } from 'enums'

export const useGetVendors = (queryParams: GetVendorsQueryParams) => {
  const { data, ...rest } = useQuery({
    queryKey: [QueryKey.GetVendors, queryParams],
    queryFn: () => getVendors(queryParams),
    placeholderData: keepPreviousData,
  })

  const { total, data: vendors } = data || {}

  return {
    data: vendors,
    total,
    ...rest,
  }
}
