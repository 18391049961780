import { FC } from 'react'

import { currency } from 'constant'

import { Container, FormItem, InputNumber, Typography } from 'designSystem'

interface FeeItemProps {
  name: string
  label: string
}

export const FeeItem: FC<FeeItemProps> = ({ name, label }) => {
  return (
    <Container ai="center" jc="space-between">
      <Typography size="xs" fontWeight="l">
        {label}
      </Typography>
      <FormItem name={name}>
        <InputNumber placeholder="00.00" precision={2} prefix={currency} width={72} />
      </FormItem>
    </Container>
  )
}
