import { Avatar as BaseAvatar } from 'antd'
import { styled } from 'styled-components'

export const Avatar = styled(BaseAvatar)`
  background: ${({ theme }) => theme.colors.lines};
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
`
