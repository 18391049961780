import { FC } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'

import { Container } from 'designSystem'

import { routes } from './constants/routes'

export const Vendors: FC = () => {
  return (
    <>
      <Outlet />
      <Container fd="column">
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Container>
    </>
  )
}
