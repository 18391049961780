import { styled } from 'styled-components'

export const Wrapper = styled.div<{ $isLinkAndViewOnly?: boolean }>`
  max-width: 100%;
  ${({ $isLinkAndViewOnly }) => $isLinkAndViewOnly && 'pointer-events: initial'};
`

export const CustomPreview = styled.div<{
  disabled?: boolean
}>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`
