import { api, EntityId, VendorCarrierSettings } from 'api'

import { CarrierDetailsPayload } from './types/carrierDetailsPayload'

export const patchCarrierDetails = ({
  id,
  data,
}: {
  id: EntityId
  data: CarrierDetailsPayload
}): Promise<VendorCarrierSettings> => {
  return api({
    method: 'patch',
    url: `/api/v1/vendors/${id}/types/carrier`,
    data,
  })
}
