import { PurchaseOrderBillingStatus } from 'api/types/purchaseOrder'

export const purchaseOrderBillingStatusOptions: {
  value: PurchaseOrderBillingStatus
  label: string
}[] = [
  {
    value: PurchaseOrderBillingStatus.Billed,
    label: 'Billed',
  },
  {
    value: PurchaseOrderBillingStatus.NotBilled,
    label: 'Not Billed',
  },
  {
    value: PurchaseOrderBillingStatus.PartiallyBilled,
    label: 'Partially Billed',
  },
]
