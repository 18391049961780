import { getAccessorTitle } from '../utils/getAccessorTitle'
import { Accessor } from '../types/accessor'

export const inventoryNotSwitchableColumns = [Accessor.Actions]
export const inventoryRequiredColumns = [Accessor.Index, Accessor.Title, Accessor.Sku, Accessor.Qty]

export const inventoryDefaultColumns = [
  Accessor.Index,
  Accessor.Title,
  Accessor.LocationCodeId,
  Accessor.Sku,
  Accessor.Qty,
  Accessor.Price,
  Accessor.ResaleMargin,
  Accessor.TotalCost,
]

export const inventoryVisibleColumnsOptions = Object.values(Accessor)
  .filter((accessor) => !inventoryNotSwitchableColumns.includes(accessor))
  .map((accessor) => ({
    value: accessor,
    label: getAccessorTitle(accessor),
    disabled: inventoryRequiredColumns.includes(accessor),
  }))
