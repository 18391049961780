import { FC } from 'react'
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Layout } from 'antd'

import { route, vendorViewRoute } from 'constant'

import { Breadcrumbs, Sidebar, Spinner } from 'components'

import { useGetVendorById } from './hooks/useGetVendorById'
import { getBreadcrumbsData } from './utils/getBreadcrumbsData'
import { getSidebarItems } from './utils/getSidebarItems'
import { getVendorRoutesByType } from './utils/getVendorRoutesByType'
import { ActionButtons } from './elements/ActionButtons'

export const Vendor: FC = () => {
  const { id } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const showActions = pathname === `${route.vendors}/${id}${vendorViewRoute.details}`

  const { data, isLoading } = useGetVendorById({
    id: Number(id),
    onError: () => {
      navigate(route.vendors)
    },
  })
  const itemsSidebarItems = id ? getSidebarItems(id, data?.type) : []

  const breadcrumbItem = data
    ? {
        title: `${data?.companyName} details`,
      }
    : undefined

  return (
    <Layout>
      <Sidebar sidebarItems={itemsSidebarItems} />
      <Layout.Content>
        <Breadcrumbs
          breadcrumbItems={getBreadcrumbsData(breadcrumbItem)}
          actions={showActions && <ActionButtons name={data?.companyName} disabled={data && data.isOwn} />}
        />
        {isLoading ? (
          <Spinner />
        ) : (
          <Routes>
            {getVendorRoutesByType(data?.type).map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
          </Routes>
        )}
      </Layout.Content>
    </Layout>
  )
}
