import { referenceItemSubLineLabels } from 'constant'
import { ReferenceSubLineFormAccessor } from 'types'

export const getSubLineAccessorTitle = (accessor: ReferenceSubLineFormAccessor): string => {
  return {
    [ReferenceSubLineFormAccessor.Asset]: referenceItemSubLineLabels.assetId,
    [ReferenceSubLineFormAccessor.AssetId]: referenceItemSubLineLabels.assetId,
    [ReferenceSubLineFormAccessor.PickupDate]: referenceItemSubLineLabels.pickupDate,
    [ReferenceSubLineFormAccessor.RedeliveryReferenceId]: referenceItemSubLineLabels.redeliveryReferenceId,
    [ReferenceSubLineFormAccessor.Status]: referenceItemSubLineLabels.status,
    [ReferenceSubLineFormAccessor.IsModificationRequired]: referenceItemSubLineLabels.isModificationRequired,
    [ReferenceSubLineFormAccessor.IsRepairRequired]: referenceItemSubLineLabels.isRepairRequired,
    [ReferenceSubLineFormAccessor.GateOutDate]: referenceItemSubLineLabels.gateOutDate,
    [ReferenceSubLineFormAccessor.ReceivedDate]: referenceItemSubLineLabels.receivedDate,
    [ReferenceSubLineFormAccessor.Condition]: referenceItemSubLineLabels.condition,
    [ReferenceSubLineFormAccessor.ContainerColor]: referenceItemSubLineLabels.containerColor,
    [ReferenceSubLineFormAccessor.PickupReferenceId]: referenceItemSubLineLabels.pickupReferenceId,
    [ReferenceSubLineFormAccessor.AssetNumber]: 'Asset Number',
  }[accessor]
}
