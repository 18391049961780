import { css, styled } from 'styled-components'

import { Container } from 'designSystem'

export const WrapperHeader = styled.thead<{
  $zebraStripedStyle?: boolean
  $withBorderRadius?: boolean
  $editableTable?: boolean
}>`
  & tr {
    position: relative;
    z-index: ${({ theme }) => theme.zIndexLevel[1]};
  }
  &&&& tr:first-child > *:first-child {
    border-start-start-radius: ${({ $withBorderRadius, theme }) =>
      $withBorderRadius ? theme.borderRadius.medium : '0'};
  }

  &&&& tr:last-child > *:last-child {
    border-start-end-radius: ${({ $withBorderRadius, theme }) => ($withBorderRadius ? theme.borderRadius.medium : '0')};
  }
  &&&&& th,
  &&&&& td {
    padding: ${({ theme, $editableTable }) =>
      $editableTable ? `${theme.spacers['1']} ${theme.spacers['5']}` : `${theme.spacers['1']} ${theme.spacers['4']}`};
    height: 32px;
    border-bottom: none;

    .ant-table-column-sorters {
      justify-content: unset;

      .ant-table-column-sorter-up,
      .ant-table-column-sorter-down {
        > svg {
          fill: transparent;
          stroke: ${({ theme }) => theme.colors.primary};
          stroke-width: 100px;
        }
      }

      .ant-table-column-sorter-up.active,
      .ant-table-column-sorter-down.active {
        > svg {
          fill: ${({ theme }) => theme.colors.primary};
        }
      }

      .ant-table-column-title {
        flex: unset;
      }
    }
    &.ant-table-column-sort,
    &.ant-table-column-has-sorters {
      background: ${({ theme }) => theme.colors.lines};
    }
    &.ant-table-cell:before {
      display: none;
    }
    &.ant-table-cell.hover-actions-column {
      position: static !important;
    }
  }
  th::before {
    display: none;
  }

  &.ant-table-thead .ant-table-cell {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.fontWeight.xl};
    background: ${({ theme }) => theme.colors.lines};
  }
  ${({ $zebraStripedStyle }) =>
    $zebraStripedStyle &&
    css`
      & tr {
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
      }
      th::before {
        display: block;
      }
      &.ant-table-thead .ant-table-cell {
      background: ${({ theme }) => theme.colors.white};
      }
      &&& th {
        background-color: ${({ theme }) => theme.colors.white};
        height: 40px;
    `}
`

export const BodyWrapper = styled.tbody`
  & .row-even td {
    background-color: ${({ theme }) => theme.colors.underline};
  }
`

export const Row = styled.tr`
  &&&& .hover-actions-column:not(:has(.disable-hover-effect)) {
    opacity: 0;
    padding: ${({ theme }) => `${theme.spacers['1']} ${theme.spacers['3']}`};
  }
  &&& td {
    padding: ${({ theme }) => `${theme.spacers['1']} ${theme.spacers['4']}`};
    height: 32px;

    &[style='text-align: right;'] {
      & .form-item > * > * {
        justify-content: end;
      }
    }

    & .form-item {
      width: 100%;

      > *:not(.form-item__error) {
        width: 100%;
      }

      .ant-input-affix-wrapper,
      .ant-select,
      .ant-picker {
        width: 100% !important;
        max-width: 200px;
      }
    }
  }
`

export const TableWrapper = styled.table`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeight.l};
  font-family: ${({ theme }) => theme.fontFamily.primary};

  &&&&& .index-column {
    padding-left: ${({ theme }) => theme.spacers['4']};
    padding-right: 0;

    &:not(th) {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`

export const TableComponentWrapper = styled(Container)<{
  $withBorderRadius?: boolean
  $withoutHeader?: boolean
  $zebraStripedStyle?: boolean
  $editableTable?: boolean
}>`
  ${({ $withoutHeader }) =>
    $withoutHeader &&
    css`
      thead {
        display: none;
      }
    `}
  ${({ $withBorderRadius }) =>
    $withBorderRadius &&
    css`
      .ant-table-content {
        border-radius: ${({ theme }) => `${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0`};
      }
    `}

  &&& th[style="text-align: right;"] > * {
    width: 100%;
    justify-content: flex-end;
  }

  &&& .ant-table-cell.ant-table-row-expand-icon-cell {
    padding: 0;
    margin: 0;
    height: 32px;
    border: none;
    display: flex;
    align-items: center;
  }

  && .ant-table-row-selected .ant-table-cell {
    background-color: ${({ theme }) => theme.colors.accent[80]};
  }
  && .ant-table-expanded-row-fixed {
    padding: 0;
    margin: 0;
    max-width: 100%;
  }
  && .ant-table-expanded-row > .ant-table-cell {
    padding: 0;

    & .row-even td {
      background-color: ${({ theme }) => theme.colors.white};
    }

    & .ant-table-thead .ant-table-cell {
      color: ${({ theme }) => theme.colors.secondary};
    }

    & .ant-table-tbody {
      & tr:last-child td {
        border-bottom: none;
      }
    }

    .ant-table-thead {
      tr:first-child {
        box-shadow: none;
      }
    }
  }
  && .ant-table-expanded-row > .ant-table-cell {
    border-left: 5px solid ${({ theme }) => theme.colors.accent[400]};
  }
  && .ant-table-expanded-row > .ant-table-cell .ant-table-thead .ant-table-cell {
    background: ${({ theme }) => theme.colors.white};
    border: none;
    padding: ${({ theme, $editableTable }) =>
      $editableTable ? `${theme.spacers['1']} ${theme.spacers['5']}` : `${theme.spacers['1']} ${theme.spacers['4']}`};
  }
  &&&& td {
    transition: unset;
    &.hover-actions-column::after {
      box-shadow: none;
    }
  }
  &&& tr.ant-table-row {
    &-clickable {
      cursor: pointer;
    }

    &:hover {
      background: ${({ theme }) => theme.colors.underline};
      td {
        background: ${({ theme }) => theme.colors.underline};
      }
      td.hover-actions-column {
        opacity: 1;
        background: linear-gradient(270deg, #f4f5f7 0%, #f4f5f7 67.24%, rgba(244, 245, 247, 0) 100%);
      }
    }
    > .ant-table-cell-row-hover {
      background: ${({ theme }) => theme.colors.white};
    }
  }
  &&& td {
    border-bottom: none;
  }
  &&& td.ant-table-column-sort {
    background: inherit;
  }
  &&& .ant-table-cell .ant-table-cell-with-append {
    color: ${({ theme }) => theme.colors.secondary};
  }

  ${({ $zebraStripedStyle }) =>
    $zebraStripedStyle &&
    css`
      && tr.ant-table-row {
        &:hover {
          > td {
            background: ${({ theme }) => theme.colors.underline};
          }
        }
      }
      &&& td {
        border-bottom: ${({ theme }) => `1px solid ${theme.colors.lines}`};
      }
      &&& .ant-table-cell .ant-table-cell-with-append {
        color: ${({ theme }) => theme.colors.primary};
      }
      .ant-table-content {
        border: 1px solid ${({ theme }) => theme.colors.lines};
        border-radius: ${({ theme }) => `${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0`};
      }
    `}
`

export const ExpandedWrapper = styled.div`
  border-left: 5px solid transparent;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
