import { Link } from 'react-router-dom'

import { envVariables, route } from 'constant'

import { Container, Icon, Typography } from 'designSystem'

export const headerNavigationItems = [
  {
    label: (
      <Container ai="center" gap={4}>
        Master Data <Icon icon="arrowDown" size={16} />
      </Container>
    ),
    key: 'master-data',
    children: [
      {
        label: (
          <Link to={route.vendors}>
            <Typography color="inherit" size="xs" fontWeight="l">
              Vendors
            </Typography>
          </Link>
        ),
        key: route.vendors,
      },
      {
        label: (
          <Link to={route.items}>
            <Typography color="inherit" size="xs" fontWeight="l">
              Items
            </Typography>
          </Link>
        ),
        key: route.items,
      },
      {
        label: (
          <Link to={route.locationCodes}>
            <Typography color="inherit" size="xs" fontWeight="l">
              Locations
            </Typography>
          </Link>
        ),
        key: route.locationCodes,
      },
      {
        label: (
          <Link to={route.truckCodes}>
            <Typography color="inherit" size="xs" fontWeight="l">
              Truck Codes
            </Typography>
          </Link>
        ),
        key: route.truckCodes,
      },
    ],
  },
  {
    label: <Link to={route.assets}>Assets</Link>,
    key: route.assets,
  },
  //TODO temporary hide
  // {
  //   label: <Link to={route.globalInventory}>Global Inventory</Link>,
  //   key: route.globalInventory,
  // },
  {
    label: <Link to={route.pos}>POs</Link>,
    key: route.pos,
  },
  {
    label: <Link to={route.customers}>Customers</Link>,
    key: route.customers,
  },
  {
    label: <Link to={route.salesOrders}>Sales Orders</Link>,
    key: route.salesOrders,
  },
  {
    label: <Link to={route.references}>References</Link>,
    key: route.references,
  },
  ...(envVariables.env.isDevelopment
    ? [
        {
          label: <Link to={route.logisticsEvents}>Logistics</Link>,
          key: route.logistics,
        },
      ]
    : []),
]
