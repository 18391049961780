import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'

import { PurchaseOrder } from 'api'
import type { TableField, TableSortingOrder } from 'hooks'
import {
  getPaymentTermLabel,
  getPurchaseOrderDepartmentLabel,
  getPurchaseOrderTypeLabel,
  hashRoute,
  route,
} from 'constant'
import { formatDate, getFormattedPriceWithCurrency } from 'utils'
import theme from 'styles/theme'

import { Container, Icon, ReactLink, Typography } from 'designSystem'
import { PurchaseOrderStatusTag, TableColumnKey, TableColumnsType } from 'components'

import { Accessor } from '../types/accessor'
import * as Styled from '../elements/styles'

import { getAccessorTitle } from './getAccessorTitle'

export const getTableColumns = (
  getFieldSorter: (field: TableField) => TableSortingOrder,
  offsetCount: number,
): TableColumnsType<PurchaseOrder> => {
  return [
    {
      title: getAccessorTitle(Accessor.Index),
      key: TableColumnKey.Index,
      ellipsis: true,
      render: (value, record, index) => offsetCount + index + 1,
    },
    {
      title: getAccessorTitle(Accessor.Number),
      key: Accessor.Number,
      dataIndex: Accessor.Number,
      width: 150,
      ellipsis: true,
    },
    {
      title: getAccessorTitle(Accessor.Status),
      key: Accessor.Status,
      dataIndex: Accessor.Status,
      width: 150,
      ellipsis: true,
      render: (status) => status && <PurchaseOrderStatusTag status={status} />,
    },
    {
      title: getAccessorTitle(Accessor.VendorName),
      key: Accessor.VendorName,
      dataIndex: Accessor.VendorName,
      width: 200,
      sorter: true,
      sortOrder: getFieldSorter('vendorName'),
      ellipsis: true,
      render: (vendorName: string, record) => {
        return (
          <Link to={hashRoute.po(record.id)}>
            <Container ai="center" gap={4}>
              <Typography color="primary" size="xs" fontWeight="l" lineEllipsis={1}>
                {vendorName}
              </Typography>
              {record.isVendorFavorite && (
                <Icon
                  style={{ fill: theme.colors.warning[400], width: 14 }}
                  color={theme.colors.warning[400]}
                  icon="star"
                />
              )}
            </Container>
          </Link>
        )
      },
    },
    {
      title: getAccessorTitle(Accessor.ExpectedDate),
      key: Accessor.ExpectedDate,
      dataIndex: Accessor.ExpectedDate,
      width: 150,
      sorter: true,
      sortOrder: getFieldSorter('expectedDate'),
      ellipsis: true,
      render: (expectedDate) => (expectedDate ? formatDate(expectedDate) : '-'),
    },
    {
      title: getAccessorTitle(Accessor.Department),
      key: Accessor.Department,
      dataIndex: Accessor.Department,
      width: 150,
      ellipsis: true,
      render: (department) => getPurchaseOrderDepartmentLabel(department),
    },
    {
      title: getAccessorTitle(Accessor.Type),
      key: Accessor.Type,
      dataIndex: Accessor.Type,
      width: 150,
      ellipsis: true,
      render: (type) => getPurchaseOrderTypeLabel(type),
    },
    {
      title: getAccessorTitle(Accessor.LocationDepot),
      key: Accessor.LocationDepot,
      dataIndex: Accessor.LocationDepot,
      width: 150,
      ellipsis: true,
      render: (locationDepot: string, entity: PurchaseOrder) => {
        const { code } = entity?.depot || {}
        const locationCode = entity?.locationCode?.code
        const tooltipTitle = `${locationCode || ''}\n${code || ''}`
        if (locationCode || code) {
          return (
            <Tooltip title={tooltipTitle} placement="topLeft" overlayInnerStyle={{ whiteSpace: 'pre-line' }}>
              <Container display="flex" fd="column">
                <Styled.LocationDepotTypography fontWeight="l" size="xs" lineEllipsis={1}>
                  {locationCode ? locationCode : '-'}
                </Styled.LocationDepotTypography>
                <Styled.LocationDepotTypography fontWeight="l" size="xs" color="secondary" lineEllipsis={1}>
                  {code ? code : '-'}
                </Styled.LocationDepotTypography>
              </Container>
            </Tooltip>
          )
        }

        return '-'
      },
    },
    {
      title: getAccessorTitle(Accessor.PaymentTerms),
      key: Accessor.PaymentTerms,
      dataIndex: Accessor.PaymentTerms,
      width: 150,
      ellipsis: true,
      render: (paymentTerms) => getPaymentTermLabel(paymentTerms),
    },
    {
      title: getAccessorTitle(Accessor.ExpirationDate),
      key: Accessor.ExpirationDate,
      dataIndex: Accessor.ExpirationDate,
      width: 150,
      sorter: true,
      sortOrder: getFieldSorter('expirationDate'),
      ellipsis: true,
      render: (expirationDate) => formatDate(expirationDate, 'Standard'),
    },
    {
      title: getAccessorTitle(Accessor.Qty),
      key: Accessor.Qty,
      dataIndex: Accessor.Qty,
      width: 100,
      ellipsis: true,
      render: (value) => value || 0,
    },
    {
      title: getAccessorTitle(Accessor.ItemsTotal),
      key: Accessor.ItemsTotal,
      dataIndex: Accessor.ItemsTotal,
      width: 150,
      ellipsis: true,
      render: (itemsTotal) => {
        const itemsTotalFormatted = getFormattedPriceWithCurrency(itemsTotal)
        return (
          <Typography size="xs" fontWeight="xl">
            {itemsTotalFormatted}
          </Typography>
        )
      },
    },
    {
      title: getAccessorTitle(Accessor.Notes),
      key: Accessor.Notes,
      dataIndex: Accessor.Notes,
      width: 200,
      ellipsis: true,
      render: (notes) => notes || '-',
    },
    {
      title: getAccessorTitle(Accessor.ExternalPoNotes),
      key: Accessor.ExternalPoNotes,
      dataIndex: Accessor.ExternalPoNotes,
      width: 200,
      ellipsis: true,
      render: (notes) => notes || '-',
    },
    {
      title: getAccessorTitle(Accessor.CreatedBy),
      key: Accessor.CreatedBy,
      dataIndex: Accessor.CreatedBy,
      width: 200,
      ellipsis: true,
      render: (createdBy) => {
        return (
          <ReactLink to={`${route.userManagement}/${createdBy.id}`}>
            <Typography color="inherit" size="xs" fontWeight="l" lineEllipsis={1}>
              {`${createdBy.firstName} ${createdBy.lastName}`}
            </Typography>
          </ReactLink>
        )
      },
    },
  ]
}
