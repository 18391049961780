import { FC, useCallback } from 'react'

import { useFormContext, useTruckCodes } from 'hooks'
import { currency, distanceUnits } from 'constant'

import { Container, FormItem, InputNumber } from 'designSystem'
import { FormItemSelectSingleAsync } from 'components'

export const OptionThree: FC = () => {
  const { trigger } = useFormContext()

  const handleMilesRangeChange = useCallback(
    (field: string) => () => {
      if (field === 'option.fromMiles') {
        trigger?.('option.toMiles')
      } else {
        trigger?.('option.fromMiles')
      }
    },
    [trigger],
  )

  return (
    <Container gap={8} ai="center">
      <FormItemSelectSingleAsync
        name="option.truckCodeId"
        getItems={useTruckCodes}
        width={120}
        placeholder="Truck Code"
      />
      <FormItem name="option.fromMiles">
        <InputNumber
          align="right"
          placeholder="From"
          onChange={handleMilesRangeChange('option.fromMiles')}
          suffix={distanceUnits.Mi}
          suffixWidth={24}
          width={120}
        />
      </FormItem>
      -
      <FormItem name="option.toMiles">
        <InputNumber
          align="right"
          placeholder="To"
          onChange={handleMilesRangeChange('option.toMiles')}
          suffix={distanceUnits.Mi}
          suffixWidth={24}
          width={120}
        />
      </FormItem>
      ,
      <FormItem name="option.flatFeePrice">
        <InputNumber placeholder="Price" precision={2} prefix={currency} width={120} />
      </FormItem>
    </Container>
  )
}
