import { css, styled } from 'styled-components'

import type { SearchType } from './types/searchType'

interface SearchContainerProps {
  width?: number
  searchType?: SearchType
  size?: 'middle' | 'large'
}

export const SearchContainer = styled.div<SearchContainerProps>`
  width: ${({ width }) => width}px;
  .ant-input-clear-icon {
    display: flex;
  }

  .ant-space-compact {
    width: 100%;
    ${({ searchType }) =>
      searchType === 'type' &&
      css`
        & > div:first-child {
          width: 60%;
        }

        & > div:last-child {
          width: 40%;
          left: -1px;
        }
      `};

    .ant-input-affix-wrapper {
      &:hover {
        border-color: ${({ theme }) => theme.colors.accent[400]};
      }
    }
  }
  ${({ size, theme }) => {
    if (size === 'large') {
      return css`
        .ant-select-selector {
          .ant-select-selection-item span {
            font-size: ${theme.fontSize.s};
          }
        }
      `
    }
  }}
`

export const DropdownMenu = styled.div<Pick<SearchContainerProps, 'size'>>`
  .ant-select-item-option-content span {
    font-size: ${({ size, theme }) => (size === 'large' ? `${theme.fontSize.s}` : `${theme.fontSize.xs}`)};
  }
`
