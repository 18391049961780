import { FC, Key, useCallback, useEffect, useState } from 'react'

import { PurchaseOrderItem, PurchaseOrderType } from 'api'
import { useFieldArray, useFormContext, useTable, useWatch } from 'hooks'
import { PurchaseOrderFormAccessor } from 'types'
import { getFormattedPriceWithCurrency } from 'utils'

import { Button, Container, Typography } from 'designSystem'
import { Table } from 'components'
import { ItemAssets } from 'modules/purchaseOrders/types/itemAssets'
import { getTableColumns } from 'modules/purchaseOrders/utils/getTableColumns'

import { getItemsTotalAmount } from './utils/getItemsTotalAmount'
import { prepareDefaultRowData } from './utils/prepareDefaultRowData'
import { PurchaseOrderRow } from './types/purchaseOrderRow'
import { ExpandableNewRow } from '../../../../elements/ExpandableNewRow'

export const PurchaseOrderItems: FC = () => {
  const { TableContextProvider } = useTable({})
  const [expandedRowKeys, setExpandedRowKeys] = useState<(string | number)[] | undefined>(undefined)
  const { getValues, watch, setValue, unregister } = useFormContext()
  const [itemAssets, setItemAssets] = useState<ItemAssets>({})
  const { fields: dataSource, append, remove } = useFieldArray({ name: 'items' })
  const currentItemsValues = getValues(PurchaseOrderFormAccessor.Items) || []
  const type = useWatch({ name: PurchaseOrderFormAccessor.Type })
  const vendorId = useWatch({ name: PurchaseOrderFormAccessor.VendorId })

  useEffect(() => {
    if (type && type !== PurchaseOrderType.Standard) {
      const currentItems: PurchaseOrderItem[] | undefined = getValues(PurchaseOrderFormAccessor.Items)
      const purchaseOrderType =
        type === PurchaseOrderType.VendorRelease
          ? PurchaseOrderFormAccessor.RedeliveryNumber
          : PurchaseOrderFormAccessor.VendorReleaseNumber

      currentItems?.forEach((item, itemIndex) => {
        item.subLines?.forEach((subLine, subLineIndex) => {
          unregister(
            `${PurchaseOrderFormAccessor.Items}.${itemIndex}.${PurchaseOrderFormAccessor.SubLines}.${subLineIndex}.${purchaseOrderType}`,
          )
        })
      })
    }
  }, [type, setValue, getValues, unregister])

  const isPOTypeStandard = type === PurchaseOrderType.Standard

  const itemsCount = dataSource.length

  const insertRow = useCallback(() => {
    append(prepareDefaultRowData(itemsCount), { shouldFocus: false })
  }, [append, itemsCount])

  const handleRemove = useCallback(
    (index: number) => {
      remove(index)
    },
    [remove],
  )

  const itemsTotalAmount = getItemsTotalAmount(currentItemsValues)

  const columns = getTableColumns({
    watch,
    onDelete: handleRemove,
    setExpandedRowKeys,
    setItemAssets,
    skuAsset: itemAssets,
    itemsCount,
    type,
    isAddNew: false,
    setValue,
  })

  useEffect(() => {
    if (!itemsCount) {
      insertRow()
    }
  }, [itemsCount, insertRow])

  return (
    <>
      <Container mb={4}>
        <Typography fontWeight="xl" size="l">
          Items Details
        </Typography>
      </Container>
      <TableContextProvider>
        <Table<PurchaseOrderRow>
          scroll={{ x: 1350 }}
          dataSource={dataSource as PurchaseOrderRow[]}
          columns={columns as PurchaseOrderRow[]}
          editableTable
          expandable={{
            ...(!isPOTypeStandard && {
              expandedRowKeys,
              defaultExpandAllRows: true,
              expandedRowRender: (_, index: number) => <ExpandableNewRow name={`items.${index}`} vendorId={vendorId} />,
              rowExpandable: (record: PurchaseOrderRow) => {
                return !isPOTypeStandard && !!itemAssets[record.id]?.containAsset
              },
              onExpandedRowsChange: (expandedRows: readonly Key[]) => {
                setExpandedRowKeys([...(expandedRows as string[])])
              },
            }),
          }}
        />
        <Container ai="center" jc="space-between" mt={4}>
          <Button icon="plus" type="link" onClick={insertRow}>
            Add
          </Button>
          {itemsTotalAmount && (
            <Typography fontWeight="xl" size="xs">
              Total {getFormattedPriceWithCurrency(itemsTotalAmount)}
            </Typography>
          )}
        </Container>
      </TableContextProvider>
    </>
  )
}
