import { api, ApiQueryParams, EntityId, VendorResalePriceOverwriteRule, VendorType } from 'api'
import { Pagination } from 'types'

import { getTypeUrlByVendorType } from 'modules/vendors/utils/getTypeUrlByVendorType'

import { ResaleRulePayload } from './types/resaleRuleCreateForm'

export interface GetResaleRuleResponse extends Pagination {
  data?: VendorResalePriceOverwriteRule[]
}

export type GetEquipmentQueryParams = ApiQueryParams & {
  vendorId: EntityId
  type: VendorType
}

export const getResaleRules = ({
  vendorId,
  type,
  ...params
}: GetEquipmentQueryParams): Promise<GetResaleRuleResponse> => {
  const typeUrl = getTypeUrlByVendorType(type)

  return api({
    method: 'get',
    url: `/api/v1/vendors/${vendorId}/types/${typeUrl}/rules`,
    params: {
      join: ['vendor', 'item||id,sku'],
      ...params,
    },
  })
}

export const postResaleRule = ({
  vendorId,
  data,
  type,
}: {
  vendorId?: EntityId
  data?: ResaleRulePayload
  type: VendorType
}): Promise<GetResaleRuleResponse> => {
  const typeUrl = getTypeUrlByVendorType(type)

  return api({
    method: 'post',
    url: `/api/v1/vendors/${vendorId}/types/${typeUrl}/rules`,
    data,
    params: {
      join: ['truckCode'],
    },
  })
}

export const patchResaleRule = ({
  vendorId,
  resaleRuleId,
  data,
  type,
}: {
  vendorId?: EntityId
  resaleRuleId?: EntityId
  data?: ResaleRulePayload
  type: VendorType
}): Promise<GetResaleRuleResponse> => {
  const typeUrl = getTypeUrlByVendorType(type)

  return api({
    method: 'patch',
    url: `/api/v1/vendors/${vendorId}/types/${typeUrl}/rules/${resaleRuleId}`,
    data,
    params: {
      join: ['truckCode'],
    },
  })
}

export const deleteResaleRule = ({
  vendorId,
  resaleRuleId,
  type,
}: {
  vendorId?: EntityId
  resaleRuleId?: EntityId
  type: VendorType
}): Promise<void> => {
  const typeUrl = getTypeUrlByVendorType(type)

  return api({
    method: 'delete',
    url: `/api/v1/vendors/${vendorId}/types/${typeUrl}/rules/${resaleRuleId}`,
  })
}
