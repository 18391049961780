import {
  cloneElement,
  CSSProperties,
  FC,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Popover } from 'antd'
import { SetFieldValue } from 'react-hook-form'
import isEqual from 'react-fast-compare'

import { useFormContext } from 'hooks/useForm'
import { typedMemo } from 'types'
import { getFormattedPrice, getObjectValueByKey } from 'utils'

import { ConfirmationButtons, EditInputNumber, EditInputNumberProps, EditView } from 'designSystem'

import * as Styled from './styles'

export interface FormItemEditInputNumberProps extends Omit<EditInputNumberProps, 'name' | 'error' | 'onChange'> {
  name: string
  onChange: SetFieldValue<any>
  onConfirm?: (currentValue: number | null | undefined) => void
  // EditView props
  addLink?: boolean
  linkHref?: string
  align?: CSSProperties['textAlign']
  withConfirmation?: boolean
  suffix?: ReactNode
  prefix?: ReactNode
  isFieldViewOnly?: boolean
  popover?: ReactElement
}

export const FormItemEditInputNumberBase: FC<FormItemEditInputNumberProps> = forwardRef(
  (
    {
      disabled,
      size,
      onBlur,
      onChange,
      name,
      value,
      addLink,
      linkHref,
      align,
      onConfirm,
      suffix,
      prefix,
      withConfirmation = false,
      precision,
      isFieldViewOnly,
      onPressEnter,
      popover,
      ...props
    },
    ref,
  ) => {
    const [isEdit, setIsEdit] = useState(false)
    const [isOpenPopover, setIsOpenPopover] = useState(false)
    const {
      getFieldState,
      setValue,
      getValues,
      trigger,
      formState: { defaultValues },
      triggerSubmit,
    } = useFormContext()

    const defaultValue = getObjectValueByKey(name, defaultValues)
    const formValue = getValues(name)

    const [currentValue, setCurrentValue] = useState<number | undefined | null>(null)

    const error = getFieldState(name)?.error?.message

    const handleCancelConfirmation = useCallback(() => {
      setValue(name, formValue)
      setCurrentValue(formValue)
      setIsEdit(false)
    }, [name, formValue, setCurrentValue, setValue])

    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        if (popover) {
          setValue(name, defaultValue)
          return
        }
        if (error) {
          setValue(name, defaultValue)
        } else if (withConfirmation) {
          handleCancelConfirmation()
        }

        trigger(name)
        setIsEdit(false)
        onBlur?.(event)
      },
      [name, withConfirmation, error, onBlur, handleCancelConfirmation, setValue, defaultValue, trigger, popover],
    )

    const handleChange = useCallback(
      (value: number | undefined | null) => {
        if (withConfirmation) {
          setCurrentValue(value)
          return
        }
        onChange?.(value)
      },
      [onChange, withConfirmation],
    )

    const handleViewClick = useCallback(() => {
      setIsEdit(true)
    }, [setIsEdit])

    const handleConfirmConfirmation = useCallback(() => {
      setValue(name, currentValue)
      trigger(name)
      setIsEdit(false)
      onConfirm?.(currentValue)
    }, [currentValue, name, onConfirm, setValue, trigger])

    const isConfirmButtonDisabled = currentValue === formValue || (formValue === undefined && currentValue === null)

    const handlePressEnter = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        setIsEdit(false)
        onPressEnter?.(event)
        if (withConfirmation) {
          handleConfirmConfirmation()
        } else {
          triggerSubmit?.()
        }
      },
      [onPressEnter, triggerSubmit, withConfirmation, handleConfirmConfirmation],
    )

    useEffect(() => {
      if (!isEqual(formValue, currentValue)) {
        setCurrentValue(formValue)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValue])

    const onPopoverCancel = useCallback(() => {
      setIsEdit(false)
      setIsOpenPopover(false)
      onChange?.(defaultValue)
    }, [onChange, defaultValue])

    const onPopoverConfirm = useCallback(() => {
      if (error) {
        return
      }

      onChange?.(value)
      onConfirm?.(value)
      setIsEdit(false)
      setIsOpenPopover(false)
    }, [onConfirm, error, onChange, value])

    return (
      <>
        {isEdit ? (
          <Popover
            placement="bottomRight"
            destroyTooltipOnHide
            open={withConfirmation && isEdit}
            arrow={false}
            content={
              <ConfirmationButtons
                onConfirm={handleConfirmConfirmation}
                onCancel={handleCancelConfirmation}
                disabled={isConfirmButtonDisabled}
              />
            }
          >
            <Styled.InputWrapper size={size}>
              <EditInputNumber
                {...props}
                onPressEnter={handlePressEnter}
                error={error}
                align={align}
                name={name}
                value={withConfirmation ? currentValue : value}
                autoFocus
                onBlur={handleBlur}
                onChange={handleChange}
                ref={ref}
                size={size}
                disabled={disabled}
                suffix={suffix}
                prefix={prefix}
                precision={precision}
              />
              {popover &&
                cloneElement(popover, {
                  key: isOpenPopover ? 'open' : 'closed',
                  onConfirm: onPopoverConfirm,
                  onCancel: onPopoverCancel,
                  isOpen: isOpenPopover,
                  disabled: !!error || defaultValue === value,
                })}
            </Styled.InputWrapper>
          </Popover>
        ) : (
          <EditView
            prefix={prefix}
            align={align}
            suffix={suffix}
            as={addLink ? 'link' : undefined}
            size={size}
            disabled={isFieldViewOnly ? false : disabled}
            error={!!error}
            href={linkHref}
            value={precision ? getFormattedPrice(value, precision) : value}
            onClick={handleViewClick}
          />
        )}
      </>
    )
  },
)

export const FormItemEditInputNumber = typedMemo(FormItemEditInputNumberBase)
