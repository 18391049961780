import { FC, useCallback, useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { PurchaseOrderType } from 'api'
import { useSalesOrders } from 'hooks'
import { purchaseOrderLabels } from 'constant'
import { PurchaseOrderFormAccessor } from 'types'

import { Checkbox, Container, FormItem, Spacer, TextArea } from 'designSystem'
import { FormItemSelectSingleAsync } from 'components'
import { PurchaseOrderDeliveryType } from 'modules/purchaseOrders/types'

import { PurchaseOrderDeliveryTypeOptions } from 'modules/purchaseOrders/constants/purchaseOrderDeliveryTypeOptions'

import { WarehouseSelect } from '../WarehouseSelect'

export const AdditionalInformation: FC = () => {
  const { unregister, setValue } = useFormContext()
  const type = useWatch({ name: PurchaseOrderFormAccessor.Type })
  const deliveryType = useWatch({ name: PurchaseOrderFormAccessor.DeliveryType })
  const isStandardType = type === PurchaseOrderType.Standard
  const isDeliveryTypeWarehouse = deliveryType === PurchaseOrderDeliveryType.Warehouse

  const handleChangeDeliveryType = useCallback(
    (type: PurchaseOrderDeliveryType) => (value: boolean) => {
      setValue(PurchaseOrderFormAccessor.DeliveryType, value ? type : undefined)
    },
    [setValue],
  )

  useEffect(() => {
    if (!isStandardType) {
      unregister([PurchaseOrderFormAccessor.DeliveryType, PurchaseOrderFormAccessor.WarehouseId])
    }
  }, [isStandardType, unregister])

  useEffect(() => {
    if (!isDeliveryTypeWarehouse) {
      unregister(PurchaseOrderFormAccessor.WarehouseId)
    }
  }, [isDeliveryTypeWarehouse, unregister])
  return (
    <>
      <FormItem name={PurchaseOrderFormAccessor.Notes} label={purchaseOrderLabels.notes}>
        <TextArea size="small" placeholder="Type notes" autoSize={{ minRows: 3 }} />
      </FormItem>
      <Spacer mb={3} />
      <FormItem name={PurchaseOrderFormAccessor.ExternalPoNotes} label={purchaseOrderLabels.externalPoNotes}>
        <TextArea size="small" placeholder="Type notes" autoSize={{ minRows: 3 }} />
      </FormItem>
      <Spacer mb={3} />
      {!isStandardType && (
        <FormItemSelectSingleAsync
          placeholder="Start Typing"
          name={PurchaseOrderFormAccessor.SalesOrderId}
          label={purchaseOrderLabels.salesOrderId}
          getItems={useSalesOrders}
        />
      )}
      {isStandardType && (
        <>
          <Container>
            {PurchaseOrderDeliveryTypeOptions.map(({ label, value, disabled }) => (
              <Checkbox
                key={value}
                disabled={disabled}
                checked={deliveryType === value}
                onChange={handleChangeDeliveryType(value)}
              >
                {label}
              </Checkbox>
            ))}
          </Container>
          {isDeliveryTypeWarehouse && (
            <>
              <Spacer mb={3} />
              <WarehouseSelect />
            </>
          )}
        </>
      )}
    </>
  )
}
