import { FC } from 'react'

import { SalesOrder } from 'api'

import { Tabs } from 'designSystem'

import { getSalesOrderTabs } from './utils/getSalesOrderTabs'

interface SalesOrderTabsProps {
  data?: SalesOrder
}
export const SalesOrderTabs: FC<SalesOrderTabsProps> = ({ data }) => {
  if (!data) {
    return null
  }

  const tabs = getSalesOrderTabs(data)

  return <Tabs sticky size="small" items={tabs} isTransparentView />
}
