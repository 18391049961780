import { api, EntityId, TruckCode } from 'api'

import { TruckCodePayload } from '../../types/truckCodePayload'

export interface UpdateTruckCodePayload {
  id?: EntityId
  data: Partial<TruckCodePayload>
}

export const getTruckCodeById = (id?: EntityId): Promise<TruckCode> => {
  return api({
    method: 'get',
    url: `/api/v1/truck-codes/${id}`,
  })
}

export const patchTruckCode = ({ id, data }: UpdateTruckCodePayload): Promise<TruckCode> => {
  return api({
    method: 'patch',
    url: `/api/v1/truck-codes/${id}`,
    data,
  })
}

export const deleteTruckCode = (id?: EntityId): Promise<void> => {
  return api({
    method: 'delete',
    url: `/api/v1/truck-codes/${id}`,
  })
}
