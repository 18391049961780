import { FC, useCallback } from 'react'

import { useForm } from 'hooks'

import { Button, Container, FormItem, Input, Typography } from 'designSystem'

import { validationSchema } from './utils/validationSchema'
import { BillingReferenceForm } from '../../types/billingReferenceForm'

interface LinkForBillNumberProps {
  onClose: () => void
  onSubmit: (data: string) => void
}

export const LinkForBillNumber: FC<LinkForBillNumberProps> = ({ onClose, onSubmit }) => {
  const { Form, handleSubmit } = useForm<BillingReferenceForm>({
    validationSchema,
  })

  const onSubmitData = useCallback(
    (data: BillingReferenceForm) => {
      onSubmit(data.billingReference)
      onClose()
    },
    [onClose, onSubmit],
  )

  return (
    <Form>
      <Container display="flex" fd="column" jc="space-between">
        <Container display="flex" fd="column" gap={16}>
          <Typography size="s" fontWeight="l">
            You are about to mark item as Paid, please share bill number to confirm
          </Typography>
          <Container display="flex" fd="column">
            <FormItem name="billingReference" label="Link">
              <Input placeholder="Type billing reference" />
            </FormItem>
          </Container>
        </Container>
        <Container display="flex" jc="space-between" gap={8} mt={7}>
          <Button type="default" onClick={onClose}>
            Cancel
          </Button>
          <Button type="primary" fullwidth onClick={handleSubmit(onSubmitData)}>
            Confirm
          </Button>
        </Container>
      </Container>
    </Form>
  )
}
