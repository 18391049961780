import { AssetStatus } from 'api'

export const getItemsAssetsListProps = (
  itemId?: number | null,
  vendorReleaseId?: number | null,
  depotSettingId?: number | null,
) => ({
  queryParams: {
    status: { $in: [AssetStatus.Available, AssetStatus.VendorAccount] },
    ...(itemId && { 'items.id': { $eq: itemId } }),
    ...(vendorReleaseId && { 'referenceItemSubLines.referenceItem.referenceId': { $eq: vendorReleaseId } }),
    ...(depotSettingId && { vendorDepotId: { $eq: depotSettingId } }),
  },
  join: vendorReleaseId ? ['items||id', 'referenceItemSubLines', 'referenceItemSubLines.referenceItem'] : undefined,
})
