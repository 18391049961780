import { FC, useCallback } from 'react'
import { Col, Row } from 'antd'

import { PurchaseOrderReferenceType } from 'api'
import { useForm } from 'hooks'
import { PurchaseOrderFormAccessor } from 'types'

import { Button, Checkbox, Container, FormItem, Input, Modal, ModalProps, Typography } from 'designSystem'

import { validationSchema } from '../../utils/validationSchema'
import { CreateNewReferenceNumberPayload } from '../../types/createNewReferenceNumberPayload'

interface CreateNewReferenceNumberModalProps extends Omit<ModalProps, 'onOk' | 'onCancel'> {
  type: PurchaseOrderReferenceType
  toggleCreateReferenceNumberModal: () => void
  onConfirm: (data: CreateNewReferenceNumberPayload) => void
  isLoading: boolean
}

export const CreateNewReferenceNumberModal: FC<CreateNewReferenceNumberModalProps> = ({
  width = 440,
  title = 'Create Reference Number',
  open,
  footer = null,
  onConfirm,
  toggleCreateReferenceNumberModal,
  isLoading,
  type,
  ...modalProps
}) => {
  const { Form, handleSubmit, reset, watch, setValue } = useForm<CreateNewReferenceNumberPayload>({
    validationSchema,
    mode: 'onChange',
  })
  const isRedeliveryReferenceNumber = type === PurchaseOrderReferenceType.Redelivery
  const shouldGenerateReferenceNumber = watch(PurchaseOrderFormAccessor.ShouldGenerateReferenceNumber) || false

  const handleToggleCheckbox = useCallback(
    (value: boolean) => {
      if (value) {
        setValue(PurchaseOrderFormAccessor.ReferenceNumber, undefined)
      }
    },
    [setValue],
  )

  return (
    <Modal
      destroyOnClose
      {...modalProps}
      afterClose={reset}
      open={open}
      width={width}
      title={title}
      footer={footer}
      onCancel={toggleCreateReferenceNumberModal}
    >
      <Form onSubmit={handleSubmit(onConfirm)}>
        <Row gutter={8} align="bottom">
          <Col span={isRedeliveryReferenceNumber ? 12 : 24}>
            <FormItem name={PurchaseOrderFormAccessor.ReferenceNumber} label="Reference Number">
              <Input placeholder="Type Reference Number" disabled={shouldGenerateReferenceNumber} />
            </FormItem>
          </Col>
          {isRedeliveryReferenceNumber && (
            <Col span={12}>
              <Container ai="center" gap={4} mb={3} ml={3}>
                <FormItem name={PurchaseOrderFormAccessor.ShouldGenerateReferenceNumber}>
                  <Checkbox onChange={handleToggleCheckbox} />
                </FormItem>
                <Typography size="xs" fontWeight="l">
                  Generate
                </Typography>
              </Container>
            </Col>
          )}
        </Row>
        <Container display="flex" jc="space-between" gap={8} mt={6}>
          <Button type="default" onClick={toggleCreateReferenceNumberModal}>
            Cancel
          </Button>
          <Button loading={isLoading} type="primary" fullwidth onClick={handleSubmit(onConfirm)}>
            Confirm
          </Button>
        </Container>
      </Form>
    </Modal>
  )
}
