import { FC } from 'react'
import { Outlet, Route, Routes, useLocation } from 'react-router-dom'
import { Layout } from 'antd'

import { Sidebar } from 'components'

import { globalSettingsRoutes, globalSettingsSidebarItems, routesWithoutSidebar } from './constants'

export const GlobalSettings: FC = () => {
  const location = useLocation()
  const sidebarItems = routesWithoutSidebar.includes(location.pathname) ? [] : globalSettingsSidebarItems

  return (
    <Layout>
      {!!sidebarItems.length && <Sidebar sidebarItems={sidebarItems} />}
      <Outlet />
      <Layout.Content>
        <Routes>
          {globalSettingsRoutes.map((globalSettingRoute) => (
            <Route key={globalSettingRoute.path} path={globalSettingRoute.path} element={globalSettingRoute.element} />
          ))}
        </Routes>
      </Layout.Content>
    </Layout>
  )
}
