import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { route } from 'constant'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { CreateTruckCodePayload, postTruckCode } from '../queryApi'

export const useTruckCodeCreate = () => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const navigate = useNavigate()

  const { mutate, ...rest } = useMutation({
    mutationFn: postTruckCode,
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.TruckCodeCreate)

      queryClient.setQueryData([QueryKey.GetTruckCodeById, data.id], data)
      showNotification({ title: 'Truck code created', type: 'success' })
      navigate(`${route.truckCodes}/${data.id}`)
    },
  })

  const createTruckCode = useCallback(
    (payload: CreateTruckCodePayload) => {
      mutate(payload)
    },
    [mutate],
  )

  return {
    createTruckCode,
    ...rest,
  }
}
