import { useCallback, useEffect } from 'react'

import { EntityId, PurchaseOrderItem, PurchaseOrderType } from 'api'
import { useFormContext } from 'hooks'
import { useGetPurchaseOrderOptions, useSetPurchaseOrderOptions } from 'store'

import { TableColumnsType, useTableColumns } from 'components'
import { getTableExpandableColumns } from 'modules/purchaseOrders/utils/getTableExpandableColumns'

import { redeliveryEditModeColumns } from 'modules/purchaseOrders/constants/redeliveryColumns'
import { vendorReleaseEditModeColumns } from 'modules/purchaseOrders/constants/vendorReleaseColumns'

interface UseGetExpandableColumnsProps {
  index: number
  record: Partial<PurchaseOrderItem>
  type: PurchaseOrderType | undefined
  vendorId?: EntityId
  onDeleteSubLine: ({ itemId, subLineId }: { itemId: EntityId; subLineId: EntityId }) => void
  onChangeIsBilled?: (name: string) => void
  allFieldsDisabled?: boolean
}

export const useGetExpandableColumns = ({
  index,
  record,
  type,
  onDeleteSubLine,
  onChangeIsBilled,
  vendorId,
  allFieldsDisabled,
}: UseGetExpandableColumnsProps) => {
  const { watch, setValue, triggerSubmit, getValues } = useFormContext()
  const setOptionsById = useSetPurchaseOrderOptions()
  const locationOptionsById = useGetPurchaseOrderOptions('locations')

  const qtyFieldName = `items.${index}.qty`
  const rowSubLinesFieldName = `items.${index}.subLines`
  const itemQty = getValues(qtyFieldName) || 0

  const handleRemove = useCallback(
    (subLineId?: number) => {
      setValue(qtyFieldName, itemQty - 1)
      if (record.id && subLineId) {
        onDeleteSubLine({ itemId: record.id, subLineId })
      }
    },
    [qtyFieldName, itemQty, onDeleteSubLine, setValue, record.id],
  )

  const allColumns = getTableExpandableColumns({
    itemId: record.id,
    isEditMode: true,
    onDelete: handleRemove,
    triggerSubmit,
    rowSubLinesFieldName,
    setValue,
    locationOptionsById,
    setOptionsById,
    watch,
    onChangeIsBilled,
    vendorId,
    poType: type,
    allFieldsDisabled,
  })

  const { setVisibleColumns, columns } = useTableColumns<Partial<PurchaseOrderItem>>({
    allColumns: allColumns as TableColumnsType<Partial<PurchaseOrderItem>>,
    defaultColumns: vendorReleaseEditModeColumns,
  })

  useEffect(() => {
    if (type === PurchaseOrderType.VendorRelease) {
      setVisibleColumns(vendorReleaseEditModeColumns)
    }
    if (type === PurchaseOrderType.Redelivery) {
      setVisibleColumns(redeliveryEditModeColumns)
    }
  }, [type, setVisibleColumns])

  return { columns: columns }
}
