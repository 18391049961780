import { FC } from 'react'

import { Vendor } from 'api'
import { route } from 'constant'
import { AppModule } from 'types'

import {
  Table,
  TableFilterDrawer,
  TableFilterOptions,
  TableSearchSingle,
  TableTitle,
  useTableColumns,
} from 'components'

import { useVendors } from './hooks/useVendors'
import { getTableColumns } from './utils/getTableColumns'
import { vendorsFilterOptions } from './constants/vendorsFilterOptions'
import { vendorsVisibleColumnsOptions } from './constants/vendorsVisibleColumns'

export const VendorsList: FC = () => {
  const {
    data,
    isLoading,
    pagination,
    onTableChange,
    getFieldSorter,
    offsetCount,
    TableContextProvider,
    filterOptions,
    setFilterOptions,
  } = useVendors()
  const allColumns = getTableColumns(getFieldSorter, offsetCount)
  const { visibleColumns, setVisibleColumns, columns } = useTableColumns<Vendor>({
    module: AppModule.Vendors,
    allColumns,
  })

  const vendorsList: Vendor[] = data.map(({ id, ...rest }) => ({
    key: id.toString(),
    id,
    ...rest,
  }))

  return (
    <TableContextProvider>
      <TableTitle
        title="Vendors"
        total={pagination?.total}
        addRoute={route.vendorsCreate}
        search={<TableSearchSingle searchFieldName="companyName" />}
        filter={
          <TableFilterDrawer
            setFilterOptions={setFilterOptions}
            filterOptions={filterOptions}
            options={vendorsFilterOptions}
            favoriteFieldName="fv.vendor_id"
            module={AppModule.Vendors}
          />
        }
        columns={
          <TableFilterOptions
            title="Columns"
            icon="columns"
            options={vendorsVisibleColumnsOptions}
            value={visibleColumns}
            onChange={setVisibleColumns}
          />
        }
      />
      <Table<Vendor>
        withBorderRadius={false}
        scroll={{ x: 750 }}
        dataSource={vendorsList}
        loading={isLoading}
        columns={columns}
        pagination={pagination}
        onChange={onTableChange}
      />
    </TableContextProvider>
  )
}
