import type { SalesOrderSubLine } from 'api'

import { itemLabels } from '../item'

interface SalesOrderLineItemSubLineLabels extends Record<keyof Omit<SalesOrderSubLine, 'item'>, string> {
  item: typeof itemLabels
}

export const salesOrderLineItemSubLineLabels: SalesOrderLineItemSubLineLabels = {
  id: '',
  itemId: 'SKU',
  item: itemLabels,
  qty: 'Qty.',
  price: 'Price',
  discount: 'Discount',
  taxes: 'Taxes',
  subtotal: 'Subtotal',
  total: 'Total',
}
