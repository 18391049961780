import { FC } from 'react'

import { useFormContext } from 'hooks'
import { currency, distanceUnits } from 'constant'

import { EditFormItem, Typography } from 'designSystem'
import { FormItemEditInputNumber } from 'components'

interface TableOptionFourProps {
  name: string
}

export const TableOptionFour: FC<TableOptionFourProps> = ({ name }) => {
  const { triggerSubmit } = useFormContext()

  return (
    <>
      <Typography size="xs" color="secondary" nowrap>
        Flat Fee
      </Typography>
      <EditFormItem
        name={`${name}.flatFeePrice`}
        render={({ field }) => (
          <FormItemEditInputNumber
            size="small"
            width={80}
            align="left"
            prefix={currency}
            precision={2}
            placeholder="Minimum Price"
            {...field}
            onBlur={triggerSubmit}
          />
        )}
      />
      <Typography size="xs" color="secondary" nowrap>
        and
      </Typography>
      <EditFormItem
        name={`${name}.perMilePrice`}
        render={({ field }) => (
          <FormItemEditInputNumber
            size="small"
            width={120}
            align="left"
            prefix={currency}
            precision={2}
            placeholder="Price pre Mile After"
            {...field}
            onBlur={triggerSubmit}
          />
        )}
      />
      <Typography size="xs" color="secondary" nowrap>
        per {distanceUnits.Mi} after
      </Typography>
    </>
  )
}
