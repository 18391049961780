import { FC, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

import { route } from 'constant'

import { Container, Icon } from 'designSystem'

import * as Styled from './styles'

export const UnauthorizedLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container display="block" px={7} py={4}>
      <Link to={route.login}>
        <Icon icon="logo" />
      </Link>
      <Styled.Content>
        <Styled.Wrapper>{children}</Styled.Wrapper>
      </Styled.Content>
    </Container>
  )
}
