import { ReferenceNumberBookingType } from 'api/types/referenceNumber/referenceNumberBookingType'

export const referenceNumberBookingTypeOptionLabel = {
  [ReferenceNumberBookingType.Release]: 'Release',
  [ReferenceNumberBookingType.VendorRelease]: 'Vendor Release',
}

export const referenceNumberBookingTypeOptions = Object.values(ReferenceNumberBookingType).map((bookingType) => ({
  value: bookingType,
  label: referenceNumberBookingTypeOptionLabel[bookingType],
}))
