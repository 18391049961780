import {
  cloneElement,
  CSSProperties,
  FC,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  ReactElement,
  useCallback,
  useState,
} from 'react'

import { useFormContext } from 'hooks/useForm'
import { typedMemo } from 'types'
import { getObjectValueByKey } from 'utils'

import type { EditInputProps } from 'designSystem'
import { EditInput, EditView } from 'designSystem'

import * as Styled from './styles'

export interface FormItemEditInputProps extends Omit<EditInputProps, 'name' | 'error' | 'onChange'> {
  name: string
  onChange: (value?: string) => void
  onConfirm?: (value?: string) => void
  // EditView props
  addLink?: boolean
  align?: CSSProperties['textAlign']
  popover?: ReactElement
  isFieldViewOnly?: boolean
  href?: string
  nowrap?: boolean
}

export const FormItemEditInputBase: FC<FormItemEditInputProps> = forwardRef(
  (
    {
      disabled,
      size,
      onBlur,
      onChange,
      name,
      value,
      addLink,
      suffix,
      align,
      onConfirm,
      popover,
      isFieldViewOnly,
      href,
      nowrap,
      onPressEnter,
      ...props
    },
    ref,
  ) => {
    const [isEdit, setIsEdit] = useState(false)
    const [isOpenPopover, setIsOpenPopover] = useState(false)
    const {
      getFieldState,
      formState: { defaultValues },
      triggerSubmit,
    } = useFormContext()

    const error = getFieldState(name)?.error
    const defaultValue = getObjectValueByKey(name, defaultValues)

    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        onBlur?.(event)
        setIsEdit(false)
        if (error || popover) {
          onChange?.(defaultValue)
        }
      },
      [onBlur, onChange, defaultValue, error, popover],
    )

    const handleViewClick = useCallback(() => {
      setIsEdit(true)
    }, [setIsEdit])

    const onPopoverCancel = useCallback(() => {
      setIsEdit(false)
      setIsOpenPopover(false)
      onChange?.(defaultValue)
    }, [onChange, defaultValue])

    const onPopoverConfirm = useCallback(() => {
      if (error) {
        return
      }

      onChange?.(value?.trim())
      onConfirm?.(value)
      setIsEdit(false)
      setIsOpenPopover(false)
    }, [onConfirm, error, onChange, value])

    const handlePressEnter = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (popover) {
          setIsOpenPopover(!error && defaultValue !== value?.trim())
        } else {
          setIsEdit(false)
          onPressEnter?.(event)
          triggerSubmit?.()
        }
      },
      [defaultValue, error, onPressEnter, popover, triggerSubmit, value],
    )

    return (
      <Styled.Wrapper $align={align} $isLinkAndViewOnly={isFieldViewOnly && !!href && !!value}>
        {isEdit ? (
          <Styled.InputWrapper>
            <EditInput
              {...props}
              onPressEnter={handlePressEnter}
              align={align}
              name={name}
              value={value}
              status={error && 'error'}
              autoFocus
              onBlur={handleBlur}
              onChange={onChange}
              ref={ref}
              size={size}
              disabled={disabled}
              suffix={suffix}
            />
            {popover &&
              cloneElement(popover, {
                key: isOpenPopover ? 'open' : 'closed',
                onConfirm: onPopoverConfirm,
                onCancel: onPopoverCancel,
                isOpen: isOpenPopover,
                disabled: !!error || defaultValue === value?.trim(),
              })}
          </Styled.InputWrapper>
        ) : (
          <EditView
            align={align}
            suffix={suffix}
            nowrap={nowrap}
            withEdit={!!href && !isFieldViewOnly}
            href={href && value ? href : undefined}
            as={addLink ? 'link' : href ? 'select-link' : undefined}
            size={size}
            disabled={isFieldViewOnly ? false : disabled}
            error={!!error}
            value={value}
            onClick={handleViewClick}
          />
        )}
      </Styled.Wrapper>
    )
  },
)

export const FormItemEditInput = typedMemo(FormItemEditInputBase)
