import { route } from 'constant'

export const breadcrumbsData = [
  {
    title: 'Locations',
    url: route.locationCodes,
  },
  {
    title: 'Location Details',
  },
]
