import { PurchaseOrderItemsSubLine, PurchaseOrderReferenceType, ReferenceNumberStatus } from 'api'
import { PurchaseOrderFormAccessor } from 'types'

import { SelectSingleOption } from 'designSystem'
import { PurchaseOrderSubLineRow, ReferenceNumberOptions } from 'modules/purchaseOrders/types'

interface GetReferenceNumberDataProps {
  referenceRecordType: PurchaseOrderReferenceType | null
  referenceNumberOptions?: ReferenceNumberOptions
  record: PurchaseOrderSubLineRow | Partial<PurchaseOrderItemsSubLine>
}

interface GetReferenceNumberDataReturn {
  referenceFiled?: 'vendorReleaseReferenceNumber' | 'redeliveryReferenceNumber'
  createFieldName?: string
  editFieldName?: PurchaseOrderFormAccessor
  options: SelectSingleOption[]
  status?: ReferenceNumberStatus
}

export const getReferenceNumberData = ({
  referenceRecordType,
  referenceNumberOptions,
  record,
}: GetReferenceNumberDataProps): GetReferenceNumberDataReturn => {
  if (referenceRecordType === PurchaseOrderReferenceType.VendorRelease) {
    return {
      referenceFiled: PurchaseOrderFormAccessor.VendorReleaseReferenceNumber,
      createFieldName: PurchaseOrderFormAccessor.VendorReleaseNumber as string,
      editFieldName: PurchaseOrderFormAccessor.VendorReleaseRefId,
      options: referenceNumberOptions?.vendorReleaseNumberOptions || [],
      status: record?.asset?.vendorReleaseReferenceNumber?.status,
    }
  }
  if (referenceRecordType === PurchaseOrderReferenceType.Redelivery) {
    return {
      referenceFiled: PurchaseOrderFormAccessor.RedeliveryReferenceNumber,
      createFieldName: PurchaseOrderFormAccessor.RedeliveryNumber as string,
      editFieldName: PurchaseOrderFormAccessor.RedeliveryRefId,
      options: referenceNumberOptions?.redeliveryNumberOptions || [],
      status: record?.asset?.redeliveryReferenceNumber?.status,
    }
  }

  return {
    referenceFiled: undefined,
    editFieldName: undefined,
    createFieldName: undefined,
    options: [],
    status: undefined,
  }
}
