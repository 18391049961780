import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ReferenceNumberType } from 'api'
import { route } from 'constant'

import { Container } from 'designSystem'
import { Breadcrumbs } from 'components'

import { breadcrumbsData } from '../../constants/breadcrumbsData'
import { BookingNumber } from './elements/BookingNumber'
import { PickupNumber } from './elements/PickupNumber'
import { RedeliveryNumber } from './elements/RedeliveryNumber'
import { VendorReleaseNumber } from './elements/VendorReleaseNumber'

export const ReferenceCreate: FC = () => {
  const { type } = useParams()
  const navigate = useNavigate()

  const component = type
    ? {
        [ReferenceNumberType.Booking]: <BookingNumber />,
        [ReferenceNumberType.VendorRelease]: <VendorReleaseNumber />,
        [ReferenceNumberType.Redelivery]: <RedeliveryNumber />,
        [ReferenceNumberType.Pickup]: <PickupNumber />,
      }[type]
    : null

  useEffect(() => {
    if (!type || !Object.values(ReferenceNumberType).includes(type as ReferenceNumberType)) {
      navigate(route.references)
    }
  }, [type, navigate])

  return (
    <>
      <Breadcrumbs breadcrumbItems={breadcrumbsData} />
      <Container mt={4} fg={1} fd="column">
        {component}
      </Container>
    </>
  )
}
