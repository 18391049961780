import { useLocationCodes } from 'hooks'
import { vendorTypeOptions } from 'constant'
import { getContainsArrayFilter } from 'utils'

import { Icon } from 'designSystem'
import { TableFilterSelectMulti, TableFilterSelectMultiAsync } from 'components'

export const vendorsFilterOptions = [
  {
    value: 'locationCodes.id',
    label: 'Vendor Location',
    component: (
      <TableFilterSelectMultiAsync
        key="locationCodes.id"
        name="locationCodes.id"
        getItems={useLocationCodes}
        placeholder="Locations"
      />
    ),
  },
  {
    value: 'type',
    label: 'Vendor Type',
    component: (
      <TableFilterSelectMulti
        prefix={<Icon icon="address" />}
        key="type"
        name="type"
        options={vendorTypeOptions}
        placeholder="Vendor Type"
        getFilterFn={getContainsArrayFilter}
      />
    ),
  },
  {
    value: 'fv.vendor_id',
  },
]
