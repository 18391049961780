import isEqual from 'react-fast-compare'

import type { UserTableSettings } from 'types'

export const checkIfUserSettingsChanged = (
  prevSettings?: UserTableSettings,
  newSettings?: UserTableSettings,
): boolean => {
  return !!Object.keys(prevSettings || {})
    .map(
      (key) =>
        !isEqual(prevSettings?.[key as keyof typeof prevSettings], newSettings?.[key as keyof typeof newSettings]),
    )
    .filter(Boolean).length
}
