import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { VendorType } from 'api'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { InventoryPayload } from '../types/inventoryCreateForm'

import { patchInventory } from '../queryApi'

export const usePatchInventory = (vendorId: number, type: VendorType.ContainerVendor | VendorType.Parts) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation({
    mutationFn: patchInventory,
    onSuccess: () => {
      const appEvent =
        type === VendorType.ContainerVendor ? AppEvent.InventoryContainerVendorUpdate : AppEvent.InventoryPartsUpdate

      dispatchAppEvent(appEvent)
      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const patch = useCallback(
    (inventoryId: number, payload: InventoryPayload) => {
      mutate({ vendorId, inventoryId, data: payload, type })
    },
    [mutate, vendorId, type],
  )

  return {
    patch,
    ...rest,
  }
}
