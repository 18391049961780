import type { Address, ApiQueryParams, Contact, Customer, CustomerCity, CustomerState, EntityId } from 'api'
import { Pagination } from 'types'

import { api } from '../api'

export interface GetCustomersResponse extends Pagination {
  data?: Customer[]
}

export type GetCustomersQueryParams = ApiQueryParams

export const getCustomers = (params?: GetCustomersQueryParams): Promise<GetCustomersResponse> => {
  return api({
    method: 'get',
    url: '/api/v1/customers/',
    params,
  })
}

export interface GetCustomersCitiesResponse extends Pagination {
  data?: CustomerCity[]
}

export const getCustomersCities = (params?: ApiQueryParams): Promise<GetCustomersCitiesResponse> => {
  return api({
    method: 'get',
    url: '/api/v1/customers/addresses/cities',
    params,
  })
}

export interface GetCustomersStatesResponse extends Pagination {
  data?: CustomerState[]
}

export const getCustomersStates = (params?: ApiQueryParams): Promise<GetCustomersStatesResponse> => {
  return api({
    method: 'get',
    url: '/api/v1/customers/addresses/states',
    params,
  })
}

export interface GetCustomerContactsResponse extends Pagination {
  data: Contact[]
}

export const getCustomerContacts = (id?: EntityId, params?: ApiQueryParams): Promise<GetCustomerContactsResponse> => {
  return api({
    method: 'get',
    url: `/api/v1/customers/${id}/contacts`,
    params: {
      sort: 'id,ASC',
      ...params,
    },
  })
}

export interface GetCustomerAddressesResponse extends Pagination {
  data: Address[]
}

export const getCustomerBillingAddresses = (
  id?: EntityId,
  params?: ApiQueryParams,
): Promise<GetCustomerAddressesResponse> => {
  return api({
    method: 'get',
    url: `/api/v1/customers/${id}/billing-addresses`,
    params: {
      sort: 'id,ASC',
      ...params,
    },
  })
}

export const getCustomerShippingAddresses = (
  id?: EntityId,
  params?: ApiQueryParams,
): Promise<GetCustomerAddressesResponse> => {
  return api({
    method: 'get',
    url: `/api/v1/customers/${id}/shipping-addresses`,
    params: {
      sort: 'id,ASC',
      ...params,
    },
  })
}
