import { VendorCarrierPriceListItemType } from 'api'

import { Container, Typography } from 'designSystem'

export const carrierPriceListItemTypeOptions = [
  {
    value: VendorCarrierPriceListItemType.One,
    label: (
      <Container gap={4}>
        <Typography size="s">
          [Truck]{' '}
          <Typography size="s" color="secondary">
            Flat Fee
          </Typography>{' '}
          [Price]{' '}
          <Typography size="s" color="secondary">
            from
          </Typography>{' '}
          [Location]{' '}
          <Typography size="s" color="secondary">
            to
          </Typography>{' '}
          [ZIP]
        </Typography>
        <Typography size="s" color="secondary">
          [City, State]
        </Typography>
      </Container>
    ),
  },
  {
    value: VendorCarrierPriceListItemType.Two,
    label: (
      <Container gap={4}>
        <Typography size="s">
          [Truck]{' '}
          <Typography size="s" color="secondary">
            Flat Fee
          </Typography>{' '}
          [Price] [Minimum Miles]{' '}
          <Typography size="s" color="secondary">
            and
          </Typography>{' '}
          [Price per Mile After]
        </Typography>
      </Container>
    ),
  },
  {
    value: VendorCarrierPriceListItemType.Three,
    label: (
      <Container gap={4}>
        <Typography size="s">[Truck] [Miles Range], [Price]</Typography>
      </Container>
    ),
  },

  {
    value: VendorCarrierPriceListItemType.Four,
    label: (
      <Container gap={4}>
        <Typography size="s">
          [Truck]{' '}
          <Typography size="s" color="secondary">
            Flat Fee
          </Typography>{' '}
          [Minimum Price]{' '}
          <Typography size="s" color="secondary">
            and
          </Typography>{' '}
          [Price per Mile After]
        </Typography>
      </Container>
    ),
  },
]
