import { FC, MouseEvent, ReactNode, useCallback, useState } from 'react'
import { Popover } from 'antd'
import { PopoverProps } from 'antd/lib/popover'

import { typedMemo } from 'types'

import { ConfirmationPopoverOkType } from './types/сonfirmationPopoverOkType'
import { PopoverContent } from './elements/PopoverContent'

interface ConfirmationPopoverProps extends PopoverProps {
  text: ReactNode
  titleText?: ReactNode | string
  onOk?: () => void
  onOpen?: () => void
  onCancel?: () => void
  isLoading?: boolean
  shouldOpen?: boolean
  okText?: string
  okType?: ConfirmationPopoverOkType
  cancelText?: string
  showOk?: boolean
  showCancel?: boolean
  disabled?: boolean
}

export const ConfirmationPopoverBase: FC<ConfirmationPopoverProps> = ({
  open: openControlled,
  onOk,
  onOpen,
  text,
  placement = 'bottomRight',
  children,
  isLoading,
  shouldOpen,
  onOpenChange,
  onCancel,
  okText,
  okType,
  cancelText,
  showOk = true,
  showCancel = true,
  titleText,
  disabled,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(!!openControlled)
  const isLocalOpenControlled = openControlled === undefined

  const handleSetIsOpen = useCallback(
    (open: boolean) => {
      if (isLocalOpenControlled) {
        setIsOpen(open)
      }
    },
    [isLocalOpenControlled],
  )

  const handleCancel = useCallback(() => {
    handleSetIsOpen(false)
    onCancel?.()
  }, [onCancel, handleSetIsOpen])

  const handleSubmit = useCallback(
    async (e: MouseEvent) => {
      e.stopPropagation()

      await onOk?.()
      handleSetIsOpen(false)
    },
    [onOk, handleSetIsOpen],
  )

  const onClick = useCallback(
    (e: MouseEvent) => {
      if (shouldOpen) {
        e.stopPropagation()
        e.preventDefault()

        handleSetIsOpen(true)

        onOpen?.()
      }
    },
    [onOpen, handleSetIsOpen, shouldOpen],
  )

  const handleOpenChange = useCallback(
    (visible: boolean) => {
      handleSetIsOpen(visible)
      onOpenChange?.(visible)
    },
    [onOpenChange, handleSetIsOpen],
  )

  return (
    <Popover
      {...props}
      placement={placement}
      arrow={false}
      content={
        <PopoverContent
          text={text}
          onOk={handleSubmit}
          onCancel={handleCancel}
          isLoading={isLoading}
          okText={okText}
          cancelText={cancelText}
          showOk={showOk}
          showCancel={showCancel}
          okType={okType}
          titleText={titleText}
          disabled={disabled}
        />
      }
      open={isLocalOpenControlled ? isOpen : openControlled}
      onOpenChange={handleOpenChange}
      trigger="click"
    >
      <span onClick={onClick}>{children}</span>
    </Popover>
  )
}

export const ConfirmationPopover = typedMemo(ConfirmationPopoverBase)
