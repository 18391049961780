import { FC, PropsWithChildren } from 'react'
import type { UseDroppableArguments } from '@dnd-kit/core'
import { useDroppable } from '@dnd-kit/core'

import { typedMemo } from 'types'

import * as Styled from './styles'

export type DroppableProps = UseDroppableArguments

export const DroppableBase: FC<PropsWithChildren<DroppableProps>> = ({ children, ...props }) => {
  const { setNodeRef } = useDroppable({
    id: props.id,
    data: props.data,
  })

  return <Styled.Droppable ref={setNodeRef}>{children}</Styled.Droppable>
}

export const Droppable = typedMemo(DroppableBase)
