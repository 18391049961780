import { FC, FocusEvent, useCallback } from 'react'

import { AsyncSelectGetItemsProps, typedMemo } from 'types'

import { EditFormItem } from 'designSystem'

import { FormItemEditSelectMulti, FormItemEditSelectMultiProps } from '../FormItemEditSelectMulti'

interface FormItemEditSelectMultiAsyncProps
  extends FormItemEditSelectMultiProps,
    Omit<AsyncSelectGetItemsProps, 'id' | 'defaultOption'> {
  name: string
  nestedName?: string
  getItems: (props: AsyncSelectGetItemsProps) => any
}

export const FormItemEditSelectMultiAsyncBase: FC<FormItemEditSelectMultiAsyncProps> = ({
  id,
  name,
  label,
  width,
  showSearch = true,
  allowClear = true,
  getItems,
  nestedEntityId,
  valuePropName,
  queryParams,
  withFullDataOption,
  onBlur,
  join,
  fields,
  isFieldViewOnly,
  ...props
}) => {
  const { options, onSearch, onPopupScrollEnd, isLoading, onChangeEnabled } = getItems({
    id: id || name,
    valuePropName,
    nestedEntityId,
    queryParams,
    withFullDataOption,
    join,
    fields,
  })

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      onBlur?.(event)
      onSearch('')
    },
    [onSearch, onBlur],
  )

  return (
    <EditFormItem
      name={name}
      label={label}
      nestedName={props.nestedName}
      isViewOnly={isFieldViewOnly}
      render={({ field }) => (
        <FormItemEditSelectMulti
          {...field}
          {...props}
          onBlur={handleBlur}
          allowClear={allowClear}
          showSearch={showSearch}
          options={options}
          onSearch={onSearch}
          onPopupScrollEnd={onPopupScrollEnd}
          onFocusChange={onChangeEnabled}
          loading={isLoading}
          width={width}
        />
      )}
    />
  )
}

export const FormItemEditSelectMultiAsync = typedMemo(FormItemEditSelectMultiAsyncBase)
