import { ItemCategory } from 'api'

export const insertCategory = (categories: ItemCategory[], newCategory: ItemCategory): ItemCategory[] => {
  return newCategory.parentId
    ? categories?.map((item) => {
        if (item.id === newCategory.parentId) {
          return {
            ...item,
            children: [...(item.children ?? []), newCategory],
          }
        }
        return item
      })
    : [...(categories ?? []), newCategory]
}
