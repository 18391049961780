import { CSSProperties, FC, forwardRef, useCallback, useState } from 'react'

import { useFormContext } from 'hooks'
import { typedMemo } from 'types'
import { debounce, getObjectValueByKey } from 'utils'

import { EditView } from 'designSystem'

import * as Styled from './styles'

import {
  FormItemGoogleAutocomplete,
  FormItemGoogleAutocompleteProps,
} from '../FormItemGoogleAutocomplete/FormItemGoogleAutocomplete'

export interface FormItemEditGoogleAutocompleteProps extends FormItemGoogleAutocompleteProps {
  align?: CSSProperties['textAlign']
}

export const FormItemEditGoogleAutocompleteBase: FC<FormItemEditGoogleAutocompleteProps> = forwardRef(
  ({ disabled, value, onChange, size, onBlur, name, suffix, align, ...props }, ref) => {
    const {
      formState: { defaultValues, errors },
    } = useFormContext()
    const [isEdit, setIsEdit] = useState(false)

    const defaultValue = getObjectValueByKey(name, defaultValues)
    const error = getObjectValueByKey(name, errors)

    const handleBlur = useCallback(() => {
      if (error) {
        onChange?.(defaultValue)
      }
      setIsEdit(false)
      onBlur?.()
    }, [onBlur, error, defaultValue, onChange])

    const handleViewClick = useCallback(() => {
      setIsEdit(true)
    }, [setIsEdit])

    const handleChangeAutocomplete = useCallback(() => {
      setIsEdit(false)
    }, [])

    return (
      <>
        {isEdit ? (
          <Styled.AutocompleteWrapper $size={size}>
            <FormItemGoogleAutocomplete
              {...props}
              align={align}
              name={name}
              value={value}
              autoFocus
              onBlur={debounce(handleBlur)}
              error={error}
              onChange={onChange}
              ref={ref}
              size={size}
              disabled={disabled}
              suffix={suffix}
              onChangeAutocomplete={handleChangeAutocomplete}
              onPressEnter={debounce(onBlur)}
            />
          </Styled.AutocompleteWrapper>
        ) : (
          <EditView
            align={align}
            suffix={suffix}
            size={size}
            disabled={disabled}
            error={!!error}
            value={value}
            onClick={handleViewClick}
          />
        )}
      </>
    )
  },
)

export const FormItemEditGoogleAutocomplete = typedMemo(FormItemEditGoogleAutocompleteBase)
