import { styled } from 'styled-components'

import { Container as BaseContainer } from 'designSystem'

export const ReferenceNumber = styled(BaseContainer)`
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background: ${({ theme }) => theme.colors.accent[80]};
  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.accent[400]};
  }
`

export const Header = styled(BaseContainer)`
  font-weight: ${({ theme }) => theme.fontWeight.l};
`

export const IconWrapper = styled.div<{ $hasExpired: boolean }>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${({ theme, $hasExpired }) => ($hasExpired ? theme.colors.error[80] : theme.colors.warning[80])};
  && svg {
    fill: ${({ theme, $hasExpired }) => ($hasExpired ? theme.colors.error[400] : theme.colors.warning[400])};
  }
`

export const Line = styled.div`
  width: 1px;
  flex: 1;
  background: ${({ theme }) => theme.colors.lines};
`
