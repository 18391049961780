import { FC, useCallback, useEffect, useState } from 'react'

import { useForm } from 'hooks'
import { currency } from 'constant'
import { typedMemo } from 'types'

import { Container, Divider, EditFormItem, FormItem, Spacer, Switch, Typography } from 'designSystem'
import { ConfirmationPopover, FormItemEditInput, FormItemEditInputNumber, FormItemEditTextArea } from 'components'

import { CustomerFormProps } from '../../types/customerFormProps'

const UccFieldsBase: FC<CustomerFormProps> = (formProps) => {
  const { Form, triggerSubmit, watch, unregister, setValue } = useForm(formProps)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const defaultIsUcc = formProps.defaultValues?.isUcc
  const isUcc = watch('isUcc')

  const closePopover = useCallback(() => {
    setPopoverOpen(false)
  }, [])

  const handlePopoverOpenChange = useCallback(
    (value: boolean) => {
      if (!value) {
        closePopover()
      }
    },
    [closePopover],
  )

  const onPopoverOk = useCallback(() => {
    setValue('isUcc', false)
    setPopoverOpen(false)
    triggerSubmit?.()
  }, [setValue, triggerSubmit])

  const handleIsUccChange = useCallback(
    (value: boolean) => {
      if (defaultIsUcc) {
        setPopoverOpen(true)
      } else {
        setValue('isUcc', value)
        triggerSubmit?.()
      }
    },
    [triggerSubmit, setValue, defaultIsUcc],
  )

  useEffect(() => {
    if (!isUcc) {
      unregister('ucc')
    }
  }, [isUcc, unregister])

  return (
    <Form>
      <Container jc="space-between" ai="center">
        <Typography size="l" fontWeight="xl">
          UCC Lien
        </Typography>
        <ConfirmationPopover
          open={popoverOpen}
          shouldOpen={isUcc}
          onCancel={closePopover}
          onOpenChange={handlePopoverOpenChange}
          onOk={onPopoverOk}
          text="Are you sure you want to remove UCC Settings? All associated data will be removed from the system."
        >
          <FormItem name="isUcc" disableOnChange>
            <Switch onChange={handleIsUccChange} />
          </FormItem>
        </ConfirmationPopover>
      </Container>
      {isUcc && (
        <>
          <Spacer mb={3} />
          <EditFormItem
            name="ucc.balance"
            label="UCC Lien Balance"
            render={({ field }) => (
              <FormItemEditInputNumber
                placeholder="Enter Balance"
                {...field}
                onBlur={triggerSubmit}
                precision={2}
                suffix={currency}
              />
            )}
          />
          <Divider my={4} />
          <Typography fontWeight="xl">UCC Lien Forwarding Funds Details</Typography>
          <Spacer mb={3} />
          <Container fd="column" gap={4}>
            <EditFormItem
              name="ucc.name"
              label="Name"
              render={({ field }) => <FormItemEditInput placeholder="Enter Name" {...field} onBlur={triggerSubmit} />}
            />
            <EditFormItem
              name="ucc.email"
              label="Email"
              render={({ field }) => <FormItemEditInput placeholder="Type email" {...field} onBlur={triggerSubmit} />}
            />
            <EditFormItem
              name="ucc.details"
              label="Details"
              render={({ field }) => (
                <FormItemEditTextArea placeholder="Type Details" {...field} onConfirm={triggerSubmit} />
              )}
            />
          </Container>
        </>
      )}
    </Form>
  )
}

export const UccFields = typedMemo(UccFieldsBase)
