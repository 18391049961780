import { ReferenceNumber, ReferenceNumberRedeliveryTransactionType } from 'api'
import { TableField, TableSortingOrder } from 'hooks'
import {
  getAssetConditionLabel,
  getReferenceTypeOptionLabel,
  hash,
  referenceNumberRedeliveryTransactionTypeLabel,
} from 'constant'
import { formatDate } from 'utils'

import { ReactLink, Typography } from 'designSystem'
import { ReferenceStatusTag, RelatedLinksList, TableColumnKey, TableColumnsType } from 'components'

import { AssetRedeliveryReferenceAccessor } from '../types/assetRedeliveryReferenceAccessor'

import { getAssetRedeliveryReferenceAccessorTitle } from './getAssetRedeliveryReferenceAccessorTitle'

export const getAssetRedeliveryReferencesTableColumns = (
  getFieldSorter: (field: TableField) => TableSortingOrder,
  offsetCount: number,
): TableColumnsType<ReferenceNumber> => [
  {
    title: getAssetRedeliveryReferenceAccessorTitle(AssetRedeliveryReferenceAccessor.Index),
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => offsetCount + index + 1,
  },
  {
    title: getAssetRedeliveryReferenceAccessorTitle(AssetRedeliveryReferenceAccessor.CreatedAt),
    key: AssetRedeliveryReferenceAccessor.CreatedAt,
    dataIndex: AssetRedeliveryReferenceAccessor.CreatedAt,
    width: 200,
    ellipsis: true,
    sorter: true,
    sortOrder: getFieldSorter(AssetRedeliveryReferenceAccessor.CreatedAt),
    render: (value) => formatDate(value, 'StandardWithHours'),
  },
  {
    title: getAssetRedeliveryReferenceAccessorTitle(AssetRedeliveryReferenceAccessor.Number),
    key: AssetRedeliveryReferenceAccessor.Number,
    dataIndex: AssetRedeliveryReferenceAccessor.Number,
    width: 200,
    sorter: true,
    sortOrder: getFieldSorter(AssetRedeliveryReferenceAccessor.Number),
    ellipsis: true,
    render: (number: string, record) => (
      <ReactLink to={`${hash.reference}/${record.id}`} target="_self">
        <Typography color="inherit" size="xs" fontWeight="l" lineEllipsis={1}>
          {number}
        </Typography>
      </ReactLink>
    ),
  },
  {
    title: getAssetRedeliveryReferenceAccessorTitle(AssetRedeliveryReferenceAccessor.Type),
    key: AssetRedeliveryReferenceAccessor.Type,
    dataIndex: AssetRedeliveryReferenceAccessor.Type,
    width: 150,
    sorter: true,
    sortOrder: getFieldSorter(AssetRedeliveryReferenceAccessor.Type),
    ellipsis: true,
    render: (type) => getReferenceTypeOptionLabel(type),
  },
  {
    title: getAssetRedeliveryReferenceAccessorTitle(AssetRedeliveryReferenceAccessor.TransactionType),
    key: AssetRedeliveryReferenceAccessor.TransactionType,
    dataIndex: AssetRedeliveryReferenceAccessor.TransactionType,
    width: 150,
    ellipsis: true,
    render: (transactionType: ReferenceNumberRedeliveryTransactionType) =>
      referenceNumberRedeliveryTransactionTypeLabel[transactionType],
  },
  {
    title: getAssetRedeliveryReferenceAccessorTitle(AssetRedeliveryReferenceAccessor.RelatedReference),
    key: AssetRedeliveryReferenceAccessor.RelatedReference,
    width: 200,
    ellipsis: true,
    render: (_, record) => {
      const relatedRecords = [
        record.vendorRelease,
        record.bookingNumber,
        ...(record.bookingNumbers || []),
        ...(record.redeliveryNumbers || []),
      ]
        .filter(Boolean)
        .map((ref) => ({
          id: ref?.id as number,
          title: ref?.number as string,
        }))
      return relatedRecords.length ? (
        <RelatedLinksList hash="reference" linkProps={{ target: '_self' }} data={relatedRecords} />
      ) : (
        '-'
      )
    },
  },
  {
    title: getAssetRedeliveryReferenceAccessorTitle(AssetRedeliveryReferenceAccessor.ReceivedDate),
    key: AssetRedeliveryReferenceAccessor.ReceivedDate,
    width: 200,
    ellipsis: true,
    render: (value: undefined, record: ReferenceNumber) => {
      const dateString = record?.items
        ?.reduce((acc, item) => {
          const subLineDate = item?.subLines
            ?.map((subLine) => (subLine.receivedDate ? formatDate(subLine.receivedDate) : ''))
            .filter(Boolean)
            .join(', ')

          if (subLineDate) {
            acc.push(subLineDate)
          }
          return acc
        }, [] as string[])
        .join(', ')

      return dateString || '-'
    },
  },
  {
    title: getAssetRedeliveryReferenceAccessorTitle(AssetRedeliveryReferenceAccessor.Condition),
    key: AssetRedeliveryReferenceAccessor.Condition,
    width: 200,
    ellipsis: true,
    render: (value: undefined, record: ReferenceNumber) => {
      const conditions = record?.items
        ?.reduce((acc, item) => {
          const subLineConditions = item?.subLines
            ?.map((subLine) => (subLine.condition ? getAssetConditionLabel(subLine.condition) : ''))
            .filter(Boolean)
            .join(', ')

          if (subLineConditions) {
            acc.push(subLineConditions)
          }
          return acc
        }, [] as string[])
        .join(', ')

      return conditions || '-'
    },
  },
  {
    title: getAssetRedeliveryReferenceAccessorTitle(AssetRedeliveryReferenceAccessor.Notes),
    key: AssetRedeliveryReferenceAccessor.Notes,
    dataIndex: AssetRedeliveryReferenceAccessor.Notes,
    width: 200,
    ellipsis: true,
    render: (notes) => notes || '-',
  },
  {
    title: getAssetRedeliveryReferenceAccessorTitle(AssetRedeliveryReferenceAccessor.Status),
    key: AssetRedeliveryReferenceAccessor.Status,
    dataIndex: AssetRedeliveryReferenceAccessor.Status,
    width: 200,
    sorter: true,
    sortOrder: getFieldSorter(AssetRedeliveryReferenceAccessor.Status),
    ellipsis: true,
    render: (status) => <ReferenceStatusTag status={status} />,
  },
]
