import { PurchaseOrderDeliveryType } from '../types'

export const PurchaseOrderDeliveryTypeOptions: {
  value: PurchaseOrderDeliveryType
  label: string
  disabled?: boolean
}[] = [
  {
    value: PurchaseOrderDeliveryType.Warehouse,
    label: 'Warehouse',
  },
  {
    value: PurchaseOrderDeliveryType.SalesOrder,
    label: 'Sales order',
    //TODO remove disabled when sales order is implemented
    disabled: true,
  },
]
