import { FC } from 'react'

import { Button, Container, Modal, ModalProps, Typography } from 'designSystem'

interface ConfirmationModalProps extends Omit<ModalProps, 'onOk' | 'onCancel'> {
  onCancel: () => void
  onOk: () => void
  text: string
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  width = 460,
  destroyOnClose = true,
  onCancel,
  footer = null,
  onOk,
  text,
  title,
  ...props
}) => {
  return (
    <Modal
      {...props}
      width={width}
      destroyOnClose={destroyOnClose}
      footer={footer}
      title={title}
      onCancel={onCancel}
      onOk={onOk}
    >
      <Typography size="xs" color="secondary">
        {text}
      </Typography>
      <Container mt={4} display="flex" ai="center" jc="space-between">
        <Button size="small" onClick={onCancel}>
          Cancel
        </Button>
        <Button size="small" type="primary" onClick={onOk}>
          Yes
        </Button>
      </Container>
    </Modal>
  )
}
