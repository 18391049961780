import { getAccessorTitle } from '../utils/getAccessorTitle'
import { Accessor } from '../types/accessor'

export const assetsRequiredColumns = [Accessor.Index, Accessor.AssetNumber]

export const assetsDefaultColumns = [
  Accessor.Index,
  Accessor.AssetNumber,
  Accessor.Status,
  Accessor.BirthmarkNumber,
  Accessor.BirthmarkSize,
]

export const assetsVisibleColumnsOptions = Object.values(Accessor).map((accessor) => ({
  value: accessor,
  label: getAccessorTitle(accessor),
  disabled: assetsRequiredColumns.includes(accessor),
}))
