export const storageFeeList = [
  {
    label: 'Gate In Fee',
    name: 'depotSettings.gateInFee',
  },
  {
    label: 'Gate Out Fee',
    name: 'depotSettings.gateOutFee',
  },
  {
    label: 'Lift Fee',
    name: 'depotSettings.liftFee',
  },
  {
    label: 'Daily Rate per TEU',
    name: 'depotSettings.dailyRatePerTEU',
  },
  {
    label: 'Daily Rate per x2 TEU',
    name: 'depotSettings.dailyRatePerX2TEU',
  },
  {
    label: 'Labor Rate per Hour',
    name: 'depotSettings.laborRatePerHour',
  },
]
