import { AppEnvironment } from 'types'

export const envVariables = {
  env: {
    isProduction: process.env.REACT_APP_ENVIRONMENT === AppEnvironment.Production,
    isStaging: process.env.REACT_APP_ENVIRONMENT === AppEnvironment.Staging,
    isDevelopment: process.env.REACT_APP_ENVIRONMENT === AppEnvironment.Development,
  },
  imagesUrl: `${process.env.REACT_APP_FILES_URL}/public/images/`,
}
