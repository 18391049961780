import { Link } from 'react-router-dom'

import { route } from 'constant'

export const settingsSidebarItems = [
  {
    label: <Link to={route.profile}>Profile</Link>,
    key: route.profile,
  },
  {
    label: <Link to={route.settingsCompany}>Company</Link>,
    key: route.settingsCompany,
  },
  // {
  //   label: <Link to={route.settingsNotifications}>Notifications</Link>,
  //   key: route.settingsNotifications,
  // },
]
