import { useCallback, useState } from 'react'

type ListItem<T> = Partial<T> & { id: number }

interface UseEditableTableProps<T> {
  data: ListItem<T>[]
}
export const useEditableTable = <T>({ data }: UseEditableTableProps<T>) => {
  const [isAddNew, setIsAddNew] = useState(false)

  const onAddNew = useCallback(() => {
    setIsAddNew(true)
  }, [])

  const onCancelNew = useCallback(() => {
    setIsAddNew(false)
  }, [])

  const dataSource = data.map(({ id, ...rest }) => ({
    key: id.toString(),
    id,
    ...rest,
  }))

  return {
    dataSource: isAddNew ? [{ key: '-1', id: -1 }, ...dataSource] : dataSource,
    isAddNew,
    onCancelNew,
    setIsAddNew,
    onAddNew,
  }
}
