import type { ApiQueryParams } from 'api'
import { Pagination } from 'types'

import { PurchaseOrder } from 'api/types/purchaseOrder'

import { api } from '../api'

export interface GetPurchaseOrdersResponse extends Pagination {
  data?: PurchaseOrder[]
}

export type GetPurchaseOrdersQueryParams = ApiQueryParams

export const getPurchaseOrders = (params?: GetPurchaseOrdersQueryParams): Promise<GetPurchaseOrdersResponse> => {
  const { fields: requestFields, ...requestParams } = params || {}
  const fields = requestFields ? `?fields=${requestFields}` : '/'

  return api({
    method: 'get',
    url: `/api/v1/purchase-orders${fields}`,
    params: requestParams,
  })
}
