import { accessManagementRoot } from './accessManagementRoute'
import { assetCodesRoot } from './assetCodesRoute'
import { locationCodesRoot } from './locationCodesRoute'
import { truckCodesRoot } from './truckCodesRoute'

export const globalSettingsRoot = '/global-settings'

export const globalSettingsRoute = {
  assetCodes: assetCodesRoot,
  locationCodes: locationCodesRoot,
  accessManagement: accessManagementRoot,
  truckCodes: truckCodesRoot,
  emailSettings: '/email-settings',
  assetStatuses: '/asset-statuses',
  depotCodes: '/depot-codes',
}
