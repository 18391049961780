import { ReferenceNumberAssetStatus } from 'api/types/referenceNumber/referenceNumberAssetStatus'

export const referenceNumberAssetStatusLabel = {
  [ReferenceNumberAssetStatus.Open]: 'Open',
  [ReferenceNumberAssetStatus.OpenPendingModification]: 'Open Pending Modification',
  [ReferenceNumberAssetStatus.OpenModificationDone]: 'Open Modification Done',
  [ReferenceNumberAssetStatus.Pending]: 'Pending',
  [ReferenceNumberAssetStatus.PendingRedelivery]: 'Pending Redelivery',
  [ReferenceNumberAssetStatus.Received]: 'Received',
  [ReferenceNumberAssetStatus.Closed]: 'Closed',
  [ReferenceNumberAssetStatus.Voided]: 'Voided',
  [ReferenceNumberAssetStatus.Rejected]: 'Rejected',
}

export const bookingAssetStatusOptions = [
  ReferenceNumberAssetStatus.Open,
  ReferenceNumberAssetStatus.Closed,
  ReferenceNumberAssetStatus.Voided,
  ReferenceNumberAssetStatus.Rejected,
].map((status) => ({
  value: status,
  label: referenceNumberAssetStatusLabel[status],
}))

export const bookingReleaseAssetStatusOptions = [
  ReferenceNumberAssetStatus.Open,
  ReferenceNumberAssetStatus.OpenPendingModification,
  ReferenceNumberAssetStatus.OpenModificationDone,
  ReferenceNumberAssetStatus.Closed,
  ReferenceNumberAssetStatus.Voided,
  ReferenceNumberAssetStatus.Rejected,
].map((status) => ({
  value: status,
  label: referenceNumberAssetStatusLabel[status],
}))

export const redeliveryAssetStatusOptions = [
  ReferenceNumberAssetStatus.PendingRedelivery,
  ReferenceNumberAssetStatus.Received,
  ReferenceNumberAssetStatus.Voided,
].map((status) => ({
  value: status,
  label: referenceNumberAssetStatusLabel[status],
}))

export const vendorReleaseAssetStatusOptions = [
  ReferenceNumberAssetStatus.Open,
  ReferenceNumberAssetStatus.Pending,
  ReferenceNumberAssetStatus.Closed,
  ReferenceNumberAssetStatus.Voided,
].map((status) => ({
  value: status,
  label: referenceNumberAssetStatusLabel[status],
}))

export const pickupAssetStatusOptions = vendorReleaseAssetStatusOptions
