import { VendorType, VendorTypeNames } from 'api'

export interface UploadTypeOption {
  label: string
  value: VendorType
}

export const uploadTypeOptions: UploadTypeOption[] = [
  {
    label: VendorTypeNames[VendorType.ContainerVendor],
    value: VendorType.ContainerVendor,
  },
  {
    label: VendorTypeNames[VendorType.Modifications],
    value: VendorType.Modifications,
  },
  {
    label: VendorTypeNames[VendorType.Parts],
    value: VendorType.Parts,
  },
]
