import { TimePicker as TimePickerBase } from 'antd'
import { css, styled } from 'styled-components'

import { DatePickerStyles } from 'styles/datePickerStyles'

import { Container as BaseContainer } from '../Container'
import type { TimePickerProps } from './TimePicker'

export const Container = styled(BaseContainer)<{ $prefix: boolean }>`
  ${({ $prefix, theme }) => {
    return (
      $prefix &&
      css`
        &&&&& .ant-picker-input {
          padding-left: ${theme.spacers[4]};
        }
      `
    )
  }}
`

export const TimePicker = styled(TimePickerBase)<Omit<TimePickerProps, 'format' | 'value' | 'onChange'>>`
  ${DatePickerStyles}
`

export const PrefixIcon = styled(BaseContainer)`
  position: absolute;
  left: ${({ theme }) => theme.spacers[3]};
  top: 50%;
  transform: translateY(-50%);
  z-index: ${({ theme }) => theme.zIndexLevel[1]};
`
