import { FC } from 'react'

import { ReferenceNumberAsset, ReferenceNumberItem, ReferenceNumberType } from 'api'
import { typedMemo } from 'types'

import { DecreaseAssetsQtyModal, DecreaseAssetsQtyModalProps, Table } from 'components'

import { decreaseBookingAssetsTableColumns } from './constants/decreaseBookingAssetsTableColumns'
import { decreasePickupAssetsTableColumns } from './constants/decreasePickupAssetsTableColumns'

interface DecreaseItemQty extends Omit<DecreaseAssetsQtyModalProps, 'table'> {
  item: ReferenceNumberItem
  referenceType: ReferenceNumberType
}

const DecreaseItemQtyBase: FC<DecreaseItemQty> = ({ item, referenceType, ...props }) => {
  const columns = {
    [ReferenceNumberType.Booking]: decreaseBookingAssetsTableColumns,
    [ReferenceNumberType.VendorRelease]: [],
    [ReferenceNumberType.Redelivery]: [],
    [ReferenceNumberType.Pickup]: decreasePickupAssetsTableColumns,
  }[referenceType]

  const dataSource = item.subLines?.map(({ id, ...rest }) => ({
    key: id?.toString(),
    ...rest,
  }))

  return (
    <DecreaseAssetsQtyModal
      {...props}
      table={<Table<Partial<ReferenceNumberAsset>> columns={columns} dataSource={dataSource} />}
    />
  )
}

export const DecreaseItemQty = typedMemo(DecreaseItemQtyBase)
