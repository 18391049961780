import { forwardRef, Ref } from 'react'
import { RefSelectProps } from 'antd/lib/select'

import { typedMemo } from 'types/typedMemo'

import type { SelectSingleProps } from 'designSystem'

import * as Styled from './styles'

export const EditSelectSingleBase = forwardRef(
  ({ autoFocus = true, defaultOpen = true, ...props }: SelectSingleProps, ref: Ref<RefSelectProps>) => {
    return <Styled.EditSelectSingle autoFocus={autoFocus} defaultOpen={defaultOpen} {...props} isEdit ref={ref} />
  },
)

export const EditSelectSingle = typedMemo(EditSelectSingleBase)
