import { Dispatch, SetStateAction } from 'react'
import {
  FieldValues,
  UseFieldArrayRemove,
  UseFieldArrayReplace,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form'

import { ReferenceNumberAsset, ReferenceNumberType } from 'api'
import { ReferenceSubLineFormAccessor } from 'types'

import { ReferenceItemsAssetsIds } from '../../../types/referenceItemsAssetsIds'

export const subLinesFieldsActions = {
  removeOne: ({
    getValues,
    itemAssetsName,
    skuIndex,
    assetIndex,
    qtyFieldName,
    setValue,
    setAssetsIds,
    remove,
  }: {
    getValues: UseFormGetValues<FieldValues>
    itemAssetsName: string
    skuIndex: number
    assetIndex: number
    qtyFieldName: string
    setValue: UseFormSetValue<FieldValues>
    setAssetsIds?: Dispatch<SetStateAction<ReferenceItemsAssetsIds>>
    remove: UseFieldArrayRemove
  }) => {
    remove(assetIndex)
    const assetsForm = (getValues(itemAssetsName) || []) as ReferenceNumberAsset[]
    const newAssetsIds = {} as ReferenceItemsAssetsIds

    assetsForm.forEach((asset, i) => {
      newAssetsIds[`${skuIndex}.${i}`] = asset?.[ReferenceSubLineFormAccessor.AssetId]
    })

    setValue(qtyFieldName, assetsForm?.length)
    setAssetsIds?.((prev) => ({ ...prev, [`${skuIndex}.${assetIndex}`]: undefined, ...newAssetsIds }))
  },
  removeMultiple: ({
    getValues,
    itemAssetsName,
    skuIndex,
    assetIndexes,
    setAssetsIds,
    remove,
  }: {
    getValues: UseFormGetValues<FieldValues>
    itemAssetsName: string
    skuIndex: number
    assetIndexes: number
    setAssetsIds?: Dispatch<SetStateAction<ReferenceItemsAssetsIds>>
    remove: UseFieldArrayRemove
  }) => {
    const assetsForm = getValues(itemAssetsName) as ReferenceNumberAsset[]
    const newAssetsIds = {} as ReferenceItemsAssetsIds

    const indexes: number[] = []
    assetsForm?.forEach((asset, i) => {
      if (i >= assetsForm.length + assetIndexes) {
        indexes.push(i)
        newAssetsIds[`${skuIndex}.${i}`] = undefined
      }
    })

    setTimeout(() => {
      remove(indexes)
    }, 0)

    const newAssets = assetsForm?.slice(0, indexes[0])
    newAssets.forEach((asset, i) => {
      newAssetsIds[`${skuIndex}.${i}`] = asset?.[ReferenceSubLineFormAccessor.AssetId]
    })

    setAssetsIds?.((prev) => ({ ...prev, ...newAssetsIds }))
  },
  removeAssetsIds: ({
    getValues,
    itemAssetsName,
    skuIndex,
    setAssetsIds,
    replace,
    type,
  }: {
    getValues: UseFormGetValues<FieldValues>
    itemAssetsName: string
    skuIndex: number
    setAssetsIds?: Dispatch<SetStateAction<ReferenceItemsAssetsIds>>
    replace: UseFieldArrayReplace<FieldValues>
    type: ReferenceNumberType
  }) => {
    const isRedelivery = type === ReferenceNumberType.Redelivery

    const assetsForm = getValues(itemAssetsName) as ReferenceNumberAsset[]
    replace(
      assetsForm.map((asset) => ({
        ...asset,
        [ReferenceSubLineFormAccessor.AssetId]: null,
        ...(isRedelivery ? { [ReferenceSubLineFormAccessor.Condition]: null } : {}),
      })),
    )
    setAssetsIds?.((prev) => {
      const newIds = { ...prev } as ReferenceItemsAssetsIds
      assetsForm.forEach((asset, i) => {
        if (asset.assetId) {
          newIds[`${skuIndex}.${i}`] = undefined
        }
      })
      return newIds
    })
  },
  removeSubLinesByIds: ({
    ids,
    getValues,
    itemAssetsName,
    skuIndex,
    setAssetsIds,
    replace,
  }: {
    ids: number[]
    getValues: UseFormGetValues<FieldValues>
    itemAssetsName: string
    skuIndex: number
    setAssetsIds?: Dispatch<SetStateAction<ReferenceItemsAssetsIds>>
    replace: UseFieldArrayReplace<FieldValues>
  }) => {
    const subLinesForm = getValues(itemAssetsName) as ReferenceNumberAsset[]

    const newSubLines = subLinesForm.filter(({ id }) => !ids.includes(id))
    setAssetsIds?.((prev) => {
      const newIds = { ...prev }

      Object.keys(newIds).forEach((prevKey) => {
        if (prevKey.split('.')[0] === skuIndex.toString()) {
          delete newIds[prevKey]
        }
      })

      newSubLines.forEach((subLine, index) => {
        if (subLine.assetId) {
          newIds[`${skuIndex}.${index}`] = subLine.assetId
        }
      })

      return newIds
    })

    replace(newSubLines)
  },
}
