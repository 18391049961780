import { ReferenceNumberItem } from 'api'

export const getItemsAssets = (items?: ReferenceNumberItem[]) =>
  items?.reduce(
    (acc, item) => {
      item.subLines?.forEach((subLine) => {
        if (subLine.asset) {
          acc.push({ id: subLine.asset.id, title: subLine.asset.assetNumber })
        }
      })
      return acc
    },
    [] as { id: number; title: string }[],
  )
