import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { apiUrl } from 'api'
import { ErrorCode } from 'enums'
import { useGetSearchParams } from 'hooks'
import { route } from 'constant'
import { getErrorMessageFromErrorCode } from 'utils'

import { Button, showNotification } from 'designSystem'

import { SubmitLoginResponse } from '../../queryApi'

interface GoogleAuthProps {
  onSuccess: (data: SubmitLoginResponse) => void
}

export const GoogleAuth: FC<GoogleAuthProps> = ({ onSuccess }) => {
  const navigate = useNavigate()
  const [isGoogleLoading, setIsGoogleLoading] = useState(false)
  const {
    accessToken,
    refreshToken,
    accessTokenExpiresIn,
    refreshTokenExpiresIn,
    code: errorCode,
  } = useGetSearchParams()

  const handleGoogleAuthLoading = useCallback(() => {
    setIsGoogleLoading(true)
  }, [])

  useEffect(() => {
    const message = getErrorMessageFromErrorCode(errorCode as ErrorCode)

    if (message) {
      showNotification({ title: message, type: 'error' })
      navigate(route.login, { replace: true })
    }
  }, [errorCode, navigate])

  useEffect(() => {
    if (accessToken && refreshToken && refreshTokenExpiresIn && accessTokenExpiresIn) {
      const data: SubmitLoginResponse = {
        accessToken: {
          token: String(accessToken),
          expiresIn: Number(accessTokenExpiresIn),
        },
        refreshToken: {
          token: String(refreshToken),
          expiresIn: Number(refreshTokenExpiresIn),
        },
      }

      onSuccess(data)
      navigate(route.home)
    }
  }, [accessToken, refreshToken, onSuccess, refreshTokenExpiresIn, accessTokenExpiresIn, navigate])

  return (
    <a href={`${apiUrl}/api/v1/auth/google`}>
      <Button block icon="google" onClick={handleGoogleAuthLoading} loading={isGoogleLoading}>
        Log in with Google
      </Button>
    </a>
  )
}
