import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { Contact, EntityId, PurchaseOrder } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { deletePOContact } from '../queryApi'

export const useDeletePOContact = (purchaseOrderId: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation({
    mutationFn: deletePOContact,
    onSuccess: (_, payload) => {
      dispatchAppEvent(AppEvent.PurchaseOrderDeleteContact)

      queryClient.setQueryData<PurchaseOrder | undefined>([QueryKey.GetPurchaseOrderById, purchaseOrderId], (old) => {
        if (!old) return old
        return {
          ...old,
          contacts: old.contacts.filter((contact: Contact) => contact.id !== payload.contactId),
        }
      })

      showNotification({ title: 'Contact deleted', type: 'success' })
    },
  })

  const remove = useCallback(
    (contactId: number) => {
      mutate({ purchaseOrderId, contactId })
    },
    [mutate, purchaseOrderId],
  )

  return {
    remove,
    ...rest,
  }
}
