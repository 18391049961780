import { FC, ReactNode } from 'react'

import { typedMemo } from 'types'

import { IconDelete } from '../Icon/elements/IconDelete'
import * as Styled from './styles'

interface TitleTagProps {
  title: string
  prefix?: ReactNode
  canDelete?: boolean
  onDelete?: () => void
}

export const TitleTagBase: FC<TitleTagProps> = ({ title, prefix, canDelete, onDelete }) => (
  <Styled.TitleTag>
    {prefix}
    {title}
    {canDelete && <IconDelete size={14} onClick={onDelete} />}
  </Styled.TitleTag>
)

export const TitleTag = typedMemo(TitleTagBase)
