import { Accessor } from '../types/accessor'

export const getAccessorTitle = (accessor: Accessor): string => {
  return {
    [Accessor.Index]: '#',
    [Accessor.CompanyName]: 'Company Name',
    [Accessor.Type]: 'Vendor Type',
    [Accessor.LocationCodes]: 'Locations',
    [Accessor.BillingAddress]: 'Billing Address',
    [Accessor.PaymentTerms]: 'Payment Terms',
    [Accessor.Contacts]: 'Contacts',
    [Accessor.UploadSheet]: 'Upload Sheet',
  }[accessor]
}
