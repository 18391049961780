import { Link } from 'react-router-dom'

import { locationCodesRoute, route } from 'constant'

export const getSidebarItems = (id: number) => {
  const locationCodeRoute = `${route.locationCodes}/${id}`

  return [
    {
      label: <Link to={`${locationCodeRoute}${locationCodesRoute.details}`}>Location Details</Link>,
      key: `${locationCodeRoute}${locationCodesRoute.details}`,
    },
    {
      label: <Link to={`${locationCodeRoute}${locationCodesRoute.integrations}`}>Integrations</Link>,
      key: `${locationCodeRoute}${locationCodesRoute.integrations}`,
    },
  ]
}
