import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { route } from 'constant'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { ItemCreateFormData } from '../../../types/itemPayload'

import { postItem } from '../queryApi'

export const useCreateItem = () => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const navigate = useNavigate()

  const { mutate, ...rest } = useMutation({
    mutationFn: postItem,
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.ItemCreate)

      queryClient.setQueryData([QueryKey.GetItemById, data.id], data)
      showNotification({ title: 'Item created', type: 'success' })
      navigate(`${route.items}/${data.id}`)
    },
  })

  const create = useCallback(
    (payload: ItemCreateFormData) => {
      const { groupedSKUs: groupedSKUsForm = [], ...rest } = payload
      const groupedSKUs = groupedSKUsForm.filter(Boolean)

      mutate({ ...rest, groupedSKUs })
    },
    [mutate],
  )

  return {
    create,
    ...rest,
  }
}
