import { PurchaseOrderSubLineAccessor } from 'types'

import { TableColumnKey } from 'components'

export const redeliveryCreateModeColumns = [
  PurchaseOrderSubLineAccessor.ExpectedDeliveryDate,
  PurchaseOrderSubLineAccessor.LocationCodeId,
  PurchaseOrderSubLineAccessor.VendorDepotId,
  PurchaseOrderSubLineAccessor.ReferenceNumber,
  TableColumnKey.HoverActions,
]

export const redeliveryEditModeColumns = [
  PurchaseOrderSubLineAccessor.ExpectedDeliveryDate,
  PurchaseOrderSubLineAccessor.LocationCodeId,
  PurchaseOrderSubLineAccessor.VendorDepotId,
  PurchaseOrderSubLineAccessor.AssetNumber,
  PurchaseOrderSubLineAccessor.ReferenceType,
  PurchaseOrderSubLineAccessor.ReferenceNumber,
  PurchaseOrderSubLineAccessor.IsReceived,
  PurchaseOrderSubLineAccessor.IsBilled,
  TableColumnKey.HoverActions,
]
