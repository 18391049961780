import { settingsRoute } from 'constant'

import { NotFoundPage } from 'components'

import { Company } from '../routes/Company'
import { Profile } from '../routes/Profile'

export const settingsRoutes = [
  {
    path: `${settingsRoute.profile}/*`,
    element: <Profile />,
  },
  {
    path: `${settingsRoute.company}/*`,
    element: <Company />,
  },
  {
    path: settingsRoute.notifications,
    element: <>Notifications</>,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
