import { truckCodesRoute } from 'constant'

import { NotFoundPage } from 'components'

import { TruckCodeCreate } from '../routes/TruckCodeCreate'
import { TruckCodesList } from '../routes/TruckCodesList'
import { TruckCodeView } from '../routes/TruckCodeView'

export const truckCodesRoutes = [
  {
    path: truckCodesRoute.create,
    element: <TruckCodeCreate />,
  },

  {
    path: truckCodesRoute.list,
    element: <TruckCodesList />,
  },

  {
    path: truckCodesRoute.view,
    element: <TruckCodeView />,
  },

  {
    path: '*',
    element: <NotFoundPage />,
  },
]
