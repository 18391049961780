import { FC, KeyboardEventHandler, useCallback, useState } from 'react'
import { Space } from 'antd'

import { typedMemo } from 'types'
import theme from 'styles/theme'

import type { SearchProps } from './types/searchProps'
import { SearchTypeEnum } from './types/searchType'
import * as Styled from './styles'

import { Icon } from '../Icon'
import { Input } from '../Input'
import { SelectSingle } from '../SelectSingle'

export const SearchBase: FC<SearchProps> = ({
  allowClear,
  width,
  searchType = 'single',
  size = 'middle',
  placeholder = 'Type to search',
  options,
  defaultValue,
  defaultOption,
  loading,
  onSearch,
  onSelect,
  onBlur,
  onClickSearchIcon,
  onClickClear,
  onPressEnter,
}) => {
  const [searchValue, setSearchValue] = useState<string>(defaultValue || '')
  const [selectedOption, setSelectedOption] = useState<string>(defaultOption || (options?.[0].value as string))

  const handleChangeSearch = useCallback(
    (value: string) => {
      setSearchValue(value)
      onSearch?.(value)
    },
    [onSearch],
  )

  const handleChangeSelect = useCallback(
    (value?: string | number | null) => {
      if (typeof value === 'string') {
        setSelectedOption(value)
        onSelect?.(value)
      }
    },
    [onSelect],
  )

  const handlePressEnter: KeyboardEventHandler<HTMLInputElement> = useCallback(() => {
    onPressEnter?.(searchValue, selectedOption)
  }, [onPressEnter, searchValue, selectedOption])

  return (
    <Styled.SearchContainer width={width} searchType={searchType} size={size}>
      <Space.Compact size={size}>
        <Input
          value={searchValue}
          onPressEnter={handlePressEnter}
          onBlur={onBlur}
          onChange={handleChangeSearch}
          placeholder={placeholder}
          allowClear={allowClear && !loading && { clearIcon: <Icon icon="cross" size={14} onClick={onClickClear} /> }}
          prefix={<Icon icon="search" size={18} onClick={onClickSearchIcon} />}
          suffix={loading && <Icon icon="load" size={18} spin />}
        />

        {searchType === SearchTypeEnum.Type && (
          <SelectSingle
            value={selectedOption}
            onChange={handleChangeSelect}
            options={options}
            dropdownRender={(menu) => <Styled.DropdownMenu size={size}>{menu}</Styled.DropdownMenu>}
            defaultValue={defaultOption || options?.[0].value}
            suffixIcon={<Icon icon="caretDown" color={theme.colors.primary} size={14} />}
          />
        )}
      </Space.Compact>
    </Styled.SearchContainer>
  )
}

export const Search = typedMemo(SearchBase)
