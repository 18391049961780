import type { ApiQueryParams, TruckCode } from 'api'
import { Pagination } from 'types'

import { api } from '../api'

export interface GetTruckCodesResponse extends Pagination {
  data?: TruckCode[]
}

export type GetTruckCodesQueryParams = ApiQueryParams

export const getTruckCodes = (params?: GetTruckCodesQueryParams): Promise<GetTruckCodesResponse> => {
  return api({
    method: 'get',
    url: '/api/v1/truck-codes/',
    params,
  })
}
