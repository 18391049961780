import { FC } from 'react'

import { PurchaseOrder } from 'api'

import { Container, Typography } from 'designSystem'
import { Table } from 'components'

import { tableColumns } from './constants/tableColumns'

interface RelatedPOsProps {
  data?: PurchaseOrder | PurchaseOrder[] | null
}

export const RelatedPOs: FC<RelatedPOsProps> = ({ data }) => {
  if (!data || (Array.isArray(data) && !data?.length)) {
    return null
  }

  const dataSource = Array.isArray(data)
    ? data.map((po) => ({
        ...po,
        key: po.id.toString(),
      }))
    : [{ ...data, key: data.id.toString() }]

  return (
    <Container fd="column" gap={8} width="100%">
      <Typography size="m" fontWeight="xl">
        Purchase Order
      </Typography>
      <Table<PurchaseOrder> dataSource={dataSource} columns={tableColumns} scroll={{ x: 750 }} />
    </Container>
  )
}
