import { useCallback } from 'react'

import { AppEvent } from 'types'
import { useAddSendEmailActions } from 'store'

import { invalidateRelatedQueries } from './utils/invalidateRelatedQueries'

export const useDispatchAppEvent = () => {
  const addSendEmailActions = useAddSendEmailActions()

  const dispatchAppEvent = useCallback(
    (appEvent: AppEvent) => {
      invalidateRelatedQueries(appEvent, addSendEmailActions)
    },
    [addSendEmailActions],
  )

  return {
    dispatchAppEvent,
  }
}
