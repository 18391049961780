import { generateUniqueId } from 'utils'

import { PurchaseOrderRow } from 'modules/purchaseOrders/types/purchaseOrderRow'

export const prepareDefaultRowData = (itemsCount: number): PurchaseOrderRow =>
  ({
    id: generateUniqueId(),
    key: itemsCount + 1,
    rate: undefined,
    qty: undefined,
    itemId: undefined,
    billedQty: 0,
    receivedQty: 0,
  }) satisfies PurchaseOrderRow
