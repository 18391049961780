import type { ItemCategory } from 'api'

import type { SelectSingleOption } from 'designSystem'

import { ItemCategoryType } from 'api/types/item/itemCategoryType'

export const getSingleCategoriesOptions = (
  categories: ItemCategory[] | undefined,
): SelectSingleOption[] | undefined => {
  return categories?.reduce((acc, category) => {
    if (category.type === ItemCategoryType.Folder) {
      category.children?.forEach((child) => {
        acc.push({ label: child.name, value: child.id })
      })
    } else {
      acc.push({ label: category.name, value: category.id })
    }

    return acc
  }, [] as SelectSingleOption[])
}
