import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'

import { PurchaseOrderItem } from 'api'
import { PurchaseOrderFormAccessor } from 'types'

type optionType = PurchaseOrderFormAccessor.VendorReleaseNumber | PurchaseOrderFormAccessor.RedeliveryNumber

export const useValueChange = (type: optionType) => {
  const { setValue, getValues } = useFormContext()

  const handleBlur = useCallback(() => {
    const currentValue = getValues(type)
    const currentItems: PurchaseOrderItem[] = getValues('items') || []

    currentItems.forEach((item: PurchaseOrderItem, index: number) => {
      for (let i = 0; i < item.qty; i++) {
        const path = `items.${index}.subLines.${i}.${type}`
        setValue(path, currentValue)
      }
    })
  }, [setValue, getValues, type])

  return { handleBlur }
}
