import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { AppEvent, SendEmailAction } from 'types'

const getRelatedQueryKeysByQueryEntity = (
  appEvent: AppEvent,
  addSendEmailTypes: (types: SendEmailAction[]) => void,
): QueryKey[] | undefined => {
  switch (appEvent) {
    case AppEvent.AssetCreate:
    case AppEvent.AssetUpdate:
    case AppEvent.AssetDelete:
      return [
        QueryKey.GetPurchaseOrderItems,
        QueryKey.GetReferenceItems,
        QueryKey.GetAssets,
        QueryKey.GetGlobalInventoryMatrix,
        QueryKey.GetConexWestAssets,
        QueryKey.GetVendorItems,
        QueryKey.GetDepotAssets,
      ]
    case AppEvent.AssetCodeCreate:
    case AppEvent.AssetCodeDelete:
      return [QueryKey.GetAssetCodes]
    case AppEvent.AssetCodeUpdate:
      return [
        QueryKey.GetAssetCodes,
        QueryKey.GetAssets,
        QueryKey.GetAssetById,
        QueryKey.GetItems,
        QueryKey.GetItemById,
        QueryKey.GetGlobalInventoryMatrix,
        QueryKey.GetConexWestAssets,
        QueryKey.GetVendorItems,
      ]
    case AppEvent.LocationCodeCreate:
    case AppEvent.LocationCodeDelete:
      return [QueryKey.GetLocationCodes]
    case AppEvent.LocationCodeUpdate:
      return [
        QueryKey.GetLocationCodes,
        QueryKey.GetAssets,
        QueryKey.GetAssetById,
        QueryKey.GetItems,
        QueryKey.GetItemById,
        QueryKey.GetVendors,
        QueryKey.GetVendorById,
        QueryKey.GetCarrierLocationsByVendorId,
        QueryKey.GetGlobalInventoryMatrix,
        QueryKey.GetVendorItems,
      ]
    case AppEvent.TruckCodeCreate:
    case AppEvent.TruckCodeDelete:
      return [QueryKey.GetTruckCodes]
    case AppEvent.TruckCodeUpdate:
      return [QueryKey.GetTruckCodes, QueryKey.GetEquipment]
    case AppEvent.UserCreate:
    case AppEvent.UserUpdate:
    case AppEvent.UserDelete:
      return [QueryKey.GetUsers]
    case AppEvent.CategoryUpdate:
      return [QueryKey.GetItems]
    case AppEvent.ItemCreate:
    case AppEvent.ItemDelete:
      return [
        QueryKey.GetItems,
        QueryKey.GetDataMapping,
        QueryKey.GetGlobalInventoryMatrix,
        QueryKey.GetConexWestAssets,
        QueryKey.GetVendorItems,
        QueryKey.GetAssetCodeById,
      ]
    case AppEvent.ItemUpdate:
      return [
        QueryKey.GetItems,
        QueryKey.GetAssetCodeById,
        QueryKey.GetGlobalInventoryMatrix,
        QueryKey.GetConexWestAssets,
        QueryKey.GetVendorItems,
        QueryKey.GetDepotAssets,
        QueryKey.GetItemActivityStreamline,
      ]
    case AppEvent.VendorCreate:
    case AppEvent.VendorDelete:
      return [QueryKey.GetVendors, QueryKey.GetGlobalInventoryMatrix]
    case AppEvent.VendorUpdate:
      return [
        QueryKey.GetVendors,
        QueryKey.GetCarrierLocationsByVendorId,
        QueryKey.GetEquipment,
        QueryKey.GetDataMapping,
        QueryKey.GetConexWestAssets,
        QueryKey.GetModifications,
        QueryKey.GetResaleRules,
        QueryKey.GetGlobalInventoryVendor,
        QueryKey.GetVendorItems,
        QueryKey.GetVendorCarrierPriceList,
        QueryKey.GetPurchaseOrders,
        QueryKey.GetVendorPurchaseOrders,
      ]
    case AppEvent.EquipmentCreate:
    case AppEvent.EquipmentUpdate:
    case AppEvent.EquipmentDelete:
      return [
        QueryKey.GetEquipment,
        QueryKey.GetGlobalInventoryMatrix,
        QueryKey.GetConexWestAssets,
        QueryKey.GetVendorItems,
      ]
    case AppEvent.DataMappingCreate:
    case AppEvent.DataMappingUpdate:
    case AppEvent.DataMappingDelete:
      return [
        QueryKey.GetDataMapping,
        QueryKey.GetGlobalInventoryMatrix,
        QueryKey.GetConexWestAssets,
        QueryKey.GetVendorItems,
      ]
    case AppEvent.ModificationCreate:
    case AppEvent.ModificationUpdate:
    case AppEvent.ModificationDelete:
      return [QueryKey.GetModifications, QueryKey.GetGlobalInventoryMatrix, QueryKey.GetConexWestAssets]
    case AppEvent.VendorUploadModifications:
      return [QueryKey.GetModifications, QueryKey.GetGlobalInventoryMatrix, QueryKey.GetConexWestAssets]
    case AppEvent.VendorContainerInventoryUpload:
      return [
        QueryKey.GetVendorInventoryContainerVendor,
        QueryKey.GetGlobalInventoryMatrix,
        QueryKey.GetConexWestAssets,
      ]
    case AppEvent.VendorPartsInventoryUpload:
      return [QueryKey.GetVendorInventoryParts, QueryKey.GetGlobalInventoryMatrix, QueryKey.GetConexWestAssets]
    case AppEvent.InventoryContainerVendorCreate:
    case AppEvent.InventoryContainerVendorUpdate:
    case AppEvent.InventoryContainerVendorDelete:
      return [
        QueryKey.GetVendorInventoryContainerVendor,
        QueryKey.GetGlobalInventoryMatrix,
        QueryKey.GetConexWestAssets,
        QueryKey.GetVendorItems,
      ]
    case AppEvent.InventoryPartsCreate:
    case AppEvent.InventoryPartsUpdate:
    case AppEvent.InventoryPartsDelete:
      return [
        QueryKey.GetVendorInventoryParts,
        QueryKey.GetGlobalInventoryMatrix,
        QueryKey.GetConexWestAssets,
        QueryKey.GetVendorItems,
      ]

    case AppEvent.ResaleRuleContainerVendorCreate:
    case AppEvent.ResaleRulePartsCreate:
    case AppEvent.ResaleRuleContainerVendorUpdate:
    case AppEvent.ResaleRulePartsUpdate:
    case AppEvent.ResaleRuleContainerVendorDelete:
    case AppEvent.ResaleRulePartsDelete:
      return [QueryKey.GetResaleRules]
    case AppEvent.VendorCarrierPriceListCreate:
    case AppEvent.VendorCarrierPriceListUpdate:
    case AppEvent.VendorCarrierPriceListDelete:
      return [QueryKey.GetVendorCarrierPriceList]
    case AppEvent.PurchaseOrderCreated: {
      addSendEmailTypes([SendEmailAction.PurchaseOrderCreate])
      return [
        QueryKey.GetPurchaseOrders,
        QueryKey.GetAssets,
        QueryKey.GetVendorPurchaseOrders,
        QueryKey.GetReferenceNumbers,
        QueryKey.GetPOActivityStreamline,
      ]
    }
    case AppEvent.PurchaseOrderUpdated:
    case AppEvent.PurchaseOrderDeleted:
    case AppEvent.PurchaseOrderSubLineDeleted:
    case AppEvent.PurchaseOrderItemCreated:
    case AppEvent.PurchaseOrderItemDeleted:
    case AppEvent.PurchaseOrderPatchAssetNumber:
    case AppEvent.PurchaseOrderItemUpdated: {
      addSendEmailTypes([SendEmailAction.PurchaseOrderUpdate])
      return [
        QueryKey.GetPurchaseOrders,
        QueryKey.GetAssets,
        QueryKey.GetVendorPurchaseOrders,
        QueryKey.GetReferenceNumbers,
        QueryKey.GetReferenceItems,
        QueryKey.GetPOActivityStreamline,
      ]
    }
    case AppEvent.PurchaseOrderCreateContact:
    case AppEvent.PurchaseOrderPostContactFromVendorToPO:
    case AppEvent.PurchaseOrderDeleteContact: {
      return [QueryKey.GetVendorContacts, QueryKey.GetPOActivityStreamline]
    }
    case AppEvent.CustomerCreate:
    case AppEvent.CustomerUpdate:
    case AppEvent.CustomerDeleted:
      return [QueryKey.GetCustomers]
    case AppEvent.ReferenceNumberPatchAssetNumber:
      return [
        QueryKey.GetReferenceNumbers,
        QueryKey.GetAssets,
        QueryKey.GetPurchaseOrderItems,
        QueryKey.GetReferenceNumberActivityStreamline,
      ]
    case AppEvent.ReferenceNumberUpdate:
    case AppEvent.ReferenceNumberDelete:
      return [
        QueryKey.GetReferenceNumbers,
        QueryKey.GetAssetBookingNumbers,
        QueryKey.GetAssetRedeliveryNumbers,
        QueryKey.GetReferenceNumberActivityStreamline,
        QueryKey.GetDepotBookings,
      ]
    case AppEvent.ReferenceNumberCreate: {
      return [
        QueryKey.GetReferenceNumbers,
        QueryKey.GetAssetBookingNumbers,
        QueryKey.GetAssetRedeliveryNumbers,
        QueryKey.GetCustomers,
        QueryKey.GetCustomerContacts,
        QueryKey.GetReferenceNumberActivityStreamline,
        QueryKey.GetDepotBookings,
      ]
    }
    case AppEvent.ReferenceNumberPickupContactsUpdate:
      return [
        QueryKey.GetReferenceNumbers,
        QueryKey.GetAssetBookingNumbers,
        QueryKey.GetAssetRedeliveryNumbers,
        QueryKey.GetCustomers,
        QueryKey.GetCustomerContacts,
        QueryKey.GetReferenceNumberActivityStreamline,
      ]
    case AppEvent.ReferenceNumberItemsUpdate:
      return [
        QueryKey.GetAssetBookingNumbers,
        QueryKey.GetAssetRedeliveryNumbers,
        QueryKey.GetReferenceNumberActivityStreamline,
      ]
    case AppEvent.AssetRepairCreate:
    case AppEvent.AssetRepairUpdate:
    case AppEvent.AssetRepairDelete:
      return [QueryKey.GetAssetRepairs]
    case AppEvent.SalesOrderCreated: {
      return [QueryKey.GetSalesOrderList]
    }
    default:
      return undefined
  }
}

export const invalidateRelatedQueries = (appEvent: AppEvent, addSendEmailTypes: (types: SendEmailAction[]) => void) => {
  getRelatedQueryKeysByQueryEntity(appEvent, addSendEmailTypes)?.forEach((queryKey) =>
    queryClient.invalidateQueries({ queryKey: [queryKey] }),
  )
}
