import { useCallback, useEffect, useRef } from 'react'
import isEqual from 'react-fast-compare'

import { getObjectValueByKey } from 'utils'

import { useFormContext } from '../useForm'

export interface UseMultipleEditFieldsProps {
  name: string
  index: number
  showDelete?: boolean
  isLoading?: boolean
  onSubmitPost: (index: number) => void
  onSubmitPatch: (index: number) => void
  onDelete: (index: number) => void
}

export const useFieldArrayItemWithMultipleEndpoints = ({
  name,
  index,
  showDelete,
  isLoading,
  onSubmitPatch,
  onSubmitPost,
  onDelete,
}: UseMultipleEditFieldsProps) => {
  const currentFieldSubmit = useRef<boolean>()
  const {
    watch,
    formState: { defaultValues, errors },
    trigger,
  } = useFormContext()
  const value = watch(`${name}.${index}`)
  const id = value?.id
  const defaultValue = getObjectValueByKey(`${name}.${index}`, defaultValues)
  const showDeleteButton = showDelete && id
  const isCurrentLoading = isLoading && currentFieldSubmit.current

  const isError = useCallback(() => !!getObjectValueByKey(`${name}.${index}`, errors), [name, index, errors])

  const handleSubmitPatch = useCallback(() => {
    trigger(name).then(() => {
      if (id && !isError() && !isEqual(value, defaultValue)) {
        currentFieldSubmit.current = true
        onSubmitPatch?.(index)
      }
    })
  }, [trigger, name, index, id, isError, value, defaultValue, onSubmitPatch])

  const handleSubmitPost = useCallback(() => {
    trigger(name).then(() => {
      if (!id && !isError()) {
        currentFieldSubmit.current = true
        onSubmitPost?.(index)
      }
    })
  }, [trigger, name, index, id, isError, onSubmitPost])

  const handleDelete = useCallback(() => {
    onDelete(index)
  }, [index, onDelete])

  useEffect(() => {
    if (!isLoading) {
      currentFieldSubmit.current = false
    }
  }, [isLoading])

  return {
    showDeleteButton,
    isLoading: isCurrentLoading,
    handleSubmitPost,
    handleSubmitPatch,
    handleDelete,
    id,
    value,
    isError: isError(),
  }
}
