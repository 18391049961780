export enum ReferenceNumberStatus {
  Open = 'open',
  Voided = 'voided',
  // For types: Booking, Vendor Release
  Pending = 'pending',
  InProgress = 'in_progress',
  Closed = 'closed',
  // For type: Redelivery
  PendingRedelivery = 'pending_redelivery',
  Received = 'received',
  // For type: Pickup
  Scheduled = 'scheduled',
  TransitionToCustomer = 'transition_to_customer',
  TransitionToDepot = 'transition_to_depot',
  ClosedCustomer = 'closed_customer',
  ClosedDepot = 'closed_depot',
}
