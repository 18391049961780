import { createGlobalStyle } from 'styled-components'

import { MenuStyles } from './menuStyles'
import { NotificationStyles } from './notificationStyles'
import { PopoverStyles } from './popoverStyles'

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.fontFamily.primary}, sans-serif;
  }

  html,
  body {
    height: auto;
    width: 100%;
  }
  body {
    background-color: ${({ theme }) => theme.colors.underline};
    color: ${({ theme }) => theme.colors.primary};
    overflow-x: hidden;
  }

  // TODO: Think how to set suggestions dropdown width and position
  .pac-container {
    z-index: ${({ theme }) => theme.zIndexLevel.googleMapsDropdown};
    margin-top: 12px;
    &::after {
      display: none;
    }
  }
  
  .disabled-row {
    pointer-events: none;
  }
  
  a{
    text-decoration: none;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  ul{
    margin: 0;
    padding: 0;
  }

  .ck-powered-by-balloon {
    display: none !important; // hide editor powered 
  }

  // remove autofill background color in browsers
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
     -webkit-transition-delay: 9999s;
     transition-delay: 9999s;
  }

  ${NotificationStyles}
  ${PopoverStyles}
  ${MenuStyles}
  `
