import { api, EntityId, getItemsJoinQueryParams, Item } from 'api'

import { ItemPayload } from '../../../../types/itemPayload'

export const getItemById = (id?: EntityId): Promise<Item> => {
  return api({
    method: 'get',
    url: `/api/v1/items/${id}`,
    params: {
      join: getItemsJoinQueryParams,
    },
  })
}

export const patchItem = ({ id, data }: { id: EntityId; data: ItemPayload }): Promise<Item> => {
  return api({
    method: 'patch',
    url: `/api/v1/items/${id}`,
    data,
    params: {
      join: getItemsJoinQueryParams,
    },
  })
}

export const deleteItem = (id?: EntityId): Promise<void> => {
  return api({
    method: 'delete',
    url: `/api/v1/items/${id}`,
  })
}
