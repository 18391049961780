import { CSSProperties, ElementRef, forwardRef, ReactNode, useCallback, useImperativeHandle, useRef } from 'react'
import type { TimePickerProps as BaseTimePickerProps } from 'antd'
import { TimePicker as TimePickerBase } from 'antd'

import type { TimeValue } from 'api'
import { DateFormat, typedMemo } from 'types'
import { Dayjs, getDateValueFromTimeValue, getTimeValueFromDate } from 'utils'
import theme from 'styles/theme'

import * as Styled from './styles'

import { Icon } from '../Icon'

export type TimePickerProps = Omit<BaseTimePickerProps, 'format' | 'value'> & {
  value?: TimeValue | null
  align?: CSSProperties['textAlign']
  width?: number
  error?: string
  format?: keyof typeof DateFormat
  prefix?: ReactNode
  onChange?: (value: TimeValue | null) => void
}

export type TimePickerRef = ElementRef<typeof TimePickerBase> & {
  blur: () => void
}

export const TimePickerBaseComponent = forwardRef<TimePickerRef, TimePickerProps>(
  ({ placeholder, suffixIcon, onChange, value, format = 'TwelveHourTimeFormat', prefix, ...props }, ref) => {
    const timePickerRef = useRef<TimePickerRef>(null)

    useImperativeHandle(ref, () => timePickerRef.current as TimePickerRef)

    const handleChange = useCallback(
      (value: Dayjs | null) => {
        const transformedValue = getTimeValueFromDate(value)

        onChange?.(transformedValue)
        timePickerRef.current?.blur()
      },
      [onChange],
    )
    return (
      <Styled.Container $prefix={!!prefix}>
        <Styled.TimePicker
          {...props}
          value={getDateValueFromTimeValue(value)}
          ref={timePickerRef}
          format={DateFormat[format]}
          onChange={handleChange}
          placeholder={placeholder || DateFormat[format].toLowerCase()}
          suffixIcon={suffixIcon ? suffixIcon : <Icon icon="timeFilled" size={12} color={theme.colors.primary} />}
        />
        {prefix ? <Styled.PrefixIcon>{prefix}</Styled.PrefixIcon> : null}
      </Styled.Container>
    )
  },
)

export const TimePicker = typedMemo(TimePickerBaseComponent)
