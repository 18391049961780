import { FC, useCallback, useEffect, useState } from 'react'

import { ItemCategoryType } from 'api'
import { useGetItemCategories } from 'hooks'
import { debounce } from 'utils'
import {
  useIsUserAdmin,
  useIsUserSuperAdmin,
  useItemSearch,
  useItemSearchCategories,
  useItemSetCategories,
  useItemSetSearch,
} from 'store'

import { Container, Icon, Input, Spacer, Typography } from 'designSystem'
import { Spinner } from 'components'

import { AddCategory } from './elements/AddCategory/AddCategory'
import { CategoryForm } from './elements/CategoryForm'
import { CategoryList } from './elements/CategoryList'
import * as Styled from './styles'

interface CategoriesProps {
  categoryError: string | null
}

export const Categories: FC<CategoriesProps> = ({ categoryError }) => {
  const { data: itemCategories } = useGetItemCategories()
  const search = useItemSearch()
  const searchCategories = useItemSearchCategories()
  const setSearch = useItemSetSearch()
  const setCategoriesState = useItemSetCategories()
  const isAdmin = useIsUserAdmin()
  const isSuperAdmin = useIsUserSuperAdmin()

  const [showCategoryForm, setShowCategoryForm] = useState(false)
  const [categoryType, setCategoryType] = useState<ItemCategoryType | null>(null)

  const handleShowCategoryForm = useCallback((categoryType: ItemCategoryType) => {
    setShowCategoryForm(true)
    setCategoryType(categoryType)
  }, [])

  const handleChangeSearch = useCallback(
    (value: string) => {
      setSearch(value)
    },
    [setSearch],
  )

  useEffect(() => {
    if (itemCategories) {
      setCategoriesState(itemCategories)
    }
  }, [itemCategories, setCategoriesState])

  return (
    <Styled.Card error={!!categoryError}>
      <Styled.Header>
        <Container ai="center" jc="space-between" mb={4}>
          <Typography fontWeight="xl" size="l">
            Categories
          </Typography>
          {(isAdmin || isSuperAdmin) && <AddCategory handleShowCategoryForm={handleShowCategoryForm} />}
        </Container>
        <Input
          size="large"
          prefix={<Icon icon="search" size={14} />}
          placeholder="Type to search"
          onChange={debounce(handleChangeSearch)}
        />
        <Spacer mb={4} />
        {showCategoryForm && <CategoryForm categoryType={categoryType} setShowCategoryForm={setShowCategoryForm} />}
      </Styled.Header>

      {itemCategories ? (
        <CategoryList />
      ) : (
        <Styled.SpinnerWrapper>
          <Spinner />
        </Styled.SpinnerWrapper>
      )}
      {!!search && !searchCategories.length && (
        <Container jc="center">
          <Typography>No result found in all search cases</Typography>
        </Container>
      )}
      {categoryError && (
        <Container mt={3}>
          <Typography color="error" size="s" fontWeight="l">
            {categoryError}
          </Typography>
        </Container>
      )}
    </Styled.Card>
  )
}
