import { Link } from 'react-router-dom'

import { route } from 'constant'

export const globalSettingsSidebarItems = [
  //TODO temporary hide
  // {
  //   label: <Link to={route.assetCodes}>Asset Codes</Link>,
  //   key: route.assetCodes,
  // },
  {
    label: <Link to={route.userManagement}>Access Management</Link>,
    key: route.accessManagement,
  },
  // {
  //   label: <Link to={route.emailSettings}>Email Settings</Link>,
  //   key: route.emailSettings,
  // },
  // {
  //   label: <Link to={route.assetStatuses}>Asset Statuses</Link>,
  //   key: route.assetStatuses,
  // },
  // {
  //   label: <Link to={route.depotCodes}>Depot Codes</Link>,
  //   key: route.depotCodes,
  // },
]
