import { FC, useCallback, useState } from 'react'
import { Popover, Tooltip } from 'antd'

import { hash as hashEntity, route } from 'constant'

import type { FontSize, ReactLinkProps } from 'designSystem'
import { Container, ReactLink, Typography } from 'designSystem'

import * as Styled from './styles'

interface RelatedLinksListProps {
  entity?: keyof typeof route
  hash?: keyof typeof hashEntity
  subRoute?: string
  data?: { id: number; title: string } | { id: number; title: string }[]
  linkProps?: Omit<ReactLinkProps, 'to'> & {
    fontSize?: FontSize
  }
}

export const RelatedLinksList: FC<RelatedLinksListProps> = ({ entity, hash, subRoute, data = [], linkProps }) => {
  const [open, setOpen] = useState(false)
  const { fontSize = 'xs', ...reactLinkProps } = linkProps || {}

  const handleOpenChanged = useCallback((opened: boolean) => {
    setOpen(opened)
  }, [])

  const isDataArray = Array.isArray(data)
  if (isDataArray ? !data.length : !data?.id) {
    return '-'
  }

  const dataWithLinks = (isDataArray ? data : [data]).map(({ id, title }) => ({
    label: title,
    href: `${entity ? route[entity] : hash ? hashEntity[hash] : ''}/${id}${subRoute || ''}`,
  }))

  const [firstLink, ...restLinks] = dataWithLinks
  const columnsCount = restLinks?.length < 3 ? restLinks.length : 3

  return (
    <Container gap={8} ai="center">
      <ReactLink to={firstLink.href} {...reactLinkProps}>
        <Tooltip title={firstLink.label} destroyTooltipOnHide>
          <Typography color="inherit" size={fontSize} fontWeight="l" lineEllipsis={1}>
            {firstLink.label}
          </Typography>
        </Tooltip>
      </ReactLink>
      {!!restLinks.length && (
        <Popover
          arrow={false}
          placement="bottom"
          trigger="click"
          afterOpenChange={handleOpenChanged}
          content={
            <Styled.ContentWrapper $columnsCount={columnsCount}>
              {restLinks.map(({ label, href }) => (
                <Typography key={href} size={fontSize} fontWeight="l" lineEllipsis={1} nowrap>
                  <ReactLink to={href} {...reactLinkProps}>
                    {label}
                  </ReactLink>
                </Typography>
              ))}
            </Styled.ContentWrapper>
          }
        >
          <Styled.CountButton extraSmall size="small" iconRight={open ? 'arrowUp' : 'arrowDown'}>
            <Typography color="info" size={fontSize}>{`+${restLinks.length}`}</Typography>
          </Styled.CountButton>
        </Popover>
      )}
    </Container>
  )
}
