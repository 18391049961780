import { purchaseOrderLabels } from 'constant'
import { PurchaseOrderSubLineAccessor } from 'types'

export const getAccessorSubLineTitle = (accessor: PurchaseOrderSubLineAccessor): string => {
  return {
    [PurchaseOrderSubLineAccessor.ExpectedDeliveryDate]: purchaseOrderLabels.items.subLines.expectedDeliveryDate,
    [PurchaseOrderSubLineAccessor.LocationCodeId]: purchaseOrderLabels.items.subLines.asset.locationCodeId,
    [PurchaseOrderSubLineAccessor.VendorDepotId]: purchaseOrderLabels.items.subLines.asset.vendorDepotId,
    [PurchaseOrderSubLineAccessor.AssetNumber]: purchaseOrderLabels.items.subLines.asset.assetNumber,
    [PurchaseOrderSubLineAccessor.IsBilled]: purchaseOrderLabels.items.subLines.asset.isBilled,
    [PurchaseOrderSubLineAccessor.IsReceived]: purchaseOrderLabels.items.subLines.asset.isReceived,
    [PurchaseOrderSubLineAccessor.ReferenceNumber]: 'Reference Number',
    [PurchaseOrderSubLineAccessor.BillingReference]: purchaseOrderLabels.items.subLines.asset.billingReference,
    [PurchaseOrderSubLineAccessor.ReferenceType]: 'Type',
  }[accessor]
}
