import { apiInstance, ApiInstanceResponse, PurchaseOrder } from 'api'

import { purchaseOrderJoinParams } from 'modules/purchaseOrders/constants/purchaseOrderJoinParams'
import { PurchaseOrderPayload } from './types/purchaseOrderCreateForm'

export const postPurchaseOrder = (data: PurchaseOrderPayload): Promise<ApiInstanceResponse<PurchaseOrder>> => {
  return apiInstance({
    method: 'post',
    url: '/api/v1/purchase-orders',
    data,
    params: {
      join: purchaseOrderJoinParams,
    },
  })
}
