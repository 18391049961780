import { FC } from 'react'
import { AvatarProps as BaseAvatarProps } from 'antd/lib/avatar'

import { User } from 'api'
import { typedMemo } from 'types'

import { getStringFirstLetter } from './utils/getStringFirstLetter'
import * as Styled from './styles'

interface AvatarProps extends BaseAvatarProps {
  user: User
}

export const AvatarBase: FC<AvatarProps> = ({ user, ...rest }) => {
  const firstNameLetter = getStringFirstLetter(user.firstName)
  const lastNameLetter = getStringFirstLetter(user.lastName)
  return (
    <Styled.Avatar src={user.avatarPath} {...rest}>
      {firstNameLetter}
      {lastNameLetter}
    </Styled.Avatar>
  )
}

export const Avatar = typedMemo(AvatarBase)
