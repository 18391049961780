export enum PaymentTerms {
  Net7 = 'net_7',
  Net15 = 'net_15',
  Net30 = 'net_30',
  Net45 = 'net_45',
  Net60 = 'net_60',
  Net90 = 'net_90',
  DueEndOfMonth = 'due_end_of_month',
  DueEndOfNextMonth = 'due_end_of_next_month',
  DueOnReceipt = 'due_on_receipt',
  Prepayment = 'prepayment',
  Custom = 'custom',
}
