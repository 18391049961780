import { FC } from 'react'

import { ReferenceNumber } from 'api'

import { Tabs } from 'designSystem'

import { getReferenceTabs } from './utils/getReferenceTabs'

interface ReferenceTabsProps {
  data?: ReferenceNumber
  canEditOnlyStatus?: boolean
}

export const ReferenceTabs: FC<ReferenceTabsProps> = ({ data, canEditOnlyStatus }) => {
  if (!data) {
    return null
  }

  const tabs = getReferenceTabs(data, canEditOnlyStatus)

  return <Tabs sticky size="small" items={tabs} isTransparentView />
}
