import { api, EmailTemplate, PurchaseOrderEmail } from 'api'

export const getPurchaseOrderEmailTemplate = (key?: PurchaseOrderEmail): Promise<EmailTemplate> => {
  return api({
    method: 'get',
    url: `/api/v1/email-templates/${key}`,
  })
}

export interface PostPurchaseOrderEmailProps {
  purchaseOrderId?: number
  payload: {
    trigger: PurchaseOrderEmail
    subject: string
    html: string
    requestId: string
  }
}

export const postPurchaseOrderEmail = ({ purchaseOrderId, payload }: PostPurchaseOrderEmailProps): Promise<void> => {
  return api({
    method: 'post',
    url: `/api/v1/purchase-orders/${purchaseOrderId}/email/send`,
    data: payload,
  })
}
