import { customerViewRoute } from 'constant'

import { NotFoundPage } from 'components'

import { CustomerDetails } from '../routes/CustomerDetails'
import { CustomerIntegrations } from '../routes/CustomerIntegrations'

export const routes = [
  {
    path: customerViewRoute.details,
    element: <CustomerDetails />,
  },
  {
    path: customerViewRoute.integrations,
    element: <CustomerIntegrations />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
