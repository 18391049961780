import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { Spinner, ZohoIntegration } from 'components'

import { useGetLocationCodeById } from '../../hooks/useGetLocationCodeById'

export const LocationCodeIntegrations: FC = () => {
  const { id } = useParams()

  const { data, isLoading } = useGetLocationCodeById(Number(id))

  if (isLoading) {
    return <Spinner />
  }

  return <ZohoIntegration module="zohoLocationsLink" id={data?.zohoId} />
}
