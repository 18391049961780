import { FC, ReactNode, useCallback, useState } from 'react'
import { Col, Row } from 'antd'

import { AddressFields } from 'api'
import { useFormContext } from 'hooks'
import { countriesOptions } from 'constant'
import { typedMemo } from 'types'

import { Container, FormItem, Icon, Input, SelectSingle, Spacer, Typography } from 'designSystem'

import { Card, CardProps } from '../../Card'
import { GooglePlacesAutocomplete } from '../../GooglePlacesAutocomplete'

interface FormItemAddressProps extends CardProps {
  name?: string
  title?: ReactNode
  hideFields?: (keyof AddressFields)[]
}

export const FormItemAddressBase: FC<FormItemAddressProps> = ({ name, title, hideFields, ...cardProps }) => {
  const { setValue, watch } = useFormContext()
  const [search, setSearch] = useState('')

  const country = watch(name ? `${name}.country` : 'country')

  const shouldShowField = useCallback(
    (fieldName: keyof AddressFields) => {
      return !hideFields?.length || !hideFields.includes(fieldName)
    },
    [hideFields],
  )

  const onChangeAutocomplete = useCallback(
    (values: AddressFields) => {
      Object.keys(values).forEach((key) => {
        if (shouldShowField(key as keyof AddressFields)) {
          setValue(name ? `${name}.${key}` : key, values[key as keyof AddressFields])
        }
      })
      setSearch('')
    },
    [setValue, name, shouldShowField],
  )

  const handleChange = useCallback((value?: string) => {
    setSearch(value || '')
  }, [])

  return (
    <Card {...cardProps}>
      <Container jc="space-between" ai="center">
        {title || (
          <Typography size="l" fontWeight="xl">
            Billing Address
          </Typography>
        )}
        <FormItem name={name ? `${name}.country` : 'country'}>
          <SelectSingle allowClear showSearch width={150} placeholder="Select country" options={countriesOptions} />
        </FormItem>
      </Container>
      <Spacer mb={5} />
      <GooglePlacesAutocomplete
        onChange={handleChange}
        value={search}
        placeholder="Search Address"
        ml={-26}
        onChangeAutocomplete={onChangeAutocomplete}
        restrictions={country ? { country } : undefined}
        prefix={<Icon icon="search" size={14} />}
      />
      <Spacer mb={3} />
      <Container display="flex" fd="column" gap={8}>
        <Row gutter={8}>
          {shouldShowField('city') && (
            <Col span={12}>
              <FormItem name={name ? `${name}.city` : 'city'} label="City">
                <Input placeholder="Type City" />
              </FormItem>
            </Col>
          )}
          {shouldShowField('state') && (
            <Col span={12}>
              <FormItem name={name ? `${name}.state` : 'state'} label="State">
                <Input placeholder="Type State" />
              </FormItem>
            </Col>
          )}
        </Row>

        <Row gutter={8}>
          {shouldShowField('street') && (
            <Col span={12}>
              <FormItem name={name ? `${name}.street` : 'street'} label="Street">
                <Input placeholder="Type Street" />
              </FormItem>
            </Col>
          )}
          {shouldShowField('zipCode') && (
            <Col span={12}>
              <FormItem name={name ? `${name}.zipCode` : 'zipCode'} label="Zip">
                <Input placeholder="Type zip" />
              </FormItem>
            </Col>
          )}
        </Row>
      </Container>
    </Card>
  )
}

export const FormItemAddress = typedMemo(FormItemAddressBase)
