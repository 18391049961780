import { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { Button, Typography } from 'designSystem'
import { ConfirmationPopover } from 'components'

import { useRemoveVendor } from './hooks/useRemoveVendor'

interface ActionButtonsProps {
  name?: string
  disabled?: boolean
}

export const ActionButtons: FC<ActionButtonsProps> = ({ name, disabled }) => {
  const { id } = useParams()
  const { remove, isPending } = useRemoveVendor(Number(id))

  const handleRemoveVendor = useCallback(() => {
    remove()
  }, [remove])
  return (
    <ConfirmationPopover
      text={
        <Typography size="xs">
          Are you sure you want to delete{' '}
          <Typography size="xs" fontWeight="xl" truncateAfterCharAt={23}>
            {name}
          </Typography>{' '}
          Vendor?
        </Typography>
      }
      onOk={handleRemoveVendor}
      isLoading={isPending}
    >
      <Button icon="delete" danger size="small" disabled={disabled} />
    </ConfirmationPopover>
  )
}
