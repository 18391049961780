import * as Yup from 'yup'

import { numberBasicValidation, resaleMarginValidation, skuValidation } from 'utils'

export const inventorySchema = Yup.object({
  title: Yup.string().max(100, 'Max length of 100 characters').required('Title is required'),
  sku: skuValidation,
  qty: numberBasicValidation.required('Qty is required'),
  price: numberBasicValidation.nullable(),
  resaleMargin: resaleMarginValidation,
  locationCodeId: Yup.number().nullable(),
})

export const validationSchema = (isAddNew?: boolean) =>
  Yup.object({
    item: isAddNew ? inventorySchema : Yup.object(),
    list: Yup.array(inventorySchema),
  })
