import isEqual from 'react-fast-compare'

import { CarrierPriceListPayloadOption } from '../types/carrierPriceListPayload'

export const preparePatchPayload = (
  payload: CarrierPriceListPayloadOption,
  data: CarrierPriceListPayloadOption[],
): CarrierPriceListPayloadOption => {
  let payloadData = { id: payload.id, type: payload.type } as CarrierPriceListPayloadOption
  const dataItem = data.find(({ id }) => id === payload.id)

  Object.keys(payload).forEach((key) => {
    const objectKey = key as keyof CarrierPriceListPayloadOption

    if (!isEqual(payload?.[objectKey], dataItem?.[objectKey])) {
      payloadData = {
        ...payloadData,
        [objectKey]: payload[objectKey],
      }
    }
  })

  return payloadData
}
