import { SalesOrderStatus } from 'api'
import { salesOrderStatusOptionsLabel } from 'constant'

import { TagType } from 'designSystem'

interface SalesOrderStatusTagData {
  status: TagType
  text?: string
}

export const getTagDataBySalesOrderStatus = (status: SalesOrderStatus): SalesOrderStatusTagData => {
  const text = salesOrderStatusOptionsLabel[status]

  const tagStatus = {
    [SalesOrderStatus.New]: TagType.InProgress,
    [SalesOrderStatus.PendingAccountingApproval]: TagType.Pending,
    [SalesOrderStatus.PaymentApproved]: TagType.Pending,
    [SalesOrderStatus.WeCarryAssets]: TagType.InProgress,
    [SalesOrderStatus.AssetProduced]: TagType.Pending,
    [SalesOrderStatus.BookingCreated]: TagType.Pending,
    [SalesOrderStatus.ReadyToShipping]: TagType.Pending,
    [SalesOrderStatus.Completed]: TagType.Success,
    [SalesOrderStatus.Cancelled]: TagType.Warning,
  }[status]

  return { status: tagStatus, text }
}
