import { Address, api, EntityId } from 'api'

interface PostCustomerShippingAddressProps {
  customerId?: EntityId
  data?: Address
}

export const postCustomerShippingAddress = ({
  customerId,
  data,
}: PostCustomerShippingAddressProps): Promise<Address> => {
  return api({
    method: 'post',
    url: `/api/v1/customers/${customerId}/shipping-addresses`,
    data,
  })
}

interface PatchCustomerShippingAddressProps {
  customerId?: EntityId
  id?: EntityId
  data?: Address
}

export const patchCustomerShippingAddress = ({
  customerId,
  id,
  data,
}: PatchCustomerShippingAddressProps): Promise<Address> => {
  return api({
    method: 'patch',
    url: `/api/v1/customers/${customerId}/shipping-addresses/${id}`,
    data,
  })
}

interface DeleteCustomerShippingAddressProps {
  customerId?: EntityId
  id?: EntityId
}

export const deleteCustomerShippingAddress = ({
  customerId,
  id,
}: DeleteCustomerShippingAddressProps): Promise<void> => {
  return api({
    method: 'delete',
    url: `/api/v1/customers/${customerId}/shipping-addresses/${id}`,
  })
}
