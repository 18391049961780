import { Tab } from 'designSystem'

import { BillNumber } from '../elements/BillNumber'
import { LinkForBillNumber } from '../elements/LinkFoBillNumber'

interface GetTabItemsProps {
  onClose: () => void
  onSubmit: (data: string) => void
}

export const getTabItems = ({ onClose, onSubmit }: GetTabItemsProps): Tab[] => {
  return [
    {
      key: 'linkForBillNumber',
      label: 'Link',
      children: <LinkForBillNumber onClose={onClose} onSubmit={onSubmit} />,
    },
    {
      key: 'billNumber',
      label: 'Bill Number',
      children: <BillNumber onClose={onClose} onSubmit={onSubmit} />,
    },
  ]
}
