import { ReferenceNumber } from 'api'

import { Tab } from 'designSystem'

import { ReferenceDetails } from '../elements/ReferenceDetails'
import { ReferenceHistory } from '../elements/ReferenceHistory'

export const getReferenceTabs = (data: ReferenceNumber, canEditOnlyStatus?: boolean): Tab[] => [
  {
    label: 'Details',
    icon: 'gridNoOutline',
    children: <ReferenceDetails data={data} canEditOnlyStatus={canEditOnlyStatus} />,
  },
  {
    label: 'History',
    icon: 'history',
    children: <ReferenceHistory id={data.id} />,
  },
]
