import { FC } from 'react'

import type { PurchaseOrderStatus } from 'api'

import { Tag, TagProps } from 'designSystem'

import { getTagDataByPurchaseOrderStatus } from './utils/getTagDataByPurchaseOrderStatus'

interface PurchaseOrderStatusTagProps extends Omit<TagProps, 'status'> {
  status: PurchaseOrderStatus
}

export const PurchaseOrderStatusTag: FC<PurchaseOrderStatusTagProps> = ({ status: poStatus, ...props }) => {
  const { status, text } = getTagDataByPurchaseOrderStatus(poStatus)
  return <Tag {...props} status={status} text={text} />
}
