import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { EntityId, GetReferenceItemsPayload } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { postReferenceItem, PostReferenceNumberItemPayload } from '../queryApi'

export const usePostReferenceItem = (referenceId?: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation({
    mutationFn: postReferenceItem,
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.ReferenceNumberItemsUpdate)
      queryClient.setQueryData(
        [QueryKey.GetReferenceItems, referenceId],
        (prev: GetReferenceItemsPayload | undefined) => {
          if (!prev) {
            return undefined
          }
          return {
            ...prev,
            data: [...prev.data, data],
          }
        },
      )
      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const post = useCallback(
    (data: PostReferenceNumberItemPayload) => {
      mutate({ referenceId, data })
    },
    [mutate, referenceId],
  )

  return {
    post,
    ...rest,
  }
}
