import { TruckCode } from 'api'
import { QueryKey } from 'enums'
import { useTable } from 'hooks'
import { defaultSortParams } from 'constant'

import { useGetTruckCodes } from './useGetTruckCodes'

export const useTruckCodes = () => {
  const { pagination, onChange, getFieldSorter, sortQueryParams, searchQueryParams, ...props } = useTable<TruckCode>({
    defaultSorters: defaultSortParams[QueryKey.GetTruckCodes],
  })

  const { data, total, isLoading, isFetching } = useGetTruckCodes({
    limit: pagination.limit,
    page: pagination.page,
    sort: sortQueryParams,
    s: searchQueryParams,
  })

  return {
    data: data || [],
    pagination: {
      ...pagination,
      current: pagination.page,
      pageSize: pagination.limit,
      total,
    },
    getFieldSorter,
    onTableChange: onChange,
    isLoading: isLoading || isFetching,
    offsetCount: (pagination.page - 1) * pagination.limit,
    ...props,
  }
}
