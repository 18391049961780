import { FC, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'antd'

import { AssetHolder, AssetNumberType } from 'api'
import { useDepotCodes, useForm, useItems, useLocationCodes, useVendors } from 'hooks'
import {
  assetConditionOptions,
  assetHolderOptions,
  assetLabels,
  assetTransactionTypeOptions,
  containerColorOptions,
  currency,
  route,
} from 'constant'
import { isDateBeforeCurrentDateEndOfDay } from 'utils'

import {
  Button,
  Container,
  DatePicker,
  Divider,
  FormItem,
  Input,
  InputNumber,
  SelectSingle,
  Spacer,
  Typography,
} from 'designSystem'
import { Breadcrumbs, Card, FormItemSelectSingleAsync, FormItemSelectSingleAsyncGroup } from 'components'

import { useCreateAsset } from './hooks/useCreateAsset'
import { validationSchema } from '../../utils/validationSchema'
import { maxItemsSKU } from '../../constants/maxRecordsCount'
import { breadcrumbsData } from './constants/breadcrumbsData'
import { AssetPayload } from '../../types/assetPayload'
import { AssetForm } from '../Asset/routes/AssetDetails/types/assetForm'
import { AssetNumberTypeView } from '../../elements/AssetNumberTypeView'

export const AssetCreate: FC = () => {
  const { Form, handleSubmit, watch, trigger, setValue } = useForm<AssetForm>({
    validationSchema,
    defaultValues: { assetNumberType: AssetNumberType.Temporary },
  })
  const { create, isPending } = useCreateAsset()

  const loanNumber = watch('loanNumber') as string
  const locationCodeId = watch('locationCodeId')
  const assetHolder = watch('assetHolder')

  const onSubmit = useCallback(
    (data: AssetPayload) => {
      create(data)
    },
    [create],
  )

  useEffect(() => {
    if (!loanNumber) {
      setValue('loanExpirationDate', undefined)
      trigger('loanExpirationDate')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanNumber])

  useEffect(() => {
    setValue('vendorDepotId', undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationCodeId])

  useEffect(() => {
    if (assetHolder === 'organization') {
      setValue('vendorId', undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetHolder])

  return (
    <>
      <Breadcrumbs breadcrumbItems={breadcrumbsData} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Container jc="center">
          <Container display="flex" jc="center" fd="column" ai="center" my={7} maxWidth={500}>
            <Card>
              <Typography fontWeight="xl" size="l">
                Create Asset
              </Typography>
              <Spacer mb={5} />
              <FormItem name="assetNumber" label={assetLabels.assetNumber}>
                <Input placeholder="Type asset number" bordered={false} uppercase size="large" />
              </FormItem>
              <AssetNumberTypeView />
              <Divider my={4} />
              <FormItem name="assetHolder" label={assetLabels.assetHolder}>
                <SelectSingle placeholder="Select" options={assetHolderOptions} />
              </FormItem>
              <Spacer mb={4} />
              {assetHolder === AssetHolder.VendorAccount && (
                <Row gutter={8}>
                  <Col span={12}>
                    <FormItemSelectSingleAsync name="vendorId" label={assetLabels.vendorId} getItems={useVendors} />
                  </Col>
                </Row>
              )}
              <Divider my={4} />
              <Row gutter={8}>
                <Col span={12}>
                  <FormItem name="birthmarkNumber" label={assetLabels.birthmarkNumber}>
                    <Input placeholder="Type birthmark number" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem name="birthmarkSize" label={assetLabels.birthmarkSize}>
                    <Input placeholder="Type birthmark size" />
                  </FormItem>
                </Col>
              </Row>
              <Divider my={4} />
              <Row gutter={8}>
                <Col span={12}>
                  <FormItem name="cost" label={assetLabels.cost}>
                    <InputNumber placeholder="00.00" precision={2} prefix={currency} />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem name="transactionType" label={assetLabels.transactionType}>
                    <SelectSingle placeholder="Select" options={assetTransactionTypeOptions} />
                  </FormItem>
                </Col>
              </Row>
              <Divider my={4} />
              <Row gutter={8}>
                <Col span={12}>
                  <FormItem name="loanNumber" label={assetLabels.loanNumber}>
                    <Input placeholder="Type loan number" />
                  </FormItem>
                </Col>
                {!!loanNumber && (
                  <Col span={12}>
                    <FormItem name="loanExpirationDate" label={assetLabels.loanExpirationDate}>
                      <DatePicker disabledDate={isDateBeforeCurrentDateEndOfDay} />
                    </FormItem>
                  </Col>
                )}
              </Row>
              <Divider my={4} />
              <Row gutter={8}>
                <Col span={12}>
                  <FormItemSelectSingleAsync
                    name="locationCodeId"
                    label={assetLabels.locationCodeId}
                    getItems={useLocationCodes}
                  />
                </Col>
                <Col span={12}>
                  <FormItemSelectSingleAsync
                    name="vendorDepotId"
                    label={assetLabels.vendorDepotId}
                    getItems={useDepotCodes}
                    disabled={!locationCodeId}
                    queryParams={{
                      ...(locationCodeId && { locationCodeId: { $eq: locationCodeId } }),
                    }}
                  />
                </Col>
              </Row>
              <Divider my={4} />
              <Row gutter={8}>
                <Col span={12}>
                  <FormItem name="condition" label={assetLabels.condition}>
                    <SelectSingle placement="topLeft" placeholder="Select" options={assetConditionOptions} />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem name="containerColor" label={assetLabels.containerColor}>
                    <SelectSingle options={containerColorOptions} placeholder="Select" />
                  </FormItem>
                </Col>
              </Row>
              <Spacer mb={3} />
              <Row gutter={8}>
                <Col span={12}>
                  <FormItem name="ageOfUnit" label={assetLabels.ageOfUnit}>
                    <DatePicker />
                  </FormItem>
                </Col>
              </Row>
            </Card>
            <Spacer mb={3} />
            <Card>
              <Container fd="column">
                <Typography fontWeight="xl" size="l">
                  {assetLabels.items}
                </Typography>
                <Spacer mb={4} />
                <FormItemSelectSingleAsyncGroup
                  isRequired
                  name="itemsIds"
                  getItems={useItems}
                  maxFieldsCount={maxItemsSKU}
                  queryParams={{ type: { $eq: 'assets' } }}
                />
              </Container>
            </Card>
            <Container jc="space-between" mt={4} width="100%">
              <Link to={route.assets}>
                <Button type="default">Cancel</Button>
              </Link>
              <Button type="primary" htmlType="submit" loading={isPending}>
                Create
              </Button>
            </Container>
          </Container>
        </Container>
      </Form>
    </>
  )
}
