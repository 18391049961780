import { FC, useCallback } from 'react'

import { AsyncSelectGetItemsProps, typedMemo } from 'types'

import { EditFormItem, SelectSingleOption } from 'designSystem'

import { FormItemEditSelectSingle, FormItemEditSelectSingleProps } from '../FormItemEditSelectSingle'

interface FormItemEditSelectSingleAsyncProps
  extends FormItemEditSelectSingleProps,
    Omit<AsyncSelectGetItemsProps, 'id'> {
  name: string
  index?: number
  getItems: (props: AsyncSelectGetItemsProps) => any
}

export const FormItemEditSelectSingleAsyncBase: FC<FormItemEditSelectSingleAsyncProps> = ({
  id,
  name,
  index,
  label,
  width = 150,
  showSearch = true,
  allowClear = true,
  disabledValues,
  onChange,
  defaultOption,
  onSubmit,
  getItems,
  nestedEntityId,
  valuePropName,
  queryParams,
  withFullDataOption,
  join,
  fields,
  isFieldViewOnly,
  ...props
}) => {
  const { options, onSearch, onPopupScrollEnd, isLoading, onChangeEnabled } = getItems({
    id: id || name,
    valuePropName,
    nestedEntityId,
    queryParams,
    withFullDataOption,
    join,
    fields,
  })

  const handleChange = useCallback(
    (fieldOnChange: (...args: any) => void) => (value?: string | number | null, option?: SelectSingleOption) => {
      if (index !== undefined) {
        fieldOnChange(option || { value: undefined, label: undefined })
      } else {
        fieldOnChange(value, option)
      }
      onChange?.(value, option)
    },
    [index, onChange],
  )

  return (
    <EditFormItem
      name={name}
      label={label}
      nestedName={props.nestedName}
      isViewOnly={isFieldViewOnly}
      render={({ field }) => (
        <FormItemEditSelectSingle
          {...field}
          {...props}
          onSubmit={onSubmit}
          onChange={handleChange(field.onChange)}
          defaultOption={defaultOption}
          allowClear={allowClear}
          showSearch={showSearch}
          options={options}
          disabledValues={disabledValues}
          onSearch={onSearch}
          onPopupScrollEnd={onPopupScrollEnd}
          onFocusChange={onChangeEnabled}
          loading={isLoading}
          width={width}
        />
      )}
    />
  )
}

export const FormItemEditSelectSingleAsync = typedMemo(FormItemEditSelectSingleAsyncBase)
