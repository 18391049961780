import { FC } from 'react'

import { VendorDepotSettings } from 'api'

import { Container, Spacer, Typography } from 'designSystem'
import { Card, GoogleMap, Spinner } from 'components'

import { DepotForm } from './elements/DepotForm'

interface DepotGeneralInfoProps {
  data?: VendorDepotSettings
  isLoading?: boolean
}

export const DepotGeneralInfo: FC<DepotGeneralInfoProps> = ({ data, isLoading }) => {
  if (isLoading) {
    return <Spinner />
  }

  if (!data) {
    return null
  }

  return (
    <Container display="flex" fd="column" ma={4}>
      <Card maxWidth={390} mb={3}>
        <Typography size="l" fontWeight="xl">
          Depot Info
        </Typography>
        <Spacer mb={4} />
        <DepotForm vendorId={Number(data.id)} data={data} />
      </Card>
      <GoogleMap
        options={{
          disableDefaultUI: true,
          zoomControl: true,
        }}
        mapContainerStyle={{ width: 390, height: 360, borderRadius: 6 }}
        center={{ lat: data.latitude, lng: data.longitude }}
        markers={[{ lat: data.latitude, lng: data.longitude, title: data.code }]}
      />
    </Container>
  )
}
