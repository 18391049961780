import { css, styled } from 'styled-components'

import { SelectMulti } from 'designSystem/components/SelectMulti'

import type { EditSelectMultiProps } from './EditSelectMulti'

export const EditSelectStyled = styled(SelectMulti)<EditSelectMultiProps>`
  width: 100%;
  min-width: ${({ width }) => (width ? `${width}px` : '80px')};
  align-self: flex-end;

  && .ant-select-selection-item-remove {
    display: flex;
  }

  && .ant-select-selection-overflow {
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    .ant-select-selection-overflow-item {
      align-self: flex-end;
    }
  }

  &&&& .ant-select-selection-search-input {
    font-size: ${({ theme }) => theme.fontSize.s};
  }
  ${({ size, prefixIcon }) => {
    if (size === 'small') {
      return css`
        font-size: ${({ theme }) => theme.fontSize.xs};
        &&& .ant-select-selection-placeholder {
          font-size: ${({ theme }) => theme.fontSize.xs};
          line-height: 18px;
        }
        &&& .ant-select-selection-item {
          margin-top: 1px;
          margin-bottom: 1px;
        }
        &&& .ant-select-clear {
          right: 3px;
        }
        &&&& .ant-select-selection-search-input {
          font-size: ${({ theme }) => theme.fontSize.xs};
        }

        &&&&&.ant-select-single .ant-select-selection-search {
          inset-inline-end: 18px;
          inset-inline-start: ${({ theme }) => (prefixIcon ? theme.spacers[5] : theme.spacers[3])};
        }
        }

        &&& .ant-select-arrow {
          inset-inline-end: 4px;
        }
        &&& .ant-select-selection-search-input {
          height: ${({ theme }) => theme.spacers[4]};
          font-size: ${({ theme }) => theme.fontSize.xs};
        }
        &&&.ant-select-single .ant-select-selector {
          height: ${({ theme }) => theme.spacers[5]};
          .ant-select-selection-search-input {
            height: ${({ theme }) => theme.spacers[4]};
          }
        }
      `
    }
    return css`
      font-size: ${({ theme }) => theme.fontSize.s};

      &&& .ant-select-selection-search-input {
        height: 26px;
        font-size: ${({ theme }) => theme.fontSize.s};
      }
      &&& .ant-select-selection-placeholder {
        font-size: ${({ theme }) => theme.fontSize.s};
        line-height: ${({ theme }) => theme.lineHeight.m};
      }
      &&&.ant-select-single .ant-select-selector {
        height: ${({ theme }) => theme.spacers[8]};
        .ant-select-selection-search-input {
          height: 26px;
        }
      }

      &&& .ant-select-selection-item > div > span {
        font-size: ${({ theme }) => theme.fontSize.s};
      }
    `
  }}

  &&&&& .ant-select-selection-search {
    inset-inline-start: ${({ theme, prefixIcon }) => (prefixIcon ? theme.spacers[5] : theme.spacers[2])};
  }
  &&&&.ant-select-single {
    ${({ prefixIcon }) => css`
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        padding-left: ${({ theme }) => (prefixIcon ? theme.spacers[4] : theme.spacers[1])};
      }
    `};
  }
`
