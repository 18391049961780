import { FC, useCallback } from 'react'

import { Contact } from 'api'
import { useFieldArray, useFormContext } from 'hooks'
import { typedMemo } from 'types'
import { getIsFormFieldViewOnly } from 'utils'

import { Button, Container } from 'designSystem'

import { EditContact } from './elements/EditContact'

interface FormItemEditContactsProps {
  name: string
  onSubmitPost: (index: number) => void
  onSubmitPatch: (index: number) => void
  onDelete: (index: number) => void
  maxContacts?: number
  isLoading?: boolean
  addButtonTitle?: string
}

export const FormItemEditContactsBase: FC<FormItemEditContactsProps> = ({
  name,
  maxContacts,
  onSubmitPost,
  onSubmitPatch,
  onDelete,
  isLoading,
  addButtonTitle = 'Add Contact Person',
}) => {
  const { watch, viewOnlyFields } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name,
  })

  const values: Contact[] = watch(name) || []

  const isFieldViewOnly = getIsFormFieldViewOnly(viewOnlyFields, name)

  const showAddButton =
    !isFieldViewOnly &&
    (maxContacts === undefined || fields.length < maxContacts) &&
    values.filter(({ id }) => !id).length < 1

  const handleDelete = useCallback(
    (index: number) => {
      remove(index)
      onDelete?.(index)
    },
    [remove, onDelete],
  )

  const handleCancel = useCallback(
    (index: number) => {
      remove(index)
    },
    [remove],
  )

  const addNewItem = () => append({})

  return (
    <Container display="flex" fd="column" ai="flex-start" gap={8}>
      {fields.map((field, index) => (
        <EditContact
          name="contacts"
          index={index}
          key={field.id}
          onSubmitPost={onSubmitPost}
          onSubmitPatch={onSubmitPatch}
          showDelete={values.filter(({ id }) => !!id)?.length > 1}
          onDelete={handleDelete}
          onCancel={handleCancel}
          isLoading={isLoading}
        />
      ))}
      {showAddButton && (
        <Button type="link" icon="plus" onClick={addNewItem}>
          {addButtonTitle}
        </Button>
      )}
    </Container>
  )
}

export const FormItemEditContacts = typedMemo(FormItemEditContactsBase)
