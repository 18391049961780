import { Asset, getAssets } from 'api'
import { QueryKey } from 'enums'
import { AsyncSelectGetItemsProps } from 'types'

import type { SelectSingleOption } from 'designSystem'

import { useAsyncList } from './useAsyncList'

export const useAssets = ({ withFullDataOption, fields, join, ...props }: AsyncSelectGetItemsProps) => {
  const { list, ...asyncListProps } = useAsyncList<Asset>({
    queryFn: getAssets,
    queryKey: QueryKey.GetAssetsList,
    searchFieldName: 'assetNumber',
    limit: 10,
    fields: fields || 'id,assetNumber,condition',
    join: join || ['items||id'],
    ...props,
  })

  const options = list.map((item) => ({
    value: item.id,
    label: item.assetNumber,
    ...(withFullDataOption && { data: item }),
  })) satisfies SelectSingleOption[]

  return {
    options,
    ...asyncListProps,
  }
}
