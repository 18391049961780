import { FieldValues, UseFormGetValues } from 'react-hook-form'

import { AssetNumberType, ReferenceNumberType } from 'api'

import { SelectSingleOption } from 'designSystem'

import { ReferenceItemsAssetsIds } from '../../../types/referenceItemsAssetsIds'

import { ReferenceItemsContextData } from '../../../contexts/referenceItemsContext'
import { getBookingTableColumns } from './getBookingTableColumns'
import { getPickupTableColumns } from './getPickupTableColumns'
import { getRedeliveryTableColumns } from './getRedeliveryTableColumns'
import { getVendorReleaseTableColumns } from './getVendorReleaseTableColumns'

export interface TableColumnsProps extends ReferenceItemsContextData {
  skuIndex: number
  fieldName: string
  onDelete: (assetIndex: number) => void
  itemId: number
  handleAssetChange: (index: number, value: any, option?: SelectSingleOption) => void
  assetsIds?: ReferenceItemsAssetsIds
  triggerSubmit?: () => void
  isFormViewOnly?: boolean
  assetNumberTypesById?: Record<string, AssetNumberType | undefined>
  getValues: UseFormGetValues<FieldValues>
}

export const getTableColumns = (type: ReferenceNumberType) => (props: TableColumnsProps) => {
  return {
    [ReferenceNumberType.VendorRelease]: getVendorReleaseTableColumns(props),
    [ReferenceNumberType.Booking]: getBookingTableColumns(props),
    [ReferenceNumberType.Redelivery]: getRedeliveryTableColumns(props),
    [ReferenceNumberType.Pickup]: getPickupTableColumns(props),
  }[type]
}
