import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import { BreadcrumbItemType } from 'antd/lib/breadcrumb/Breadcrumb'

import { typedMemo } from 'types'

import { BreadcrumbItem } from './types/breadcrumbItem'
import * as Styled from './styles'

interface BreadcrumbsProps {
  actions?: ReactNode
  breadcrumbItems: BreadcrumbItem[]
}

export const BreadcrumbsBase: FC<BreadcrumbsProps> = ({ breadcrumbItems, actions }) => {
  const items: BreadcrumbItemType[] = breadcrumbItems.map(({ title, url }) => ({
    title: url ? <Link to={url}>{title}</Link> : title,
    key: title,
  }))

  return (
    <Styled.BreadcrumbsContainer>
      <Breadcrumb items={items} />
      {actions}
    </Styled.BreadcrumbsContainer>
  )
}

export const Breadcrumbs = typedMemo(BreadcrumbsBase)
