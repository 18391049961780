import { css, styled } from 'styled-components'

import { Container, EditFormItemSize } from 'designSystem'

export const AutocompleteWrapper = styled(Container)<{ $size?: EditFormItemSize }>`
  ${({ $size }) =>
    $size === 'small' &&
    css`
      .ant-input-affix-wrapper {
        height: ${({ theme }) => theme.inlineEdit.size.s};
        padding: 0 ${({ theme }) => theme.spacers['3']};
        line-height: 18px;
      }
    `}
`
