import { assetLabels } from 'constant'

import { Accessor } from '../types/accessor'

export const getAccessorTitle = (accessor: Accessor): string => {
  return {
    [Accessor.Index]: '#',
    [Accessor.AssetNumber]: assetLabels.assetNumber,
    [Accessor.Status]: assetLabels.status,
    [Accessor.LocationCode]: assetLabels.locationCodeId,
    [Accessor.AssetHolder]: assetLabels.assetHolder,
    [Accessor.AssetNumberType]: assetLabels.assetNumberType,
    [Accessor.BirthmarkNumber]: assetLabels.birthmarkNumber,
    [Accessor.BirthmarkSize]: assetLabels.birthmarkSize,
    [Accessor.Condition]: assetLabels.condition,
    [Accessor.ItemSku]: assetLabels.items,
    [Accessor.PurchaseOrder]: assetLabels.purchaseOrderSubLine,
    [Accessor.VendorReleaseReferenceNumber]: assetLabels.vendorReleaseNumber,
    [Accessor.RedeliveryReferenceNumber]: assetLabels.redeliveryNumber,
  }[accessor]
}
