import { getPurchaseOrders, PurchaseOrder } from 'api'
import { QueryKey } from 'enums'
import { AsyncSelectGetItemsProps } from 'types'

import type { SelectSingleOption } from 'designSystem'

import { useAsyncList } from './useAsyncList'

export const usePOs = ({
  valuePropName, // keyof PO - the value of it should be of type string | number
  withFullDataOption,
  fields,
  ...props
}: AsyncSelectGetItemsProps) => {
  const { list, ...asyncSelectProps } = useAsyncList<PurchaseOrder>({
    ...props,
    queryFn: getPurchaseOrders,
    queryKey: QueryKey.GetPurchaseOrderList,
    searchFieldName: 'number',
    limit: 10,
    fields: fields || 'id,number',
  })

  const options = list.map((po) => ({
    value: valuePropName ? (po[valuePropName as keyof PurchaseOrder] as string | number) : po.id,
    label: po.number,
    ...(withFullDataOption && { data: po }),
  })) satisfies SelectSingleOption[]

  return {
    options,
    ...asyncSelectProps,
  }
}
