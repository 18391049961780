import { ReferenceNumberAssetStatus, ReferenceNumberBookingAsset } from 'api'
import { referenceNumberAssetStatusLabel } from 'constant'
import { ReferenceSubLineFormAccessor } from 'types'
import { formatDate } from 'utils'

import { Checkbox } from 'designSystem'
import type { TableColumnsType } from 'components/Table'
import { getFormattedTableCellValue } from 'components/Table'

import { getSubLineAccessorTitle } from '../../../utils/getSubLineAccessorTitle'

export const decreaseBookingAssetsTableColumns: TableColumnsType<Partial<ReferenceNumberBookingAsset>> = [
  {
    title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.Asset),
    key: ReferenceSubLineFormAccessor.Asset,
    dataIndex: ReferenceSubLineFormAccessor.Asset,
    width: 170,
    ellipsis: true,
    render: (value, record) => getFormattedTableCellValue(record.asset?.assetNumber),
  },
  {
    title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.IsModificationRequired),
    key: ReferenceSubLineFormAccessor.IsModificationRequired,
    dataIndex: ReferenceSubLineFormAccessor.IsModificationRequired,
    width: 120,
    ellipsis: true,
    render: (value) => <Checkbox checked={value} />,
  },
  {
    title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.IsRepairRequired),
    key: ReferenceSubLineFormAccessor.IsRepairRequired,
    dataIndex: ReferenceSubLineFormAccessor.IsRepairRequired,
    width: 120,
    ellipsis: true,
    render: (value) => <Checkbox checked={value} />,
  },
  {
    title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.GateOutDate),
    key: ReferenceSubLineFormAccessor.GateOutDate,
    dataIndex: ReferenceSubLineFormAccessor.GateOutDate,
    width: 120,
    ellipsis: true,
    render: (value) => getFormattedTableCellValue(formatDate(value)),
  },
  {
    title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.Status),
    key: ReferenceSubLineFormAccessor.Status,
    dataIndex: ReferenceSubLineFormAccessor.Status,
    width: 100,
    ellipsis: true,
    render: (value: ReferenceNumberAssetStatus) => referenceNumberAssetStatusLabel[value],
  },
]
