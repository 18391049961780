import { QueryFilter, TableFilter, TableFilterSingleValue } from '../types/tableFilter'

export const getFiltersQueryParams = (newFilters?: TableFilter): QueryFilter | undefined => {
  return newFilters
    ? Object.keys(newFilters).reduce(
        (
          acc:
            | Record<string, Record<string, TableFilterSingleValue['value'] | TableFilterSingleValue['value'][]>>
            | undefined,
          current,
        ) => {
          if (!acc) {
            acc = {}
          }

          const filter = newFilters[current].value

          if (Array.isArray(filter)) {
            acc[current] = { [newFilters[current].condition]: filter.map(({ value }) => value) }
          } else {
            acc[current] = { [newFilters[current].condition]: filter?.value }
          }
          return acc
        },
        undefined,
      )
    : undefined
}
