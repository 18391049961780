import { PurchaseOrderDepartment } from 'api/types/purchaseOrder'

export const purchaseOrderDepartmentLabels = {
  [PurchaseOrderDepartment.Procurement]: 'Procurement',
  [PurchaseOrderDepartment.Tracking]: 'Tracking',
  [PurchaseOrderDepartment.Parts]: 'Parts',
}

export const purchaseOrderDepartmentOptions: { value: PurchaseOrderDepartment; label: string }[] = [
  {
    value: PurchaseOrderDepartment.Procurement,
    label: purchaseOrderDepartmentLabels[PurchaseOrderDepartment.Procurement],
  },
  {
    value: PurchaseOrderDepartment.Tracking,
    label: purchaseOrderDepartmentLabels[PurchaseOrderDepartment.Tracking],
  },
  {
    value: PurchaseOrderDepartment.Parts,
    label: purchaseOrderDepartmentLabels[PurchaseOrderDepartment.Parts],
  },
]

export const getPurchaseOrderDepartmentLabel = (purchaseOrderDepartment: PurchaseOrderDepartment): string | undefined =>
  purchaseOrderDepartmentOptions.find(({ value }) => value === purchaseOrderDepartment)?.label
