import { useQuery } from '@tanstack/react-query'

import { EntityId } from 'api'
import { QueryKey } from 'enums'

import { getCarrierLocations } from '../queryApi'

export const useGetCarrierLocations = (id?: EntityId) => {
  const { data, ...rest } = useQuery({
    queryKey: [QueryKey.GetCarrierLocationsByVendorId, id],
    queryFn: () => getCarrierLocations(id),
    enabled: !!id,
  })

  const { total, data: items } = data || {}

  return {
    data: items,
    total,
    ...rest,
  }
}
