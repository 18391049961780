import { GetReferenceNumbersQueryParams, ReferenceNumber } from 'api'
import { QueryKey } from 'enums'
import { AppModule } from 'types'

import { useGetReferenceNumbers } from '../useGetReferenceNumbers'
import { useMemoCompare } from '../useMemoCompare'
import { TableFilter, useTable } from '../useTable'

interface UseReferencesTableProps {
  queryKey: QueryKey
  module?: AppModule
  enabled?: boolean
  params?: GetReferenceNumbersQueryParams
  defaultFilters?: TableFilter
}

export const useReferencesTable = ({ queryKey, params, enabled, ...props }: UseReferencesTableProps) => {
  const { pagination, onChange, getFieldSorter, sortQueryParams, searchQueryParams, ...useTableProps } =
    useTable<ReferenceNumber>({
      ...props,
    })

  const queryParams = useMemoCompare({
    limit: pagination.limit,
    page: pagination.page,
    sort: sortQueryParams,
    s: searchQueryParams,
    join: [
      'items',
      'items.item||id,sku',
      'items.subLines',
      'items.subLines.asset||id,assetNumber',
      'locationCode||id,code',
      'depotSetting||id,code,vendorId',
      'vendorRelease||id,number',
      'bookingNumber||id,number',
      'bookingNumbers||id,number',
      'redeliveryNumbers||id,number',
    ],
    ...params,
  })

  const { data, total, isLoading } = useGetReferenceNumbers({
    queryKey,
    enabled,
    params: queryParams,
  })

  const dataSource: ReferenceNumber[] = (data || []).map(({ id, ...rest }) => ({
    key: id.toString(),
    id,
    ...rest,
  }))

  return {
    dataSource,
    total,
    pagination: {
      ...pagination,
      current: pagination.page,
      pageSize: pagination.limit,
      total,
    },
    getFieldSorter,
    onChange,
    loading: isLoading,
    offsetCount: (pagination.page - 1) * pagination.limit,
    ...useTableProps,
  }
}
