import { FC, PropsWithChildren } from 'react'
import { useDraggable } from '@dnd-kit/core'

import { typedMemo } from 'types'

import * as Styled from './styles'

interface DraggableProps {
  id: number
  disabled?: boolean
  data?: any
}

export const DraggableBase: FC<PropsWithChildren<DraggableProps>> = ({ children, id, data, disabled }) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id,
    data,
    disabled,
  })

  return (
    <Styled.DragWrapper disabled={disabled} ref={setNodeRef} {...listeners} {...attributes}>
      {children}
    </Styled.DragWrapper>
  )
}

export const Draggable = typedMemo(DraggableBase)
