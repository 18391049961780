import { Accessor } from '../types/accessor'

export const getAccessorTitle = (accessor: Accessor): string => {
  return {
    [Accessor.Index]: '#',
    [Accessor.Title]: 'Title',
    [Accessor.TruckCode]: 'Truck Code',
    [Accessor.Qty]: 'Qty.',
    [Accessor.PhoneNumber]: 'Phone',
    [Accessor.Email]: 'Email',
    [Accessor.Actions]: '',
  }[accessor]
}
