import {
  AssetNumberType,
  ReferenceNumberAssetStatus,
  ReferenceNumberRedeliveryAsset,
  ReferenceNumberRedeliveryTransactionType,
  ReferenceNumberType,
} from 'api'
import { useReferences } from 'hooks'
import { assetConditionOptions, containerColorOptions, route } from 'constant'
import { ReferenceSubLineFormAccessor } from 'types'
import { getTableTitle } from 'utils'

import { Container, EditFormItem, IconDelete } from 'designSystem'
import {
  FormItemEditDatePicker,
  FormItemEditInput,
  FormItemEditSelectSingle,
  FormItemEditSelectSingleAsync,
  TableColumnKey,
  TableColumnsType,
} from 'components'

import { ResetAssetNumberButton } from '../elements/ResetAssetNumberButton'
import { SubLineStatusColumn } from '../elements/SubLineStatusColumn'

import { getSubLineAccessorTitle } from './getSubLineAccessorTitle'
import type { TableColumnsProps } from './getTableColumns'

export const getRedeliveryTableColumns = ({
  skuIndex,
  fieldName,
  onDelete,
  itemId,
  assetsIds,
  data,
  canChangeQty,
  isFieldViewOnly,
  isFormViewOnly,
  triggerSubmit,
  referenceData,
  assetNumberTypesById,
  getValues,
}: TableColumnsProps): TableColumnsType<ReferenceNumberRedeliveryAsset> => {
  const skuSubLinesFromData = data?.[skuIndex]?.subLines || []
  const transactionType = referenceData?.transactionType as ReferenceNumberRedeliveryTransactionType
  const showPickup =
    !!transactionType &&
    [
      ReferenceNumberRedeliveryTransactionType.Pickup,
      ReferenceNumberRedeliveryTransactionType.RentalSwapReturn,
      ReferenceNumberRedeliveryTransactionType.SaleSwapReturn,
    ].includes(transactionType)

  const isRowNotEditable = (index: number) =>
    getValues(`${fieldName}.${index}.${ReferenceSubLineFormAccessor.Status}`) === ReferenceNumberAssetStatus.Received

  return [
    {
      title: getTableTitle(TableColumnKey.Index),
      key: TableColumnKey.Index,
      ellipsis: true,
      render: (value, record, index) => index + 1,
    },
    {
      title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.Asset),
      key: ReferenceSubLineFormAccessor.Asset,
      dataIndex: ReferenceSubLineFormAccessor.Asset,
      width: 200,
      ellipsis: true,
      render: (value, record, index) => {
        const isNotEditable = isRowNotEditable(index)
        const asset = skuSubLinesFromData[index]?.asset
        const isAssetOriginalType = asset?.assetNumberType === AssetNumberType.Original

        return (
          <Container ai="center">
            <Container>
              <EditFormItem
                name={`${fieldName}.${index}.${ReferenceSubLineFormAccessor.AssetNumber}`}
                render={({ field }) => (
                  <FormItemEditInput
                    {...field}
                    isFieldViewOnly={isNotEditable}
                    href={`${route.assets}/${asset?.id}`}
                    align="left"
                    size="small"
                    placeholder="Asset Number"
                    disabled={!itemId}
                    onBlur={triggerSubmit}
                  />
                )}
              />
            </Container>

            {isAssetOriginalType && (
              <ResetAssetNumberButton
                subLineId={skuSubLinesFromData[index].id}
                itemId={data?.[skuIndex]?.itemId}
                disabled={isNotEditable}
              />
            )}
          </Container>
        )
      },
    },
    {
      title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.ReceivedDate),
      key: ReferenceSubLineFormAccessor.ReceivedDate,
      dataIndex: ReferenceSubLineFormAccessor.ReceivedDate,
      width: 150,
      ellipsis: true,
      render: (value, record, index) => {
        const isNotEditable = isRowNotEditable(index)

        return (
          <EditFormItem
            name={`${fieldName}.${index}.${ReferenceSubLineFormAccessor.ReceivedDate}`}
            isViewOnly={isNotEditable}
            size="small"
            render={({ field }) => (
              <FormItemEditDatePicker {...field} align="left" size="small" onBlur={triggerSubmit} />
            )}
          />
        )
      },
    },
    {
      title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.Condition),
      key: ReferenceSubLineFormAccessor.Condition,
      dataIndex: ReferenceSubLineFormAccessor.Condition,
      width: 200,
      ellipsis: true,
      render: (value, record, index) => {
        const isNotEditable = isRowNotEditable(index)

        return (
          <EditFormItem
            name={`${fieldName}.${index}.${ReferenceSubLineFormAccessor.Condition}`}
            isViewOnly={isNotEditable}
            render={({ field }) => (
              <FormItemEditSelectSingle
                {...field}
                fullwidth
                disabled={!assetsIds?.[`${skuIndex}.${index}`]}
                size="small"
                placeholder="Condition"
                options={assetConditionOptions}
                onSubmit={triggerSubmit}
              />
            )}
          />
        )
      },
    },
    {
      title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.ContainerColor),
      key: ReferenceSubLineFormAccessor.ContainerColor,
      dataIndex: ReferenceSubLineFormAccessor.ContainerColor,
      width: 200,
      ellipsis: true,
      render: (value, record, index) => {
        const isNotEditable = isRowNotEditable(index)

        return (
          <EditFormItem
            name={`${fieldName}.${index}.${ReferenceSubLineFormAccessor.ContainerColor}`}
            isViewOnly={isNotEditable}
            render={({ field }) => (
              <FormItemEditSelectSingle
                {...field}
                fullwidth
                returnDefaultValueOnBlur={false}
                allowClear
                disabled={!assetsIds?.[`${skuIndex}.${index}`]}
                size="small"
                placeholder="Container Color"
                options={containerColorOptions}
                onSubmit={triggerSubmit}
              />
            )}
          />
        )
      },
    },
    ...(showPickup
      ? [
          {
            title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.PickupReferenceId),
            key: ReferenceSubLineFormAccessor.PickupReferenceId,
            dataIndex: ReferenceSubLineFormAccessor.PickupReferenceId,
            width: 200,
            ellipsis: true,
            render: (value: number, record: ReferenceNumberRedeliveryAsset, index: number) => {
              const pickupReference = skuSubLinesFromData[index]?.pickupReference
              const assetId = assetsIds?.[`${skuIndex}.${index}`]
              const isNotEditable = isRowNotEditable(index)

              return (
                <FormItemEditSelectSingleAsync
                  name={`${fieldName}.${index}.${ReferenceSubLineFormAccessor.PickupReferenceId}`}
                  isFieldViewOnly={isNotEditable || isFieldViewOnly?.(ReferenceSubLineFormAccessor.PickupReferenceId)}
                  fullwidth
                  defaultOption={
                    pickupReference ? { value: pickupReference.id, label: pickupReference.number } : undefined
                  }
                  returnDefaultValueOnBlur={false}
                  href={route.reference}
                  isCreateMode={!pickupReference}
                  size="small"
                  getItems={useReferences}
                  disabled={!assetId}
                  queryParams={{
                    type: { $eq: ReferenceNumberType.Pickup },
                    ...(assetId && { 'items.subLines.assetId': { $eq: assetId } }),
                  }}
                  join={['items||subLines', 'items.subLines||assetId']}
                  onSubmit={triggerSubmit}
                />
              )
            },
          },
        ]
      : []),
    {
      title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.Status),
      key: ReferenceSubLineFormAccessor.Status,
      dataIndex: ReferenceSubLineFormAccessor.Status,
      width: 200,
      ellipsis: true,
      align: 'right',
      render: (value, record, index) => {
        const asset = skuSubLinesFromData[index]?.asset
        const assetId = assetsIds?.[`${skuIndex}.${index}`]
        const assetNumberType = assetId ? assetNumberTypesById?.[assetId] : undefined

        return (
          <SubLineStatusColumn
            recordName={`${fieldName}.${index}`}
            fieldName={ReferenceSubLineFormAccessor.Status}
            assetNumberType={asset?.assetNumberType || assetNumberType}
          />
        )
      },
    },
    ...(canChangeQty && !isFormViewOnly
      ? [
          {
            key: TableColumnKey.HoverActions,
            dataIndex: TableColumnKey.HoverActions,
            ellipsis: true,
            render: (value: undefined, record: ReferenceNumberRedeliveryAsset, index: number) => {
              const isLastSubLine = skuSubLinesFromData.length === 1
              return isLastSubLine ? null : (
                <Container jc="flex-end">
                  <IconDelete onClick={() => onDelete?.(index)} />
                </Container>
              )
            },
          },
        ]
      : []),
  ]
}
