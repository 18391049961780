import { useCallback, useState } from 'react'

import { UserSettingsService } from '../services'
import type { AppModule } from 'types'

interface UsePaginationProps {
  module?: AppModule
  limit?: number
}

export const usePagination = ({ module, limit: limitControlled }: UsePaginationProps) => {
  const [limit, setLimit] = useState(limitControlled || UserSettingsService.getTablePageLimit())
  const [page, onChange] = useState(1)

  const onShowSizeChange = useCallback(
    (current: number, size: number) => {
      setLimit(size)
      onChange(1) // reset page to get correct data
      if (module) {
        UserSettingsService.setTablePageLimit(size)
      }
    },
    [module],
  )

  return {
    limit,
    page,
    onChange,
    onShowSizeChange,
    showSizeChanger: true,
    hideOnSinglePage: true,
  }
}
