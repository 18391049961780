import { useQuery } from '@tanstack/react-query'

import { EntityId } from 'api'
import { QueryKey } from 'enums'

import { getCustomerUccAddresses } from '../queryApi'

export const useGetCustomerUccAddresses = (id: EntityId, enabled?: boolean) => {
  const { data, ...rest } = useQuery({
    queryKey: [QueryKey.GetCustomerUccAddresses, id],
    queryFn: () => getCustomerUccAddresses(id),
    enabled: !!id && !!enabled,
  })

  return {
    data: data?.data,
    ...rest,
  }
}
