import { api, EntityId } from 'api'

export interface PostVendorFilePayload {
  vendorId: EntityId
  formData: FormData
}

export const postFileToModifications = ({ vendorId, formData }: PostVendorFilePayload) => {
  return api({
    method: 'post',
    url: `/api/v1/vendors/${vendorId}/modifications/upload`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const postFileToInventory = ({ vendorId, formData }: PostVendorFilePayload) => {
  return api({
    method: 'post',
    url: `/api/v1/vendors/${vendorId}/types/container-supplier/inventory/upload`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })
}

export const postFileToPartsInventory = ({ vendorId, formData }: PostVendorFilePayload) => {
  return api({
    method: 'post',
    url: `/api/v1/vendors/${vendorId}/types/parts/inventory/upload`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })
}
