import { FC, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { envVariables, route } from 'constant'

import { Container, Icon } from 'designSystem'

import { headerNavigationItems } from './constants'
import { HeaderAvatar } from './elements'
import { Notifications } from './elements/Notifications'
import * as Styled from './styles'

export const Header: FC = () => {
  const location = useLocation()
  const firstPath = `/${location.pathname.split('/')[1]}`
  const menuItemsKeys = headerNavigationItems.reduce((acc, item) => {
    if (item.children) {
      item?.children.forEach((child) => {
        acc.push(child.key)
      })
    } else {
      acc.push(item?.key)
    }
    return acc
  }, [] as string[])
  const menuItemLocationExist = menuItemsKeys.includes(firstPath)
  const defaultMenuItem = menuItemLocationExist ? firstPath : null
  const [activeMenuItem, setActiveMenuItem] = useState<string | null>(defaultMenuItem)

  const handleClickMenuItem = useCallback(({ key }: { key: string }) => {
    setActiveMenuItem(key)
  }, [])

  useEffect(() => {
    if (menuItemLocationExist) {
      setActiveMenuItem(firstPath)
    } else {
      setActiveMenuItem(null)
    }
  }, [menuItemLocationExist, firstPath])

  return (
    <Styled.Header>
      <Styled.Link to={route.home}>
        <Icon icon="logo" />
      </Styled.Link>
      <Styled.Navigation
        mode="horizontal"
        overflowedIndicator={<Icon icon="dots" size={24} />}
        selectedKeys={activeMenuItem ? [activeMenuItem] : []}
        items={headerNavigationItems}
        onClick={handleClickMenuItem}
      />
      <Container gap="8" ai="center" ml={4}>
        {/* TODO show after module will be ready */}
        {envVariables.env.isDevelopment && <Notifications />}
        <HeaderAvatar />
      </Container>
    </Styled.Header>
  )
}
