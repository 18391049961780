import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { ApiHeader, EntityId, PurchaseOrderItem } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'
import { useSetPurchaseOrderPayload, useSetPurchaseOrderRequestId } from 'store'

import { showNotification } from 'designSystem'

import { deletePurchaseOrderSubLine } from '../queryApi'

export const useDeletePurchaseOrderSubLine = (purchaseOrderId: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const setPurchaseOrderRequestId = useSetPurchaseOrderRequestId()
  const setPurchaseOrderPayload = useSetPurchaseOrderPayload()

  const { mutate, ...rest } = useMutation({
    mutationFn: deletePurchaseOrderSubLine,
    onSuccess: ({ headers }, { itemId, subLineId }) => {
      setPurchaseOrderRequestId(headers[ApiHeader.RequestId])
      dispatchAppEvent(AppEvent.PurchaseOrderSubLineDeleted)
      // Hard-codded for send email trigger action
      setPurchaseOrderPayload({ items: [{ qty: 1 }] })
      queryClient.setQueryData(
        [QueryKey.GetPurchaseOrderItems, purchaseOrderId],
        (prev: PurchaseOrderItem[] | undefined) => {
          const updatedPurchaseOrderItems = prev?.reduce((acc, purchaseItem) => {
            if (purchaseItem.id === itemId) {
              if (purchaseItem.subLines?.length === 1) {
                return acc
              } else {
                acc.push({
                  ...purchaseItem,
                  qty: purchaseItem.qty - 1,
                  subLines: purchaseItem.subLines?.filter(({ id }) => id !== subLineId),
                })
              }
            } else {
              acc.push(purchaseItem)
            }
            return acc
          }, [] as PurchaseOrderItem[])

          return updatedPurchaseOrderItems
        },
      )

      showNotification({ title: 'SubLine deleted', type: 'success' })
    },
  })

  const removeSubLine = useCallback(
    ({ itemId, subLineId }: { itemId: EntityId; subLineId: EntityId }) => {
      mutate({ itemId, purchaseOrderId, subLineId })
    },
    [mutate, purchaseOrderId],
  )

  return {
    removeSubLine,
    ...rest,
  }
}
