import { FC, forwardRef, Ref, useCallback, useState } from 'react'
import type { UploadProps as BaseUploadProps } from 'antd'
import type { UploadChangeParam, UploadFile } from 'antd/lib/upload'

import { typedMemo } from 'types'
import theme from 'styles/theme'

import * as Styled from './styles'

import { Button } from '../Button'
import { Icon } from '../Icon'
import { showNotification } from '../Notification'
import { Typography } from '../Typography'

export interface UploadProps extends Omit<BaseUploadProps, 'onChange'> {
  error?: string
  maxFileSize?: number
  onChange?: (fileList: UploadFile<File>[]) => void
}

export const UploadBase: FC<UploadProps> = forwardRef(
  (
    {
      multiple = false,
      maxCount = 1,
      beforeUpload = () => false,
      // maxFileSize in megabytes
      maxFileSize = 5,
      error,
      onChange,
      accept,
      ...props
    },
    ref: Ref<HTMLDivElement>,
  ) => {
    const [fileList, setFileList] = useState<UploadFile<File>[]>([])

    const handleChange = useCallback(
      (info: UploadChangeParam<UploadFile<File>>) => {
        const { file } = info
        const isLessThanMaxFileSize = file.size ? file.size / 1024 / 1024 < maxFileSize : true
        const isAllowedFormat = accept
          ? accept.split(',').every((fileExtension) => file.name.endsWith(fileExtension))
          : true

        if (!isLessThanMaxFileSize) {
          showNotification({
            title: `${file.name} must be smaller than ${maxFileSize}MB`,
            type: 'error',
          })
        }
        if (!isAllowedFormat) {
          showNotification({ title: `${file.name} is not allowed format`, type: 'error' })
        }

        if (!isLessThanMaxFileSize || !isAllowedFormat) {
          return
        }

        setFileList(info.fileList)
        onChange?.(info.fileList)
      },
      [onChange, accept, maxFileSize],
    )

    return (
      <Styled.Dragger
        accept={accept}
        beforeUpload={beforeUpload}
        {...props}
        fileList={fileList}
        hideUpload={fileList.length >= maxCount}
        maxCount={maxCount}
        error={error}
        multiple={multiple}
        onChange={handleChange}
        itemRender={(originNode, file, fileList, actions) => {
          return (
            <Styled.UploadFileRow ai="center" jc="space-between" py={3}>
              {file.name} <Button size="small" onClick={() => actions.remove()} icon="delete" />
            </Styled.UploadFileRow>
          )
        }}
      >
        <div ref={ref}>
          <Icon icon="upload" color={theme.colors.secondary} />
        </div>
        <Typography fontWeight="l" size="xs">
          Drop file to attach, or{' '}
          <Typography fontWeight="l" size="xs" color="info">
            browse.
          </Typography>
        </Typography>
      </Styled.Dragger>
    )
  },
)

export const Upload = typedMemo(UploadBase)
