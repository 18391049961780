import { ErrorCode } from 'enums'

import { ErrorMessage } from './types/ErrorMessage'

export const errorMessages: Partial<ErrorMessage> = {
  [ErrorCode.UserNotFound]: 'User does not exist',
  [ErrorCode.TooManyRequests]: 'Too many requests. Please try again later',
  [ErrorCode.PasswordIsTheSame]: 'Password cannot be the same as the old one',
  [ErrorCode.ResetPasswordSessionClosed]: 'Link has expired',
  [ErrorCode.EmailIsAlreadyExists]: 'User with such email already exists',
  [ErrorCode.CodeIsAlreadyExists]: 'Code is already in use',
  [ErrorCode.TitleIsAlreadyExists]: 'Title is already in use',
  [ErrorCode.OldPasswordIsWrong]: 'Old password is wrong',
  [ErrorCode.AssetNumberIsAlreadyExists]: 'Asset number is already in use',
  [ErrorCode.AssetNotFound]: 'Asset does not exist',
  [ErrorCode.ResendInviteEmailTooManyRequests]: 'Too many requests. Please try again later',
  [ErrorCode.SkuIsAlreadyExists]: 'SKU is already in use',
  [ErrorCode.ItemCategoryNotFound]: 'Item category not found',
  [ErrorCode.LocationCodeNotFound]: 'Location code not found',
  [ErrorCode.AssetCodeNotFound]: 'Asset code not found',
  [ErrorCode.ItemCategorySingleCannotHaveChildren]: 'Item category single can not have children',
  [ErrorCode.ResalePriceOverwriteRuleIsAlreadyExists]: 'Such resale price overwrite rule already exists',
  [ErrorCode.VendorSkuIsAlreadyExists]: 'Vendor SKU is already used',
  [ErrorCode.ItemSkuIsAlreadyExists]: 'Internal SKU is already used',
  [ErrorCode.VendorSkuMappingIsAlreadyExists]: 'Vendor SKU mapping is already exists',
  [ErrorCode.UqPriceListTruckCodeTypeIsAlreadyExists]: 'Truck Code is already in use',
  [ErrorCode.ReferenceNumberIsAlreadyExists]: 'Reference Number already exists',
  [ErrorCode.BookingReferenceStatusToClose]:
    "You can't change status to Closed when there are not filled assets fields",
  [ErrorCode.TruckCodeAlreadyExists]: 'Truck Code already exists',
  [ErrorCode.DeletingUserForbidden]: "You can't delete this user because it's in use elsewhere in the system",
  [ErrorCode.VendorDepotRedeliveryEmailsIsEmpty]: 'Depot emails not found',
  [ErrorCode.PurchaseOrderContactsNotFound]: 'PO contacts not found',
  [ErrorCode.PurchaseOrderItemNotFound]: 'PO item not found',
  [ErrorCode.SalesOrderNotFound]: 'Sales order not found',
  [ErrorCode.ReferenceIsAlreadyExists]: 'Reference is already in use',
}
