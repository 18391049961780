import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { EntityId, GetReferenceItemsPayload } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { resetReferenceAssetNumber } from '../queryApi'

interface PostContactFromVendorToPOProps {
  referenceId: EntityId
  itemId?: EntityId
  subLineId: EntityId
}

export const useResetReferenceAssetNumber = ({ referenceId, itemId, subLineId }: PostContactFromVendorToPOProps) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation({
    mutationFn: resetReferenceAssetNumber,
    onSuccess: (data, payload) => {
      dispatchAppEvent(AppEvent.ReferenceNumberPatchAssetNumber)

      queryClient.setQueryData(
        [QueryKey.GetReferenceItems, payload.referenceId],
        (items: GetReferenceItemsPayload | undefined) => {
          if (!items) return
          const updatedItems = items.data.map((item) => {
            if (item.itemId === payload.itemId) {
              return {
                ...item,
                subLines: item.subLines?.map((subLine) => {
                  if (subLine.id === payload.subLineId) {
                    return {
                      ...subLine,
                      ...(subLine.asset && {
                        asset: {
                          ...subLine.asset,
                          ...data.asset,
                        },
                      }),
                    }
                  }
                  return subLine
                }),
              }
            }
            return item
          })
          return {
            ...items,
            data: updatedItems,
          }
        },
      )

      showNotification({ title: 'Asset Number updated', type: 'success' })
    },
  })

  const patchReferenceAssetNumber = useCallback(() => {
    mutate({ referenceId, itemId, subLineId })
  }, [mutate, referenceId, itemId, subLineId])

  return {
    patchReferenceAssetNumber,
    ...rest,
  }
}
