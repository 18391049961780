import { api, EntityId, LocationCode } from 'api'

import { LocationCodePayload } from '../../../../types/locationCodePayload'

export interface UpdateLocationCodePayload {
  id?: EntityId
  data: Partial<LocationCodePayload>
}

export const patchLocationCode = ({ id, data }: UpdateLocationCodePayload): Promise<LocationCode> => {
  return api({
    method: 'patch',
    url: `/api/v1/location-codes/${id}`,
    data,
  })
}
