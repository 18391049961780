import { Asset } from 'api'
import type { TableField, TableSortingOrder } from 'hooks'
import { route } from 'constant'
import { formatDate, getFormattedPriceWithCurrency } from 'utils'

import { ReactLink, Typography } from 'designSystem'
import { AssetStatusTag, RelatedLinksList, TableColumnKey, TableColumnsType } from 'components'

import { DepotAccessor } from '../types/depotAccessor'

import { getTableTitle } from './getTableTitle'

export const getTableColumns = (
  getFieldSorter: (field: TableField) => TableSortingOrder,
  offsetCount: number,
): TableColumnsType<Asset> => [
  {
    title: getTableTitle(DepotAccessor.Index),
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => offsetCount + index + 1,
  },
  {
    title: getTableTitle(DepotAccessor.AssetNumber),
    key: DepotAccessor.AssetNumber,
    dataIndex: DepotAccessor.AssetNumber,
    width: 200,
    sorter: true,
    sortOrder: getFieldSorter(DepotAccessor.AssetNumber),
    ellipsis: true,
    render: (assetNumber: Asset[DepotAccessor.AssetNumber], record) => {
      return (
        <ReactLink to={`${route.assets}/${record.id}`}>
          <Typography color="inherit" size="xs" fontWeight="l" lineEllipsis={1}>
            {assetNumber}
          </Typography>
        </ReactLink>
      )
    },
  },
  {
    title: getTableTitle(DepotAccessor.ItemSku),
    key: DepotAccessor.ItemSku,
    dataIndex: DepotAccessor.ItemSku,
    width: 200,
    ellipsis: true,
    render: (items: Asset[DepotAccessor.ItemSku]) => {
      const data = items?.map(({ id, sku }) => ({
        id,
        title: sku,
      }))
      return <RelatedLinksList entity="items" data={data} />
    },
  },
  {
    title: getTableTitle(DepotAccessor.Cost),
    key: DepotAccessor.Cost,
    dataIndex: DepotAccessor.Cost,
    width: 200,
    ellipsis: true,
    render: (cost: Asset[DepotAccessor.Cost]) => (cost ? getFormattedPriceWithCurrency(cost) : '-'),
  },
  {
    title: getTableTitle(DepotAccessor.Status),
    key: DepotAccessor.Status,
    dataIndex: DepotAccessor.Status,
    width: 200,
    ellipsis: true,
    render: (status: Asset[DepotAccessor.Status]) => (status ? <AssetStatusTag status={status} /> : '-'),
  },
  {
    title: getTableTitle(DepotAccessor.CreatedAt),
    key: DepotAccessor.CreatedAt,
    dataIndex: DepotAccessor.CreatedAt,
    width: 120,
    ellipsis: true,
    render: (date: Asset[DepotAccessor.CreatedAt]) => formatDate(date),
  },
]
