import { PaymentTerms } from 'api/types/paymentTerms'

export const paymentTermsLabel = {
  [PaymentTerms.Net7]: 'Net 7',
  [PaymentTerms.Net15]: 'Net 15',
  [PaymentTerms.Net30]: 'Net 30',
  [PaymentTerms.Net45]: 'Net 45',
  [PaymentTerms.Net60]: 'Net 60',
  [PaymentTerms.Net90]: 'Net 90',
  [PaymentTerms.DueEndOfMonth]: 'Due End of Month',
  [PaymentTerms.DueEndOfNextMonth]: 'Due End of Next Month',
  [PaymentTerms.DueOnReceipt]: 'Due on Receipt',
  [PaymentTerms.Prepayment]: 'Prepayment',
  [PaymentTerms.Custom]: 'Custom',
}

export const paymentTermsOptions = [
  {
    value: PaymentTerms.Net7,
    label: paymentTermsLabel[PaymentTerms.Net7],
  },
  {
    value: PaymentTerms.Net15,
    label: paymentTermsLabel[PaymentTerms.Net15],
  },
  {
    value: PaymentTerms.Net30,
    label: paymentTermsLabel[PaymentTerms.Net30],
  },
  {
    value: PaymentTerms.Net45,
    label: paymentTermsLabel[PaymentTerms.Net45],
  },
  {
    value: PaymentTerms.Net60,
    label: paymentTermsLabel[PaymentTerms.Net60],
  },
  {
    value: PaymentTerms.Net90,
    label: paymentTermsLabel[PaymentTerms.Net90],
  },
  {
    value: PaymentTerms.DueEndOfMonth,
    label: paymentTermsLabel[PaymentTerms.DueEndOfMonth],
  },
  {
    value: PaymentTerms.DueEndOfNextMonth,
    label: paymentTermsLabel[PaymentTerms.DueEndOfNextMonth],
  },
  {
    value: PaymentTerms.DueOnReceipt,
    label: paymentTermsLabel[PaymentTerms.DueOnReceipt],
  },
  {
    value: PaymentTerms.Prepayment,
    label: paymentTermsLabel[PaymentTerms.Prepayment],
  },
  {
    value: PaymentTerms.Custom,
    label: paymentTermsLabel[PaymentTerms.Custom],
  },
]

export const getPaymentTermLabel = (purchaseOrderPaymentTerm: PaymentTerms): string | undefined =>
  paymentTermsOptions.find(({ value }) => value === purchaseOrderPaymentTerm)?.label
