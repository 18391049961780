import { DatePicker } from 'antd'
import { styled } from 'styled-components'

import { DatePickerStyles } from 'styles/datePickerStyles'

import type { DateRangePickerProps } from './DateRangePicker'

const { RangePicker } = DatePicker

export const DateRangePicker = styled(RangePicker)<Omit<DateRangePickerProps, 'format'>>`
  ${DatePickerStyles}
`
