import { FC } from 'react'

import type { PurchaseOrderBillingStatus } from 'api'

import { Tag, TagProps } from 'designSystem'

import { getTagDataByPurchaseOrderStatus } from './utils/getTagDataByPurchaseOrderBillingStatus'

interface PurchaseOrderBillingStatusTagProps extends Omit<TagProps, 'status'> {
  status: PurchaseOrderBillingStatus
}

export const PurchaseOrderBillingStatusTag: FC<PurchaseOrderBillingStatusTagProps> = ({
  status: poStatus,
  ...props
}) => {
  const { status, text } = getTagDataByPurchaseOrderStatus(poStatus)
  return <Tag {...props} status={status} text={text} />
}
