import * as Yup from 'yup'

import { numberBasicValidation } from 'utils'

export const validationSchema = Yup.object({
  locations: Yup.array(
    Yup.object({
      id: Yup.number(),
      locationCode: Yup.object({
        value: numberBasicValidation.required('Location is required'),
        label: Yup.string(),
      }),
      boundary: numberBasicValidation
        .min(0, 'Min boundary should be more than 0 miles')
        .max(5000, 'Max boundary is 5000 miles')
        .required('Boundary is required'),
    }).required(),
  ).required(),
})
