import { FC, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useForm } from 'hooks'
import { route } from 'constant'
import { SalesOrderFormAccessor } from 'types'

import { Button, Container, Spacer, Typography } from 'designSystem'
import { Breadcrumbs, Card, NewContact } from 'components'

import { useCreateSalesOrder } from './hooks/useCreateSalesOrder'
import { breadcrumbsData } from './constants/breadcrumbsData'
import { salesOrderCreateSchema } from 'modules/salesOrders/constants/fieldsValidation'
import { SalesOrderCreateForm, SalesOrderFormData } from './types/salesOrderCreateForm'
import { SalesOrderAddContact } from './elements/SalesOrderAddContact'
import { SalesOrderForm } from './elements/SalesOrderForm'
import { SalesOrderLineItems } from './elements/SalesOrderLineItems'

export const SalesOrderCreate: FC = () => {
  const [contacts, setContacts] = useState<NewContact[]>([])
  const [onSiteContacts, setOnSiteContacts] = useState<NewContact[]>([])

  const { Form, handleSubmit, unregister, watch } = useForm<SalesOrderCreateForm>({
    validationSchema: salesOrderCreateSchema,
    defaultValues: {
      shouldSendEmail: false,
      isWeekendDelivery: false,
      isReceivingSeparately: false,
      // Backend requires this field to be an empty array
      dayWeekendDelivery: [],
    },
    mode: 'onChange',
  })

  const { create, isPending } = useCreateSalesOrder()
  const isWeekend = watch(SalesOrderFormAccessor.IsWeekendDelivery)

  const onSubmit = useCallback(
    (data: SalesOrderFormData) => {
      create({ ...data, contacts, onSiteContacts })
    },
    [contacts, create, onSiteContacts],
  )

  useEffect(() => {
    if (!isWeekend) {
      unregister(SalesOrderFormAccessor.DayWeekendDelivery)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWeekend])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Breadcrumbs breadcrumbItems={breadcrumbsData} />
      <Container ml={4} mr={4} mt={6} ai="center" jc="space-between" minHeight={32}>
        <Typography fontWeight="xl" size="l">
          Create Sales Order
        </Typography>
        <Container gap={8}>
          <SalesOrderAddContact
            contacts={contacts}
            setContacts={setContacts}
            contactType={SalesOrderFormAccessor.ContactsIds}
          />
          <SalesOrderAddContact
            contacts={onSiteContacts}
            setContacts={setOnSiteContacts}
            buttonText={'Add On-Site Contact'}
            contactType={SalesOrderFormAccessor.OnSiteContactsIds}
          />
        </Container>
      </Container>

      <Container fd="column" ai="center" jc="center" my={4}>
        <Card fullwidth>
          <SalesOrderForm
            contacts={contacts}
            setContacts={setContacts}
            onSiteContacts={onSiteContacts}
            setOnSiteContacts={setOnSiteContacts}
          />
          <Spacer mb={11} />
          <SalesOrderLineItems />
        </Card>

        <Container mt={5} jc="space-between" width="100%" px={4}>
          <Link to={route.salesOrders}>
            <Button px={13} type="default">
              Cancel
            </Button>
          </Link>
          <Button px={13} type="primary" htmlType="submit" loading={isPending}>
            Save
          </Button>
        </Container>
      </Container>
    </Form>
  )
}
