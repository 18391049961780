import { FC } from 'react'
import type { MenuProps } from 'antd'

import { ItemCategoryType } from 'api'
import theme from 'styles/theme'

import { Button, Icon, Typography } from 'designSystem'

import * as Styled from './styles'

interface AddCategoryProps {
  handleShowCategoryForm: (type: ItemCategoryType) => void
}

export const AddCategory: FC<AddCategoryProps> = ({ handleShowCategoryForm }) => {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Styled.MenuItem onClick={() => handleShowCategoryForm(ItemCategoryType.Single)}>
          <Icon icon="category" color={theme.colors.secondary} />
          <Typography fontWeight="l" size="s">
            Single Category
          </Typography>
        </Styled.MenuItem>
      ),
    },
    {
      key: '2',
      label: (
        <Styled.MenuItem onClick={() => handleShowCategoryForm(ItemCategoryType.Folder)}>
          <Icon icon="folder" color={theme.colors.secondary} />
          <Typography fontWeight="l" size="s">
            Category Group
          </Typography>
        </Styled.MenuItem>
      ),
    },
  ]

  return (
    <Styled.Dropdown menu={{ items }} trigger={['click']}>
      <Button icon="plus" type="link">
        Add new
      </Button>
    </Styled.Dropdown>
  )
}
