import { Dispatch, FC, SetStateAction } from 'react'
import { Row } from 'antd'

import { ContactFormData } from '../../types/salesOrderCreateForm'
import { CustomerInformation } from './elements/CustomerInformation'
import { DateInformation } from './elements/DateInformation'
import { InvoiceInformation } from './elements/InvoiceInformation'
import { NotesInformation } from './elements/NotesInformation'
import * as Styled from './styles'

interface SalesOrderFormProps {
  contacts: ContactFormData[]
  onSiteContacts: ContactFormData[]
  setContacts: Dispatch<SetStateAction<ContactFormData[]>>
  setOnSiteContacts: Dispatch<SetStateAction<ContactFormData[]>>
}

export const SalesOrderForm: FC<SalesOrderFormProps> = ({
  contacts,
  onSiteContacts,
  setContacts,
  setOnSiteContacts,
}) => {
  return (
    <Row>
      <Styled.Col span={6}>
        <CustomerInformation contacts={contacts} setContacts={setContacts} />
      </Styled.Col>
      <Styled.Col span={6}>
        <InvoiceInformation />
      </Styled.Col>
      <Styled.Col span={6}>
        <DateInformation onSiteContacts={onSiteContacts} setOnSiteContacts={setOnSiteContacts} />
      </Styled.Col>
      <Styled.Col span={6}>
        <NotesInformation />
      </Styled.Col>
    </Row>
  )
}
