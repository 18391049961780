import { FC } from 'react'

import { VendorCarrierSettingsType } from 'api'

import { Checkbox, Container, Divider, SelectSingleOption, Typography } from 'designSystem'

interface InfoGroup {
  disabled?: boolean
  mainOption?: VendorCarrierSettingsType
  options: SelectSingleOption[]
  withDivider?: boolean
}

export const InfoGroup: FC<InfoGroup> = ({ disabled, options, mainOption, withDivider }) => {
  return (
    <Container fd="column" width="100%">
      {options.map((option, index) => {
        const isMainOption = mainOption === option.value
        const paddingTop = isMainOption || index === 1 ? undefined : 3
        const paddingBottom = isMainOption || index === options.length - 1 ? undefined : 3
        return (
          <Container key={option.value} mb={isMainOption ? 7 : undefined}>
            <Container jc="space-between" width="100%" pt={paddingTop} pb={paddingBottom}>
              <Checkbox value={option.value} disabled={disabled && !isMainOption}>
                <Typography size={isMainOption ? 's' : 'xs'} fontWeight="l">
                  {option.label}
                </Typography>
              </Checkbox>
            </Container>
            {withDivider && <Divider vertical />}
          </Container>
        )
      })}
    </Container>
  )
}
