import * as Yup from 'yup'

import {
  AssetCodeAdditionalAttribute,
  AssetCodeGrade,
  AssetCodeHeight,
  AssetCodeLength,
  AssetCodeType,
  AssetCodeWidth,
  ItemType,
} from 'api'
import { numberBasicValidation, skuValidation } from 'utils'

import { SelectSingleOption } from 'designSystem/components'

export const validationSchema = Yup.object({
  name: Yup.string().max(100, 'Max length of 100 characters').required('Name is required'),
  description: Yup.string().max(250, 'Max length of 250 characters').required('Description is required'),
  sku: skuValidation.test('skuDuplicates', 'This SKU is already selected in the Grouped SKU', (value, context) => {
    const groupedSKUs = context?.parent?.groupedSKUs || []
    const result = groupedSKUs.some((item: SelectSingleOption) => item?.label === value)
    return !result
  }),
  type: Yup.mixed<ItemType>().oneOf(Object.values(ItemType)).required('Type is required'),
  locationCodeId: Yup.number().nullable().default(null),
  categoryId: Yup.number().required('Category is required'),
  price: numberBasicValidation
    .required('Price is required')
    .positive('Should be positive number')
    .max(1000000, 'Should be less than 1,000,000'),
  groupedSKUs: Yup.array().default([]),
  weight: numberBasicValidation.nullable(),
  assetType: Yup.mixed<AssetCodeType>().when('type', {
    is: ItemType.Assets,
    then: (schema) => schema.oneOf(Object.values(AssetCodeType)).required('Asset Type is required'),
    otherwise: (schema) => schema.nullable().default(null),
  }),
  length: Yup.mixed<AssetCodeLength>().when('type', {
    is: ItemType.Assets,
    then: (schema) =>
      schema
        .oneOf(Object.keys(AssetCodeLength).map((key) => AssetCodeLength[key as keyof typeof AssetCodeLength]))
        .required('Length is required'),
    otherwise: (schema) => schema.nullable().default(null),
  }),
  width: Yup.mixed<AssetCodeWidth>().when('type', {
    is: ItemType.Assets,
    then: (schema) =>
      schema
        .oneOf(Object.keys(AssetCodeWidth).map((key) => AssetCodeWidth[key as keyof typeof AssetCodeWidth]))
        .required('Width is required'),
    otherwise: (schema) => schema.nullable().default(null),
  }),
  height: Yup.mixed<AssetCodeHeight>().when('type', {
    is: ItemType.Assets,
    then: (schema) =>
      schema
        .oneOf(Object.keys(AssetCodeHeight).map((key) => AssetCodeHeight[key as keyof typeof AssetCodeHeight]))
        .required('Height is required'),
    otherwise: (schema) => schema.nullable().default(null),
  }),
  grade: Yup.mixed<AssetCodeGrade>().when('type', {
    is: ItemType.Assets,
    then: (schema) => schema.oneOf(Object.values(AssetCodeGrade)).required('Grade is required'),
    otherwise: (schema) => schema.nullable().default(null),
  }),
  additionalAttributes: Yup.array(Yup.mixed<AssetCodeAdditionalAttribute>().required('Additional Attributes required'))
    .nullable()
    .default([]),
})
