import { itemLabels } from 'constant'

import { Accessor } from '../types/accessor'

export const getAccessorTitle = (accessor: Accessor): string => {
  return {
    [Accessor.Index]: '#',
    [Accessor.Name]: itemLabels.name,
    [Accessor.Description]: itemLabels.description,
    [Accessor.Sku]: itemLabels.sku,
    [Accessor.Type]: itemLabels.type,
    [Accessor.Category]: itemLabels.categoryId,
    [Accessor.Price]: itemLabels.price,
    [Accessor.GroupedSKUs]: itemLabels.groupedSKUs,
  }[accessor]
}
