import { FocusEvent, forwardRef, ReactNode, Ref, UIEvent, useCallback, useEffect, useState } from 'react'
import type { SelectProps } from 'antd'
import { Select as SelectBase, Tooltip } from 'antd'
import { RefSelectProps } from 'antd/lib/select'

import { useDebouncedValue } from 'hooks/useDebouncedValue'
import { typedMemo } from 'types'
import theme from 'styles/theme'

import { SelectDropdown } from '../SelectMulti/elements/SelectDropdown'
import { SelectSpinner } from '../SelectMulti/elements/SelectSpinner'
import * as Styled from './styles'

import { Container } from '../Container'
import { Icon } from '../Icon'
import { Typography } from '../Typography'

export interface SelectSingleOption {
  label: string
  value: string | number
  icon?: ReactNode
  disabled?: boolean
  data?: any
}

export interface SelectSingleProps
  extends Omit<SelectProps<SelectSingleOption['value'] | undefined | null, SelectSingleOption>, 'onChange'> {
  defaultOption?: SelectSingleOption
  disabledValues?: (string | number | undefined | null)[]
  returnDefaultValueOnBlur?: boolean
  name?: string
  label?: string
  onPopupScrollEnd?: () => void
  suffixIcon?: ReactNode
  prefixIcon?: ReactNode
  prefix?: ReactNode
  error?: string
  // Wrapper
  isEdit?: boolean
  required?: boolean // mostly is needed for async group lists to don't remove default value
  width?: number
  onFocusChange?: (focused: boolean) => void
  onChange?: (value?: string | number | null, option?: SelectSingleOption) => void
  withCreateNew?: boolean
  onClickCreateNew?: () => void
}

export const SelectSingleBase = forwardRef(
  (
    {
      // wrapper
      isEdit,
      width,
      // select
      allowClear,
      options,
      defaultOption,
      returnDefaultValueOnBlur,
      prefix,
      prefixIcon,
      suffixIcon,
      showSearch = false,
      onChange,
      onSearch,
      onBlur,
      onFocus,
      searchValue,
      onPopupScrollEnd,
      loading = false,
      value,
      size = 'middle',
      error,
      onFocusChange,
      placeholder = 'Select',
      required,
      disabled: disabledControlled,
      disabledValues,
      withCreateNew,
      onClickCreateNew,
      ...props
    }: SelectSingleProps,
    ref: Ref<RefSelectProps>,
  ) => {
    const [disabled, setDisabled] = useState(disabledControlled)
    const {
      value: search,
      handleChange: handleChangeDebouncedSearch,
      setValue: setSearch,
    } = useDebouncedValue(searchValue, onSearch)

    const optionsWithDisabledValues = options?.map((option: SelectSingleOption) => ({
      ...option,
      disabled: option.disabled || (disabledValues?.includes(option.value) && option.value !== value),
    }))

    const handleFocus = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        onFocus?.(event)
        onFocusChange?.(true)
      },
      [onFocus, onFocusChange],
    )

    // on blur we shouldn't debounce setting search
    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        if (onSearch) {
          setSearch('')
          if ((error && defaultOption && returnDefaultValueOnBlur) || required) {
            onChange?.(defaultOption?.value || null, defaultOption as SelectSingleOption)
          } else if (!value) {
            onChange?.(null, undefined)
          }
        }

        onBlur?.(event)
        onFocusChange?.(false)
      },
      [
        returnDefaultValueOnBlur,
        required,
        onChange,
        setSearch,
        value,
        onSearch,
        onBlur,
        onFocusChange,
        error,
        defaultOption,
      ],
    )

    const handleChange = useCallback(
      (value?: string | number, option?: SelectSingleOption | SelectSingleOption[]) => {
        if (!Array.isArray(option)) {
          const currentOption = optionsWithDisabledValues?.find((item) => item.value === value)
          onChange?.(isEdit ? value || undefined : value || null, currentOption)
        }
      },
      [isEdit, onChange, optionsWithDisabledValues],
    )

    const handleSearch = useCallback(
      (search: string) => {
        if (onSearch) {
          handleChangeDebouncedSearch?.(search)
        }
      },
      [onSearch, handleChangeDebouncedSearch],
    )

    const handlePopupScroll = useCallback(
      (event: UIEvent) => {
        const target = event.target as HTMLDivElement

        if (!loading && target.scrollTop + target.offsetHeight === target.scrollHeight) {
          onPopupScrollEnd?.()
        }
      },
      [onPopupScrollEnd, loading],
    )

    useEffect(() => {
      // In some reason sometimes focus event doesn't work after disabled prop change
      setTimeout(() => {
        setDisabled(disabledControlled)
      }, 0)
    }, [disabledControlled])

    return (
      <Styled.SelectSingleWrapper isEdit={isEdit} width={width} isPrefix={!!prefix || !!prefixIcon} isSuffix>
        {(prefix || prefixIcon) && (
          <Styled.SelectSinglePrefixIcon size={size}> {prefix || prefixIcon}</Styled.SelectSinglePrefixIcon>
        )}
        <Styled.SelectSingle<string | number, SelectSingleOption>
          {...props}
          defaultActiveFirstOption={false}
          placeholder={placeholder}
          ref={ref}
          onPopupScroll={handlePopupScroll}
          showSearch={showSearch}
          onSearch={showSearch ? handleSearch : undefined}
          searchValue={onSearch ? search : undefined}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={disabled}
          notFoundContent={loading ? <Container minHeight={18} /> : <Container jc="center">No result found</Container>}
          optionFilterProp="label"
          optionLabelProp="label"
          filterOption={!onSearch}
          size={size}
          status={error && 'error'}
          suffixIcon={
            loading ? <SelectSpinner /> : suffixIcon || <Icon icon="caretDown" color={theme.colors.primary} size={14} />
          }
          allowClear={allowClear && { clearIcon: <Icon icon="cancelFilled" size={14} /> }}
          dropdownRender={(menu) => (
            <SelectDropdown
              menu={menu}
              isLoading={loading}
              withCreateNew={withCreateNew}
              onClickCreateNew={onClickCreateNew}
            />
          )}
        >
          {optionsWithDisabledValues?.map((option) => (
            <SelectBase.Option
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={option.disabled}
              style={{ padding: 0 }}
            >
              <Tooltip title={option.label} destroyTooltipOnHide mouseLeaveDelay={0}>
                <Styled.SelectSingleOption disabled={option.disabled}>
                  <Container gap={2} style={{ minWidth: 0 }}>
                    <Typography display="flex" fontWeight="l" size="xs" lineEllipsis={1}>
                      {!!option.icon && <Container mr={2}>{option.icon}</Container>}
                      {option.label}
                    </Typography>
                  </Container>
                </Styled.SelectSingleOption>
              </Tooltip>
            </SelectBase.Option>
          ))}
        </Styled.SelectSingle>
      </Styled.SelectSingleWrapper>
    )
  },
)

export const SelectSingle = typedMemo(SelectSingleBase)
