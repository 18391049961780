import type { VendorFeedSettings } from 'api'

import { getMarginDefaultValues } from './getMarginDefaultValues'

export const getFeedSettingsDefaultValues = (data: VendorFeedSettings) => {
  return {
    scrappingSettings: {
      feedUrl: data?.scrappingSettings?.feedUrl || '',
      isEnabled: data?.scrappingSettings?.isEnabled ?? false,
      fetchSchedule: data?.scrappingSettings?.fetchSchedule || undefined,
    },
    resaleMargin: getMarginDefaultValues(data?.resaleMargin),
  }
}
