import theme from 'styles/theme'

import { ColorVariant } from '../types/colorVariant'

export const getStylesByColor = (color: ColorVariant) => {
  switch (color) {
    case 'secondary':
      return theme.colors.secondary
    case 'placeholder':
      return theme.colors.placeholder
    case 'primaryTone':
      return theme.colors.primaryTone
    case 'error':
      return theme.colors.error[400]
    case 'success':
      return theme.colors.success[400]
    case 'info':
      return theme.colors.accent[400]
    case 'inherit':
      return 'inherit'
    default:
      return theme.colors.primary
  }
}
