import type { TableColumnKey } from 'components'

export const getTableTitle = (key: TableColumnKey): string => {
  return {
    index: '#',
    actions: '',
    hoverActions: '',
    empty: '',
  }[key]
}
