import { PurchaseOrderSubLineAccessor } from 'types'

import { getAccessorSubLineTitle } from 'modules/purchaseOrders/utils/getAccessorSubLineTitle'

export const purchaseOrderSubItemsNotSwitchableColumns = [PurchaseOrderSubLineAccessor.BillingReference]

export const purchaseOrderSubItemsRequiredColumns = [PurchaseOrderSubLineAccessor.AssetNumber]

export const purchaseOrderSubItemsDefaultColumns = [
  PurchaseOrderSubLineAccessor.AssetNumber,
  PurchaseOrderSubLineAccessor.ExpectedDeliveryDate,
  PurchaseOrderSubLineAccessor.LocationCodeId,
  PurchaseOrderSubLineAccessor.VendorDepotId,
  PurchaseOrderSubLineAccessor.IsReceived,
  PurchaseOrderSubLineAccessor.IsBilled,
  PurchaseOrderSubLineAccessor.ReferenceNumber,
]

export const purchaseOrderSubItemsVisibleColumnsOptions = Object.values(PurchaseOrderSubLineAccessor)
  .filter((accessor) => !purchaseOrderSubItemsNotSwitchableColumns.includes(accessor))
  .map((accessor) => ({
    value: accessor,
    label: getAccessorSubLineTitle(accessor),
    disabled: purchaseOrderSubItemsRequiredColumns.includes(accessor),
  }))
