import { VendorResalePriceOverwriteRule } from 'api'
import { useItems, useVendors } from 'hooks'
import { conditionalOperatorOptions, marginSettingsMeasureOptions, marginSettingsOperatorOptions } from 'constant'

import { Container, EditFormItem, FormItem, InputNumber, SelectSingle, Typography } from 'designSystem'
import {
  EditableTableActions,
  EditableTableIndex,
  FormItemEditInputNumber,
  FormItemEditSelectSingle,
  FormItemEditSelectSingleAsync,
  FormItemSelectSingleAsync,
  getEditableColumnFormItemName,
  isEditableTableAddNewIndex,
  TableColumnKey,
  TableColumnsType,
} from 'components'

import { Accessor } from '../types/accessor'

interface ResaleRuleTableColumnsProps {
  offsetCount: number
  isAddNew: boolean
  onCancelNew: () => void
  onDelete: (id?: number) => void
  triggerSubmit: () => void
}

export const getTableColumns = ({
  offsetCount,
  isAddNew,
  onCancelNew,
  onDelete,
  triggerSubmit,
}: ResaleRuleTableColumnsProps): TableColumnsType<Partial<VendorResalePriceOverwriteRule>> => [
  {
    key: TableColumnKey.Index,
    ellipsis: true,
    width: isAddNew ? 50 : undefined,
    render: (value, record, index) => (
      <EditableTableIndex isAddNew={isAddNew} index={index} offsetCount={offsetCount} />
    ),
  },
  {
    key: Accessor.Data,
    dataIndex: Accessor.Data,
    ellipsis: true,
    width: '90%',
    render: (value, record, index) => {
      return (
        <Container fw="wrap" gap={2}>
          <Container ai="center" gap={2}>
            <Typography color="secondary" size="xs">
              If
            </Typography>
            <>
              {isEditableTableAddNewIndex(index, isAddNew) ? (
                <FormItemSelectSingleAsync name="item.vendorId" label="" getItems={useVendors} width={120} />
              ) : (
                <FormItemEditSelectSingleAsync
                  name={getEditableColumnFormItemName('list', 'vendorId', index, isAddNew)}
                  size="small"
                  onSubmit={triggerSubmit}
                  defaultOption={{
                    value: record.vendor?.id as number,
                    label: record.vendor?.companyName as string,
                  }}
                  getItems={useVendors}
                />
              )}
            </>
          </Container>
          <Container ai="center" gap={2}>
            <Typography color="secondary" size="xs">
              qty
            </Typography>
            <>
              {isEditableTableAddNewIndex(index, isAddNew) ? (
                <FormItem name="item.condOperator">
                  <SelectSingle options={conditionalOperatorOptions} placeholder="" width={50} />
                </FormItem>
              ) : (
                <EditFormItem
                  name={getEditableColumnFormItemName('list', 'condOperator', index, isAddNew)}
                  render={({ field }) => (
                    <FormItemEditSelectSingle
                      {...field}
                      size="small"
                      width={40}
                      onSubmit={triggerSubmit}
                      options={conditionalOperatorOptions}
                      defaultOption={conditionalOperatorOptions.find((option) => option.value === record.condOperator)}
                    />
                  )}
                />
              )}
            </>
          </Container>
          <Container gap={2}>
            {isEditableTableAddNewIndex(index, isAddNew) ? (
              <FormItem name="item.condQuantity">
                <InputNumber placeholder="0-9999" width={80} />
              </FormItem>
            ) : (
              <EditFormItem
                name={getEditableColumnFormItemName('list', 'condQuantity', index, isAddNew)}
                render={({ field }) => (
                  <FormItemEditInputNumber
                    size="small"
                    align="left"
                    placeholder="0-9999"
                    {...field}
                    onBlur={triggerSubmit}
                  />
                )}
              />
            )}
          </Container>
          <Container ai="center" gap={2}>
            <Typography color="secondary" size="xs">
              then
            </Typography>
            {isEditableTableAddNewIndex(index, isAddNew) ? (
              <FormItem name={'item.statementOperator'}>
                <SelectSingle options={marginSettingsOperatorOptions} placeholder="" width={50} />
              </FormItem>
            ) : (
              <EditFormItem
                name={getEditableColumnFormItemName('list', 'statementOperator', index, isAddNew)}
                render={({ field }) => (
                  <FormItemEditSelectSingle
                    {...field}
                    width={40}
                    size="small"
                    onSubmit={triggerSubmit}
                    options={marginSettingsOperatorOptions}
                    defaultOption={marginSettingsOperatorOptions.find(
                      (option) => option.value === record.statementOperator,
                    )}
                  />
                )}
              />
            )}
          </Container>
          <Container gap={2}>
            {isEditableTableAddNewIndex(index, isAddNew) ? (
              <FormItem name="item.statementQuantity">
                <InputNumber placeholder="00.00" precision={2} width={90} />
              </FormItem>
            ) : (
              <EditFormItem
                name={getEditableColumnFormItemName('list', 'statementQuantity', index, isAddNew)}
                render={({ field }) => (
                  <FormItemEditInputNumber
                    size="small"
                    align="left"
                    width={60}
                    placeholder="00.00"
                    precision={2}
                    {...field}
                    onBlur={triggerSubmit}
                  />
                )}
              />
            )}
          </Container>
          <Container gap={2}>
            {isEditableTableAddNewIndex(index, isAddNew) ? (
              <FormItem name="item.statementMeasure">
                <SelectSingle options={marginSettingsMeasureOptions} placeholder="" width={50} />
              </FormItem>
            ) : (
              <EditFormItem
                name={getEditableColumnFormItemName('list', 'statementMeasure', index, isAddNew)}
                render={({ field }) => (
                  <FormItemEditSelectSingle
                    {...field}
                    width={40}
                    size="small"
                    onSubmit={triggerSubmit}
                    options={marginSettingsMeasureOptions}
                    defaultOption={marginSettingsMeasureOptions.find(
                      (option) => option.value === record.statementMeasure,
                    )}
                  />
                )}
              />
            )}
          </Container>
          <Container ai="center" gap={2}>
            <Typography color="secondary" size="xs">
              to the price of
            </Typography>
            <>
              {isEditableTableAddNewIndex(index, isAddNew) ? (
                <FormItemSelectSingleAsync name="item.itemId" label="" getItems={useItems} width={140} />
              ) : (
                <FormItemEditSelectSingleAsync
                  name={getEditableColumnFormItemName('list', 'itemId', index, isAddNew)}
                  size="small"
                  onSubmit={triggerSubmit}
                  defaultOption={{
                    value: record.itemId as number,
                    label: record.item?.sku || '',
                  }}
                  getItems={useItems}
                />
              )}
            </>
          </Container>
        </Container>
      )
    },
  },
  {
    key: TableColumnKey.Actions,
    render: (value, record, index) => (
      <EditableTableActions
        isAddNew={isAddNew}
        index={index}
        onCancelNew={onCancelNew}
        onDelete={() => onDelete(record.id)}
      />
    ),
  },
]
