import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { ApiError } from 'api'
import { ErrorCode } from 'enums'
import { route } from 'constant'

import { showNotification } from 'designSystem'

import { submitResetPassword, SubmitResetPasswordPayload } from '../queryApi'

export const useSubmitResetPassword = () => {
  const navigate = useNavigate()
  const { mutate, ...rest } = useMutation({
    mutationFn: submitResetPassword,
    onError: (error: ApiError) => {
      const responseCode = error.response?.data.code
      if (responseCode === ErrorCode.ResetPasswordSessionClosed) {
        navigate(route.forgotPassword)
      }
    },
    onSuccess: () => {
      showNotification({ title: 'Password successfully changed', type: 'success' })
      navigate(route.login)
    },
  })

  const resetPassword = useCallback(
    (data: SubmitResetPasswordPayload) => {
      mutate(data)
    },
    [mutate],
  )

  return {
    resetPassword,
    ...rest,
  }
}
