import { FC } from 'react'

import { GetZohoLinkProps, useZohoLink } from 'hooks'
import { publicImageUrl } from 'constant'
import theme from 'styles/theme'

import { Container, Divider, Icon, Typography } from 'designSystem'

import { Card } from '../Card'
import { LabelValueMarkup } from '../LabelValueMarkup'

export const ZohoIntegration: FC<GetZohoLinkProps> = ({ module, id }) => {
  const { getZohoLink } = useZohoLink()

  const zohoLink = getZohoLink({ module, id })
  const isSynced = !!zohoLink

  return (
    <Container fd="column" gap={16} pa={4} width="100%">
      <Typography size="l" fontWeight="xl" as="h2">
        Integrations
      </Typography>

      <Card pa={4} maxWidth={440}>
        <Container fd="column" gap={16}>
          <img src={publicImageUrl.ZohoIntegration} width={172} height={64} loading="lazy" alt="ERP Zoho" />
          <Container fd="column">
            <Typography size="m" fontWeight="xl">
              General status
            </Typography>
            <Container gap={8}>
              {isSynced ? (
                <>
                  <Icon icon="allDone" color={theme.colors.success[400]} size={16} />
                  <Typography size="s" fontWeight="l" color="success">
                    All synced
                  </Typography>
                </>
              ) : (
                <>
                  <Icon icon="errorOutline" color={theme.colors.error[400]} size={16} />
                  <Typography size="s" fontWeight="l" color="error">
                    Not synced
                  </Typography>
                </>
              )}
            </Container>
          </Container>
          <Divider />
          <Container fd="column" gap={8}>
            <LabelValueMarkup label="Zoho ID" value={id || '-'} />
            <LabelValueMarkup
              label="Link to Zoho Record"
              href={zohoLink}
              value={zohoLink}
              alignItems="flex-start"
              typographyPropsLabel={{ nowrap: true }}
              typographyPropsValue={{ lineEllipsis: 0, wordBreak: 'break-word' }}
            />
          </Container>
        </Container>
      </Card>
    </Container>
  )
}
