import { salesOrderLabels } from 'constant'

import { Accessor } from '../types/accessor'

export const getAccessorTitle = (accessor: Accessor): string => {
  return {
    [Accessor.Index]: '#',
    [Accessor.Number]: salesOrderLabels.number,
    [Accessor.ZohoId]: salesOrderLabels.zohoId,
    [Accessor.Customer]: salesOrderLabels.customer,
    [Accessor.Status]: salesOrderLabels.status,
    [Accessor.Invoice]: salesOrderLabels.invoice,
    [Accessor.SalesRepresentative]: 'Sales Rep.',
    [Accessor.Dispatcher]: salesOrderLabels.dispatcher,
    [Accessor.CreatedAt]: 'Order Date',
    [Accessor.ExpectedDate]: salesOrderLabels.expectedDate,
    [Accessor.ItemSKUs]: 'Item SKUs',
    [Accessor.Total]: salesOrderLabels.total,
    [Accessor.Modification]: 'Modification',
    [Accessor.AccountingStatus]: salesOrderLabels.accountingStatus,
    [Accessor.GeneralNotes]: salesOrderLabels.generalNotes,
  }[accessor]
}
