import { FC } from 'react'

import { useTableContext } from 'hooks'
import { getIncludesFilter } from 'utils'

import { SelectMultiAsync, SelectMultiAsyncProps, SelectSingleOption } from 'designSystem'

type TableFilterSelectMultiAsyncProps = Omit<SelectMultiAsyncProps, 'onChange'>

export const TableFilterSelectMultiAsync: FC<TableFilterSelectMultiAsyncProps> = ({
  name,
  fullwidth = true,
  ...props
}) => {
  const { updateFilters, getFilterValue } = useTableContext()

  const filterValue = getFilterValue?.(name) as SelectSingleOption[] | undefined
  const value = filterValue?.map(({ value }) => value) as (string | number)[]

  const handleFilterChange = (value?: (string | number)[], options?: SelectSingleOption[]) => {
    updateFilters?.(getIncludesFilter(name, options?.map((option) => ({ value: option?.value, label: option?.label }))))
  }

  return (
    <SelectMultiAsync
      {...props}
      fullwidth={fullwidth}
      showSearch
      allowClear
      name={name}
      value={value}
      defaultOptions={filterValue}
      onChange={handleFilterChange}
    />
  )
}
