export const customersRoot = '/customers'

export const customersRoute = {
  list: '/',
  create: '/create',
  view: '/:id',
}

export const customerViewRoute = {
  details: '/details',
  integrations: '/integrations',
}
