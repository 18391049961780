import { FC, useCallback } from 'react'

import { useFieldArray, useFormContext } from 'hooks'
import { typedMemo } from 'types'

import { Button, Container, EditFormItem, Spacer, Typography } from 'designSystem'

import { FieldArrayItem } from '../FieldArrayItem'
import { FormItemEditInput } from '../FormItemEditInput'

interface FormItemEditEmailGroupProps {
  name: string
  title?: string
  maxFields?: number
}

export const FormItemEditEmailGroupBase: FC<FormItemEditEmailGroupProps> = ({ name, title, maxFields }) => {
  const {
    watch,
    triggerSubmit,
    formState: { defaultValues },
  } = useFormContext()
  const { fields, append, remove } = useFieldArray({ name })

  const values: string[] = watch(name)
  const currentDefaultValues = defaultValues?.[name]

  const showAddButton =
    (maxFields === undefined || fields.length < maxFields) && values?.length === currentDefaultValues?.length

  const handleDelete = useCallback(
    (index: number) => {
      remove(index)
      triggerSubmit?.()
    },
    [remove, triggerSubmit],
  )

  const addNewItem = () => append(undefined)

  return (
    <Container display="block">
      {title && (
        <>
          <Typography fontWeight="xl">{title}</Typography>
          <Spacer mt={3} />
        </>
      )}
      <Container fd="column" gap={4}>
        {fields.map((field, index) => (
          <FieldArrayItem key={field.id} onDelete={() => handleDelete(index)} showDelete>
            <EditFormItem
              name={`${name}.${index}`}
              label={`Email ${index + 1}`}
              render={({ field }) => <FormItemEditInput placeholder="Email" {...field} onBlur={triggerSubmit} />}
            />
          </FieldArrayItem>
        ))}
      </Container>
      {showAddButton && (
        <>
          <Spacer mt={3} />
          <Button type="link" icon="plus" onClick={addNewItem}>
            Add
          </Button>
        </>
      )}
    </Container>
  )
}

export const FormItemEditEmailGroup = typedMemo(FormItemEditEmailGroupBase)
