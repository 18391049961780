import { useCallback, useEffect, useRef } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'

import { ApiQueryParams } from 'api'
import { QueryKey } from 'enums'
import { Pagination, QueryParams } from 'types'

interface InfinityListResponse<T> extends Pagination {
  data?: T[]
}

interface useInfinityListProps<T> extends ApiQueryParams {
  queryKey: QueryKey
  queryFn: (params?: ApiQueryParams) => Promise<InfinityListResponse<T>>
  limit?: number
  queryParams?: QueryParams
  initialPageParam?: number
}

export const useInfinityList = <T>({
  limit,
  queryKey,
  queryFn,
  queryParams,
  join,
  fields,
  initialPageParam,
}: useInfinityListProps<T>) => {
  const observerElem = useRef(null)

  const { fetchNextPage, hasNextPage, ...rest } = useInfiniteQuery({
    queryKey: [queryKey, { limit, queryParams }],
    queryFn: ({ pageParam }) =>
      queryFn({
        page: pageParam,
        limit,
        s: queryParams
          ? JSON.stringify({
              ...queryParams,
            })
          : undefined,
        fields,
        join,
      }),
    getNextPageParam: ({ page, pageCount }) => {
      return page === pageCount ? undefined : page + 1
    },
    initialPageParam: initialPageParam ?? 1,
    gcTime: 0,
    staleTime: 0,
  })

  const handleObserver = useCallback(
    (entries: any) => {
      const [target] = entries
      if (target.isIntersecting) {
        fetchNextPage()
      }
    },
    [fetchNextPage],
  )

  useEffect(() => {
    const element = observerElem.current
    const option = { threshold: 0 }
    const observer = new IntersectionObserver(handleObserver, option)
    if (element) {
      observer.observe(element)
      return () => observer.unobserve(element)
    }
  }, [fetchNextPage, hasNextPage, handleObserver])

  return {
    observerElem,
    hasNextPage,
    fetchNextPage,
    ...rest,
  }
}
