import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'

import { Contact, LocationCode, PaymentTerms, Vendor, VendorType, VendorTypeNames } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import type { TableField, TableSortingOrder } from 'hooks'
import { getPaymentTermLabel, route } from 'constant'
import theme from 'styles/theme'

import { Container, Icon, Typography } from 'designSystem'
import { RelatedLinksList, TableColumnKey, TableColumnsType } from 'components'

import { Accessor } from '../types/accessor'
import { UploadSheet } from '../elements/UploadSheet'

import { getAccessorTitle } from './getAccessorTitle'

export const getTableColumns = (
  getFieldSorter: (field: TableField) => TableSortingOrder,
  offsetCount: number,
): TableColumnsType<Vendor> => [
  {
    title: getAccessorTitle(Accessor.Index),
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => offsetCount + index + 1,
  },
  {
    title: getAccessorTitle(Accessor.CompanyName),
    key: Accessor.CompanyName,
    dataIndex: Accessor.CompanyName,
    width: 300,
    sorter: true,
    sortOrder: getFieldSorter('companyName'),
    ellipsis: true,
    render: (companyName: string, record) => {
      const onClick = () => {
        queryClient.setQueryData([QueryKey.GetVendorById, record.id], {
          ...record,
          locationCodes: record?.locationCodes?.sort((a, b) => a.id - b.id),
        })
      }

      return (
        <Link to={`${route.vendors}/${record.id}`} onClick={onClick}>
          <Container ai="center" gap={4}>
            <Typography color="primary" size="xs" fontWeight="l" lineEllipsis={1}>
              {companyName}
            </Typography>
            {record.isFavorite && (
              <Icon
                style={{ fill: theme.colors.warning[400], width: 14 }}
                color={theme.colors.warning[400]}
                icon="star"
              />
            )}
          </Container>
        </Link>
      )
    },
  },
  {
    title: getAccessorTitle(Accessor.Type),
    key: Accessor.Type,
    dataIndex: Accessor.Type,
    width: 350,
    ellipsis: true,
    render: (type) => {
      const vendorTypes = type.map((item: VendorType) => VendorTypeNames[item]).join(', ')
      return (
        <Tooltip placement="topLeft" title={vendorTypes}>
          {vendorTypes || '-'}
        </Tooltip>
      )
    },
  },
  {
    title: getAccessorTitle(Accessor.LocationCodes),
    key: Accessor.LocationCodes,
    dataIndex: Accessor.LocationCodes,
    width: 200,
    ellipsis: true,
    render: (locationCodes: LocationCode[]) => {
      const data = locationCodes.map(({ id, code }) => ({
        id,
        title: code,
      }))
      return <RelatedLinksList entity="locationCodes" data={data} />
    },
  },
  {
    title: getAccessorTitle(Accessor.BillingAddress),
    key: Accessor.BillingAddress,
    dataIndex: Accessor.BillingAddress,
    ellipsis: true,
    width: 350,
    render: (_, { street, city, state, zipCode, country }: Vendor) => {
      const address = [street, city, state, zipCode, country].filter(Boolean)
      if (!address.length) {
        return '-'
      }

      const addressText = address.join(', ')

      return (
        <Tooltip placement="topLeft" title={addressText}>
          {addressText}
        </Tooltip>
      )
    },
  },
  {
    title: getAccessorTitle(Accessor.PaymentTerms),
    key: Accessor.PaymentTerms,
    dataIndex: Accessor.PaymentTerms,
    ellipsis: true,
    width: 150,
    render: (value: PaymentTerms) => {
      return getPaymentTermLabel(value)
    },
  },
  {
    title: getAccessorTitle(Accessor.Contacts),
    key: Accessor.Contacts,
    dataIndex: Accessor.Contacts,
    width: 300,
    ellipsis: true,
    render: (contacts: Pick<Contact, 'email'>[]) => {
      const emails = contacts.map(({ email }) => email)
      return (
        <Tooltip
          placement="topLeft"
          title={emails.map((email) => (
            <div key={email}>{email}</div>
          ))}
        >
          {emails.join(', ')}
        </Tooltip>
      )
    },
  },
  {
    title: getAccessorTitle(Accessor.UploadSheet),
    key: Accessor.UploadSheet,
    align: 'right',
    width: 220,
    render: (record: Vendor) => {
      return !record.type.includes(VendorType.ContainerVendor) &&
        !record.type.includes(VendorType.Modifications) &&
        !record.type.includes(VendorType.Parts) ? (
        '-'
      ) : (
        <UploadSheet vendorId={record.id} type={record.type} />
      )
    },
  },
]
