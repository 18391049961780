import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { profileRoutes } from './constants/profileRoutes'

export const Profile: FC = () => {
  return (
    <Routes>
      {profileRoutes.map((profileRoute) => (
        <Route key={profileRoute.path} path={profileRoute.path} element={profileRoute.element} />
      ))}
    </Routes>
  )
}
