import { Dispatch, FC, SetStateAction, useCallback } from 'react'

import { useFormContext, useGetCustomerContacts, useWatch } from 'hooks'
import { ReferenceFormAccessor } from 'types'

import { AddNewContactActionButton, NewContact } from 'components'
import { maxContactsCount } from 'modules/customers'

interface PickupAddContactProps {
  contacts: NewContact[]
  setContacts: Dispatch<SetStateAction<NewContact[]>>
}

export const PickupAddContact: FC<PickupAddContactProps> = ({ contacts, setContacts }) => {
  const { setValue, getValues } = useFormContext()
  const customerId = useWatch({ name: ReferenceFormAccessor.CustomerId })
  const { data: customerContacts = [], isLoading } = useGetCustomerContacts(customerId)

  const handleAddNewContact = useCallback(
    (contact: NewContact) => {
      setContacts((prev) => [...prev, contact])
      setValue(ReferenceFormAccessor.Contacts, [...(getValues(ReferenceFormAccessor.Contacts) || []), contact.email])
    },
    [setContacts, setValue, getValues],
  )
  const contactsWithNew = [...contacts, ...customerContacts]

  const showAdd = !isLoading && customerId && contactsWithNew.length < maxContactsCount

  return showAdd ? <AddNewContactActionButton contacts={contacts} onSubmit={handleAddNewContact} /> : null
}
