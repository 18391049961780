import { FC, useCallback, useState } from 'react'

import { PurchaseOrder, PurchaseOrderEmail } from 'api'
import { useHighlightedHtmlString } from 'hooks'
import { usePurchaseOrderRequestId } from 'store'

import { Button, Container, Modal, Spacer, Typography } from 'designSystem'
import { TextEditor } from 'components'

import { useGetPurchaseOrderEmailTemplate } from './hooks/useGetPurchaseOrderEmailTemplate'
import { usePostPurchaseOrderEmail } from './hooks/usePostPurchaseOrderEmail'

interface PurchaseOrderSendEmailProps {
  modalType: PurchaseOrderEmail
  onClose: () => void
  purchaseOrder?: PurchaseOrder
}

export const PurchaseOrderSendEmail: FC<PurchaseOrderSendEmailProps> = ({ modalType, onClose, purchaseOrder }) => {
  const [open, setOpen] = useState(true)
  const purchaseOrderRequestId = usePurchaseOrderRequestId()

  const onPostEmailSuccess = useCallback(() => {
    setOpen(false)
    onClose()
  }, [onClose])

  const { data, isLoading: isGetLoading, isError } = useGetPurchaseOrderEmailTemplate({ key: modalType })
  const { post, isPending: isPostLoading } = usePostPurchaseOrderEmail(purchaseOrder?.id, onPostEmailSuccess)
  const { defaultString, getCurrentString, onChange } = useHighlightedHtmlString(data?.htmlContent)
  const { defaultString: subject } = useHighlightedHtmlString(data?.subject)

  const handleClose = useCallback(() => {
    setOpen(false)
    onClose()
  }, [onClose])

  const handleOk = useCallback(() => {
    post({
      trigger: modalType,
      subject: data?.subject || '',
      html: getCurrentString(),
      requestId: purchaseOrderRequestId || '',
    })
  }, [post, getCurrentString, data?.subject, modalType, purchaseOrderRequestId])

  const isToDepot = [
    PurchaseOrderEmail.CreatePurchaseOrderRedeliveryToDepot,
    PurchaseOrderEmail.UpdatePurchaseOrderRedeliveryToDepot,
  ].includes(modalType)
  const emailToText = isToDepot ? 'Depot' : 'Vendor'
  const recipients = isToDepot
    ? purchaseOrder?.depot?.redeliveryEmails
    : purchaseOrder?.contacts.map((contact) => contact.email)

  return (
    <Modal
      title={`Email Purchase Order to ${emailToText}`}
      isLoading={isGetLoading}
      width={850}
      open={open}
      onCancel={handleClose}
      onOk={handleOk}
      destroyOnClose
      footer={null}
      centered
    >
      {isError ? (
        <Typography size="s" color="error">
          Cannot GET template
        </Typography>
      ) : (
        <>
          <Container mb={2} gap={16}>
            <Typography size="s" fontWeight="l">
              Subject:
            </Typography>
            <Spacer mr={2} />
            <Typography size="s" fontWeight="l">
              <div dangerouslySetInnerHTML={{ __html: subject }} />
            </Typography>
          </Container>
          <Container mb={4} gap={16}>
            <Typography size="s" fontWeight="l">
              Recipients:
            </Typography>
            <Container fw="wrap" gap={4}>
              {recipients?.length ? (
                recipients.map((email, index) => (
                  <span key={email}>
                    <a key={email} href={`mailto:${email}?subject=${data?.subject} ${purchaseOrder?.number}`}>
                      <Typography size="s" fontWeight="l" color="primary">
                        {email}
                      </Typography>
                    </a>
                    {index !== recipients.length - 1 && ','}
                  </span>
                ))
              ) : (
                <Typography size="s" color="error">
                  Emails not added
                </Typography>
              )}
            </Container>
          </Container>
          <TextEditor data={defaultString} onChange={onChange} height={300} />
          <Container display="flex" jc="space-between" gap={8} mt={6}>
            <Button type="default" onClick={handleClose}>
              Back
            </Button>
            <Button type="primary" onClick={handleOk} loading={isPostLoading} disabled={!recipients?.length}>
              Send email
            </Button>
          </Container>
        </>
      )}
    </Modal>
  )
}
