import { useCallback, useRef } from 'react'
import { useMutation } from '@tanstack/react-query'

import { Contact, GetVendorContactsResponse } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { deleteVendorContact } from '../queryApi'

export const useDeleteVendorContact = (id: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const idToDelete = useRef<number>()

  const { mutate, ...rest } = useMutation({
    mutationFn: deleteVendorContact,
    onSuccess: () => {
      dispatchAppEvent(AppEvent.VendorContactDelete)

      queryClient.setQueryData(
        [QueryKey.GetVendorContacts, id],
        (old: GetVendorContactsResponse | undefined) =>
          ({
            ...old,
            data: old?.data.filter((contact: Contact) => contact.id !== idToDelete.current),
          }) as GetVendorContactsResponse,
      )
      idToDelete.current = undefined
      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const deleteContact = useCallback(
    (contactId: number) => {
      idToDelete.current = contactId
      mutate({ vendorId: id, contactId: contactId })
    },
    [mutate, id],
  )

  return {
    deleteContact,
    ...rest,
  }
}
