import { Navigate } from 'react-router-dom'

import { globalSettingsRoot, globalSettingsRoute } from 'constant'

import { NotFoundPage } from 'components'

import { AccessManagement } from '../routes/AccessManagement'

export const globalSettingsRoutes = [
  {
    path: `/`,
    element: <Navigate to={`${globalSettingsRoot}${globalSettingsRoute.accessManagement}`} replace />,
  },
  //TODO temporary hide
  // {
  //   path: `${globalSettingsRoute.assetCodes}/*`,
  //   element: <AssetCodes />,
  // },
  {
    path: `${globalSettingsRoute.accessManagement}/*`,
    element: <AccessManagement />,
  },
  {
    path: globalSettingsRoute.emailSettings,
    element: <>Email settings</>,
  },
  {
    path: globalSettingsRoute.assetStatuses,
    element: <>Asset statuses</>,
  },

  {
    path: '*',
    element: <NotFoundPage />,
  },
]
