import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { VendorType } from 'api'

import { Container, Spacer, Typography } from 'designSystem'
import { Card, Spinner } from 'components'
import { useGetContainerVendorByVendorId } from 'modules/vendors/hooks/useGetContainerVendorByVendorId'
import { useUpdateContainerVendorEquipmentType } from 'modules/vendors/hooks/useUpdateContainerVendorEquipmentType'

import { ResalePriceOverwriteRules } from 'modules/vendors/routes/Vendor/elements/ResalePriceOverwriteRules'
import { ContainerVendorInfoCard } from 'modules/vendors/routes/Vendor/routes/ContainerVendor/elements/ContainerVendorInfoCard'

export const ContainerVendorDetails: FC = () => {
  const { id } = useParams()

  const { data, isLoading: isLoadingContainerVendor } = useGetContainerVendorByVendorId({ id: Number(id) })
  const { update, isPending, isError } = useUpdateContainerVendorEquipmentType({ id: Number(id) })

  if (isLoadingContainerVendor) {
    return <Spinner />
  }

  if (!data) {
    return null
  }
  return (
    <Container pa={4} fd="column">
      <Typography fontWeight="xl" size="l">
        Container Vendor Info
      </Typography>
      <Spacer mb={4} />
      <Card maxWidth={390}>
        <ContainerVendorInfoCard
          data={data.typeOfEquipment}
          isLoading={isPending}
          isError={isError}
          onSubmit={update}
        />
      </Card>
      <Spacer mb={7} />
      <ResalePriceOverwriteRules type={VendorType.ContainerVendor} />
    </Container>
  )
}
