import { convertSecondsToDays, getCookie, removeCookie, setCookie } from 'utils'

export interface Token {
  token: string
  expiresIn: number
}

export interface RefreshTokenPayload {
  refreshToken: string
}

export interface RefreshTokenResponse {
  accessToken: Token
  refreshToken: Token
}

export function getAccessToken(): string | undefined {
  return getCookie('AccessToken')
}

export function setAccessToken(token: Token) {
  const expireInDays = convertSecondsToDays(token.expiresIn)
  setCookie('AccessToken', token.token, { expires: expireInDays })
}

export function getRefreshToken(): string | undefined {
  return getCookie('RefreshToken')
}

export function setRefreshToken(token: Token) {
  const expireInDays = convertSecondsToDays(token.expiresIn)
  setCookie('RefreshToken', token.token, { expires: expireInDays })
}

export function removeTokens() {
  removeCookie('AccessToken')
  removeCookie('RefreshToken')
}

export const isAuthenticated = (): boolean => !!getAccessToken() || !!getRefreshToken()
