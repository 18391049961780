import { Dispatch, SetStateAction } from 'react'
import { FieldValues, UseFormWatch } from 'react-hook-form'

import { ItemType } from 'api'
import { useItems } from 'hooks'
import { currency } from 'constant'
import { SalesOrderFormAccessor, SalesOrderLineItemsAccessor, SalesOrderSubLinesAccessor } from 'types'
import { getFormattedPriceWithCurrency, getTableTitle } from 'utils'

import { EditFormItem, IconDelete, Typography } from 'designSystem'
import {
  EditableTableActions,
  FormItemEditInputNumber,
  FormItemEditSelectSingleAsync,
  TableColumnKey,
  TableColumnsType,
} from 'components'

import { LineItemSku, SalesOrderSubLineRow } from '../types'

import { getAccessorSubLineTitle } from './getAccessorSubLineTitle'
import { getLineItemSubTotal } from './getLineItemSubTotal'
import { getSubLineTableFieldName } from './getSubLineTableFieldName'

interface LineItemsSubLinesColumnsProps {
  setLineItemSku: Dispatch<SetStateAction<LineItemSku>>
  lineItemSku: LineItemSku
  onDelete: (index: number) => void
  watch: UseFormWatch<FieldValues>
  rowSubLinesFieldName: string
}

export const getExpandableColumns = ({
  setLineItemSku,
  lineItemSku,
  onDelete,
  watch,
  rowSubLinesFieldName,
}: LineItemsSubLinesColumnsProps): TableColumnsType<SalesOrderSubLineRow> => [
  {
    title: getTableTitle(TableColumnKey.Index),
    key: TableColumnKey.Index,
    width: 60,
  },
  {
    title: getAccessorSubLineTitle(SalesOrderSubLinesAccessor.Sku),
    key: SalesOrderLineItemsAccessor.Sku,
    dataIndex: SalesOrderLineItemsAccessor.Sku,
    width: 160,
    ellipsis: true,
    render: (title, record, index) => {
      return (
        <FormItemEditSelectSingleAsync
          name={getSubLineTableFieldName({
            rowSubLinesFieldName,
            fieldName: SalesOrderFormAccessor.LineItemId,
            index,
          })}
          withFullDataOption
          fullwidth
          isCreateMode={true}
          size="small"
          getItems={useItems}
          queryParams={{ type: { $eq: ItemType.Modification } }}
          onChange={(value, option) => {
            if (option?.data) {
              setLineItemSku((prev) => ({
                ...prev,
                [record.id]: {
                  name: option?.data?.name,
                  sku: option?.data?.sku,
                },
              }))
            } else if (option === undefined) {
              setLineItemSku((prev) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { [String(record.id)]: _, ...rest } = prev
                return rest
              })
            }
          }}
        />
      )
    },
  },
  {
    title: getAccessorSubLineTitle(SalesOrderSubLinesAccessor.ItemName),
    key: SalesOrderSubLinesAccessor.ItemName,
    dataIndex: SalesOrderSubLinesAccessor.ItemName,
    ellipsis: true,

    render: (value, record) => {
      const title = lineItemSku[record.id]?.name
      if (!title) return null
      return (
        <Typography lineEllipsis={1} fontWeight="l" size="xs">
          {title}
        </Typography>
      )
    },
  },

  {
    title: getAccessorSubLineTitle(SalesOrderSubLinesAccessor.Qty),
    key: SalesOrderLineItemsAccessor.Qty,
    dataIndex: SalesOrderLineItemsAccessor.Qty,
    ellipsis: true,
    width: 100,
    render: (value, record, index) => (
      <EditFormItem
        name={getSubLineTableFieldName({
          rowSubLinesFieldName,
          fieldName: SalesOrderFormAccessor.LineItemQty,
          index,
        })}
        render={({ field }) => (
          <FormItemEditInputNumber {...field} size="small" align="left" placeholder="Quantity" prefix="x" />
        )}
      />
    ),
  },
  {
    title: getAccessorSubLineTitle(SalesOrderSubLinesAccessor.Price),
    key: SalesOrderLineItemsAccessor.Price,
    dataIndex: SalesOrderLineItemsAccessor.Price,
    ellipsis: true,
    width: 120,
    render: (value, record, index) => (
      <EditFormItem
        name={getSubLineTableFieldName({
          rowSubLinesFieldName,
          fieldName: SalesOrderFormAccessor.LineItemPrice,
          index,
        })}
        render={({ field }) => (
          <FormItemEditInputNumber
            {...field}
            size="small"
            align="left"
            placeholder="00.00"
            precision={2}
            suffix={currency}
          />
        )}
      />
    ),
  },
  {
    title: getAccessorSubLineTitle(SalesOrderSubLinesAccessor.Discount),
    key: SalesOrderLineItemsAccessor.Discount,
    dataIndex: SalesOrderLineItemsAccessor.Discount,
    ellipsis: true,
    width: 120,
    render: (value, record, index) => (
      <EditFormItem
        name={getSubLineTableFieldName({
          rowSubLinesFieldName,
          fieldName: SalesOrderFormAccessor.LineItemDiscount,
          index,
        })}
        render={({ field }) => (
          <FormItemEditInputNumber
            {...field}
            size="small"
            align="left"
            placeholder="00.00"
            precision={2}
            prefix="-"
            suffix={currency}
          />
        )}
      />
    ),
  },
  {
    title: getAccessorSubLineTitle(SalesOrderSubLinesAccessor.Taxes),
    key: SalesOrderLineItemsAccessor.Taxes,
    dataIndex: SalesOrderLineItemsAccessor.Taxes,
    ellipsis: true,
    width: 120,
    render: (value, record, index) => (
      <EditFormItem
        name={getSubLineTableFieldName({
          rowSubLinesFieldName,
          fieldName: SalesOrderFormAccessor.LineItemTaxes,
          index,
        })}
        render={({ field }) => (
          <FormItemEditInputNumber
            {...field}
            size="small"
            align="left"
            placeholder="00.00"
            precision={2}
            suffix={currency}
          />
        )}
      />
    ),
  },
  {
    title: getAccessorSubLineTitle(SalesOrderSubLinesAccessor.SubTotal),
    key: SalesOrderLineItemsAccessor.SubTotal,
    dataIndex: SalesOrderLineItemsAccessor.SubTotal,
    ellipsis: true,
    width: 120,
    render: (value, record, index) => {
      const subTotal = getLineItemSubTotal({ watch, index })
      return getFormattedPriceWithCurrency(subTotal)
    },
  },
  {
    title: getAccessorSubLineTitle(SalesOrderSubLinesAccessor.Total),
    key: SalesOrderLineItemsAccessor.Total,
    dataIndex: SalesOrderLineItemsAccessor.Total,
    ellipsis: true,
    width: 120,
    render: (value, record, index) => {
      const subTotal = getLineItemSubTotal({ watch, index })
      const currentTaxes =
        watch(`${SalesOrderFormAccessor.LineItems}.${index}.${SalesOrderFormAccessor.LineItemTaxes}`) || 0

      const total = subTotal + currentTaxes
      return (
        <Typography fontWeight="xl" size="xs">
          {getFormattedPriceWithCurrency(total)}
        </Typography>
      )
    },
  },
  {
    key: TableColumnKey.HoverActions,
    width: 30,
    render: (value, record, index) => {
      return (
        <EditableTableActions
          name={SalesOrderFormAccessor.LineItems}
          icon={<IconDelete />}
          index={index}
          size="small"
          onDelete={() => onDelete(index)}
        />
      )
    },
  },
]
