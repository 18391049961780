import { ReferenceNumberPickupAsset, ReferenceNumberType } from 'api'
import { useAssets, useReferences } from 'hooks'
import { pickupAssetStatusOptions, route } from 'constant'
import { ReferenceSubLineFormAccessor } from 'types'
import { getTableTitle, isDateBeforeCurrentDateEndOfDay } from 'utils'

import { Container, EditFormItem, Icon, IconDelete } from 'designSystem'
import {
  FormItemEditDatePicker,
  FormItemEditSelectSingle,
  FormItemEditSelectSingleAsync,
  ReferenceNumberAssetStatusTag,
  TableColumnKey,
  TableColumnsType,
} from 'components'

import { getSubLineAccessorTitle } from './getSubLineAccessorTitle'
import type { TableColumnsProps } from './getTableColumns'

export const getPickupTableColumns = ({
  skuIndex,
  fieldName,
  onDelete,
  itemId,
  handleAssetChange,
  assetsIds,
  data,
  canChangeQty,
  isFieldViewOnly,
  isFormViewOnly,
  triggerSubmit,
}: TableColumnsProps): TableColumnsType<ReferenceNumberPickupAsset> => {
  const assetsIdsArray = Object.values(assetsIds || {})
  const skuSubLinesFromData = data?.[skuIndex]?.subLines || []

  return [
    {
      title: getTableTitle(TableColumnKey.Index),
      key: TableColumnKey.Index,
      ellipsis: true,
      render: (value, record, index) => index + 1,
    },
    {
      title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.Asset),
      key: ReferenceSubLineFormAccessor.Asset,
      dataIndex: ReferenceSubLineFormAccessor.Asset,
      width: '55%',
      ellipsis: true,
      render: (value, record, index) => {
        const asset = skuSubLinesFromData[index]?.asset

        return (
          <FormItemEditSelectSingleAsync
            name={`${fieldName}.${index}.${ReferenceSubLineFormAccessor.AssetId}`}
            isFieldViewOnly={isFieldViewOnly?.(ReferenceSubLineFormAccessor.AssetId)}
            fullwidth
            defaultOption={asset ? { value: asset.id, label: asset.assetNumber } : undefined}
            href={route.assets}
            disabledValues={assetsIdsArray}
            isCreateMode={!asset}
            disabled={!itemId}
            size="small"
            getItems={useAssets}
            placeholder="Select Asset"
            onChange={(value) => handleAssetChange(index, value)}
            queryParams={{
              ...(itemId && { 'items.id': { $eq: itemId } }),
            }}
            onSubmit={triggerSubmit}
          />
        )
      },
    },
    {
      title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.PickupDate),
      key: ReferenceSubLineFormAccessor.PickupDate,
      dataIndex: ReferenceSubLineFormAccessor.PickupDate,
      width: '15%',
      ellipsis: true,
      render: (value, record, index) => (
        <EditFormItem
          size="small"
          name={`${fieldName}.${index}.${ReferenceSubLineFormAccessor.PickupDate}`}
          render={({ field }) => (
            <FormItemEditDatePicker
              {...field}
              align="left"
              size="small"
              onBlur={triggerSubmit}
              disabledDate={isDateBeforeCurrentDateEndOfDay}
            />
          )}
        />
      ),
    },
    {
      title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.RedeliveryReferenceId),
      key: ReferenceSubLineFormAccessor.RedeliveryReferenceId,
      dataIndex: ReferenceSubLineFormAccessor.RedeliveryReferenceId,
      width: '15%',
      ellipsis: true,
      render: (value, record, index) => {
        const subLine = skuSubLinesFromData[index]
        const currentAssetId = assetsIds?.[`${skuIndex}.${index}`]

        return (
          <FormItemEditSelectSingleAsync
            name={`${fieldName}.${index}.${ReferenceSubLineFormAccessor.RedeliveryReferenceId}`}
            isFieldViewOnly={isFieldViewOnly?.(ReferenceSubLineFormAccessor.RedeliveryReferenceId)}
            defaultOption={
              subLine?.redeliveryReference
                ? { value: subLine.redeliveryReference.id, label: subLine.redeliveryReference.number }
                : undefined
            }
            returnDefaultValueOnBlur={false}
            href={route.reference}
            getItems={useReferences}
            join={currentAssetId ? ['items', 'items.subLines||assetId'] : undefined}
            queryParams={{
              type: { $eq: ReferenceNumberType.Redelivery },
              ...(currentAssetId && { 'items.subLines.assetId': { $eq: currentAssetId } }),
            }}
            disabled={!currentAssetId}
            isCreateMode={!subLine}
            size="small"
            fullwidth
            onSubmit={triggerSubmit}
          />
        )
      },
    },
    {
      title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.Status),
      key: ReferenceSubLineFormAccessor.Status,
      dataIndex: ReferenceSubLineFormAccessor.Status,
      width: '15%',
      ellipsis: true,
      align: 'right',
      render: (value, record, index) => (
        <EditFormItem
          name={`${fieldName}.${index}.${ReferenceSubLineFormAccessor.Status}`}
          render={({ field }) => (
            <FormItemEditSelectSingle
              {...field}
              fullwidth
              size="small"
              customPreview={
                <ReferenceNumberAssetStatusTag status={field.value} icon={<Icon icon="arrowDown" size={16} />} />
              }
              placeholder="Status"
              options={pickupAssetStatusOptions}
              onSubmit={triggerSubmit}
            />
          )}
        />
      ),
    },
    ...(canChangeQty && !isFormViewOnly
      ? [
          {
            key: TableColumnKey.HoverActions,
            dataIndex: TableColumnKey.HoverActions,
            ellipsis: true,
            render: (value: undefined, record: ReferenceNumberPickupAsset, index: number) => {
              const isLastSubLine = skuSubLinesFromData.length === 1
              return isLastSubLine ? null : (
                <Container jc="flex-end">
                  <IconDelete onClick={() => onDelete?.(index)} />
                </Container>
              )
            },
          },
        ]
      : []),
  ]
}
