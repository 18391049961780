import { api, EntityId, VendorDepotSettings } from 'api'

export const getDepotByVendorId = (vendorId?: EntityId): Promise<VendorDepotSettings> => {
  return api({
    method: 'get',
    url: `/api/v1/vendors/${vendorId}/types/depot`,
    params: {
      join: ['locationCode'],
    },
  })
}
