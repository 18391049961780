import { Navigate } from 'react-router-dom'

import { vendorsRoute, vendorViewRoute } from 'constant'

import { NotFoundPage } from 'components'

import { Vendor } from '../routes/Vendor'
import { VendorCreate } from '../routes/VendorCreate'
import { VendorsList } from '../routes/VendorsList'

export const routes = [
  {
    path: vendorsRoute.create,
    element: <VendorCreate />,
  },
  {
    path: vendorsRoute.view,
    element: <Navigate to={vendorViewRoute.details.replace('/', '')} replace />,
  },
  {
    path: `${vendorsRoute.view}/*`,
    element: <Vendor />,
  },
  {
    path: vendorsRoute.list,
    element: <VendorsList />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
