import { styled } from 'styled-components'

import { Container } from '../Container'
import type { ConfirmationButtonsProps } from './ConfirmationButtons'

type IconWrapperProps = Pick<ConfirmationButtonsProps, 'size' | 'disabled'>

export const ConfirmationContainer = styled(Container)`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  right: 0;
  z-index: ${({ theme }) => theme.zIndexLevel[1]};
  background-color: ${({ theme }) => theme.colors.white};
  top: ${({ theme }) => `calc(100% + ${theme.spacers[2]})`};
  border: 1px solid ${({ theme }) => theme.colors.lines};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`

export const IconWrapper = styled.span<IconWrapperProps>`
  width: ${({ size }) => (size === 'small' ? 24 : 32)}px;
  height: ${({ size }) => (size === 'small' ? 24 : 32)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

export const Separator = styled.span`
  width: 1px;
  background-color: ${({ theme }) => theme.colors.lines};
`
