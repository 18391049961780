import { Asset, ReferenceNumberType } from 'api'

export const getAssetReferencesByType = (targetType: ReferenceNumberType, items?: Asset) => {
  return (
    items?.referenceItemSubLines
      ?.filter(({ referenceItem }) => referenceItem?.reference?.type === targetType)
      .map(({ referenceItem }) => ({
        id: referenceItem?.reference?.id,
        title: referenceItem?.reference?.number,
      })) || []
  )
}
