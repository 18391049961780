import { ContainerColor } from 'api/types/containerColor'

export const containerColorOptionLabel = {
  [ContainerColor.DarkBeige]: 'RAL1001 Dark Beige',
  [ContainerColor.LightBeige]: 'RAL1015 Light Beige',
  [ContainerColor.OxideRed]: 'RAL3009 Oxide Red',
  [ContainerColor.GentianBlue]: 'RAL5010 Gentian Blue',
  [ContainerColor.SlateGrey]: 'RAL7015 Slate Grey',
  [ContainerColor.LightGrey]: 'RAL7035 Light Grey',
  [ContainerColor.WindowGrey]: 'RAL7040 Window Grey',
  [ContainerColor.TrafficGrey]: 'RAL7042 Traffic Grey',
  [ContainerColor.SignalWhite]: 'RAL9003 Singnal White',
  [ContainerColor.White]: 'RAL9010 White',
}

export const containerColorOptions = Object.keys(containerColorOptionLabel).map((key) => ({
  value: key as ContainerColor,
  label: containerColorOptionLabel[key as ContainerColor],
}))
