import type { ApiQueryParams, EntityId, VendorCarrierPriceListItem } from 'api'
import { api, VendorCarrierSettingsEquipment } from 'api'
import { Pagination } from 'types'

import { CarrierPriceListPayload } from './types/carrierPriceListPayload'

export interface GetCarrierPriceListResponse extends Pagination {
  data?: VendorCarrierPriceListItem[]
}

export type GetCarrierPriceListQueryParams = ApiQueryParams & {
  vendorId: EntityId
}

export const getCarrierPriceList = ({
  vendorId,
  ...params
}: GetCarrierPriceListQueryParams): Promise<GetCarrierPriceListResponse> => {
  return api({
    method: 'get',
    url: `/api/v1/vendors/${vendorId}/types/carrier/price-list`,
    params: {
      join: ['truckCode', 'locationCode'],
      ...params,
    },
  })
}

export const patchPriceListItem = ({
  vendorId,
  priceListItemId,
  data,
}: {
  vendorId?: EntityId
  priceListItemId?: EntityId
  data?: CarrierPriceListPayload
}): Promise<VendorCarrierSettingsEquipment> => {
  return api({
    method: 'patch',
    url: `/api/v1/vendors/${vendorId}/types/carrier/price-list/${priceListItemId}`,
    data,
    params: {
      join: ['truckCode', 'locationCode'],
    },
  })
}

export const deletePriceListItem = ({
  vendorId,
  priceListItemId,
}: {
  vendorId?: EntityId
  priceListItemId?: EntityId
}): Promise<void> => {
  return api({
    url: `/api/v1/vendors/${vendorId}/types/carrier/price-list/${priceListItemId}`,
    method: 'delete',
  })
}
