import { VendorCarrierSettingsEquipment, VendorResalePriceOverwriteRule, VendorType } from 'api'
import { useEditableTable, useTable } from 'hooks'

import { useGetResaleRules } from './useGetResaleRules'

export const useResaleRule = (vendorId: number, type: VendorType) => {
  const { pagination, onChange, getFieldSorter, sortQueryParams, searchQueryParams, ...props } = useTable<
    Partial<VendorCarrierSettingsEquipment>
  >({})

  const {
    data = [],
    total,
    isLoading,
    isFetching,
  } = useGetResaleRules({
    vendorId,
    limit: pagination.limit,
    page: pagination.page,
    sort: sortQueryParams,
    s: searchQueryParams,
    type,
  })

  const editableTable = useEditableTable<VendorResalePriceOverwriteRule>({
    data,
  })

  return {
    ...editableTable,
    data: data,
    pagination: {
      ...pagination,
      current: pagination.page,
      pageSize: pagination.limit,
      total,
    },
    getFieldSorter,
    onTableChange: onChange,
    isLoading: isLoading || isFetching,
    offsetCount: (pagination.page - 1) * pagination.limit,
    ...props,
  }
}
