import { VendorCarrierPriceListItem } from 'api'

import { EditableTableActions, TableColumnKey, TableColumnsType } from 'components'

import { Accessor } from '../types/accessor'
import { TablePriceListItem } from '../elements/TablePriceListItem'

interface EquipmentTableColumnsProps {
  offsetCount: number
  onDelete: (id?: number) => void
}

export const getTableColumns = ({
  offsetCount,
  onDelete,
}: EquipmentTableColumnsProps): TableColumnsType<Partial<VendorCarrierPriceListItem>> => [
  {
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => offsetCount + index + 1,
  },

  {
    key: Accessor.Data,
    render: (value, record, index) => <TablePriceListItem index={index} {...record} />,
  },
  {
    key: TableColumnKey.Actions,
    width: 70,
    render: (value, record, index) => <EditableTableActions index={index} onDelete={() => onDelete(record.id)} />,
  },
]
