import { Link } from 'react-router-dom'
import { styled } from 'styled-components'

export const ReactLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 0;
  color: ${({ theme }) => theme.colors.accent[400]};

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.accent[400]};
  }
`
