import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { Container } from 'designSystem'

import { ModificationTable } from './elements/ModificationTable'

export const Modifications: FC = () => {
  const { id } = useParams()

  return (
    <Container pa={4} fd="column">
      <ModificationTable id={Number(id)} />
    </Container>
  )
}
