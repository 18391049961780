import { styled } from 'styled-components'

export const DraggableItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacers[3]};
  gap: ${({ theme }) => theme.spacers[1]};

  border: 1px solid ${({ theme }) => theme.colors.lines};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background: ${({ theme }) => theme.colors.white};
`
