import type { ApiQueryParams, VendorContainerSettings } from 'api'
import { Pagination } from 'types'

import { api } from '../api'

export interface GetVendorContainerInventoryResponse extends Pagination {
  data?: VendorContainerSettings['inventory']
}

export type GetVendorContainerInventoryQueryParams = ApiQueryParams

export const getVendorContainerInventory =
  (vendorId?: number) =>
  (params?: GetVendorContainerInventoryQueryParams): Promise<GetVendorContainerInventoryResponse> => {
    return api({
      method: 'get',
      url: `/api/v1/vendors/${vendorId}/types/container-supplier/inventory`,
      params,
    })
  }
