import { route } from 'constant'

export const breadcrumbsData = [
  {
    title: 'Items',
    url: route.items,
  },
  {
    title: 'Item Details',
  },
]
