import { Navigate } from 'react-router-dom'

import { assetsRoute } from 'constant'

import { NotFoundPage } from 'components'

import { Asset } from '../routes/Asset'
import { AssetCreate } from '../routes/AssetCreate'
import { AssetsList } from '../routes/AssetsList'

export const routes = [
  {
    path: assetsRoute.create,
    element: <AssetCreate />,
  },
  {
    path: assetsRoute.view,
    element: <Navigate to={assetsRoute.details.replace('/', '')} replace />,
  },
  {
    path: `${assetsRoute.view}/*`,
    element: <Asset />,
  },

  {
    path: assetsRoute.list,
    element: <AssetsList />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
