import { Asset, GetAssetsQueryParams } from 'api'
import { QueryKey } from 'enums'
import { AppModule } from 'types'

import { useGetAssets } from '../useGetAssets'
import { useMemoCompare } from '../useMemoCompare'
import { TableFilter, useTable } from '../useTable'

interface UseAssetsTableProps {
  queryKey: QueryKey
  module?: AppModule
  enabled?: boolean
  params?: GetAssetsQueryParams
  defaultFilters?: TableFilter
}

export const useAssetsTable = ({ queryKey, params, enabled, ...props }: UseAssetsTableProps) => {
  const { pagination, onChange, getFieldSorter, sortQueryParams, searchQueryParams, ...rest } = useTable<Asset>({
    ...props,
  })

  const queryParams = useMemoCompare({
    limit: pagination.limit,
    page: pagination.page,
    sort: sortQueryParams,
    s: searchQueryParams,
    ...params,
  })

  const { data, isLoading, total } = useGetAssets({
    queryKey,
    enabled,
    params: queryParams,
  })

  const dataSource: Asset[] = (data || []).map(({ id, ...rest }) => ({
    key: id.toString(),
    id,
    ...rest,
  }))

  return {
    dataSource,
    total,
    pagination: {
      ...pagination,
      current: pagination.page,
      pageSize: pagination.limit,
      total,
    },
    getFieldSorter,
    onChange,
    loading: isLoading,
    offsetCount: (pagination.page - 1) * pagination.limit,
    ...rest,
  }
}
