import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { PurchaseOrder } from 'api'
import { AppModule } from 'types'

import { Container, Spacer } from 'designSystem'
import {
  Table,
  TableFilterDrawer,
  TableFilterOptions,
  TableSearchSingle,
  TableTitle,
  useTableColumns,
} from 'components'

import { useVendorPurchaseOrders } from './hooks/useVendorPurchaseOrders'
import { getTableColumns } from './utils/getTableColumns'
import { vendorPurchaseOrdersFilterOptions } from './constants/vendorPurchaseOrdersFilterOptions'
import { vendorPurchaseOrdersVisibleColumnsOptions } from './constants/vendorPurchaseOrdersVisibleColumns'

export const VendorPurchaseOrders: FC = () => {
  const { id } = useParams()
  const {
    data,
    isLoading,
    pagination,
    onTableChange,
    getFieldSorter,
    offsetCount,
    TableContextProvider,
    filterOptions,
    setFilterOptions,
  } = useVendorPurchaseOrders({ vendorId: Number(id) })
  const allColumns = getTableColumns(getFieldSorter, offsetCount)

  const { visibleColumns, setVisibleColumns, columns } = useTableColumns<PurchaseOrder>({
    module: AppModule.VendorPurchaseOrders,
    allColumns,
  })

  const purchaseOrdersList: PurchaseOrder[] = data.map(({ id, ...rest }) => ({
    key: id.toString(),
    id,
    ...rest,
  }))

  return (
    <Container pa={4} fd="column">
      <TableContextProvider>
        <TableTitle
          withoutDefaultPadding
          title="PO's"
          total={pagination?.total}
          search={<TableSearchSingle searchFieldName={['locationCode.code', 'items.item.sku', 'number']} />}
          filter={
            <TableFilterDrawer
              setFilterOptions={setFilterOptions}
              filterOptions={filterOptions}
              options={vendorPurchaseOrdersFilterOptions}
              module={AppModule.VendorPurchaseOrders}
            />
          }
          columns={
            <TableFilterOptions
              title="Columns"
              icon="columns"
              options={vendorPurchaseOrdersVisibleColumnsOptions}
              value={visibleColumns}
              onChange={setVisibleColumns}
            />
          }
        />
        <Spacer mb={4} />
        <Table<PurchaseOrder>
          scroll={{ x: 750 }}
          dataSource={purchaseOrdersList}
          loading={isLoading}
          columns={columns}
          pagination={pagination}
          onChange={onTableChange}
        />
      </TableContextProvider>
    </Container>
  )
}
