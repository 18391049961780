import { FC } from 'react'

import * as Styled from './styles'

export const PasswordRequirements: FC = () => {
  return (
    <Styled.List>
      <li>Minimum 8 characters</li>
      <li>At least one uppercase letter</li>
      <li>At least one lowercase letter</li>
      <li>Includes numbers</li>
      <li>No spaces</li>
      <li>Include at least one special character</li>
    </Styled.List>
  )
}
