import { FC, useCallback } from 'react'

import { useTableContext } from 'hooks'
import { getEqualsFilter } from 'utils'
import { useUser } from 'store'

import { SelectSingleOption, Switch } from 'designSystem'

export const TableFilterCreatedByMe: FC = () => {
  const name = 'createdById'
  const { updateFilters, getFilterValue } = useTableContext()
  const user = useUser()
  const value = getFilterValue?.(name) as SelectSingleOption | undefined

  const handleFilterChange = useCallback(
    (checked: boolean) => {
      updateFilters?.(getEqualsFilter(name, { value: checked && user ? user.id : undefined }))
    },
    [updateFilters, user],
  )

  return <Switch value={!!value?.value} onChange={handleFilterChange} text="Show Only Created By Me" />
}
