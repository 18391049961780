import { MarginSettingOperator } from 'api/types/marginSetting/marginSettingOperator'

export const marginSettingsOperatorOptions = [
  {
    label: '+',
    value: MarginSettingOperator.Plus,
  },
  {
    label: '-',
    value: MarginSettingOperator.Minus,
  },
]

export const getMarginSettingsOperatorLabel = (operator: MarginSettingOperator) =>
  marginSettingsOperatorOptions.find(({ value }) => value === operator)?.label || ''
