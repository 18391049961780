import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { EntityId } from 'api'

import { showNotification } from 'designSystem'

import { postInvite } from '../queryApi'

export const useResendInvite = () => {
  const { mutate, ...rest } = useMutation({
    mutationFn: postInvite,
    onSuccess: () => {
      showNotification({ title: 'Invite sent', type: 'success' })
    },
  })

  const resendInvite = useCallback(
    (payload: EntityId) => {
      mutate(payload)
    },
    [mutate],
  )

  return {
    resendInvite,
    ...rest,
  }
}
