import * as Yup from 'yup'

import { numberBasicValidation } from 'utils'

export const validationSchema = Yup.object({
  title: Yup.string().max(50, 'Max length of 50 characters').required('Title is required'),
  bedLengthInFt: numberBasicValidation
    .required('Bed Length is required')
    .min(0, 'Bed Length must be greater than 0')
    .max(99, 'Bed Length must be less than 99'),
  code: Yup.string().max(10, 'Max length of 10 characters').required('Truck Code is required'),
  allow5ftOverhang: Yup.boolean().nullable(),
})
