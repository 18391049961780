import { FC } from 'react'

import { ReferenceNumberItem } from 'api'
import { useWatch } from 'hooks'
import { ReferenceFormAccessor, typedMemo } from 'types'

import { Button, Container } from 'designSystem'
import { getVendorReleaseItemsQty } from 'modules/references/utils/getVendorReleaseItemsQty'

interface BookingNumberButtonsProps {
  isLoading: boolean
  onClickCancel: () => void
  vendorReleaseItems?: Record<string, number>
}

const BookingNumberButtonsBase: FC<BookingNumberButtonsProps> = ({ isLoading, onClickCancel, vendorReleaseItems }) => {
  const items = useWatch({ name: ReferenceFormAccessor.Items }) as ReferenceNumberItem[]

  const itemsQty = vendorReleaseItems ? getVendorReleaseItemsQty(items) : {}

  const disableSubmitButton = Object.keys(itemsQty).some((itemId) => {
    return vendorReleaseItems?.[itemId] && itemsQty[itemId] > vendorReleaseItems[itemId]
  })

  return (
    <Container pa={4} jc="space-between" gap={8}>
      <Button onClick={onClickCancel}>Cancel</Button>
      <Button type="primary" htmlType="submit" loading={isLoading} disabled={disableSubmitButton}>
        Save
      </Button>
    </Container>
  )
}

export const BookingNumberButtons = typedMemo(BookingNumberButtonsBase)
