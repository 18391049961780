import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { userManagementRoutes } from './constants/userManagementRoutes'

export const UserManagement: FC = () => {
  return (
    <Routes>
      {userManagementRoutes.map((userManagementRoutes) => (
        <Route
          key={userManagementRoutes.path}
          path={userManagementRoutes.path}
          element={userManagementRoutes.element}
        />
      ))}
    </Routes>
  )
}
