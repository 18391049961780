import { css, styled } from 'styled-components'

import { DatePicker } from '../../DatePicker'
import type { EditDatePickerProps } from './EditDatePicker'

export const EditDatePicker = styled(DatePicker)<EditDatePickerProps>`
  .ant-picker-input input {
    text-align: ${({ align }) => align};
  }
  ${({ size }) => {
    if (size === 'small') {
      return css`
        height: ${({ theme }) => theme.inlineEdit.size.s};
        .ant-picker-input input {
          font-size: ${({ theme }) => theme.fontSize.xs};
          line-height: ${({ theme }) => theme.lineHeight.s};
        }
      `
    }
    if (size === 'middle') {
      return css`
        height: ${({ theme }) => theme.inlineEdit.size.m};
        .ant-picker-input input {
          font-size: ${({ theme }) => theme.fontSize.s};
          line-height: ${({ theme }) => theme.lineHeight.m};
        }
      `
    }
  }}
`
