import { cloneElement, KeyboardEvent, ReactElement, useCallback, useEffect, useState } from 'react'
import { Popover, Tooltip } from 'antd'
import isEqual from 'react-fast-compare'

import { Controller, useFormContext, useFormState } from 'hooks/useForm'
import { typedMemo } from 'types'
import { getIsFormFieldViewOnly, getObjectValueByKey } from 'utils'
import theme from 'styles/theme'

import { getFormItemPrefix } from './utils/getFormItemPrefix'
import * as Styled from './styles'

import { ConfirmationButtons } from '../ConfirmationButtons'
import { Container } from '../Container'
import { Icon } from '../Icon'
import { Typography } from '../Typography'

export type FormItemProps = {
  name: string
  label?: string
  defaultValue?: string | number
  isRequired?: boolean
  hidden?: boolean
  children: ReactElement
  onChange?: (value: any) => void
  onBlur?: () => void
  disableOnChange?: boolean
  showErrorBottom?: boolean
  isViewOnly?: boolean
} & (
  | {
      disableOnChange: true
      withConfirmation: true //// works with inputNumber, Input, TextArea
    }
  | {
      withConfirmation?: never
      disableOnChange?: boolean
    }
)

export const FormItemBase = ({
  defaultValue,
  disableOnChange,
  name,
  children,
  label,
  isRequired,
  hidden,
  showErrorBottom = false,
  withConfirmation,
  isViewOnly,
}: FormItemProps) => {
  const { control, setValue, getValues, trigger, viewOnlyFields } = useFormContext()

  const { errors } = useFormState({ name, exact: true })
  const formValue = getValues(name)
  const isFieldViewOnly = isViewOnly || getIsFormFieldViewOnly(viewOnlyFields, name)

  const error = getObjectValueByKey(name, errors)?.message
  const [isShowPopover, setIsShowPopover] = useState(false)
  const [currentValue, setCurrentValue] = useState(formValue)

  // check if children component is inputNumber
  const childrenDisplayName = (children.type as React.ComponentType)?.displayName
  const childrenHasOnPressEnterHandler = childrenDisplayName && ['Input', 'InputNumber'].includes(childrenDisplayName)
  const emptyValue = childrenDisplayName === 'InputNumber' ? null : ''
  const isConfirmButtonDisabled = currentValue === formValue || (formValue === undefined && currentValue === emptyValue)

  const { onBlur: childrenOnBlur, onFocus: childrenOnFocus, onChange: childrenOnChange } = children.props || {}

  const handleCancelConfirmation = useCallback(() => {
    const newValue = formValue || emptyValue
    setValue(name, newValue)
    setCurrentValue(newValue)
  }, [formValue, emptyValue, setValue, name])

  const handleConfirmConfirmation = useCallback(() => {
    setValue(name, currentValue)
    trigger(name)
  }, [setValue, name, currentValue, trigger])

  const handleFocus = useCallback(() => {
    childrenOnFocus?.()
    if (withConfirmation) {
      setIsShowPopover(true)
    }
  }, [childrenOnFocus, withConfirmation])

  const handleChange = useCallback(
    (fieldOnChange: (value: any) => void) =>
      (value: any, ...args: any) => {
        if (withConfirmation) {
          setCurrentValue(value)
        }
        childrenOnChange?.(value, ...args)
        if (!disableOnChange) {
          fieldOnChange(value)
        }
      },
    [disableOnChange, withConfirmation, childrenOnChange],
  )

  const handleBlur = useCallback(
    (fieldOnBlur: () => void) => () => {
      fieldOnBlur()
      if (withConfirmation) {
        handleCancelConfirmation()
        setIsShowPopover(false)
      }
      childrenOnBlur?.()
    },
    [withConfirmation, childrenOnBlur, handleCancelConfirmation],
  )

  const handlePressEnter = useCallback(
    (event: KeyboardEvent) => {
      children.props.onPressEnter?.(event)

      if (withConfirmation) {
        handleConfirmConfirmation()
      }
    },
    [children.props, handleConfirmConfirmation, withConfirmation],
  )

  useEffect(() => {
    if (!isEqual(formValue, currentValue)) {
      setCurrentValue(formValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue])

  return (
    <>
      {label && (
        <Styled.Title>
          <Container>
            <Typography as="label" size="xs" fontWeight="l" color={error ? 'error' : 'primary'}>
              {label}
              {isRequired && <Typography color="error">*</Typography>}
            </Typography>
          </Container>
          {error && (
            <Tooltip
              title={error}
              trigger="hover"
              placement="topRight"
              getPopupContainer={(node) => node.parentNode as HTMLElement}
            >
              <Icon icon="errorFilled" size={15} color={theme.colors.error[400]} />
            </Tooltip>
          )}
        </Styled.Title>
      )}
      <Popover
        placement="bottomRight"
        destroyTooltipOnHide
        open={isShowPopover && withConfirmation}
        arrow={false}
        content={
          <ConfirmationButtons
            onConfirm={handleConfirmConfirmation}
            onCancel={handleCancelConfirmation}
            preventDefault={false}
            disabled={isConfirmButtonDisabled}
          />
        }
      >
        <Styled.ControllerWrapper $hidden={hidden} $isFieldViewOnly={isFieldViewOnly}>
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field, fieldState }) =>
              cloneElement(children, {
                ref: field.ref,
                name,
                error: fieldState?.error?.message,
                prefix: getFormItemPrefix({
                  prefix: children.props.prefix,
                  error: fieldState?.error?.message,
                  label,
                }),
                value: withConfirmation ? currentValue : field.value,
                onChange: handleChange(field.onChange),
                onFocus: handleFocus,
                onBlur: handleBlur(field.onBlur),
                ...(childrenHasOnPressEnterHandler && { onPressEnter: handlePressEnter }),
              })
            }
          />
        </Styled.ControllerWrapper>
      </Popover>

      {showErrorBottom && !!error && (
        <Typography size="xs" fontWeight="l" color={error ? 'error' : 'primary'}>
          {error}
        </Typography>
      )}
    </>
  )
}

export const FormItem = typedMemo(FormItemBase)
