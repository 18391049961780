import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { deleteEquipment } from '../queryApi'

export const useDeleteEquipment = (vendorId: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation({
    mutationFn: deleteEquipment,
    onSuccess: () => {
      dispatchAppEvent(AppEvent.EquipmentDelete)

      showNotification({ title: 'Equipment deleted', type: 'success' })
    },
  })

  const remove = useCallback(
    (equipmentId: number) => {
      mutate({ vendorId, equipmentId })
    },
    [mutate, vendorId],
  )

  return {
    remove,
    ...rest,
  }
}
