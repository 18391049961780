export enum Accessor {
  Index = 'index',
  Number = 'number',
  ZohoId = 'zohoId',
  Customer = 'customer',
  Status = 'status',
  Invoice = 'invoice',
  SalesRepresentative = 'salesRepresentative',
  Dispatcher = 'dispatcher',
  CreatedAt = 'createdAt',
  ExpectedDate = 'expectedDate',
  ItemSKUs = 'itemSKUs',
  Total = 'total',
  Modification = 'modification',
  AccountingStatus = 'accountingStatus',
  GeneralNotes = 'generalNotes',
}
