import { Navigate } from 'react-router-dom'

import { vendorPartsRoute } from 'constant'

import { LinkTabs, NotFoundPage } from 'components'

import { partTabs } from './partTabs'

export const partRoutes = [
  {
    path: '/',
    element: <Navigate to={vendorPartsRoute.details.replace('/', '')} replace />,
  },
  {
    path: vendorPartsRoute.details,
    element: <LinkTabs items={partTabs} />,
  },
  {
    path: vendorPartsRoute.inventory,
    element: <LinkTabs items={partTabs} />,
  },
  {
    path: vendorPartsRoute.settings,
    element: <LinkTabs items={partTabs} />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
