import { FC } from 'react'

import { typedMemo } from 'types'

import * as Styled from './styles'

interface ButtonFavoriteProps {
  onClick?: () => void
  isFavorite?: boolean
  disabled?: boolean
}

export const ButtonFavoriteBase: FC<ButtonFavoriteProps> = ({ onClick, isFavorite, ...props }) => {
  return <Styled.ButtonFavorite icon="star" size="small" onClick={onClick} checked={isFavorite} {...props} />
}

export const ButtonFavorite = typedMemo(ButtonFavoriteBase)
