import { FC } from 'react'

import { UserNotification, UserNotificationModule } from 'api'

import { ReferenceNotification } from './elements/ReferenceNotification'

export const SingleNotification: FC<UserNotification> = (notification) => {
  if (!notification.module) return null

  const Component = {
    [UserNotificationModule.Reference]: <ReferenceNotification notification={notification} />,
    [UserNotificationModule.SalesOrder]: null,
    [UserNotificationModule.PurchaseOrder]: null,
  }[notification.module]

  return Component
}
