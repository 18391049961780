import { api, EntityId, referenceItemsDefaultQueryParams, ReferenceNumberItem } from 'api'

import {
  BookingNumberFormItem,
  PickupNumberFormItem,
  RedeliveryNumberFormItem,
  VendorReleaseNumberFormItem,
} from '../../types'

export type PatchReferenceNumberItemPayload =
  | BookingNumberFormItem
  | PickupNumberFormItem
  | RedeliveryNumberFormItem
  | VendorReleaseNumberFormItem

export type PostReferenceNumberItemPayload = PatchReferenceNumberItemPayload

export interface PatchReferenceItemQueryParams {
  referenceId?: EntityId
  itemId?: EntityId
  data?: PatchReferenceNumberItemPayload
}

export const patchReferenceItem = ({
  referenceId,
  itemId,
  data,
}: PatchReferenceItemQueryParams): Promise<ReferenceNumberItem> => {
  return api({
    method: 'patch',
    url: `/api/v1/references/${referenceId}/items/${itemId}`,
    data,
    params: referenceItemsDefaultQueryParams,
  })
}

export interface PostReferenceItemQueryParams {
  referenceId?: EntityId
  data?: PatchReferenceNumberItemPayload
}

export const postReferenceItem = ({
  referenceId,
  data,
}: PostReferenceItemQueryParams): Promise<ReferenceNumberItem> => {
  return api({
    method: 'post',
    url: `/api/v1/references/${referenceId}/items`,
    data,
    params: referenceItemsDefaultQueryParams,
  })
}

export interface DeleteReferenceItemParams {
  referenceId?: number
  itemId?: number
}

export const deleteReferenceItem = ({ referenceId, itemId }: DeleteReferenceItemParams): Promise<void> => {
  return api({
    method: 'delete',
    url: `/api/v1/references/${referenceId}/items/${itemId}`,
  })
}
