import { FC } from 'react'
import { Route, Routes, useLocation, useParams } from 'react-router-dom'
import { Layout } from 'antd'

import { locationCodesRoute, route } from 'constant'

import { Breadcrumbs, Sidebar } from 'components'

import { getSidebarItems } from './utils/getSidebarItems'
import { breadcrumbsData } from './constants/breadcrumbsData'
import { locationCodeRoutes } from './constants/locationCodeRoutes'
import { routesWithoutSidebar } from './constants/routesWithoutSidebar'
import { ActionButtons } from './elements/ActionButtons'

export const LocationCode: FC = () => {
  const { id } = useParams()
  const location = useLocation()

  const locationSidebarItems = getSidebarItems(Number(id))
  const sidebarItems = routesWithoutSidebar.includes(location.pathname) ? [] : locationSidebarItems

  const showActions = location.pathname === `${route.locationCodes}/${id}${locationCodesRoute.details}`

  return (
    <Layout>
      <Sidebar sidebarItems={sidebarItems} />
      <Layout.Content>
        <Breadcrumbs breadcrumbItems={breadcrumbsData} actions={showActions && <ActionButtons />} />
        <Routes>
          {locationCodeRoutes.map((assetRoute) => (
            <Route key={assetRoute.path} path={assetRoute.path} element={assetRoute.element} />
          ))}
        </Routes>
      </Layout.Content>
    </Layout>
  )
}
