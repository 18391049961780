import { css, styled } from 'styled-components'

import { Container as BaseContainer } from 'designSystem'

export const PopoverWrapper = styled.div`
  position: absolute;
  top: -12px;
  right: 0;
`

export const Container = styled(BaseContainer)<{ $isFieldViewOnly: boolean }>`
  ${({ $isFieldViewOnly }) =>
    $isFieldViewOnly &&
    css`
      pointer-events: none;
    `}
`
