import { styled } from 'styled-components'

import type { GridLayoutProps } from './GridLayout'

export const GridLayoutContainer = styled.div<GridLayoutProps>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ columns, gap = 0 }) =>
    `repeat(${columns}, calc((100% - ${gap * (columns - 1)}px) / ${columns}))`};
  grid-gap: ${({ gap }) => gap && `${gap}px`};
`
