import { ReferenceNumber, ReferenceNumberBookingTransactionType } from 'api'
import type { TableField, TableSortingOrder } from 'hooks'
import { hash, referenceNumberBookingTransactionTypeLabel, referenceNumberBookingTypeOptionLabel } from 'constant'
import { formatDate } from 'utils'

import { ReactLink, Typography } from 'designSystem'
import { ReferenceStatusTag, TableColumnKey, TableColumnsType } from 'components'

import { DepotBookingAccessor } from '../types/depotBookingAccessor'

import { getTableTitle } from './getTableTitle'

export const getTableColumns = (
  getFieldSorter: (field: TableField) => TableSortingOrder,
  offsetCount: number,
): TableColumnsType<ReferenceNumber> => [
  {
    title: getTableTitle(DepotBookingAccessor.Index),
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => offsetCount + index + 1,
  },
  {
    title: getTableTitle(DepotBookingAccessor.Number),
    key: DepotBookingAccessor.Number,
    dataIndex: DepotBookingAccessor.Number,
    width: 200,
    sorter: true,
    sortOrder: getFieldSorter(DepotBookingAccessor.Number),
    ellipsis: true,
    render: (number: ReferenceNumber[DepotBookingAccessor.Number], record) => {
      return (
        <ReactLink to={`${hash.reference}/${record.id}`} target="_self">
          <Typography color="inherit" size="xs" fontWeight="l" lineEllipsis={1}>
            {number}
          </Typography>
        </ReactLink>
      )
    },
  },
  {
    title: getTableTitle(DepotBookingAccessor.BookingType),
    key: DepotBookingAccessor.BookingType,
    dataIndex: DepotBookingAccessor.BookingType,
    width: 150,
    ellipsis: true,
    render: (bookingType: ReferenceNumber[DepotBookingAccessor.BookingType]) =>
      referenceNumberBookingTypeOptionLabel[bookingType],
  },
  {
    title: getTableTitle(DepotBookingAccessor.TransactionType),
    key: DepotBookingAccessor.TransactionType,
    dataIndex: DepotBookingAccessor.TransactionType,
    width: 150,
    ellipsis: true,
    render: (transactionType: ReferenceNumberBookingTransactionType) =>
      referenceNumberBookingTransactionTypeLabel[transactionType],
  },
  {
    title: getTableTitle(DepotBookingAccessor.Status),
    key: DepotBookingAccessor.Status,
    dataIndex: DepotBookingAccessor.Status,
    width: 200,
    ellipsis: true,
    render: (status: ReferenceNumber[DepotBookingAccessor.Status]) => <ReferenceStatusTag status={status} />,
  },
  {
    title: getTableTitle(DepotBookingAccessor.CreatedAt),
    key: DepotBookingAccessor.CreatedAt,
    dataIndex: DepotBookingAccessor.CreatedAt,
    width: 130,
    ellipsis: true,
    render: (createdAt: ReferenceNumber[DepotBookingAccessor.CreatedAt]) => formatDate(createdAt),
  },
  {
    title: getTableTitle(DepotBookingAccessor.ExpirationDate),
    key: DepotBookingAccessor.ExpirationDate,
    dataIndex: DepotBookingAccessor.ExpirationDate,
    width: 130,
    ellipsis: true,
    render: (expirationDate: ReferenceNumber[DepotBookingAccessor.ExpirationDate]) =>
      expirationDate ? formatDate(expirationDate) : '-',
  },
]
