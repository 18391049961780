import { assetHolderOptions, assetNumberTypeOptions, assetStatusOptions } from 'constant'

import { TableFilterSelectMulti } from 'components'

export const assetsFilterOptions = [
  {
    value: 'status',
    label: 'Status',
    component: <TableFilterSelectMulti key="status" name="status" placeholder="Status" options={assetStatusOptions} />,
  },
  {
    value: 'assetHolder',
    label: 'Asset Holder',
    component: (
      <TableFilterSelectMulti
        key="assetHolder"
        name="assetHolder"
        placeholder="Asset Holder"
        options={assetHolderOptions}
      />
    ),
  },

  {
    value: 'assetNumberType',
    label: 'Asset Number Type',
    component: (
      <TableFilterSelectMulti
        key="assetNumberType"
        name="assetNumberType"
        placeholder="Asset Number Type"
        options={assetNumberTypeOptions}
      />
    ),
  },
]
