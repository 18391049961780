import { AssetStatus } from 'api'
import { assetStatusOptionLabel } from 'constant'

import { TagType } from 'designSystem'

interface AssetStatusTagData {
  status: TagType
  text?: string
}

export const getTagDataByAssetStatus = (status: AssetStatus): AssetStatusTagData => {
  const text = assetStatusOptionLabel[status]

  const tagStatus = {
    [AssetStatus.Create]: TagType.Done,
    [AssetStatus.Recycle]: TagType.Pending,
    [AssetStatus.Rented]: TagType.InProgress,
    [AssetStatus.Split]: TagType.Warning,
    [AssetStatus.Booked]: TagType.Success,
    [AssetStatus.Sold]: TagType.Default,
    [AssetStatus.Available]: TagType.Success,
    [AssetStatus.NotAvailable]: TagType.Disabled,
    [AssetStatus.PendingDelete]: TagType.Error,
    [AssetStatus.VendorAccount]: TagType.Done,
    [AssetStatus.PendingVendorRelease]: TagType.Done,
    [AssetStatus.PendingRedelivery]: TagType.Done,
  }[status]

  return { status: tagStatus, text }
}
