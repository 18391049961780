import { referencesRoute } from 'constant'

import { NotFoundPage } from 'components'

import { ReferenceCreate } from '../routes/ReferenceCreate'
import { ReferenceCreateChooseType } from '../routes/ReferenceCreateChooseType'
import { ReferencesList } from '../routes/ReferencesList'

export const routes = [
  {
    path: referencesRoute.createChooseType,
    element: <ReferenceCreateChooseType />,
  },
  {
    path: referencesRoute.create,
    element: <ReferenceCreate />,
  },
  {
    path: referencesRoute.list,
    element: <ReferencesList />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
