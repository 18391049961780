import { forwardRef, LegacyRef, memo, useCallback, useRef } from 'react'
import type { AutocompleteProps } from '@react-google-maps/api'
import { Autocomplete } from '@react-google-maps/api'

import type { AddressFields } from 'api'
import { typedMemo } from 'types'

import { Input, InputProps } from 'designSystem'

import * as Styled from './styles'

type AutocompleteType = google.maps.places.Autocomplete
export type AutocompleteTypes = 'country' | 'postal_code' | 'locality' | 'route' | 'administrative_area_level_1'

export interface GooglePlacesAutocompleteProps extends Omit<InputProps, 'onBlur'> {
  onChangeAutocomplete?: (fields: AddressFields) => void
  types?: AutocompleteTypes[]
  restrictions?: AutocompleteProps['restrictions']
  onBlur?: () => void
  ml?: number // Dropdowm margin-left
}

export const GooglePlacesAutocompleteBase = memo(
  forwardRef(
    (
      { onChange, types, restrictions, onChangeAutocomplete, width, ml, ...props }: GooglePlacesAutocompleteProps,
      ref,
    ) => {
      const autocompleteRef = useRef<AutocompleteType>()

      const onPlaceChanged = useCallback(() => {
        const place = autocompleteRef?.current?.getPlace()

        if (place?.address_components?.length) {
          const places = place.address_components

          onChangeAutocomplete?.({
            country: places.find(({ types }) => types.includes('country'))?.short_name,
            state: places.find(({ types }) => types.includes('administrative_area_level_1'))?.long_name,
            city: places.find(({ types }) => types.includes('locality'))?.long_name,
            street: places.find(({ types }) => types.includes('route'))?.long_name,
            zipCode: places.find(({ types }) => types.includes('postal_code'))?.long_name,
          })
        }
      }, [onChangeAutocomplete])

      const onLoad = useCallback(
        (autocomplete: AutocompleteType) => {
          autocompleteRef.current = autocomplete
          autocomplete.addListener('place_changed', onPlaceChanged)
        },
        [onPlaceChanged],
      )

      const handleChange = useCallback(
        (value: string) => {
          onChange?.(value)
        },
        [onChange],
      )

      return (
        <Styled.AutocompleteWrapper $width={width}>
          <Autocomplete
            onPlaceChanged={onPlaceChanged}
            onLoad={onLoad}
            restrictions={restrictions}
            types={types}
            fields={['address_components', 'formatted_address']}
            ref={ref as LegacyRef<Autocomplete>}
          >
            <Input {...props} width={width} onChange={handleChange} />
          </Autocomplete>

          <Styled.AutocompletePacContainerStyle $width={width} $ml={ml} />
        </Styled.AutocompleteWrapper>
      )
    },
  ),
)

export const GooglePlacesAutocomplete = typedMemo(GooglePlacesAutocompleteBase)
