export enum Accessor {
  Index = 'index',
  Number = 'number',
  Type = 'type',
  LocationCode = 'locationCode',
  Depot = 'depot',
  Status = 'status',
  ExpectedDate = 'expectedDate',
  ExpirationDate = 'expirationDate',
  CreatedAt = 'createdAt',
  Qty = 'allQty',
  ItemsTotal = 'totalAmount',
  Notes = 'notes',
}
