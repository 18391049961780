export enum TagType {
  Default = 'default',
  Error = 'error',
  Success = 'success',
  Warning = 'warning',
  Disabled = 'disabled',
  InProgress = 'in progress',
  Pending = 'pending',
  Done = 'done',
}
