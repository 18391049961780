import { FieldValues, UseFormWatch } from 'react-hook-form'

import { SalesOrderFormAccessor } from 'types'

interface GetLineItemSubTotalProps {
  watch: UseFormWatch<FieldValues>
  index: number
}

export const getLineItemSubTotal = ({ watch, index }: GetLineItemSubTotalProps) => {
  const currentLineItemQty =
    watch(`${SalesOrderFormAccessor.LineItems}.${index}.${SalesOrderFormAccessor.LineItemQty}`) || 0
  const currentLineItemPrice =
    watch(`${SalesOrderFormAccessor.LineItems}.${index}.${SalesOrderFormAccessor.LineItemPrice}`) || 0
  const currentLineItemDiscount =
    watch(`${SalesOrderFormAccessor.LineItems}.${index}.${SalesOrderFormAccessor.LineItemDiscount}`) || 0
  return currentLineItemQty * currentLineItemPrice - currentLineItemDiscount
}
