import { FC } from 'react'

import { typedMemo } from 'types'

import * as Styled from './styles'

import { IconProps } from '../../Icon'

type IconCloseProps = Omit<IconProps, 'icon'>

export const IconDeleteBase: FC<IconCloseProps> = ({ onClick, size = 16, ...props }) => {
  return <Styled.Icon size={size} onClick={onClick} {...props} icon="cross" />
}

export const IconDelete = typedMemo(IconDeleteBase)
