import { purchaseOrderLabels } from 'constant'

import { Accessor } from '../types/accessor'

export const getAccessorTitle = (accessor: Accessor): string => {
  return {
    [Accessor.Index]: '#',
    [Accessor.Number]: purchaseOrderLabels.number,
    [Accessor.Status]: purchaseOrderLabels.status,
    [Accessor.VendorName]: purchaseOrderLabels.vendorName,
    [Accessor.ExpectedDate]: purchaseOrderLabels.expectedDate,
    [Accessor.Department]: purchaseOrderLabels.department,
    [Accessor.Type]: purchaseOrderLabels.type,
    [Accessor.LocationDepot]: 'Location & Depot',
    [Accessor.PaymentTerms]: purchaseOrderLabels.paymentTerms,
    [Accessor.ExpirationDate]: purchaseOrderLabels.expirationDate,
    [Accessor.Qty]: 'Qty.',
    [Accessor.ItemsTotal]: 'Items Total',
    [Accessor.Notes]: purchaseOrderLabels.notes,
    [Accessor.ExternalPoNotes]: purchaseOrderLabels.externalPoNotes,
    [Accessor.CreatedBy]: purchaseOrderLabels.createdBy,
  }[accessor]
}
