import { css, styled } from 'styled-components'

type WrapperProps = {
  isEditMode: boolean
  $isFieldViewOnly?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  min-width: 0;
  display: flex;
  form {
    min-width: 0;
  }
  ${({ $isFieldViewOnly }) =>
    $isFieldViewOnly &&
    css`
      pointer-events: none;
    `}
  ${({ isEditMode }) =>
    isEditMode &&
    css`
      width: 100%;
      form {
        flex: 1;
      }
    `}
`
