import { getAssetBookingReferenceAccessorTitle } from '../utils/getAssetBookingReferenceAccessorTitle'
import { AssetBookingReferenceAccessor } from '../types/assetBookingReferenceAccessor'

export const assetBookingReferencesRequiredColumns = [
  AssetBookingReferenceAccessor.Index,
  AssetBookingReferenceAccessor.Number,
]

export const assetBookingReferencesDefaultColumns = [
  AssetBookingReferenceAccessor.Index,
  AssetBookingReferenceAccessor.CreatedAt,
  AssetBookingReferenceAccessor.Number,
  AssetBookingReferenceAccessor.Type,
  AssetBookingReferenceAccessor.TransactionType,
  AssetBookingReferenceAccessor.RelatedReference,
  AssetBookingReferenceAccessor.GateOutDate,
  AssetBookingReferenceAccessor.Notes,
  AssetBookingReferenceAccessor.Status,
]

export const assetBookingReferencesVisibleColumnsOptions = Object.values(AssetBookingReferenceAccessor).map(
  (accessor) => ({
    value: accessor,
    label: getAssetBookingReferenceAccessorTitle(accessor),
    disabled: assetBookingReferencesRequiredColumns.includes(accessor),
  }),
)
