import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { Spinner, ZohoIntegration } from 'components'

import { useGetItemById } from '../ItemDetails/hooks/useGetItemById'

export const ItemIntegrations: FC = () => {
  const { id } = useParams()

  const { data, isLoading } = useGetItemById(Number(id))

  if (isLoading) {
    return <Spinner />
  }

  return <ZohoIntegration module="zohoItemsLink" id={data?.zohoId} />
}
