import { salesOrderLabels } from 'constant'
import { SalesOrderSubLinesAccessor } from 'types'

export const getAccessorSubLineTitle = (accessor: SalesOrderSubLinesAccessor): string => {
  return {
    [SalesOrderSubLinesAccessor.Sku]: salesOrderLabels.lineItems.itemId,
    [SalesOrderSubLinesAccessor.ItemName]: salesOrderLabels.lineItems.title,
    [SalesOrderSubLinesAccessor.Qty]: salesOrderLabels.lineItems.qty,
    [SalesOrderSubLinesAccessor.Price]: salesOrderLabels.lineItems.price,
    [SalesOrderSubLinesAccessor.Discount]: salesOrderLabels.lineItems.discount,
    [SalesOrderSubLinesAccessor.Taxes]: salesOrderLabels.lineItems.taxes,
    [SalesOrderSubLinesAccessor.SubTotal]: salesOrderLabels.lineItems.subtotal,
    [SalesOrderSubLinesAccessor.Total]: salesOrderLabels.lineItems.total,
  }[accessor]
}
