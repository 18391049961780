import { FC, MouseEvent, useCallback, useEffect, useState } from 'react'
import { Popover } from 'antd'

import { AddressFields } from 'api'
import { useFormContext } from 'hooks/useForm'
import { countries, defaultAddressFieldsNames } from 'constant'
import { typedMemo } from 'types'
import { getIsFormFieldViewOnly } from 'utils'

import { Container, EditView, Typography } from 'designSystem'

import { AddressFields as AddressFieldsComponent } from './elements/AddressFields'
import * as Styled from './styles'

interface FormItemEditAddressProps {
  onConfirm?: () => void
  disabled?: boolean
  name?: string
  label?: string
  onOpenChange?: (open: boolean) => void
  hideFields?: (keyof AddressFields)[]
}

export const FormItemEditAddressBase: FC<FormItemEditAddressProps> = ({
  onConfirm,
  disabled,
  name,
  label,
  onOpenChange,
  hideFields,
}) => {
  const [isEdit, setIsEdit] = useState(false)
  const { getValues, viewOnlyFields } = useFormContext()

  const { country, state, city, street, zipCode } = (name ? getValues(name) : getValues()) || {}

  useEffect(() => {
    onOpenChange?.(isEdit)
  }, [isEdit, onOpenChange])

  const addressString = [street, city, state, zipCode, countries[country as keyof typeof countries]]
    .filter(Boolean)
    .join(', ')

  const handleViewClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setIsEdit(true)
    },
    [setIsEdit],
  )

  const onClose = useCallback(() => {
    setIsEdit(false)
  }, [])

  const isFieldViewOnly = getIsFormFieldViewOnly(viewOnlyFields, name || defaultAddressFieldsNames)

  return (
    <Styled.Container gap={24} $isFieldViewOnly={isFieldViewOnly}>
      <Typography size="s" fontWeight="l" color="secondary" nowrap>
        {label || 'Address'}
      </Typography>
      <Container fg={1} jc="flex-end">
        <Styled.PopoverWrapper>
          <Popover
            open={isEdit}
            placement="bottomRight"
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
            trigger="click"
            arrow={false}
            destroyTooltipOnHide
            content={
              <AddressFieldsComponent onClose={onClose} onConfirm={onConfirm} name={name} hideFields={hideFields} />
            }
          />
        </Styled.PopoverWrapper>
        <EditView disabled={disabled} value={addressString} onClick={handleViewClick} />
      </Container>
    </Styled.Container>
  )
}

export const FormItemEditAddress = typedMemo(FormItemEditAddressBase)
