import { ItemType } from 'api/types/item/itemType'

export const itemTypeLabel = {
  [ItemType.Modification]: 'Modification',
  [ItemType.Service]: 'Service',
  [ItemType.Goods]: 'Goods',
  [ItemType.Assets]: 'Assets',
  [ItemType.Transportation]: 'Transportation',
  [ItemType.Transfers]: 'Transfers',
}

export const itemTypeOptions = [
  {
    label: itemTypeLabel[ItemType.Modification],
    value: ItemType.Modification,
  },
  {
    label: itemTypeLabel[ItemType.Service],
    value: ItemType.Service,
  },
  {
    label: itemTypeLabel[ItemType.Goods],
    value: ItemType.Goods,
  },
  {
    label: itemTypeLabel[ItemType.Assets],
    value: ItemType.Assets,
  },
  {
    label: itemTypeLabel[ItemType.Transportation],
    value: ItemType.Transportation,
  },
  {
    label: itemTypeLabel[ItemType.Transfers],
    value: ItemType.Transfers,
  },
]
