import { locationCodesRoute } from 'constant'

import { LocationCodeDetails } from '../routes/LocationCodeDetails'
import { LocationCodeIntegrations } from '../routes/LocationCodeIntegrations'

export const locationCodeRoutes = [
  {
    path: locationCodesRoute.details,
    element: <LocationCodeDetails />,
  },
  {
    path: locationCodesRoute.integrations,
    element: <LocationCodeIntegrations />,
  },
]
