import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { GetReferenceItemsPayload, ReferenceNumberBookingType, ReferenceNumberType } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { route } from 'constant'

import { Button, Container } from 'designSystem'

import type { ReferenceNumberTitleProps } from '../../'

export const VendorReleaseActions: FC<ReferenceNumberTitleProps> = ({ data }) => {
  const navigate = useNavigate()

  const handleCreateBookingClick = useCallback(() => {
    const itemsData = queryClient.getQueryData([QueryKey.GetReferenceItems, data.id]) as GetReferenceItemsPayload

    const referenceData = {
      bookingType: ReferenceNumberBookingType.VendorRelease,
      locationCodeId: data.locationCodeId,
      locationCode: data.locationCode,
      depotSettingId: data.depotSettingId,
      depotSetting: data.depotSetting,
      vendorReleaseId: data.id,
      vendorRelease: data,
      items: itemsData.data,
    }

    navigate(`${route.referencesCreateChooseType}/${ReferenceNumberType.Booking}`, { state: { data: referenceData } })
  }, [navigate, data])

  return (
    <Container gap={8}>
      <Button size="small" px={3} onClick={handleCreateBookingClick}>
        Create Booking
      </Button>
    </Container>
  )
}
