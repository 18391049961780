import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Col, Row, Tooltip } from 'antd'

import { Contact, CustomerStatus } from 'api'
import {
  useCustomers,
  useFormContext,
  useGetCustomerBillingAddresses,
  useGetCustomerContacts,
  useGetCustomerShippingAddresses,
  useWatch,
} from 'hooks'
import { salesOrderLabels } from 'constant'
import { SalesOrderFormAccessor } from 'types'
import theme from 'styles/theme'

import { Container, FormItem, Icon, Input, SelectMulti, SelectSingle } from 'designSystem'
import { AddContactModal, FormItemSelectSingleAsync, NewContact } from 'components'
import { ContactFormData } from 'modules/salesOrders/routes/SalesOrderCreate/types/salesOrderCreateForm'

import { maxContactsCount } from 'modules/salesOrders/constants/recordsCount'
import * as Styled from './styles'

interface CustomerInformationProps {
  contacts: ContactFormData[]
  setContacts: Dispatch<SetStateAction<ContactFormData[]>>
}

export const CustomerInformation: FC<CustomerInformationProps> = ({ contacts, setContacts }) => {
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false)

  const customerId = useWatch({ name: SalesOrderFormAccessor.CustomerId })
  const { setValue, getValues } = useFormContext()

  const { data: customerContacts = [], isLoading } = useGetCustomerContacts(customerId)
  const { options: billingAddressOptions = [], isLoading: isBillingAddressLoading } =
    useGetCustomerBillingAddresses(customerId)
  const { options: shippingAddressOptions = [], isLoading: isShippingAddressLoading } =
    useGetCustomerShippingAddresses(customerId)

  const contactsWithNew = [...contacts, ...customerContacts] as Contact[]

  const contactOptions = contactsWithNew.map((contact) => ({
    value: contact.id || contact.email,
    label: `${contact.firstName} ${contact.lastName}`,
  }))

  useEffect(() => {
    setValue(SalesOrderFormAccessor.ShippingAddressId, undefined)
    setValue(SalesOrderFormAccessor.BillingAddressId, undefined)
    setValue(SalesOrderFormAccessor.ContactsIds, undefined)
    setValue(SalesOrderFormAccessor.OnSiteContactsIds, undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId])

  const toggleContactModal = useCallback(() => {
    setIsAddContactModalOpen((prev) => !prev)
  }, [])

  const handleCreateNewContact = useCallback(
    (payload: NewContact) => {
      setContacts((prev) => [...prev, payload])
      setValue(SalesOrderFormAccessor.ContactsIds, [
        ...(getValues(SalesOrderFormAccessor.ContactsIds) || []),
        payload.email,
      ])
      toggleContactModal()
    },
    [getValues, setContacts, setValue, toggleContactModal],
  )

  return (
    <Container fd="column" width="100%" gap={8}>
      <Row>
        <Col span={24}>
          <FormItemSelectSingleAsync
            name={SalesOrderFormAccessor.CustomerId}
            label={salesOrderLabels.customerId}
            getItems={useCustomers}
            queryParams={{ status: { $eq: CustomerStatus.Active } }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem name={SalesOrderFormAccessor.CustomerPurchaseOrder} label={salesOrderLabels.customerPurchaseOrder}>
            <Input placeholder="Start Typing" />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem name={SalesOrderFormAccessor.ShippingAddressId} label={salesOrderLabels.shippingAddressId}>
            <SelectSingle
              allowClear
              showSearch
              placeholder={customerId ? 'Start Typing' : 'Select customer first'}
              disabled={!customerId}
              options={shippingAddressOptions}
              loading={isShippingAddressLoading}
            />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem name={SalesOrderFormAccessor.BillingAddressId} label={salesOrderLabels.billingAddressId}>
            <SelectSingle
              allowClear
              showSearch
              placeholder={customerId ? 'Start Typing' : 'Select customer first'}
              disabled={!customerId}
              options={billingAddressOptions}
              loading={isBillingAddressLoading}
            />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem name={SalesOrderFormAccessor.ContactsIds} label={salesOrderLabels.contacts}>
            <SelectMulti
              options={customerId ? contactOptions : []}
              loading={isLoading}
              placeholder={customerId ? 'Start typing' : 'Select customer first'}
              disabled={!customerId}
              withCreateNew={contactsWithNew.length < maxContactsCount}
              onClickCreateNew={toggleContactModal}
            />
          </FormItem>
        </Col>
      </Row>
      <Container jc="flex-end" ai="center" mt={2}>
        <FormItem name={SalesOrderFormAccessor.IsEmailRequired}>
          <Styled.Checkbox>{salesOrderLabels.shouldSendEmail}</Styled.Checkbox>
        </FormItem>
        <Tooltip title="Email will be send to selected contacts">
          <Icon icon="info" size={16} color={theme.colors.placeholder} />
        </Tooltip>
      </Container>
      <AddContactModal
        open={isAddContactModalOpen}
        onCancel={toggleContactModal}
        onSubmit={handleCreateNewContact}
        contacts={contactsWithNew}
      />
    </Container>
  )
}
