import { Link } from 'react-router-dom'

import { Role, User, UserStatus } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import type { TableField, TableSortingOrder } from 'hooks'
import { route } from 'constant'

import { Container, Typography } from 'designSystem'
import { ResendInvite, TableColumnKey, TableColumnsType, UserStatusTag } from 'components'

export const getTableColumns = (
  getFieldSorter: (field: TableField) => TableSortingOrder,
  offsetCount: number,
): TableColumnsType<User> => [
  {
    title: '#',
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => offsetCount + index + 1,
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: ['firstName', 'lastName'],
    sorter: true,
    sortOrder: getFieldSorter(['firstName', 'lastName']),
    ellipsis: true,
    width: '45%',
    render: (value, record) => {
      const onClick = () => {
        queryClient.setQueryData([QueryKey.GetUserById, record.id], record)
      }
      return (
        <Link to={`${route.userManagement}/${record.id}`} onClick={onClick}>
          <Typography fontWeight="l" size="xs" lineEllipsis={1}>
            {record.firstName} {record.lastName}
          </Typography>
        </Link>
      )
    },
  },
  {
    title: 'Role',
    key: 'roles',
    dataIndex: 'roles',
    ellipsis: true,
    width: '10%',
    render: (roles: Role[]) => roles.map(({ name }) => name).join(', '),
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email',
    ellipsis: true,
    width: '15%',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    ellipsis: true,
    width: '20%',
    render: (status, record) => (
      <Container ai="center" gap={12}>
        <UserStatusTag userStatus={status} />
        {status === UserStatus.Invited && <ResendInvite id={record.id} />}
      </Container>
    ),
  },
  {
    title: 'User ID',
    key: 'id',
    dataIndex: 'id',
    width: '10%',
    ellipsis: true,
    render: (id) => (
      <Typography color="placeholder" size="xs" fontWeight="l">
        {id}
      </Typography>
    ),
  },
]
