import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { route } from 'constant'

import { showNotification } from 'designSystem'

import { ChangePasswordPayload, patchPassword } from '../queryApi'

interface UseUpdatePasswordProps {
  onError: () => void
}

export const useUpdatePassword = ({ onError }: UseUpdatePasswordProps) => {
  const navigate = useNavigate()
  const { mutate, ...rest } = useMutation({
    mutationFn: patchPassword,
    onSuccess: () => {
      showNotification({ title: 'Password successfully changed', type: 'success' })
      navigate(route.profileMainInfo)
    },
    onError,
  })

  const updatePassword = useCallback(
    (payload: ChangePasswordPayload) => {
      mutate(payload)
    },
    [mutate],
  )

  return {
    updatePassword,
    ...rest,
  }
}
