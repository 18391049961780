import { AssetRepair } from 'api'

import { RepairsFormPayload } from '../types/repairsForm'

export const getPatchPayload = (payload: RepairsFormPayload, defaultData: AssetRepair[]) => {
  return payload.list?.map((repair) => {
    const { id, cost, status, description } = repair
    const prev = defaultData.find((item) => item.id === id)
    const payload = {
      ...(prev?.cost !== cost && { cost }),
      ...(prev?.status !== status && { status }),
      ...(prev?.description !== description && { description }),
    }

    return {
      id,
      payload,
    }
  })
}
