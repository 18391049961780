import { FC } from 'react'

import { useFieldArrayItemWithMultipleEndpoints, useLocationCodes } from 'hooks'
import { distanceUnits } from 'constant'

import { ConfirmationButtons, Container, EditFormItem, Typography } from 'designSystem'
import { Card, FormItemEditInputNumber, FormItemEditSelectSingleAsync } from 'components'

import * as Styled from './styles'

interface EditLocationFieldsProps {
  index: number
  name: string
  onCancel: (index: number) => void
  onDelete: (index: number) => void
  onSubmitPost: (index: number) => void
  onSubmitPatch: (index: number) => void
  showDelete: boolean
  isLoading?: boolean
  disabledValues?: (number | undefined)[]
}

export const EditLocationFields: FC<EditLocationFieldsProps> = ({
  name,
  index,
  onCancel,
  onDelete,
  onSubmitPost,
  onSubmitPatch,
  showDelete,
  isLoading,
  disabledValues,
}) => {
  const {
    id,
    value,
    isError,
    isLoading: isCurrentLoading,
    showDeleteButton,
    handleSubmitPost,
    handleSubmitPatch,
    handleDelete,
  } = useFieldArrayItemWithMultipleEndpoints({
    name,
    isLoading,
    onSubmitPost,
    onSubmitPatch,
    onDelete,
    showDelete,
    index,
  })
  const { locationCode, boundary } = value || {}
  const isConfirmationDisabled = !boundary || !locationCode?.value || isError || isCurrentLoading

  return (
    <Card pa={4} isLoading={isCurrentLoading} showDelete={showDeleteButton} onClickDelete={handleDelete}>
      <Container gap={4} fd="column">
        <FormItemEditSelectSingleAsync
          required
          id={id}
          name={`${name}.${index}.locationCode`}
          nestedName="value"
          label="Location"
          onSubmit={handleSubmitPatch}
          defaultOption={{
            value: locationCode?.value as number,
            label: locationCode?.label as string,
          }}
          disabledValues={disabledValues}
          index={index}
          getItems={useLocationCodes}
        />

        <EditFormItem
          name={`${name}.${index}.boundary`}
          label="Boundary"
          render={({ field }) => (
            <FormItemEditInputNumber
              precision={2}
              placeholder="Type Boundary"
              {...field}
              onBlur={handleSubmitPatch}
              suffix={
                <Styled.SuffixWrapper>
                  <Typography size="s" fontWeight="l">
                    {distanceUnits.Mi}
                  </Typography>
                </Styled.SuffixWrapper>
              }
              suffixWidth={44}
            />
          )}
        />
      </Container>
      {!id && (
        <ConfirmationButtons
          trigger="click"
          onConfirm={handleSubmitPost}
          onCancel={() => onCancel(index)}
          disabled={isConfirmationDisabled}
        />
      )}
    </Card>
  )
}
