import { PurchaseOrderType } from 'api'
import { PurchaseOrderSubLineAccessor } from 'types'

import { TableFilterOption } from 'components'

import { redeliveryEditModeColumns } from 'modules/purchaseOrders/constants/redeliveryColumns'
import { vendorReleaseEditModeColumns } from 'modules/purchaseOrders/constants/vendorReleaseColumns'

export const getSubLineOptions = (
  poType: PurchaseOrderType,
  purchaseOrderSubItemsVisibleColumnsOptions: TableFilterOption[],
) => {
  switch (poType) {
    case PurchaseOrderType.VendorRelease:
      return purchaseOrderSubItemsVisibleColumnsOptions.filter((option) =>
        vendorReleaseEditModeColumns.includes(option.value as PurchaseOrderSubLineAccessor),
      )

    case PurchaseOrderType.Redelivery:
      return purchaseOrderSubItemsVisibleColumnsOptions.filter((option) =>
        redeliveryEditModeColumns.includes(option.value as PurchaseOrderSubLineAccessor),
      )

    default:
      return undefined
  }
}
