import { PurchaseOrder } from 'api'

import { referenceNotAllowedStatuses } from '../constants/referenceNotAllowedStatuses'

export const prepareNewSubLineData = (poData?: PurchaseOrder) => ({
  expectedDeliveryDate: poData?.expectedDate,
  locationCodeId: poData?.locationCodeId,
  referenceType: poData?.type || null,
  asset: {
    vendorDepotId: poData?.depotId,
    vendorReleaseRefId:
      poData?.vendorReleaseReferenceNumber?.id &&
      !referenceNotAllowedStatuses.includes(poData?.vendorReleaseReferenceNumber.status)
        ? poData?.vendorReleaseReferenceNumber?.id
        : null,
    redeliveryRefId:
      poData?.redeliveryReferenceNumber?.id &&
      !referenceNotAllowedStatuses.includes(poData?.redeliveryReferenceNumber.status)
        ? poData?.vendorReleaseReferenceNumber?.id
        : null,
  },
})
