import { FC, useCallback, useEffect } from 'react'
import { Col, Row } from 'antd'

import { VendorCarrierSettingsLocation } from 'api'
import { useFieldArray, useFormContext, useLocationCodes } from 'hooks'
import { distanceUnits } from 'constant'

import { Button, Container, FormItem, InputNumber } from 'designSystem'
import { FieldArrayItem, FormItemSelectSingleAsync } from 'components'

import { maxCarrierLocationsCount } from 'modules/vendors/constants/maxRecordsCount'

export const CarrierLocations: FC = () => {
  const { watch } = useFormContext()
  const { fields, append, remove } = useFieldArray({ name: 'carrierSettings.locations' })

  const locations: Pick<VendorCarrierSettingsLocation, 'locationCodeId'>[] = watch('carrierSettings.locations') || []
  const selectedLocations = locations.map(({ locationCodeId }) => locationCodeId)

  const insertField = useCallback(() => {
    append({ locationCodeId: undefined, boundary: 100 }, { shouldFocus: false })
  }, [append])

  useEffect(() => {
    // Add one field item by default
    if (!fields.length) {
      insertField()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container display="flex" fd="column" gap={8}>
      {fields.map((field, index) => (
        <FieldArrayItem key={field.id} onDelete={() => remove(index)} showDelete={fields?.length > 1}>
          <Row gutter={8}>
            <Col span={12}>
              <FormItemSelectSingleAsync
                id={field.id}
                label="Location"
                name={`carrierSettings.locations.${index}.locationCodeId`}
                placeholder="Select"
                disabledValues={selectedLocations}
                index={index}
                getItems={useLocationCodes}
              />
            </Col>
            <Col span={12}>
              <FormItem name={`carrierSettings.locations.${index}.boundary`} label="Boundary">
                <InputNumber placeholder="Boundary" precision={2} suffix={distanceUnits.Mi} suffixWidth={40} />
              </FormItem>
            </Col>
          </Row>
        </FieldArrayItem>
      ))}

      {fields.length < maxCarrierLocationsCount && (
        <Container mt={3}>
          <Button type="link" icon="plus" onClick={insertField}>
            Add
          </Button>
        </Container>
      )}
    </Container>
  )
}
