import { useQuery } from '@tanstack/react-query'

import { QueryKey } from 'enums'

import { getMySettings } from '../queryApi'

export const useGetMySettings = (enabled: boolean) => {
  const { data, ...rest } = useQuery({
    queryKey: [QueryKey.UserMeSettings],
    queryFn: getMySettings,
    enabled,
  })

  return {
    data,
    ...rest,
  }
}
