import type { ApiQueryParams, SalesOrder } from 'api'
import { Pagination } from 'types'

import { api } from '../api'

export interface GetSalesOrdersResponse extends Pagination {
  data?: SalesOrder[]
}

export type GetSalesOrdersQueryParams = ApiQueryParams

export const getSalesOrders = (params?: GetSalesOrdersQueryParams): Promise<GetSalesOrdersResponse> => {
  return api({
    method: 'get',
    url: '/api/v1/sales-orders',
    params,
  })
}
