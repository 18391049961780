import type { SalesOrder } from 'api'

import { salesOrderLineItemLabels } from './salesOrderLineItemLabels'

interface SalesOrderLabels extends Record<keyof Omit<SalesOrder, 'lineItems' | 'purchaseOrders'>, string> {
  lineItems: typeof salesOrderLineItemLabels
}

export const salesOrderLabels: SalesOrderLabels = {
  id: '',
  number: 'SO Number',
  zohoId: 'Zoho ID',
  shouldSendEmail: 'Send Email',
  customerPurchaseOrder: 'Customer PO',
  shippingAddressId: 'Ship To',
  billingAddressId: 'Bill To',
  shippingAddress: 'Ship To',
  billingAddress: 'Bill To',
  contacts: 'Contacts',
  customerId: 'Customer',
  customer: 'Customer',
  salesRepresentativeId: 'Sales Rep',
  quoteUrl: 'Quote Url',
  dispatcherId: 'Dispatcher',
  dispatcher: 'Dispatcher',
  expectedDate: 'Expected Date',
  earliestDate: 'Earliest Date',
  latestDate: 'Latest Date',
  specificDate: 'Specific Date',
  onSiteContacts: 'On Site Contact',
  generalNotes: 'General Notes',
  dispatchNotes: 'Dispatch Notes',
  procurementNotes: 'Procurement Notes',
  accountingStatus: 'Accounting Status',
  invoice: 'Invoice',
  paymentTerms: 'Payment Terms',
  depositPaid: 'Deposit Paid',
  paymentType: 'Payment Type',
  paymentStatus: 'Payment Status',
  salesRepresentative: 'Sales Rep.',
  procurementId: 'Procurement',
  procurement: 'Procurement',
  status: 'Status',
  isReceivingSeparately: 'Receiving Separately',
  isWeekendDelivery: 'Weekend',
  dayWeekendDelivery: 'Weekend',
  availableHours: 'Available Hours',
  attachments: 'Attachments',
  subtotal: 'Subtotal',
  taxes: 'Taxes',
  total: 'Total',
  lineItems: salesOrderLineItemLabels,
}
