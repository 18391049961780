import { route } from 'constant'

export const breadcrumbsData = [
  {
    title: 'References',
    url: route.references,
  },
  {
    title: 'Create Reference Number',
  },
]
