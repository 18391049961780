import { FC, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useForm } from 'hooks'
import { distanceUnits } from 'constant'

import { Checkbox, Container, Divider, EditFormItem, FormItem, Typography } from 'designSystem'
import {
  Breadcrumbs,
  Card,
  ConfirmationRequiredPopover,
  FormItemEditInput,
  FormItemEditInputNumber,
  Spinner,
  StripLoader,
} from 'components'

import { useGetTruckCodeById } from './hooks/useGetTruckCodeById'
import { useUpdateTruckCode } from './hooks/useUpdateTruckCode'
import { validationSchema } from '../utils/validationSchema'
import { getBreadcrumbsData } from './utils/getBreadcrumbsData'
import { TruckCodeForm } from '../../types/TruckCodeForm'
import { TruckCodePayload } from '../../types/truckCodePayload'
import { ActionButtons } from './elements/ActionButtons'

export const TruckCodeView: FC = () => {
  const { id } = useParams()
  const { data, isFetching } = useGetTruckCodeById(Number(id))
  const { update, isError, isPending } = useUpdateTruckCode(Number(id))

  const onSubmit = useCallback(
    (payload: TruckCodePayload) => {
      update(payload)
    },
    [update],
  )

  const { Form, triggerSubmit } = useForm<TruckCodeForm>({
    validationSchema,
    mode: 'onChange',
    onSubmit,
    isSubmitError: isError,
    defaultValues: useMemo(
      () => ({
        title: data?.title,
        code: data?.code,
        bedLengthInFt: data?.bedLengthInFt,
        allow5ftOverhang: data?.allow5ftOverhang,
      }),
      [data],
    ),
  })

  if (isFetching) {
    return <Spinner />
  }

  return (
    <>
      {!!data && (
        <>
          <Breadcrumbs breadcrumbItems={getBreadcrumbsData({ title: data.code })} actions={<ActionButtons />} />
          <Container pa={4} fd="column" gap={12}>
            <Card maxWidth={390}>
              <StripLoader isLoading={isPending} />
              <Form>
                <EditFormItem
                  name="code"
                  render={({ field }) => (
                    <FormItemEditInput
                      size="large"
                      align="left"
                      {...field}
                      onConfirm={triggerSubmit}
                      popover={<ConfirmationRequiredPopover withPopover />}
                    />
                  )}
                />
                <Divider mt={7} mb={4} />
                <Container fd="column" gap={4}>
                  <EditFormItem
                    name="title"
                    label="Title"
                    render={({ field }) => <FormItemEditInput {...field} onBlur={triggerSubmit} />}
                  />
                  <EditFormItem
                    name="bedLengthInFt"
                    label="Bed Length"
                    render={({ field }) => (
                      <FormItemEditInputNumber suffix={distanceUnits.Ft} {...field} onBlur={triggerSubmit} />
                    )}
                  />
                </Container>
                <Divider my={4} />
                <FormItem name="allow5ftOverhang">
                  <Checkbox onChange={triggerSubmit}>
                    <Typography size="s">Allow 5ft overhang</Typography>
                  </Checkbox>
                </FormItem>
              </Form>
            </Card>
          </Container>
        </>
      )}
    </>
  )
}
