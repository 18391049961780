import { useQuery } from '@tanstack/react-query'

import { EntityId } from 'api'
import { QueryKey } from 'enums'

import { getPartsByVendorId } from '../queryApi'

type UseGetPartByVendorIdProps = {
  id: EntityId
  enabled?: boolean
}

export const useGetPartsByVendorId = ({ id, enabled = true }: UseGetPartByVendorIdProps) => {
  const { data, isFetching, isLoading, fetchStatus, ...rest } = useQuery({
    queryKey: [QueryKey.GetPartsByVendorId, id],
    queryFn: () => getPartsByVendorId(id),
    enabled: !!id && enabled,
  })

  return {
    data,
    isLoading: (isLoading || isFetching) && fetchStatus !== 'idle',
    ...rest,
  }
}
