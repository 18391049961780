import { FC, useCallback } from 'react'

import { useForm } from 'hooks'

import { Button, Container, FormItem, Input, Spacer } from 'designSystem'
import { Card, PasswordRequirements } from 'components'

import { useUpdatePassword } from './hooks/useUpdatePassword'
import { validationSchema } from './utils/validationSchema'
import { ChangePasswordForm } from './types/changePasswordForm'
import { PasswordPayload } from './types/passwordPayload'

export const ChangePassword: FC = () => {
  const { Form, handleSubmit, reset, trigger, getValues } = useForm<ChangePasswordForm>({
    validationSchema,
  })

  const onError = useCallback(() => {
    reset()
  }, [reset])

  const { updatePassword, isPending } = useUpdatePassword({ onError })

  const onSubmit = useCallback(
    (data: PasswordPayload) => {
      const { oldPassword, newPassword } = data
      const passwordDataPayload = {
        oldPassword,
        newPassword,
      }
      updatePassword({ password: passwordDataPayload })
    },
    [updatePassword],
  )
  const confirmPassword = getValues('confirmPassword')

  const handleBlurNewPassword = useCallback(() => {
    if (confirmPassword) {
      trigger('confirmPassword')
    }
  }, [confirmPassword, trigger])

  return (
    <Container ma={4}>
      <Card maxWidth={500}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormItem name="oldPassword" label="Old Password">
            <Input placeholder="Type your old password" hideChars />
          </FormItem>
          <Spacer mb={3} />
          <FormItem name="newPassword" label="New Password">
            <Input placeholder="Type your new password" hideChars onBlur={handleBlurNewPassword} />
          </FormItem>
          <Spacer mb={3} />
          <FormItem name="confirmPassword" label="Confirm Password">
            <Input placeholder="Type your confirm password" hideChars />
          </FormItem>
          <Spacer mb={4} />
          <PasswordRequirements />
          <Spacer mb={7} />
          <Button type="primary" htmlType="submit" block loading={isPending}>
            Save
          </Button>
        </Form>
      </Card>
    </Container>
  )
}
