import { api, EntityId, VendorDepotSettings } from 'api'

import { DepotPayload } from './types/depotPayload'

export const patchVendorDepot = ({
  vendorId,
  data,
}: {
  vendorId: EntityId
  data: DepotPayload
}): Promise<VendorDepotSettings> => {
  return api({
    method: 'patch',
    url: `/api/v1/vendors/${vendorId}/types/depot`,
    params: {
      join: ['locationCode'],
    },
    data,
  })
}
