import { CSSProperties } from 'react'
import { styled } from 'styled-components'

interface WrapperProps {
  $isLinkAndViewOnly?: boolean
  $align?: CSSProperties['textAlign']
}

export const Wrapper = styled.div<WrapperProps>`
  min-width: 0;
  display: flex;
  justify-content: ${({ $align }) => ($align === 'left' ? 'flex-start' : 'flex-end')};
  width: 100%;
  ${({ $isLinkAndViewOnly }) => $isLinkAndViewOnly && 'pointer-events: initial'};
`

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`
