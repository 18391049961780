import { ItemCategory } from 'api'

export const renameCategory = (categories: ItemCategory[], name: string, id: number): ItemCategory[] => {
  return categories.map((item) => {
    if (item.id === id) {
      return { ...item, name: name as string }
    } else {
      return {
        ...item,
        children: item.children?.map((child) => {
          if (child.id === id) {
            return { ...child, name }
          } else {
            return child
          }
        }),
      }
    }
  })
}
