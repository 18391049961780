import { FC } from 'react'

import { useGetVendorContacts } from 'hooks'
import { countries, getVendorTypeOptionLabel } from 'constant'
import { formatDate } from 'utils'

import { Container, Divider, Typography } from 'designSystem'
import { LabelValueMarkup } from 'components/LabelValueMarkup'
import { useGetVendorById } from 'modules/vendors/routes/Vendor/hooks/useGetVendorById'

import { VendorDetailsCardContacts } from './elements/VendorDetailsCardContacts'
import { VendorDetailsCardLocations } from './elements/VendorDetailsCardLocations'

import { Card } from '../../../../Card'
import { Spinner } from '../../../../Spinner'
import { VendorIsFavoriteActionButton } from '../VendorIsFavoriteActionButton'

interface VendorDetailsCardProps {
  id: number
}

export const VendorDetailsCard: FC<VendorDetailsCardProps> = ({ id }) => {
  const { data, isLoading } = useGetVendorById({ id })
  const { data: contacts = [], isLoading: isContactsLoading } = useGetVendorContacts(id)

  if (isLoading || isContactsLoading) {
    return <Spinner />
  }

  if (!data) {
    return <Container>Vendor not found</Container>
  }

  const { companyName, type, country, city, street, zipCode, createdAt, locationCodes } = data

  const addressData = [
    {
      label: 'Country',
      value: country ? countries[country as keyof typeof countries] : '',
    },
    {
      label: 'City',
      value: city,
    },
    {
      label: 'Street',
      value: street,
    },
    {
      label: 'ZIP',
      value: zipCode,
    },
  ]

  return (
    <Card pa={0}>
      <Container pt={4} pb={7} pr={7} pl={7} fd="column">
        <Container jc="space-between" ai="center">
          <Typography size="l" fontWeight="xl" lineEllipsis={1} nowrap>
            {companyName}
          </Typography>
          <VendorIsFavoriteActionButton id={id} />
        </Container>
        {!!type.length && (
          <Typography size="xs" color="secondary">
            {type.map((vendorType) => getVendorTypeOptionLabel(vendorType)).join(', ')}
          </Typography>
        )}
        <Divider mt={3} mb={4} />
        <Container fd="column" gap={8}>
          <Typography fontWeight="xl">Billing Address</Typography>
          <Container fd="column" gap={4}>
            {addressData.map(({ label, value }) => (
              <LabelValueMarkup key={value} label={label} value={value || '-'} />
            ))}
          </Container>
        </Container>
        <Divider my={4} />
        <VendorDetailsCardLocations data={locationCodes} />
        <VendorDetailsCardContacts data={contacts} />
        <LabelValueMarkup label="Created Time" value={formatDate(createdAt, 'StandardWithHours')} />
      </Container>
    </Card>
  )
}
