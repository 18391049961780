import { useCallback, useEffect, useRef } from 'react'
import { FieldValues, UseFieldArrayRemove, UseFieldArrayReplace } from 'react-hook-form'

import { Asset, EntityId, PurchaseOrder, PurchaseOrderType } from 'api'
import { useFormContext, useWatch } from 'hooks'
import { PurchaseOrderFormAccessor } from 'types'
import { useGetPurchaseOrderOptions, useSetPurchaseOrderOptions } from 'store'

import { TableColumnsType, useTableColumns } from 'components'
import { PurchaseOrderSubLineRow } from 'modules/purchaseOrders/types/purchaseOrderSubLineRow'
import { getReferenceNumberData } from 'modules/purchaseOrders/utils/getReferenceNumberData'
import { getTableExpandableColumns } from 'modules/purchaseOrders/utils/getTableExpandableColumns'
import { prepareDefaultSubLineData } from 'modules/purchaseOrders/utils/prepareDefaultSubLineData'

import { maxSubLineCount } from 'modules/purchaseOrders/constants/maxRecordsCount'
import { redeliveryCreateModeColumns } from 'modules/purchaseOrders/constants/redeliveryColumns'
import { vendorReleaseCreateModeColumns } from 'modules/purchaseOrders/constants/vendorReleaseColumns'

interface UseGetExpandableColumnsProps {
  name: string
  poData?: PurchaseOrder
  vendorId?: EntityId
  isCreateMode?: boolean
  replace: UseFieldArrayReplace<FieldValues>
  remove: UseFieldArrayRemove
  rowsCount: number
  isAddNew?: boolean
}

export const useGetExpandableColumns = ({
  name,
  poData,
  vendorId,
  isCreateMode,
  replace,
  remove,
  rowsCount,
  isAddNew,
}: UseGetExpandableColumnsProps) => {
  const qtyFieldName = `${name}.${PurchaseOrderFormAccessor.ItemQty}`
  const rowSubLinesFieldName = `${name}.${PurchaseOrderFormAccessor.SubLines}`
  const { watch, unregister, setValue, getValues } = useFormContext()
  const locationOptionsById = useGetPurchaseOrderOptions('locations')
  const depotOptionsById = useGetPurchaseOrderOptions('depots')
  const setOptionsById = useSetPurchaseOrderOptions()

  const poType: PurchaseOrderType = useWatch({ name: PurchaseOrderFormAccessor.Type }) || poData?.type

  const previousVendorId = useRef<number | undefined>(undefined)
  const itemQty = getValues(qtyFieldName) || 0

  useEffect(() => {
    if (!!previousVendorId.current && vendorId !== previousVendorId.current) {
      const item = getValues(name)
      const newSubLines = item?.subLines?.map((subLine: Asset) => ({
        ...subLine,
        locationCodeId: null,
        depotCode: null,
      }))
      setValue(name, { ...item, subLines: newSubLines })
    }
    previousVendorId.current = vendorId
  }, [itemQty, rowSubLinesFieldName, setValue, unregister, vendorId, getValues, name])

  const handleRemove = useCallback(
    (index: number) => {
      remove(index)
      setValue(qtyFieldName, itemQty - 1)
    },
    [qtyFieldName, itemQty, setValue, remove],
  )

  const allColumns = getTableExpandableColumns({
    onDelete: handleRemove,
    rowSubLinesFieldName,
    setValue,
    locationOptionsById,
    depotOptionsById,
    setOptionsById,
    watch,
    vendorId,
    poType,
    isAddNew,
  })

  const { setVisibleColumns, columns } = useTableColumns<PurchaseOrderSubLineRow>({
    allColumns: allColumns as TableColumnsType<PurchaseOrderSubLineRow>,
    defaultColumns: vendorReleaseCreateModeColumns,
  })

  useEffect(() => {
    if (poType === PurchaseOrderType.VendorRelease) {
      setVisibleColumns(vendorReleaseCreateModeColumns)
    }
    if (poType === PurchaseOrderType.Redelivery) {
      setVisibleColumns(redeliveryCreateModeColumns)
    }
  }, [poType, setVisibleColumns])

  const shouldReplaceDataSource = rowsCount !== itemQty && itemQty <= maxSubLineCount

  useEffect(() => {
    if (shouldReplaceDataSource) {
      const itemSubLines = getValues(rowSubLinesFieldName)

      const {
        locationCodeId,
        expectedDate,
        depotId,
        vendorReleaseNumber,
        redeliveryNumber,
        vendorReleaseReferenceNumber,
        redeliveryReferenceNumber,
      } = poData || getValues()

      const referenceNumberData = getReferenceNumberData({
        vendorReleaseNumber,
        redeliveryNumber,
        vendorReleaseReferenceNumber,
        redeliveryReferenceNumber,
        shouldCreateNew: !!isAddNew,
      })

      replace(
        prepareDefaultSubLineData({
          itemSubLines,
          itemQty,
          locationCodeId,
          expectedDate,
          depotId,
          isCreateMode,
          ...referenceNumberData,
        }),
      )
    }
  }, [shouldReplaceDataSource, itemQty, replace, getValues, rowSubLinesFieldName, poData, isAddNew, isCreateMode])

  useEffect(() => {
    return () => {
      unregister(rowSubLinesFieldName)
    }
  }, [unregister, rowSubLinesFieldName])

  return { columns }
}
