import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { AssetRepair } from 'api'

import { getPatchPayload } from '../utils/getPatchPayload'
import { RepairsFormPayload } from '../types/repairsForm'

import { useDeleteRepairs } from './useDeleteRepairs'
import { usePatchRepairs } from './usePatchRepairs'
import { usePostRepairs } from './usePostRepairs'

export const useRepairsRequests = (setIsAddNew: (isAddNew: boolean) => void, data: AssetRepair[]) => {
  const { id: vendorId } = useParams()

  const {
    post,
    isSuccess: isPostSuccess,
    isError: isPostError,
    isPending: isPostLoading,
  } = usePostRepairs(Number(vendorId))

  const {
    patch,
    isSuccess: isPatchSuccess,
    isError: isPatchError,
    isPending: isPatchLoading,
  } = usePatchRepairs(Number(vendorId))

  const {
    remove,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
    isPending: isDeleteLoading,
  } = useDeleteRepairs(Number(vendorId))

  const onSubmitPost = useCallback(
    (payload: RepairsFormPayload) => {
      if (payload.item) {
        post(payload.item)
        setIsAddNew(false)
      }
    },
    [post, setIsAddNew],
  )

  const onSubmitPatch = useCallback(
    (payload: RepairsFormPayload) => {
      const patchData = getPatchPayload(payload, data)
      patchData?.forEach(({ id, payload }) => {
        patch(id as number, payload)
      })
    },
    [data, patch],
  )

  const onDelete = useCallback(
    (equipmentId?: number) => {
      if (equipmentId) {
        remove(equipmentId)
      }
    },
    [remove],
  )

  return {
    isSubmitSuccess: isPostSuccess || isPatchSuccess || isDeleteSuccess,
    isSubmitError: isPostError || isPatchError || isDeleteError,
    isEditLoading: isPostLoading || isPatchLoading || isDeleteLoading,
    onSubmitPost,
    onSubmitPatch,
    onDelete,
  }
}
