import { Dispatch, SetStateAction } from 'react'
import { FieldValues, UseFormWatch } from 'react-hook-form'

import { useItems } from 'hooks'
import { currency } from 'constant'
import { SalesOrderFormAccessor, SalesOrderLineItemsAccessor } from 'types'
import { getFormattedPriceWithCurrency, getTableTitle } from 'utils'

import { Button, EditFormItem, IconDelete, Typography } from 'designSystem'
import {
  EditableTableActions,
  FormItemEditInputNumber,
  FormItemEditSelectSingleAsync,
  TableColumnKey,
  TableColumnsType,
} from 'components'

import { LineItemModifications, LineItemSku, SalesOrderLineItemRow } from '../types'

import { getAccessorLineItemTitle } from './getAccessorLineItemTitle'
import { getLineItemSubTotal } from './getLineItemSubTotal'
import { getTableFieldName } from './getTableFieldName'

interface LineItemsColumnsProps {
  setLineItemSku: Dispatch<SetStateAction<LineItemSku>>
  lineItemSku: LineItemSku
  itemsCount: number
  onDelete: (index: number) => void
  watch: UseFormWatch<FieldValues>
  lineItemModifications: LineItemModifications
  handleAddModification: (index: string) => void
}

export const getTableColumns = ({
  setLineItemSku,
  lineItemSku,
  itemsCount,
  onDelete,
  watch,
  handleAddModification,
  lineItemModifications,
}: LineItemsColumnsProps): TableColumnsType<SalesOrderLineItemRow> => [
  {
    title: getTableTitle(TableColumnKey.Index),
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => index + 1,
  },
  {
    title: getAccessorLineItemTitle(SalesOrderLineItemsAccessor.Sku),
    key: SalesOrderLineItemsAccessor.Sku,
    dataIndex: SalesOrderLineItemsAccessor.Sku,
    width: 160,
    ellipsis: true,
    render: (title, record, index) => {
      return (
        <FormItemEditSelectSingleAsync
          name={getTableFieldName({
            fieldName: SalesOrderFormAccessor.LineItemId,
            index,
          })}
          withFullDataOption
          fullwidth
          isCreateMode={true}
          size="small"
          getItems={useItems}
          onChange={(value, option) => {
            if (option?.data) {
              setLineItemSku((prev) => ({
                ...prev,
                [record.id]: {
                  name: option?.data?.name,
                  sku: option?.data?.sku,
                },
              }))
            } else if (option === undefined) {
              setLineItemSku((prev) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { [String(record.id)]: _, ...rest } = prev
                return rest
              })
            }
          }}
        />
      )
    },
  },
  {
    title: getAccessorLineItemTitle(SalesOrderLineItemsAccessor.ItemName),
    key: SalesOrderLineItemsAccessor.ItemName,
    dataIndex: SalesOrderLineItemsAccessor.ItemName,
    ellipsis: true,
    width: 180,
    render: (value, record) => {
      const title = lineItemSku[record.id]?.name
      if (!title) return null
      return (
        <Typography lineEllipsis={1} fontWeight="l" size="xs">
          {title}
        </Typography>
      )
    },
  },
  {
    title: getAccessorLineItemTitle(SalesOrderLineItemsAccessor.Modification),
    key: SalesOrderLineItemsAccessor.Modification,
    dataIndex: SalesOrderLineItemsAccessor.Modification,
    ellipsis: true,
    render: (value, record) => {
      const showButton = !lineItemModifications[record.id]
      if (showButton) {
        return (
          <Button icon="plus" type="link" onClick={() => handleAddModification(record.id)}>
            Add Modification
          </Button>
        )
      }
    },
  },
  {
    title: getAccessorLineItemTitle(SalesOrderLineItemsAccessor.Qty),
    key: SalesOrderLineItemsAccessor.Qty,
    dataIndex: SalesOrderLineItemsAccessor.Qty,
    ellipsis: true,
    width: 100,
    render: (value, record, index) => (
      <EditFormItem
        name={getTableFieldName({
          fieldName: SalesOrderFormAccessor.LineItemQty,
          index,
        })}
        render={({ field }) => (
          <FormItemEditInputNumber {...field} size="small" align="left" placeholder="Quantity" prefix="x" />
        )}
      />
    ),
  },
  {
    title: getAccessorLineItemTitle(SalesOrderLineItemsAccessor.Price),
    key: SalesOrderLineItemsAccessor.Price,
    dataIndex: SalesOrderLineItemsAccessor.Price,
    ellipsis: true,
    width: 120,
    render: (value, record, index) => (
      <EditFormItem
        name={getTableFieldName({
          fieldName: SalesOrderFormAccessor.LineItemPrice,
          index,
        })}
        render={({ field }) => (
          <FormItemEditInputNumber
            {...field}
            size="small"
            align="left"
            placeholder="00.00"
            precision={2}
            suffix={currency}
          />
        )}
      />
    ),
  },
  {
    title: getAccessorLineItemTitle(SalesOrderLineItemsAccessor.Discount),
    key: SalesOrderLineItemsAccessor.Discount,
    dataIndex: SalesOrderLineItemsAccessor.Discount,
    ellipsis: true,
    width: 120,
    render: (value, record, index) => (
      <EditFormItem
        name={getTableFieldName({
          fieldName: SalesOrderFormAccessor.LineItemDiscount,
          index,
        })}
        render={({ field }) => (
          <FormItemEditInputNumber
            {...field}
            size="small"
            align="left"
            placeholder="00.00"
            precision={2}
            prefix="-"
            suffix={currency}
          />
        )}
      />
    ),
  },
  {
    title: getAccessorLineItemTitle(SalesOrderLineItemsAccessor.Taxes),
    key: SalesOrderLineItemsAccessor.Taxes,
    dataIndex: SalesOrderLineItemsAccessor.Taxes,
    ellipsis: true,
    width: 120,
    render: (value, record, index) => (
      <EditFormItem
        name={getTableFieldName({
          fieldName: SalesOrderFormAccessor.LineItemTaxes,
          index,
        })}
        render={({ field }) => (
          <FormItemEditInputNumber
            {...field}
            size="small"
            align="left"
            placeholder="00.00"
            precision={2}
            suffix={currency}
          />
        )}
      />
    ),
  },
  {
    title: getAccessorLineItemTitle(SalesOrderLineItemsAccessor.SubTotal),
    key: SalesOrderLineItemsAccessor.SubTotal,
    dataIndex: SalesOrderLineItemsAccessor.SubTotal,
    ellipsis: true,
    width: 120,
    render: (value, record, index) => {
      const subTotal = getLineItemSubTotal({ watch, index })
      return getFormattedPriceWithCurrency(subTotal)
    },
  },
  {
    title: getAccessorLineItemTitle(SalesOrderLineItemsAccessor.Total),
    key: SalesOrderLineItemsAccessor.Total,
    dataIndex: SalesOrderLineItemsAccessor.Total,
    ellipsis: true,
    width: 120,
    render: (value, record, index) => {
      const subTotal = getLineItemSubTotal({ watch, index })
      const currentTaxes =
        watch(`${SalesOrderFormAccessor.LineItems}.${index}.${SalesOrderFormAccessor.LineItemTaxes}`) || 0

      const total = subTotal + currentTaxes
      return (
        <Typography fontWeight="xl" size="xs">
          {getFormattedPriceWithCurrency(total)}
        </Typography>
      )
    },
  },
  {
    key: TableColumnKey.HoverActions,
    width: 30,
    render: (value, record, index) => {
      return itemsCount === 1 ? null : (
        <EditableTableActions
          name={SalesOrderFormAccessor.LineItems}
          icon={<IconDelete />}
          index={index}
          size="small"
          onDelete={() => onDelete(index)}
        />
      )
    },
  },
]
