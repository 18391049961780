import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { EntityId } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'

import { showNotification } from 'designSystem'

import { ContainerVendorPayload } from '../types/ContainerVendorPayload'

import { patchContainerVendorInfo } from '../queryApi'

interface useUpdateContainerVendorFeedProps {
  id: EntityId
  onSettled?: () => void
}

export const useUpdateContainerVendorEquipmentType = ({ id, onSettled }: useUpdateContainerVendorFeedProps) => {
  const { mutate, ...rest } = useMutation({
    mutationFn: patchContainerVendorInfo,
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKey.GetContainerVendorByVendorId, id], data)
      showNotification({ title: 'Changes saved', type: 'success' })
    },
    onSettled,
  })

  const update = useCallback(
    (payload: ContainerVendorPayload) => {
      const data = {
        typeOfEquipment: payload.typeOfEquipment,
      }
      mutate({ id, data })
    },
    [id, mutate],
  )

  return {
    update,
    ...rest,
  }
}
