import { Navigate } from 'react-router-dom'

import { itemsRoute } from 'constant'

import { NotFoundPage } from 'components'

import { Item } from '../routes/Item'
import { ItemCreate } from '../routes/ItemCreate'
import { ItemsList } from '../routes/ItemsList'

export const routes = [
  {
    path: itemsRoute.create,
    element: <ItemCreate />,
  },
  {
    path: itemsRoute.view,
    element: <Navigate to={itemsRoute.details.replace('/', '')} replace />,
  },
  {
    path: `${itemsRoute.view}/*`,
    element: <Item />,
  },
  {
    path: itemsRoute.list,
    element: <ItemsList />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
