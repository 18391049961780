import { FC, PropsWithChildren } from 'react'

import { Header } from './elements/Header'
import * as Styled from './styles'

export const AuthorizedLayout: FC<PropsWithChildren> = ({ children }) => (
  <>
    <Header />
    <Styled.Container>{children}</Styled.Container>
  </>
)
