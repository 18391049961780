import { FC } from 'react'

import { PurchaseOrder } from 'api'

import { Tabs } from 'designSystem'

import { getPurchaseOrderTabs } from './utils/getPurchaseOrderTabs'

interface PurchaseOrderTabsProps {
  data?: PurchaseOrder
}
export const PurchaseOrderTabs: FC<PurchaseOrderTabsProps> = ({ data }) => {
  if (!data) {
    return null
  }

  const tabs = getPurchaseOrderTabs(data)

  return <Tabs sticky size="small" items={tabs} isTransparentView />
}
