import type { ApiQueryParams, VendorPartsSettings } from 'api'
import { Pagination } from 'types'

import { api } from '../api'

export interface GetVendorPartsInventoryResponse extends Pagination {
  data?: VendorPartsSettings['inventory']
}

export type GetVendorPartsInventoryQueryParams = ApiQueryParams

export const getVendorPartsInventory =
  (vendorId?: number) =>
  (params?: GetVendorPartsInventoryQueryParams): Promise<GetVendorPartsInventoryResponse> => {
    return api({
      method: 'get',
      url: `/api/v1/vendors/${vendorId}/types/parts/inventory`,
      params,
    })
  }
