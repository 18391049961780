import type { SalesOrderLineItem } from 'api'

import { itemLabels } from '../item'
import { salesOrderLineItemSubLineLabels } from './salesOrderLineItemSubLineLabels'

interface SalesOrderLineItemLabels extends Record<keyof Omit<SalesOrderLineItem, 'item' | 'subLines'>, string> {
  item: typeof itemLabels
  subLines: typeof salesOrderLineItemSubLineLabels
}

export const salesOrderLineItemLabels: SalesOrderLineItemLabels = {
  id: '',
  itemId: 'SKU',
  item: itemLabels,
  title: 'Title',
  qty: 'Qty.',
  price: 'Price',
  discount: 'Discount',
  taxes: 'Taxes',
  subtotal: 'Subtotal',
  total: 'Total',
  subLines: salesOrderLineItemSubLineLabels,
}
