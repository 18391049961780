import { api, Asset, getAssetsJoinQueryParams } from 'api'

import { AssetPayload } from '../../types/assetPayload'

export type PostAssetPayload = AssetPayload

export const postAsset = (data: PostAssetPayload): Promise<Asset> => {
  return api({
    method: 'post',
    url: '/api/v1/assets',
    data,
    params: {
      join: getAssetsJoinQueryParams,
    },
  })
}
