import { PurchaseOrderStatus } from 'api'
import { PurchaseOrderFormAccessor } from 'types'

interface GetViewOnlyFieldsPODetailsProps {
  isAddNew: boolean
  isPOContainDifferentReferenceNumbers: boolean
  isLoading: boolean
  status?: PurchaseOrderStatus
}

export const getViewOnlyFieldsPODetails = ({
  isAddNew,
  isPOContainDifferentReferenceNumbers,
  isLoading,
  status,
}: GetViewOnlyFieldsPODetailsProps) => {
  if (isLoading || isAddNew) {
    return Object.values(PurchaseOrderFormAccessor)
  }
  if (status === PurchaseOrderStatus.Closed) {
    return Object.values(PurchaseOrderFormAccessor).filter((field) => field !== PurchaseOrderFormAccessor.Status)
  }

  const fields: PurchaseOrderFormAccessor[] = [
    ...(isPOContainDifferentReferenceNumbers
      ? [PurchaseOrderFormAccessor.RedeliveryNumber, PurchaseOrderFormAccessor.VendorReleaseNumber]
      : []),
    PurchaseOrderFormAccessor.LocationCodeId,
    PurchaseOrderFormAccessor.VendorName,
    PurchaseOrderFormAccessor.Type,
    PurchaseOrderFormAccessor.BillingStatus,
    PurchaseOrderFormAccessor.ReceivingStatus,
    PurchaseOrderFormAccessor.PaymentStatus,
    PurchaseOrderFormAccessor.Warehouse,
  ]

  return fields
}
