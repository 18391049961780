import { FC, forwardRef, MouseEvent, Ref, useCallback } from 'react'
import type { SwitchProps as BaseSwitchProps } from 'antd/lib/switch'

import { typedMemo } from 'types'

import * as Styled from './styles'

import { Container } from '../Container'
import { Icon } from '../Icon'
import { Typography } from '../Typography'

interface SwitchProps extends BaseSwitchProps {
  text?: string
  value?: boolean
}

export const SwitchBase: FC<SwitchProps> = forwardRef(
  ({ text, onChange, value = false, ...props }, ref: Ref<HTMLInputElement>) => {
    const handleChange = useCallback(
      (checked: boolean, e: MouseEvent<HTMLButtonElement>) => {
        onChange?.(checked, e)
      },
      [onChange],
    )
    return (
      <Container gap={8} ai="center">
        <Styled.Switch
          checked={value}
          ref={ref}
          size="small"
          onChange={handleChange}
          {...props}
          checkedChildren={<Icon icon="check" size={14} />}
          unCheckedChildren={<Icon icon="minus" size={10} />}
        />
        {text && (
          <Typography as="span" fontWeight="l" size="xs">
            {text}
          </Typography>
        )}
      </Container>
    )
  },
)

export const Switch = typedMemo(SwitchBase)
