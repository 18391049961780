import { CustomerAccountValue } from 'api/types/customer'

export const customerAccountValueLabel = {
  [CustomerAccountValue.ClassCPrivate]: 'Class C Private',
  [CustomerAccountValue.ClassBSmallBusiness]: 'Class B Small Private',
  [CustomerAccountValue.ClassALarge]: 'Class A Large Corp/Government',
}

export const customerAccountValueOptions = Object.values(CustomerAccountValue).map((accountValue) => ({
  value: accountValue,
  label: customerAccountValueLabel[accountValue],
}))
