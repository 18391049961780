import { Dispatch, FC, SetStateAction, useCallback } from 'react'

import { useFormContext, useGetVendorContacts, useWatch } from 'hooks'
import { PurchaseOrderFormAccessor } from 'types'

import { AddNewContactActionButton, NewContact } from 'components'

import { maxContactsCount } from 'modules/purchaseOrders/constants/maxRecordsCount'

interface PurchaseOrderAddContactProps {
  contacts: NewContact[]
  setContacts: Dispatch<SetStateAction<NewContact[]>>
}

export const PurchaseOrderAddContact: FC<PurchaseOrderAddContactProps> = ({ contacts, setContacts }) => {
  const { setValue } = useFormContext()

  const vendorId = useWatch({ name: PurchaseOrderFormAccessor.VendorId })
  const { data: vendorContacts = [], isLoading } = useGetVendorContacts(vendorId)

  const handleAddNewContact = useCallback(
    (contact: NewContact) => {
      setContacts((prev) => [...prev, contact])
      setValue(
        PurchaseOrderFormAccessor.ContactsIds,
        [...contacts, contact].map(({ email }) => email),
      )
    },
    [setContacts, setValue, contacts],
  )

  const contactsWithNew = [...contacts, ...vendorContacts]

  const showAdd = !isLoading && vendorId && contactsWithNew.length < maxContactsCount

  return showAdd ? <AddNewContactActionButton contacts={contacts} onSubmit={handleAddNewContact} /> : null
}
