import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { EntityId } from 'api'
import { QueryKey } from 'enums'
import { route } from 'constant'
import { useSetPurchaseOrderData } from 'store'

import { getPurchaseOrderById } from 'modules/purchaseOrders/queryApi'

export const useGetPurchaseOrderById = (id: EntityId) => {
  const setPurchaseOrderData = useSetPurchaseOrderData()
  const navigate = useNavigate()

  const { data, isSuccess, isError, ...rest } = useQuery({
    queryKey: [QueryKey.GetPurchaseOrderById, id],
    queryFn: () => getPurchaseOrderById(id),
    enabled: !!id,
  })

  useEffect(() => {
    if (isSuccess) {
      setPurchaseOrderData(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      navigate(route.pos)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  return {
    data,
    isSuccess,
    isError,
    ...rest,
  }
}
