import { assetStatusOptions } from 'constant'

import { AssetStatus } from 'api/types/asset/assetStatus'

export const getAvailableAssetStatusOptions = (currentStatus?: AssetStatus) => {
  return assetStatusOptions.filter((option) => {
    switch (currentStatus) {
      case AssetStatus.Available:
        return [
          AssetStatus.Available,
          AssetStatus.Split,
          AssetStatus.Recycle,
          AssetStatus.Booked,
          AssetStatus.PendingDelete,
          AssetStatus.PendingRedelivery,
        ].includes(option.value)
      case AssetStatus.Create:
        return [
          AssetStatus.Create,
          AssetStatus.Available,
          AssetStatus.PendingDelete,
          AssetStatus.PendingVendorRelease,
          AssetStatus.PendingRedelivery,
        ].includes(option.value)
      case AssetStatus.NotAvailable:
        return [
          AssetStatus.NotAvailable,
          AssetStatus.Available,
          AssetStatus.Split,
          AssetStatus.Recycle,
          AssetStatus.PendingDelete,
        ].includes(option.value)
      case AssetStatus.Rented:
        return [
          AssetStatus.Available,
          AssetStatus.NotAvailable,
          AssetStatus.Rented,
          AssetStatus.PendingDelete,
        ].includes(option.value)
      case AssetStatus.Sold:
        return [AssetStatus.Sold, AssetStatus.PendingDelete].includes(option.value)
      case AssetStatus.Split:
        return [AssetStatus.Split, AssetStatus.PendingDelete].includes(option.value)
      case AssetStatus.Recycle:
        return [AssetStatus.Recycle, AssetStatus.PendingDelete].includes(option.value)
      case AssetStatus.Booked:
        return [
          AssetStatus.Available,
          AssetStatus.NotAvailable,
          AssetStatus.Sold,
          AssetStatus.Rented,
          AssetStatus.VendorAccount,
          AssetStatus.PendingDelete,
        ].includes(option.value)
      case AssetStatus.PendingDelete:
        return [AssetStatus.PendingDelete].includes(option.value)
      case AssetStatus.PendingVendorRelease:
        return [AssetStatus.VendorAccount, AssetStatus.PendingRedelivery, AssetStatus.PendingDelete].includes(
          option.value,
        )
      case AssetStatus.PendingRedelivery:
        return [AssetStatus.Available, AssetStatus.PendingVendorRelease, AssetStatus.PendingDelete].includes(
          option.value,
        )
      case AssetStatus.VendorAccount:
        return [AssetStatus.Booked, AssetStatus.PendingVendorRelease, AssetStatus.PendingDelete].includes(option.value)
      default:
        return option
    }
  })
}
