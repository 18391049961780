import { CustomerStatus } from 'api/types/customer/customerStatus'

export const customerStatusLabel = {
  [CustomerStatus.Inactive]: 'Inactive',
  [CustomerStatus.Active]: 'Active',
  [CustomerStatus.Blocked]: 'Blocked',
}

export const customerStatusOptions = Object.values(CustomerStatus).map((status) => ({
  value: status,
  label: customerStatusLabel[status],
}))
