export enum ErrorCode {
  // general
  DeletingUserForbidden = 'DELETING_USER_FORBIDDEN',
  ChosenCompanyIsForbidden = 'CHOSEN_COMPANY_IS_FORBIDDEN',
  ResourceNotFound = 'RESOURCE_NOT_FOUND',
  TooManyRequests = 'TOO_MANY_REQUESTS',
  // auth
  TokenIsInvalid = 'TOKEN_IS_INVALID',
  TokenIsExpired = 'TOKEN_IS_EXPIRED',
  RefreshTokenIsRemoved = 'REFRESH_TOKEN_IS_REMOVED',
  RefreshTokenIsInvalid = 'REFRESH_TOKEN_IS_INVALID',
  CannotRefreshSession = 'CANNOT_REFRESH_SESSION',
  ResetPasswordSessionClosed = 'RESET_PASSWORD_SESSION_CLOSED',
  CannotCreateSession = 'CANNOT_CREATE_SESSION',
  PasswordIsTheSame = 'PASSWORD_IS_THE_SAME',
  // users
  UserNotFound = 'USER_NOT_FOUND',
  OldPasswordRequired = 'OLD_PASSWORD_REQUIRED',
  OldPasswordIsWrong = 'OLD_PASSWORD_IS_WRONG',
  ResendInviteEmailTooManyRequests = 'RESEND_INVITE_EMAIL_TOO_MANY_REQUESTS',
  UserAlreadyActive = 'USER_ALREADY_ACTIVE',
  UserIsInactive = 'USER_IS_INACTIVE',
  UserIsSocial = 'USER_IS_SOCIAL',
  EmailIsAlreadyExists = 'EMAIL_IS_ALREADY_EXISTS',
  // companies
  CompanyNotFound = 'COMPANY_NOT_FOUND',
  // location codes/truck codes/asset codes
  CodeIsAlreadyExists = 'CODE_IS_ALREADY_EXISTS',
  // truck codes
  TitleIsAlreadyExists = 'TITLE_IS_ALREADY_EXISTS',
  TruckCodeAlreadyExists = 'TRUCK_CODE_ID_IS_ALREADY_EXISTS',

  // assets
  AssetNumberIsAlreadyExists = 'ASSET_NUMBER_IS_ALREADY_EXISTS',
  AssetNotFound = 'ASSET_NOT_FOUND',
  // items
  SkuIsAlreadyExists = 'SKU_IS_ALREADY_EXISTS',
  ItemCategoryNotFound = 'ITEM_CATEGORY_NOT_FOUND',
  LocationCodeNotFound = 'LOCATION_CODE_NOT_FOUND',
  AssetCodeNotFound = 'ASSET_CODE_NOT_FOUND',
  // item categories
  ItemCategorySingleCannotHaveChildren = 'ITEM_CATEGORY_SINGLE_CANNOT_HAVE_CHILDREN',
  // vendors
  ResalePriceOverwriteRuleIsAlreadyExists = 'RESALE_PRICE_OVERWRITE_RULE_IS_ALREADY_EXISTS',
  VendorSkuIsAlreadyExists = 'VENDOR_SKU_IS_ALREADY_EXISTS',
  ItemSkuIsAlreadyExists = 'ITEM_SKU_IS_ALREADY_EXISTS',
  VendorSkuMappingIsAlreadyExists = 'VENDOR_SKU_MAPPING_IS_ALREADY_EXISTS',
  UqPriceListTruckCodeTypeIsAlreadyExists = 'UQ_PRICELIST_TRUCK_CODE_TYPE_IS_ALREADY_EXISTS',
  // references
  ReferenceNumberIsAlreadyExists = 'NUMBER_IS_ALREADY_EXISTS',
  BookingReferenceStatusToClose = 'REFERENCE_EDIT_FORBIDDEN_TO_CLOSE',
  ReferenceIsAlreadyExists = 'REFERENCE_IS_ALREADY_EXISTS',
  // emails
  VendorDepotRedeliveryEmailsIsEmpty = 'VENDOR_DEPOT_REDELIVERY_EMAILS_IS_EMPTY',
  // purchase orders
  PurchaseOrderContactsNotFound = 'PURCHASE_ORDER_CONTACTS_NOT_FOUND',
  PurchaseOrderItemNotFound = 'PURCHASE_ORDER_ITEM_NOT_FOUND',
  SalesOrderNotFound = 'SALES_ORDER_NOT_FOUND',
}
