import type { ReferenceNumberAsset } from 'api'

import { assetLabels } from '../asset'

interface ReferenceItemSubLineLabels extends Record<keyof Omit<ReferenceNumberAsset, 'asset'>, string> {
  asset: typeof assetLabels
}

export const referenceItemSubLineLabels: ReferenceItemSubLineLabels = {
  id: '',
  assetId: 'Asset',
  asset: assetLabels,
  status: 'Status',
  gateOutDate: 'Gate Out Date',
  isModificationRequired: 'Modification',
  isRepairRequired: 'Repair',
  receivedDate: 'Received Date',
  condition: 'Condition',
  containerColor: 'Container Color',
  pickupReference: 'Pickup',
  pickupReferenceId: 'Pickup',
  pickupDate: 'Pickup Date',
  redeliveryReference: 'Redelivery',
  redeliveryReferenceId: 'Redelivery',
}
