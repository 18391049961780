import { api, User } from 'api'
import type { UserTableSettings } from 'types'

export const getUserMe = (): Promise<User> => {
  return api.get('/api/v1/users/me', {
    params: {
      join: ['roles', 'company'],
    },
  })
}

export const getMySettings = (): Promise<UserTableSettings[]> => {
  return api({
    method: 'get',
    url: `/api/v1/table-filters`,
  })
}
