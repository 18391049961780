import { FC } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'

import { Container } from 'designSystem'

import { locationCodesRoutes } from './constants/locationCodesRoutes'

export const LocationCodes: FC = () => {
  return (
    <>
      <Outlet />
      <Container fd="column">
        <Routes>
          {locationCodesRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Container>
    </>
  )
}
