import { useCustomersCities, useCustomersStates } from 'hooks'
import { customerStatusOptions } from 'constant'

import { TableFilterRange, TableFilterSelectMulti, TableFilterSelectMultiAsync } from 'components'

export const customersFilterOptions = [
  {
    value: 'creditLimit',
    label: 'Credit Limit',
    component: <TableFilterRange key="creditLimit" name="creditLimit" />,
  },
  {
    value: 'status',
    label: 'Status',
    component: (
      <TableFilterSelectMulti key="status" name="status" placeholder="Status" options={customerStatusOptions} />
    ),
  },
  {
    value: 'billingAddresses.city',
    label: 'City',
    component: (
      <TableFilterSelectMultiAsync
        key="city"
        name="billingAddresses.city"
        placeholder="City"
        getItems={useCustomersCities}
        queryParams={{ type: { $eq: 'billing' } }}
      />
    ),
  },
  {
    value: 'billingAddresses.state',
    label: 'State',
    component: (
      <TableFilterSelectMultiAsync
        key="state"
        name="billingAddresses.state"
        placeholder="State"
        getItems={useCustomersStates}
        queryParams={{ type: { $eq: 'billing' } }}
      />
    ),
  },
]
