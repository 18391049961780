import { FC, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'antd'

import { useForm } from 'hooks'
import { route } from 'constant'
import { formatDate, toDate } from 'utils'
import { useUser } from 'store'

import { Button, Container, Divider, FormItem, Input, Spacer, Typography } from 'designSystem'
import { Breadcrumbs, Card, FormItemAddress, LabelValueMarkup } from 'components'

import { useCreateLocationCode } from './hooks/useCreateLocationCode'
import { validationSchema } from '../../utils/validationSchema'
import { breadcrumbsData } from './constants/breadcrumbsData'
import { LocationCodeForm } from '../../types/locationCodeForm'

import { CreateLocationCodePayload } from './queryApi'

export const LocationCodesCreate: FC = () => {
  const { Form, handleSubmit } = useForm<LocationCodeForm>({ validationSchema })
  const user = useUser()
  const { createLocationCode, isPending } = useCreateLocationCode(user)

  const onSubmit = useCallback(
    (data: CreateLocationCodePayload) => {
      createLocationCode(data)
    },
    [createLocationCode],
  )

  return (
    <>
      <Breadcrumbs breadcrumbItems={breadcrumbsData} />
      <Container display="flex" jc="center" fd="column" ai="center">
        <Container display="block" maxWidth={500}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Card mt={5}>
              <Typography fontWeight="xl" size="l">
                Create Location Code
              </Typography>
              <Spacer mb={5} />
              <Row gutter={8}>
                <Col span={12}>
                  <FormItem name="code" label="Location Code">
                    <Input placeholder="Type location code" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem name="name" label="Name">
                    <Input placeholder="Type name" />
                  </FormItem>
                </Col>
              </Row>
              <Divider my={4} />
              <FormItemAddress
                title={<Typography fontWeight="xl">Address</Typography>}
                pa={0}
                bordered={false}
                hideFields={['street', 'zipCode']}
              />
            </Card>
            <Container fd="column" maxWidth={500} mb={5}>
              <Card mt={3}>
                <LabelValueMarkup
                  label="Created By"
                  typographyPropsLabel={{ size: 'xs' }}
                  typographyPropsValue={{ color: 'primary', size: 'xs' }}
                  value={`${user?.firstName} ${user?.lastName}`}
                />
                <LabelValueMarkup
                  label="Created Date"
                  typographyPropsLabel={{ size: 'xs' }}
                  typographyPropsValue={{ color: 'primary', size: 'xs' }}
                  value={formatDate(toDate())}
                />
              </Card>
            </Container>
            <Container jc="space-between">
              <Link to={route.locationCodes}>
                <Button type="default">Cancel</Button>
              </Link>
              <Button type="primary" htmlType="submit" loading={isPending}>
                Create
              </Button>
            </Container>
          </Form>
        </Container>
      </Container>
    </>
  )
}
