import { referenceLabels } from 'constant'

import { DepotBookingAccessor } from '../types/depotBookingAccessor'

export const getTableTitle = (accessor: DepotBookingAccessor): string => {
  return {
    [DepotBookingAccessor.Index]: '#',
    [DepotBookingAccessor.Number]: referenceLabels.number,
    [DepotBookingAccessor.BookingType]: referenceLabels.bookingType,
    [DepotBookingAccessor.TransactionType]: referenceLabels.transactionType,
    [DepotBookingAccessor.Status]: referenceLabels.status,
    [DepotBookingAccessor.CreatedAt]: referenceLabels.createdAt,
    [DepotBookingAccessor.ExpirationDate]: referenceLabels.expirationDate,
  }[accessor]
}
