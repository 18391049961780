import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { QueryKey } from 'enums'

import { getCarrierPriceList, GetCarrierPriceListQueryParams } from '../queryApi'

export const useGetCarrierPriceList = (params: GetCarrierPriceListQueryParams) => {
  const { data, ...rest } = useQuery({
    queryKey: [QueryKey.GetVendorCarrierPriceList, params],
    queryFn: () => getCarrierPriceList(params),
    placeholderData: keepPreviousData,
    enabled: !!params?.vendorId,
  })

  const { total, data: items } = data || {}

  return {
    data: items,
    total,
    ...rest,
  }
}
