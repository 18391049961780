import { CSSProperties } from 'react'

import theme from 'styles/theme'

import { FontSize } from '../types/fontSize'

export const getStylesBySize = (size: FontSize): CSSProperties => {
  switch (size) {
    case 'xs':
      return { fontSize: theme.fontSize.xs, lineHeight: theme.lineHeight.s }
    case 'm':
      return { fontSize: theme.fontSize.m, lineHeight: theme.lineHeight.m }
    case 'l':
      return { fontSize: theme.fontSize.l, lineHeight: theme.lineHeight.m }
    case 'xl':
      return { fontSize: theme.fontSize.xl, lineHeight: theme.lineHeight.l }
    case 'xxl':
      return { fontSize: theme.fontSize.xxl, lineHeight: theme.lineHeight.xl }
    default:
      return { fontSize: theme.fontSize.s, lineHeight: theme.lineHeight.m }
  }
}
