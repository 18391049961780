import { VendorDepotSettings } from 'api'

import { Tab } from 'designSystem'

import { DepotAssets } from '../elements/DepotAssets'
import { DepotBookings } from '../elements/DepotBookings'
import { DepotGeneralInfo } from '../elements/DepotGeneralInfo'

interface GetDepotTabsProps {
  data?: VendorDepotSettings
  isLoading?: boolean
}

export const getDepotTabs = ({ data, isLoading }: GetDepotTabsProps): Tab[] => [
  {
    label: 'General Info',
    children: <DepotGeneralInfo data={data} isLoading={isLoading} />,
  },
  {
    label: 'Assets',
    children: <DepotAssets vendorDepotId={data?.id} />,
  },
  {
    label: 'Bookings',
    children: <DepotBookings vendorDepotId={data?.id} />,
  },
]
