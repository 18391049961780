import { YesNo } from 'api/types/yesNo'

export const yesNoLabel = {
  [YesNo.Yes]: 'Yes',
  [YesNo.No]: 'No',
}

export const yesNoOptions = [
  {
    label: yesNoLabel[YesNo.Yes],
    value: true,
  },
  {
    label: yesNoLabel[YesNo.No],
    value: false,
  },
]
