import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'

import { EntityId, getVendorById } from 'api'
import { QueryKey } from 'enums'
interface UseGetVendorByIdProps {
  id?: EntityId
  onError?: () => void
}

export const useGetVendorById = ({ id, onError }: UseGetVendorByIdProps) => {
  const { data, isLoading, isFetching, fetchStatus, isError, ...rest } = useQuery({
    queryKey: [QueryKey.GetVendorById, id],
    queryFn: () => getVendorById(id),
    enabled: !!id,
  })

  useEffect(() => {
    if (isError) {
      onError?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  return {
    data,
    isLoading: (isLoading || isFetching) && fetchStatus !== 'idle',
    ...rest,
  }
}
