import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { ApiHeader, PurchaseOrderItem } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'
import { useSetPurchaseOrderPayload, useSetPurchaseOrderRequestId } from 'store'

import { showNotification } from 'designSystem'

import { deletePurchaseOrderItem } from '../queryApi'

export const useDeletePurchaseOrderItem = (purchaseOrderId: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const setPurchaseOrderRequestId = useSetPurchaseOrderRequestId()
  const setPurchaseOrderPayload = useSetPurchaseOrderPayload()

  const { mutate, ...rest } = useMutation({
    mutationFn: deletePurchaseOrderItem,
    onSuccess: ({ headers }, { itemId }) => {
      setPurchaseOrderRequestId(headers[ApiHeader.RequestId])
      // Hard-codded for send email trigger action
      setPurchaseOrderPayload({ items: [{ itemId }] })
      dispatchAppEvent(AppEvent.PurchaseOrderItemDeleted)
      queryClient.setQueryData(
        [QueryKey.GetPurchaseOrderItems, purchaseOrderId],
        (prev: PurchaseOrderItem[] | undefined) => {
          return prev ? [...prev.filter((purchaseItem) => purchaseItem.id !== itemId)] : undefined
        },
      )
      showNotification({ title: 'Item deleted', type: 'success' })
    },
  })

  const removeItem = useCallback(
    (itemId: number) => {
      mutate({ itemId, purchaseOrderId })
    },
    [mutate, purchaseOrderId],
  )

  return {
    removeItem,
    ...rest,
  }
}
