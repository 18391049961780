export enum VendorType {
  ContainerVendor = 'container_supplier',
  Carrier = 'carrier',
  Depot = 'depot',
  Modifications = 'modifications',
  Parts = 'parts',
}

export const VendorTypeNames = {
  [VendorType.ContainerVendor]: 'Container Vendor',
  [VendorType.Carrier]: 'Carrier',
  [VendorType.Depot]: 'Depot',
  [VendorType.Modifications]: 'Modifications',
  [VendorType.Parts]: 'Parts',
}
