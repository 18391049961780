import { FC, useCallback } from 'react'

import { typedMemo } from 'types'

import { Button } from 'designSystem'

import { useResendInvite } from './hooks/useResendInvite'

interface ResendInviteProps {
  id: number
}

export const ResendInviteBase: FC<ResendInviteProps> = ({ id }) => {
  const { resendInvite, isPending } = useResendInvite()

  const handleClick = useCallback(() => {
    resendInvite(id)
  }, [id, resendInvite])
  return (
    <Button type="link" size="small" onClick={handleClick} loading={isPending}>
      Resend Invite
    </Button>
  )
}

export const ResendInvite = typedMemo(ResendInviteBase)
