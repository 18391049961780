import * as Yup from 'yup'

import {
  customerBillingAddressesValidation,
  customerContactsValidation,
  customerShippingAddressesValidation,
  customerUCCValidation,
  customerValidation,
} from '../../../constants/validationSchema'

export const validationSchema = Yup.object({
  ...customerValidation,
  ...customerUCCValidation,
  ...customerContactsValidation,
  ...customerBillingAddressesValidation,
  ...customerShippingAddressesValidation,
})
