import { FC, ReactNode, useEffect } from 'react'

import { useFormContext } from 'hooks'
import { getIsFormFieldViewOnly } from 'utils'

import { Button, Container } from 'designSystem'
import { ConfirmationPopover } from 'components/Popover'

import { isEditableTableAddNewIndex } from '../utils/isEditableTableAddNewIndex'
import * as Styled from './styles'

export interface EditableTableActionsProps {
  name?: string
  isAddNew?: boolean
  index: number
  onCancelNew?: () => void
  onDelete: () => void
  icon?: ReactNode
  size?: 'small' | 'middle'
}

export const EditableTableActions: FC<EditableTableActionsProps> = ({
  isAddNew = false,
  index,
  onCancelNew,
  onDelete,
  icon,
  size = 'middle',
  name,
}) => {
  const { unregister, viewOnlyFields } = useFormContext()
  const showAddActions = isEditableTableAddNewIndex(index, isAddNew)

  const isFieldViewOnly = name ? getIsFormFieldViewOnly(viewOnlyFields, name) : false

  useEffect(() => {
    if (!isAddNew) {
      unregister('item')
    }
  }, [isAddNew, unregister])

  return isFieldViewOnly ? null : (
    <Styled.Container gap={4} jc="flex-end" size={size} className={showAddActions ? 'disable-hover-effect' : ''}>
      {showAddActions ? (
        <>
          <Styled.Button size="small" type="primary" htmlType="submit" px={1} icon="check">
            Apply
          </Styled.Button>
          <Button size="small" icon="cross" onClick={onCancelNew} />
        </>
      ) : (
        <ConfirmationPopover text="Are you sure you want to delete?" onOk={onDelete}>
          {icon ? <Container>{icon}</Container> : <Styled.Icon size={16} icon="delete" onClick={() => undefined} />}
        </ConfirmationPopover>
      )}
    </Styled.Container>
  )
}
