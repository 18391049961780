import { FC, useCallback } from 'react'

import { useForm } from 'hooks'
import { contactValidationSchema } from 'utils'

import { Button, Container, Modal, ModalProps } from 'designSystem'

import { NewContact } from './types/newContact'

import { Contact as ContactData } from '../../Contacts'

interface AddContactModalProps extends Omit<ModalProps, 'onOk' | 'onCancel'> {
  onSubmit: (values: NewContact) => void
  onCancel?: () => void
  contacts?: NewContact[]
}

export const AddContactModal: FC<AddContactModalProps> = ({
  contacts = [],
  width = 440,
  title = 'Add Contact',
  open,
  footer = null,
  onSubmit,
  onCancel,
  ...modalProps
}) => {
  const { Form, handleSubmit, reset } = useForm<NewContact>({
    validationSchema: contactValidationSchema,
    context: { contacts },
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneExtension: '',
      phoneNumber: '',
    },
  })

  const onClickSubmit = useCallback(() => {
    handleSubmit(onSubmit)()
  }, [handleSubmit, onSubmit])

  return (
    <Modal
      {...modalProps}
      afterClose={reset}
      open={open}
      width={width}
      title={title}
      footer={footer}
      onCancel={onCancel}
    >
      <Form>
        <ContactData />
        <Container display="flex" jc="space-between" gap={8} mt={6}>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" fullwidth onClick={onClickSubmit}>
            Confirm
          </Button>
        </Container>
      </Form>
    </Modal>
  )
}
