import { VendorType } from 'api'
import { vendorContainerRoute } from 'constant'
import { AppModule } from 'types'

import { Tab } from 'designSystem'

import { Inventory } from 'modules/vendors/routes/Vendor/elements/Inventory'

import { ContainerVendorDetails } from '../routes/ContainerVendorDetails'

export const containerVendorTabs: Tab[] = [
  {
    key: vendorContainerRoute.details,
    label: 'General Info',
    children: <ContainerVendorDetails />,
  },
  {
    key: vendorContainerRoute.inventory,
    label: 'Inventory',
    children: <Inventory type={VendorType.ContainerVendor} module={AppModule.VendorContainerInventory} />,
  },
  // {
  //   key: vendorContainerSupplierRoute.settings,
  //   label: 'Settings',
  //   children: <div>Settings</div>,
  // },
]
