import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { containerVendorRoutes } from './constants/containerVendorRoutes'

export const ContainerVendor: FC = () => {
  return (
    <Routes>
      {containerVendorRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
    </Routes>
  )
}
