import { styled } from 'styled-components'

import { Card as BaseCard } from 'components'

export const Card = styled(BaseCard)`
  padding-left: 12px;
  max-width: 500px;
  min-height: 300px;
  width: 100%;
`

export const Header = styled.div`
  padding-left: 12px;
`

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
`
