import { api, EntityId, VendorCarrierSettingsLocation } from 'api'
import { Pagination } from 'types'

import { CarrierLocationPayload } from './types/carrierLocationsForm'

export interface GetCarrierLocationsResponse extends Pagination {
  data: VendorCarrierSettingsLocation[]
}

export const getCarrierLocations = (vendorId?: EntityId): Promise<GetCarrierLocationsResponse> => {
  return api({
    method: 'get',
    url: `/api/v1/vendors/${vendorId}/types/carrier/locations`,
    params: {
      join: ['locationCode'],
    },
  })
}

export const postCarrierLocation = ({
  vendorId,
  data,
}: {
  vendorId?: EntityId
  data?: CarrierLocationPayload
}): Promise<VendorCarrierSettingsLocation> => {
  return api({
    method: 'post',
    url: `/api/v1/vendors/${vendorId}/types/carrier/locations`,
    data,
    params: {
      join: ['locationCode'],
    },
  })
}

export const patchCarrierLocation = ({
  vendorId,
  locationId,
  data,
}: {
  vendorId?: EntityId
  locationId?: EntityId
  data?: CarrierLocationPayload
}): Promise<VendorCarrierSettingsLocation> => {
  return api({
    method: 'patch',
    url: `/api/v1/vendors/${vendorId}/types/carrier/locations/${locationId}`,
    data,
    params: {
      join: ['locationCode'],
    },
  })
}

export const deleteCarrierLocation = ({
  vendorId,
  locationId,
}: {
  vendorId?: EntityId
  locationId?: EntityId
}): Promise<{ deleted: boolean }> => {
  return api({
    method: 'delete',
    url: `/api/v1/vendors/${vendorId}/types/carrier/locations/${locationId}`,
  })
}
