import { FC, MouseEvent, useCallback } from 'react'

import { typedMemo } from 'types'
import theme from 'styles/theme'

import type { EditFormItemSize } from 'designSystem'

import * as Styled from './styles'

import { Icon } from '../Icon'

export interface ConfirmationButtonsProps {
  onConfirm: () => void
  onCancel: () => void
  size?: EditFormItemSize
  disabled?: boolean
  trigger?: 'click' | 'mouseDown'
  preventDefault?: boolean
}

export const ConfirmationButtonsBase: FC<ConfirmationButtonsProps> = ({
  onConfirm,
  onCancel,
  size,
  disabled,
  trigger = 'mouseDown',
  preventDefault = true,
}) => {
  const handleConfirm = useCallback(
    (event: MouseEvent) => {
      if (preventDefault) {
        event.preventDefault()
        event.stopPropagation()
      }

      if (!disabled) {
        onConfirm()
      }
    },
    [disabled, onConfirm, preventDefault],
  )

  const onMouseDown = useCallback(
    (event: MouseEvent) => {
      if (trigger === 'mouseDown') {
        handleConfirm(event)
      }
    },
    [trigger, handleConfirm],
  )

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (trigger === 'click') {
        handleConfirm(event)
      }
    },
    [trigger, handleConfirm],
  )

  const confirmIconColor = disabled ? theme.colors.lines : theme.colors.success[400]

  return (
    <Styled.ConfirmationContainer>
      <Styled.IconWrapper size={size} onMouseDown={onCancel}>
        <Icon icon="cross" color={theme.colors.error[400]} size={20} />
      </Styled.IconWrapper>

      <Styled.Separator />

      <Styled.IconWrapper size={size} disabled={disabled} onMouseDown={onMouseDown} onClick={handleClick}>
        <Icon icon="check" color={confirmIconColor} size={20} />
      </Styled.IconWrapper>
    </Styled.ConfirmationContainer>
  )
}

export const ConfirmationButtons = typedMemo(ConfirmationButtonsBase)
