import { FC, useCallback } from 'react'

import { useForm } from 'hooks'

import { Spacer, Typography } from 'designSystem'
import { Spinner } from 'components'

import { useDeleteCarrierLocation } from './hooks/useDeleteCarrierLocation'
import { useGetCarrierLocations } from './hooks/useGetCarrierLocations'
import { usePatchCarrierLocation } from './hooks/usePatchCarrierLocation'
import { usePostCarrierLocation } from './hooks/usePostCarrierLocation'
import { validationSchema } from './constants/validationSchema'
import { maxCarrierLocationsCount } from 'modules/vendors/constants/maxRecordsCount'
import { CarrierLocationsFormSchema } from './types/carrierLocationsForm'
import { CarrierLocationsFields } from './elements/CarrierLocationsFields'

interface CarrierLocationsForm {
  id: number
}

export const CarrierLocationsForm: FC<CarrierLocationsForm> = ({ id }) => {
  const { data, isLoading } = useGetCarrierLocations(id)
  const { patch, isPending: isLoadingPatch, isError } = usePatchCarrierLocation(id)
  const { post, isPending: isLoadingPost, isError: isErrorPost } = usePostCarrierLocation(id)
  const { remove, isError: isErrorDelete } = useDeleteCarrierLocation(id)

  const getDefaultValues = useCallback(() => {
    if (data) {
      return {
        locations: data.map(({ id, locationCode, boundary }) => ({
          id,
          locationCode: { value: locationCode.id, label: locationCode.code },
          boundary,
        })),
      }
    }
    return undefined
  }, [data])

  const { Form, watch } = useForm<CarrierLocationsFormSchema>({
    validationSchema,
    mode: 'onChange',
    isSubmitError: isError || isErrorPost || isErrorDelete,
    defaultValues: getDefaultValues(),
  })

  const locationsFormData = watch('locations') || []

  const handleSubmitPost = (index: number) => {
    post(locationsFormData[index])
  }

  const handleSubmitPatch = (index: number) => {
    patch(locationsFormData[index])
  }

  const handleDelete = (index: number) => {
    remove(locationsFormData[index]?.id as number)
  }

  return (
    <Form>
      <Typography fontWeight="xl">Locations</Typography>
      <Spacer mb={4} />
      {isLoading && <Spinner />}
      <CarrierLocationsFields
        name="locations"
        onSubmitPost={handleSubmitPost}
        onSubmitPatch={handleSubmitPatch}
        onDelete={handleDelete}
        isLoading={isLoadingPatch || isLoadingPost}
        maxFields={maxCarrierLocationsCount}
      />
    </Form>
  )
}
