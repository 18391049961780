import { css, styled } from 'styled-components'

import type { CardProps as BaseCardProps } from './Card'

interface CardProps extends BaseCardProps {
  $fullwidth: boolean
}

export const Card = styled.div<CardProps>`
  position: relative;
  width: 100%;
  display: ${({ hidden }) => hidden && 'none'};
  border: ${({ theme, bordered = true, error }) =>
    bordered && `1px solid ${error ? theme.colors.error[400] : theme.colors.lines}`};
  border-radius: ${({ theme, bordered = true }) => bordered && theme.borderRadius.small};
  background: ${({ theme }) => theme.colors.white};
  padding: ${({ theme, pa = 7 }) => theme.spacers[pa]};
  margin: ${({ ma, theme }) => ma && theme.spacers[ma]};
  margin-top: ${({ mt, theme }) => mt && theme.spacers[mt]};
  margin-right: ${({ mr, theme }) => mr && theme.spacers[mr]};
  margin-bottom: ${({ mb, theme }) => mb && theme.spacers[mb]};
  margin-left: ${({ ml, theme }) => ml && theme.spacers[ml]};
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `}

  ${({ $fullwidth }) =>
    $fullwidth &&
    css`
      border-radius: 0;
      border-left: 0;
      border-right: 0;
    `}
`

export const CardDeleteWrapper = styled.div<{ visible?: boolean }>`
  position: absolute;
  padding: ${({ theme }) => theme.spacers[2]};
  top: -12px;
  right: -12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.error[400]};
  color: ${({ theme }) => theme.colors.white};
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s;

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      z-index: 2;
    `}
`
