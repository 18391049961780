import { getVendorsJoinQueryParams, Vendor } from 'api'
import { useTable } from 'hooks'
import { AppModule } from 'types'

import { useGetVendors } from './useGetVendors'

export const useVendors = () => {
  const { pagination, onChange, getFieldSorter, sortQueryParams, searchQueryParams, ...props } = useTable<Vendor>({
    module: AppModule.Vendors,
  })

  const { data, total, isLoading, isFetching } = useGetVendors({
    limit: pagination.limit,
    page: pagination.page,
    sort: sortQueryParams,
    s: searchQueryParams,
    join: [...getVendorsJoinQueryParams, 'contacts||email'],
  })

  return {
    data: data || [],
    pagination: {
      ...pagination,
      current: pagination.page,
      pageSize: pagination.limit,
      total,
    },
    getFieldSorter,
    onTableChange: onChange,
    isLoading: isLoading || isFetching,
    offsetCount: (pagination.page - 1) * pagination.limit,
    ...props,
  }
}
