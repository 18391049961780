import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { ActivityStreamlineType } from 'api'
import { QueryKey } from 'enums'

import { ActivityStreamLineTable } from 'components'

export const ItemHistory: FC = () => {
  const { id } = useParams()

  const filters = {
    $and: [
      {
        $or: [
          {
            topLevelEntityId: { $eq: id },
          },
        ],
      },
      { topLevelType: { $eq: ActivityStreamlineType.ItemEntity } },
    ],
  }

  return <ActivityStreamLineTable queryKey={QueryKey.GetItemActivityStreamline} filters={JSON.stringify(filters)} />
}
