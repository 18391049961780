import { FC, PropsWithChildren, ReactNode } from 'react'

import { typedMemo } from 'types'

import { TagMode } from './types/tagMode'
import { TagType } from './types/tagType'
import { DefaultTag } from './elements/DefaultTag'
import { TooltipTag } from './elements/TooltipTag'

export interface TagProps {
  status: TagType
  text?: string | ReactNode
  onClick?: () => void
  icon?: ReactNode
  type?: TagMode
}

export const TagBase: FC<PropsWithChildren<TagProps>> = ({ status, text, icon, onClick, type = 'default' }) => {
  const Component: Record<TagMode, ReactNode> = {
    default: <DefaultTag onClick={onClick} icon={icon} text={text} status={status} />,
    tooltip: <TooltipTag onClick={onClick} text={text} status={status} />,
  } as const

  return Component[type]
}

export const Tag = typedMemo(TagBase)
