import { FC, useCallback } from 'react'

import { Button, Checkbox, Container, Spacer, Typography } from 'designSystem'

import type { TableFilterOption } from '../../../../types/tableFilterOption'
import * as Styled from '../../styles'

export interface TableFilterOptionsColumnProps {
  title?: string
  options?: TableFilterOption[]
  onChange?: (values: TableFilterOption['value'][]) => void
  value?: TableFilterOption['value'][]
}

export const TableFilterOptionsColumn: FC<TableFilterOptionsColumnProps> = ({ title, onChange, options, value }) => {
  const allSelected = value?.length === options?.length

  const toggleSelectAll = useCallback(() => {
    onChange?.(
      allSelected
        ? options?.filter(({ disabled, label }) => disabled || !label)?.map(({ value }) => value) || []
        : options?.map(({ value }) => value) || [],
    )
  }, [onChange, allSelected, options])

  const handleChange = useCallback(
    (optionValue: string) => (checked: boolean) => {
      if (checked) {
        onChange?.([...(value || []), optionValue])
      } else {
        onChange?.(value?.filter((v) => v !== optionValue) || [])
      }
    },
    [onChange, value],
  )

  return (
    <Container display="flex" fd="column" flex={1}>
      <Container jc="space-between" ai="center">
        {title && <Typography fontWeight="xl">{title}</Typography>}
        <Button
          onClick={toggleSelectAll}
          type="link"
          size="small"
          px={1}
          danger={allSelected}
          icon={allSelected ? 'cross' : 'allDone'}
        >
          {allSelected ? 'Unselect All' : 'Select All'}
        </Button>
      </Container>
      <Spacer mb={4} />
      <Styled.CheckboxWrapper display="flex" fd="column" gap={8}>
        {options?.map(({ value: optionValue, label, ...rest }) => {
          if (label) {
            return (
              <Checkbox
                key={optionValue}
                value={optionValue}
                checked={value?.includes(optionValue)}
                onChange={handleChange(optionValue)}
                {...rest}
              >
                {label}
              </Checkbox>
            )
          }
        })}
      </Styled.CheckboxWrapper>
    </Container>
  )
}
