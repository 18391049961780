import { AssetCodeGrade } from 'api/types/assetCode/assetCodeGrade'

export const assetCodeGradeOptionLabel = {
  [AssetCodeGrade.New]: 'New',
  [AssetCodeGrade.Used]: 'Used',
  [AssetCodeGrade.AsIs]: 'AS-IS',
  [AssetCodeGrade.Refurbished]: 'Refurbished',
}

export const assetCodeGradeOptions = Object.values(AssetCodeGrade).map((grade) => ({
  value: grade,
  label: assetCodeGradeOptionLabel[grade],
}))
