import { FC, useCallback, useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'

import { MarginSettingMeasure } from 'api'
import { useFormContext } from 'hooks/useForm'
import {
  getMarginSettingsMeasureLabel,
  getMarginSettingsOperatorLabel,
  marginSettingsMeasureOptions,
  marginSettingsOperatorOptions,
} from 'constant'
import { typedMemo } from 'types'
import { getFormattedPrice, getObjectValueByKey } from 'utils'

import { Container, EditFormItem, EditFormItemSize, Typography } from 'designSystem'

import { EditConfirmationPopover } from '../../Popover'
import { FormItemEditInputNumber } from '../FormItemEditInputNumber'
import { FormItemEditSelectSingle } from '../FormItemEditSelectSingle'

interface FormItemEditMarginSettingsProps {
  name?: string
  label?: string
  size?: EditFormItemSize
  onConfirm?: () => void
  disabled?: boolean
  popupContainer?: undefined | HTMLElement
}

export const FormItemEditMarginSettingsBase: FC<FormItemEditMarginSettingsProps> = ({
  name = 'marginSettings',
  label = 'Resale Margin',
  onConfirm,
  disabled,
  popupContainer,
  size = 'middle',
}) => {
  const {
    trigger,
    getValues,
    resetField,
    formState: { defaultValues, errors },
  } = useFormContext()

  const isError = !!getObjectValueByKey(name, errors)?.operand
  const defaults = defaultValues?.[name]
  const currentValues = getValues(name)
  const isCurrentValuesEqualsDefaults = isEqual(currentValues, defaults)

  const [fields, setFields] = useState(defaults)

  const onFieldChange = useCallback(
    (field: string, value?: string | number) => {
      setFields({ ...fields, [field]: value })
    },
    [fields],
  )

  const handleCancel = useCallback(() => {
    resetField(name)
    setFields(defaults)
  }, [resetField, name, defaults])

  const viewValue = currentValues?.operand
    ? [
        getMarginSettingsOperatorLabel(currentValues.operator),
        getFormattedPrice(currentValues.operand),
        getMarginSettingsMeasureLabel(currentValues.measure),
      ]
        .filter(Boolean)
        .join('')
    : undefined

  useEffect(() => {
    if (!isEqual(defaults, fields)) {
      setFields(defaults)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaults])

  useEffect(() => {
    if (fields?.measure === MarginSettingMeasure.Percentage) {
      trigger(`${name}.operand`)
    }
  }, [fields, trigger, name])

  return (
    <Container gap={24}>
      {label && (
        <Typography size="s" fontWeight="l" color="secondary">
          {label}
        </Typography>
      )}
      <Container fg={1} jc={label ? 'flex-end' : 'flex-start'}>
        <EditConfirmationPopover
          viewValue={viewValue}
          size={size}
          onConfirm={onConfirm}
          onCancel={handleCancel}
          disabled={disabled || isError || isCurrentValuesEqualsDefaults}
          popupContainer={popupContainer}
        >
          <Container gap={4} fd="column">
            <EditFormItem
              name={`${name}.operator`}
              label="Action"
              render={({ field }) => (
                <FormItemEditSelectSingle
                  width={50}
                  {...field}
                  onChange={(value: any) => {
                    field.onChange(value)
                    onFieldChange('operator', value)
                  }}
                  options={marginSettingsOperatorOptions}
                />
              )}
            />
            <EditFormItem
              name={`${name}.operand`}
              label="Number"
              render={({ field }) => (
                <FormItemEditInputNumber
                  precision={2}
                  {...field}
                  onChange={(value: number) => {
                    field.onChange(value)
                    onFieldChange('operand', value)
                  }}
                />
              )}
            />
            <EditFormItem
              name={`${name}.measure`}
              label="Value"
              render={({ field }) => (
                <FormItemEditSelectSingle
                  width={50}
                  {...field}
                  onChange={(value: any) => {
                    field.onChange(value)
                    onFieldChange('measure', value)
                  }}
                  options={marginSettingsMeasureOptions}
                />
              )}
            />
          </Container>
        </EditConfirmationPopover>
      </Container>
    </Container>
  )
}

export const FormItemEditMarginSettings = typedMemo(FormItemEditMarginSettingsBase)
