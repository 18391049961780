import { api, EntityId, ReferenceNumberVendorRelease } from 'api'

import { VendorReleaseNumberForm } from 'modules/references/types'

import { referenceNumberByIdJoinParams } from 'modules/references/constants/referenceNumberByIdJoinParams'

export const patchVendorReleaseNumber = ({
  id,
  data,
}: {
  id: EntityId
  data: Partial<Omit<VendorReleaseNumberForm, 'items'>>
}): Promise<{ reference: ReferenceNumberVendorRelease }> => {
  return api({
    method: 'patch',
    url: `/api/v1/references/vendor-release/${id}`,
    data,
    params: referenceNumberByIdJoinParams,
  })
}
