import * as Yup from 'yup'

import { numberBasicValidation } from 'utils'

import {
  basePurchaseOrderValidation,
  purchaseOrderStatusValidation,
} from 'modules/purchaseOrders/constants/validationSchema'

export const validationSchema = Yup.object({
  ...basePurchaseOrderValidation,
  status: purchaseOrderStatusValidation,
  vendorId: numberBasicValidation,
})
