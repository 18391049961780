import { FC, useState } from 'react'

import { useFieldArrayItemWithMultipleEndpoints } from 'hooks'

import { ConfirmationButtons, Container, EditFormItem } from 'designSystem'
import { Card, FormItemEditAddress, FormItemEditInput } from 'components'

interface EditWarehouseProps {
  index: number
  name: string
  onCancel: (index: number) => void
  onDelete: (index: number) => void
  onSubmitPost: (index: number) => void
  onSubmitPatch: (id: number) => void
  isLoading?: boolean
}

export const EditWarehouse: FC<EditWarehouseProps> = ({
  name,
  index,
  onCancel,
  onDelete,
  onSubmitPost,
  onSubmitPatch,
  isLoading,
}) => {
  const {
    id,
    value,
    isError,
    isLoading: isCurrentLoading,
    showDeleteButton,
    handleSubmitPost,
    handleSubmitPatch,
    handleDelete,
  } = useFieldArrayItemWithMultipleEndpoints({
    name,
    isLoading,
    onSubmitPost,
    onSubmitPatch,
    onDelete,
    showDelete: true,
    index,
  })
  const [isOpenedAddress, setAddressModal] = useState(false)
  const { title } = value || {}

  const isConfirmationDisabled = !title || isError || isCurrentLoading

  const handleAddressVisibilityChange = (open: boolean) => {
    setAddressModal(open)
  }

  return (
    <Card pa={4} isLoading={isCurrentLoading} showDelete={showDeleteButton} onClickDelete={handleDelete}>
      <Container gap={4} fd="column">
        <EditFormItem
          name={`${name}.${index}.title`}
          label="Name"
          render={({ field }) => {
            return <FormItemEditInput placeholder="Type warehouse name" {...field} onBlur={handleSubmitPatch} />
          }}
        />
        <FormItemEditAddress
          onConfirm={handleSubmitPatch}
          name={`${name}.${index}`}
          onOpenChange={handleAddressVisibilityChange}
        />
      </Container>
      {!id && !isOpenedAddress && (
        <ConfirmationButtons
          trigger="click"
          onConfirm={handleSubmitPost}
          onCancel={() => onCancel(index)}
          disabled={isConfirmationDisabled}
        />
      )}
    </Card>
  )
}
