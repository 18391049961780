import { FC, useCallback, useRef } from 'react'
import { Row } from 'antd'

import { ItemCategoryType } from 'api'
import { useClickOutside, useForm } from 'hooks'
import theme from 'styles/theme'

import { Button, Container, FormItem, Icon, Input, showNotification } from 'designSystem'

import { usePostCategoryItem } from '../../hooks/usePostCategoryItem'
import { validationSchema } from '../../utils/validationSchema'
import { CategoryItemForm } from '../../types/categoryItemForm'
import { CategoryPayload } from '../../types/categoryPayload'
import * as Styled from './styles'

interface CategoryFormProps {
  categoryType: ItemCategoryType | null
  setShowCategoryForm: (show: boolean) => void
  parentId?: number
}

export const CategoryForm: FC<CategoryFormProps> = ({ categoryType, setShowCategoryForm, parentId }) => {
  const { Form, handleSubmit, reset } = useForm<CategoryItemForm>({
    mode: 'onSubmit',
    validationSchema,
  })
  const placeholder = categoryType === ItemCategoryType.Folder ? 'Enter folder name' : 'Enter category name'

  const handleHideCategoryForm = useCallback(() => {
    setShowCategoryForm(false)
    reset()
  }, [reset, setShowCategoryForm])

  const onSuccess = useCallback(() => {
    showNotification({ title: 'Category created', type: 'success' })
    handleHideCategoryForm()
  }, [handleHideCategoryForm])
  const { createCategoryItem, isPending } = usePostCategoryItem({ onSuccess })

  const onSubmit = useCallback(
    (data: CategoryPayload) => {
      createCategoryItem({ ...data, ...(categoryType && { type: categoryType }), ...(parentId && { parentId }) })
    },
    [createCategoryItem, categoryType, parentId],
  )

  const rowRef = useRef<HTMLDivElement>(null)

  useClickOutside(rowRef, () => {
    handleHideCategoryForm()
  })

  return (
    <Styled.Wrapper>
      <Form style={{ width: '100%' }}>
        <Row justify="space-between" ref={rowRef} gutter={[0, 4]}>
          <Container ai="center" gap={7}>
            {categoryType === ItemCategoryType.Folder && <Icon icon="folder" color={theme.colors.secondary} />}
            <FormItem name="name">
              <Input placeholder={placeholder} autoFocus width={220} />
            </FormItem>
          </Container>
          <Container gap={4}>
            <Button onClick={handleSubmit(onSubmit)} type="primary" size="small" px={2} loading={isPending}>
              Create
            </Button>
            <Button icon="cross" size="small" onClick={handleHideCategoryForm} />
          </Container>
        </Row>
      </Form>
    </Styled.Wrapper>
  )
}
