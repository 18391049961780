import { FC, useCallback } from 'react'

import { Company } from 'api'
import { useForm } from 'hooks'

import { Container, Divider, EditFormItem, Spacer, Typography } from 'designSystem'
import { Card, FormItemEditAddress, FormItemEditInput, FormItemEditTextArea, Spinner, StripLoader } from 'components'

import { useGetMyCompany, useUpdateMyCompany } from './hooks'
import { validationSchema } from './utils/validationSchema'
import { UpdateMyCompanyForm } from './types/updateMyCompanyForm'
import { InviteBlock } from './elements/InviteBlock'
import { WarehouseForm } from './elements/WarehouseForm'
import * as Styled from './styles'

export const GeneralInformation: FC = () => {
  const { data: companyData, isFetching } = useGetMyCompany()

  const { updateMyCompany, isError, isPending } = useUpdateMyCompany()

  const onSubmit = useCallback(
    (data: Partial<Company>) => {
      updateMyCompany(data)
    },
    [updateMyCompany],
  )

  const { Form, triggerSubmit } = useForm<UpdateMyCompanyForm>({
    validationSchema,
    mode: 'onChange',
    onSubmit,
    isSubmitError: isError,
    defaultValues: companyData,
  })

  if (isFetching) {
    return <Spinner />
  }

  return (
    <Styled.Wrapper>
      <Container pa={4} gap={8}>
        <Container fd="column">
          <Form>
            <Card maxWidth={390}>
              <StripLoader isLoading={isPending} />
              <EditFormItem
                name="name"
                label="Company Name"
                render={({ field }) => (
                  <FormItemEditInput placeholder="Type company name" {...field} onBlur={triggerSubmit} />
                )}
              />
              <Spacer mb={2} />
              <EditFormItem
                name="phoneNumber"
                label="Company Phone"
                render={({ field }) => (
                  <FormItemEditInput placeholder="Type company phone" {...field} onBlur={triggerSubmit} type="phone" />
                )}
              />
              <Spacer mb={2} />
              <EditFormItem
                name="contactName"
                label="Contact Name"
                render={({ field }) => (
                  <FormItemEditInput placeholder="Type contact name" {...field} onBlur={triggerSubmit} />
                )}
              />
              <Spacer mb={2} />
              <EditFormItem
                name="email"
                label="Email"
                render={({ field }) => (
                  <FormItemEditInput placeholder="Type company email" {...field} onBlur={triggerSubmit} />
                )}
              />
              <Divider my={4} />
              <FormItemEditAddress onConfirm={triggerSubmit} />
            </Card>
          </Form>
          <Spacer mb={3} />
          <WarehouseForm />
        </Container>
        <Container fd="column">
          <Form>
            <Card maxWidth={390}>
              <Typography size="m" fontWeight="xl">
                Company Description
              </Typography>
              <Spacer mb={4} />
              <EditFormItem
                name="description"
                render={({ field }) => (
                  <FormItemEditTextArea placeholder="Type company description" {...field} onConfirm={triggerSubmit} />
                )}
              />
            </Card>
            <Spacer mb={3} />
            <InviteBlock />
          </Form>
          <Spacer mb={3} />
        </Container>
      </Container>
    </Styled.Wrapper>
  )
}
