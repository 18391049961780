import type { ApiQueryParams, EntityId, VendorModificationsSettings } from 'api'
import { api } from 'api'
import { Pagination } from 'types'

import { ModificationPayload } from './types/modificationCreateForm'

export interface GetModificationsResponse extends Pagination {
  data?: VendorModificationsSettings[]
}

export type GetModificationsQueryParams = ApiQueryParams & {
  vendorId: EntityId
}

export const GetModifications = ({
  vendorId,
  ...params
}: GetModificationsQueryParams): Promise<GetModificationsResponse> => {
  return api({
    method: 'get',
    url: `/api/v1/vendors/${vendorId}/modifications`,
    params: {
      ...params,
    },
  })
}

export const postModification = ({
  vendorId,
  data,
}: {
  vendorId?: EntityId
  data?: ModificationPayload
}): Promise<VendorModificationsSettings> => {
  return api({
    method: 'post',
    url: `/api/v1/vendors/${vendorId}/modifications`,
    data,
    params: {
      join: ['truckCode'],
    },
  })
}

export const patchModification = ({
  vendorId,
  modificationId,
  data,
}: {
  vendorId?: EntityId
  modificationId?: EntityId
  data?: ModificationPayload
}): Promise<VendorModificationsSettings> => {
  return api({
    method: 'patch',
    url: `/api/v1/vendors/${vendorId}/modifications/${modificationId}`,
    data,
    params: {
      join: ['truckCode'],
    },
  })
}

export const deleteModification = ({
  vendorId,
  modificationId,
}: {
  vendorId?: EntityId
  modificationId?: EntityId
}): Promise<void> => {
  return api({
    method: 'delete',
    url: `/api/v1/vendors/${vendorId}/modifications/${modificationId}`,
  })
}
