import { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from 'designSystem'
import { ConfirmationPopover } from 'components'

import { useRemoveItem } from '../../routes/ItemDetails/hooks/useRemoveItem'

export const ActionButtons: FC = () => {
  const { id } = useParams()
  const { remove, isPending } = useRemoveItem(Number(id))

  const handleRemoveItem = useCallback(() => {
    remove()
  }, [remove])

  return (
    <ConfirmationPopover text="Are you sure you want to remove item?" onOk={handleRemoveItem} isLoading={isPending}>
      <Button icon="delete" danger size="small" />
    </ConfirmationPopover>
  )
}
