import { PurchaseOrderItemsSubLine, PurchaseOrderReferenceType } from 'api'
import type { Dayjs } from 'utils'
import { generateUniqueId } from 'utils'

import { PurchaseOrderSubLineRow } from '../types'

interface PrepareDefaultSubLineData {
  itemQty: number
  locationCodeId?: number | null
  expectedDate?: Dayjs
  depotId?: number
  itemSubLines?: PurchaseOrderItemsSubLine[]
  isCreateMode?: boolean
  vendorReleaseRefId?: number
  redeliveryRefId?: number
  vendorReleaseNumber?: string
  redeliveryNumber?: string
  referenceType: PurchaseOrderReferenceType | null
}

export const prepareDefaultSubLineData = ({
  itemQty,
  locationCodeId,
  expectedDate,
  depotId,
  itemSubLines,
  isCreateMode,
  vendorReleaseNumber,
  redeliveryNumber,
  vendorReleaseRefId,
  redeliveryRefId,
  referenceType,
}: PrepareDefaultSubLineData) => {
  const data = []
  const assetData = {
    vendorReleaseNumber,
    redeliveryNumber,
  }
  const additionalFields = isCreateMode
    ? assetData
    : {
        asset: assetData,
      }

  const currentItemQty = itemQty || 0

  for (let i = 0; i < currentItemQty; i++) {
    if (!!itemSubLines?.length && itemSubLines[i]) {
      data.push(itemSubLines[i])
    } else {
      data.push({
        id: generateUniqueId(),
        key: i,
        redeliveryRefId,
        vendorReleaseRefId,
        locationCodeId: locationCodeId || null,
        expectedDeliveryDate: expectedDate,
        vendorDepotId: depotId || null,
        referenceType,
        ...additionalFields,
      } satisfies PurchaseOrderSubLineRow)
    }
  }
  return data
}
