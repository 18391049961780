import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { ApiHeader, EntityId, PurchaseOrderItem } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'
import { useSetPurchaseOrderPayload, useSetPurchaseOrderRequestId } from 'store'

import { showNotification } from 'designSystem'

import { resetAssetNumber } from '../queryApi'

interface UseResetPOAssetNumberProps {
  purchaseOrderId: EntityId
  itemId?: EntityId
  subLineId: EntityId
}

export const useResetPOAssetNumber = ({ purchaseOrderId, itemId, subLineId }: UseResetPOAssetNumberProps) => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const setPurchaseOrderRequestId = useSetPurchaseOrderRequestId()
  const setPurchaseOrderPayload = useSetPurchaseOrderPayload()

  const { mutate, ...rest } = useMutation({
    mutationFn: resetAssetNumber,
    onSuccess: ({ headers, data }, payload) => {
      setPurchaseOrderRequestId(headers[ApiHeader.RequestId])
      // Hard-codded for send email trigger action
      setPurchaseOrderPayload({ items: [{ itemId }] })
      dispatchAppEvent(AppEvent.PurchaseOrderPatchAssetNumber)
      queryClient.setQueryData(
        [QueryKey.GetPurchaseOrderItems, payload.purchaseOrderId],
        (items: PurchaseOrderItem[] | undefined) => {
          const updatedItems = items?.map((item) => {
            if (item.id === payload.itemId) {
              return {
                ...item,
                subLines: item.subLines?.map((subLine) => {
                  if (subLine.id === payload.subLineId) {
                    return {
                      ...subLine,
                      ...(subLine.asset && {
                        asset: {
                          ...subLine.asset,
                          ...data.asset,
                        },
                      }),
                    }
                  }
                  return subLine
                }),
              }
            }
            return item
          })
          return updatedItems
        },
      )

      showNotification({ title: 'Asset Number updated', type: 'success' })
    },
  })

  const patchPOAssetNumber = useCallback(() => {
    mutate({ purchaseOrderId, itemId, subLineId })
  }, [mutate, purchaseOrderId, itemId, subLineId])

  return {
    patchPOAssetNumber,
    ...rest,
  }
}
