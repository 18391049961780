import { useCallback } from 'react'

import { useForm } from 'hooks'
import { useUser } from 'store'

import { Container, Divider, EditFormItem } from 'designSystem'
import { Avatar, Card, FormItemEditInput, StripLoader } from 'components'

import { useUpdateMe } from './hooks/useUpdateMe'
import { validationSchema } from './utils/validationSchema'
import { UpdateMeForm } from './types/updateMeForm'

import { UpdateMePayload } from './queryApi'

export const MainInfo = () => {
  const currentUser = useUser()
  const { updateMe, isError, isPending } = useUpdateMe()

  const onSubmit = useCallback(
    (data: UpdateMePayload) => {
      updateMe(data)
    },
    [updateMe],
  )

  const { Form, triggerSubmit } = useForm<UpdateMeForm>({
    validationSchema,
    mode: 'onChange',
    onSubmit,
    isSubmitError: isError,
    defaultValues: currentUser,
  })

  return (
    <>
      {currentUser && (
        <Container pa={4}>
          <Card maxWidth={390}>
            <StripLoader isLoading={isPending} />
            <Avatar user={currentUser} size={56} /> <Divider my={4} />
            <Form>
              <Container fd="column" gap={4}>
                <EditFormItem
                  name="firstName"
                  label="First Name"
                  render={({ field }) => (
                    <FormItemEditInput placeholder="Type First Name" {...field} onBlur={triggerSubmit} />
                  )}
                />
                <EditFormItem
                  name="lastName"
                  label="Last Name"
                  render={({ field }) => (
                    <FormItemEditInput placeholder="Type Last Name" {...field} onBlur={triggerSubmit} />
                  )}
                />
                <EditFormItem
                  name="email"
                  label="Email"
                  render={({ field }) => (
                    <FormItemEditInput placeholder="Type email" {...field} onBlur={triggerSubmit} />
                  )}
                />
                <EditFormItem
                  name="phoneNumber"
                  label="Phone Number"
                  render={({ field }) => (
                    <FormItemEditInput placeholder="Type phone number" {...field} onBlur={triggerSubmit} type="phone" />
                  )}
                />
                <EditFormItem
                  name="phoneExtension"
                  label="Extension"
                  render={({ field }) => (
                    <FormItemEditInput placeholder="Type extension" {...field} onBlur={triggerSubmit} />
                  )}
                />
              </Container>
            </Form>
          </Card>
        </Container>
      )}
    </>
  )
}
