import { Col, Row } from 'antd'

import { salesOrderLabels } from 'constant'
import { SalesOrderFormAccessor } from 'types'

import { Container, FormItem, TextArea } from 'designSystem'

export const NotesInformation = () => {
  return (
    <Container fd="column" width="100%" gap={8}>
      <Row gutter={8}>
        <Col span={24}>
          <FormItem name={SalesOrderFormAccessor.GeneralNotes} label={salesOrderLabels.generalNotes}>
            <TextArea size="small" placeholder="Type notes" autoSize={{ minRows: 4 }} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={24}>
          <FormItem name={SalesOrderFormAccessor.DispatchNotes} label={salesOrderLabels.dispatchNotes}>
            <TextArea size="small" placeholder="Type notes" autoSize={{ minRows: 4 }} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={24}>
          <FormItem name={SalesOrderFormAccessor.ProcurementNotes} label={salesOrderLabels.procurementNotes}>
            <TextArea size="small" placeholder="Type notes" autoSize={{ minRows: 4 }} />
          </FormItem>
        </Col>
      </Row>
    </Container>
  )
}
