import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { partRoutes } from './constants/partRoutes'

export const Parts: FC = () => {
  return (
    <Routes>
      {partRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
    </Routes>
  )
}
