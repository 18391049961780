import { FC, ReactElement, useCallback } from 'react'
import { Tooltip } from 'antd'
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form'

import { useFormContext } from 'hooks/useForm'
import { typedMemo } from 'types'
import { getIsFormFieldViewOnly, getObjectValueByKey } from 'utils'
import theme from 'styles/theme'

import { type EditFormItemSize } from 'designSystem'

import * as Styled from './styles'

import { Container } from '../Container'
import { Icon } from '../Icon'
import { Typography } from '../Typography'

export interface EditFormItemRenderProps {
  field: ControllerRenderProps<FieldValues, string> & { isFieldViewOnly: boolean }
  fieldState: ControllerFieldState
  formState: UseFormStateReturn<FieldValues>
}

export interface EditFormItemProps {
  name: string
  nestedName?: string
  label?: string
  layout?: 'vertical' | 'horizontal'
  defaultValue?: string
  size?: EditFormItemSize
  render: (props: EditFormItemRenderProps) => ReactElement
  isViewOnly?: boolean
}

export const EditFormItemBase: FC<EditFormItemProps> = ({
  name,
  nestedName,
  render,
  label,
  layout = 'horizontal',
  size,
  isViewOnly,
}) => {
  const {
    control,
    formState: { errors },
    viewOnlyFields,
  } = useFormContext()
  const error = getObjectValueByKey(nestedName ? `${name}.${nestedName}` : name, errors)?.message

  const renderError = useCallback(
    () => (
      <Tooltip className="form-item__error" title={<>{error}</>} trigger="hover" placement="top">
        <Icon icon="errorFilled" size={15} color={theme.colors.error[400]} />
      </Tooltip>
    ),
    [error],
  )

  const isFieldViewOnly = isViewOnly || getIsFormFieldViewOnly(viewOnlyFields, name)

  const renderComponent = useCallback(
    ({
      field,
      fieldState,
      formState,
    }: {
      field: ControllerRenderProps<FieldValues, string>
      fieldState: ControllerFieldState
      formState: UseFormStateReturn<FieldValues>
    }) => {
      return render({
        field: {
          ...field,
          isFieldViewOnly,
        },
        fieldState,
        formState,
      })
    },
    [render, isFieldViewOnly],
  )

  return (
    <Styled.FormItemWrapper layout={layout} $isFieldViewOnly={isFieldViewOnly}>
      {label && (
        <Styled.LabelWrapper layout={layout}>
          <Styled.Label size={size}>
            <Container>
              <Typography
                nowrap
                as="label"
                size={size === 'small' ? 'xs' : 's'}
                fontWeight="l"
                color={error ? 'error' : 'secondary'}
              >
                {label}
              </Typography>
            </Container>
          </Styled.Label>
          {!!error && layout === 'vertical' && renderError()}
        </Styled.LabelWrapper>
      )}
      <Styled.Container layout={layout} noLabel={!label} className="form-item">
        {!!error && layout === 'horizontal' && renderError()}
        <Controller control={control} name={name} render={renderComponent} />
      </Styled.Container>
    </Styled.FormItemWrapper>
  )
}

export const EditFormItem = typedMemo(EditFormItemBase)
