import { FC } from 'react'

import { useLocationCodes, useTruckCodes } from 'hooks'
import { currency } from 'constant'

import { Container, FormItem, InputNumber, Typography } from 'designSystem'
import { FormItemGoogleAutocomplete, FormItemSelectSingleAsync } from 'components'

import { CityStateView } from '../../../CityStateView'

export const OptionOne: FC = () => {
  return (
    <Container fd="column" gap={16}>
      <Container gap={8} ai="center" fw="wrap">
        <FormItemSelectSingleAsync
          name="option.truckCodeId"
          getItems={useTruckCodes}
          width={160}
          placeholder="Truck Code"
        />
        <Typography size="s" color="secondary">
          Flat Fee
        </Typography>
        <FormItem name="option.flatFeePrice">
          <InputNumber placeholder="Price" precision={2} prefix={currency} width={160} />
        </FormItem>
        <Typography size="s" color="secondary">
          from
        </Typography>
        <FormItemSelectSingleAsync
          name="option.locationCodeId"
          getItems={useLocationCodes}
          width={160}
          placeholder="Location"
        />
      </Container>
      <Container gap={8} ai="center" fw="wrap">
        <Typography size="s" color="secondary">
          to
        </Typography>
        <FormItem name="option.zipCode">
          <FormItemGoogleAutocomplete
            name="option.zipCode"
            width={240}
            size="middle"
            placeholder="ZIP"
            types={['postal_code']}
            fieldsNames={{ zipCode: 'option.zipCode', city: 'option.city', state: 'option.state' }}
          />
        </FormItem>
        <CityStateView name="option" size="s" color="secondary" />
      </Container>
    </Container>
  )
}
