import { FC, forwardRef, Ref } from 'react'

import { typedMemo } from 'types'

import type { InputNumberProps } from 'designSystem'

import * as Styled from './styles'

export type EditInputNumberProps = InputNumberProps

export const EditInputNumberBase: FC<EditInputNumberProps> = forwardRef(
  ({ align = 'right', ...props }, ref: Ref<HTMLInputElement>) => {
    return <Styled.InputNumber align={align} {...props} ref={ref} />
  },
)

export const EditInputNumber = typedMemo(EditInputNumberBase)
