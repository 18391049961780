import { PublicImage } from 'types'

import { envVariables } from './envVariables'

export const publicImageUrl = Object.keys(PublicImage).reduce(
  (acc, key) => {
    return {
      ...acc,
      [key]: `${envVariables.imagesUrl}${PublicImage[key as keyof typeof PublicImage]}`,
    }
  },
  {} as Record<keyof typeof PublicImage, string>,
)
