import { FC } from 'react'

import { ActivityStreamlineType } from 'api'
import { QueryKey } from 'enums'

import { ActivityStreamLineTable } from 'components'

interface PurchaseOrderHistoryProps {
  id: number
}

export const PurchaseOrderHistory: FC<PurchaseOrderHistoryProps> = ({ id }) => {
  const filters = {
    $and: [
      {
        $or: [
          {
            topLevelEntityId: { $eq: id },
          },
        ],
      },
      { topLevelType: { $eq: ActivityStreamlineType.PurchaseOrderEntity } },
    ],
  }

  return <ActivityStreamLineTable queryKey={QueryKey.GetPOActivityStreamline} filters={JSON.stringify(filters)} />
}
