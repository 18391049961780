import { FC } from 'react'

import { ReferenceNumber, ReferenceNumberType } from 'api'

import { Container, Typography } from 'designSystem'

import { PickupActions } from './elements/PickupActions'
import { VendorReleaseActions } from './elements/VendorReleaseActions'

export interface ReferenceNumberTitleProps {
  data: ReferenceNumber
  canEdit: boolean
}

export const ReferenceNumberTitle: FC<ReferenceNumberTitleProps> = ({ data, canEdit }) => {
  const { number, type } = data

  const actions = {
    [ReferenceNumberType.Booking]: null,
    [ReferenceNumberType.Redelivery]: null,
    [ReferenceNumberType.VendorRelease]: <VendorReleaseActions data={data} canEdit={canEdit} />,
    [ReferenceNumberType.Pickup]: <PickupActions data={data} canEdit={canEdit} />,
  }[type]

  return (
    <Container jc="space-between" ai="center" gap={8} fg={1} minHeight={32}>
      <Typography fontWeight="xl" size="l">
        {number}
      </Typography>

      {actions}
    </Container>
  )
}
