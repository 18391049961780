import { PurchaseOrder, PurchaseOrderBillingStatus, PurchaseOrderReceivingStatus } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'

import { SubLineCounts } from './getSubLineCounts'

type UpdatePurchaseOrderStatusesProps = SubLineCounts & {
  id: number
}

const getBillingStatusBySubLines = (count: number, totalSubLines: number): PurchaseOrderBillingStatus => {
  switch (count) {
    case 0:
      return PurchaseOrderBillingStatus.NotBilled
    case totalSubLines:
      return PurchaseOrderBillingStatus.Billed
    default:
      return PurchaseOrderBillingStatus.PartiallyBilled
  }
}

const getReceivingStatusStatusBySubLines = (count: number, totalSubLines: number): PurchaseOrderReceivingStatus => {
  switch (count) {
    case 0:
      return PurchaseOrderReceivingStatus.NotReceived
    case totalSubLines:
      return PurchaseOrderReceivingStatus.Received
    default:
      return PurchaseOrderReceivingStatus.PartiallyReceived
  }
}

export const updatePurchaseOrderStatuses = ({
  totalBilledSubLines,
  totalReceivedSubLines,
  totalSubLines,
  id,
}: UpdatePurchaseOrderStatusesProps) => {
  const billingStatus = getBillingStatusBySubLines(totalBilledSubLines, totalSubLines)
  const receivingStatus = getReceivingStatusStatusBySubLines(totalReceivedSubLines, totalSubLines)
  queryClient.setQueryData([QueryKey.GetPurchaseOrderById, id], (prev: PurchaseOrder | undefined) => {
    if (!prev) return undefined
    const hasStatusChanged = prev.billingStatus !== billingStatus || prev.receivingStatus !== receivingStatus

    if (hasStatusChanged) {
      return {
        ...prev,
        billingStatus,
        receivingStatus,
      }
    }
  })
}
