import { Navigate } from 'react-router-dom'

import { vendorCarrierRoute, vendorContainerRoute } from 'constant'

import { LinkTabs, NotFoundPage } from 'components'

import { containerVendorTabs } from './containerVendorTabs'

export const containerVendorRoutes = [
  {
    path: '/',
    element: <Navigate to={vendorCarrierRoute.details.replace('/', '')} replace />,
  },
  {
    path: vendorContainerRoute.details,
    element: <LinkTabs items={containerVendorTabs} />,
  },
  {
    path: vendorContainerRoute.inventory,
    element: <LinkTabs items={containerVendorTabs} />,
  },
  {
    path: vendorContainerRoute.settings,
    element: <LinkTabs items={containerVendorTabs} />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
