import { cloneElement, FC, ReactElement, ReactNode, useCallback, useState } from 'react'

import { useFieldArray, useFormContext } from 'hooks'
import { AsyncSelectGetItemsProps, QueryParams, typedMemo } from 'types'
import { getIsFormFieldViewOnly } from 'utils'

import { Button, Container, SelectSingleOption } from 'designSystem'

import { FieldArrayItem } from '../FieldArrayItem'
import { FormItemEditSelectSingleAsync } from '../FormItemEditSelectSingleAsync'

interface FormItemEditSelectSingleAsyncGroupProps {
  name: string
  label: string
  onSubmit?: () => void
  getItems: (props: AsyncSelectGetItemsProps) => any
  placeholder?: string
  maxFieldsCount?: number
  isRequired?: boolean
  disabledValues?: SelectSingleOption['value'][]
  additionalItemContent?: ReactNode[]
  popover?: ReactElement
  queryParams?: QueryParams
}

export const FormItemEditSelectSingleAsyncGroupBase: FC<FormItemEditSelectSingleAsyncGroupProps> = ({
  name,
  onSubmit,
  getItems,
  label,
  maxFieldsCount,
  isRequired,
  disabledValues,
  additionalItemContent,
  queryParams,
  popover,
  ...props
}) => {
  const {
    watch,
    formState: { errors },
    viewOnlyFields,
  } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name,
  })

  const currentError = errors[name]

  const isFieldViewOnly = getIsFormFieldViewOnly(viewOnlyFields, name)

  const values = watch(name) as SelectSingleOption[]
  const isFieldsExist = !!values?.length
  const showAddButton =
    !isFieldViewOnly &&
    !values?.some((v) => v?.value === undefined) &&
    (maxFieldsCount === undefined || fields.length < maxFieldsCount)

  const handleSubmit = useCallback(() => {
    const emptyValueIndexes: number[] = []

    values?.forEach((option, index) => {
      const isErrorExist = Array.isArray(currentError) && currentError?.[index]
      if (!option?.value && !isErrorExist) {
        emptyValueIndexes.push(index)
      }
    })
    remove(emptyValueIndexes)
    onSubmit?.()
  }, [onSubmit, values, remove, currentError])

  const selectedValues = values?.map((v) => v?.value) || []
  const allDisabledValues = [...selectedValues, ...(disabledValues || [])]

  const addNewItem = () => append(undefined)

  const isShowDelete = (isRequired ? values?.filter(Boolean).length > 1 : true) && !isFieldViewOnly
  const showAddFirstItem = !isFieldsExist && !isFieldViewOnly

  const [deleteItemIndex, setDeleteItemIndex] = useState<number | undefined>()

  const handleClickDelete = useCallback(
    (index: number, value?: number | string) => () => {
      if (popover && value !== undefined) {
        setDeleteItemIndex(index)
      } else {
        remove(index)
        handleSubmit()
      }
    },
    [remove, popover, handleSubmit],
  )

  const handleClosePopover = useCallback(() => {
    setDeleteItemIndex(undefined)
  }, [])

  const handleOpenChange = useCallback(
    (visible: boolean) => {
      if (!visible) {
        handleClosePopover()
      }
    },
    [handleClosePopover],
  )

  const onPopoverConfirm = useCallback(() => {
    if (typeof deleteItemIndex === 'number') {
      remove(deleteItemIndex)
      handleSubmit()
    }
    setDeleteItemIndex(undefined)
  }, [deleteItemIndex, remove, handleSubmit])

  return (
    <Container display="flex" fd="column" jc="flex-start" ai="flex-start" gap={8}>
      {showAddFirstItem && (
        <Button type="link" icon="stack" onClick={addNewItem}>
          Add first item
        </Button>
      )}
      {isFieldsExist && (
        <>
          {fields.map((field, index) => {
            const currentValue = values?.[index]?.value
            return (
              <FieldArrayItem
                key={field.id}
                onDelete={handleClickDelete(index, currentValue)}
                showDelete={isShowDelete}
              >
                <Container fd="column">
                  <FormItemEditSelectSingleAsync
                    {...props}
                    required
                    value={values?.[index]?.value}
                    id={field?.id}
                    name={`${name}.${index}`}
                    label={`${label} ${index + 1}`}
                    disabledValues={allDisabledValues}
                    defaultOption={values?.[index]}
                    index={index}
                    onSubmit={handleSubmit}
                    getItems={getItems}
                    queryParams={queryParams}
                    popover={currentValue ? popover : undefined}
                  />
                  {!!additionalItemContent?.length && additionalItemContent[index]}
                  {popover &&
                    cloneElement(popover, {
                      ...popover.props,
                      open: deleteItemIndex === index || popover.props.open,
                      onCancel: handleClosePopover,
                      onOk: onPopoverConfirm,
                      onOpenChange: handleOpenChange,
                      placement: 'bottomRight',
                    })}
                </Container>
              </FieldArrayItem>
            )
          })}

          {showAddButton && (
            <Button type="link" icon="plus" onClick={addNewItem}>
              Add
            </Button>
          )}
        </>
      )}
    </Container>
  )
}

export const FormItemEditSelectSingleAsyncGroup = typedMemo(FormItemEditSelectSingleAsyncGroupBase)
