import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { Container, Spacer } from 'designSystem'
import { Card, Spinner } from 'components'

import { useGetCarrierByVendorId } from './hooks/useGetCarrierByVendorId'
import { CarrierDetailsForm } from './elements/CarrierDetailsForm'
import { CarrierLocationsForm } from './elements/CarrierLocationsForm'

export const CarrierDetails: FC = () => {
  const { id } = useParams()

  const { data, isFetching } = useGetCarrierByVendorId(Number(id))

  if (isFetching) {
    return <Spinner />
  }

  if (!data) {
    return null
  }

  return (
    <Container display="flex" fd="column" ma={4}>
      <Card maxWidth={390}>
        <CarrierDetailsForm id={Number(id)} data={data} />
        <Spacer mb={7} />
        <CarrierLocationsForm id={Number(id)} />
      </Card>
    </Container>
  )
}
