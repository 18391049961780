import { VendorCarrierSettingsEquipment } from 'api'

import { EquipmentFormPayload } from '../types/equipmentCreateForm'

export const getPatchPayload = (payload: EquipmentFormPayload, defaultData: VendorCarrierSettingsEquipment[]) => {
  return payload.list?.map((equipment) => {
    const { id, title, truckCodeId, phoneNumber, email, qty } = equipment
    const prev = defaultData.find((item) => item.id === id)

    const payload = {
      ...(prev?.title !== title && { title }),
      ...(prev?.truckCodeId !== truckCodeId && { truckCodeId }),
      ...(prev?.phoneNumber !== phoneNumber && { phoneNumber }),
      ...(prev?.email !== email && { email }),
      ...(prev?.qty !== qty && { qty }),
    }

    return {
      id,
      payload,
    }
  })
}
