import { Drawer as BaseDrawer } from 'antd'
import { styled } from 'styled-components'

import { Button as BaseButton, Container as BaseContainer } from 'designSystem'

export const Drawer = styled(BaseDrawer)`
  .ant-drawer-header {
    border-bottom: none;
  }

  .ant-drawer-body {
    padding-top: 0;
  }
`

export const Container = styled(BaseContainer)`
  min-height: calc(100% - 64px);
`

export const Counter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background: ${({ theme }) => theme.colors.accent[400]};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
`

export const Button = styled(BaseButton)`
  &&& .ant-btn-icon {
    margin-inline-end: ${({ theme }) => theme.spacers[2]};
  }
`
