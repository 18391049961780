import { PropsWithChildren, useCallback, useState } from 'react'

import { ReferenceNumberItem, ReferenceNumberType } from 'api'
import { useFieldArray, useFormContext, useMemoCompare, useWatch } from 'hooks'
import { ReferenceFormAccessor, typedMemo } from 'types'

import { Button, Container, SpacerLevel, Typography } from 'designSystem'

import { getVendorReleaseItemsQty } from '../../utils/getVendorReleaseItemsQty'
import { getDefaultAssetsIds } from './utils/getDefaultAssetsIds'
import { maxItems } from 'modules/references/constants/maxRecordsCount'
import { ReferenceItemsAssetsIds } from './types/referenceItemsAssetsIds'
import { ReferenceItem } from './elements/ReferenceItem'

import { ReferenceItemsContext, ReferenceItemsContextData } from './contexts/referenceItemsContext'

export interface ReferenceItemsProps extends ReferenceItemsContextData {
  type: ReferenceNumberType
  minCount?: number
  pa?: SpacerLevel
  isFormViewOnly?: boolean
  onDeleteItem?: (index: number) => void
  vendorReleaseItems?: Record<string, number>
}

const ReferenceItemsBase = ({
  referenceData,
  data,
  type,
  minCount = 1,
  pa,
  isFieldViewOnly,
  canChangeQty = true,
  isFormViewOnly,
  onDeleteItem,
  vendorReleaseItems,
  children,
}: PropsWithChildren<ReferenceItemsProps>) => {
  const memoizedData = useMemoCompare(data)

  const { getValues } = useFormContext()
  const { fields, append, remove } = useFieldArray({ name: ReferenceFormAccessor.Items })
  const items = useWatch({ name: ReferenceFormAccessor.Items }) as ReferenceNumberItem[]
  const vendorReleaseId = useWatch({ name: ReferenceFormAccessor.VendorReleaseId })
  const transactionType = useWatch({ name: ReferenceFormAccessor.TransactionType })
  const depotSettingId = useWatch({ name: ReferenceFormAccessor.DepotSettingId })
  const bookingType = useWatch({ name: ReferenceFormAccessor.BookingType })
  const [assetsIds, setAssetsIds] = useState<ReferenceItemsAssetsIds>(getDefaultAssetsIds(data))

  const currentItemsQty = vendorReleaseItems ? getVendorReleaseItemsQty(items) : {}
  const isAllowedItemsQtyError = Object.keys(currentItemsQty).some((itemId) => {
    return vendorReleaseItems?.[itemId] && currentItemsQty[itemId] > vendorReleaseItems[itemId]
  })

  const handleClickAdd = useCallback(() => {
    append({}, { shouldFocus: false })
  }, [append])

  const handleClickRemove = useCallback(
    (index: number) => () => {
      remove(index)

      const items = (getValues(ReferenceFormAccessor.Items) || []) as ReferenceNumberItem[]
      const newAssetsIds = items.reduce((acc, curr, currentIndex) => {
        curr.subLines?.forEach((subLine, index) => {
          acc = { ...acc, [`${currentIndex}.${index}`]: subLine.assetId }
        })
        return acc
      }, {})

      setAssetsIds(newAssetsIds)
      onDeleteItem?.(index)
    },
    [remove, onDeleteItem, getValues, setAssetsIds],
  )

  const isNewItemCreateInProgress = memoizedData && memoizedData?.length < items?.length
  const showDelete = !isFormViewOnly && canChangeQty && fields?.length > minCount
  const showAdd =
    !isNewItemCreateInProgress && !isFormViewOnly && canChangeQty && fields.length < maxItems && !isAllowedItemsQtyError

  const memoizedReferenceData = useMemoCompare(
    referenceData || vendorReleaseId || transactionType || depotSettingId || bookingType
      ? {
          ...(referenceData ? referenceData : {}),
          ...(vendorReleaseId ? { vendorReleaseId } : {}),
          ...(transactionType ? { transactionType } : {}),
          ...(depotSettingId ? { depotSettingId } : {}),
          ...(bookingType ? { bookingType } : {}),
        }
      : undefined,
  )

  const memoizedItemsContextValue = useMemoCompare({
    data: memoizedData,
    canChangeQty,
    isFieldViewOnly,
    referenceData: memoizedReferenceData,
    assetsIds,
    setAssetsIds,
  })

  return (
    <ReferenceItemsContext.Provider value={memoizedItemsContextValue}>
      <Container pa={pa} fg={1} fd="column" bg="white">
        {((canChangeQty && !isFormViewOnly) || !!fields.length || !!children) && (
          <Container jc="space-between" gap={16} ai="center">
            <Typography size="m" fontWeight="xl">
              Items
            </Typography>
            {children}
          </Container>
        )}
        <Container fd="column" mt={4} gap={16}>
          {fields.map((field, index) => (
            <Container key={field.id} fd="column">
              <ReferenceItem
                name={ReferenceFormAccessor.Items}
                showDelete={showDelete}
                type={type}
                index={index}
                onClickRemove={handleClickRemove(index)}
                isFormViewOnly={isFormViewOnly}
                vendorReleaseItems={vendorReleaseItems}
                currentQty={currentItemsQty}
                applyOverlay={(!!isNewItemCreateInProgress && index < fields.length - 1) || isAllowedItemsQtyError}
              />
            </Container>
          ))}
          {showAdd && (
            <Container mt={2}>
              <Button onClick={handleClickAdd} icon="plus" type="link">
                Add SKU
              </Button>
            </Container>
          )}
        </Container>
      </Container>
    </ReferenceItemsContext.Provider>
  )
}

export const ReferenceItems = typedMemo(ReferenceItemsBase)
