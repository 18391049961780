import { FC, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import type { SidebarItem } from './types/sidebarItem'
import * as Styled from './styles'

interface SidebarProps {
  sidebarItems: SidebarItem[]
}

export const Sidebar: FC<SidebarProps> = ({ sidebarItems }) => {
  const location = useLocation()

  const menuItemsKeys = sidebarItems.map((item) => item.key)

  const menuItemLocationExist = menuItemsKeys.find((key) => location.pathname.includes(key))
  const [activeMenuItem, setActiveMenuItem] = useState<string | null>(null)

  const handleClickMenuItem = useCallback(({ key }: { key: string }) => {
    setActiveMenuItem(key)
  }, [])

  useEffect(() => {
    if (menuItemLocationExist) {
      setActiveMenuItem(menuItemLocationExist)
    } else {
      setActiveMenuItem(null)
    }
  }, [menuItemLocationExist])

  return (
    <Styled.Sidebar width={172}>
      <Styled.Menu
        selectedKeys={activeMenuItem ? [activeMenuItem] : []}
        items={sidebarItems}
        onClick={handleClickMenuItem}
      />
    </Styled.Sidebar>
  )
}
