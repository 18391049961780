import { route } from 'constant'

import { BreadcrumbItem } from 'components'

export const getBreadcrumbsData = (dynamicItem?: BreadcrumbItem): BreadcrumbItem[] => {
  if (dynamicItem) {
    return [{ url: route.vendors, title: 'Vendors' }, dynamicItem]
  }
  return [{ url: route.vendors, title: 'Vendors' }]
}
