import { Divider as BaseDivider } from 'antd'
import { styled } from 'styled-components'

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const Divider = styled(BaseDivider)`
  &.ant-divider {
    margin: ${({ theme }) => `${theme.spacers[4]} 0 ${theme.spacers[3]}`};
  }
  &&& .ant-divider-inner-text {
    padding: 0 ${({ theme }) => theme.spacers[7]};
  }
`
