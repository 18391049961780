import { ReferenceNumberRedeliveryTransactionType } from 'api/types/referenceNumber/referenceNumberRedeliveryTransactionType'

export const referenceNumberRedeliveryTransactionTypeLabel = {
  [ReferenceNumberRedeliveryTransactionType.Purchase]: 'Purchase',
  [ReferenceNumberRedeliveryTransactionType.Pickup]: 'Pickup',
  [ReferenceNumberRedeliveryTransactionType.Transfer]: 'Transfer',
  [ReferenceNumberRedeliveryTransactionType.RentalSwapReturn]: 'Rental Swap Return',
  [ReferenceNumberRedeliveryTransactionType.SaleSwapReturn]: 'Sale Swap Return',
  [ReferenceNumberRedeliveryTransactionType.Recycle]: 'Recycle',
  [ReferenceNumberRedeliveryTransactionType.Rejection]: 'Rejection',
}

export const referenceNumberRedeliveryTransactionTypeOptions: {
  value: ReferenceNumberRedeliveryTransactionType
  label: string
}[] = [
  {
    value: ReferenceNumberRedeliveryTransactionType.Purchase,
    label: referenceNumberRedeliveryTransactionTypeLabel[ReferenceNumberRedeliveryTransactionType.Purchase],
  },
  {
    value: ReferenceNumberRedeliveryTransactionType.Pickup,
    label: referenceNumberRedeliveryTransactionTypeLabel[ReferenceNumberRedeliveryTransactionType.Pickup],
  },
  {
    value: ReferenceNumberRedeliveryTransactionType.Transfer,
    label: referenceNumberRedeliveryTransactionTypeLabel[ReferenceNumberRedeliveryTransactionType.Transfer],
  },
  {
    value: ReferenceNumberRedeliveryTransactionType.RentalSwapReturn,
    label: referenceNumberRedeliveryTransactionTypeLabel[ReferenceNumberRedeliveryTransactionType.RentalSwapReturn],
  },
  {
    value: ReferenceNumberRedeliveryTransactionType.SaleSwapReturn,
    label: referenceNumberRedeliveryTransactionTypeLabel[ReferenceNumberRedeliveryTransactionType.SaleSwapReturn],
  },
  {
    value: ReferenceNumberRedeliveryTransactionType.Recycle,
    label: referenceNumberRedeliveryTransactionTypeLabel[ReferenceNumberRedeliveryTransactionType.Recycle],
  },
  {
    value: ReferenceNumberRedeliveryTransactionType.Rejection,
    label: referenceNumberRedeliveryTransactionTypeLabel[ReferenceNumberRedeliveryTransactionType.Rejection],
  },
]
