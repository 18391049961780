import { ItemCategory } from 'api'

export const removeCategory = (categories: ItemCategory[], id: number, parentId: number | null): ItemCategory[] => {
  if (!parentId) {
    return categories.filter((item) => item.id !== id)
  } else {
    return categories.map((category) => {
      if (category.id === parentId) {
        return {
          ...category,
          children: category.children?.filter((child) => child.id !== id),
        }
      }
      return category
    })
  }
}
