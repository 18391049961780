import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react'
import { Col, Row } from 'antd'

import { CustomerStatus, ReferenceNumber, ReferenceNumberType } from 'api'
import { useCustomers, useFormContext, useGetCustomerBillingAddresses } from 'hooks'
import { getReferenceTypeOptionLabel, referenceLabels, referenceNumberRedeliveryTransactionTypeOptions } from 'constant'
import { ReferenceFormAccessor } from 'types'
import { formatDate, getAddressString, isDateBeforeCurrentDateEndOfDay, toDate } from 'utils'

import { DatePicker, FormItem, Input, SelectSingle, Spacer, TextArea, Typography } from 'designSystem'
import { AddAddressModal, FormItemSelectSingleAsync, LabelValueMarkup, NewAddress } from 'components'

import { getReferenceNumberPrefix } from './utils/getReferenceNumberPrefix'
import * as Styled from './styles'

import { LocationDepotFields } from '../LocationDepotFields'

interface ReferenceNumberDetailsFieldsProps {
  type: ReferenceNumberType
  defaultData?: ReferenceNumber
  newAddresses?: NewAddress[]
  setNewAddresses?: Dispatch<SetStateAction<NewAddress[]>>
}

export const ReferenceNumberDetailsFields: FC<ReferenceNumberDetailsFieldsProps> = ({
  type,
  defaultData,
  setNewAddresses,
  newAddresses = [],
}) => {
  const { setValue, getValues, trigger } = useFormContext()
  const [customerId, setCustomerId] = useState(getValues(ReferenceFormAccessor.CustomerId))
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false)

  const toggleAddressModalOpen = useCallback(() => setIsAddressModalOpen((prev) => !prev), [])

  const isRedelivery = type === ReferenceNumberType.Redelivery
  const isPickup = type === ReferenceNumberType.Pickup
  const isVendorRelease = type === ReferenceNumberType.VendorRelease

  const { options: addressOptions = [], isLoading: isAddressLoading } = useGetCustomerBillingAddresses(
    customerId,
    isPickup,
  )
  const addressOptionsWithNew = [
    ...newAddresses.map((newAddress) => {
      const value = getAddressString(newAddress)
      return {
        label: value,
        value,
      }
    }),
    ...addressOptions,
  ]

  const handleCustomerChange = useCallback(
    (value?: string | number | null) => {
      setCustomerId(value)
      setValue(ReferenceFormAccessor.CustomerAddressId, null)
    },
    [setValue],
  )

  const handleCreateNewAddress = useCallback(
    (address: NewAddress) => {
      setValue(ReferenceFormAccessor.CustomerAddressId, getAddressString(address))
      setNewAddresses?.((prev) => [...prev, address])
      toggleAddressModalOpen()
      trigger(ReferenceFormAccessor.CustomerAddressId)
    },
    [setValue, setNewAddresses, toggleAddressModalOpen, trigger],
  )

  return (
    <Styled.FieldsWrapper fd="column" gap={8} mt={4}>
      {isVendorRelease && (
        <Row gutter={8}>
          <Col span={24}>
            <FormItem name={ReferenceFormAccessor.Number} label={`${getReferenceTypeOptionLabel(type)} Number`}>
              <Input placeholder={`${getReferenceNumberPrefix(type)}-####`} />
            </FormItem>
          </Col>
          <Col span={24}>
            <Spacer mt={2} />
            <Typography size="xs" fontWeight="l" color="secondary">
              Leave blank to generate automatically
            </Typography>
          </Col>
        </Row>
      )}
      {isRedelivery && (
        <Row>
          <Col span={24}>
            <FormItem name={ReferenceFormAccessor.TransactionType} label={referenceLabels.transactionType}>
              <SelectSingle options={referenceNumberRedeliveryTransactionTypeOptions} />
            </FormItem>
          </Col>
        </Row>
      )}
      {isPickup && (
        <>
          <Row>
            <Col span={24}>
              <FormItemSelectSingleAsync
                name={ReferenceFormAccessor.CustomerId}
                label={referenceLabels.customerId}
                getItems={useCustomers}
                onChange={handleCustomerChange}
                queryParams={{ status: { $eq: CustomerStatus.Active } }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem name={ReferenceFormAccessor.CustomerAddressId} label={referenceLabels.customerAddressId}>
                <SelectSingle
                  allowClear
                  showSearch
                  // withCreateNew - add in future when will be needed
                  onClickCreateNew={toggleAddressModalOpen}
                  disabled={!customerId}
                  options={addressOptionsWithNew}
                  placeholder={customerId ? 'Select' : 'Select Customer first'}
                  loading={isAddressLoading}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <FormItem name={ReferenceFormAccessor.PickupDate} label={referenceLabels.pickupDate}>
                <DatePicker disabledDate={isDateBeforeCurrentDateEndOfDay} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem name={ReferenceFormAccessor.ScheduledPickupDate} label={referenceLabels.scheduledPickupDate}>
                <DatePicker disabledDate={isDateBeforeCurrentDateEndOfDay} />
              </FormItem>
            </Col>
          </Row>
        </>
      )}
      {!isRedelivery && (
        <Row gutter={8}>
          <Col span={12}>
            <FormItem name={ReferenceFormAccessor.ExpirationDate} label={referenceLabels.expirationDate}>
              <DatePicker />
            </FormItem>
          </Col>
        </Row>
      )}
      {!isPickup && <LocationDepotFields defaultData={defaultData} type={type} />}
      <Row>
        <Col span={24}>
          <FormItem name={ReferenceFormAccessor.Notes} label={referenceLabels.notes}>
            <TextArea size="small" placeholder="Type notes" autoSize={{ minRows: 4 }} />
          </FormItem>
        </Col>
      </Row>
      <Styled.CreatedDateWrapper>
        <LabelValueMarkup
          label="Created Time"
          typographyPropsLabel={{ color: 'primary', size: 'xs' }}
          typographyPropsValue={{ color: 'primary', size: 'xs' }}
          value={formatDate(toDate(), 'Standard')}
        />
      </Styled.CreatedDateWrapper>
      <AddAddressModal open={isAddressModalOpen} onCancel={toggleAddressModalOpen} onSubmit={handleCreateNewAddress} />
    </Styled.FieldsWrapper>
  )
}
