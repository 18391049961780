import { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from 'designSystem'
import { ConfirmationPopover } from 'components'

import { useRemoveLocationCode } from './hooks/useRemoveLocationCode'

export const ActionButtons: FC = () => {
  const { id } = useParams()
  const { remove, isPending } = useRemoveLocationCode(Number(id))

  const handleRemoveLocationCode = useCallback(() => {
    remove()
  }, [remove])

  return (
    <ConfirmationPopover
      text="Are you sure you want to remove location?"
      onOk={handleRemoveLocationCode}
      isLoading={isPending}
    >
      <Button icon="delete" danger size="small" />
    </ConfirmationPopover>
  )
}
