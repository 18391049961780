import { FC } from 'react'

import { ReferenceNumber } from 'api'
import { QueryKey } from 'enums'
import { useReferencesTable } from 'hooks'
import { route } from 'constant'
import { AppModule } from 'types'

import {
  Table,
  TableFilterDrawer,
  TableFilterOptions,
  TableSearchSingle,
  TableTitle,
  useTableColumns,
} from 'components'

import { getTableColumns } from './utils/getTableColumns'
import { referencesFilterOptions } from './constants/referencesFilterOptions'
import { referencesVisibleColumnsOptions } from './constants/referenceVisibleColumns'

export const ReferencesList: FC = () => {
  const {
    dataSource,
    loading,
    pagination,
    onChange,
    total,
    getFieldSorter,
    offsetCount,
    TableContextProvider,
    filterOptions,
    setFilterOptions,
  } = useReferencesTable({ queryKey: QueryKey.GetReferenceNumbers, module: AppModule.References })
  const allColumns = getTableColumns(getFieldSorter, offsetCount)
  const { visibleColumns, setVisibleColumns, columns } = useTableColumns<ReferenceNumber>({
    module: AppModule.References,
    allColumns,
  })

  return (
    <TableContextProvider>
      <TableTitle
        title="References"
        total={total}
        addRoute={route.referencesCreateChooseType}
        search={<TableSearchSingle searchFieldName={'number'} />}
        filter={
          <TableFilterDrawer
            setFilterOptions={setFilterOptions}
            filterOptions={filterOptions}
            options={referencesFilterOptions}
            module={AppModule.References}
          />
        }
        columns={
          <TableFilterOptions
            title="Columns"
            icon="columns"
            options={referencesVisibleColumnsOptions}
            value={visibleColumns}
            onChange={setVisibleColumns}
          />
        }
      />
      <Table<ReferenceNumber>
        withBorderRadius={false}
        dataSource={dataSource}
        loading={loading}
        columns={columns}
        pagination={pagination}
        onChange={onChange}
        scroll={{ x: 750 }}
      />
    </TableContextProvider>
  )
}
