import { FC, useCallback } from 'react'

import { Contact, EntityId } from 'api'
import { useForm, useGetCustomerContacts } from 'hooks'

import { Container, Spacer, Typography } from 'designSystem'
import { FormItemEditContacts, Spinner } from 'components'

import { useDeleteCustomerContact } from './hooks/useDeleteCustomerContact'
import { usePatchCustomerContact } from './hooks/usePatchCustomerContact'
import { usePostCustomerContact } from './hooks/usePostCustomerContact'
import { validationSchema } from './constants/validationSchema'
import { maxContactsCount } from 'modules/customers/constants/maxRecordsCount'
import { CustomerContactsForm } from './types/customerContactsForm'

interface CustomerContactsProps {
  id: EntityId
}

export const CustomerContacts: FC<CustomerContactsProps> = ({ id }) => {
  const { data: contacts, isLoading } = useGetCustomerContacts(id)
  const { post, isError: isErrorPost, isPending: isLoadingPost } = usePostCustomerContact(id)
  const { patch, isError: isErrorPatch, isPending: isLoadingPatch } = usePatchCustomerContact(id)
  const { deleteContact, isError: isErrorDelete } = useDeleteCustomerContact(id)

  const { Form, watch } = useForm<CustomerContactsForm>({
    validationSchema,
    mode: 'onChange',
    isSubmitError: isErrorPost || isErrorDelete || isErrorPatch,
    defaultValues: { contacts },
  })
  const contactsFormData: Contact[] = watch('contacts') as Contact[]

  const onSubmitPost = useCallback(
    (index: number) => {
      post(contactsFormData?.[index])
    },
    [contactsFormData, post],
  )

  const onSubmitPatch = useCallback(
    (index: number) => {
      patch(contactsFormData?.[index])
    },
    [contactsFormData, patch],
  )

  const onDelete = useCallback(
    (index: number) => {
      deleteContact(contactsFormData?.[index]?.id)
    },
    [contactsFormData, deleteContact],
  )

  return (
    <Form>
      <Typography fontWeight="xl">Contact Persons</Typography>
      <Spacer mb={3} />
      {isLoading && (
        <Container ai="center" jc="center" minHeight={200}>
          <Spinner size="large" />
        </Container>
      )}
      <FormItemEditContacts
        isLoading={isLoadingPatch || isLoadingPost}
        name="contacts"
        onSubmitPost={onSubmitPost}
        onSubmitPatch={onSubmitPatch}
        onDelete={onDelete}
        maxContacts={maxContactsCount}
      />
    </Form>
  )
}
