import { FC, useEffect } from 'react'

import { useFormContext } from 'hooks'
import { paymentTermsOptions } from 'constant'
import { PurchaseOrderFormAccessor } from 'types'
import { useGetPurchaseOrderVendorPaymentTerm } from 'store'

import { FormItem, SelectSingle } from 'designSystem'

export const PaymentTermsField: FC = () => {
  const paymentTerm = useGetPurchaseOrderVendorPaymentTerm()
  const { setValue } = useFormContext()

  useEffect(() => {
    setValue(PurchaseOrderFormAccessor.PaymentTerms, paymentTerm)
  }, [paymentTerm, setValue])

  return (
    <FormItem name={PurchaseOrderFormAccessor.PaymentTerms} label="Payment Terms">
      <SelectSingle options={paymentTermsOptions} placeholder="Select" />
    </FormItem>
  )
}
