import { api, EntityId, ReferenceNumberPickup } from 'api'

import { PickupNumberFormPayload } from 'modules/references/types'

import { referenceNumberByIdJoinParams } from 'modules/references/constants/referenceNumberByIdJoinParams'

export const patchPickupNumber = ({
  id,
  data,
}: {
  id: EntityId
  data: Partial<Omit<PickupNumberFormPayload, 'items'>>
}): Promise<{ reference: ReferenceNumberPickup }> => {
  return api({
    method: 'patch',
    url: `/api/v1/references/pickup/${id}`,
    data,
    params: referenceNumberByIdJoinParams,
  })
}
