import { ReferenceNumber, ReferenceNumberBookingTransactionType } from 'api'
import { TableField, TableSortingOrder } from 'hooks'
import { getReferenceTypeOptionLabel, hash, referenceNumberBookingTransactionTypeLabel } from 'constant'
import { formatDate } from 'utils'

import { ReactLink, Typography } from 'designSystem'
import { ReferenceStatusTag, RelatedLinksList, TableColumnKey, TableColumnsType } from 'components'

import { AssetBookingReferenceAccessor } from '../types/assetBookingReferenceAccessor'

import { getAssetBookingReferenceAccessorTitle } from './getAssetBookingReferenceAccessorTitle'

export const getAssetBookingReferencesTableColumns = (
  getFieldSorter: (field: TableField) => TableSortingOrder,
  offsetCount: number,
): TableColumnsType<ReferenceNumber> => [
  {
    title: getAssetBookingReferenceAccessorTitle(AssetBookingReferenceAccessor.Index),
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => offsetCount + index + 1,
  },
  {
    title: getAssetBookingReferenceAccessorTitle(AssetBookingReferenceAccessor.CreatedAt),
    key: AssetBookingReferenceAccessor.CreatedAt,
    dataIndex: AssetBookingReferenceAccessor.CreatedAt,
    width: 200,
    ellipsis: true,
    sorter: true,
    sortOrder: getFieldSorter(AssetBookingReferenceAccessor.CreatedAt),
    render: (value) => formatDate(value, 'StandardWithHours'),
  },
  {
    title: getAssetBookingReferenceAccessorTitle(AssetBookingReferenceAccessor.Number),
    key: AssetBookingReferenceAccessor.Number,
    dataIndex: AssetBookingReferenceAccessor.Number,
    width: 200,
    sorter: true,
    sortOrder: getFieldSorter(AssetBookingReferenceAccessor.Number),
    ellipsis: true,
    render: (number: string, record) => (
      <ReactLink to={`${hash.reference}/${record.id}`} target="_self">
        <Typography color="inherit" size="xs" fontWeight="l" lineEllipsis={1}>
          {number}
        </Typography>
      </ReactLink>
    ),
  },
  {
    title: getAssetBookingReferenceAccessorTitle(AssetBookingReferenceAccessor.Type),
    key: AssetBookingReferenceAccessor.Type,
    dataIndex: AssetBookingReferenceAccessor.Type,
    width: 150,
    sorter: true,
    sortOrder: getFieldSorter(AssetBookingReferenceAccessor.Type),
    ellipsis: true,
    render: (type) => getReferenceTypeOptionLabel(type),
  },
  {
    title: getAssetBookingReferenceAccessorTitle(AssetBookingReferenceAccessor.TransactionType),
    key: AssetBookingReferenceAccessor.TransactionType,
    dataIndex: AssetBookingReferenceAccessor.TransactionType,
    width: 150,
    ellipsis: true,
    render: (transactionType: ReferenceNumberBookingTransactionType) =>
      referenceNumberBookingTransactionTypeLabel[transactionType],
  },
  {
    title: getAssetBookingReferenceAccessorTitle(AssetBookingReferenceAccessor.RelatedReference),
    key: AssetBookingReferenceAccessor.RelatedReference,
    width: 200,
    ellipsis: true,
    render: (_, record) => {
      const relatedRecords = [
        record.vendorRelease,
        record.bookingNumber,
        ...(record.bookingNumbers || []),
        ...(record.redeliveryNumbers || []),
      ]
        .filter(Boolean)
        .map((ref) => ({
          id: ref?.id as number,
          title: ref?.number as string,
        }))
      return relatedRecords.length ? (
        <RelatedLinksList hash="reference" linkProps={{ target: '_self' }} data={relatedRecords} />
      ) : (
        '-'
      )
    },
  },
  {
    title: getAssetBookingReferenceAccessorTitle(AssetBookingReferenceAccessor.GateOutDate),
    key: AssetBookingReferenceAccessor.GateOutDate,
    width: 200,
    ellipsis: true,
    render: (value: undefined, record: ReferenceNumber) => {
      const dateString = record?.items
        ?.reduce((acc, item) => {
          const subLineDate = item?.subLines
            ?.map((subLine) => (subLine.gateOutDate ? formatDate(subLine.gateOutDate) : ''))
            .filter(Boolean)
            .join(', ')

          if (subLineDate) {
            acc.push(subLineDate)
          }
          return acc
        }, [] as string[])
        .join(', ')

      return dateString || '-'
    },
  },
  {
    title: getAssetBookingReferenceAccessorTitle(AssetBookingReferenceAccessor.Notes),
    key: AssetBookingReferenceAccessor.Notes,
    dataIndex: AssetBookingReferenceAccessor.Notes,
    width: 200,
    ellipsis: true,
    render: (notes) => notes || '-',
  },
  {
    title: getAssetBookingReferenceAccessorTitle(AssetBookingReferenceAccessor.Status),
    key: AssetBookingReferenceAccessor.Status,
    dataIndex: AssetBookingReferenceAccessor.Status,
    width: 200,
    sorter: true,
    sortOrder: getFieldSorter(AssetBookingReferenceAccessor.Status),
    ellipsis: true,
    render: (status) => <ReferenceStatusTag status={status} />,
  },
]
