import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { getWarehouses } from 'api'
import { QueryKey } from 'enums'

export const useGetWarehouses = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QueryKey.GetWarehouses],
    queryFn: getWarehouses,
    placeholderData: keepPreviousData,
  })

  return {
    data,
    ...rest,
  }
}
