import type { PurchaseOrderItem } from 'api'

import { itemLabels } from '../item'
import { purchaseOrderItemSubLineLabels } from './purchaseOrderItemSubLineLabels'

interface PurchaseOrderItemLabels
  extends Record<keyof Omit<PurchaseOrderItem, 'subLines' | 'item' | 'purchaseOrder'>, string> {
  subLines: typeof purchaseOrderItemSubLineLabels
  item: typeof itemLabels
}

export const purchaseOrderItemLabels: PurchaseOrderItemLabels = {
  id: '',
  itemId: 'SKU',
  item: itemLabels,
  title: 'Title',
  qty: 'Qty.',
  rate: 'Rate',
  subLines: purchaseOrderItemSubLineLabels,
  billedQty: 'Billed Qty.',
  receivedQty: 'Received Qty.',
  totalAmount: 'Total Amount',
}
