import { FC, useCallback } from 'react'

import { ReferenceNumber, ReferenceNumberType } from 'api'
import { useDepotCodes, useFormContext, useLocationCodes, useWatch } from 'hooks'
import { referenceLabels, route } from 'constant'
import { ReferenceFormAccessor } from 'types'

import { FormItemEditSelectSingleAsync } from 'components'

type LocationDepotFieldsProps = Pick<ReferenceNumber, 'locationCode' | 'depotSetting'> & {
  type: ReferenceNumberType
}

export const LocationDepotFields: FC<LocationDepotFieldsProps> = ({ locationCode, depotSetting, type }) => {
  const { setValue, triggerSubmit } = useFormContext()
  const locationCodeId = useWatch({ name: ReferenceFormAccessor.LocationCodeId })

  const handleLocationCodeSubmit = useCallback(
    (submitLocationCodeId?: string | number | null) => {
      if (submitLocationCodeId && locationCodeId !== submitLocationCodeId) {
        setValue(ReferenceFormAccessor.DepotSettingId, null)
      }
      triggerSubmit?.()
    },
    [setValue, triggerSubmit, locationCodeId],
  )

  const locationCodeDefaultOption = locationCode
    ? {
        value: locationCode.id,
        label: locationCode.code,
      }
    : undefined

  const depotCodeDefaultOption = depotSetting
    ? {
        value: depotSetting.id,
        label: depotSetting.code,
      }
    : undefined

  const isBookingType = type === ReferenceNumberType.Booking

  return (
    <>
      <FormItemEditSelectSingleAsync
        name={ReferenceFormAccessor.LocationCodeId}
        label={isBookingType ? referenceLabels.gateOutLocationId : referenceLabels.locationCodeId}
        href={route.locationCodes}
        getItems={useLocationCodes}
        defaultOption={locationCodeDefaultOption}
        onSubmit={handleLocationCodeSubmit}
      />
      <FormItemEditSelectSingleAsync
        name={ReferenceFormAccessor.DepotSettingId}
        label={isBookingType ? referenceLabels.gateOutDepotId : referenceLabels.depotSettingId}
        getItems={useDepotCodes}
        defaultOption={depotCodeDefaultOption}
        onSubmit={triggerSubmit}
        queryParams={{
          ...(locationCodeId && { [ReferenceFormAccessor.LocationCodeId]: { $eq: locationCodeId } }),
        }}
      />
    </>
  )
}
