import { forwardRef, ReactElement, Ref, useCallback, useState } from 'react'
import { InputRef } from 'antd/lib/input'

import { AddressFields } from 'api'
import { useFormContext } from 'hooks'
import { typedMemo } from 'types'

import { GooglePlacesAutocomplete, GooglePlacesAutocompleteProps } from '../../GooglePlacesAutocomplete'

export interface FormItemGoogleAutocompleteProps extends GooglePlacesAutocompleteProps {
  name: string
  fieldsNames?: AddressFields
}

export const FormItemGoogleAutocompleteBase = forwardRef(
  (
    {
      name,
      value,
      error,
      fieldsNames,
      onBlur,
      onChange,
      onChangeAutocomplete,
      ...props
    }: FormItemGoogleAutocompleteProps,
    ref: Ref<InputRef>,
  ): ReactElement => {
    const { setValue } = useFormContext()
    const [search, setSearch] = useState(value || '')

    const handleChange = useCallback(
      (value: string) => {
        setSearch(value)
        if (!value) {
          Object.keys(fieldsNames || {}).forEach((key) => {
            const fieldKey = fieldsNames?.[key as keyof typeof fieldsNames] as string
            if (fieldKey === name) {
              onChange?.('')
            } else {
              setValue(fieldKey, null)
            }
          })
        }
      },
      [name, fieldsNames, setValue, onChange],
    )

    const handleChangeAutocomplete = useCallback(
      (address: AddressFields) => {
        Object.keys(fieldsNames || {}).forEach((key) => {
          const fieldKey = fieldsNames?.[key as keyof typeof fieldsNames] as string
          const fieldValue = address?.[key as keyof AddressFields] as string

          if (fieldKey === name) {
            onChange?.(fieldValue || '')
            setSearch(fieldValue || '')
          } else {
            setValue(fieldKey, fieldValue)
          }
        })

        onChangeAutocomplete?.(address)
      },
      [fieldsNames, setValue, name, onChangeAutocomplete, onChange],
    )

    const handleBlur = useCallback(() => {
      if (!value) {
        onChange?.('')
        setSearch('')
      } else {
        setSearch(value)
      }

      onBlur?.()
    }, [value, onBlur, onChange])

    return (
      <GooglePlacesAutocomplete
        {...props}
        allowClear
        onChange={handleChange}
        value={search}
        onBlur={handleBlur}
        ref={ref}
        error={error}
        onChangeAutocomplete={handleChangeAutocomplete}
      />
    )
  },
)

export const FormItemGoogleAutocomplete = typedMemo(FormItemGoogleAutocompleteBase)
