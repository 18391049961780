import { ReferenceNumberAssetStatus, ReferenceNumberItem } from 'api'

export const getIsAllSubLinesStatusReceived = (items?: ReferenceNumberItem[]) => {
  return items?.reduce((acc, item) => {
    if (!acc) {
      return false
    }
    item.subLines?.forEach((subLine) => {
      if (subLine.status !== ReferenceNumberAssetStatus.Received) {
        acc = false
      }
    })

    return acc
  }, true)
}
