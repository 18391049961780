export enum ReferenceNumberBookingTransactionType {
  Sale = 'sale',
  Rental = 'rental',
  Transfer = 'transfer',
  Split = 'split',
  Recycle = 'recycle',
  RentalSwap = 'rental_swap',
  SaleSwap = 'sale_swap',
  Rejection = 'rejection',
}
