import { FC, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { removeTokens } from 'api'
import { queryClient } from 'config/queryClient'
import { route } from 'constant'
import { useRemoveUser, useUser } from 'store'

import { Button, Container, Spacer, Typography } from 'designSystem'
import { Avatar } from 'components/Avatar'

import * as Styled from './styles'

interface AvatarMenuProps {
  closePopover: () => void
}

export const AvatarMenu: FC<AvatarMenuProps> = ({ closePopover }) => {
  const navigate = useNavigate()
  const user = useUser()
  const removeUser = useRemoveUser()

  const handleLogout = useCallback(() => {
    queryClient.clear()
    removeTokens()
    removeUser()
    navigate(route.login, {
      replace: true,
    })
  }, [navigate, removeUser])

  return (
    <>
      <Container display="block" pa={4}>
        <Typography as="h2" fontWeight="xl" size="s">
          My account
        </Typography>
        <Spacer mb={3} />
        {user && (
          <Styled.UserInfo>
            <Avatar user={user} />
            <Styled.UserContent>
              <Typography as="h3" fontWeight="xl" size="s" lineEllipsis={1}>
                {user.firstName} {user.lastName}
              </Typography>
              <Typography as="h4" fontWeight="l" size="xs" lineEllipsis={1}>
                {user.email}
              </Typography>
            </Styled.UserContent>
          </Styled.UserInfo>
        )}
        <Link to={route.profile}>
          <Button type="link" icon="edit" size="small" onClick={closePopover}>
            Edit Profile
          </Button>
        </Link>
      </Container>
      <Styled.Divider />
      <Container display="flex" fd="column" pa={4}>
        <Typography as="h2" fontWeight="xl" size="s">
          Company
        </Typography>
        <Spacer mb={3} />
        <Link to={route.globalSettings}>
          <Button type="link" icon="edit" size="small" onClick={closePopover}>
            Global Settings
          </Button>
        </Link>
      </Container>
      <Styled.Divider />
      <Container pa={4}>
        <Button icon="logout" type="link" size="small" danger onClick={handleLogout}>
          Log Out
        </Button>
      </Container>
    </>
  )
}
