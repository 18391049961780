import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { EquipmentPayload } from '../types/equipmentCreateForm'

import { patchEquipment } from '../queryApi'

export const usePatchEquipment = (vendorId: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation({
    mutationFn: patchEquipment,
    onSuccess: () => {
      dispatchAppEvent(AppEvent.EquipmentUpdate)

      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const patch = useCallback(
    (equipmentId: number, payload: EquipmentPayload) => {
      mutate({ vendorId, equipmentId, data: payload })
    },
    [mutate, vendorId],
  )

  return {
    patch,
    ...rest,
  }
}
