import { FC } from 'react'
import { Col, Row } from 'antd'

import { MarginSettingMeasure, MarginSettingOperator } from 'api'
import { marginSettingsMeasureOptions, marginSettingsOperatorOptions, vendorFetchOptions } from 'constant'

import { Container, FormItem, Input, InputNumber, SelectSingle, Spacer, Switch, Typography } from 'designSystem'

interface FeedSettingsProps {
  name?: string
}

export const FeedSettings: FC<FeedSettingsProps> = ({ name }) => {
  return (
    <Container fd="column">
      <FormItem name={name ? `${name}.scrappingSettings.feedUrl` : 'scrappingSettings.feedUrl'} label="Feed Url">
        <Input placeholder="Enter Url" defaultValue="https://" />
      </FormItem>
      <Spacer mb={3} />
      <FormItem name={name ? `${name}.scrappingSettings.isEnabled` : 'scrappingSettings.isEnabled'}>
        <Switch text="Enable Feed URL" />
      </FormItem>
      <Spacer mb={4} />
      <Row gutter={8}>
        <Col span={12}>
          <FormItem
            name={name ? `${name}.scrappingSettings.fetchSchedule` : 'scrappingSettings.fetchSchedule'}
            label="Fetch Schedule"
          >
            <SelectSingle placeholder="Select" options={vendorFetchOptions} />
          </FormItem>
        </Col>
        <Col span={12}>
          <Row>
            <Typography size="xs" fontWeight="l">
              Resale Margin
            </Typography>
          </Row>
          <Spacer mb={1} />
          <Row gutter={8}>
            <Col span={6}>
              <FormItem
                name={name ? `${name}.resaleMargin.operator` : 'resaleMargin.operator'}
                defaultValue={MarginSettingOperator.Plus}
              >
                <SelectSingle placeholder="Select" options={marginSettingsOperatorOptions} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem name={name ? `${name}.resaleMargin.operand` : 'resaleMargin.operand'}>
                <InputNumber placeholder="00.00" precision={2} />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                name={name ? `${name}.resaleMargin.measure` : 'resaleMargin.measure'}
                defaultValue={MarginSettingMeasure.Amount}
              >
                <SelectSingle placeholder="Select" options={marginSettingsMeasureOptions} />
              </FormItem>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
