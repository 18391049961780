import { FC } from 'react'

import { Container } from 'designSystem'

import type { ReferenceNumberTitleProps } from '../../'
import { AddPickupContacts } from '../AddPickupContacts'

export const PickupActions: FC<ReferenceNumberTitleProps> = ({ data, canEdit }) => {
  const { id, customerId, contacts } = data

  return canEdit ? (
    <Container gap={8}>
      <AddPickupContacts id={id} customerId={customerId} contacts={contacts} />
    </Container>
  ) : null
}
