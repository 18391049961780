import { api, Contact, EntityId } from 'api'

export const postCustomerContact = ({
  customerId,
  data,
}: {
  customerId?: EntityId
  data?: Contact
}): Promise<Contact> => {
  return api({
    method: 'post',
    url: `/api/v1/customers/${customerId}/contacts`,
    data,
  })
}

export const patchCustomerContact = ({
  customerId,
  contactId,
  data,
}: {
  customerId?: EntityId
  contactId?: EntityId
  data?: Contact
}): Promise<Contact> => {
  return api({
    method: 'patch',
    url: `/api/v1/customers/${customerId}/contacts/${contactId}`,
    data,
  })
}

export const deleteCustomerContact = ({
  customerId,
  contactId,
}: {
  customerId?: EntityId
  contactId?: EntityId
}): Promise<{ deleted: boolean }> => {
  return api({
    method: 'delete',
    url: `/api/v1/customers/${customerId}/contacts/${contactId}`,
  })
}
