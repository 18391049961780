import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { ApiHeader, EntityId, PurchaseOrderStatus } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent, PurchaseOrderFormAccessor } from 'types'
import { useSetPurchaseOrderData, useSetPurchaseOrderPayload, useSetPurchaseOrderRequestId } from 'store'

import { showNotification } from 'designSystem'

import { PurchaseOrderDetailsPayload } from '../types/purchaseOrderDetailsPayload'

import { patchPurchaseOrder } from '../queryApi'

export const useUpdatePurchaseOrder = (id?: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const setPurchaseOrderData = useSetPurchaseOrderData()
  const setPurchaseOrderPayload = useSetPurchaseOrderPayload()
  const setPurchaseOrderRequestId = useSetPurchaseOrderRequestId()

  const { mutate, ...rest } = useMutation({
    mutationFn: patchPurchaseOrder,
    onSuccess: ({ data, headers }, payload) => {
      setPurchaseOrderRequestId(headers[ApiHeader.RequestId])
      setPurchaseOrderData(data)
      setPurchaseOrderPayload(payload.data)
      dispatchAppEvent(AppEvent.PurchaseOrderUpdated)

      queryClient.setQueryData([QueryKey.GetPurchaseOrderById, id], data)
      showNotification({ title: 'Changes saved', type: 'success' })

      if (
        payload.data[PurchaseOrderFormAccessor.VendorReleaseNumber] ||
        payload.data[PurchaseOrderFormAccessor.RedeliveryNumber] ||
        payload.data.status === PurchaseOrderStatus.Voided
      ) {
        queryClient.invalidateQueries({ queryKey: [QueryKey.GetPurchaseOrderItems, id] })
      }
    },
  })

  const update = useCallback(
    (payload: PurchaseOrderDetailsPayload) => {
      if (id) {
        mutate({ id, data: payload })
      }
    },
    [mutate, id],
  )

  return {
    update,
    ...rest,
  }
}
