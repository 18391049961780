import { ItemCategory, ItemCategoryType } from 'api'

import { PatchCategoryItemPayload } from '../../../queryApi'

export const getDragPayload = (currentCategory: ItemCategory, overCategory: ItemCategory, categoriesLength: number) => {
  const getDragPosition = () => {
    switch (overCategory?.type) {
      case ItemCategoryType.Single:
        return overCategory.position
      case ItemCategoryType.Folder:
        return overCategory.children.length + 1
      default:
        return currentCategory.parentId ? categoriesLength + 1 : categoriesLength
    }
  }

  const dragPayload = {
    id: currentCategory.id,
    parentId: overCategory?.type === ItemCategoryType.Folder ? overCategory.id : overCategory?.parentId || null,
    position: getDragPosition(),
  } satisfies PatchCategoryItemPayload
  return dragPayload
}
