import { ActivityStreamlineType } from 'api'

export const getActivityStreamLineEntity = (type: ActivityStreamlineType) => {
  return {
    [ActivityStreamlineType.PurchaseOrderEntity]: 'PO',
    [ActivityStreamlineType.PurchaseOrderItemEntity]: 'PO Item line',
    [ActivityStreamlineType.PurchaseOrderSubLineEntity]: 'PO Asset line',
    [ActivityStreamlineType.PurchaseOrderContactEntity]: 'PO Contact',
    [ActivityStreamlineType.ReferenceEntity]: 'Reference',
    [ActivityStreamlineType.ReferenceItemEntity]: 'Reference Item line',
    [ActivityStreamlineType.ReferenceItemSubLineEntity]: 'Reference Asset line',
    [ActivityStreamlineType.ReferenceContactEntity]: 'Reference Contact',
    [ActivityStreamlineType.ItemEntity]: 'Item',
  }[type]
}
