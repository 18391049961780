import { VendorType } from 'api'

export const getCurrentTypeFieldName = (type?: VendorType): string | undefined => {
  if (type) {
    return {
      [VendorType.Carrier]: 'carrierSettings',
      [VendorType.ContainerVendor]: 'containerSupplierSettings',
      [VendorType.Modifications]: 'modificationsSettings',
      [VendorType.Depot]: 'depotSettings',
      [VendorType.Parts]: 'partSettings',
    }[type]
  }

  return undefined
}
