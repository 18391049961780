import { FC } from 'react'

import {
  ReferenceNumber,
  ReferenceNumberRedelivery,
  ReferenceNumberRedeliveryTransactionType,
  ReferenceNumberType,
} from 'api'
import { useForm, usePOs, useReferences, useSalesOrders } from 'hooks'
import {
  currency,
  redeliveryStatusOptions,
  referenceLabels,
  referenceNumberRedeliveryTransactionTypeOptions,
  route,
  vendorViewRoute,
} from 'constant'
import { ReferenceFormAccessor, typedMemo } from 'types'

import { Container, EditFormItem, GridLayout, Icon, Spacer, Typography } from 'designSystem'
import {
  FormItemEditDatePicker,
  FormItemEditInputNumber,
  FormItemEditSelectSingle,
  FormItemEditSelectSingleAsync,
  FormItemEditTextArea,
  LabelValueMarkup,
  ReferenceStatusTag,
  StripLoader,
} from 'components'
import { RedeliveryNumberForm, ReferenceNumberByTypeProps } from 'modules/references/types'
import { getViewOnlyFields } from 'modules/references/utils/getViewOnlyFields'

import { useUpdateRedeliveryNumber } from './hooks/useUpdateRedeliveryNumber'
import { redeliveryNumberFieldsValidation } from 'modules/references/constants/fieldsValidation'
import { ReferenceItemsEdit } from 'modules/references/elements/ReferenceItemsEdit'

import { RelatedPOs } from '../RelatedPOs'
import { RelatedReferenceNumber } from '../RelatedReferenceNumber'

interface RedeliveryProps extends ReferenceNumberByTypeProps {
  data: ReferenceNumberRedelivery
}

const RedeliveryBase: FC<RedeliveryProps> = ({ canEditOnlyStatus, data }) => {
  const { update, isError, isPending } = useUpdateRedeliveryNumber(data.id)

  const isPurchase = data?.transactionType === ReferenceNumberRedeliveryTransactionType.Purchase
  const isSomeRelatedRecord = !![data?.purchaseOrder, data?.bookingNumber, data?.salesOrder].filter(Boolean).length

  const { Form, triggerSubmit } = useForm<Omit<RedeliveryNumberForm, 'items'>>({
    mode: 'onChange',
    onSubmit: update,
    isSubmitError: isError,
    validationSchema: redeliveryNumberFieldsValidation,
    defaultValues: data,
    viewOnlyFields: canEditOnlyStatus
      ? getViewOnlyFields(ReferenceFormAccessor.Status)
      : [
          ReferenceFormAccessor.CreatedAt,
          ReferenceFormAccessor.TransactionType,
          ReferenceFormAccessor.PurchaseOrderId,
          ReferenceFormAccessor.BookingNumberId,
          ReferenceFormAccessor.LocationCodeId,
          ReferenceFormAccessor.DepotSettingId,
        ],
  })

  const purchaseOrderDefaultOption = data?.purchaseOrder
    ? {
        value: data.purchaseOrder.id,
        label: data.purchaseOrder.number,
      }
    : undefined

  const bookingDefaultOption = data?.bookingNumber
    ? {
        value: data.bookingNumber.id,
        label: data.bookingNumber.number,
      }
    : undefined

  const salesOrderDefaultOption = data?.salesOrder
    ? {
        value: data.salesOrder.id,
        label: data.salesOrder.number,
      }
    : undefined

  return (
    <>
      <Form>
        <StripLoader noBorderRadius isLoading={isPending} />
        <GridLayout columns={3} gap={16}>
          <Container fd="column" width="100%">
            <Typography fontWeight="xl">Redelivery Details</Typography>
            <Container mt={4} gap={4} fd="column">
              <EditFormItem
                name={ReferenceFormAccessor.Status}
                label={referenceLabels.status}
                render={({ field }) => (
                  <FormItemEditSelectSingle
                    {...field}
                    customPreview={
                      <ReferenceStatusTag status={field.value} icon={<Icon icon="arrowDown" size={16} />} />
                    }
                    options={redeliveryStatusOptions}
                    onSubmit={triggerSubmit}
                  />
                )}
              />
              <EditFormItem
                name={ReferenceFormAccessor.TransactionType}
                label={referenceLabels.transactionType}
                render={({ field }) => (
                  <FormItemEditSelectSingle {...field} options={referenceNumberRedeliveryTransactionTypeOptions} />
                )}
              />
              <EditFormItem
                name={ReferenceFormAccessor.CreatedAt}
                label={referenceLabels.createdAt}
                render={({ field }) => <FormItemEditDatePicker {...field} />}
              />
              <LabelValueMarkup
                showTooltip={false}
                label={referenceLabels.locationCodeId}
                typographyPropsValue={data?.locationCode ? undefined : { color: 'secondary' }}
                value={data?.locationCode ? data.locationCode.code : 'Empty'}
                href={data?.locationCode ? `${route.locationCodes}/${data?.locationCode.id}` : undefined}
              />
              <LabelValueMarkup
                showTooltip={false}
                label={referenceLabels.depotSettingId}
                typographyPropsValue={data?.depotSetting ? undefined : { color: 'secondary' }}
                value={data?.depotSetting ? data.depotSetting.code : 'Empty'}
                href={
                  data?.depotSetting
                    ? `${route.vendors}/${data.depotSetting.vendorId}${vendorViewRoute.depot}`
                    : undefined
                }
              />
              <Container fd="column">
                <EditFormItem
                  name={ReferenceFormAccessor.Notes}
                  label={referenceLabels.notes}
                  layout="vertical"
                  render={({ field }) => (
                    <FormItemEditTextArea placeholder="Type notes" {...field} onConfirm={triggerSubmit} />
                  )}
                />
              </Container>
            </Container>
          </Container>
          <Container fd="column" width="100%">
            <Typography fontWeight="xl">Related Records</Typography>
            <Container mt={4} gap={4} fd="column">
              {isSomeRelatedRecord ? (
                <>
                  <FormItemEditSelectSingleAsync
                    name={ReferenceFormAccessor.SalesOrderId}
                    label={referenceLabels.salesOrderId}
                    getItems={useSalesOrders}
                    defaultOption={salesOrderDefaultOption}
                    onSubmit={triggerSubmit}
                    href={route.so}
                  />
                  {isPurchase && (
                    <FormItemEditSelectSingleAsync
                      name={ReferenceFormAccessor.PurchaseOrderId}
                      label={referenceLabels.purchaseOrderId}
                      returnDefaultValueOnBlur={false}
                      href={route.po}
                      getItems={usePOs}
                      defaultOption={purchaseOrderDefaultOption}
                      onSubmit={triggerSubmit}
                    />
                  )}
                  <FormItemEditSelectSingleAsync
                    name={ReferenceFormAccessor.BookingNumberId}
                    label={referenceLabels.bookingNumberId}
                    href={route.reference}
                    getItems={useReferences}
                    defaultOption={bookingDefaultOption}
                  />
                </>
              ) : (
                <Typography color="secondary" fontWeight="l">
                  No Related Records
                </Typography>
              )}
            </Container>
          </Container>
          <Container fd="column" width="100%">
            <Typography fontWeight="xl">Details</Typography>
            <Container mt={4} gap={4} fd="column">
              <EditFormItem
                name={ReferenceFormAccessor.Dpp}
                label={referenceLabels.dpp}
                render={({ field }) => (
                  <FormItemEditInputNumber
                    placeholder="Enter dpp amount"
                    {...field}
                    precision={2}
                    suffix={currency}
                    onBlur={triggerSubmit}
                  />
                )}
              />
            </Container>
          </Container>
        </GridLayout>
      </Form>
      <Spacer mt={9} />
      <ReferenceItemsEdit
        id={data?.id}
        type={data?.type}
        data={data?.items}
        status={data?.status}
        referenceData={{ transactionType: data?.transactionType }}
        isFormViewOnly={canEditOnlyStatus}
      />
      <Container gap={16} mt={4} fd="column">
        {data?.purchaseOrder && <RelatedPOs data={data.purchaseOrder} />}
        {data?.bookingNumber && (
          <RelatedReferenceNumber type={ReferenceNumberType.Booking} data={data.bookingNumber as ReferenceNumber} />
        )}
      </Container>
    </>
  )
}

export const Redelivery = typedMemo(RedeliveryBase)
