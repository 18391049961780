import { Switch as BaseSwitch } from 'antd'
import { styled } from 'styled-components'

export const Switch = styled(BaseSwitch)`
  &&& .ant-switch-inner-unchecked,
  &&& .ant-switch-inner-checked {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`
