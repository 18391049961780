import { FC, forwardRef, Ref } from 'react'
import { RefSelectProps } from 'antd/lib/select'

import { typedMemo } from 'types'

import type { EditFormItemSize, SelectMultiProps } from 'designSystem'

import * as Styled from './styles'

const defaultProps = {
  autoFocus: true,
  defaultOpen: true,
}

export type EditSelectMultiProps = SelectMultiProps & {
  size?: EditFormItemSize
}

const EditSelectMultiBase: FC<EditSelectMultiProps> = forwardRef((props, ref: Ref<RefSelectProps>) => {
  return <Styled.EditSelectStyled {...props} isEdit ref={ref} />
})

EditSelectMultiBase.defaultProps = defaultProps

export const EditSelectMulti = typedMemo(EditSelectMultiBase)
