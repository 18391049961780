import { Role } from 'api/types/role'

export interface RoleOption {
  label: string
  value: number
}

export const getRolesOptions = (roles: Role[] | undefined): RoleOption[] | undefined => {
  return roles?.map((role) => ({
    label: role.name,
    value: role.id,
  }))
}
