import { css, styled } from 'styled-components'

import { SelectStyle } from './types/selectStyle'

import type { SelectMultiProps } from './SelectMulti'

export interface SelectWrapperProps {
  width?: number
  selectStyle?: SelectStyle
  isOptionSelected?: boolean
  isFocused?: boolean
  label?: string
  isEdit?: boolean
}

export const SelectWrapper = styled.div<SelectWrapperProps>`
  position: relative;
  display: flex;
  width: 100%;

  .ant-select-dropdown {
    width: ${({ width }) => (width ? `${width}px` : '100%')} !important;
  }

  &&&& .ant-select-selector {
    padding-right: 28px; // style to not overlap icon
  }

  && .ant-select-selection-overflow {
    .ant-checkbox {
      display: none;
    }
  }

  .ant-select-item-option-state {
    display: none;
  }
  &&& .ant-select-selection-search {
    inset-inline-start: ${({ theme }) => theme.spacers[1]};
  }
  &&&&& .ant-select-selection-search-input {
    font-size: ${({ theme }) => theme.fontSize.xs};
    line-height: ${({ theme }) => theme.lineHeight.s};
    font-weight: ${({ theme }) => theme.fontWeight.l};
  }
  &&& .ant-select {
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    font-weight: ${({ theme }) => theme.fontWeight.l};

    &:hover,
    &:active,
    &:focus {
      & .ant-select-selector {
        box-shadow: ${({ theme }) => theme.boxShadow.input};
        border-color: ${({ theme }) => theme.colors.accent[400]};
      }
    }
  }

  &&& .ant-select-selector {
    padding-left: ${({ theme }) => theme.spacers[3]};
    padding-right: ${({ theme }) => theme.spacers[3]};

    ${({ isFocused, isOptionSelected, theme, label }) => {
      if ((isFocused || isOptionSelected) && label) {
        return `padding-top:${theme.spacers[8]};`
      }
    }}
  }
  & .ant-select-arrow {
    transition: transform 0.3s;
  }
  & .ant-select-open .ant-select-arrow {
    transform: rotate(180deg);
  }
  &&& .ant-select-disabled .ant-select-selector {
    color: ${({ theme }) => theme.colors.primary};
  }
  & .ant-select-item-option-active {
    background-color: ${({ theme }) => theme.colors.underline};
  }
  &&&& .ant-select-item-option-selected {
    background-color: transparent;
    &:hover {
      background-color: ${({ theme }) => theme.colors.underline};
    }
  }

  ${({ selectStyle, theme, isOptionSelected }) => {
    if (selectStyle === 'filled') {
      return css`
        && .ant-select-selector {
          background-color: ${theme.colors.underline};
          border-color: transparent;
        }
      `
    }
    if (selectStyle === 'outline' && isOptionSelected) {
      return css`
        && .ant-select-selector {
          border-color: ${theme.colors.primary};
        }
      `
    }
  }}
  & .ant-select-selection-placeholder {
    font-weight: ${({ theme }) => theme.fontWeight.l};
    font-size: ${({ theme }) => theme.fontSize.xs};
    line-height: ${({ theme }) => theme.lineHeight.s};
  }
  & .ant-select-multiple {
    .ant-select-selection-placeholder {
      inset-inline-start: ${({ theme }) => theme.spacers[3]};
    }
    .ant-select-selection-search {
      margin-inline-start: ${({ theme }) => theme.spacers[2]};
    }
  }

  &&&& .ant-select-single .ant-select-selection-search {
    inset-inline-start: ${({ theme }) => theme.spacers[3]};
    inset-inline-end: ${({ theme }) => theme.spacers[8]};
  }
  &&& .ant-select-multiple .ant-select-selection-search {
    margin: 0;
    inset-inline-start: 0;
  }

  & .ant-select-clear {
    display: flex;
    width: 14px;
    height: 14px;
    margin-top: 0;
    transform: translateY(-50%);
    border-radius: 50%;
  }
`
export const PrefixIcon = styled.span<Pick<SelectMultiProps, 'size'>>`
  position: absolute;
  display: flex;
  left: ${({ theme, size }) => (size === 'small' ? theme.spacers[2] : theme.spacers[3])};
  z-index: ${({ theme }) => theme.zIndexLevel[1]};
  top: 50%;
  transform: translateY(-50%);
`
export const Option = styled.div<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => disabled && 0.5};
  padding: ${({ theme }) => `${theme.spacers[2]} ${theme.spacers[3]}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-width: 0;
`

export const LabelWrapper = styled.span`
  position: absolute;
  pointer-events: none;
  top: ${({ theme }) => theme.spacers[3]};
  left: ${({ theme }) => theme.spacers[3]};
  z-index: ${({ theme }) => theme.zIndexLevel[1]};
  display: flex;
  justify-content: space-between;
`

export const LabelItem = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacers[1]};
`

export const Prefix = styled.span`
  pointer-events: auto;
  display: flex;
`
