export const toolbarItems = [
  'heading',
  '|',
  'fontFamily',
  'fontSize',
  'fontColor',
  'fontBackgroundColor',
  '|',
  'bold',
  'italic',
  'underline',
  'strikethrough',
  '|',
  'link',
  'insertTable',
  'blockQuote',
  '|',
  'alignment',
  '|',
  'bulletedList',
  'numberedList',
  '|',
  'outdent',
  'indent',
  '|',
  'undo',
  'redo',
]
