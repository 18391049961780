import { VendorType } from 'api'
import { vendorPartsRoute } from 'constant'
import { AppModule } from 'types'

import { Tab } from 'designSystem'

import { Inventory } from '../../../elements/Inventory'

import { PartsDetails } from '../routes/PartsDetails'

export const partTabs: Tab[] = [
  {
    key: vendorPartsRoute.details,
    label: 'General Info',
    children: <PartsDetails />,
  },
  {
    key: vendorPartsRoute.inventory,
    label: 'Inventory',
    children: <Inventory type={VendorType.Parts} module={AppModule.VendorPartsInventory} />,
  },
]
