import type { ThemeConfig } from 'antd'
import type { DefaultTheme } from 'styled-components'

import { ZIndexLevel } from 'types'

import type { FontSize, FontWeight, LineHeight, SpacerLevel } from 'designSystem'

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      white: '#ffffff'
      primary: '#172B4D'
      primaryTone: '#2E405F'
      secondary: '#737F94'
      placeholder: '#B9BFC9'
      underline: '#F4F5F7'
      lines: '#DFE1E6'
      transparent: 'transparent'
      accent: {
        80: '#DCEDFF'
        90: '#B1D8FF'
        100: '#7BBDFF'
        200: '#4399EF'
        300: '#0E7AE7'
        400: '#2151C5'
      }
      error: {
        80: '#FFE3DB'
        90: '#FFCBBE'
        100: '#FFAA96'
        200: '#FF8568'
        300: '#FF5E37'
        400: '#ED3C11'
      }
      warning: {
        80: '#FFF8DB'
        90: '#FFF2BD'
        100: '#FFEA95'
        200: '#FFE479'
        300: '#FFDB4E'
        400: '#F8C500'
      }
      success: {
        80: '#DAFFED'
        90: '#A9FFD6'
        100: '#71FFBB'
        200: '#48EF9E'
        300: '#18D47A'
        400: '#07BD66'
      }
      contrast: {
        80: '#DED2FF'
        90: '#C0A8FF'
        100: '#9D78FF'
        200: '#7C4BFF'
        300: '#6638E0'
        400: '#4B25B1'
      }
    }
    fontFamily: Record<string, string>
    fontSize: Record<FontSize, string>
    lineHeight: Record<LineHeight, string>
    spacers: Record<SpacerLevel, string>
    fontWeight: Record<FontWeight, number>
    borderRadius: Record<'small' | 'medium' | 'extraSmall', string>
    zIndexLevel: Record<ZIndexLevel, number>
    inlineEdit: {
      size: {
        s: string
        m: string
        l: string
      }
    }
    boxShadow: {
      input: string
    }
  }
}

const theme: DefaultTheme = {
  colors: {
    white: '#ffffff',
    primary: '#172B4D',
    primaryTone: '#2E405F',
    secondary: '#737F94',
    placeholder: '#B9BFC9',
    underline: '#F4F5F7',
    lines: '#DFE1E6',
    transparent: 'transparent',
    accent: {
      80: '#DCEDFF',
      90: '#B1D8FF',
      100: '#7BBDFF',
      200: '#4399EF',
      300: '#0E7AE7',
      400: '#2151C5',
    },
    error: {
      80: '#FFE3DB',
      90: '#FFCBBE',
      100: '#FFAA96',
      200: '#FF8568',
      300: '#FF5E37',
      400: '#ED3C11',
    },
    warning: {
      80: '#FFF8DB',
      90: '#FFF2BD',
      100: '#FFEA95',
      200: '#FFE479',
      300: '#FFDB4E',
      400: '#F8C500',
    },
    success: {
      80: '#DAFFED',
      90: '#A9FFD6',
      100: '#71FFBB',
      200: '#48EF9E',
      300: '#18D47A',
      400: '#07BD66',
    },
    contrast: {
      80: '#DED2FF',
      90: '#C0A8FF',
      100: '#9D78FF',
      200: '#7C4BFF',
      300: '#6638E0',
      400: '#4B25B1',
    },
  },
  fontFamily: {
    primary: 'Inter',
  },
  fontSize: {
    xs: '12px',
    s: '14px',
    m: '16px',
    l: '18px',
    xl: '24px',
    xxl: '32px',
  },
  fontWeight: {
    s: 300,
    m: 400,
    l: 500,
    xl: 700,
  },
  boxShadow: {
    input: '0 0 0 2px rgba(33, 81, 197, 0.25)',
  },
  lineHeight: {
    s: '16px',
    m: '24px',
    l: '32px',
    xl: '40px',
  },
  spacers: {
    '0': '0px',
    '1': '2px',
    '2': '4px',
    '3': '8px',
    '4': '16px',
    '5': '20px',
    '6': '22px',
    '7': '24px',
    '8': '28px',
    '9': '32px',
    '10': '40px',
    '11': '48px',
    '12': '56px',
    '13': '64px',
    '14': '100px',
  },
  borderRadius: {
    extraSmall: '2px',
    small: '6px',
    medium: '8px',
  },
  inlineEdit: {
    size: {
      s: '20px',
      m: '28px',
      l: ' 32px',
    },
  },
  zIndexLevel: {
    1: 1,
    2: 2,
    tabs: 5,
    modal: 1051,
    googleMapsDropdown: 1051,
    notifications: 1052,
    selectDropdown: 1052,
  },
}

const baseFieldConfig = {
  colorBgContainer: theme.colors.white,
  colorTextDisabled: theme.colors.placeholder,
  colorBgContainerDisabled: theme.colors.underline,
  colorErrorBorderHover: theme.colors.error[400],
  colorError: theme.colors.error[400],
  colorText: theme.colors.primary,
  colorBorder: theme.colors.lines,
  colorTextPlaceholder: theme.colors.placeholder,
  fontSize: parseInt(theme.fontSize.xs),
  lineHeight: 1.5,
  controlHeight: parseInt(theme.spacers[9]),
}

export const configProviderSetup: ThemeConfig = {
  components: {
    Button: {
      controlHeight: parseInt(theme.spacers[10]),
      controlHeightSM: parseInt(theme.spacers[9]),
      fontSize: parseInt(theme.fontSize.s),
      fontSizeSM: parseInt(theme.fontSize.xs),
      borderRadius: parseInt(theme.borderRadius.small),
      borderRadiusSM: parseInt(theme.borderRadius.small),
      colorPrimaryBg: theme.colors.accent[100],
      colorLink: theme.colors.accent[400],
      colorLinkHover: theme.colors.accent[400],
      colorLinkActive: theme.colors.accent[400],
      colorBgContainer: theme.colors.white,
      colorText: theme.colors.primary,
      colorBorder: theme.colors.lines,
      colorBgTextHover: theme.colors.accent[100],
      colorInfoHover: theme.colors.accent[100],
      colorBgContainerDisabled: theme.colors.lines,
      colorTextDisabled: theme.colors.placeholder,
      boxShadow: 'none',
      paddingContentHorizontal: parseInt(theme.spacers[9]),
      paddingContentVertical: parseInt(theme.spacers[3]),
    },
    Input: baseFieldConfig,
    Select: { ...baseFieldConfig, zIndexPopup: theme.zIndexLevel.selectDropdown },
    Radio: {
      colorText: theme.colors.primary,
      fontSize: parseInt(theme.fontSize.xs),
      colorPrimaryHover: theme.colors.lines,
      colorTextDisabled: theme.colors.placeholder,
      colorPrimary: theme.colors.white,
      colorBgContainer: theme.colors.white,
      colorWhite: theme.colors.primary,
      colorBorder: theme.colors.lines,
      colorPrimaryActive: theme.colors.primary,
    },
    Checkbox: {
      lineHeight: 1.5,
      colorPrimary: theme.colors.accent[400],
      colorPrimaryHover: theme.colors.accent[400],
      colorText: theme.colors.primary,
      colorTextDisabled: theme.colors.placeholder,
      colorBgContainerDisabled: theme.colors.lines,
      colorBgContainer: theme.colors.white,
      colorBorder: theme.colors.lines,
      borderRadiusSM: parseInt(theme.borderRadius.extraSmall),
    },
  },

  token: {
    colorPrimary: theme.colors.accent[400],
    colorPrimaryHover: theme.colors.accent[400],
    colorPrimaryActive: theme.colors.accent[200],
    borderRadius: parseInt(theme.borderRadius.small),
    fontFamily: theme.fontFamily.primary,
    fontSize: parseInt(theme.fontSize.xs),
  },
}

export default theme
