import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { hashRoute } from 'constant'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { SalesOrderCreatePayload } from '../types/salesOrderCreateForm'

import { postSalesOrder } from '../queryApi'

export const useCreateSalesOrder = () => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const navigate = useNavigate()

  const { mutate, ...rest } = useMutation({
    mutationFn: postSalesOrder,
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.SalesOrderCreated)
      queryClient.setQueryData([QueryKey.GetSalesOrderById, data.id], data)
      showNotification({ title: 'SO created', type: 'success' })
      navigate(hashRoute.so(data.id))
    },
  })

  const create = useCallback(
    (data: SalesOrderCreatePayload) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { contactsIds, contacts, onSiteContactsIds, onSiteContacts, ...rest } = data
      const contactsWithCustomerIds = contacts?.map(({ id, ...rest }) => ({ ...rest, customerContactId: id }))
      const onSiteContactsWithCustomerIds = onSiteContacts?.map(({ id, ...rest }) => ({
        ...rest,
        customerContactId: id,
      }))
      mutate({ ...rest, contacts: contactsWithCustomerIds, onSiteContacts: onSiteContactsWithCustomerIds })
    },
    [mutate],
  )

  return {
    create,
    ...rest,
  }
}
