export const purchaseOrderJoinParams = [
  'locationCode||id,code',
  'warehouse',
  'contacts',
  'depot||id,code,redeliveryEmails',
  'vendorReleaseReferenceNumber||id,number,status',
  'redeliveryReferenceNumber||id,number,status',
  'salesOrder||id,number',
  'createdBy||id,firstName,lastName',
]
