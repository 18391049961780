import { css, styled } from 'styled-components'

import { Button as BaseButton, Container as BaseContainer, Icon as BaseIcon } from 'designSystem'

import type { EditableTableActionsProps } from './EditableTableActions'

export const Container = styled(BaseContainer)<Pick<EditableTableActionsProps, 'size'>>`
  ${({ size }) => {
    return (
      size === 'small' &&
      css`
        &&&&&& .ant-btn {
          height: 26px;
          min-height: 26px;
        }
        &&&&&& .ant-btn-icon-only {
          width: 26px;
        }
      `
    )
  }}
`

export const Icon = styled(BaseIcon)`
  display: flex;
`

export const Button = styled(BaseButton)`
  &&& .ant-btn-icon {
    margin-inline-end: 0px;
  }
`
