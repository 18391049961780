import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { Spinner, ZohoIntegration } from 'components'

import { useGetCustomerById } from '../CustomerDetails/hooks/useGetCustomerById'

export const CustomerIntegrations: FC = () => {
  const { id } = useParams()

  const { data, isLoading } = useGetCustomerById(Number(id))

  if (isLoading) {
    return <Spinner />
  }

  return <ZohoIntegration module="zohoCustomersLink" id={data?.zohoId} />
}
