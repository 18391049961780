import { FC } from 'react'

import { PurchaseOrder } from 'api'

import { Container, Typography } from 'designSystem'

import { maxContactsCount } from 'modules/purchaseOrders/constants/maxRecordsCount'
import { AddPOContacts } from 'modules/purchaseOrders/elements/AddPOContacts'

export type PurchaseOrderTitleProps = Pick<PurchaseOrder, 'id' | 'number' | 'contacts' | 'vendorId'>

export const PurchaseOrderTitle: FC<PurchaseOrderTitleProps> = ({ id, number, contacts, vendorId }) => {
  const showAddContactButton = contacts.length < maxContactsCount
  return (
    <Container ai="center" jc="space-between">
      <Typography size="l" fontWeight="xl">
        {number}
      </Typography>
      {showAddContactButton && <AddPOContacts id={id} contacts={contacts} vendorId={vendorId} />}
    </Container>
  )
}
