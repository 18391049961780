import { apiInstance, ApiInstanceResponse, EntityId, PurchaseOrder } from 'api'

import { purchaseOrderJoinParams } from 'modules/purchaseOrders/constants/purchaseOrderJoinParams'
import { PurchaseOrderDetailsPayload } from './types/purchaseOrderDetailsPayload'

interface PatchPurchaseOrderParams {
  id: EntityId
  data: PurchaseOrderDetailsPayload
}

export const patchPurchaseOrder = ({
  id,
  data,
}: PatchPurchaseOrderParams): Promise<ApiInstanceResponse<PurchaseOrder>> => {
  return apiInstance({
    method: 'patch',
    url: `/api/v1/purchase-orders/${id}`,
    data,
    params: {
      join: purchaseOrderJoinParams,
    },
  })
}
