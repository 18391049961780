import { FC, useCallback, useState } from 'react'

import { LocationCode, VendorDepotSettings } from 'api'
import { useDepotCodes, useFormContext, useLocationCodes, useWatch } from 'hooks'
import { assetLabels, route } from 'constant'
import { typedMemo } from 'types'

import { Container } from 'designSystem'

import { ConfirmationPopover } from '../../Popover'
import { FormItemEditSelectSingleAsync } from '../FormItemEditSelectSingleAsync'

interface FormItemsEditLocationDepotProps {
  locationFieldName: string
  depotFieldName: string
  locationCode?: LocationCode | null
  depotCode?: VendorDepotSettings | null
}

const FormItemsEditLocationDepotBase: FC<FormItemsEditLocationDepotProps> = ({
  locationFieldName,
  depotFieldName,
  locationCode,
  depotCode,
}) => {
  const [open, setOpen] = useState(false)
  const { triggerSubmit, setValue } = useFormContext()
  const locationCodeId = useWatch({ name: locationFieldName })

  const handleOpenConfirmation = useCallback(
    (newLocationCodeId?: string | number | null) => {
      if (depotCode?.id && newLocationCodeId && newLocationCodeId !== locationCode?.id) {
        setOpen(true)
      } else {
        triggerSubmit?.()
      }
    },
    [depotCode?.id, locationCode?.id, triggerSubmit],
  )

  const handleCancelConfirmation = useCallback(() => {
    setOpen(false)
    setValue(locationFieldName, locationCode?.id)
  }, [setValue, locationCode?.id, locationFieldName])

  const handleSubmitConfirmation = useCallback(() => {
    setValue(depotFieldName, null)
    setOpen(false)
    triggerSubmit?.()
  }, [triggerSubmit, setValue, depotFieldName])

  return (
    <Container fd="column" gap={4}>
      <FormItemEditSelectSingleAsync
        name="locationCodeId"
        label={assetLabels.locationCodeId}
        href={route.locationCodes}
        onSubmit={handleOpenConfirmation}
        defaultOption={
          locationCode && !open
            ? {
                value: locationCode.id,
                label: locationCode.code,
              }
            : undefined
        }
        getItems={useLocationCodes}
      />
      <FormItemEditSelectSingleAsync
        name="vendorDepotId"
        label={assetLabels.vendorDepotId}
        onSubmit={triggerSubmit}
        defaultOption={
          depotCode
            ? {
                value: depotCode.id,
                label: depotCode.code,
              }
            : undefined
        }
        disabled={!locationCodeId}
        getItems={useDepotCodes}
        queryParams={{
          ...(locationCodeId && { locationCodeId: { $eq: locationCodeId } }),
        }}
      />
      <ConfirmationPopover
        open={open}
        onCancel={handleCancelConfirmation}
        onOpenChange={handleCancelConfirmation}
        onOk={handleSubmitConfirmation}
        text="Are you sure you want to make this change and remove Depot Code?"
      />
    </Container>
  )
}

export const FormItemsEditLocationDepot = typedMemo(FormItemsEditLocationDepotBase)
