import * as Yup from 'yup'

import { numberBasicValidation, resaleMarginValidation, skuValidation } from 'utils'

export const modificationSchema = Yup.object({
  name: Yup.string().max(100, 'Max length of 100 characters').required('Title is required'),
  price: numberBasicValidation.nullable(),
  resaleMargin: resaleMarginValidation,
  sku: skuValidation,
})

export const validationSchema = (isAddNew?: boolean) =>
  Yup.object({
    item: isAddNew ? modificationSchema : Yup.object(),
    list: Yup.array(modificationSchema),
  })
