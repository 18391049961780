import { Accessor } from '../types/accessor'

export const getAccessorTitle = (accessor: Accessor): string => {
  return {
    [Accessor.Index]: '#',
    [Accessor.RepairIssued]: 'Repair Issued',
    [Accessor.Id]: 'ID',
    [Accessor.Description]: 'Description',
    [Accessor.Cost]: 'Cost',
    [Accessor.RepairStatus]: 'Repair Status',
    [Accessor.Actions]: '',
  }[accessor]
}
