import * as Yup from 'yup'

import {
  VendorCarrierSettings,
  VendorContainerSettings,
  VendorDepotSettings,
  VendorModificationsSettings,
  VendorPartsSettings,
  VendorType,
} from 'api'

import {
  vendorCarrierSettingsSchema,
  vendorContactsSchema,
  vendorContainerSettingsSchema,
  vendorDepotSettingsSchema,
  vendorModificationsSettingsSchema,
  vendorPartsSettingsSchema,
  vendorSchema,
} from '../../../constants/vendorValidation'

export const validationSchema = Yup.object({
  ...vendorSchema,
  ...vendorContactsSchema,
  locationCodeIds: Yup.array(Yup.number().required('Location code required')),
  modificationsSettings: Yup.mixed<VendorModificationsSettings>()
    .when('type', {
      is: (type: VendorType) => type.includes(VendorType.Modifications),
      then: () => Yup.object(vendorModificationsSettingsSchema),
    })
    .default(undefined),
  containerSupplierSettings: Yup.mixed<VendorContainerSettings>()
    .when('type', {
      is: (type: VendorType) => type.includes(VendorType.ContainerVendor),
      then: () => Yup.object(vendorContainerSettingsSchema),
    })
    .default(undefined),
  carrierSettings: Yup.mixed<VendorCarrierSettings>()
    .when('type', {
      is: (type: VendorType) => type.includes(VendorType.Carrier),
      then: () => Yup.object(vendorCarrierSettingsSchema),
    })
    .default(undefined),
  depotSettings: Yup.mixed<VendorDepotSettings>()
    .when('type', {
      is: (type: VendorType) => type.includes(VendorType.Depot),
      then: () => Yup.object(vendorDepotSettingsSchema),
    })
    .default(undefined),
  partSettings: Yup.mixed<VendorPartsSettings>()
    .when('type', {
      is: (type: VendorType) => type.includes(VendorType.Parts),
      then: () => Yup.object(vendorPartsSettingsSchema),
    })
    .default(undefined),
})
