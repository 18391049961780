import { useQuery } from '@tanstack/react-query'

import { EntityId } from 'api'
import { QueryKey } from 'enums'

import { getCarrierByVendorId } from '../queryApi'

export const useGetCarrierByVendorId = (id?: EntityId) => {
  const { data, ...rest } = useQuery({
    queryKey: [QueryKey.GetCarrierByVendorId, id],
    queryFn: () => getCarrierByVendorId(id),
    enabled: !!id,
  })

  return {
    data,
    ...rest,
  }
}
