import { Dropdown as BaseDropdown } from 'antd'
import { styled } from 'styled-components'

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`

export const Dropdown = styled(BaseDropdown)`
  font-size: ${({ theme }) => theme.fontSize.s};
`
