import { api, LocationCode } from 'api'

import { LocationCodePayload } from '../../types/locationCodePayload'

export type CreateLocationCodePayload = LocationCodePayload

export const postLocationCode = (data: CreateLocationCodePayload): Promise<LocationCode> => {
  return api({
    method: 'post',
    url: '/api/v1/location-codes',
    data,
  })
}
