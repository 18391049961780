export enum SalesOrderStatus {
  New = 'new',
  PendingAccountingApproval = 'pending_accounting_approval',
  PaymentApproved = 'payment_approved',
  WeCarryAssets = 'we_carry_assets',
  AssetProduced = 'asset_produced',
  BookingCreated = 'booking_created',
  ReadyToShipping = 'ready_to_shipping',
  Completed = 'completed',
  Cancelled = 'cancelled',
}
