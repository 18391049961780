import type { PurchaseOrderItemsSubLine } from 'api'

import { assetLabels } from '../asset'

interface PurchaseOrderItemSubLineLabels
  extends Record<keyof Omit<PurchaseOrderItemsSubLine, 'asset' | 'poItem'>, string> {
  asset: typeof assetLabels
}

export const purchaseOrderItemSubLineLabels: PurchaseOrderItemSubLineLabels = {
  id: '',
  expectedDeliveryDate: 'Exp. Delivery',
  assetId: 'Asset Number',
  asset: assetLabels,
  locationCodeId: 'Location Code',
  locationCode: 'Location Code',
  vendorDepotId: 'Vendor Depot',
  vendorDepot: 'Vendor Depot',
  referenceType: 'Reference Type',
}
