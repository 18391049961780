import * as Yup from 'yup'

import { AssetRepairStatus } from 'api'

export const repairsItemSchema = Yup.object({
  cost: Yup.string().max(50, 'Max length of 50 characters').nullable(),
  description: Yup.string().max(150, 'Max length of 150 characters').nullable(),
  status: Yup.mixed<AssetRepairStatus>().oneOf(Object.values(AssetRepairStatus)),
})

export const validationSchema = (isAddNew?: boolean) =>
  Yup.object({
    item: isAddNew ? repairsItemSchema : Yup.object(),
    list: Yup.array(repairsItemSchema),
  })
