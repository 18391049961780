import { ReferenceNumberStatus } from 'api'
import { referenceNumberAllStatusOptions } from 'constant'

import { TagType } from 'designSystem'

interface ReferenceStatusTagData {
  status: TagType
  text?: string
}

export const getTagDataByReferenceStatus = (status: ReferenceNumberStatus): ReferenceStatusTagData => {
  const text = referenceNumberAllStatusOptions.find(({ value }) => value === status)?.label

  const tagStatus = {
    [ReferenceNumberStatus.Open]: TagType.Default,
    [ReferenceNumberStatus.Pending]: TagType.Warning,
    [ReferenceNumberStatus.InProgress]: TagType.InProgress,
    [ReferenceNumberStatus.Closed]: TagType.Success,
    [ReferenceNumberStatus.Voided]: TagType.Error,
    [ReferenceNumberStatus.PendingRedelivery]: TagType.Pending,
    [ReferenceNumberStatus.Received]: TagType.Success,
    [ReferenceNumberStatus.Scheduled]: TagType.Warning,
    [ReferenceNumberStatus.TransitionToCustomer]: TagType.InProgress,
    [ReferenceNumberStatus.TransitionToDepot]: TagType.InProgress,
    [ReferenceNumberStatus.ClosedCustomer]: TagType.Success,
    [ReferenceNumberStatus.ClosedDepot]: TagType.Success,
  }[status]

  return { status: tagStatus, text }
}
