import { Link } from 'react-router-dom'

import { LocationCode } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import type { TableField, TableSortingOrder } from 'hooks'
import { route } from 'constant'

import { Typography } from 'designSystem'
import { TableColumnKey, TableColumnsType } from 'components'

export const getTableColumns = (
  getFieldSorter: (field: TableField) => TableSortingOrder,
  offsetCount: number,
): TableColumnsType<LocationCode> => [
  {
    title: '#',
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => offsetCount + index + 1,
  },
  {
    title: 'Location Code',
    key: 'code',
    dataIndex: 'code',
    sorter: true,
    ellipsis: true,
    width: '50%',
    sortOrder: getFieldSorter('code'),
    render: (value, record) => {
      const onClick = () => {
        queryClient.setQueryData([QueryKey.GetLocationCodeById, record.id], record)
      }

      return (
        <Link to={`${route.locationCodes}/${record.id}`} onClick={onClick}>
          <Typography color="primary" size="xs" fontWeight="l" lineEllipsis={1}>
            {value}
          </Typography>
        </Link>
      )
    },
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    width: '20%',
    ellipsis: true,
  },
]
