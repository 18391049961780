import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  referenceNumber: Yup.string().when('shouldGenerateReferenceNumber', {
    is: (shouldGenerateReferenceNumber: boolean) => !shouldGenerateReferenceNumber,
    then: (schema) => schema.required('Reference number is required'),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  shouldGenerateReferenceNumber: Yup.boolean().default(false).notRequired(),
})
