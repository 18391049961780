import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'antd'

import { Contact, SystemRoles } from 'api'
import { useAssets, useFormContext, useGetCustomerContacts, useUsers, useVendors, useWatch } from 'hooks'
import { currency, referenceLabels, referenceNumberPickupUrgencyOptions } from 'constant'
import { ReferenceFormAccessor } from 'types'

import {
  Container,
  DatePicker,
  FormItem,
  InputNumber,
  SelectMulti,
  SelectMultiAsync,
  SelectSingle,
  Typography,
} from 'designSystem'
import { AddContactModal, FormItemSelectSingleAsync, NewContact } from 'components'
import { maxContactsCount } from 'modules/customers'

interface PickupNumberDetailsProps {
  newContacts: NewContact[]
  setNewContacts: Dispatch<SetStateAction<NewContact[]>>
}

export const PickupNumberDetails: FC<PickupNumberDetailsProps> = ({ newContacts, setNewContacts }) => {
  const { setValue, getValues } = useFormContext()
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false)

  const customerId = useWatch({ name: ReferenceFormAccessor.CustomerId })

  const { data: contacts = [], isLoading } = useGetCustomerContacts(customerId)
  const contactsWithNew = [...newContacts, ...contacts] as Contact[]

  const contactOptions = contactsWithNew.map((contact) => ({
    value: contact.id || contact.email,
    label: `${contact.firstName} ${contact.lastName}`,
  }))

  const toggleContactModal = useCallback(() => {
    setIsAddContactModalOpen((prev) => !prev)
  }, [])

  const handleCreateNewContact = useCallback(
    (payload: NewContact) => {
      setNewContacts((prev) => [...prev, payload])
      setValue(ReferenceFormAccessor.Contacts, [...(getValues(ReferenceFormAccessor.Contacts) || []), payload.email])
      toggleContactModal()
    },
    [toggleContactModal, setValue, getValues, setNewContacts],
  )

  useEffect(() => {
    setValue(
      ReferenceFormAccessor.Contacts,
      // remove only customer contacts(where id is number)
      getValues(ReferenceFormAccessor.Contacts)?.filter((contact: string | number) => typeof contact !== 'number'),
    )
  }, [customerId, setValue, getValues])

  return (
    <Container fd="column" width="100%" gap={16}>
      <Typography size="m" fontWeight="xl">
        Details
      </Typography>
      <Container fd="column" gap={8}>
        <Row>
          <Col span={24}>
            <FormItem name={ReferenceFormAccessor.Urgency} label={referenceLabels.urgency}>
              <SelectSingle options={referenceNumberPickupUrgencyOptions} />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem name={ReferenceFormAccessor.Contacts} label={referenceLabels.contacts}>
              <SelectMulti
                disabled={!customerId}
                withCreateNew={contactsWithNew.length < maxContactsCount}
                onClickCreateNew={toggleContactModal}
                options={contactOptions}
                loading={isLoading}
                placeholder={customerId ? 'Select' : 'Select Customer first'}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem name={ReferenceFormAccessor.AvailableAssetsIds} label={referenceLabels.availableAssets}>
              <SelectMultiAsync
                showSearch
                name={ReferenceFormAccessor.AvailableAssetsIds}
                getItems={useAssets}
                placeholder="Select"
                fullwidth
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItemSelectSingleAsync
              name={ReferenceFormAccessor.VendorId}
              label={referenceLabels.vendorId}
              getItems={useVendors}
              queryParams={{
                type: { $contArr: ['carrier'] },
              }}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <FormItemSelectSingleAsync
              name={ReferenceFormAccessor.DispatcherId}
              label={referenceLabels.dispatcherId}
              getItems={useUsers}
              queryParams={{ 'roles.systemRole': { $eq: SystemRoles.Dispatcher } }}
              join={['roles']}
            />
          </Col>
          <Col span={12}>
            <FormItem name={ReferenceFormAccessor.DispatchedDate} label={referenceLabels.dispatchedDate}>
              <DatePicker />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <FormItem name={ReferenceFormAccessor.TransportationPrice} label={referenceLabels.transportationPrice}>
              <InputNumber prefix={currency} precision={2} placeholder="00.00" />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem name={ReferenceFormAccessor.TransportationCost} label={referenceLabels.transportationCost}>
              <InputNumber prefix={currency} precision={2} placeholder="00.00" />
            </FormItem>
          </Col>
        </Row>
        <AddContactModal
          open={isAddContactModalOpen}
          onCancel={toggleContactModal}
          onSubmit={handleCreateNewContact}
          contacts={contactsWithNew}
        />
      </Container>
    </Container>
  )
}
