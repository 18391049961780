import { Role } from 'api'
import { userStatusOptions } from 'constant'
import { getRolesOptions } from 'utils'

import { TableFilterSelectMulti } from 'components'

export const accessManagementFilterOptions = (roles: Role[] | undefined) => {
  return [
    {
      value: 'roles',
      label: 'Role',
      component: (
        <TableFilterSelectMulti key="roles" name="roles.id" placeholder="Role" options={getRolesOptions(roles)} />
      ),
    },
    {
      value: 'status',
      label: 'Status',
      component: <TableFilterSelectMulti key="status" name="status" placeholder="Status" options={userStatusOptions} />,
    },
  ]
}
