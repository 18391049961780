import { FC, useCallback, useEffect } from 'react'

import { useFieldArray } from 'hooks'
import { typedMemo } from 'types'

import { Button, Container, Icon, Spacer, TitleTag, Typography } from 'designSystem'

import { Card, CardProps } from '../../Card'
import { FieldArrayItem } from '../FieldArrayItem'
import { FormItemAddress } from '../FormItemAddress'

interface FormItemAddressGroupPros extends CardProps {
  name: string
  label: string
  title?: string
  maxCount?: number
}

export const FormItemAddressGroupBase: FC<FormItemAddressGroupPros> = ({
  name,
  label,
  title,
  maxCount = 20,
  ...cardProps
}) => {
  const { fields, append, remove } = useFieldArray({ name })

  const insertField = useCallback(() => {
    append({}, { shouldFocus: false })
  }, [append])

  useEffect(() => {
    // Add one field item by default
    if (!fields.length) {
      insertField()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card {...cardProps}>
      {title && (
        <>
          <Typography fontWeight="xl" size="l">
            {title}
          </Typography>
          <Spacer mb={5} />
        </>
      )}
      <Container fd="column" gap={16}>
        {fields.map((field, index) => (
          <FieldArrayItem key={field.id}>
            <FormItemAddress
              bordered={false}
              pa={0}
              name={`${name}.${index}`}
              title={
                <TitleTag
                  title={`${label} ${index + 1}`}
                  prefix={<Icon icon="address" size={14} />}
                  canDelete={fields.length !== 1}
                  onDelete={() => remove?.(index)}
                />
              }
            />
          </FieldArrayItem>
        ))}
      </Container>
      {(!maxCount || fields.length < maxCount) && (
        <Container mt={3}>
          <Button type="link" icon="plus" onClick={insertField}>
            Add
          </Button>
        </Container>
      )}
    </Card>
  )
}

export const FormItemAddressGroup = typedMemo(FormItemAddressGroupBase)
