import { ReferenceNumberAssetStatus, ReferenceNumberPickupAsset } from 'api'
import { referenceNumberAssetStatusLabel } from 'constant'
import { ReferenceSubLineFormAccessor } from 'types'
import { formatDate } from 'utils'

import type { TableColumnsType } from 'components/Table'
import { getFormattedTableCellValue } from 'components/Table'

import { getSubLineAccessorTitle } from '../../../utils/getSubLineAccessorTitle'

export const decreasePickupAssetsTableColumns: TableColumnsType<Partial<ReferenceNumberPickupAsset>> = [
  {
    title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.Asset),
    key: ReferenceSubLineFormAccessor.Asset,
    dataIndex: ReferenceSubLineFormAccessor.Asset,
    width: 170,
    ellipsis: true,
    render: (value, record) => getFormattedTableCellValue(record.asset?.assetNumber),
  },
  {
    title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.PickupDate),
    key: ReferenceSubLineFormAccessor.PickupDate,
    dataIndex: ReferenceSubLineFormAccessor.PickupDate,
    width: 120,
    ellipsis: true,
    render: (value) => getFormattedTableCellValue(formatDate(value)),
  },
  {
    title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.RedeliveryReferenceId),
    key: ReferenceSubLineFormAccessor.RedeliveryReferenceId,
    dataIndex: ReferenceSubLineFormAccessor.RedeliveryReferenceId,
    width: 120,
    ellipsis: true,
    render: (value, record) => getFormattedTableCellValue(record.redeliveryReference?.number),
  },
  {
    title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.Status),
    key: ReferenceSubLineFormAccessor.Status,
    dataIndex: ReferenceSubLineFormAccessor.Status,
    width: 100,
    ellipsis: true,
    render: (value: ReferenceNumberAssetStatus) => referenceNumberAssetStatusLabel[value],
  },
]
