import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { VendorType } from 'api'

import { Container, Spacer, Typography } from 'designSystem'
import { Card, Spinner } from 'components'
import { useGetPartsByVendorId } from 'modules/vendors/hooks/useGetPartsByVendorId'
import { useUpdatePartsFeed } from 'modules/vendors/hooks/useUpdatePartsFeed'

import { InfoCard } from 'modules/vendors/routes/Vendor/elements/InfoCard'
import { ResalePriceOverwriteRules } from 'modules/vendors/routes/Vendor/elements/ResalePriceOverwriteRules'

export const PartsDetails: FC = () => {
  const { id } = useParams()
  const { data, isLoading: isLoadingParts } = useGetPartsByVendorId({ id: Number(id) })
  const { update, isPending, isError } = useUpdatePartsFeed({ id: Number(id) })

  if (isLoadingParts) {
    return <Spinner />
  }

  if (!data) {
    return null
  }
  return (
    <Container pa={4} fd="column">
      <Typography fontWeight="xl" size="l">
        Parts Info
      </Typography>
      <Spacer mb={4} />
      <Card maxWidth={390}>
        <InfoCard data={data} isLoading={isPending} isError={isError} onSubmit={update} />
      </Card>
      <Spacer mb={7} />
      <ResalePriceOverwriteRules type={VendorType.Parts} />
    </Container>
  )
}
