import { Customer, getCustomers } from 'api'
import { QueryKey } from 'enums'
import { AsyncSelectGetItemsProps } from 'types'

import type { SelectSingleOption } from 'designSystem'

import { useAsyncList } from './useAsyncList'

export const useCustomers = ({
  valuePropName, // keyof Customer - the value of it should be of type string | number
  withFullDataOption,
  fields,
  ...props
}: AsyncSelectGetItemsProps) => {
  const { list, ...asyncListProps } = useAsyncList<Customer>({
    ...props,
    queryFn: getCustomers,
    queryKey: QueryKey.GetCustomersList,
    searchFieldName: 'companyName',
    limit: 10,
    fields: fields || 'id,companyName',
  })

  const options = list.map((customer) => ({
    value: valuePropName ? (customer[valuePropName as keyof Customer] as string | number) : customer.id,
    label: customer.companyName,
    ...(withFullDataOption && { data: customer }),
  })) satisfies SelectSingleOption[]

  return {
    options,
    ...asyncListProps,
  }
}
