import { FC, useCallback } from 'react'
import { Col, Row } from 'antd'

import { useDepotCodes, useFormContext, useLocationCodes, useWatch } from 'hooks'
import { referenceLabels } from 'constant'
import { ReferenceFormAccessor } from 'types'

import { FormItemSelectSingleAsync } from 'components'

export const GateInLocationDepotFields: FC = () => {
  const { setValue } = useFormContext()

  const gateInLocationId = useWatch({ name: ReferenceFormAccessor.GateInLocationCodeId })

  const handleGetInLocationChange = useCallback(() => {
    setValue(ReferenceFormAccessor.GateInDepotSettingId, null)
  }, [setValue])

  return (
    <Row gutter={8}>
      <Col span={12}>
        <FormItemSelectSingleAsync
          name={ReferenceFormAccessor.GateInLocationCodeId}
          label={referenceLabels.gateInLocationCodeId}
          getItems={useLocationCodes}
          onChange={handleGetInLocationChange}
        />
      </Col>
      <Col span={12}>
        <FormItemSelectSingleAsync
          name={ReferenceFormAccessor.GateInDepotSettingId}
          label={referenceLabels.gateInDepotSettingId}
          getItems={useDepotCodes}
          disabled={!gateInLocationId}
          placeholder={gateInLocationId ? undefined : 'Select Gate In Location first'}
          queryParams={{
            ...(gateInLocationId && { [ReferenceFormAccessor.LocationCodeId]: { $eq: gateInLocationId } }),
          }}
        />
      </Col>
    </Row>
  )
}
