import { useCallback, useEffect, useState } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { ApiQueryParams } from 'api'
import { QueryKey } from 'enums'
import { Pagination, QueryParams } from 'types'

import { SelectSingleOption } from 'designSystem'

import { usePagination } from '../usePagination'

interface UseAsyncListResponse<T> extends Pagination {
  data?: T[]
}

interface UseAsyncListProps<T> extends ApiQueryParams {
  id: string
  queryFn: (params?: ApiQueryParams) => Promise<UseAsyncListResponse<T>>
  queryKey: QueryKey
  defaultOption?: SelectSingleOption
  searchFieldName: string | string[]
  queryParams?: QueryParams
  disabled?: boolean
}

export const useAsyncList = <T>({
  id,
  queryFn,
  queryKey,
  defaultOption,
  searchFieldName,
  limit: limitControlled,
  queryParams,
  disabled,
  fields,
  join,
}: UseAsyncListProps<T>) => {
  const [search, setSearch] = useState(defaultOption?.label || '')
  const [list, setList] = useState<T[]>([])
  const { page, limit, onChange } = usePagination({ limit: limitControlled })
  const [enabled, setEnabled] = useState(false)

  const queryEnabled = ((enabled && !search) || (enabled && !!search && search.length > 1)) && !disabled

  // Temporary solution to search by multiple fields
  const searchFields = Array.isArray(searchFieldName)
    ? Object.assign(
        {},
        {
          $or: [
            ...searchFieldName.map((searchField) => ({
              $or: search
                .split(' ')
                .filter(Boolean)
                .map((phrase) => ({ [searchField]: { $contL: phrase?.trim() } })),
            })),
          ],
        },
      )
    : { [searchFieldName]: { $contL: search?.trim() } }

  const sortFields = Array.isArray(searchFieldName)
    ? [...searchFieldName.map((searchField) => `${searchField},ASC`)]
    : [`${searchFieldName},ASC`]

  const { data, isLoading, isFetching, isSuccess, ...rest } = useQuery({
    // Should be unique query key if we use hook more than once in the page
    queryKey: [`${queryKey}-${id}`, { page, limit, search, queryParams }],
    queryFn: () =>
      queryFn({
        page,
        limit,
        s:
          search || queryParams
            ? JSON.stringify({
                ...(queryParams && queryParams),
                ...(search && searchFields),
              })
            : undefined,
        sort: sortFields,
        fields,
        join,
      }),
    enabled: queryEnabled,
    gcTime: 0,
    staleTime: 0,
    placeholderData: keepPreviousData,
  })

  const onPopupScrollEnd = useCallback(() => {
    if (data && data.page < (data?.pageCount || 1)) {
      onChange(page + 1)
    }
  }, [data, page, onChange])

  const handleSearch = useCallback(
    (value?: string) => {
      onChange(1)
      setSearch(value || '')
    },
    [onChange],
  )

  useEffect(() => {
    if (isSuccess && enabled && !isFetching) {
      if (data?.page === 1) {
        setList(data.data || [])
      } else {
        setList((prev) => [...prev, ...(data?.data || [])])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching])

  useEffect(() => {
    if (!enabled) {
      setList([])
      onChange(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled])

  return {
    data,
    search,
    list,
    onPopupScrollEnd,
    onSearch: handleSearch,
    onChangeEnabled: setEnabled,
    isLoading: queryEnabled && (isLoading || isFetching),
    ...rest,
  }
}
