import { VendorModificationsSettings } from 'api'
import { useEditableTable, useTable } from 'hooks'

import { useGetModifications } from './useGetModifications'

export const useModifications = (vendorId: number) => {
  const { pagination, onChange, getFieldSorter, sortQueryParams, searchQueryParams, ...props } = useTable<
    Partial<VendorModificationsSettings>
  >({})

  const {
    data = [],
    total,
    isLoading,
    isFetching,
  } = useGetModifications({
    vendorId,
    limit: pagination.limit,
    page: pagination.page,
    sort: sortQueryParams,
    s: searchQueryParams,
  })

  const editableTable = useEditableTable<VendorModificationsSettings>({
    data,
  })

  return {
    ...editableTable,
    data: data,
    pagination: {
      ...pagination,
      current: pagination.page,
      pageSize: pagination.limit,
      total,
    },
    getFieldSorter,
    onTableChange: onChange,
    isLoading: isLoading || isFetching,
    offsetCount: (pagination.page - 1) * pagination.limit,
    ...props,
  }
}
