import { FC, useCallback } from 'react'

import { SendEmailAction, typedMemo } from 'types'
import { useRemoveSendEmailAction, useSendEmailActions } from 'store'

import { PurchaseOrderModals } from './elements/PurchaseOrderModals'

export const AppModalsBase: FC = () => {
  const sendEmailActions = useSendEmailActions()
  const removeSendEmailAction = useRemoveSendEmailAction()

  const handleRemoveAction = useCallback(
    (type: SendEmailAction) => () => {
      removeSendEmailAction(type)
    },
    [removeSendEmailAction],
  )

  if (!sendEmailActions?.length) {
    return null
  }

  const poModals = sendEmailActions.filter((action) =>
    [SendEmailAction.PurchaseOrderCreate, SendEmailAction.PurchaseOrderUpdate].includes(action),
  )

  return (
    <>
      {poModals.map((action) => (
        <PurchaseOrderModals key={action} action={action} removeAction={handleRemoveAction(action)} />
      ))}
    </>
  )
}

export const AppModals = typedMemo(AppModalsBase)
