import { FC, useEffect } from 'react'
import { Col, Row } from 'antd'

import { useFormContext } from 'hooks'
import { currency } from 'constant'

import { Container, FormItem, Input, InputNumber, Spacer, Switch, TextArea, Typography } from 'designSystem'
import { Card, FormItemAddressGroup } from 'components'

import { maxUccAddressesCount } from 'modules/customers/constants/maxRecordsCount'

export const UccFields: FC = () => {
  const { watch, unregister } = useFormContext()
  const isUcc = watch('isUcc')

  useEffect(() => {
    if (!isUcc) {
      unregister('ucc')
    }
  }, [isUcc, unregister])

  return (
    <Card>
      <Container ai="center" jc="space-between">
        <Typography fontWeight="xl" size="l">
          UCC Lien
        </Typography>
        <FormItem name="isUcc">
          <Switch size="default" />
        </FormItem>
      </Container>
      {isUcc && (
        <Container fd="column" mt={4} gap={16}>
          <Row>
            <Col span={10}>
              <FormItem name="ucc.balance" label="UCC Lien Balance">
                <InputNumber placeholder="00.00" precision={2} prefix={currency} />
              </FormItem>
            </Col>
          </Row>
          <Typography size="s" fontWeight="xl">
            UCC Lien Forwarding Funds Details
          </Typography>
          <Container fd="column">
            <Row gutter={8}>
              <Col span={12}>
                <FormItem name="ucc.name" label="Full Name">
                  <Input placeholder="Enter Full Name" />
                </FormItem>
              </Col>
            </Row>
            <Spacer mb={3} />
            <FormItem name="ucc.email" label="Email">
              <Input placeholder="Enter Email" />
            </FormItem>
            <Spacer mb={3} />
            <FormItem name="ucc.details" label="Details">
              <TextArea size="small" placeholder="Enter Details" autoSize={{ minRows: 5 }} />
            </FormItem>
          </Container>
          <FormItemAddressGroup
            name="ucc.addresses"
            label="UCC Address"
            title="Ucc Addresses"
            maxCount={maxUccAddressesCount}
            pa={0}
            bordered={false}
          />
        </Container>
      )}
    </Card>
  )
}
