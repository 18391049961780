import { api, apiInstance, ApiInstanceResponse, EntityId, PurchaseOrderItem } from 'api'

import { purchaseOrderItemsParams } from 'modules/purchaseOrders/constants/purchaseOrderItemsParams'
import { PurchaseOrderItemsPayload } from './types/purchaseOrderItemsForm'
import { DeleteSubLinesPayload } from 'modules/purchaseOrders/routes/PurchaseOrder/elements/PurchaseOrderTabs/elements/PurchaseOrderDetails/elements/PurchaseOrderDetailsItems/types/DeleteSubLinesPayload'

export interface GetPurchaseOrderItemsPayload {
  purchaseOrderId: EntityId
}

export const getPurchaseOrderItems = ({
  purchaseOrderId,
}: {
  purchaseOrderId: EntityId
}): Promise<PurchaseOrderItem[]> => {
  return api({
    method: 'get',
    url: `/api/v1/purchase-orders/${purchaseOrderId}/items`,
    params: purchaseOrderItemsParams,
  })
}

export const deletePurchaseOrderItem = ({
  purchaseOrderId,
  itemId,
}: {
  purchaseOrderId?: EntityId
  itemId?: EntityId
}): Promise<ApiInstanceResponse<undefined>> => {
  return apiInstance({
    method: 'delete',
    url: `/api/v1/purchase-orders/${purchaseOrderId}/items/${itemId}`,
  })
}

export const postPurchaseOrderItem = ({
  purchaseOrderId,
  data,
}: {
  purchaseOrderId?: EntityId
  data?: PurchaseOrderItemsPayload
}): Promise<ApiInstanceResponse<PurchaseOrderItem>> => {
  return apiInstance({
    method: 'post',
    url: `/api/v1/purchase-orders/${purchaseOrderId}/items`,
    data,
    params: purchaseOrderItemsParams,
  })
}

export const deletePurchaseOrderSubLine = ({
  purchaseOrderId,
  itemId,
  subLineId,
}: {
  purchaseOrderId?: EntityId
  itemId?: EntityId
  subLineId?: EntityId
}): Promise<ApiInstanceResponse<undefined>> => {
  return apiInstance({
    method: 'delete',
    url: `/api/v1/purchase-orders/${purchaseOrderId}/items/${itemId}/sub-lines/${subLineId}`,
  })
}

export const deleteSubLines = ({
  purchaseOrderId,
  itemId,
  data,
}: {
  purchaseOrderId?: EntityId
  itemId?: EntityId
  data: DeleteSubLinesPayload
}): Promise<ApiInstanceResponse<PurchaseOrderItem>> => {
  return apiInstance({
    method: 'patch',
    url: `/api/v1/purchase-orders/${purchaseOrderId}/items/${itemId}`,
    data,
    params: purchaseOrderItemsParams,
  })
}
