import { Upload } from 'antd'
import { css, styled } from 'styled-components'

import { Container } from '../Container'
import type { UploadProps } from './Upload'

interface DraggerProps extends Omit<UploadProps, 'onChange'> {
  hideUpload: boolean
}

export const Dragger = styled(Upload.Dragger)<DraggerProps>`
  &&& .ant-upload {
    background: transparent;
    outline: none;

    ${({ hideUpload }) =>
      hideUpload &&
      css`
        display: none;
      `}
  }
  && .ant-upload-drag {
    border: 1px dashed ${({ error, theme }) => (error ? theme.colors.error[400] : theme.colors.lines)};
    transition: border-color 0.3s;

    &:hover {
      border: 1px dashed ${({ error, theme }) => (error ? theme.colors.error[400] : theme.colors.accent[400])};
    }
  }
`

export const UploadFileRow = styled(Container)`
  border-top: 1px solid ${({ theme }) => theme.colors.lines};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lines};
`
