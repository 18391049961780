import { Link } from 'react-router-dom'

import { Address, Customer } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import type { TableField, TableSortingOrder } from 'hooks'
import { route } from 'constant'
import { getFormattedPriceWithCurrency } from 'utils'

import { Typography } from 'designSystem'
import { CustomerStatusTag, TableColumnKey, TableColumnsType } from 'components'

import { Accessor } from '../types/accessor'

import { getAccessorTitle } from './getAccessorTitle'

export const getTableColumns = (
  getFieldSorter: (field: TableField) => TableSortingOrder,
  offsetCount: number,
): TableColumnsType<Customer> => [
  {
    title: getAccessorTitle(Accessor.Index),
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => offsetCount + index + 1,
  },
  {
    title: getAccessorTitle(Accessor.CompanyName),
    key: Accessor.CompanyName,
    dataIndex: Accessor.CompanyName,
    width: 600,
    sorter: true,
    sortOrder: getFieldSorter(Accessor.CompanyName),
    ellipsis: true,
    render: (companyName: string, record) => {
      const onClick = () => {
        queryClient.setQueryData([QueryKey.GetCustomerById, record.id], record)
      }

      return (
        <Link to={`${route.customers}/${record.id}`} onClick={onClick}>
          <Typography color="primary" size="xs" fontWeight="l" lineEllipsis={1}>
            {companyName}
          </Typography>
        </Link>
      )
    },
  },
  {
    title: getAccessorTitle(Accessor.CreditLimit),
    key: Accessor.CreditLimit,
    dataIndex: Accessor.CreditLimit,
    width: 200,
    sorter: true,
    sortOrder: getFieldSorter(Accessor.CreditLimit),
    ellipsis: true,
    render: (creditLimit) => (
      <Typography size="xs" fontWeight="xl">
        {getFormattedPriceWithCurrency(creditLimit)}
      </Typography>
    ),
  },
  {
    title: getAccessorTitle(Accessor.Status),
    key: Accessor.Status,
    dataIndex: Accessor.Status,
    width: 150,
    ellipsis: true,
    render: (status) => <CustomerStatusTag status={status} />,
  },
  {
    title: getAccessorTitle(Accessor.Notes),
    key: Accessor.Notes,
    dataIndex: Accessor.Notes,
    width: 200,
    ellipsis: true,
    render: (notes) => notes || '-',
  },
  {
    title: getAccessorTitle(Accessor.BillingAddresses),
    key: Accessor.BillingAddresses,
    dataIndex: Accessor.BillingAddresses,
    ellipsis: true,
    width: 200,
    render: (addresses: Address[]) => {
      if (addresses.length === 0) {
        return '-'
      }

      return addresses
        .map(({ street, city, state, zipCode, country }) =>
          [street, city, state, zipCode, country].filter(Boolean).join(', '),
        )
        .filter(Boolean)
        .join('; ')
    },
  },
]
