export const assetsRoot = '/assets'

export const assetsRoute = {
  list: '/',
  create: '/create',
  view: '/:id',
  details: '/details',
  integrations: '/integrations',
  purchaseOrders: '/purchase-order',
  bookingNumbers: '/booking-numbers',
  redeliveryNumbers: '/redelivery-numbers',
  repairs: '/repairs',
}
