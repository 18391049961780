import { FC } from 'react'

import { AssetStatus } from 'api'

import { Tag, TagProps } from 'designSystem'

import { getTagDataByAssetStatus } from './utils/getTagDataByAssetStatus'

interface AssetStatusTagProps extends Omit<TagProps, 'status'> {
  status: AssetStatus
}

export const AssetStatusTag: FC<AssetStatusTagProps> = ({ status, ...props }) => {
  const { status: tagStatus, text } = getTagDataByAssetStatus(status)
  return <Tag {...props} status={tagStatus} text={text} />
}
