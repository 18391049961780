import { styled } from 'styled-components'

import { Icon as IconDS } from '../../Icon'

export const Icon = styled(IconDS)`
  cursor: pointer;
  transition: color 0.3s;
  color: ${({ theme }) => theme.colors.secondary};

  &:hover {
    color: ${({ theme }) => theme.colors.primaryTone};
  }
`
