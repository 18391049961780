import { css, styled } from 'styled-components'

import { TagType } from '../../types/tagType'

import type { TagProps } from '../../Tag'

export const Tag = styled.div<TagProps>`
  width: fit-content;
  font-weight: ${({ theme }) => theme.fontWeight.xl};
  font-size: ${({ theme }) => theme.fontSize.xs};
  line-height: ${({ theme }) => theme.lineHeight.s};
  padding: ${({ theme }) => `3px ${theme.spacers[2]}`};
  border-width: 1px;
  border-style: solid;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  cursor: ${({ onClick }) => !!onClick && 'pointer'};
  ${({ status }) => {
    switch (status) {
      case TagType.Success:
        return css`
          color: ${({ theme }) => theme.colors.success[400]};
          background-color: ${({ theme }) => theme.colors.success[80]};
          border-color: ${({ theme }) => theme.colors.success[400]};
        `
      case TagType.Disabled:
        return css`
          color: ${({ theme }) => theme.colors.secondary};
          background-color: ${({ theme }) => theme.colors.lines};
          border-color: ${({ theme }) => theme.colors.placeholder};
        `
      case TagType.Warning:
        return css`
          color: ${({ theme }) => theme.colors.warning[400]};
          background-color: ${({ theme }) => theme.colors.warning[80]};
          border-color: ${({ theme }) => theme.colors.warning[400]};
        `
      case TagType.Error:
        return css`
          color: ${({ theme }) => theme.colors.error[400]};
          background-color: ${({ theme }) => theme.colors.error[80]};
          border-color: ${({ theme }) => theme.colors.error[400]};
        `
      case TagType.Pending:
        return css`
          color: ${({ theme }) => theme.colors.contrast[400]};
          background-color: ${({ theme }) => theme.colors.contrast[80]};
          border-color: ${({ theme }) => theme.colors.contrast[400]};
        `
      case TagType.InProgress:
        return css`
          color: ${({ theme }) => theme.colors.accent[400]};
          background-color: ${({ theme }) => theme.colors.accent[80]};
          border-color: ${({ theme }) => theme.colors.accent[400]};
        `
      case TagType.Done:
        return css`
          color: ${({ theme }) => theme.colors.primary};
          background-color: ${({ theme }) => theme.colors.lines};
          border-color: ${({ theme }) => theme.colors.secondary};
        `
      default:
        return css`
          color: ${({ theme }) => theme.colors.primary};
          background-color: transparent;
          border-color: ${({ theme }) => theme.colors.placeholder};
        `
    }
  }}
`

export const IconWrapper = styled.div`
  display: none;
  ${Tag}:hover & {
    display: flex;
  }
`
