import { ReferenceNumberItem } from 'api'

import { ReferenceItemsAssetsIds } from '../types/referenceItemsAssetsIds'

export const getDefaultAssetsIds = (data?: ReferenceNumberItem[]): ReferenceItemsAssetsIds => {
  const ids = {} as ReferenceItemsAssetsIds

  data?.forEach((item, index) => {
    item.subLines?.forEach((subLine, subLineIndex) => {
      if (subLine.assetId) {
        ids[`${index}.${subLineIndex}`] = subLine.assetId
      }
    })
  })

  return ids
}
