import { css, styled } from 'styled-components'

import { Button } from 'designSystem'

export const ButtonFavorite = styled(Button)<{ checked?: boolean }>`
  ${({ checked, theme }) =>
    checked &&
    css`
      &&&.ant-btn {
        border-color: ${theme.colors.warning[400]};
      }

      svg {
        fill: ${theme.colors.warning[400]};
        color: ${theme.colors.warning[400]};
      }
    `}
`
