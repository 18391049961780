import { FC } from 'react'

import { Container } from 'designSystem'
import { ZohoIntegration } from 'components'

interface SalesOrderIntegrationsProps {
  zohoId: number
}

export const SalesOrderIntegrations: FC<SalesOrderIntegrationsProps> = ({ zohoId }) => {
  return (
    <Container bg="white">
      <ZohoIntegration module="zohoSaleOrdersLink" id={zohoId} />
    </Container>
  )
}
