import { FC, useEffect } from 'react'
import { Col, Row } from 'antd'

import { ReferenceNumberRedeliveryTransactionType, ReferenceNumberType } from 'api'
import { useFormContext, usePOs, useReferences, useSalesOrders, useWatch } from 'hooks'
import { referenceLabels } from 'constant'
import { ReferenceFormAccessor } from 'types'

import { Container } from 'designSystem'
import { FormItemSelectSingleAsync } from 'components'

export const RelatedFields: FC = () => {
  const { unregister } = useFormContext()
  const transactionType = useWatch({ name: ReferenceFormAccessor.TransactionType })
  const isPurchase = transactionType === ReferenceNumberRedeliveryTransactionType.Purchase

  useEffect(() => {
    if (!isPurchase) {
      unregister(ReferenceFormAccessor.PurchaseOrderId)
    }
  }, [isPurchase, unregister])

  return (
    <Container fd="column" gap={8}>
      <Row>
        <Col span={24}>
          <FormItemSelectSingleAsync
            name={ReferenceFormAccessor.SalesOrderId}
            label={referenceLabels.salesOrderId}
            getItems={useSalesOrders}
          />
        </Col>
      </Row>
      {isPurchase && (
        <Row>
          <Col span={24}>
            <FormItemSelectSingleAsync
              name={ReferenceFormAccessor.PurchaseOrderId}
              label={referenceLabels.purchaseOrderId}
              getItems={usePOs}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <FormItemSelectSingleAsync
            name={ReferenceFormAccessor.BookingNumberId}
            label={referenceLabels.bookingNumberId}
            getItems={useReferences}
            queryParams={{ type: { $eq: ReferenceNumberType.Booking } }}
          />
        </Col>
      </Row>
    </Container>
  )
}
