import { api, Item } from 'api'

import { ItemPayload } from '../../types/itemPayload'

export type PostItemPayload = ItemPayload

export const postItem = (data: PostItemPayload): Promise<Item> => {
  return api({
    method: 'post',
    url: '/api/v1/items',
    params: {
      join: ['locationCode', 'category', 'groupedSKUs'],
    },
    data,
  })
}
