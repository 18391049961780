import {
  ChangeEvent,
  CSSProperties,
  FC,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  ReactElement,
  Ref,
  useCallback,
} from 'react'
import { InputProps as BaseInputProps, InputRef } from 'antd/lib/input'

import { typedMemo } from 'types'

import { getValueByInputType } from './utils/getValueByInputType'
import { InputType } from './types/inputType'
import * as Styled from './styles'

export interface InputProps extends Omit<BaseInputProps, 'pattern' | 'value' | 'onChange'> {
  value?: string
  onChange?: (value: string) => void
  hideChars?: boolean
  uppercase?: boolean
  error?: string
  width?: number
  ref?: Ref<InputRef>
  type?: InputType
  align?: CSSProperties['textAlign']
  pattern?: RegExp
}

export const InputBase: FC<InputProps> = forwardRef(
  (
    { error, hideChars, uppercase, width, prefix, type = 'text', onChange, pattern, onBlur, onPressEnter, ...props },
    ref,
  ): ReactElement => {
    const InputComponent = hideChars ? Styled.InputPassword : Styled.Input

    const handleChangeInput = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const currentCaretPosition = e.target.selectionStart ?? 0
        const currentValue = e.target.value
        const newValue = getValueByInputType(currentValue, type, pattern)

        e.target.value = uppercase ? newValue.toUpperCase() : newValue

        const caretOffset = currentCaretPosition - (currentValue.length - newValue.length)
        const newCaretPosition = Math.max(caretOffset, 0)

        e.target.setSelectionRange(newCaretPosition, newCaretPosition)

        onChange?.(e.target.value)
      },
      [type, onChange, uppercase, pattern],
    )

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      if (props.value) {
        onChange?.(props.value.trim())
      }
      onBlur?.(event)
    }

    const handlePressEnter = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        event.preventDefault()
        event.stopPropagation()
        onPressEnter?.(event)
      },
      [onPressEnter],
    )

    return (
      <Styled.Wrapper width={width}>
        <InputComponent
          status={error && 'error'}
          {...props}
          onPressEnter={handlePressEnter}
          onBlur={handleBlur}
          onChange={handleChangeInput}
          ref={ref}
          prefix={prefix || <span />}
          $isPrefix={!!prefix}
          autoComplete="off"
        />
      </Styled.Wrapper>
    )
  },
)

const Input = typedMemo(InputBase)

Input.displayName = 'Input'

export { Input }
