import { styled } from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  width: 100%;
  max-width: 390px;
  overflow: hidden;
`
export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: ${({ theme }) => theme.spacers[4]};
  background: ${({ theme }) => theme.colors.accent[80]};
  min-height: 65px;
`

export const AvatarWrapper = styled.div`
  position: absolute;
  left: ${({ theme }) => theme.spacers[7]};
  bottom: -50%;
  border: 3px solid ${({ theme }) => theme.colors.white};
  border-radius: 50%;
`
export const Content = styled.div`
  padding: 50px 24px 24px;
`
