import { FC, useCallback, useEffect } from 'react'

import { VendorType } from 'api'
import { useForm } from 'hooks'
import { FileExtension } from 'constant'

import { FormItem, showNotification, Spacer, Upload } from 'designSystem'

import { useUploadFile } from './hooks/useUploadFile'
import { validationSchema } from './utils/validationSchema'
import { UploadTypeOption } from '../../constants/uploadTypeOptions'
import { LocalFileForm, UploadLocaleFilePayload } from './types/useForm'
import { ConfirmItem } from './elements/ConfirmItem'
import { VendorTypeItem } from './elements/VendorTypeItem'

interface UploadLocalFileProps {
  vendorId: number
  handleCloseModal: () => void
  uploadTypeOptions?: UploadTypeOption[]
  vendorType?: VendorType
}

export const UploadLocalFile: FC<UploadLocalFileProps> = ({
  vendorId,
  handleCloseModal,
  uploadTypeOptions,
  vendorType,
}) => {
  const onSuccessUpload = () => {
    handleCloseModal()
    showNotification({ title: 'The CSV file was successfully uploaded', type: 'success' })
  }

  const { uploadFile, isLoading } = useUploadFile({ onSuccess: onSuccessUpload, vendorId })

  const onSubmit = useCallback(
    (data: UploadLocaleFilePayload) => {
      uploadFile(data)
    },
    [uploadFile],
  )

  const { Form, handleSubmit, setValue } = useForm<LocalFileForm>({
    validationSchema,
    mode: 'onChange',
    context: { isUploadTypeRequired: !!uploadTypeOptions },
    onSubmit,
    defaultValues: {
      ...(uploadTypeOptions && { vendorType: uploadTypeOptions[0].value }),
    },
  })

  useEffect(() => {
    if (vendorType) {
      setValue('vendorType', vendorType)
    }
  }, [vendorType, setValue])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {uploadTypeOptions && <VendorTypeItem uploadTypeOptions={uploadTypeOptions} />}
      <Spacer mb={4} />
      <FormItem name="files" showErrorBottom>
        <Upload accept={FileExtension.Csv} />
      </FormItem>
      <Spacer mb={7} />
      <ConfirmItem isLoading={isLoading} />
    </Form>
  )
}
