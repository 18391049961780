import type { ScrappingSettings } from 'api'

export const getScrappingSettingsDefaultValues = (
  scrappingSettings: ScrappingSettings | undefined | null,
): ScrappingSettings => {
  return {
    feedUrl: scrappingSettings?.feedUrl || undefined,
    fetchSchedule: scrappingSettings?.fetchSchedule,
    isEnabled: scrappingSettings?.isEnabled,
  }
}
