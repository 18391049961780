import { FC, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'

import { EntityId, PurchaseOrder } from 'api'
import { useFieldArray, useMemoCompare, useWatch } from 'hooks'
import { PurchaseOrderFormAccessor } from 'types'
import { generateUniqueId } from 'utils'

import { Table } from 'components'
import { PurchaseOrderSubLineRow } from 'modules/purchaseOrders/types'
import { getReferenceNumberData } from 'modules/purchaseOrders/utils/getReferenceNumberData'

import { useGetExpandableColumns } from './hooks/useGetExpandableColumns'
import { maxSubLineCount } from 'modules/purchaseOrders/constants/maxRecordsCount'

interface ExpandableRowProps {
  name: string
  poData?: PurchaseOrder
  vendorId?: EntityId
  isAddNew?: boolean
}

export const ExpandableNewRow: FC<ExpandableRowProps> = ({ name, poData, vendorId, isAddNew }) => {
  const rowSubLinesFieldName = `${name}.${PurchaseOrderFormAccessor.SubLines}`
  const memoPOData = useMemoCompare(poData)
  const { fields: dataSource, remove, replace, append } = useFieldArray({ name: rowSubLinesFieldName })
  const { getValues, setValue } = useFormContext()
  const itemQty = useWatch({ name: `${name}.${PurchaseOrderFormAccessor.ItemQty}` }) || 0
  const { columns } = useGetExpandableColumns({
    isAddNew,
    name,
    poData: memoPOData,
    vendorId,
    isCreateMode: true,
    remove,
    replace,
    rowsCount: dataSource.length,
  })

  const onAddNewRow = useCallback(() => {
    const {
      locationCodeId,
      expectedDate,
      depotId,
      vendorReleaseNumber,
      redeliveryNumber,
      vendorReleaseReferenceNumber,
      redeliveryReferenceNumber,
    } = memoPOData || getValues()

    setValue(`${name}.${PurchaseOrderFormAccessor.ItemQty}`, itemQty + 1)

    const referenceNumberData = getReferenceNumberData({
      vendorReleaseNumber,
      redeliveryNumber,
      vendorReleaseReferenceNumber,
      redeliveryReferenceNumber,
      shouldCreateNew: !!isAddNew,
    })

    append({
      id: generateUniqueId(),
      key: dataSource.length,
      expectedDeliveryDate: expectedDate,
      vendorDepotId: depotId,
      locationCodeId,
      ...referenceNumberData,
    })
  }, [append, dataSource.length, getValues, memoPOData, name, setValue, itemQty, isAddNew])

  const allowToAddNewRow = itemQty < maxSubLineCount

  return (
    <Table<PurchaseOrderSubLineRow>
      scroll={{ x: 800, ...(dataSource.length > 10 && { y: 390 }) }}
      dataSource={dataSource as PurchaseOrderSubLineRow[]}
      columns={columns}
      onAddNewRow={allowToAddNewRow ? onAddNewRow : undefined}
    />
  )
}
