import { styled } from 'styled-components'

export const Content = styled.div`
  padding-top: 120px;
  display: flex;
  justify-content: center;
`

export const Wrapper = styled.div`
  max-width: 376px;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacers[7]};
  border: 1px solid ${({ theme }) => theme.colors.lines};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`
