import { FC } from 'react'

import { SalesOrder } from 'api'

import { Container, Typography } from 'designSystem'

export type SalesOrderTitleProps = Pick<SalesOrder, 'number'>

export const SalesOrderTitle: FC<SalesOrderTitleProps> = ({ number }) => {
  return (
    <Container ai="center" minHeight={32}>
      <Typography size="l" fontWeight="xl">
        {number}
      </Typography>
    </Container>
  )
}
