import { FC } from 'react'
import { Skeleton } from 'antd'

import { ReferenceNumber, ReferenceNumberStatus } from 'api'
import { useMemoCompare } from 'hooks'
import { HashRouterComponentProps } from 'types'

import { Drawer } from 'designSystem'

import { useGetReferenceNumberById } from './hooks/useGetReferenceNumberById'
import { ReferenceNumberTitle } from './elements/ReferenceNumberTitle'
import { ReferenceTabs } from './elements/ReferenceTabs'

export const Reference: FC<HashRouterComponentProps> = ({ hash }) => {
  const referenceId = Number(hash.split('/')?.[1])

  const { data, isLoading } = useGetReferenceNumberById(referenceId)

  const memoizedData = useMemoCompare(data) as ReferenceNumber | undefined

  const canEditOnlyStatus = [
    ReferenceNumberStatus.Closed,
    ReferenceNumberStatus.Voided,
    ReferenceNumberStatus.Received,
  ].includes(memoizedData?.status as ReferenceNumberStatus)

  return (
    <Drawer
      hashUrl={hash}
      bg="transparent"
      pa={0}
      title={
        isLoading && !memoizedData?.number ? (
          <Skeleton.Input active />
        ) : (
          !!memoizedData && <ReferenceNumberTitle data={memoizedData} canEdit={!canEditOnlyStatus} />
        )
      }
      isLoading={isLoading}
    >
      {!isLoading && <ReferenceTabs data={memoizedData} canEditOnlyStatus={canEditOnlyStatus} />}
    </Drawer>
  )
}
