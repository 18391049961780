import { vendorCarrierRoute } from 'constant'

import { Tab } from 'designSystem'

import { CarrierDetails } from '../routes/CarrierDetails'
import { CarrierEquipment } from '../routes/CarrierEquipment'
import { CarrierPriceList } from '../routes/CarrierPriceList'

export const carrierTabs: Tab[] = [
  {
    key: vendorCarrierRoute.details,
    label: 'General Info',
    children: <CarrierDetails />,
  },
  {
    key: vendorCarrierRoute.priceList,
    label: 'Pricelist Options',
    children: <CarrierPriceList />,
  },
  {
    key: vendorCarrierRoute.equipment,
    label: 'Equipment',
    children: <CarrierEquipment />,
  },
]
