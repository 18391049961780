import { FC, useCallback, useState } from 'react'

import { PurchaseOrder } from 'api'
import { useGetVendorContacts } from 'hooks'

import { AddContactsActionButton, AddContactsFormData } from 'components'
import { usePOContactsRequests } from 'modules/purchaseOrders/hooks/usePOContactsRequests'

import { maxContactsCount } from 'modules/purchaseOrders/constants/maxRecordsCount'

export type AddPOContactsProps = Pick<PurchaseOrder, 'id' | 'contacts'> & {
  buttonText?: string
  vendorId?: number
}

export const AddPOContacts: FC<AddPOContactsProps> = ({
  id,
  vendorId,
  contacts,
  buttonText = 'Add Contacts To PO',
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { data: vendorContacts } = useGetVendorContacts(vendorId, modalOpen)
  const { postPOContacts, postNewContact, isLoading, isSuccess } = usePOContactsRequests({
    purchaseOrderId: id,
    vendorId,
  })

  const handleSubmit = useCallback(
    (payload: AddContactsFormData) => {
      const { contacts = [], entityContacts = [] } = payload

      if (contacts.length) {
        postNewContact(contacts[0])
      }

      if (entityContacts.length) {
        postPOContacts({ vendorContactsIds: entityContacts })
      }
      setModalOpen(false)
    },
    [postNewContact, postPOContacts],
  )

  const onModalOpenChange = useCallback((open: boolean) => setModalOpen(open), [])

  return (
    <AddContactsActionButton
      maxContactsCount={maxContactsCount}
      entityContactsCount={vendorContacts?.length || 0}
      buttonText={buttonText}
      contacts={contacts}
      entityContacts={vendorContacts}
      onConfirm={handleSubmit}
      isConfirmSuccess={isSuccess}
      confirmLoading={isLoading}
      onModalOpenChange={onModalOpenChange}
    />
  )
}
