import { ConditionalOperator } from 'api/types/conditionalOperator'

export const conditionalOperatorOptions = [
  {
    label: '=',
    value: ConditionalOperator.Equal,
  },
  {
    label: '>',
    value: ConditionalOperator.GreaterThan,
  },
  {
    label: '<',
    value: ConditionalOperator.LessThan,
  },
]
