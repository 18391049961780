import { FC, Key, useEffect, useMemo, useState } from 'react'

import { PurchaseOrder, PurchaseOrderItem, PurchaseOrderType } from 'api'
import { useForm, useMemoCompare } from 'hooks'
import { AppModule } from 'types'
import { getFormattedPriceWithCurrency } from 'utils'
import { useSetIsAddNew, useSetPurchaseOrderOptions, useSetReferenceNumberOptions } from 'store'

import { Button, Container, SelectSingleOption, Spacer, Typography } from 'designSystem'
import { Table, TableColumnsType, TableFilterOptions, TableTitle, useTableColumns } from 'components'
import { ItemAssets } from 'modules/purchaseOrders/types/itemAssets'
import { getTableColumns } from 'modules/purchaseOrders/utils/getTableColumns'

import { usePurchaseOrderItems } from './hooks/usePurchaseOrderItems'
import { usePurchaseOrderItemsRequests } from './hooks/usePurchaseOrderItemsRequests'
import { getItemsDefaultData } from './utils/getItemsDefaultData'
import { getReferenceNumberOptions } from './utils/getReferenceNumberOptions'
import { getSubLineOptions } from './utils/getSubLineOptions'
import { getSubLineValue } from './utils/getSubLineValue'
import { getViewOnlyFieldsPOItems } from './utils/getViewOnlyFieldsPOItems'
import { purchaseOrderItemsVisibleColumnsOptions } from './constants/purchaseOrderItemsVisibleColumns'
import { purchaseOrderSubItemsVisibleColumnsOptions } from './constants/purchaseOrderSubItemsVisibleColumns'
import { validationSchema } from './constants/validationSchema'
import { PurchaseOrderItemsForm } from './types/purchaseOrderItemsForm'
import { ExpandableEditRow } from './elements/ExpandableEditRow'
import { ExpandableNewRow } from 'modules/purchaseOrders/elements/ExpandableNewRow'

interface PurchaseOrderItemsProps {
  poData?: PurchaseOrder
  allFieldsNotEditable: boolean
  purchaseOrderId: number
}

export const PurchaseOrderDetailsItems: FC<PurchaseOrderItemsProps> = ({
  poData,
  allFieldsNotEditable: allFieldsDisabled,
  purchaseOrderId,
}) => {
  const [itemAssets, setItemAssets] = useState<ItemAssets>({})
  const [subLines, setSubLines] = useState<TableColumnsType<Partial<PurchaseOrderItem>>>([])
  const setOptionsById = useSetPurchaseOrderOptions()
  const setIsAddNewToStore = useSetIsAddNew()

  const { type, locationCode, depot } = poData || {}

  const isPOTypeStandard = type === PurchaseOrderType.Standard

  const {
    data,
    isLoading,
    onTableChange,
    TableContextProvider,
    // editable table
    dataSource,
    isAddNew,
    setIsAddNew,
    onAddNew,
    onCancelNew,
  } = usePurchaseOrderItems(purchaseOrderId)

  const [expandedRowKeys, setExpandedRowKeys] = useState<(string | number)[] | undefined>(undefined)

  useEffect(() => {
    if (!isLoading && !!dataSource.length && expandedRowKeys === undefined) {
      setExpandedRowKeys(dataSource.map((item) => item.key))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, dataSource.length])

  const { onSubmitPost, onSubmitPatch, onDeleteItem, onDeleteSubLine, isSubmitError, isEditLoading } =
    usePurchaseOrderItemsRequests({ setIsAddNew, data, purchaseOrderId, setExpandedRowKeys })

  const viewOnlyFields = useMemo(() => {
    return getViewOnlyFieldsPOItems(allFieldsDisabled)
  }, [allFieldsDisabled])

  const { Form, handleSubmit, triggerSubmit, watch, setValue } = useForm<PurchaseOrderItemsForm>({
    validationSchema: validationSchema(isAddNew),
    mode: 'onChange',
    isTableEditForm: true,
    isLoading,
    onSubmit: onSubmitPatch,
    isSubmitError,
    defaultValues: {
      item: {
        billedQty: 0,
        receivedQty: 0,
      },
      items: getItemsDefaultData(data),
    },
    viewOnlyFields,
  })
  const setReferenceNumberOptions = useSetReferenceNumberOptions()

  const memoData = useMemoCompare(data)
  const memoPoData = useMemoCompare(poData)

  useEffect(() => {
    const referenceNumberOptions = getReferenceNumberOptions(memoData, memoPoData)
    setReferenceNumberOptions(referenceNumberOptions)
  }, [memoData, memoPoData, setReferenceNumberOptions])

  const allColumns = getTableColumns({
    onDelete: onDeleteItem,
    type,
    setItemAssets,
    skuAsset: itemAssets,
    setExpandedRowKeys,
    isAddNew,
    triggerSubmit,
    onCancelNew,
    isEditMode: true,
    watch,
    setValue,
  })

  const { visibleColumns, setVisibleColumns, columns } = useTableColumns<Partial<PurchaseOrderItem>>({
    module: AppModule.PurchaseOrderItems,
    allColumns: allColumns as TableColumnsType<Partial<PurchaseOrderItem>>,
    withActions: true,
  })

  const { visibleColumns: visibleSubColumns, setVisibleColumns: setVisibleSubColumns } = useTableColumns<
    Partial<PurchaseOrderItem>
  >({
    module: type
      ? {
          [PurchaseOrderType.Redelivery]: AppModule.PurchaseOrderRedeliverySubLines,
          [PurchaseOrderType.VendorRelease]: AppModule.PurchaseOrderVendorReleaseSubLines,
          [PurchaseOrderType.Standard]: undefined,
        }[type]
      : undefined,
    allColumns: subLines as TableColumnsType<Partial<PurchaseOrderItem>>,
    withActions: true,
  })

  const itemsTotalAmount = useMemo(() => {
    return memoData?.reduce((acc: number, item: PurchaseOrderItem) => acc + item.totalAmount, 0) || undefined
  }, [memoData])

  useEffect(() => {
    if (locationCode) {
      const locationOption = {
        label: locationCode.code,
        value: locationCode.id,
      } satisfies SelectSingleOption

      setOptionsById('locations', locationOption)
    }
    if (depot) {
      const depotOption = {
        label: depot.code,
        value: depot.id,
      } satisfies SelectSingleOption

      setOptionsById('depots', depotOption)
    }
  }, [locationCode, setOptionsById, depot])

  useEffect(() => {
    if (!isAddNew && itemAssets[-1]) {
      setItemAssets((prevItemAssets) => {
        const copyItemAssets = { ...prevItemAssets }
        delete copyItemAssets[-1]
        return copyItemAssets
      })
    }
  }, [isAddNew, itemAssets])

  useEffect(() => {
    setIsAddNewToStore(isAddNew)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddNew])

  return (
    <Container display="block" maxWidth={'100%'}>
      <Form onSubmit={handleSubmit(onSubmitPost)}>
        <TableContextProvider>
          <TableTitle
            title="Items Details"
            withoutDefaultPadding
            columns={
              <TableFilterOptions
                title="Item"
                subLineTitle="Subitem"
                icon="columns"
                width={poData?.type === PurchaseOrderType.Standard ? 200 : 380}
                options={purchaseOrderItemsVisibleColumnsOptions}
                subLineOptions={getSubLineOptions(
                  poData?.type || PurchaseOrderType.Standard,
                  purchaseOrderSubItemsVisibleColumnsOptions,
                )}
                value={visibleColumns}
                subLineValue={getSubLineValue(poData?.type || PurchaseOrderType.Standard, visibleSubColumns)}
                onChange={setVisibleColumns}
                onChangeSubLines={setVisibleSubColumns}
                showAll={isAddNew}
              />
            }
          />
          <Spacer mb={4} />
          <Table<Partial<PurchaseOrderItem>>
            scroll={{ x: 800 }}
            dataSource={dataSource}
            columns={columns}
            viewOnlyExceptFirstRow={isAddNew}
            loading={isLoading || isEditLoading}
            onChange={onTableChange}
            isEditLoading={isEditLoading}
            editableTable
            expandable={{
              ...(!isPOTypeStandard && {
                expandedRowKeys,
                expandedRowRender: (record: Partial<PurchaseOrderItem>, index: number) => {
                  return record.id === -1 ? (
                    <ExpandableNewRow name="item" poData={poData} vendorId={poData?.vendorId} isAddNew={isAddNew} />
                  ) : (
                    <ExpandableEditRow
                      allFieldsDisabled={allFieldsDisabled}
                      poData={poData}
                      isAddNew={isAddNew}
                      index={isAddNew ? index - 1 : index}
                      record={record}
                      type={type}
                      vendorId={poData?.vendorId}
                      onDeleteSubLine={onDeleteSubLine}
                      setSubLines={setSubLines}
                      allVisibleColumns={visibleSubColumns}
                    />
                  )
                },
                rowExpandable: (record: Partial<PurchaseOrderItem>) => {
                  return (
                    !isPOTypeStandard &&
                    !!record.id &&
                    (!!itemAssets[record.id]?.containAsset || !!record.subLines?.length)
                  )
                },
                onExpandedRowsChange: (expandedRows: readonly Key[]) => {
                  setExpandedRowKeys([...(expandedRows as string[])])
                },
              }),
            }}
          />
          <Container ai="center" jc="space-between" mt={4}>
            <Button icon="plus" type="link" onClick={onAddNew} disabled={isAddNew || allFieldsDisabled}>
              Add
            </Button>
            {itemsTotalAmount && (
              <Typography fontWeight="xl" size="xs">
                Total {getFormattedPriceWithCurrency(itemsTotalAmount)}
              </Typography>
            )}
          </Container>
        </TableContextProvider>
      </Form>
    </Container>
  )
}
