import { Link } from 'react-router-dom'

import { customerViewRoute, route } from 'constant'

export const getSidebarItems = (id?: string) => {
  const customerRoute = `${route.customers}/${id}`

  return [
    {
      label: <Link to={`${customerRoute}${customerViewRoute.details}`}>Customer Details</Link>,
      key: `${customerRoute}${customerViewRoute.details}`,
    },
    {
      label: <Link to={`${customerRoute}${customerViewRoute.integrations}`}>Integrations</Link>,
      key: `${customerRoute}${customerViewRoute.integrations}`,
    },
  ]
}
