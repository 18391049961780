import { AssetTransactionType } from 'api/types/asset/assetTransactionType'

export const assetTransactionTypeOptions = [
  {
    label: 'Rental',
    value: AssetTransactionType.Rental,
  },
  {
    label: 'Sale',
    value: AssetTransactionType.Sale,
  },
]
