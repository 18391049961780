import { FC, useCallback } from 'react'
import { Col, Row } from 'antd'

import { useFieldArray, useTruckCodes } from 'hooks'

import { Button, Container, FormItem, Input, InputNumber, TitleTag } from 'designSystem'
import { FormItemSelectSingleAsync } from 'components'

import { maxEquipmentsCount } from 'modules/vendors/constants/maxRecordsCount'

export const CarrierEquipments: FC = () => {
  const { fields, append, remove } = useFieldArray({ name: 'carrierSettings.equipment' })

  const insertField = useCallback(() => {
    append({})
  }, [append])

  return (
    <Container display="flex" fd="column" gap={8}>
      {fields.map((field, index) => (
        <Container key={field.id} display="flex" fd="column" gap={8} mt={index > 0 ? 3 : 0}>
          <Row>
            <TitleTag title={`Item ${index + 1}`} canDelete onDelete={() => remove(index)} />
          </Row>
          <Row>
            <Col span={24}>
              <FormItem name={`carrierSettings.equipment.${index}.title`} label="Title">
                <Input placeholder="Title" />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <FormItem name={`carrierSettings.equipment.${index}.email`} label="Email">
                <Input placeholder="Email" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem name={`carrierSettings.equipment.${index}.phoneNumber`} label="Phone">
                <Input placeholder="+" type="phone" />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <FormItemSelectSingleAsync
                id={field.id}
                name={`carrierSettings.equipment.${index}.truckCodeId`}
                label="Truck Code"
                index={index}
                getItems={useTruckCodes}
              />
            </Col>
            <Col span={12}>
              <FormItem name={`carrierSettings.equipment.${index}.qty`} label="Qty.">
                <InputNumber placeholder="0" />
              </FormItem>
            </Col>
          </Row>
        </Container>
      ))}

      {fields.length < maxEquipmentsCount && (
        <Container mt={3}>
          <Button type="link" icon="plus" onClick={insertField}>
            Add
          </Button>
        </Container>
      )}
    </Container>
  )
}
