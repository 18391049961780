import { FC } from 'react'

import { useFormContext } from 'hooks'
import { salesOrderLabels } from 'constant'
import { SalesOrderFormAccessor } from 'types'
import { getFormattedPriceWithCurrency } from 'utils'

import { Container, Typography } from 'designSystem'

import { getLineItemsTotal } from './utils/getLineItemsTotal'

export const LineItemsTotal: FC = () => {
  const { getValues } = useFormContext()
  const currentItemsValues = getValues(SalesOrderFormAccessor.LineItems) || []

  const { taxSum, subtotalSum, totalSum } = getLineItemsTotal(currentItemsValues) || {}

  return (
    <Container gap={8}>
      <Typography fontWeight="l" size="xs" color="secondary">
        {salesOrderLabels.subtotal} {getFormattedPriceWithCurrency(subtotalSum)}
      </Typography>
      <Typography fontWeight="l" size="xs" color="secondary">
        {salesOrderLabels.taxes} {getFormattedPriceWithCurrency(taxSum)}
      </Typography>
      <Typography fontWeight="l" size="xs">
        {salesOrderLabels.total} {getFormattedPriceWithCurrency(totalSum)}
      </Typography>
    </Container>
  )
}
