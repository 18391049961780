import { CustomerIndustry } from 'api/types/customer'

export const customerIndustryLabel = {
  [CustomerIndustry.Agriculture]: 'Agriculture',
  [CustomerIndustry.Construction]: 'Construction',
  [CustomerIndustry.Education]: 'Education',
  [CustomerIndustry.FoodService]: 'Food Service',
  [CustomerIndustry.GeneralCommercial]: 'General Commercial',
  [CustomerIndustry.Government]: 'Government',
  [CustomerIndustry.Hospitality]: 'Hospitality',
  [CustomerIndustry.Manufacturing]: 'Manufacturing',
  [CustomerIndustry.Military]: 'Military',
  [CustomerIndustry.Other]: 'Other',
  [CustomerIndustry.Recycling]: 'Recycling',
  [CustomerIndustry.Residential]: 'Residential',
  [CustomerIndustry.Retail]: 'Retail',
  [CustomerIndustry.Wholesale]: 'Wholesale',
  [CustomerIndustry.Winery]: 'Winery',
}

export const customerIndustryOptions = Object.values(CustomerIndustry).map((industry) => ({
  value: industry,
  label: customerIndustryLabel[industry],
}))
