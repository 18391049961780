import { FC, MouseEvent, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { Popover } from 'antd'

import { typedMemo } from 'types'

import { ConfirmationButtons, Container, EditFormItemSize, EditView, SpacerLevel } from 'designSystem'

import * as Styled from './styles'

interface EditConfirmationPopoverProps {
  onConfirm?: () => void
  onCancel?: () => void
  disabled?: boolean
  viewValue?: string
  width?: number
  pa?: SpacerLevel
  popupContainer?: undefined | HTMLElement
  size: EditFormItemSize
}

export const EditConfirmationPopoverBase: FC<PropsWithChildren<EditConfirmationPopoverProps>> = ({
  onConfirm,
  onCancel,
  disabled,
  viewValue,
  width = 230,
  pa = 3,
  children,
  popupContainer,
  size,
}) => {
  const [isEdit, setIsEdit] = useState(false)

  const handleViewClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setIsEdit(true)
    },
    [setIsEdit],
  )

  const onClose = useCallback(() => {
    setIsEdit(false)
  }, [])

  const handleConfirm = useCallback(() => {
    onConfirm?.()
    onClose()
  }, [onConfirm, onClose])

  const handleCancel = useCallback(() => {
    onClose()
    onCancel?.()
  }, [onCancel, onClose])

  const handlePopoverContentClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }, [])

  useEffect(() => {
    if (isEdit) {
      window.addEventListener('click', handleCancel)
    } else {
      window.removeEventListener('click', handleCancel)
    }

    return () => {
      window.removeEventListener('click', handleCancel)
    }
  }, [isEdit, handleCancel])

  const handleGetPopupContainer = useCallback(
    (trigger: HTMLElement) => (popupContainer ? popupContainer : (trigger.parentNode as HTMLElement)),
    [popupContainer],
  )

  return (
    <Container gap={24}>
      <Styled.EditConfirmationPopoverWrapper>
        <Popover
          open={isEdit}
          placement="bottomRight"
          getPopupContainer={handleGetPopupContainer}
          trigger="click"
          arrow={false}
          destroyTooltipOnHide
          content={
            <Container width={width} onClick={handlePopoverContentClick} fd="column" pa={pa}>
              {children}
              <ConfirmationButtons onConfirm={handleConfirm} onCancel={handleCancel} disabled={disabled} />
            </Container>
          }
        />
      </Styled.EditConfirmationPopoverWrapper>
      <EditView size={size} value={viewValue} onClick={handleViewClick} />
    </Container>
  )
}

export const EditConfirmationPopover = typedMemo(EditConfirmationPopoverBase)
