import { VendorType } from 'api'
import { getMarginDefaultValues, getScrappingSettingsDefaultValues } from 'utils'

import { VendorFeedSettings } from 'api/types/vendor/vendorFeedSettings'
import { PatchFeedPayload } from '../elements/FeedUrlTab/types/PatchFeedPayload'

interface GetDefaultValuesProps {
  type: VendorType
  feedData?: VendorFeedSettings
}

export const getDefaultValues = ({ type, feedData }: GetDefaultValuesProps): PatchFeedPayload => {
  return {
    vendorType: type,
    scrappingSettings: getScrappingSettingsDefaultValues(feedData?.scrappingSettings),
    resaleMargin: getMarginDefaultValues(feedData?.resaleMargin),
  }
}
