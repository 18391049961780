import { FC, useCallback } from 'react'

import { VendorCarrierSettings } from 'api'
import { useForm } from 'hooks'
import { getMarginDefaultValues } from 'utils'

import { Container, Divider, Typography } from 'designSystem'
import { FormItemEditMarginSettings, StripLoader } from 'components'

import { useUpdateCarrierDetails } from './hooks/useUpdateCarrierDetails'
import { validationSchema } from './constants/validationSchema'
import { CarrierDetailsFormSchema } from './types/carrierDetailsForm'
import { CarrierDetailsPayload } from './types/carrierDetailsPayload'
import { CarrierInfo } from 'modules/vendors/elements/CarrierInfo'

interface CarrierDetailsFormProps {
  id: number
  data: VendorCarrierSettings
}

export const CarrierDetailsForm: FC<CarrierDetailsFormProps> = ({ id, data }) => {
  const { update, isPending, isError } = useUpdateCarrierDetails(id)

  const onSubmit = useCallback(
    (payload: CarrierDetailsPayload) => {
      update(payload)
    },
    [update],
  )

  const { Form, triggerSubmit } = useForm<CarrierDetailsFormSchema>({
    validationSchema,
    mode: 'onChange',
    onSubmit,
    isSubmitError: isError,
    defaultValues: {
      ...data,
      resaleMargin: getMarginDefaultValues(data?.resaleMargin),
    },
  })

  return (
    <Form>
      <StripLoader isLoading={isPending} />
      <Container display="flex" fd="column" gap={16}>
        <Typography size="l" fontWeight="xl">
          Carrier Info
        </Typography>
        <Divider />
        <FormItemEditMarginSettings name="resaleMargin" onConfirm={triggerSubmit} />
        <Divider />
        <CarrierInfo name="type" isEdit />
      </Container>
    </Form>
  )
}
