import * as Yup from 'yup'

import { PurchaseOrder, PurchaseOrderStatus, PurchaseOrderType, ReferenceNumberType } from 'api'
import { isDateAfter, isDateBefore, notesValidation, numberBasicValidation } from 'utils'

import { PurchaseOrderDeliveryType, PurchaseOrderRow } from '../types'

import { maxSubLineCount } from './maxRecordsCount'

export const purchaseOrderStatusValidation = Yup.mixed<PurchaseOrderStatus>().oneOf(Object.values(PurchaseOrderStatus))

export const purchaseOrderAssetValidation = {
  locationCodeId: numberBasicValidation.nullable(),
  depotCode: Yup.string().nullable(),
  containerColor: Yup.string().nullable(),
  vendorReleaseNumber: Yup.string().max(20, 'Vendor release number should be less than 20 characters').nullable(),
  redeliveryNumber: Yup.string().max(20, 'Redelivery number should be less than 20 characters').nullable(),
}

export const purchaseOrderSubLineBaseValidation = {
  expectedDeliveryDate: Yup.date().nullable(),
}

export const purchaseOrderSubLineCreateValidation = Yup.object({
  ...purchaseOrderSubLineBaseValidation,
  ...purchaseOrderAssetValidation,
})

export const purchaseOrderSubLineViewValidation = Yup.object({
  ...purchaseOrderSubLineBaseValidation,
  type: Yup.mixed<ReferenceNumberType>().oneOf(Object.values(ReferenceNumberType)).nullable(),
  asset: Yup.object({
    ...purchaseOrderAssetValidation,
    assetNumber: Yup.string()
      .min(1, 'Asset number should be at least 1 character')
      .max(100, 'Asset number should be less than 100 characters')
      .notRequired(),
  }),
})

export const purchaseOrderItemBaseValidation = {
  itemId: numberBasicValidation.required('SKU is required'),
  qty: numberBasicValidation
    .required('Quantity is required')
    .positive('Quantity should be positive')
    .max(maxSubLineCount, `Quantity should be less than ${maxSubLineCount}`),
  rate: numberBasicValidation.required('Rate is required').positive('Rate should be positive'),
  billedQty: numberBasicValidation
    .required('Billed quantity is required')
    .min(0, 'Billed quantity should be greater than 0')
    .test(
      'Greater than qty',
      'Billed quantity should be not greater than quantity',
      (value, record: { parent: PurchaseOrderRow }) => {
        const { qty } = record.parent
        if (qty && qty < value) {
          return false
        }
        return true
      },
    ),
  receivedQty: numberBasicValidation
    .required('Received quantity is required')
    .min(0, 'Received quantity should be greater than 0')
    .test(
      'Greater than qty',
      'Received quantity should be not greater than quantity',
      (value, record: { parent: PurchaseOrderRow }) => {
        const { qty } = record.parent
        if (qty && qty < value) {
          return false
        }
        return true
      },
    ),
}

export const purchaseOrderItemCreateValidation = Yup.object({
  ...purchaseOrderItemBaseValidation,
  subLines: Yup.array().when('type', ([type], schema) => {
    if (type !== PurchaseOrderType.Standard) {
      return Yup.array().of(purchaseOrderSubLineCreateValidation)
    }
    return schema.nullable()
  }),
})

export const purchaseOrderItemViewValidation = Yup.object({
  ...purchaseOrderItemBaseValidation,
  subLines: Yup.array().when('type', ([type], schema) => {
    if (type !== PurchaseOrderType.Standard) {
      return Yup.array().of(purchaseOrderSubLineViewValidation)
    }
    return schema.nullable()
  }),
})

export const basePurchaseOrderValidation = {
  notes: notesValidation,
  externalPoNotes: notesValidation,
  expectedDate: Yup.date()
    .nullable()
    .notRequired()
    .test(
      'expectedDate',
      'Expected date could not be greater than expiration date',
      (value, record: { parent: PurchaseOrder }) => {
        const { expirationDate } = record.parent
        if (expirationDate && value) {
          return isDateBefore(value, expirationDate)
        }
        return true
      },
    ),
  expirationDate: Yup.date()
    .required('Expiration date is required')
    .test(
      'expectedDate',
      'Expiration date could not be less than expected date',
      (value, record: { parent: PurchaseOrder }) => {
        const { expectedDate } = record.parent
        if (expectedDate && value) {
          return isDateAfter(value, expectedDate)
        }
        return true
      },
    ),
  vendorReleaseNumber: Yup.string().when('type', ([type], schema) => {
    if (type === PurchaseOrderType.VendorRelease) {
      return schema.nullable().max(20, 'Vendor release number should be less than 20 characters')
    }
    return schema.nullable()
  }),

  redeliveryNumber: Yup.string().when(['type'], ([type], schema) => {
    if (type === PurchaseOrderType.Redelivery) {
      return schema.nullable().max(20, 'Redelivery number should be less than 20 characters')
    }
    return schema.nullable()
  }),
  depotId: numberBasicValidation.required('Depot is required'),
  deliveryType: Yup.mixed<PurchaseOrderDeliveryType>().oneOf(Object.values(PurchaseOrderDeliveryType)).nullable(),
  warehouseId: numberBasicValidation.nullable().when(['type', 'deliveryType'], ([type, deliveryType], schema) => {
    if (deliveryType === PurchaseOrderDeliveryType.Warehouse && type === PurchaseOrderType.Standard) {
      return schema.required('Warehouse is required')
    }
    return numberBasicValidation.nullable()
  }),
  salesOrderId: numberBasicValidation.nullable(),
  locationCodeId: numberBasicValidation.when('type', ([type]) => {
    if ([PurchaseOrderType.VendorRelease, PurchaseOrderType.Redelivery].includes(type)) {
      return numberBasicValidation.required('Location is required')
    }
    return numberBasicValidation.nullable()
  }),
  dpp: numberBasicValidation.when('type', ([type], schema) => {
    if (type === PurchaseOrderType.Redelivery) {
      return schema.required('DPP is required')
    }
    return schema.nullable()
  }),
  isEmailRequired: Yup.boolean().default(false),
}
