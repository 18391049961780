import { FC } from 'react'
import { Spin } from 'antd'
import { SpinSize } from 'antd/lib/spin'

import { typedMemo } from 'types'

import * as Styled from './styles'

interface SpinnerProps {
  size?: SpinSize
  autoWidth?: boolean
}

export const SpinnerBase: FC<SpinnerProps> = ({ size = 'large', autoWidth }) => {
  return (
    <Styled.SpinWrapper autoWidth={autoWidth}>
      <Spin spinning={true} size={size} />
    </Styled.SpinWrapper>
  )
}

export const Spinner = typedMemo(SpinnerBase)
