import { notification as baseNotification } from 'antd'
import { NotificationPlacement } from 'antd/lib/notification/interface'

import { generateUniqueId } from 'utils'

import { NotificationType } from '../types'
import * as Styled from '../styles'

import { Icon } from '../../Icon'
import { getIconByType } from './getIconByType'

interface NotificationProps {
  duration?: number
  type?: NotificationType
  title: string
  message?: string
  onButtonClick?: () => void
  placement?: NotificationPlacement
  btnText?: string
}

export const showNotification = ({
  duration = 3,
  placement,
  type = 'default',
  title,
  message,
  onButtonClick,
  btnText,
}: NotificationProps) => {
  const key = generateUniqueId()

  const handleClickButton = () => {
    if (onButtonClick) {
      onButtonClick()
    }
    baseNotification.destroy(key)
  }

  baseNotification.open({
    key,
    placement: placement || 'topRight',
    className: `${type} ${duration ? 'with-duration' : ''} ${!message && !btnText ? 'title-only' : ''}`,
    message: title,
    closeIcon: !duration && <Icon icon="cross" size={16} />,
    icon: getIconByType(type),
    duration,
    description: message,
    btn: btnText && onButtonClick && (
      <Styled.Button onClick={handleClickButton}>
        <Icon icon="stack" size={16} />
        {btnText}
      </Styled.Button>
    ),
  })
}
