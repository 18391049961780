import { FC, forwardRef, Ref } from 'react'
import { TextAreaRef } from 'antd/lib/input/TextArea'

import { typedMemo } from 'types'

import type { TextAreaProps } from 'designSystem'

import * as Styled from './styles'

export type EditTextAreaProps = TextAreaProps

export const EditTextAreaBase: FC<EditTextAreaProps> = forwardRef((props, ref: Ref<TextAreaRef>) => {
  return <Styled.TextAreaEdit {...props} ref={ref} />
})

export const EditTextArea = typedMemo(EditTextAreaBase)
