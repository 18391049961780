import { FC, useCallback, useEffect, useRef } from 'react'

import { Address } from 'api'
import { useFieldArray, useFormContext } from 'hooks'
import { typedMemo } from 'types'
import { getIsFormFieldViewOnly } from 'utils'

import { Button, Container, Typography } from 'designSystem'

import { Card } from '../../Card'
import { FormItemEditAddress } from '../FormItemEditAddress'

interface FormItemEditAddressGroupProps {
  title: string
  name: string
  onSubmitPost: (payload: Address) => void
  onSubmitPatch: (payload: Address) => void
  onDelete: (id: number) => void
  maxCount?: number
  isLoading?: boolean
}

export const FormItemEditAddressGroupBase: FC<FormItemEditAddressGroupProps> = ({
  title,
  name,
  maxCount,
  onSubmitPost,
  onSubmitPatch,
  onDelete,
  isLoading,
}) => {
  const { watch, viewOnlyFields } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name,
  })
  const addressByIndexUpdating = useRef<number>()

  const values: Address[] = watch(name)
  const isFieldViewOnly = getIsFormFieldViewOnly(viewOnlyFields, name)

  const showAddButton =
    !isFieldViewOnly &&
    (maxCount === undefined || fields.length < maxCount) &&
    values?.filter((address) => !address?.id).length < 1

  const handleRemove = useCallback(
    (index: number) => () => {
      const payload = values?.[index]
      if (payload?.id) {
        onDelete(payload.id)
      }
      remove(index)
    },
    [values, remove, onDelete],
  )

  const handleConfirm = useCallback(
    (index: number) => () => {
      addressByIndexUpdating.current = index
      const payload = values?.[index]

      if (payload.id) {
        onSubmitPatch(payload)
      } else {
        onSubmitPost(payload)
      }
    },
    [values, onSubmitPost, onSubmitPatch],
  )

  const addNew = () => append({})

  useEffect(() => {
    if (!isLoading) {
      addressByIndexUpdating.current = undefined
    }
  }, [isLoading])

  return (
    <Container display="flex" fd="column" ai="flex-start" gap={8}>
      <Typography fontWeight="xl">{title}</Typography>
      {fields.map((field, index) => (
        <Card
          pa={3}
          key={field.id}
          showDelete={!isFieldViewOnly}
          onClickDelete={handleRemove(index)}
          isLoading={isLoading && addressByIndexUpdating.current === index}
        >
          <FormItemEditAddress
            name={`${name}.${index}`}
            label={maxCount === 1 ? 'Address' : `Address ${index + 1}`}
            onConfirm={handleConfirm(index)}
          />
        </Card>
      ))}
      {showAddButton && (
        <Button type="link" icon="plus" onClick={addNew}>
          Add
        </Button>
      )}
    </Container>
  )
}

export const FormItemEditAddressGroup = typedMemo(FormItemEditAddressGroupBase)
