import { ReactNode } from 'react'

import { NotificationType } from '../types'

import { Icon } from '../../Icon'

export const getIconByType = (type: NotificationType): ReactNode => {
  switch (type) {
    case 'info':
      return <Icon icon="info" />
    case 'error':
      return <Icon icon="errorOutline" />
    case 'success':
      return <Icon icon="check" />
    case 'warning':
      return <Icon icon="warning" />
    default:
      return <Icon icon="stack" />
  }
}
