import { FocusEvent, forwardRef, useCallback, useState } from 'react'
import { SetFieldValue } from 'react-hook-form'

import { useFormContext } from 'hooks/useForm'
import { typedMemo } from 'types'
import { formatDate, toDate } from 'utils'

import type { DatePickerRef } from 'designSystem'
import { EditDatePicker, EditDatePickerProps, EditView } from 'designSystem'

export interface FormItemEditDatePickerProps extends Omit<EditDatePickerProps, 'name' | 'error' | 'onChange'> {
  name: string
  onChange: SetFieldValue<any>
}

export const FormItemEditDatePickerBase = forwardRef<DatePickerRef, FormItemEditDatePickerProps>(
  ({ disabled, size, onChange, name, onBlur, value, format = 'Standard', ...props }, ref) => {
    const [isEdit, setIsEdit] = useState(false)

    const {
      getFieldState,
      formState: { defaultValues },
    } = useFormContext()

    const { error } = getFieldState(name)

    const defaultValue = defaultValues?.[name]

    const handleViewClick = useCallback(() => {
      setIsEdit(true)
    }, [setIsEdit])

    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        setIsEdit(false)
        onBlur?.(event)
      },
      [onBlur],
    )

    return (
      <>
        {isEdit ? (
          <EditDatePicker
            {...props}
            name={name}
            defaultOpen
            format={format}
            onBlur={handleBlur}
            value={value || defaultValue ? toDate(value || defaultValue) : undefined}
            autoFocus
            status={error && 'error'}
            onChange={onChange}
            ref={ref}
            disabled={disabled}
            size={size}
          />
        ) : (
          <EditView
            layout="horizontal"
            size={size}
            disabled={disabled}
            error={!!error}
            value={formatDate(value, format)}
            onClick={handleViewClick}
          />
        )}
      </>
    )
  },
)

export const FormItemEditDatePicker = typedMemo(FormItemEditDatePickerBase)
