import type { MarginSetting } from 'api'

import { MarginSettingMeasure, MarginSettingOperator } from 'api/types/marginSetting'

export const getMarginDefaultValues = (margin: MarginSetting | undefined | null): MarginSetting => {
  return {
    measure: margin?.measure || MarginSettingMeasure.Amount,
    operator: margin?.operator || MarginSettingOperator.Plus,
    operand: margin?.operand || null,
  }
}
