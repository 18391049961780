import type { ApiQueryParams, LocationCode } from 'api'
import { Pagination } from 'types'

import { api } from '../api'

export interface GetLocationCodesResponse extends Pagination {
  data?: LocationCode[]
}

export type GetLocationCodesQueryParams = ApiQueryParams

export const getLocationCodes = (params?: GetLocationCodesQueryParams): Promise<GetLocationCodesResponse> => {
  return api({
    method: 'get',
    url: '/api/v1/location-codes/',
    params,
  })
}
