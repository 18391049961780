import { FC } from 'react'
import { Radio } from 'antd'

import { VendorType, VendorTypeNames } from 'api'

import { FormItem, Radio as RadioButton, Spacer, Typography } from 'designSystem'

import { UploadTypeOption } from '../../../../constants/uploadTypeOptions'

interface VendorTypeItemProps {
  uploadTypeOptions?: UploadTypeOption[]
  isPartsOnly?: boolean
}

export const VendorTypeItem: FC<VendorTypeItemProps> = ({ uploadTypeOptions, isPartsOnly }) => {
  return (
    <>
      <Typography fontWeight="xl" size="m">
        Vendor type
      </Typography>
      <Spacer mb={3} />
      <FormItem name="vendorType">
        {isPartsOnly ? (
          <RadioButton key={VendorType.Parts} value={VendorType.Parts} checked>
            {VendorTypeNames[VendorType.Parts]}
          </RadioButton>
        ) : (
          <Radio.Group>
            {uploadTypeOptions?.map(({ label, value }) => (
              <RadioButton key={value} value={value}>
                {label}
              </RadioButton>
            ))}
          </Radio.Group>
        )}
      </FormItem>
    </>
  )
}
