import { Typography } from 'designSystem'

export const getFormattedTableCellValue = (value?: number | string | null) => {
  return (
    value || (
      <Typography size="xs" fontWeight="l" color="secondary">
        Empty
      </Typography>
    )
  )
}
