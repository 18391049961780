import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { ModificationPayload } from '../types/modificationCreateForm'

import { postModification } from '../queryApi'

export const usePostModifications = (vendorId: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation({
    mutationFn: postModification,
    onSuccess: () => {
      dispatchAppEvent(AppEvent.ModificationCreate)
      showNotification({ title: 'Modification added', type: 'success' })
    },
  })

  const post = useCallback(
    (payload: ModificationPayload) => {
      mutate({ vendorId, data: payload })
    },
    [mutate, vendorId],
  )

  return {
    post,
    ...rest,
  }
}
