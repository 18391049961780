import { FC } from 'react'

import { UserNotification } from 'api'
import { getUserNotifications } from 'api/queryApi/userNotifications'
import { QueryKey } from 'enums'

import { Container, Spacer, Typography } from 'designSystem'
import { InfinityList } from 'components/InfinityList'

import { SingleNotification } from '../SingleNotification'

export const NotificationsPopover: FC = () => {
  return (
    <Container pa={4} fd="column">
      <Typography fontWeight="xl" size="m">
        Notifications
      </Typography>
      <Spacer mb={4} />
      <InfinityList<UserNotification>
        height={300}
        queryFn={getUserNotifications}
        queryKey={QueryKey.GetUserNotifications}
        singleItem={({ item }) => <SingleNotification {...item} />}
        gap={24}
      />
    </Container>
  )
}
