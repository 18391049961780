import { FormEventHandler, useCallback, useEffect, useMemo, useRef } from 'react'
import differenceWith from 'lodash.differencewith'
import {
  Controller as ControllerForm,
  CriteriaMode,
  DefaultValues,
  FieldValues,
  FormProvider,
  Mode,
  Resolver,
  useFieldArray as useFieldArrayForm,
  useForm as useHookForm,
  useFormContext as useFormContextForm,
  UseFormReturn,
  useFormState as useFormStateForm,
  useWatch as useWatchForm,
} from 'react-hook-form'
import isEqual from 'react-fast-compare'
import { yupResolver } from '@hookform/resolvers/yup'
import type { ObjectSchema } from 'yup'

import { useMemoCompare } from 'hooks/useMemoCompare'

import { FormProps } from './types/form'

import { FormContext, useFormContext as useCustomFormContext } from './contexts/form'

export const useFieldArray = useFieldArrayForm
export const useWatch = useWatchForm
export const Controller = ControllerForm
export const useFormState = useFormStateForm

export type UseFormParams<T extends FieldValues> = {
  criteriaMode?: CriteriaMode
  defaultValues?: DefaultValues<T>
  delayError?: number
  mode?: Mode
  onSubmit?: (values: T) => any
  isSubmitError?: boolean
  onSubmitError?: (error: unknown, setError: UseFormReturn<T>['setError']) => void
  reValidateMode?: 'onBlur' | 'onChange' | 'onSubmit'
  validationSchema: ObjectSchema<T>
  context?: Record<string, any>
  isLoading?: boolean
  isTableEditForm?: boolean
  viewOnlyFields?: string[]
  shouldFocusError?: boolean
}

export const useForm = <T extends FieldValues>(params: UseFormParams<T>) => {
  const hookForm: UseFormReturn<T> = useHookForm<T>({
    context: params.context,
    criteriaMode: params.criteriaMode,
    defaultValues: useMemo(() => params.defaultValues, [params.defaultValues]),
    delayError: params.delayError,
    mode: params.mode || 'onBlur',
    resolver: useMemo(() => yupResolver(params.validationSchema), [params.validationSchema]) as Resolver<any>,
    reValidateMode: params.reValidateMode || 'onChange',
    shouldFocusError: params?.shouldFocusError !== undefined ? params.shouldFocusError : true,
  })

  const hookFormRef = useRef(hookForm)
  const paramsRef = useRef(params)

  hookFormRef.current = hookForm
  paramsRef.current = params

  const triggerSubmit = useCallback(() => {
    if (!params.onSubmit) {
      return undefined
    }

    const trigger = hookFormRef.current.trigger
    trigger().then((isOk) => {
      if (!isOk) {
        return
      }

      const values = hookFormRef.current.getValues()
      const defaultValues = hookFormRef.current.formState.defaultValues
      const errors = hookFormRef.current.formState.errors

      const submittedFields: T = Object.keys(values).reduce((acc, key) => {
        if (!acc) {
          acc = {}
        }

        // Additional prop added to avoid errors in other places
        if (params.isTableEditForm && defaultValues?.[key]) {
          const diff = differenceWith(values?.[key], defaultValues?.[key], isEqual)
          if (diff.length) {
            acc[key] = diff
          }
        } else {
          if (!isEqual(values?.[key], defaultValues?.[key])) {
            acc[key] = values[key]
          }
        }

        return acc
      }, {} as any)

      if (Object.keys(submittedFields).length && !Object.keys(errors).length) {
        hookFormRef.current.handleSubmit(paramsRef.current.onSubmit?.(submittedFields))
      }
    })
  }, [params.isTableEditForm, params.onSubmit])

  const submitHandler: FormEventHandler<HTMLFormElement> = useCallback((event) => {
    event.preventDefault()

    return hookFormRef.current.handleSubmit((values: T) => {
      try {
        paramsRef.current.onSubmit?.(values)
      } catch (e) {
        paramsRef.current.onSubmitError?.(e, hookFormRef.current.setError)
      }
    })
  }, [])

  useEffect(() => {
    if (params.isSubmitError) {
      hookFormRef.current.reset(hookFormRef.current.formState.defaultValues as DefaultValues<T>)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.isSubmitError])

  useEffect(() => {
    if (!isEqual(params.defaultValues, hookFormRef.current.formState.defaultValues)) {
      hookFormRef.current.reset(params.defaultValues)
    }
  }, [params.defaultValues])

  useEffect(() => {
    if (params.isTableEditForm && !params.isLoading) {
      if (!isEqual(hookFormRef.current.formState.defaultValues, params.defaultValues)) {
        hookFormRef.current.reset(params.defaultValues)
      }
    }
  }, [params.isTableEditForm, params.isLoading, params.defaultValues])

  const memoViewOnlyFields = useMemoCompare(params.viewOnlyFields)

  const formProvider = useCallback(
    (props: FormProps) => (
      <FormProvider {...hookFormRef.current}>
        <FormContext.Provider
          value={{
            triggerSubmit,
            isSubmitError: params.isSubmitError,
            viewOnlyFields: memoViewOnlyFields,
          }}
        >
          <form noValidate onSubmit={submitHandler} {...props} />
        </FormContext.Provider>
      </FormProvider>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.isSubmitError, memoViewOnlyFields],
  )

  return {
    ...hookForm,
    submitHandler,
    Form: formProvider,
    triggerSubmit,
  }
}

export const useFormContext = <T extends FieldValues>() => {
  const formContext = useFormContextForm<T>()
  const customFormContext = useCustomFormContext()

  return {
    ...formContext,
    ...customFormContext,
  }
}
