import { Select as SelectBase } from 'antd'
import { css, styled } from 'styled-components'

import { Option, PrefixIcon, SelectWrapper, SelectWrapperProps } from '../SelectMulti/styles'

interface SelectSingleWrapperProps extends SelectWrapperProps {
  isPrefix?: boolean
  isSuffix?: boolean
}

export const SelectSingle = styled(SelectBase)`` as typeof SelectBase

export const SelectSingleWrapper = styled(SelectWrapper)<SelectSingleWrapperProps>`
  &&& .ant-select-selection-item {
    font-weight: ${({ theme }) => theme.fontWeight.l};
    padding-inline-end: 0;
  }

  ${({ isPrefix, theme }) =>
    isPrefix &&
    css`
      && .ant-select-selection-placeholder {
        padding-inline-start: ${theme.spacers[4]};
      }
      &&& .ant-select-selection-search-input {
        padding-left: ${theme.spacers[4]};
      }
      &&& .ant-select-selection-item {
        padding-left: ${theme.spacers[4]};
      }
    `}

  ${({ isSuffix, theme }) =>
    isSuffix &&
    css`
      && .ant-select-selection-placeholder {
        padding-inline-end: ${theme.spacers[4]};
      }
      &&& .ant-select-selection-search-input {
        padding-right: ${theme.spacers[4]};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    `}
`

export const SelectSinglePrefixIcon = styled(PrefixIcon)``

export const SelectSingleOption = styled(Option)``
