import isEqual from 'react-fast-compare'

import { VendorModificationsSettings } from 'api'

import { ModificationFormPayload } from '../types/modificationCreateForm'

export const getPatchPayload = (payload: ModificationFormPayload, defaultData: VendorModificationsSettings[]) => {
  return payload.list?.map((modification) => {
    const { id, name, price, resaleMargin, sku } = modification
    const prev = defaultData.find((item) => item.id === id)

    const payload = {
      ...(prev?.name !== name && { name }),
      ...(prev?.price !== price && { price }),
      ...(!isEqual(prev?.resaleMargin, resaleMargin) && { resaleMargin }),
      ...(prev?.sku !== sku && { sku }),
    }

    return {
      id,
      payload,
    }
  })
}
