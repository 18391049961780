import { FC, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useMemoCompare } from 'hooks'
import { purchaseOrderBillingStatusOptions, purchaseOrderLabels, purchaseOrderReceivingStatusOptions } from 'constant'
import { PurchaseOrderFormAccessor } from 'types'

import { EditFormItem } from 'designSystem'
import { FormItemEditSelectSingle, PurchaseOrderBillingStatusTag, PurchaseOrderReceivingStatusTag } from 'components'

import { useGetPurchaseOrderItems } from '../../../PurchaseOrderDetailsItems/hooks/useGetPurchaseOrderItems'
import { getSubLineCounts } from './utils/getSubLineCounts'
import { updatePurchaseOrderStatuses } from './utils/updatePurchaseOrderStatuses'

export const PurchaseOrderStatuses: FC = () => {
  const { hash } = useLocation()
  const hashRouteKey = Number(hash.split('/')[1])

  const { data } = useGetPurchaseOrderItems({
    purchaseOrderId: hashRouteKey,
  })

  const memorizedData = useMemoCompare(data)

  const { totalBilledSubLines, totalReceivedSubLines, totalSubLines } = useMemo(
    () => getSubLineCounts(memorizedData),
    [memorizedData],
  )

  useEffect(() => {
    if (data) {
      updatePurchaseOrderStatuses({
        totalBilledSubLines,
        totalReceivedSubLines,
        totalSubLines,
        id: hashRouteKey,
      })
    }
  }, [totalBilledSubLines, totalReceivedSubLines, totalSubLines, data, hashRouteKey])

  return (
    <>
      <EditFormItem
        name={PurchaseOrderFormAccessor.ReceivingStatus}
        label={purchaseOrderLabels.receivingStatus}
        render={({ field }) => (
          <FormItemEditSelectSingle
            {...field}
            customPreview={<PurchaseOrderReceivingStatusTag status={field.value} />}
            width={100}
            placeholder="Status"
            options={purchaseOrderReceivingStatusOptions}
          />
        )}
      />
      <EditFormItem
        name={PurchaseOrderFormAccessor.BillingStatus}
        label={purchaseOrderLabels.billingStatus}
        render={({ field }) => (
          <FormItemEditSelectSingle
            {...field}
            customPreview={<PurchaseOrderBillingStatusTag status={field.value} />}
            width={100}
            placeholder="Status"
            options={purchaseOrderBillingStatusOptions}
          />
        )}
      />
    </>
  )
}
