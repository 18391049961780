import type { EmailHistory } from 'api'
import { route } from 'constant'
import { formatDate, stripHTMLString } from 'utils'

import { ReactLink } from 'designSystem'

import { TableColumnKey, TableColumnsType } from '../../Table'

export const getTableColumns = (offsetCount: number): TableColumnsType<EmailHistory> => [
  {
    title: '#',
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => offsetCount + index + 1,
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    ellipsis: true,
    width: 100,
    render: (value: Date) => formatDate(value),
  },
  {
    title: 'Sender',
    dataIndex: 'sender',
    ellipsis: true,
    width: 200,
    render: (sender) => (
      <ReactLink to={`${route.userManagement}/${sender?.id}`}>
        {sender?.firstName} {sender?.lastName}
      </ReactLink>
    ),
  },
  {
    title: 'Recipient',
    dataIndex: 'to',
    ellipsis: true,
    width: 200,
    render: (to) => to?.join(', '),
  },
  {
    title: 'Subject',
    dataIndex: 'subject',
    ellipsis: true,
    width: 200,
  },
  {
    title: 'Summary',
    dataIndex: 'htmlContent',
    ellipsis: true,
    width: 200,
    render: (htmlContent) => stripHTMLString(htmlContent),
  },
]
