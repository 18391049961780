import type { UserTableSettings } from 'types'

export const prepareUserSettingsFilters = (settings?: UserTableSettings): UserTableSettings['filters'] | undefined => {
  if (settings?.filters) {
    const filters = { ...settings.filters }

    Object.keys(filters).forEach((key) => {
      const filterValue = filters[key]?.value

      if (Array.isArray(filterValue)) {
        const filteredValue = filterValue.filter(({ value }) => value !== undefined || value !== null)

        if (filteredValue?.length) {
          filters[key] = {
            ...filters[key],
            value: filteredValue,
          }
        } else {
          delete filters[key]
        }
      } else {
        if (!filterValue?.value) {
          delete filters[key]
        }
      }
    })

    return filters
  }

  return undefined
}
