import { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { Col, Row } from 'antd'

import { PurchaseOrderItem, PurchaseOrderType } from 'api'
import { useFormContext, useMemoCompare, useVendors, useWatch } from 'hooks'
import { purchaseOrderDepartmentOptions, purchaseOrderLabels, purchaseOrderTypeOptions } from 'constant'
import { PurchaseOrderFormAccessor } from 'types'
import { formatDate, toDate } from 'utils'
import { useSetPurchaseOrderVendorPaymentTerm } from 'store'

import { Container, FormItem, SelectSingle, SelectSingleOption, Spacer } from 'designSystem'
import { FormItemSelectSingleAsync, LabelValueMarkup } from 'components'
import { ContactFormData } from 'modules/purchaseOrders/routes/PurchaseOrderCreate/types/purchaseOrderCreateForm'

import * as Styled from './styles'

import { VendorContactsById } from '../VendorContactsById'

interface VendorInformationProps {
  contacts: ContactFormData[]
  setContacts: Dispatch<SetStateAction<ContactFormData[]>>
}

export const VendorInformation: FC<VendorInformationProps> = ({ contacts, setContacts }) => {
  const { setValue, getValues } = useFormContext()
  const vendorId = useWatch({ name: PurchaseOrderFormAccessor.VendorId })
  const setVendorPaymentTerm = useSetPurchaseOrderVendorPaymentTerm()

  const memoContacts: ContactFormData[] = useMemoCompare(contacts)

  const handleVendorChange = useCallback(
    (value: string | number | null | undefined, option: SelectSingleOption | undefined) => {
      setVendorPaymentTerm(option?.data.paymentTerms)
      const addedContacts = memoContacts.filter((contact) => !contact.id)
      setValue(
        PurchaseOrderFormAccessor.ContactsIds,
        addedContacts.map(({ email }) => email),
      )
      setContacts(addedContacts)
    },
    [setVendorPaymentTerm, setContacts, setValue, memoContacts],
  )
  // TODO Add Standard type to PO when will be some requirements for this type from BA
  const filteredPOTypes = purchaseOrderTypeOptions.filter((type) => type.value !== PurchaseOrderType.Standard)

  const handleChangePoType = useCallback(() => {
    const prevValue = getValues(PurchaseOrderFormAccessor.Type)
    if (prevValue === PurchaseOrderType.Standard) {
      const items: PurchaseOrderItem[] = getValues(PurchaseOrderFormAccessor.Items)

      items.forEach((item, index) => {
        setValue(`${PurchaseOrderFormAccessor.Items}.${index}`, {
          ...items[index],
          billedQty: 0,
          receivedQty: 0,
        } satisfies PurchaseOrderItem)
      })
    }
  }, [getValues, setValue])

  return (
    <Container display="flex" fd="column" height="100%">
      <Row gutter={8}>
        <Col span={12}>
          <FormItem name={PurchaseOrderFormAccessor.Department} label={purchaseOrderLabels.department}>
            <SelectSingle options={purchaseOrderDepartmentOptions} placeholder={purchaseOrderLabels.department} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem name={PurchaseOrderFormAccessor.Type} label={purchaseOrderLabels.type}>
            <SelectSingle
              options={filteredPOTypes}
              placeholder={purchaseOrderLabels.type}
              onChange={handleChangePoType}
            />
          </FormItem>
        </Col>
      </Row>
      <Spacer mb={3} />
      <FormItemSelectSingleAsync
        placeholder="Start Typing"
        allowClear={false}
        withFullDataOption
        fields="id,companyName,paymentTerms"
        name={PurchaseOrderFormAccessor.VendorId}
        label={purchaseOrderLabels.vendorId}
        getItems={useVendors}
        onChange={handleVendorChange}
      />
      <Spacer mb={3} />
      <VendorContactsById vendorId={vendorId} contacts={contacts} setContacts={setContacts} />
      <Spacer mb={4} />
      <Styled.CreatedDateWrapper>
        <LabelValueMarkup
          label="Created Time"
          typographyPropsLabel={{ size: 'xs' }}
          typographyPropsValue={{ color: 'primary', size: 'xs' }}
          value={formatDate(toDate())}
        />
      </Styled.CreatedDateWrapper>
    </Container>
  )
}
