import { FC } from 'react'

import { TableFilter, TableFilterValue, useTableContext } from 'hooks'
import { getIncludesFilter } from 'utils'

import { SelectMulti, SelectMultiProps, SelectSingleOption } from 'designSystem'

interface TableFilterSelectMultiProps extends Omit<SelectMultiProps, 'name'> {
  name: string
  getFilterFn?: (field: string, value: TableFilterValue) => TableFilter
}

export const TableFilterSelectMulti: FC<TableFilterSelectMultiProps> = ({
  name,

  mode = 'multiple',
  getFilterFn = getIncludesFilter,
  ...props
}) => {
  const { updateFilters, getFilterValue } = useTableContext()

  const filterValue = getFilterValue?.(name) as SelectSingleOption[] | undefined
  const value = filterValue?.map(({ value }) => value) as (string | number)[]

  const handleFilterChange = (value?: (string | number)[]) => {
    const tableFilterValue = value?.map((v) => ({ value: v })) as TableFilterValue
    updateFilters?.(getFilterFn?.(name, tableFilterValue))
  }

  return (
    <SelectMulti {...props} showSearch allowClear name={name} value={value} mode={mode} onChange={handleFilterChange} />
  )
}
