export enum Accessor {
  Index = 'index',
  Title = 'title',
  Sku = 'sku',
  Price = 'price',
  Qty = 'qty',
  ResaleMargin = 'resaleMargin',
  TotalCost = 'totalCost',
  LocationCodeId = 'locationCodeId',
  Actions = 'actions',
}
