import { FC } from 'react'
import { useLocation } from 'react-router-dom'

import { hashRouteComponents } from './constants/hashRouteComponents'

export const HashRouter: FC = () => {
  const { hash } = useLocation()

  if (!hash) {
    return null
  }

  const hashRouteKey = hash.split('/')[0]?.slice(1) as keyof typeof hashRouteComponents | undefined

  if (!hashRouteKey || !Object.keys(hashRouteComponents).includes(hashRouteKey)) {
    return null
  }

  const Component = hashRouteComponents[hashRouteKey]

  return <Component hash={hash} />
}
