import { Input } from 'antd'
import { css, styled } from 'styled-components'

import type { TextAreaProps } from './TextArea'

export const TextArea = styled(Input.TextArea)<Omit<TextAreaProps, 'onChange'>>`
  padding: 8px;
  width: 100%;
  font-weight: ${({ theme }) => theme.fontWeight.l};
  &&&&.ant-input {
    transition: none;
    min-height: 28px !important;
    border-color: ${({ error, theme }) => !!error && theme.colors.error[400]};

    ${({ size }) => {
      if (size === 'small') {
        return css`
          font-size: ${({ theme }) => theme.fontSize.xs};
          line-height: ${({ theme }) => theme.lineHeight.s};
        `
      }
      return css`
        font-size: ${({ theme }) => theme.fontSize.s};
        line-height: 23.2px;
      `
    }}
  }
`
