import { FC, MouseEvent, ReactNode } from 'react'

import { Button, Spacer, Typography } from 'designSystem'

import { ConfirmationPopoverOkType } from '../../types/сonfirmationPopoverOkType'
import * as Styled from './styles'

interface PopoverContentProps {
  titleText?: ReactNode | string
  text: ReactNode | string
  onOk: (e: MouseEvent) => Promise<void>
  onCancel: () => void
  isLoading?: boolean
  okText?: string
  cancelText?: string
  showOk?: boolean
  okType?: ConfirmationPopoverOkType
  showCancel?: boolean
  disabled?: boolean
}

export const PopoverContent: FC<PopoverContentProps> = ({
  text,
  onOk,
  onCancel,
  isLoading,
  okText,
  cancelText,
  okType = 'danger',
  showOk = true,
  showCancel = true,
  titleText,
  disabled,
}) => {
  return (
    <Styled.Content>
      {typeof titleText === 'string' ? <Typography fontWeight="xl">{titleText}</Typography> : titleText}
      {typeof text === 'string' ? (
        <Typography size="xs" as="p">
          {text}
        </Typography>
      ) : (
        text
      )}
      <Spacer mb={4} />
      <Styled.ButtonWrapper>
        {showCancel && (
          <Button onClick={onCancel} size="small" px={9} width={showOk ? 100 : undefined} fullwidth={!showOk}>
            {cancelText || 'Cancel'}
          </Button>
        )}

        {showOk && (
          <Button
            px={5}
            {...(okType === 'danger' && { danger: true })}
            {...(okType === 'primary' && { type: 'primary' })}
            disabled={disabled}
            onClick={onOk}
            size="small"
            loading={isLoading}
            width={showCancel ? 100 : undefined}
            fullwidth={!showCancel}
          >
            {okText || 'Yes, Delete'}
          </Button>
        )}
      </Styled.ButtonWrapper>
    </Styled.Content>
  )
}
