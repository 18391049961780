import { FC, forwardRef, Ref } from 'react'
import { InputRef } from 'antd/lib/input'

import { typedMemo } from 'types'

import type { InputProps } from 'designSystem'

import * as Styled from './styles'

export type EditInputProps = InputProps

export const EditInputBase: FC<EditInputProps> = forwardRef(({ align = 'right', ...props }, ref: Ref<InputRef>) => {
  return <Styled.Input align={align} {...props} ref={ref} />
})

export const EditInput = typedMemo(EditInputBase)
