import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { companyRoutes } from './constants/companyRoutes'

export const Company: FC = () => {
  return (
    <Routes>
      {companyRoutes.map((companyRoute) => (
        <Route key={companyRoute.path} path={companyRoute.path} element={companyRoute.element} />
      ))}
    </Routes>
  )
}
