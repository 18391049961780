import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReferenceNumberStatus, ReferenceNumberType } from 'api'
import { useForm } from 'hooks'
import { getReferenceTypeOptionLabel, route } from 'constant'

import { Button, Container, Divider, Typography } from 'designSystem'
import { VendorReleaseNumberForm } from 'modules/references/types'

import { useCreateVendorReleaseNumber } from './hooks/useCreateVendorReleaseNumber'
import { vendorReleaseNumberFieldsValidation } from 'modules/references/constants/fieldsValidation'
import { RelatedFields } from './elements/RelatedFields'
import { ReferenceItems } from 'modules/references/elements/ReferenceItems'

import { ReferenceNumberDetailsFields } from '../ReferenceNumberDetailsFields'

export const VendorReleaseNumber: FC = () => {
  const navigate = useNavigate()
  const title = getReferenceTypeOptionLabel(ReferenceNumberType.VendorRelease)
  const { create, isPending } = useCreateVendorReleaseNumber()

  const { Form, handleSubmit } = useForm<VendorReleaseNumberForm>({
    mode: 'onChange',
    validationSchema: vendorReleaseNumberFieldsValidation,
    defaultValues: {
      type: ReferenceNumberType.VendorRelease,
      status: ReferenceNumberStatus.Open,
      items: [{}],
    },
  })

  const onClickCancel = useCallback(() => {
    navigate(route.referencesCreateChooseType)
  }, [navigate])

  return (
    <Form onSubmit={handleSubmit(create)}>
      <Container pa={4}>
        <Typography size="l" fontWeight="xl">
          Create {title} Number
        </Typography>
      </Container>
      <Container pa={4} bg="white" fd="column">
        <Container mb={9}>
          <Container fd="column" width="100%">
            <Typography size="m" fontWeight="xl">
              {title} Details
            </Typography>
            <ReferenceNumberDetailsFields type={ReferenceNumberType.VendorRelease} />
          </Container>
          <Divider vertical mx={4} />
          <Container fd="column" width="100%" gap={8}>
            <RelatedFields />
          </Container>
        </Container>
        <ReferenceItems type={ReferenceNumberType.VendorRelease} />
      </Container>
      <Container pa={4} jc="space-between" gap={8}>
        <Button onClick={onClickCancel}>Cancel</Button>
        <Button type="primary" htmlType="submit" loading={isPending}>
          Save
        </Button>
      </Container>
    </Form>
  )
}
