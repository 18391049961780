import { ReactComponent as IconAddress } from 'assets/icons/address.svg'
import { ReactComponent as IconAllDone } from 'assets/icons/all-done.svg'
import { ReactComponent as IconArrowDown } from 'assets/icons/arrow-down.svg'
import { ReactComponent as IconArrowLeft } from 'assets/icons/arrow-left.svg'
import { ReactComponent as IconArrowRight } from 'assets/icons/arrow-right.svg'
import { ReactComponent as IconArrowUp } from 'assets/icons/arrow-up.svg'
import { ReactComponent as IconArrowsUpDown } from 'assets/icons/arrows-up-down.svg'
import { ReactComponent as IconArrowsUpDownBold } from 'assets/icons/arrows-up-down-bold.svg'
import { ReactComponent as IconBell } from 'assets/icons/bell.svg'
import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg'
import { ReactComponent as IconCancelFilled } from 'assets/icons/cancel-filled.svg'
import { ReactComponent as IconCaretDown } from 'assets/icons/caret-down.svg'
import { ReactComponent as IconCaretUp } from 'assets/icons/caret-up.svg'
import { ReactComponent as IconCategory } from 'assets/icons/category.svg'
import { ReactComponent as IconCheck } from 'assets/icons/check.svg'
import { ReactComponent as IconColumns } from 'assets/icons/columns.svg'
import { ReactComponent as IconCopy } from 'assets/icons/copy.svg'
import { ReactComponent as IconCross } from 'assets/icons/cross.svg'
import { ReactComponent as IconDelete } from 'assets/icons/delete.svg'
import { ReactComponent as IconDots } from 'assets/icons/dots.svg'
import { ReactComponent as IconDownload } from 'assets/icons/download.svg'
import { ReactComponent as IconDraggable } from 'assets/icons/draggable.svg'
import { ReactComponent as IconEdit } from 'assets/icons/edit.svg'
import { ReactComponent as IconErrorFilled } from 'assets/icons/error-filled.svg'
import { ReactComponent as IconErrorOutline } from 'assets/icons/error-outline.svg'
import { ReactComponent as IconFile } from 'assets/icons/file.svg'
import { ReactComponent as IconFolder } from 'assets/icons/folder.svg'
import { ReactComponent as IconGoogle } from 'assets/icons/google.svg'
import { ReactComponent as IconGrid } from 'assets/icons/grid.svg'
import { ReactComponent as IconGridNoOutline } from 'assets/icons/grid-no-outline.svg'
import { ReactComponent as IconGridOutlined } from 'assets/icons/grid-outlined.svg'
import { ReactComponent as IconHistory } from 'assets/icons/history.svg'
import { ReactComponent as IconInfo } from 'assets/icons/info.svg'
import { ReactComponent as IconLinked } from 'assets/icons/linked.svg'
import { ReactComponent as IconLoad } from 'assets/icons/load.svg'
import { ReactComponent as IconLogOut } from 'assets/icons/log-out.svg'
import { ReactComponent as IconLogo } from 'assets/icons/logo.svg'
import { ReactComponent as IconMail } from 'assets/icons/mail.svg'
import { ReactComponent as IconMenu } from 'assets/icons/menu.svg'
import { ReactComponent as IconMinus } from 'assets/icons/minus.svg'
import { ReactComponent as IconOpen } from 'assets/icons/open.svg'
import { ReactComponent as IconPeople } from 'assets/icons/people.svg'
import { ReactComponent as IconPerson } from 'assets/icons/person.svg'
import { ReactComponent as IconPin } from 'assets/icons/pin.svg'
import { ReactComponent as IconPlus } from 'assets/icons/plus.svg'
import { ReactComponent as IconRefresh } from 'assets/icons/refresh.svg'
import { ReactComponent as IconRepeat } from 'assets/icons/repeat.svg'
import { ReactComponent as IconSearch } from 'assets/icons/search.svg'
import { ReactComponent as IconSettings } from 'assets/icons/settings.svg'
import { ReactComponent as IconSliders } from 'assets/icons/sliders.svg'
import { ReactComponent as IconSort } from 'assets/icons/sort.svg'
import { ReactComponent as IconStack } from 'assets/icons/stack.svg'
import { ReactComponent as IconStar } from 'assets/icons/star.svg'
import { ReactComponent as IconTimeFilled } from 'assets/icons/time-filled.svg'
import { ReactComponent as IconTwoArrowsDown } from 'assets/icons/two-arrows-down.svg'
import { ReactComponent as IconTwoArrowsUp } from 'assets/icons/two-arrows-up.svg'
import { ReactComponent as IconUpload } from 'assets/icons/upload.svg'
import { ReactComponent as IconVisibilityOff } from 'assets/icons/visibility-off.svg'
import { ReactComponent as IconVisibilityOn } from 'assets/icons/visibility-on.svg'
import { ReactComponent as IconWarning } from 'assets/icons/warning.svg'

export const iconToComponent = {
  address: IconAddress,
  allDone: IconAllDone,
  mail: IconMail,
  logo: IconLogo,
  arrowDown: IconArrowDown,
  arrowLeft: IconArrowLeft,
  arrowRight: IconArrowRight,
  arrowUp: IconArrowUp,
  arrowsUpDown: IconArrowsUpDown,
  arrowsUpDownBold: IconArrowsUpDownBold,
  calendar: IconCalendar,
  cancelFilled: IconCancelFilled,
  caretDown: IconCaretDown,
  caretUp: IconCaretUp,
  check: IconCheck,
  columns: IconColumns,
  copy: IconCopy,
  cross: IconCross,
  delete: IconDelete,
  dots: IconDots,
  download: IconDownload,
  edit: IconEdit,
  errorFilled: IconErrorFilled,
  errorOutline: IconErrorOutline,
  file: IconFile,
  google: IconGoogle,
  grid: IconGrid,
  gridNoOutline: IconGridNoOutline,
  gridOutlined: IconGridOutlined,
  history: IconHistory,
  info: IconInfo,
  linked: IconLinked,
  load: IconLoad,
  menu: IconMenu,
  open: IconOpen,
  pin: IconPin,
  plus: IconPlus,
  refresh: IconRefresh,
  search: IconSearch,
  settings: IconSettings,
  sliders: IconSliders,
  sort: IconSort,
  stack: IconStack,
  star: IconStar,
  timeFilled: IconTimeFilled,
  twoArrowsDown: IconTwoArrowsDown,
  twoArrowsUp: IconTwoArrowsUp,
  upload: IconUpload,
  visibilityOn: IconVisibilityOn,
  visibilityOff: IconVisibilityOff,
  warning: IconWarning,
  logout: IconLogOut,
  people: IconPeople,
  folder: IconFolder,
  category: IconCategory,
  draggable: IconDraggable,
  person: IconPerson,
  minus: IconMinus,
  repeat: IconRepeat,
  bell: IconBell,
}
