import { FC, MouseEvent, useCallback, useRef } from 'react'
import { Col, Row } from 'antd'
import { useDndContext } from '@dnd-kit/core'

import { ItemCategory } from 'api'
import {
  useIsUserAdmin,
  useIsUserSuperAdmin,
  useItemActiveCategoryId,
  useItemSearch,
  useItemSetActiveCategoryId,
} from 'store'
import theme from 'styles/theme'

import { Container, Icon } from 'designSystem'
import { Draggable, Droppable } from 'components'

import * as Styled from './styles'

import { DeleteCategory } from '../DeleteCategory'
import { FormName } from '../FormName'

export interface SingleCategoryProps {
  item: ItemCategory
  draggable?: boolean
}

export const SingleCategory: FC<SingleCategoryProps> = ({ item, draggable }) => {
  const search = useItemSearch()
  const setActiveCategory = useItemSetActiveCategoryId()
  const activeCategory = useItemActiveCategoryId()
  const dndContext = useDndContext()
  const isAdmin = useIsUserAdmin()
  const isSuperAdmin = useIsUserSuperAdmin()

  const handleApplyCategory = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      setActiveCategory(item.id)
    },
    [item.id, setActiveCategory],
  )
  const draggableId = dndContext?.active?.id
  const draggableRef = useRef<HTMLDivElement>(null)

  const draggableData = {
    item,
    width: draggableRef?.current?.clientWidth,
  }

  const isDragging = draggableId === item.id && !draggable

  return (
    <Droppable id={item.id} data={item}>
      <Styled.Wrapper ref={draggableRef} isDragging={isDragging}>
        <Draggable id={item.id} data={draggableData} disabled={!!search}>
          <Icon icon="draggable" color={theme.colors.placeholder} />
        </Draggable>
        <Styled.Content draggable={draggable}>
          <Row justify="space-between" align="middle" style={{ width: '100%' }}>
            <Col span={16}>
              <FormName item={item} isFieldViewOnly={!isAdmin || !isSuperAdmin} />
            </Col>
            <Col span={8}>
              <Container ai="center" gap={4} jc="flex-end">
                {activeCategory === item.id ? (
                  <Styled.Applied>
                    <Icon icon="check" size={16} />
                    Applied
                  </Styled.Applied>
                ) : (
                  <>
                    <Styled.Button icon="check" size="small" px={2} onClick={handleApplyCategory}>
                      Apply
                    </Styled.Button>
                    {(isAdmin || isSuperAdmin) && <DeleteCategory item={item} />}
                  </>
                )}
              </Container>
            </Col>
          </Row>
        </Styled.Content>
      </Styled.Wrapper>
    </Droppable>
  )
}
