import ModalBase from 'antd/lib/modal'
import { css, styled } from 'styled-components'

import type { ModalProps } from './Modal'

export const Modal = styled(ModalBase)<ModalProps>`
  .ant-modal-content {
    padding: 0;
  }

  & .ant-modal-header {
    margin-bottom: 0;
  }

  .ant-modal-title,
  .ant-modal-body {
    position: relative;
    padding: ${({ theme }) => theme.spacers[7]};
  }

  .ant-modal-title {
    font-size: ${({ theme }) => theme.fontSize.l};
    font-weight: ${({ theme }) => theme.fontWeight.l};
    border-bottom: 1px solid ${({ theme }) => theme.colors.lines};
  }

  .ant-modal-close {
    top: ${({ theme }) => theme.spacers[5]};
    width: unset;
    height: unset;
    padding: ${({ theme }) => theme.spacers[3]};

    .ant-modal-close-x {
      display: flex;
    }
  }

  ${({ isTabsBody }) =>
    isTabsBody &&
    css`
      & .ant-modal-body {
        padding: 0;
      }

      .ant-tabs-content {
        padding: ${({ theme }) => `${theme.spacers[4]} ${theme.spacers[7]} ${theme.spacers[7]}`};
      }
    `}

  ${({ pa, theme }) =>
    pa !== undefined &&
    css`
      & .ant-modal-body {
        padding: ${theme.spacers[pa]};
      }
    `}
`

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255, 0.5);
  }
`
