import { useQuery } from '@tanstack/react-query'

import { getItemsCategories } from 'api'
import { QueryKey } from 'enums'

export const useGetItemCategories = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QueryKey.GetItemCategories],
    queryFn: getItemsCategories,
    gcTime: 0,
    staleTime: 0,
  })

  return {
    data,
    ...rest,
  }
}
