import { useEffect } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { EntityId, getReferenceItems } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'

import { getIsAllSubLinesStatusReceived } from '../utils/getIsAllSubLinesStatusReceived'

export const useGetReferenceItems = (referenceId?: EntityId, shouldCheckAllReceived?: boolean) => {
  const { data, isLoading, isFetching, fetchStatus, ...rest } = useQuery({
    queryKey: [QueryKey.GetReferenceItems, referenceId],
    queryFn: () => getReferenceItems(referenceId),
    enabled: !!referenceId,
    placeholderData: keepPreviousData,
  })

  useEffect(() => {
    if (shouldCheckAllReceived) {
      const isAllReceived = getIsAllSubLinesStatusReceived(data?.data)
      if (isAllReceived) {
        queryClient.invalidateQueries({ queryKey: [QueryKey.GetReferenceNumberById, referenceId] })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data])

  return {
    data: data?.data,
    isLoading: (isLoading || isFetching) && fetchStatus !== 'idle',
    ...rest,
  }
}
