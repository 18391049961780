import { getVendors, Vendor } from 'api'
import { QueryKey } from 'enums'
import { AsyncSelectGetItemsProps } from 'types'

import type { SelectSingleOption } from 'designSystem'

import { useAsyncList } from './useAsyncList'

export const useVendors = ({
  valuePropName, // keyof Vendor - the value of it should be of type string | number
  withFullDataOption,
  fields,
  ...props
}: AsyncSelectGetItemsProps) => {
  const { list, ...asyncListProps } = useAsyncList<Vendor>({
    ...props,
    queryFn: getVendors,
    queryKey: QueryKey.GetVendorsList,
    searchFieldName: 'companyName',
    limit: 10,
    fields: fields || 'id,companyName',
  })

  const options = list.map((vendor) => ({
    value: valuePropName ? (vendor[valuePropName as keyof Vendor] as string | number) : vendor.id,
    label: vendor.companyName,
    ...(withFullDataOption && { data: vendor }),
  })) satisfies SelectSingleOption[]

  return {
    options,
    ...asyncListProps,
  }
}
