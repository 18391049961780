import { UserSettings } from 'api'
import type { AppModule, UserTableSettings } from 'types'

import { checkIfUserSettingsChanged } from './utils/checkIfUserSettingsChanged'
import { prepareUserSettingsFilters } from './utils/prepareUserSettingsFilters'
import { defaultUserTablesSettings } from './constants/defaultUserTablesSettings'
import { tablePageLimits } from 'components/Table/constants/tablePageLimits'

import { saveModuleTableSettings, saveUserSettings } from './queryApi'

class UserSettingsServiceInstance {
  public tableSettings: UserTableSettings[] = defaultUserTablesSettings
  public userSettings: UserSettings = {
    pageLimit: tablePageLimits[0],
  }

  private findModuleTableSetting(moduleName?: AppModule) {
    return this.tableSettings.find((tableSettings) => tableSettings.module === moduleName)
  }

  private saveModuleTableSettingsRequest(moduleName: AppModule) {
    const newModuleSetting = this.findModuleTableSetting(moduleName)

    if (newModuleSetting) {
      const filters = prepareUserSettingsFilters(newModuleSetting)
      return saveModuleTableSettings({ ...newModuleSetting, filters })
    }
  }

  public getModuleTableSettings(moduleName?: AppModule) {
    if (moduleName) {
      return this.findModuleTableSetting(moduleName)
    }

    return undefined
  }

  public setInitialTableSettings(settings: UserTableSettings[]) {
    this.tableSettings = defaultUserTablesSettings.map((prevSetting) => {
      let setting = prevSetting

      settings.forEach((newSetting) => {
        if (prevSetting.module === newSetting.module) {
          setting = newSetting
        }
      })
      return setting
    })
  }

  public setModuleTableSettings(moduleName?: AppModule, settings?: Partial<UserTableSettings>) {
    const prevSettings = this.findModuleTableSetting(moduleName) as UserTableSettings
    const newSettings = { ...prevSettings, ...settings }

    this.tableSettings = this.tableSettings.map((prevSetting) => {
      if (moduleName === prevSetting.module) {
        return newSettings as UserTableSettings
      }

      return prevSetting
    })

    if (moduleName) {
      const settingsChanged = checkIfUserSettingsChanged(prevSettings, newSettings)

      if (settingsChanged) {
        this.saveModuleTableSettingsRequest(moduleName)
      }
    }
  }

  private saveUserSettings(settings: Partial<UserSettings>) {
    return saveUserSettings(settings)
  }

  public setUserSettings(settings: UserSettings) {
    this.userSettings = settings
  }

  public setTablePageLimit(pageLimit: number) {
    if (this.userSettings.pageLimit !== pageLimit) {
      this.userSettings.pageLimit = pageLimit
      this.saveUserSettings({ pageLimit })
    }
  }

  public getTablePageLimit(): number {
    return this.userSettings.pageLimit
  }
}

export const UserSettingsService = new UserSettingsServiceInstance()
