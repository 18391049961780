import type { AxiosError, AxiosResponse } from 'axios'

import { ErrorCode } from 'enums'

export type ApiInstanceResponse<T> = Omit<AxiosResponse, 'data'> & { data: T }

export interface ErrorResponseData {
  code: ErrorCode
  message: string
  responseStatus: string
  statusCode: number
}

export interface ApiError extends AxiosError {
  response?: AxiosResponse<ErrorResponseData>
}

export const apiUrl = process.env.REACT_APP_API_URL

export const apiConfig = {
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    withAuth: true, // Adding Authorization header by default
  },
}
