export const purchaseOrderItemsParams = {
  join: [
    'item',
    'subLines',
    'subLines.asset',
    'subLines.locationCode||id,code',
    'subLines.vendorDepot||id,code',
    'subLines.asset.redeliveryReferenceNumber||id,number,status',
    'subLines.asset.vendorReleaseReferenceNumber||id,number,status',
  ],
  sort: ['id,DESC', 'subLines.id,DESC'],
}
