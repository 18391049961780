import { getReferenceNumbers, ReferenceNumber } from 'api'
import { QueryKey } from 'enums'
import { AsyncSelectGetItemsProps } from 'types'

import type { SelectSingleOption } from 'designSystem'

import { useAsyncList } from './useAsyncList'

export const useReferences = ({ withFullDataOption, fields, ...props }: AsyncSelectGetItemsProps) => {
  const { list, ...asyncListProps } = useAsyncList<{ reference: ReferenceNumber }>({
    ...props,
    queryFn: getReferenceNumbers,
    queryKey: QueryKey.GetReferencesList,
    searchFieldName: 'number',
    limit: 10,
    fields: fields || 'id,number',
  })

  const options = list.map(({ reference }) => ({
    value: reference.id,
    label: reference.number,
    ...(withFullDataOption && { data: reference }),
  })) satisfies SelectSingleOption[]

  return {
    options,
    ...asyncListProps,
  }
}
