// Write helpers for formatting query api here

import type { TableFilter, TableFilterValue } from 'hooks'

import type { DateRangePickerValue, InputNumberValue } from 'designSystem'

import { QueryCondition } from 'api/types/queryCondition'

import { endOfDayDate, startOfDayDate } from './formatDate'

const formatTableFilterValue = (value: TableFilterValue): TableFilterValue => {
  const isArrayValue = Array.isArray(value)
  if (isArrayValue) {
    const newValue = value.filter((v) => !!v.value)
    return newValue?.length ? newValue : undefined
  } else {
    return value?.value ? { value: value.value } : undefined
  }
}

export const getRangeFilter = (field: string, from: InputNumberValue, to: InputNumberValue): TableFilter => {
  if (from && to) {
    return { [field]: { value: [{ value: String(from) }, { value: String(to) }], condition: QueryCondition.Between } }
  }
  if (from) {
    return { [field]: { value: { value: String(from) }, condition: QueryCondition.GraterThanEqual } }
  }
  if (to) {
    return { [field]: { value: { value: String(to) }, condition: QueryCondition.LessThanEqual } }
  }
  return { [field]: { value: undefined, condition: QueryCondition.Between } }
}

export const getDateRangeFilter = (field: string, dates?: DateRangePickerValue) => {
  const [start, end] = dates || []
  if (start && end) {
    return {
      [field]: {
        value: [{ value: startOfDayDate(start).toISOString() }, { value: endOfDayDate(end).toISOString() }],
        condition: QueryCondition.Between,
      },
    }
  }
  return { [field]: { value: undefined, condition: QueryCondition.Between } }
}

export const getIncludesFilter = (field: string, value: TableFilterValue) => {
  return {
    [field]: {
      value: formatTableFilterValue(value),
      condition: QueryCondition.Includes,
    },
  }
}
export const getContainsArrayFilter = (field: string, value: TableFilterValue) => {
  return {
    [field]: {
      value: formatTableFilterValue(value),
      condition: QueryCondition.ContainsArray,
    },
  }
}

export const getIntersectsArrayFilter = (field: string, value: TableFilterValue) => {
  return {
    [field]: {
      value: formatTableFilterValue(value),
      condition: QueryCondition.IntersectsArray,
    },
  }
}

export const getEqualsFilter = (field: string, value: TableFilterValue) => {
  return { [field]: { value, condition: QueryCondition.Equal } }
}
export const getNotNullFilter = (field: string, value: TableFilterValue) => {
  return { [field]: { value, condition: QueryCondition.NotNull } }
}

// Search
export const getMultipleSearch = (fieldName: string, fields: string[], search: string) => {
  if (!search) {
    return undefined
  }

  const fieldsValues = fields.map((field) => ({
    [field]: { [QueryCondition.ContainsLike]: search },
  }))

  return {
    field: fieldName,
    search,
    value: { $or: fieldsValues },
  }
}

export const getSingleSearch = (field: string, search: string) => {
  if (!search) {
    return undefined
  }

  return {
    field,
    search,
    value: { [field]: { [QueryCondition.ContainsLike]: search } },
  }
}
