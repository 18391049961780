import { VendorInventory, VendorType } from 'api'
import { useEditableTable, useTable } from 'hooks'

import { useGetInventory } from './useGetInventory'

export const useInventory = (vendorId: number, type: VendorType) => {
  const { pagination, onChange, getFieldSorter, sortQueryParams, searchQueryParams, ...props } = useTable<
    Partial<VendorInventory>
  >({})

  const {
    data = [],
    total,
    isLoading,
    isFetching,
  } = useGetInventory(
    {
      vendorId,
      limit: pagination.limit,
      page: pagination.page,
      sort: sortQueryParams,
      s: searchQueryParams,
      join: ['locationCode'],
    },
    type,
  )

  const editableTable = useEditableTable<VendorInventory>({
    data,
  })

  return {
    ...editableTable,
    data: data,
    pagination: {
      ...pagination,
      current: pagination.page,
      pageSize: pagination.limit,
      total,
    },
    getFieldSorter,
    onTableChange: onChange,
    isLoading: isLoading || isFetching,
    offsetCount: (pagination.page - 1) * pagination.limit,
    ...props,
  }
}
