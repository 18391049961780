import { create } from 'zustand'

import { PurchaseOrder, PurchaseOrderItem } from 'api'

import { PurchaseOrderPayload } from 'modules/purchaseOrders/routes/PurchaseOrderCreate/types/purchaseOrderCreateForm'

export type PurchaseOrderDataStorePayload = Partial<Omit<PurchaseOrderPayload, 'items'>> & {
  items?: Partial<PurchaseOrderItem>[]
}

interface PurchaseOrderDataStore {
  data?: PurchaseOrder
  setData: (data?: PurchaseOrder) => void
  setPartialData: (data?: Partial<PurchaseOrder>) => void
  payload?: PurchaseOrderDataStorePayload
  setPayload: (payload?: PurchaseOrderDataStorePayload) => void
  requestId?: string
  setRequestId: (requestId?: string) => void
}

const usePurchaseOrderDataStore = create<PurchaseOrderDataStore>((set) => ({
  data: undefined,
  setData: (data?: PurchaseOrder) => set({ data }),
  setPartialData: (data?: Partial<PurchaseOrder>) =>
    set((prev) => ({ data: { ...prev.data, ...(data as PurchaseOrder) } })),
  payload: undefined,
  setPayload: (payload?: PurchaseOrderDataStorePayload) => set({ payload }),
  requestId: undefined,
  setRequestId: (requestId?: string) => set({ requestId }),
}))

export const usePurchaseOrderData = () => usePurchaseOrderDataStore((state) => state.data)
export const useSetPurchaseOrderData = () => usePurchaseOrderDataStore((state) => state.setData)
export const useSetPartialPurchaseOrderData = () => usePurchaseOrderDataStore((state) => state.setPartialData)
export const usePurchaseOrderPayload = () => usePurchaseOrderDataStore((state) => state.payload)
export const useSetPurchaseOrderPayload = () => usePurchaseOrderDataStore((state) => state.setPayload)

export const usePurchaseOrderRequestId = () => usePurchaseOrderDataStore((state) => state.requestId)
export const useSetPurchaseOrderRequestId = () => usePurchaseOrderDataStore((state) => state.setRequestId)
