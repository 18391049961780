import { referenceLabels } from 'constant'

import { Accessor } from '../types/accessor'

export const getAccessorTitle = (accessor: Accessor): string => {
  return {
    [Accessor.Index]: '#',
    [Accessor.Number]: referenceLabels.number,
    [Accessor.Type]: referenceLabels.type,
    [Accessor.LocationCode]: referenceLabels.locationCode,
    [Accessor.DepotSetting]: referenceLabels.depotSetting,
    [Accessor.Items]: 'Item SKU',
    [Accessor.Assets]: referenceLabels.assets,
    [Accessor.CreatedAt]: 'Issue Date',
    [Accessor.Status]: referenceLabels.status,
    [Accessor.Notes]: referenceLabels.notes,
    [Accessor.RelatedReference]: 'Related Reference',
  }[accessor]
}
