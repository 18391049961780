import { SalesOrderAccountingStatus } from 'api/types/salesOrder/salesOrderAccountingStatus'

export const salesOrderAccountingStatusOptionsLabel = {
  [SalesOrderAccountingStatus.BeforeLaunching]: 'Before Launching',
  [SalesOrderAccountingStatus.ApprovedPo]: 'Approved - PO',
  [SalesOrderAccountingStatus.ApprovedPaid]: 'Approved - Paid',
  [SalesOrderAccountingStatus.ApprovedDepositPaid]: 'Approved - Deposit Paid',
  [SalesOrderAccountingStatus.ApprovedCod]: 'Approved - Cod',
  [SalesOrderAccountingStatus.PendingVerification]: 'Pending Verification',
  [SalesOrderAccountingStatus.Pending]: 'Pending',
  [SalesOrderAccountingStatus.HoldDelivery]: 'Hold Delivery',
  [SalesOrderAccountingStatus.ContactAccountingForBalance]: 'Contact Accounting For Balance',
  [SalesOrderAccountingStatus.ApprovedProcurement]: 'Approved - Procurement',
  [SalesOrderAccountingStatus.OnHoldWaitingForCheck]: 'On Hold - Waiting For Check',
  [SalesOrderAccountingStatus.OnHoldCreditCardDeclined]: 'On Hold - Credit Card Declined',
  [SalesOrderAccountingStatus.Cancelled]: '',
}

export const salesOrderAccountingStatusOptions = Object.values(SalesOrderAccountingStatus).map((status) => ({
  label: salesOrderAccountingStatusOptionsLabel[status],
  value: status,
}))
