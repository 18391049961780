import { FC } from 'react'

import { Container, Spacer, Typography } from 'designSystem'

import { calculatorFeeList } from './constants/calculatorFeeList'

import { FeeItem } from '../FeeItem'

export const CalculatorFeeList: FC = () => {
  return (
    <>
      <Typography fontWeight="xl" size="s">
        Calculator Fee
      </Typography>
      <Spacer mb={2} />
      <Container fd="column" gap={8} maxWidth={220}>
        {calculatorFeeList.map(({ name, label }) => (
          <FeeItem name={name} label={label} key={name} />
        ))}
      </Container>
    </>
  )
}
