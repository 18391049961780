import { Dispatch, FC, SetStateAction } from 'react'
import { Row } from 'antd'

import { ContactFormData } from '../../types/purchaseOrderCreateForm'
import { AdditionalInformation } from './elements/AdditionalInformation'
import { DeliveryInformation } from './elements/DeliveryInformation'
import { VendorInformation } from './elements/VendorInformation'
import * as Styled from './styles'

interface PurchaseOrderFormProps {
  contacts: ContactFormData[]
  setContacts: Dispatch<SetStateAction<ContactFormData[]>>
}

export const PurchaseOrderForm: FC<PurchaseOrderFormProps> = ({ contacts, setContacts }) => {
  return (
    <Row>
      <Styled.Col span={8}>
        <VendorInformation setContacts={setContacts} contacts={contacts} />
      </Styled.Col>
      <Styled.Col span={8}>
        <DeliveryInformation />
      </Styled.Col>
      <Styled.Col span={8}>
        <AdditionalInformation />
      </Styled.Col>
    </Row>
  )
}
