import { FC, useCallback, useState } from 'react'
import { Popover } from 'antd'

import { useUser } from 'store'

import { Avatar } from 'components/Avatar'

import * as Styled from './styles'

import { AvatarMenu } from '../AvatarMenu'

export const HeaderAvatar: FC = () => {
  const user = useUser()
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false)
  const handlePopoverOpenChange = useCallback((visible: boolean) => {
    setIsPopoverVisible(visible)
  }, [])

  const closePopover = useCallback(() => {
    setIsPopoverVisible(false)
  }, [])

  if (!user) return null

  return (
    <Popover
      onOpenChange={handlePopoverOpenChange}
      open={isPopoverVisible}
      placement="bottomRight"
      content={<AvatarMenu closePopover={closePopover} />}
      trigger="click"
      arrow={false}
    >
      <Styled.AvatarWrapper>
        <Avatar user={user} />
      </Styled.AvatarWrapper>
    </Popover>
  )
}
