import { Asset, PurchaseOrderItem, ReferenceNumberType } from 'api'

const getSubLineType = (asset?: Asset) => {
  if (asset?.vendorReleaseReferenceNumber?.number) {
    return ReferenceNumberType.VendorRelease
  }
  if (asset?.redeliveryReferenceNumber?.number) {
    return ReferenceNumberType.Redelivery
  }
  return null
}

export const getItemsDefaultData = (items: PurchaseOrderItem[]) => {
  const updatedData = items.map((item) => {
    return {
      ...item,
      subLines: item.subLines?.map((subLine) => {
        return {
          ...subLine,
          type: getSubLineType(subLine.asset),
          referenceNumber:
            subLine.asset?.vendorReleaseReferenceNumber?.number || subLine.asset?.redeliveryReferenceNumber?.number,
          asset: {
            ...subLine.asset,
            vendorReleaseNumber: subLine.asset?.vendorReleaseReferenceNumber?.number,
            redeliveryNumber: subLine.asset?.redeliveryReferenceNumber?.number,
          },
        }
      }),
    }
  })
  return updatedData
}
