import { ActivityStreamline, ActivityStreamlineType } from 'api'
import {
  itemLabels,
  purchaseOrderItemLabels,
  purchaseOrderItemSubLineLabels,
  purchaseOrderLabels,
  referenceItemLabels,
  referenceItemSubLineLabels,
  referenceLabels,
} from 'constant'

export const getEntityData = (type: ActivityStreamlineType, data: ActivityStreamline['data']) => {
  return {
    [ActivityStreamlineType.PurchaseOrderEntity]: {
      title: 'Purchase Order',
      labels: purchaseOrderLabels,
    },
    [ActivityStreamlineType.PurchaseOrderItemEntity]: {
      subTitle: JSON.stringify(data.input?.title),
      labels: purchaseOrderItemLabels,
    },
    [ActivityStreamlineType.PurchaseOrderSubLineEntity]: {
      subTitle: JSON.stringify(data.input?.asset?.assetNumber),
      labels: purchaseOrderItemSubLineLabels,
    },
    [ActivityStreamlineType.PurchaseOrderContactEntity]: {
      // TODO: add contact labels
    },
    [ActivityStreamlineType.ReferenceEntity]: {
      title: 'Reference',
      labels: referenceLabels,
    },
    [ActivityStreamlineType.ReferenceItemEntity]: {
      subTitle: JSON.stringify(data.input?.title),
      labels: referenceItemLabels,
    },
    [ActivityStreamlineType.ReferenceItemSubLineEntity]: {
      subTitle: JSON.stringify(data.input?.asset?.assetNumber),
      labels: referenceItemSubLineLabels,
    },
    [ActivityStreamlineType.ReferenceContactEntity]: {
      // TODO: add contact labels
    },
    [ActivityStreamlineType.ItemEntity]: {
      title: 'Item',
      labels: itemLabels,
    },
  }[type]
}
