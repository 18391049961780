import { createContext, Dispatch, SetStateAction } from 'react'

import { ReferenceNumber, ReferenceNumberItem } from 'api'

import { ReferenceItemsAssetsIds } from '../types/referenceItemsAssetsIds'

export interface ReferenceItemsContextData {
  data?: ReferenceNumberItem[]
  canChangeQty?: boolean
  isFieldViewOnly?: (field: string) => boolean
  referenceData?: Partial<ReferenceNumber>
  assetsIds?: ReferenceItemsAssetsIds
  setAssetsIds?: Dispatch<SetStateAction<ReferenceItemsAssetsIds>>
}

export const ReferenceItemsContext = createContext<ReferenceItemsContextData>({
  data: [],
})
