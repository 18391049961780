import { MarginSetting, MarginSettingMeasure, MarginSettingOperator } from 'api'

interface TotalCostRecord {
  resaleMargin: MarginSetting | null
  price: number | null
}

export const getTotalCost = <T extends TotalCostRecord>(record: Partial<T> | undefined): string | number | null => {
  const { resaleMargin } = record || {}
  if (!resaleMargin?.operand || !record?.price) {
    return null
  }

  let totalCost = 0

  if (resaleMargin.measure === MarginSettingMeasure.Percentage) {
    totalCost =
      resaleMargin.operator === MarginSettingOperator.Plus
        ? record.price + (record.price * resaleMargin.operand) / 100
        : record.price - (record.price * resaleMargin.operand) / 100
  } else {
    totalCost =
      resaleMargin.operator === MarginSettingOperator.Plus
        ? record.price + resaleMargin.operand
        : record.price - resaleMargin.operand
  }

  return totalCost.toString().split('.')[1]?.length > 1 ? totalCost.toFixed(2) : totalCost
}
