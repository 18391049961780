import { Tab } from 'designSystem'

import { UploadTypeOption } from 'modules/vendors/constants/uploadTypeOptions'
import { FeedUrlTab } from '../elements/FeedUrlTab'
import { UploadLocalFile } from 'modules/vendors/elements/UploadLocalFile'

interface GetTabItemsProps {
  vendorId: number
  handleCloseModal: () => void
  uploadTypeOptions: UploadTypeOption[]
  isParts: boolean
}

export const getTabItems = ({ vendorId, handleCloseModal, uploadTypeOptions, isParts }: GetTabItemsProps): Tab[] => {
  return [
    {
      key: 'localFile',
      label: 'Local File',
      children: (
        <UploadLocalFile
          vendorId={vendorId}
          handleCloseModal={handleCloseModal}
          uploadTypeOptions={uploadTypeOptions}
        />
      ),
    },
    ...(isParts
      ? [
          {
            key: 'feedUrl',
            label: 'Feed URL',
            children: <FeedUrlTab vendorId={vendorId} handleCloseModal={handleCloseModal} />,
          },
        ]
      : []),
  ]
}
