import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { EntityId } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { AssetDetailsFormData } from '../types/assetForm'

import { patchAsset } from '../queryApi'

export const useUpdateAsset = (id: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation({
    mutationFn: patchAsset,
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.AssetUpdate)

      queryClient.setQueryData([QueryKey.GetAssetById, id], data)
      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const updateAsset = useCallback(
    (payload: AssetDetailsFormData) => {
      const data = {
        ...payload,
        ...(payload.itemsIds && {
          itemsIds: payload.itemsIds?.map(({ value }) => value),
        }),
      }

      mutate({ id, data })
    },
    [mutate, id],
  )

  return {
    updateAsset,
    ...rest,
  }
}
