import { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { useForm } from 'hooks'
import { formatDate } from 'utils'

import { Container, Divider, EditFormItem } from 'designSystem'
import {
  Card,
  ConfirmationRequiredPopover,
  FormItemEditAddress,
  FormItemEditInput,
  LabelValueMarkup,
  Spinner,
  StripLoader,
} from 'components'

import { useGetLocationCodeById } from '../../hooks/useGetLocationCodeById'
import { useUpdateLocationCode } from './hooks/useUpdateLocationCode'
import { validationSchema } from '../../../../utils/validationSchema'
import { LocationCodeForm } from '../../../../types/locationCodeForm'
import { LocationCodePayload } from '../../../../types/locationCodePayload'

export const LocationCodeDetails: FC = () => {
  const { id } = useParams()
  const { data, isLoading } = useGetLocationCodeById(Number(id))
  const { update, isError, isPending } = useUpdateLocationCode(Number(id))

  const onSubmit = useCallback(
    (payload: LocationCodePayload) => {
      update(payload)
    },
    [update],
  )

  const { Form, triggerSubmit } = useForm<LocationCodeForm>({
    validationSchema,
    mode: 'onChange',
    onSubmit,
    isSubmitError: isError,
    defaultValues: data,
  })

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Container pa={4} fd="column" display="flex" gap={8} fw="wrap">
      <Card maxWidth={390}>
        <StripLoader isLoading={isPending} />
        <Form>
          <EditFormItem
            name="code"
            render={({ field }) => (
              <FormItemEditInput
                size="large"
                align="left"
                {...field}
                onConfirm={triggerSubmit}
                popover={<ConfirmationRequiredPopover withPopover />}
              />
            )}
          />

          <Divider mt={7} mb={4} />
          <EditFormItem
            name="name"
            label="Name"
            render={({ field }) => <FormItemEditInput {...field} onBlur={triggerSubmit} />}
          />
          <Divider my={4} />
          <FormItemEditAddress label="Address" onConfirm={triggerSubmit} hideFields={['street', 'zipCode']} />
        </Form>
      </Card>
      <Card maxWidth={390}>
        <LabelValueMarkup
          label="Created By"
          value={data?.createdBy ? `${data?.createdBy.firstName} ${data?.createdBy.lastName}` : '-'}
        />
        <LabelValueMarkup label="Created Date" value={formatDate(data?.createdAt)} />
      </Card>
    </Container>
  )
}
