import { ReferenceNumberBookingTransactionType } from 'api/types/referenceNumber/referenceNumberBookingTransactionType'

export const referenceNumberBookingTransactionTypeLabel = {
  [ReferenceNumberBookingTransactionType.Sale]: 'Sale',
  [ReferenceNumberBookingTransactionType.Rental]: 'Rental',
  [ReferenceNumberBookingTransactionType.Transfer]: 'Transfer',
  [ReferenceNumberBookingTransactionType.Split]: 'Split',
  [ReferenceNumberBookingTransactionType.Recycle]: 'Recycle',
  [ReferenceNumberBookingTransactionType.RentalSwap]: 'Rental Swap',
  [ReferenceNumberBookingTransactionType.SaleSwap]: 'Sale Swap',
  [ReferenceNumberBookingTransactionType.Rejection]: 'Rejection',
}

export const referenceNumberBookingTransactionTypeOptions: {
  value: ReferenceNumberBookingTransactionType
  label: string
}[] = [
  {
    value: ReferenceNumberBookingTransactionType.Sale,
    label: referenceNumberBookingTransactionTypeLabel[ReferenceNumberBookingTransactionType.Sale],
  },
  {
    value: ReferenceNumberBookingTransactionType.Rental,
    label: referenceNumberBookingTransactionTypeLabel[ReferenceNumberBookingTransactionType.Rental],
  },
  {
    value: ReferenceNumberBookingTransactionType.Transfer,
    label: referenceNumberBookingTransactionTypeLabel[ReferenceNumberBookingTransactionType.Transfer],
  },
  {
    value: ReferenceNumberBookingTransactionType.Split,
    label: referenceNumberBookingTransactionTypeLabel[ReferenceNumberBookingTransactionType.Split],
  },
  {
    value: ReferenceNumberBookingTransactionType.Recycle,
    label: referenceNumberBookingTransactionTypeLabel[ReferenceNumberBookingTransactionType.Recycle],
  },
  {
    value: ReferenceNumberBookingTransactionType.RentalSwap,
    label: referenceNumberBookingTransactionTypeLabel[ReferenceNumberBookingTransactionType.RentalSwap],
  },
  {
    value: ReferenceNumberBookingTransactionType.SaleSwap,
    label: referenceNumberBookingTransactionTypeLabel[ReferenceNumberBookingTransactionType.SaleSwap],
  },
  {
    value: ReferenceNumberBookingTransactionType.Rejection,
    label: referenceNumberBookingTransactionTypeLabel[ReferenceNumberBookingTransactionType.Rejection],
  },
]
