import { FC } from 'react'

import { LocationCode } from 'api'
import { useFormContext, useLocationCodes } from 'hooks'
import { currency } from 'constant'

import { EditFormItem, Typography } from 'designSystem'
import { FormItemEditGoogleAutocomplete, FormItemEditInputNumber, FormItemEditSelectSingleAsync } from 'components'

import { CityStateView } from '../../../CityStateView'

interface TableOptionOneProps {
  name: string
  locationCode?: LocationCode | null
}

export const TableOptionOne: FC<TableOptionOneProps> = ({ name, locationCode }) => {
  const { triggerSubmit } = useFormContext()

  return (
    <>
      <Typography size="xs" color="secondary" nowrap>
        Flat Fee
      </Typography>
      <EditFormItem
        name={`${name}.flatFeePrice`}
        render={({ field }) => (
          <FormItemEditInputNumber
            align="left"
            size="small"
            width={80}
            prefix={currency}
            precision={2}
            placeholder="Price"
            {...field}
            onBlur={triggerSubmit}
          />
        )}
      />
      <Typography size="xs" color="secondary" nowrap>
        from
      </Typography>
      <FormItemEditSelectSingleAsync
        name={`${name}.locationCodeId`}
        size="small"
        onSubmit={triggerSubmit}
        width={120}
        defaultOption={{
          value: locationCode?.id as number,
          label: locationCode?.code as string,
        }}
        getItems={useLocationCodes}
      />
      <Typography size="xs" color="secondary" nowrap>
        to ZIP:
      </Typography>
      <EditFormItem
        name={`${name}.zipCode`}
        render={({ field }) => (
          <FormItemEditGoogleAutocomplete
            size="small"
            width={160}
            placeholder="ZIP"
            types={['postal_code']}
            fieldsNames={{ zipCode: `${name}.zipCode`, city: `${name}.city`, state: `${name}.state` }}
            {...field}
            onBlur={triggerSubmit}
          />
        )}
      />
      <CityStateView name={name} size="xs" color="secondary" />
    </>
  )
}
