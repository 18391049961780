import { styled } from 'styled-components'

import { Container } from 'designSystem'

export const CheckboxWrapper = styled(Container)`
  .ant-checkbox-wrapper {
    flex-direction: row-reverse;
    justify-content: space-between;

    & + .ant-checkbox-wrapper {
      margin-inline-start: 0;
    }

    > span:last-of-type {
      flex-grow: 1;
      padding-left: 0;
    }
  }
`
