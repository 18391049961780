import { ReferenceNumberPickupUrgency } from 'api/types/referenceNumber/referenceNumberPickupUrgency'

export const referenceNumberPickupUrgencyOptions = [
  { value: ReferenceNumberPickupUrgency.High, label: 'High' },
  { value: ReferenceNumberPickupUrgency.Medium, label: 'Medium' },
  { value: ReferenceNumberPickupUrgency.Low, label: 'Low' },
]

export const getReferenceNumberPickupUrgencyOptionLabel = (type: ReferenceNumberPickupUrgency): string | undefined =>
  referenceNumberPickupUrgencyOptions.find(({ value }) => value === type)?.label
