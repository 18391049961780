import { PurchaseOrder, PurchaseOrderItem } from 'api'

export const hasPoContainDifferentReferenceNumbers = (
  data: PurchaseOrder,
  purchaseOrderItems?: PurchaseOrderItem[],
): boolean => {
  return !!purchaseOrderItems?.some(
    (item) =>
      item?.subLines?.some(
        (subLine) =>
          subLine.asset?.vendorReleaseReferenceNumber?.id !== data.vendorReleaseReferenceNumber?.id ||
          subLine.asset?.redeliveryReferenceNumber?.id !== data.redeliveryReferenceNumber?.id,
      ),
  )
}
