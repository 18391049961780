import { Accessor } from '../types/accessor'

export const getAccessorTitle = (accessor: Accessor): string => {
  return {
    [Accessor.Index]: '#',
    [Accessor.VendorName]: 'Vendor',
    [Accessor.AssetCode]: 'Asset Code',
    [Accessor.LocationCode]: 'Location',
    [Accessor.Quantity]: 'Qty',
    [Accessor.Sku]: 'SKU',
    [Accessor.Price]: 'Price',
  }[accessor]
}
