import { AssetCodeWidth } from 'api/types/assetCode/assetCodeWidth'

import { distanceUnits } from '../../distanceUnits'

export const assetCodeWidthOptions: { value: AssetCodeWidth; label: string }[] = [
  {
    value: AssetCodeWidth.S,
    label: `${AssetCodeWidth.S}${distanceUnits.Ft}`,
  },
]
