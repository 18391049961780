import { Col as BaseCol, Row as BaseRow } from 'antd'
import { styled } from 'styled-components'

export const Row = styled(BaseRow)`
  justify-content: center;
  max-width: 100%;
`
export const Col = styled(BaseCol)`
  justify-content: center;
  display: flex;
`
