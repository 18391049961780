import { FC, useCallback, useEffect } from 'react'
import { Col, Row } from 'antd'
import { useFormContext, useWatch } from 'react-hook-form'

import { PurchaseOrderType } from 'api'
import { purchaseOrderLabels } from 'constant'
import { PurchaseOrderFormAccessor } from 'types'
import { isDateBeforeCurrentDateEndOfDay } from 'utils'

import { DatePicker, FormItem } from 'designSystem'

export const DateFields: FC = () => {
  const { trigger, unregister } = useFormContext()
  const expirationDate = useWatch({ name: PurchaseOrderFormAccessor.ExpirationDate })
  const expectedDate = useWatch({ name: PurchaseOrderFormAccessor.ExpectedDate })
  const type = useWatch({ name: PurchaseOrderFormAccessor.Type })

  const isStandardType = type === PurchaseOrderType.Standard

  const handleChangeExpectedDate = useCallback(() => {
    if (expirationDate) {
      trigger(PurchaseOrderFormAccessor.ExpirationDate)
    }
  }, [trigger, expirationDate])
  const handleChangeExpirationDate = useCallback(() => {
    if (expectedDate) {
      trigger(PurchaseOrderFormAccessor.ExpectedDate)
    }
  }, [trigger, expectedDate])

  useEffect(() => {
    if (isStandardType) {
      unregister(PurchaseOrderFormAccessor.ExpectedDate)
    }
  }, [isStandardType, unregister])

  return (
    <Row gutter={8}>
      {!isStandardType && (
        <Col span={12}>
          <FormItem name={PurchaseOrderFormAccessor.ExpectedDate} label={purchaseOrderLabels.expectedDate}>
            <DatePicker disabledDate={isDateBeforeCurrentDateEndOfDay} onChange={handleChangeExpectedDate} />
          </FormItem>
        </Col>
      )}
      <Col span={isStandardType ? 24 : 12}>
        <FormItem name={PurchaseOrderFormAccessor.ExpirationDate} label={purchaseOrderLabels.expirationDate}>
          <DatePicker disabledDate={isDateBeforeCurrentDateEndOfDay} onChange={handleChangeExpirationDate} />
        </FormItem>
      </Col>
    </Row>
  )
}
