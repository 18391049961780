import { referenceNumberAllStatusOptions, referenceNumberTypeOptions } from 'constant'

import { TableFilterSelectMulti } from 'components'

export const referencesFilterOptions = [
  {
    value: 'type',
    label: 'Type',
    component: (
      <TableFilterSelectMulti key="type" name="type" placeholder="Type" options={referenceNumberTypeOptions} />
    ),
  },
  {
    value: 'status',
    label: 'Status',
    component: (
      <TableFilterSelectMulti
        key="status"
        name="status"
        placeholder="Status"
        options={referenceNumberAllStatusOptions}
      />
    ),
  },
]
