import { AssetCondition } from 'api/types/asset/assetCondition'

export const assetConditionOptions = [
  {
    label: 'Unknown',
    value: AssetCondition.Unknown,
  },
  {
    label: 'Available',
    value: AssetCondition.Available,
  },
  {
    label: 'Pending estimate',
    value: AssetCondition.PendingEstimate,
  },
  {
    label: 'Pending repair',
    value: AssetCondition.PendingRepair,
  },
  {
    label: 'Damaged',
    value: AssetCondition.Damaged,
  },
]

export const getAssetConditionLabel = (condition: AssetCondition): string | undefined =>
  assetConditionOptions.find(({ value }) => value === condition)?.label
