import { ErrorCode } from 'enums'
import { errorMessages } from 'constant'

const HasExternalReferenceText = '_HAS_EXTERNAL_REFERENCE_IN_'

const formatText = (text: string): string => {
  return text
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const getErrorMessageFromErrorCode = (errorCode: ErrorCode | undefined): string | undefined => {
  if (errorCode?.includes(HasExternalReferenceText)) {
    const entity = errorCode.split(HasExternalReferenceText)
    const [deleteName, conflictName] = entity
    return `You can not delete the ${formatText(deleteName)} when it is already used in ${formatText(conflictName)}`
  }
  const foundErrorCode = Object.values(ErrorCode).find((key) => key === errorCode)
  if (foundErrorCode) {
    return errorMessages[foundErrorCode]
  }

  return undefined
}
