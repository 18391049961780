import { FC } from 'react'

import { route } from 'constant'
import theme from 'styles/theme'

import { Container, Icon, Typography } from 'designSystem'

import * as Styled from './styles'

export const InviteBlock: FC = () => {
  return (
    <Styled.Link to={route.accessManagement}>
      <Styled.Card maxWidth={390}>
        <Container jc="space-between" gap={13}>
          <Styled.IconWrapper>
            <Icon icon="people" />
          </Styled.IconWrapper>
          <Container display="block">
            <Typography as="h3" fontWeight="xl" color="info">
              Invite your team
            </Typography>
            <Typography fontWeight="l">To invite and setup access go to Access Management</Typography>
          </Container>
          <Icon icon="arrowRight" size={16} color={theme.colors.accent[400]} />
        </Container>
      </Styled.Card>
    </Styled.Link>
  )
}
