import { keepPreviousData, useQuery } from '@tanstack/react-query'

import {
  getVendorContainerInventory,
  GetVendorContainerInventoryQueryParams,
  getVendorPartsInventory,
  GetVendorPartsInventoryQueryParams,
  VendorType,
} from 'api'
import { QueryKey } from 'enums'

type UseGetInventoryParams = (GetVendorPartsInventoryQueryParams | GetVendorContainerInventoryQueryParams) & {
  vendorId: number
}

export const useGetInventory = (params: UseGetInventoryParams, type: VendorType) => {
  const { data, ...rest } = useQuery({
    queryKey: [
      type === VendorType.Parts ? QueryKey.GetVendorInventoryParts : QueryKey.GetVendorInventoryContainerVendor,
      params,
    ],
    queryFn: () =>
      type === VendorType.Parts
        ? getVendorPartsInventory(params.vendorId)(params)
        : getVendorContainerInventory(params.vendorId)(params),
    placeholderData: keepPreviousData,
    enabled: !!params?.vendorId,
  })

  const { total, data: items } = data || {}

  return {
    data: items,
    total,
    ...rest,
  }
}
