import { getAccessorTitle } from '../utils/getAccessorTitle'
import { Accessor } from '../types/accessor'

export const vendorsRequiredColumns = [Accessor.Index, Accessor.CompanyName]

export const vendorsDefaultColumns = [
  Accessor.Index,
  Accessor.CompanyName,
  Accessor.Type,
  Accessor.LocationCodes,
  Accessor.UploadSheet,
]

export const vendorsVisibleColumnsOptions = Object.values(Accessor).map((accessor) => ({
  value: accessor,
  label: getAccessorTitle(accessor),
  disabled: vendorsRequiredColumns.includes(accessor),
}))
