import { FC, Fragment } from 'react'
import { Empty } from 'antd'

import { ApiQueryParams } from 'api'
import { QueryKey } from 'enums'
import { useInfinityList } from 'hooks'
import { Pagination, QueryParams } from 'types'

import { Container } from 'designSystem'
import { Spinner } from 'components/Spinner'

import * as Styled from './styles'

interface InfinityListResponse<T> extends Pagination {
  data?: T[]
}

interface InfinityListProps<T> extends ApiQueryParams {
  limit?: number
  queryKey: QueryKey
  queryFn: (params?: ApiQueryParams) => Promise<InfinityListResponse<T>>
  singleItem: FC<{ item: T }>
  height?: number
  queryParams?: QueryParams
  gap?: number
  initialPageParam?: number
}

export const InfinityList = <T extends { id: number }>({
  limit = 10,
  queryKey,
  queryFn,
  height,
  singleItem: SingleItem,
  queryParams,
  join,
  fields,
  gap,
  initialPageParam,
}: InfinityListProps<T>) => {
  const { data, isLoading, observerElem, isFetchingNextPage, hasNextPage } = useInfinityList<T>({
    queryKey,
    queryFn,
    limit,
    queryParams,
    join,
    fields,
    initialPageParam,
  })

  if (!hasNextPage && !data?.pages[0]?.data?.length)
    return (
      <Styled.Container height={height} gap={gap} ai="center" jc="center">
        <Empty />
      </Styled.Container>
    )

  return (
    <Styled.Container height={height} gap={gap} fd="column" ai="center" jc="center">
      {isLoading ? (
        <Container jc="center" ai="center" height="100%">
          <Spinner />
        </Container>
      ) : (
        <>
          {data?.pages.map((page) => (
            <Fragment key={page.page}>{page.data?.map((item) => <SingleItem key={item.id} item={item} />)}</Fragment>
          ))}
          {hasNextPage && (
            <span ref={observerElem}>
              {isFetchingNextPage && (
                <Container py={8}>
                  <Spinner size="small" />
                </Container>
              )}
            </span>
          )}
        </>
      )}
    </Styled.Container>
  )
}
