import { WeekendDay } from 'api/types/weekendDay'

export const weekendDayLabel = {
  [WeekendDay.Sunday]: 'Sunday',
  [WeekendDay.Saturday]: 'Saturday',
}

export const weekendDayOptions = [
  {
    label: weekendDayLabel[WeekendDay.Sunday],
    value: WeekendDay.Sunday,
  },
  {
    label: weekendDayLabel[WeekendDay.Saturday],
    value: WeekendDay.Saturday,
  },
]
