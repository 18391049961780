import { FC } from 'react'

import { useForm } from 'hooks'

import { Button, Container, FormItem, SelectMulti, SelectSingleOption } from 'designSystem'

import { entityContactsValidationSchema } from '../../constants/validationSchema'
import { AddContactsFormData } from '../../types/addContactsFormData'

import type { AddContactsModalProps } from '../../AddContactsModal'

type EntityContactsPayload = Pick<AddContactsFormData, 'entityContacts'>

interface EntityContactsProps
  extends Pick<AddContactsModalProps, 'onConfirm' | 'onCancel' | 'confirmLoading' | 'contactsCanAddCount'> {
  entityContactsOptions?: SelectSingleOption[]
}

export const EntityContacts: FC<EntityContactsProps> = ({
  entityContactsOptions,
  onConfirm,
  confirmLoading,
  onCancel,
  contactsCanAddCount,
}) => {
  const { Form, handleSubmit } = useForm<EntityContactsPayload>({
    validationSchema: entityContactsValidationSchema(contactsCanAddCount || 0),
  })

  return (
    <Form onSubmit={handleSubmit(onConfirm)}>
      <Container display="flex" fd="column" jc="space-between" minHeight={230}>
        <Container display="flex" fd="column">
          <FormItem name="entityContacts" label="Contacts">
            <SelectMulti options={entityContactsOptions} />
          </FormItem>
        </Container>
        <Container display="flex" jc="space-between" gap={8}>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" fullwidth htmlType="submit" loading={confirmLoading}>
            Confirm
          </Button>
        </Container>
      </Container>
    </Form>
  )
}
