import { Address, api, EntityId } from 'api'

export interface GetCustomerUccAddressesResponse {
  data: Address[]
}

export const getCustomerUccAddresses = (customerId?: EntityId): Promise<GetCustomerUccAddressesResponse> => {
  return api({
    method: 'get',
    url: `/api/v1/customers/${customerId}/ucc-addresses`,
  })
}

interface PostCustomerUccAddressProps {
  customerId?: EntityId
  data?: Address
}

export const postCustomerUccAddress = ({ customerId, data }: PostCustomerUccAddressProps): Promise<Address> => {
  return api({
    method: 'post',
    url: `/api/v1/customers/${customerId}/ucc-addresses`,
    data,
  })
}

interface PatchCustomerUccAddressProps {
  customerId?: EntityId
  id?: EntityId
  data?: Address
}

export const patchCustomerUccAddress = ({ customerId, id, data }: PatchCustomerUccAddressProps): Promise<Address> => {
  return api({
    method: 'patch',
    url: `/api/v1/customers/${customerId}/ucc-addresses/${id}`,
    data,
  })
}

interface DeleteCustomerUccAddressProps {
  customerId?: EntityId
  id?: EntityId
}

export const deleteCustomerUccAddress = ({ customerId, id }: DeleteCustomerUccAddressProps): Promise<void> => {
  return api({
    method: 'delete',
    url: `/api/v1/customers/${customerId}/ucc-addresses/${id}`,
  })
}
