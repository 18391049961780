import { FC, PropsWithChildren, ReactElement, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Button, Container, SpacerLevel, Typography } from 'designSystem'

interface TableTitleProps {
  title: ReactNode
  total?: number
  addRoute?: string
  search?: ReactElement
  filter?: ReactElement
  columns?: ReactElement
  additionalSettings?: ReactElement
  withoutDefaultPadding?: boolean
}

export const TableTitle: FC<PropsWithChildren<TableTitleProps>> = ({
  title,
  total,
  addRoute,
  search,
  filter,
  columns,
  additionalSettings,
  withoutDefaultPadding,
  children,
}) => {
  const spacers = withoutDefaultPadding ? {} : { pa: 4 as SpacerLevel }

  return (
    <Container display="flex" jc="space-between" ai="center" gap={8} {...spacers}>
      <Container display="flex" ai="center" gap={8}>
        <Typography size="l" fontWeight="xl" as="h2">
          {title}
        </Typography>
        {!!total && (
          <Typography color="secondary" size="l" fontWeight="xl">
            {total}
          </Typography>
        )}
      </Container>
      <Container gap={4}>
        {additionalSettings}
        {addRoute && (
          <Link to={addRoute}>
            <Button type="primary" icon="plus" size="small">
              Add
            </Button>
          </Link>
        )}
        {children}
        {search}
        {columns}
        {filter}
      </Container>
    </Container>
  )
}
