export enum Accessor {
  Index = 'index',
  Number = 'number',
  Type = 'type',
  CreatedAt = 'createdAt',
  RelatedReference = 'relatedReference',
  Items = 'items',
  Assets = 'assets',
  Status = 'status',
  LocationCode = 'locationCode',
  DepotSetting = 'depotSetting',
  Notes = 'notes',
}
