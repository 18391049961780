import { ReferenceNumberStatus } from 'api/types/referenceNumber/referenceNumberStatus'

export const referenceNumberAllStatusLabel = {
  [ReferenceNumberStatus.Open]: 'Open',
  [ReferenceNumberStatus.PendingRedelivery]: 'Pending Redelivery',
  [ReferenceNumberStatus.Received]: 'Received',
  [ReferenceNumberStatus.Pending]: 'Pending',
  [ReferenceNumberStatus.InProgress]: 'In Progress',
  [ReferenceNumberStatus.Closed]: 'Closed',
  [ReferenceNumberStatus.Scheduled]: 'Scheduled',
  [ReferenceNumberStatus.TransitionToCustomer]: 'Transition To Customer',
  [ReferenceNumberStatus.TransitionToDepot]: 'Transition To Depot',
  [ReferenceNumberStatus.ClosedCustomer]: 'Closed Customer',
  [ReferenceNumberStatus.ClosedDepot]: 'Closed Depot',
  [ReferenceNumberStatus.Voided]: 'Voided',
}

export const referenceNumberAllStatusOptions = Object.keys(referenceNumberAllStatusLabel).map((key) => ({
  value: key,
  label: referenceNumberAllStatusLabel[key as ReferenceNumberStatus],
}))

export const bookingStatusOptions = [
  { value: ReferenceNumberStatus.Open, label: referenceNumberAllStatusLabel[ReferenceNumberStatus.Open] },
  { value: ReferenceNumberStatus.Pending, label: referenceNumberAllStatusLabel[ReferenceNumberStatus.Pending] },
  { value: ReferenceNumberStatus.InProgress, label: referenceNumberAllStatusLabel[ReferenceNumberStatus.InProgress] },
  { value: ReferenceNumberStatus.Closed, label: referenceNumberAllStatusLabel[ReferenceNumberStatus.Closed] },
  { value: ReferenceNumberStatus.Voided, label: referenceNumberAllStatusLabel[ReferenceNumberStatus.Voided] },
]

export const vendorReleaseStatusOptions = bookingStatusOptions

export const redeliveryStatusOptions = [
  {
    value: ReferenceNumberStatus.PendingRedelivery,
    label: referenceNumberAllStatusLabel[ReferenceNumberStatus.PendingRedelivery],
  },
  { value: ReferenceNumberStatus.Received, label: referenceNumberAllStatusLabel[ReferenceNumberStatus.Received] },
  { value: ReferenceNumberStatus.Voided, label: referenceNumberAllStatusLabel[ReferenceNumberStatus.Voided] },
]

export const pickupStatusOptions = [
  {
    value: ReferenceNumberStatus.Open,
    label: referenceNumberAllStatusLabel[ReferenceNumberStatus.Open],
  },
  { value: ReferenceNumberStatus.Scheduled, label: referenceNumberAllStatusLabel[ReferenceNumberStatus.Scheduled] },
  {
    value: ReferenceNumberStatus.TransitionToCustomer,
    label: referenceNumberAllStatusLabel[ReferenceNumberStatus.TransitionToCustomer],
  },
  {
    value: ReferenceNumberStatus.TransitionToDepot,
    label: referenceNumberAllStatusLabel[ReferenceNumberStatus.TransitionToDepot],
  },
  {
    value: ReferenceNumberStatus.ClosedCustomer,
    label: referenceNumberAllStatusLabel[ReferenceNumberStatus.ClosedCustomer],
  },
  {
    value: ReferenceNumberStatus.ClosedDepot,
    label: referenceNumberAllStatusLabel[ReferenceNumberStatus.ClosedDepot],
  },
  { value: ReferenceNumberStatus.Voided, label: referenceNumberAllStatusLabel[ReferenceNumberStatus.Voided] },
]
