import * as Yup from 'yup'

import {
  Asset,
  AssetCondition,
  AssetNumberType,
  ContainerColor,
  ReferenceNumberAsset,
  ReferenceNumberAssetStatus,
  ReferenceNumberBookingTransactionType,
  ReferenceNumberBookingType,
  ReferenceNumberItem,
  ReferenceNumberPickupUrgency,
  ReferenceNumberRedeliveryTransactionType,
  ReferenceNumberStatus,
  ReferenceNumberType,
} from 'api'
import { ReferenceFormAccessor } from 'types'
import { dateValidation, getAssetNumberTypeByAssetNumber, notesValidation, numberBasicValidation } from 'utils'

import { maxContactsCount } from 'modules/customers/constants/maxRecordsCount'

import { maxAssetsInItem, maxItems } from './maxRecordsCount'

export const itemAssetValidation = {
  id: Yup.number().default(undefined),
  assetId: numberBasicValidation.nullable().default(null),
  status: Yup.mixed<ReferenceNumberAssetStatus>().required('Asset Status is required'),
  gateOutDate: dateValidation.nullable().default(null),
  receivedDate: dateValidation.nullable().default(null),
  pickupDate: dateValidation.nullable().default(null),
  pickupReferenceId: numberBasicValidation.nullable().default(null),
  isModificationRequired: Yup.boolean().nullable().default(false),
  isRepairRequired: Yup.boolean().nullable().default(false),
  condition: Yup.mixed<AssetCondition>().nullable().default(null),
  containerColor: Yup.mixed<ContainerColor>().nullable().default(null),
  redeliveryNumberId: numberBasicValidation.nullable().default(null),
}

export const itemValidation = {
  id: Yup.number().default(undefined),
  itemId: Yup.number().required('SKU is required'),
  qty: numberBasicValidation
    .required('Quantity is required')
    .min(0, 'Should be a positive number')
    .max(maxAssetsInItem, `Max ${maxAssetsInItem} assets`),
  subLines: Yup.array().of(Yup.object(itemAssetValidation).required()).default([]),
}

export const referenceNumberValidation = Yup.string().when('$isViewMode', ([$isViewMode], schema) => {
  if ($isViewMode) {
    return schema.required('Number is required')
  } else {
    return schema
  }
})

export const mainFieldsValidation = {
  type: Yup.mixed<ReferenceNumberType>().required('Type is required'),
  status: Yup.mixed<ReferenceNumberStatus>().required('Status is required'),
  expirationDate: dateValidation.nullable().default(null),
  notes: notesValidation,
}

export const locationDepotValidation = {
  locationCodeId: numberBasicValidation.required('Location is required'),
  depotSettingId: numberBasicValidation.nullable().default(null),
}

export const bookingTransactionTypeValidation = {
  transactionType: Yup.mixed<ReferenceNumberBookingTransactionType>().required('Transaction type is required'),
}

export const dppValidation = {
  dpp: numberBasicValidation.positive('Should be a positive number').nullable().default(null),
}

export const redeliveryTransactionTypeValidation = {
  transactionType: Yup.mixed<ReferenceNumberRedeliveryTransactionType>().required('Transaction Type is required'),
}

export const pickupCustomerValidation = {
  customerId: numberBasicValidation.required('Customer is required'),
}

type DefaultItems = Omit<ReferenceNumberItem, 'subLines'> & {
  subLines: (ReferenceNumberAsset &
    Pick<Asset, 'assetNumber' | 'assetNumberType'> & {
      assetId: number
    })[]
}

const assetNumberValidation = Yup.string()
  .when(['$defaultItems'], ([$defaultItems]: DefaultItems[][], schema, context: any) => {
    const currentAssetId = context?.from?.[0]?.value?.assetId
    const currentItemId = context?.from?.[1]?.value?.id

    if (currentAssetId && currentItemId) {
      const currentAsset = $defaultItems
        ?.find((item) => item.id === currentItemId)
        ?.subLines?.find((subLine) => subLine.assetId === currentAssetId)
      if (currentAsset?.assetNumberType === AssetNumberType.Temporary) {
        return schema.test('canChangeTo', 'You can change type to Original only', (value) => {
          const newType = getAssetNumberTypeByAssetNumber(value)
          return currentAsset?.assetNumber === value || newType === AssetNumberType.Original
        })
      }
      if (currentAsset?.assetNumberType === AssetNumberType.Original) {
        return schema.test('canChangeTo', 'You can change type to Original or Rental only', (value) => {
          const newType = getAssetNumberTypeByAssetNumber(value)
          return currentAsset?.assetNumber === value || newType !== AssetNumberType.Temporary
        })
      }
    }

    return schema
  })
  .required('Asset number is required')
  .max(100, 'Max length of 100 characters')

export const vendorReleaseItemsValidation = {
  items: Yup.array()
    .of(
      Yup.object({
        ...itemValidation,
        subLines: Yup.array()
          .of(
            Yup.object({
              id: itemAssetValidation.id,
              assetNumber: assetNumberValidation,
              gateOutDate: itemAssetValidation.gateOutDate,
              status: itemAssetValidation.status,
            }).required(),
          )
          .default([]),
      }).required(),
    )
    .max(maxItems, `Max items ${maxItems}`)
    .default([]),
}

export const bookingItemsValidation = {
  items: Yup.array()
    .of(
      Yup.object({
        ...itemValidation,
        subLines: Yup.array()
          .of(
            Yup.object({
              id: itemAssetValidation.id,
              assetId: itemAssetValidation.assetId,
              gateOutDate: itemAssetValidation.gateOutDate,
              isModificationRequired: itemAssetValidation.isModificationRequired,
              isRepairRequired: itemAssetValidation.isRepairRequired,
              status: itemAssetValidation.status,
            }).required(),
          )
          .default([]),
      }).required(),
    )
    .max(maxItems, `Max items ${maxItems}`)
    .default([]),
}

export const redeliveryItemsValidation = {
  items: Yup.array()
    .of(
      Yup.object({
        ...itemValidation,
        subLines: Yup.array()
          .of(
            Yup.object({
              id: itemAssetValidation.id,
              assetNumber: assetNumberValidation,
              pickupReferenceId: itemAssetValidation.pickupReferenceId,
              receivedDate: itemAssetValidation.receivedDate,
              condition: itemAssetValidation.condition,
              containerColor: itemAssetValidation.containerColor,
              status: itemAssetValidation.status,
            }).required(),
          )
          .default([]),
      }).required(),
    )
    .max(maxItems, `Max items ${maxItems}`)
    .default([]),
}

export const pickupItemsValidation = {
  items: Yup.array()
    .of(
      Yup.object({
        ...itemValidation,
        subLines: Yup.array()
          .of(
            Yup.object({
              id: itemAssetValidation.id,
              assetId: itemAssetValidation.assetId,
              pickupDate: itemAssetValidation.pickupDate,
              redeliveryNumberId: itemAssetValidation.redeliveryNumberId,
              status: itemAssetValidation.status,
            }).required(),
          )
          .default([]),
      }).required(),
    )
    .max(maxItems, `Max items ${maxItems}`)
    .default([]),
}

export const vendorReleaseItemsValidationSchema = Yup.object(vendorReleaseItemsValidation)
export const bookingItemsValidationSchema = Yup.object(bookingItemsValidation)
export const redeliveryItemsValidationSchema = Yup.object(redeliveryItemsValidation)
export const pickupItemsValidationSchema = Yup.object(pickupItemsValidation)

export const vendorReleaseNumberFieldsValidation = Yup.object({
  ...mainFieldsValidation,
  ...locationDepotValidation,
  number: referenceNumberValidation,
  purchaseOrderId: numberBasicValidation.required('Purchase Order is required'),
  bookingNumbersIds: Yup.array().of(numberBasicValidation.required()).nullable().default([]),
  ...vendorReleaseItemsValidation,
})

export const bookingNumberFieldsValidation = Yup.object({
  ...mainFieldsValidation,
  ...bookingTransactionTypeValidation,
  locationCodeId: locationDepotValidation.locationCodeId,
  depotSettingId: numberBasicValidation.required('Depot is required'),
  gateInLocationCodeId: numberBasicValidation
    .when([ReferenceFormAccessor.TransactionType, ReferenceFormAccessor.BookingType], {
      is: (transactionType: ReferenceNumberRedeliveryTransactionType, bookingType: ReferenceNumberBookingType) =>
        transactionType === ReferenceNumberRedeliveryTransactionType.Transfer &&
        bookingType === ReferenceNumberBookingType.Release,
      then: (schema) => schema.required('Gate In Location is required'),
    })
    .nullable()
    .default(null),
  gateInDepotSettingId: numberBasicValidation
    .when([ReferenceFormAccessor.TransactionType, ReferenceFormAccessor.BookingType], {
      is: (transactionType: ReferenceNumberRedeliveryTransactionType, bookingType: ReferenceNumberBookingType) =>
        transactionType === ReferenceNumberRedeliveryTransactionType.Transfer &&
        bookingType === ReferenceNumberBookingType.Release,
      then: (schema) => schema.required('Gate In Depot is required'),
    })
    .nullable()
    .default(null),
  bookingType: Yup.mixed<ReferenceNumberBookingType>().required('Booking Type is required'),
  assetPrefix: Yup.string().max(500, 'Max length of 500 characters').nullable().default(null),
  depotNotes: notesValidation,
  vendorReleaseId: numberBasicValidation
    .when(ReferenceFormAccessor.BookingType, {
      is: (bookingType: ReferenceNumberBookingType) => bookingType === ReferenceNumberBookingType.VendorRelease,
      then: (schema) => schema.required('Vendor Release Number is required'),
    })
    .nullable()
    .default(null),
  salesOrderId: numberBasicValidation
    .when('transactionType', {
      is: (transactionType: ReferenceNumberBookingTransactionType) =>
        transactionType &&
        [
          ReferenceNumberBookingTransactionType.Sale,
          ReferenceNumberBookingTransactionType.Rental,
          ReferenceNumberBookingTransactionType.Recycle,
          ReferenceNumberBookingTransactionType.RentalSwap,
          ReferenceNumberBookingTransactionType.SaleSwap,
          ReferenceNumberBookingTransactionType.Rejection,
        ].includes(transactionType),
      then: (schema) => schema.required('Sales Order is required'),
    })
    .nullable()
    .default(null),
  ...bookingItemsValidation,
})

export const redeliveryNumberFieldsValidation = Yup.object({
  ...mainFieldsValidation,
  ...redeliveryTransactionTypeValidation,
  ...dppValidation,
  locationCodeId: locationDepotValidation.locationCodeId,
  depotSettingId: numberBasicValidation.required('Depot is required'),
  purchaseOrderId: numberBasicValidation
    .when(ReferenceFormAccessor.TransactionType, {
      is: (transactionType: ReferenceNumberRedeliveryTransactionType) =>
        transactionType === ReferenceNumberRedeliveryTransactionType.Purchase,
      then: (schema) => schema.required('Related Order is required'),
    })
    .nullable()
    .default(null),
  salesOrderId: numberBasicValidation
    .when(ReferenceFormAccessor.TransactionType, {
      is: (transactionType: ReferenceNumberRedeliveryTransactionType) =>
        [
          ReferenceNumberRedeliveryTransactionType.RentalSwapReturn,
          ReferenceNumberRedeliveryTransactionType.SaleSwapReturn,
          ReferenceNumberRedeliveryTransactionType.Recycle,
          ReferenceNumberRedeliveryTransactionType.Rejection,
        ].includes(transactionType),
      then: (schema) => schema.required('Related Order is required'),
    })
    .nullable()
    .default(null),
  bookingNumberId: numberBasicValidation
    .when(ReferenceFormAccessor.TransactionType, {
      is: (transactionType: ReferenceNumberRedeliveryTransactionType) =>
        transactionType === ReferenceNumberRedeliveryTransactionType.Transfer,
      then: (schema) => schema.required('Booking number is required'),
    })
    .nullable()
    .default(null),
  ...redeliveryItemsValidation,
})

export const pickupFieldsValidation = Yup.object({
  ...mainFieldsValidation,
  ...pickupCustomerValidation,
  customerAddressId: numberBasicValidation.required('Address is required'),
  contacts: Yup.array()
    .of(Yup.mixed<number>().required())
    .default([])
    .max(maxContactsCount, `Max ${maxContactsCount} contacts`),
  availableAssetsIds: Yup.array().of(numberBasicValidation.required()).nullable().default([]),
  urgency: Yup.mixed<ReferenceNumberPickupUrgency>().required('Urgency is required'),
  pickupDate: dateValidation.nullable().default(null),
  scheduledPickupDate: dateValidation.nullable().default(null),
  dispatchedDate: dateValidation.nullable().default(null),
  vendorId: numberBasicValidation.nullable().default(null),
  dispatcherId: numberBasicValidation.nullable().default(null),
  transportationPrice: numberBasicValidation.positive('Should be positive').nullable().default(null),
  transportationCost: numberBasicValidation.positive('Should be positive').nullable().default(null),
  ...pickupItemsValidation,
})
