import { getItems, Item } from 'api'
import { QueryKey } from 'enums'
import { AsyncSelectGetItemsProps } from 'types'

import type { SelectSingleOption } from 'designSystem'

import { useAsyncList } from './useAsyncList'

export const useItems = ({
  valuePropName, // keyof Item - the value of it should be of type string | number
  withFullDataOption,
  fields,
  ...props
}: AsyncSelectGetItemsProps) => {
  const { list, ...asyncListProps } = useAsyncList<Item>({
    queryFn: getItems,
    queryKey: QueryKey.GetItemsList,
    searchFieldName: 'sku',
    limit: 10,
    fields: fields || 'id,sku,type,name',
    ...props,
  })

  const options = list.map((item) => ({
    value: valuePropName ? (item[valuePropName as keyof Item] as string | number) : item.id,
    label: item.sku,
    ...(withFullDataOption && { data: item }),
  })) satisfies SelectSingleOption[]

  return {
    options,
    ...asyncListProps,
  }
}
