import { useCallback } from 'react'

import { PickupNumberFormPayload } from 'modules/references/types'

import { usePostReferenceNumber } from '../../../hooks/usePostReferenceNumber'

export const useCreatePickupNumber = () => {
  const { mutate, ...rest } = usePostReferenceNumber()

  const create = useCallback(
    (payload: PickupNumberFormPayload) => {
      mutate(payload)
    },
    [mutate],
  )

  return {
    create,
    ...rest,
  }
}
