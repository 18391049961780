import { PurchaseOrderItemAccessor } from 'types'

import { getAccessorItemTitle } from 'modules/purchaseOrders/utils/getAccessorItemTitle'

export const purchaseOrderItemsRequiredColumns = [PurchaseOrderItemAccessor.Index, PurchaseOrderItemAccessor.Sku]

export const purchaseOrderItemsDefaultColumns = [
  PurchaseOrderItemAccessor.Index,
  PurchaseOrderItemAccessor.Sku,
  PurchaseOrderItemAccessor.ItemName,
  PurchaseOrderItemAccessor.Qty,
  PurchaseOrderItemAccessor.Rate,
  PurchaseOrderItemAccessor.ReceivedQty,
  PurchaseOrderItemAccessor.BilledQty,
  PurchaseOrderItemAccessor.TotalAmount,
]

export const purchaseOrderItemsVisibleColumnsOptions = Object.values(PurchaseOrderItemAccessor).map((accessor) => ({
  value: accessor,
  label: getAccessorItemTitle(accessor),
  disabled: purchaseOrderItemsRequiredColumns.includes(accessor),
}))
