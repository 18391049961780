import { VendorFetchSchedule } from 'api/types/vendor/vendorFetchSchedule'

export const vendorFetchOptions = [
  {
    label: 'Hourly',
    value: VendorFetchSchedule.Hourly,
  },
  {
    label: 'Daily',
    value: VendorFetchSchedule.Daily,
  },
  {
    label: 'Weekly',
    value: VendorFetchSchedule.Weekly,
  },
]
