import { VendorCarrierSettingsType } from 'api/types/vendor/vendorCarrierSettings/VendorCarrierSettingsType'

export const vendorCarrierTiltBetOptionsLabels = {
  [VendorCarrierSettingsType.TiltBet]: 'Tilt Bet',
  [VendorCarrierSettingsType.TiltPickupFromPort]: 'Pickup from Port',
  [VendorCarrierSettingsType.ContainerStorage]: 'Container Storage',
  [VendorCarrierSettingsType.CanPickup2_20]: 'Can Pickup 2 20',
  [VendorCarrierSettingsType.DoRepairs]: 'Do Repairs',
  [VendorCarrierSettingsType.DeliveryOutOfState]: 'Delivery out of State',
}

export const vendorCarrierTiltBetOptions = [
  {
    value: VendorCarrierSettingsType.TiltBet,
    label: vendorCarrierTiltBetOptionsLabels[VendorCarrierSettingsType.TiltBet],
  },
  {
    value: VendorCarrierSettingsType.TiltPickupFromPort,
    label: vendorCarrierTiltBetOptionsLabels[VendorCarrierSettingsType.TiltPickupFromPort],
  },
  {
    value: VendorCarrierSettingsType.ContainerStorage,
    label: vendorCarrierTiltBetOptionsLabels[VendorCarrierSettingsType.ContainerStorage],
  },
  {
    value: VendorCarrierSettingsType.CanPickup2_20,
    label: vendorCarrierTiltBetOptionsLabels[VendorCarrierSettingsType.CanPickup2_20],
  },
  {
    value: VendorCarrierSettingsType.DoRepairs,
    label: vendorCarrierTiltBetOptionsLabels[VendorCarrierSettingsType.DoRepairs],
  },
  {
    value: VendorCarrierSettingsType.DeliveryOutOfState,
    label: vendorCarrierTiltBetOptionsLabels[VendorCarrierSettingsType.DeliveryOutOfState],
  },
]
