import { FC, useCallback } from 'react'

import { ReferenceNumberType } from 'api'
import { useFormContext } from 'hooks'
import { confirmationText, purchaseOrderReferenceTypeOptions } from 'constant'
import { PurchaseOrderFormAccessor, PurchaseOrderSubLineAccessor } from 'types'

import { Container, EditFormItem } from 'designSystem'
import { ConfirmationPopover, FormItemEditSelectSingle } from 'components'

interface ReferenceTypeProps {
  rowSubLineFieldIndexName: string
  isFieldDisabled?: boolean
}

export const ReferenceType: FC<ReferenceTypeProps> = ({ rowSubLineFieldIndexName, isFieldDisabled }) => {
  const { triggerSubmit, setValue } = useFormContext()

  const handleSubmit = useCallback(
    (value: string | number | null | undefined) => {
      const isVendorRelease = value === ReferenceNumberType.VendorRelease
      const fieldName = `${rowSubLineFieldIndexName}.asset.${
        isVendorRelease ? PurchaseOrderFormAccessor.RedeliveryRefId : PurchaseOrderFormAccessor.VendorReleaseRefId
      }`
      setValue(fieldName, null)
      setValue(`${rowSubLineFieldIndexName}.${PurchaseOrderSubLineAccessor.ReferenceType}`, value)
      triggerSubmit?.()
    },
    [triggerSubmit, rowSubLineFieldIndexName, setValue],
  )

  return (
    <Container maxWidth={180} jc="flex-start">
      <EditFormItem
        name={`${rowSubLineFieldIndexName}.asset.${PurchaseOrderSubLineAccessor.ReferenceType}`}
        render={({ field }) => (
          <FormItemEditSelectSingle
            {...field}
            size="small"
            options={purchaseOrderReferenceTypeOptions}
            onSubmit={handleSubmit}
            disabled={isFieldDisabled}
            popover={
              <ConfirmationPopover
                titleText="Confirmation Required"
                placement="bottomLeft"
                text={confirmationText.confirmationRequired}
                okText="Confirm"
                okType="primary"
              />
            }
          />
        )}
      />
    </Container>
  )
}
