import { assetLabels } from 'constant'

import { DepotAccessor } from '../types/depotAccessor'

export const getTableTitle = (accessor: DepotAccessor): string => {
  return {
    [DepotAccessor.Index]: '#',
    [DepotAccessor.AssetNumber]: assetLabels.assetNumber,
    [DepotAccessor.ItemSku]: 'Item SKU',
    [DepotAccessor.Cost]: assetLabels.cost,
    [DepotAccessor.Status]: assetLabels.status,
    [DepotAccessor.CreatedAt]: assetLabels.createdAt,
  }[accessor]
}
