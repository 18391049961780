import { FC, useEffect } from 'react'

import { PurchaseOrderType } from 'api'
import { useFormContext, useWatch } from 'hooks'
import { PurchaseOrderFormAccessor } from 'types'

import { RedeliveryFields } from './elements/RedeliveryFields'
import { StandardFields } from './elements/StandardFields'
import { VendorReleaseFields } from './elements/VendorReleaseFields'

export const DeliveryInformation: FC = () => {
  const { unregister } = useFormContext()
  const type = useWatch({ name: PurchaseOrderFormAccessor.Type }) as PurchaseOrderType

  const isPOTypeVendorRelease = type === PurchaseOrderType.VendorRelease
  const isPOTypeRedelivery = type === PurchaseOrderType.Redelivery
  const isPOTypeStandard = type === PurchaseOrderType.Standard

  useEffect(() => {
    if (!isPOTypeRedelivery) {
      unregister([
        PurchaseOrderFormAccessor.Dpp,
        PurchaseOrderFormAccessor.RedeliveryNumber,
        PurchaseOrderFormAccessor.ContainerColor,
        PurchaseOrderFormAccessor.ShouldGenerateReferenceNumber,
      ])
    }
  }, [isPOTypeRedelivery, unregister])

  useEffect(() => {
    if (!isPOTypeVendorRelease) {
      unregister([PurchaseOrderFormAccessor.VendorReleaseNumber])
    }
  }, [isPOTypeVendorRelease, unregister])

  useEffect(() => {
    if (isPOTypeStandard) {
      unregister([
        PurchaseOrderFormAccessor.VendorReleaseNumber,
        PurchaseOrderFormAccessor.DepotId,
        PurchaseOrderFormAccessor.LocationCodeId,
        PurchaseOrderFormAccessor.Dpp,
        PurchaseOrderFormAccessor.RedeliveryNumber,
      ])
    }
  }, [isPOTypeStandard, unregister])

  const Component = {
    [PurchaseOrderType.VendorRelease]: <VendorReleaseFields />,
    [PurchaseOrderType.Redelivery]: <RedeliveryFields />,
    [PurchaseOrderType.Standard]: <StandardFields />,
  }[type]

  return Component
}
