import { Component, CSSProperties, FC, forwardRef, ReactNode, Ref, useCallback } from 'react'
import type { RangePickerDateProps } from 'antd/es/date-picker/generatePicker'

import type { TimeRangeValue } from 'api'
import { DateFormat, typedMemo } from 'types'
import { Dayjs, getDateValueFromTimeRangeValue, getTimeRangeValueFromDate } from 'utils'
import theme from 'styles/theme'

import * as Styled from './styles'

import { Icon } from '../Icon'

export type TimeRangePickerProps = Omit<RangePickerDateProps<Dayjs>, 'format'> & {
  ref?: Ref<Component<TimeRangePickerProps>>
  align?: CSSProperties['textAlign']
  width?: number
  error?: string
  format?: keyof typeof DateFormat
  prefix?: ReactNode
  value?: TimeRangeValue | null
  onChange?: (value: TimeRangeValue | null) => void
}

export const TimeRangePickerBase: FC<TimeRangePickerProps> = forwardRef(
  (
    { placeholder, suffixIcon, format = 'TwelveHourTimeFormat', prefix, onChange, value, ...props },
    ref: Ref<Component<TimeRangePickerProps>>,
  ) => {
    const timeFormat = DateFormat[format]

    const handleChange = useCallback(
      (values: (Dayjs | null)[] | null) => {
        const transformedValues = getTimeRangeValueFromDate(values)

        onChange?.(transformedValues)
      },
      [onChange],
    )

    return (
      <Styled.Container $prefix={!!prefix}>
        <Styled.TimeRangePicker
          {...props}
          ref={ref}
          value={value && getDateValueFromTimeRangeValue(value)}
          format={timeFormat}
          onChange={handleChange}
          placeholder={placeholder || [timeFormat.toLocaleLowerCase(), timeFormat.toLowerCase()]}
          suffixIcon={suffixIcon ? suffixIcon : <Icon icon="timeFilled" size={12} color={theme.colors.primary} />}
        />
        {prefix && <Styled.PrefixIcon>{prefix}</Styled.PrefixIcon>}
      </Styled.Container>
    )
  },
)

export const TimeRangePicker = typedMemo(TimeRangePickerBase)
