import { FC, useCallback } from 'react'

import { TypeOfEquipment } from 'api'
import { useForm } from 'hooks'
import { typeOfEquipmentOptions } from 'constant'

import { CheckboxGroup, Container, FormItem, Typography } from 'designSystem'
import { StripLoader } from 'components'
import { ContainerVendorPayload } from 'modules/vendors/types/ContainerVendorPayload'

import { validationSchema } from './constants/validationSchema'
import { InfoCardSchema } from './types/infoCardSchema'
interface InfoCardProps {
  isLoading: boolean
  isError: boolean
  data: TypeOfEquipment[]
  onSubmit: (payload: ContainerVendorPayload) => void
}

export const ContainerVendorInfoCard: FC<InfoCardProps> = ({ isLoading, isError, onSubmit: update, data }) => {
  const onSubmit = useCallback(
    (payload: ContainerVendorPayload) => {
      update(payload)
    },
    [update],
  )

  const { Form, triggerSubmit } = useForm<InfoCardSchema>({
    validationSchema,
    mode: 'onChange',
    onSubmit,
    isSubmitError: isError,
    defaultValues: { typeOfEquipment: data },
  })

  return (
    <Form>
      <StripLoader isLoading={isLoading} />
      <Container display="flex" fd="column" gap={8} nowrap>
        <Typography size="s" fontWeight="xl">
          Type of Equipment
        </Typography>
        <FormItem name="typeOfEquipment">
          <CheckboxGroup options={typeOfEquipmentOptions} onChange={triggerSubmit} />
        </FormItem>
      </Container>
    </Form>
  )
}
