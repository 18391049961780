import { ItemAssets } from '../types'

import type { PurchaseOrderRowType } from './getTableColumns'

export const getSkuDefaultOption = (record: PurchaseOrderRowType, isAddNew: boolean, skuAsset: ItemAssets) => {
  if (isAddNew && record.id === -1) {
    return undefined
  }

  if (record.item) {
    return { value: record.item.id, label: record.item.sku }
  }

  if (skuAsset[record.id]) {
    return { value: skuAsset[record.id].id as number, label: skuAsset[record.id].sku as string }
  }

  return undefined
}
