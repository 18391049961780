import { FC, MouseEvent, useCallback, useState } from 'react'
import type { CardProps as CardPropsBase } from 'antd/lib/card'

import { typedMemo } from 'types'

import { Icon, SpacerLevel } from 'designSystem'

import * as Styled from './styles'

import { StripLoader } from '../StripLoader'

export interface CardProps extends Omit<CardPropsBase, 'title'> {
  pa?: SpacerLevel
  ma?: SpacerLevel
  mt?: SpacerLevel
  mr?: SpacerLevel
  mb?: SpacerLevel
  ml?: SpacerLevel
  maxWidth?: number
  hidden?: boolean
  bordered?: boolean
  error?: boolean
  showDelete?: boolean
  onClickDelete?: () => void
  isLoading?: boolean
  fullwidth?: boolean
}

export const CardBase: FC<CardProps> = ({
  children,
  isLoading,
  showDelete,
  onClickDelete,
  onMouseEnter,
  onMouseLeave,
  fullwidth = false,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const showDeleteButton = isHovered && showDelete

  const handleMouseEnter = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (showDelete) {
        setIsHovered(true)
      }
      onMouseEnter?.(event)
    },
    [onMouseEnter, showDelete],
  )

  const handleMouseLeave = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (showDelete) {
        setIsHovered(false)
      }
      onMouseLeave?.(event)
    },
    [onMouseLeave, showDelete],
  )

  return (
    <Styled.Card onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} $fullwidth={fullwidth} {...props}>
      <StripLoader isLoading={isLoading} />
      {!fullwidth && (
        <Styled.CardDeleteWrapper visible={showDeleteButton}>
          <Icon icon="delete" onClick={onClickDelete} size={16} />
        </Styled.CardDeleteWrapper>
      )}

      {children}
    </Styled.Card>
  )
}

export const Card = typedMemo(CardBase)
