import { FC, useCallback } from 'react'
import { Col, Row } from 'antd'
import { useFormContext, useWatch } from 'react-hook-form'

import { containerColorOptions, currency, purchaseOrderLabels } from 'constant'
import { PurchaseOrderFormAccessor } from 'types'

import { Checkbox, Container, FormItem, Input, InputNumber, SelectSingle, Spacer, Typography } from 'designSystem'

import { useValueChange } from '../../../AdditionalInformation/hooks/useValueChange'

import { DateFields } from '../DateFields/DateFields'
import { LocationVsDepotFields } from '../LocationVsDepotFields'
import { PaymentTermsField } from '../PaymentTermsField'

export const RedeliveryFields: FC = () => {
  const { handleBlur } = useValueChange(PurchaseOrderFormAccessor.RedeliveryNumber)
  const { setValue } = useFormContext()
  const shouldGenerateReferenceNumber: boolean = useWatch({
    name: PurchaseOrderFormAccessor.ShouldGenerateReferenceNumber,
  })

  const handleToggleCheckbox = useCallback(
    (value: boolean) => {
      if (value) {
        setValue(PurchaseOrderFormAccessor.RedeliveryNumber, null)
      }
    },
    [setValue],
  )
  return (
    <>
      <Row gutter={8} align="bottom">
        <Col span={12}>
          <FormItem name={PurchaseOrderFormAccessor.RedeliveryNumber} label={purchaseOrderLabels.redeliveryNumber}>
            <Input
              placeholder={purchaseOrderLabels.redeliveryNumber}
              onBlur={handleBlur}
              disabled={shouldGenerateReferenceNumber}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <Container ai="center" gap="4" mb={3}>
            <Typography size="xs" fontWeight="l">
              Or
            </Typography>
            <FormItem name={PurchaseOrderFormAccessor.ShouldGenerateReferenceNumber}>
              <Checkbox onChange={handleToggleCheckbox} />
            </FormItem>
            <Typography size="xs" fontWeight="l">
              Generate
            </Typography>
          </Container>
        </Col>
      </Row>
      <Spacer mb={3} />
      <LocationVsDepotFields />
      <Spacer mb={3} />
      <Row gutter={8}>
        <Col span={12}>
          <FormItem name={PurchaseOrderFormAccessor.ContainerColor} label={purchaseOrderLabels.containerColor}>
            <SelectSingle options={containerColorOptions} placeholder="Select" allowClear />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem name={PurchaseOrderFormAccessor.Dpp} label={purchaseOrderLabels.dpp}>
            <InputNumber placeholder="00.00" precision={2} suffix={currency} />
          </FormItem>
        </Col>
      </Row>
      <Spacer mb={3} />
      <DateFields />
      <Spacer mb={3} />
      <Row gutter={8}>
        <Col span={12}>
          <PaymentTermsField />
        </Col>
      </Row>
    </>
  )
}
