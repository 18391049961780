import { CSSProperties, HTMLAttributeAnchorTarget, ReactNode } from 'react'
import { Tooltip } from 'antd'

import { typedMemo } from 'types'

import { ReactLink, Typography, TypographyProps } from 'designSystem'

import * as Styled from './styles'

interface LabelValueProps {
  label: string
  value?: string | ReactNode
  typographyPropsLabel?: Omit<TypographyProps, 'children'>
  typographyPropsValue?: Omit<TypographyProps, 'children'>
  href?: string
  hrefTarget?: HTMLAttributeAnchorTarget
  tooltipValue?: string
  showTooltip?: boolean // will show by default when undefined
  alignItems?: CSSProperties['alignItems']
}

export const LabelValueMarkupBase = ({
  label,
  value,
  typographyPropsLabel,
  typographyPropsValue,
  href,
  hrefTarget,
  tooltipValue,
  showTooltip,
  alignItems = 'center',
}: LabelValueProps) => (
  <Styled.LabelValueContainer key={label} jc="space-between" ai={alignItems} fg={1} gap={8} minHeight={28}>
    <Typography size="s" color="secondary" fontWeight="l" {...typographyPropsLabel}>
      {label}
    </Typography>

    <Tooltip
      mouseLeaveDelay={0}
      open={showTooltip}
      title={tooltipValue || (value && value !== '-' ? value : undefined)}
      destroyTooltipOnHide
      getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
    >
      {href ? (
        <ReactLink to={href} target={hrefTarget}>
          <Typography color="inherit" size="s" nowrap fontWeight="l" {...{ lineEllipsis: 1, ...typographyPropsValue }}>
            {value}
          </Typography>
        </ReactLink>
      ) : (
        <Typography size="s" nowrap fontWeight="l" {...{ lineEllipsis: 1, ...typographyPropsValue }}>
          {value || '-'}
        </Typography>
      )}
    </Tooltip>
  </Styled.LabelValueContainer>
)

export const LabelValueMarkup = typedMemo(LabelValueMarkupBase)
