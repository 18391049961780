import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { EntityId } from 'api'
import { QueryKey } from 'enums'
import { route } from 'constant'

import { getAssetById } from '../queryApi'

export const useGetAssetById = (assetId?: EntityId) => {
  const navigate = useNavigate()

  const { data, isLoading, isFetching, fetchStatus, isError, ...rest } = useQuery({
    queryKey: [QueryKey.GetAssetById, assetId],
    queryFn: () => getAssetById(assetId),
    enabled: !!assetId,
  })

  useEffect(() => {
    if (isError) {
      navigate(route.assets)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  return {
    data,
    isLoading: (isLoading || isFetching) && fetchStatus !== 'idle',
    ...rest,
  }
}
