import { FC, useCallback } from 'react'

import { Contact } from 'api'
import { useFormContext } from 'hooks'
import { getIsFormFieldViewOnly } from 'utils'

import { Container, Typography } from 'designSystem'

import { ContactView } from './elements/ContactView'
import * as Styled from './styles'

interface FormContactsWithDetailsModalProps {
  name: string
  title: string
  contacts?: Contact[] | null
  onDelete?: (contactId: number) => void
  showDelete?: boolean
}

export const FormContactsWithDetailsModal: FC<FormContactsWithDetailsModalProps> = ({
  name,
  title = 'Contacts',
  contacts,
  onDelete,
  showDelete = true,
}) => {
  const { viewOnlyFields } = useFormContext()
  const isFieldViewOnly = getIsFormFieldViewOnly(viewOnlyFields, name)

  const handleDelete = useCallback(
    (contactId: number) => () => {
      onDelete?.(contactId)
    },
    [onDelete],
  )

  return (
    <Container gap={8} jc="space-between">
      <Typography size="s" fontWeight="l" color="secondary" nowrap>
        {title}
      </Typography>

      <Styled.Wrapper>
        {contacts?.length ? (
          contacts.map((contact: Contact) => (
            <ContactView
              key={contact.id}
              contact={contact}
              showDelete={!isFieldViewOnly && showDelete}
              onDelete={handleDelete(contact.id)}
            />
          ))
        ) : (
          <Typography size="s" fontWeight="l" color="secondary">
            Empty
          </Typography>
        )}
      </Styled.Wrapper>
    </Container>
  )
}
