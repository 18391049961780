import { FC, PropsWithChildren } from 'react'

import * as Styled from './styles'

export interface GridLayoutProps {
  columns: number
  gap?: number
}

export const GridLayout: FC<PropsWithChildren<GridLayoutProps>> = (props) => {
  return <Styled.GridLayoutContainer {...props} />
}
