import { FC } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Popover } from 'antd'

import { getUserNotifications } from 'api/queryApi/userNotifications'
import { QueryKey } from 'enums'

import { Icon } from 'designSystem'

import { maxNotificationsCount } from './constants/maxNotificationsCount'
import { NotificationsPopover } from './elements/NotificationsPopover'
import * as Styled from './styles'

export const Notifications: FC = () => {
  const { data, isLoading } = useQuery({
    queryKey: [QueryKey.GetUserNotificationCount],
    queryFn: () => getUserNotifications({ limit: 1, fields: 'id' }),
  })
  const notificationCount = data?.total || 0
  const isNotificationsExist = notificationCount > 0

  return (
    <Popover
      overlayStyle={{ width: '100%', maxWidth: '400px' }}
      placement="bottomRight"
      content={<NotificationsPopover />}
      trigger="click"
      arrow={false}
      destroyTooltipOnHide
    >
      <Styled.NotificationButton $isNotificationsExist={isNotificationsExist}>
        <Icon icon="bell" />
        {!isLoading && isNotificationsExist && (
          <>{notificationCount > maxNotificationsCount ? `${maxNotificationsCount}+` : notificationCount}</>
        )}
      </Styled.NotificationButton>
    </Popover>
  )
}
