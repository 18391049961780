import { Col, Row } from 'antd'

import { VendorModificationsSettings } from 'api'
import { currency, marginSettingsMeasureOptions, marginSettingsOperatorOptions } from 'constant'
import { getFormattedPriceWithCurrency } from 'utils'

import { Container, EditFormItem, FormItem, Input, InputNumber, SelectSingle, Typography } from 'designSystem'
import {
  EditableTableActions,
  EditableTableIndex,
  FormItemEditInput,
  FormItemEditInputNumber,
  FormItemEditMarginSettings,
  getEditableColumnFormItemName,
  isEditableTableAddNewIndex,
  TableColumnKey,
  TableColumnsType,
} from 'components'
import { getTotalCost } from 'modules/vendors/routes/Vendor/utils/getTotalCost'

import { Accessor } from '../types/accessor'

import { getAccessorTitle } from './getAccessorTitle'

interface ModificationsTableColumnsProps {
  offsetCount: number
  isAddNew: boolean
  onCancelNew: () => void
  onDelete: (id?: number) => void
  triggerSubmit: () => void
  addNewValues: Partial<VendorModificationsSettings> | undefined
}

export const getTableColumns = ({
  offsetCount,
  isAddNew,
  onCancelNew,
  onDelete,
  triggerSubmit,
  addNewValues,
}: ModificationsTableColumnsProps): TableColumnsType<Partial<VendorModificationsSettings>> => [
  {
    title: getAccessorTitle(Accessor.Index),
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => (
      <EditableTableIndex isAddNew={isAddNew} index={index} offsetCount={offsetCount} />
    ),
  },
  {
    title: getAccessorTitle(Accessor.Name),
    key: Accessor.Name,
    dataIndex: Accessor.Name,
    width: 150,
    ellipsis: true,
    render: (value, record, index) =>
      isEditableTableAddNewIndex(index, isAddNew) ? (
        <FormItem name="item.name">
          <Input placeholder="Name" />
        </FormItem>
      ) : (
        <EditFormItem
          name={getEditableColumnFormItemName('list', Accessor.Name, index, isAddNew)}
          render={({ field }) => (
            <FormItemEditInput size="small" align="left" placeholder="Name" {...field} onBlur={triggerSubmit} />
          )}
        />
      ),
  },
  {
    title: getAccessorTitle(Accessor.Sku),
    key: Accessor.Sku,
    dataIndex: Accessor.Sku,
    width: 150,
    ellipsis: true,
    render: (value, record, index) =>
      isEditableTableAddNewIndex(index, isAddNew) ? (
        <FormItem name="item.sku">
          <Input placeholder="SKU" uppercase />
        </FormItem>
      ) : (
        <EditFormItem
          name={getEditableColumnFormItemName('list', Accessor.Sku, index, isAddNew)}
          render={({ field }) => (
            <FormItemEditInput
              size="small"
              align="left"
              placeholder="SKU"
              {...field}
              onBlur={triggerSubmit}
              uppercase
            />
          )}
        />
      ),
  },
  {
    title: getAccessorTitle(Accessor.Price),
    key: Accessor.Price,
    dataIndex: Accessor.Price,
    width: 120,
    ellipsis: true,
    render: (title, record, index) =>
      isEditableTableAddNewIndex(index, isAddNew) ? (
        <FormItem name="item.price">
          <InputNumber placeholder="Price" prefix={currency} precision={2} />
        </FormItem>
      ) : (
        <EditFormItem
          name={getEditableColumnFormItemName('list', Accessor.Price, index, isAddNew)}
          render={({ field }) => (
            <FormItemEditInputNumber
              prefix={currency}
              size="small"
              align="left"
              placeholder="Price"
              precision={2}
              {...field}
              onBlur={triggerSubmit}
            />
          )}
        />
      ),
  },

  {
    title: getAccessorTitle(Accessor.ResaleMargin),
    key: Accessor.ResaleMargin,
    dataIndex: Accessor.ResaleMargin,
    width: 230,
    ellipsis: true,
    render: (value, record, index) =>
      isEditableTableAddNewIndex(index, isAddNew) ? (
        <Row gutter={8}>
          <Col span={6}>
            <FormItem name="item.resaleMargin.operator">
              <SelectSingle placeholder="Select" options={marginSettingsOperatorOptions} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem name="item.resaleMargin.operand">
              <InputNumber placeholder="00.00" precision={2} />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem name="item.resaleMargin.measure">
              <SelectSingle placeholder="Select" options={marginSettingsMeasureOptions} />
            </FormItem>
          </Col>
        </Row>
      ) : (
        <FormItemEditMarginSettings
          size="small"
          popupContainer={document.body}
          name={getEditableColumnFormItemName('list', Accessor.ResaleMargin, index, isAddNew)}
          label=""
          onConfirm={triggerSubmit}
        />
      ),
  },
  {
    title: getAccessorTitle(Accessor.TotalCost),
    key: Accessor.TotalCost,
    dataIndex: Accessor.TotalCost,
    width: 150,
    ellipsis: true,
    render: (value, record, index) => {
      const currentData = index === 0 && isAddNew ? addNewValues : record
      const totalPrice = getTotalCost<VendorModificationsSettings>(currentData)
      return (
        <Container px={2}>
          <Typography fontWeight="xl" size="xs">
            {getFormattedPriceWithCurrency(totalPrice)}
          </Typography>
        </Container>
      )
    },
  },
  {
    title: getAccessorTitle(Accessor.Actions),
    key: TableColumnKey.Actions,
    width: 100,
    render: (value, record, index) => (
      <EditableTableActions
        isAddNew={isAddNew}
        index={index}
        onCancelNew={onCancelNew}
        onDelete={() => onDelete(record.id)}
      />
    ),
  },
]
