import { ChangeEvent, FC, FocusEvent, forwardRef, Ref, useCallback } from 'react'
import { TextAreaProps as TextAreaPropsBase } from 'antd/lib/input'
import { TextAreaRef } from 'antd/lib/input/TextArea'

import { typedMemo } from 'types'
import { replaceTextValueWhiteSpaces } from 'utils'

import * as Styled from './styles'

export interface TextAreaProps extends Omit<TextAreaPropsBase, 'value' | 'onChange'> {
  value?: string
  onChange?: (value?: string) => void
  ref?: Ref<TextAreaRef>
  error?: string
}

export const TextAreaBase: FC<TextAreaProps> = forwardRef(
  ({ value, onChange, onBlur, ...props }, ref: Ref<TextAreaRef>) => {
    const handleChange = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        const value = replaceTextValueWhiteSpaces(event.target.value)
        onChange?.(value)
      },
      [onChange],
    )

    const handleBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
      onChange?.(value?.trim())
      onBlur?.(event)
    }

    return <Styled.TextArea {...props} ref={ref} onChange={handleChange} onBlur={handleBlur} value={value} />
  },
)

export const TextArea = typedMemo(TextAreaBase)
