import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { Address, GetCustomerAddressesResponse } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { postCustomerShippingAddress } from '../queryApi'

export const usePostCustomerShippingAddress = (customerId: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation({
    mutationFn: postCustomerShippingAddress,
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.CustomerShippingAddressCreate)
      queryClient.setQueryData(
        [QueryKey.GetCustomerShippingAddresses, customerId],
        (old: GetCustomerAddressesResponse | undefined) =>
          old
            ? {
                ...old,
                data: old?.data.length ? [...old.data, data] : [data],
              }
            : undefined,
      )
      showNotification({ title: 'Shipping Address added', type: 'success' })
    },
  })

  const post = useCallback(
    (payload: Address) => {
      mutate({ customerId, data: payload })
    },
    [mutate, customerId],
  )

  return {
    post,
    ...rest,
  }
}
