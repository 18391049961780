import { FC, useCallback, useRef } from 'react'

import { useTableContext } from 'hooks'
import { getMultipleSearch, getSingleSearch } from 'utils'

import { Search } from 'designSystem'

interface TableSearchSingleProps {
  searchFieldName: string | string[]
  width?: number
}

export const TableSearchSingle: FC<TableSearchSingleProps> = ({ searchFieldName, width = 160 }) => {
  const { setSearch, search: tableSearch } = useTableContext()
  const searched = useRef(tableSearch?.search || '')

  const handleSearch = (value: string) => {
    searched.current = value
  }

  const handleClear = () => {
    setSearch?.()
  }

  const updateSearch = useCallback(() => {
    if (Array.isArray(searchFieldName)) {
      setSearch?.(getMultipleSearch(searchFieldName.toString(), searchFieldName, searched.current))
    } else {
      setSearch?.(getSingleSearch(searchFieldName, searched.current))
    }
  }, [setSearch, searchFieldName])

  return (
    <Search
      allowClear
      width={width}
      onSearch={handleSearch}
      onClickClear={handleClear}
      onClickSearchIcon={updateSearch}
      onPressEnter={updateSearch}
      onBlur={updateSearch}
      defaultValue={tableSearch?.search}
    />
  )
}
