import { Navigate } from 'react-router-dom'

import { customersRoute, customerViewRoute } from 'constant'

import { NotFoundPage } from 'components'

import { Customer } from '../routes/Customer'
import { CustomerCreate } from '../routes/CustomerCreate'
import { CustomersList } from '../routes/CustomersList'

export const routes = [
  {
    path: customersRoute.create,
    element: <CustomerCreate />,
  },
  {
    path: customersRoute.view,
    element: <Navigate to={customerViewRoute.details.replace('/', '')} replace />,
  },
  {
    path: `${customersRoute.view}/*`,
    element: <Customer />,
  },
  {
    path: customersRoute.list,
    element: <CustomersList />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
