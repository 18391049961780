import { styled } from 'styled-components'

import { Button } from 'designSystem'

export const CountButton = styled(Button)`
  border: none;
  background: transparent;
  box-shadow: none;
  min-width: unset !important;
  color: ${({ theme }) => theme.colors.accent[400]};
`

export const ContentWrapper = styled.div<{ $columnsCount: number }>`
  display: grid;
  grid-template-columns: ${({ $columnsCount }) => `repeat(${$columnsCount}, auto)`};
  grid-gap: ${({ theme }) => theme.spacers[3]};
  max-height: 180px;
  padding: ${({ theme }) => theme.spacers[3]};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  box-shadow: 0 5px 23px 0 rgba(0, 0, 0, 0.2);
  overflow: auto;

  > * {
    margin: 0 ${({ theme }) => theme.spacers[3]};
  }
`
