import { api, TruckCode } from 'api'

import { TruckCodePayload } from '../../types/truckCodePayload'

export type CreateTruckCodePayload = TruckCodePayload

export const postTruckCode = (data: CreateTruckCodePayload): Promise<TruckCode> => {
  return api({
    method: 'post',
    url: '/api/v1/truck-codes',
    data,
  })
}
