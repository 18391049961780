import * as Yup from 'yup'

import {
  VendorCarrierSettings,
  VendorContainerSettings,
  VendorDepotSettings,
  VendorModificationsSettings,
  VendorPartsSettings,
  VendorType,
} from 'api'

import {
  vendorCarrierSettingsSchema,
  vendorContainerSettingsSchema,
  vendorDepotSettingsSchema,
  vendorModificationsSettingsSchema,
  vendorPartsSettingsSchema,
  vendorSchema,
} from '../../../../../constants/vendorValidation'

export const getValidationSchema = (initialTypes?: VendorType[]) =>
  Yup.object({
    ...vendorSchema,
    locationCodeIds: Yup.array(
      Yup.object({ value: Yup.number(), label: Yup.string() }).required('Location code required'),
    ),
    modificationsSettings: Yup.mixed<VendorModificationsSettings>()
      .when('type', {
        is: (type: VendorType) =>
          type.includes(VendorType.Modifications) && !initialTypes?.includes(VendorType.Modifications),
        then: () => Yup.object(vendorModificationsSettingsSchema),
      })
      .default(undefined),
    containerSupplierSettings: Yup.mixed<VendorContainerSettings>()
      .when('type', {
        is: (type: VendorType) =>
          type.includes(VendorType.ContainerVendor) && !initialTypes?.includes(VendorType.ContainerVendor),
        then: () => Yup.object(vendorContainerSettingsSchema),
      })
      .default(undefined),
    carrierSettings: Yup.mixed<VendorCarrierSettings>()
      .when('type', {
        is: (type: VendorType) => type.includes(VendorType.Carrier) && !initialTypes?.includes(VendorType.Carrier),
        then: () => Yup.object(vendorCarrierSettingsSchema),
      })
      .default(undefined),
    depotSettings: Yup.mixed<VendorDepotSettings>()
      .when('type', {
        is: (type: VendorType) => type.includes(VendorType.Depot) && !initialTypes?.includes(VendorType.Depot),
        then: () => Yup.object(vendorDepotSettingsSchema),
      })
      .default(undefined),
    partSettings: Yup.mixed<VendorPartsSettings>()
      .when('type', {
        is: (type: VendorType) => type.includes(VendorType.Parts) && !initialTypes?.includes(VendorType.Parts),
        then: () => Yup.object(vendorPartsSettingsSchema),
      })
      .default(undefined),
  })
