import { api, EntityId, Vendor } from 'api'

interface PatchVendorIsFavoriteParams {
  id: EntityId
  isFavorite: boolean
}

export const patchVendorIsFavorite = ({ id, isFavorite }: PatchVendorIsFavoriteParams): Promise<Vendor> => {
  return api({
    method: 'patch',
    url: `/api/v1/vendors/${id}`,
    data: { isFavorite },
  })
}
