import { FC, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { typedMemo } from 'types'

import { Tabs, TabsProps } from 'designSystem'

interface LinkTabsProps extends TabsProps {
  onChange?: (currentTab: string) => void
}

export const LinkTabsBase: FC<LinkTabsProps> = ({ onChange, ...props }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const lastPath = pathname.split('/').pop()

  const handleChange = useCallback(
    (currentTab: string) => {
      onChange?.(currentTab)

      navigate(`${pathname.replace(`/${lastPath}`, '')}${currentTab}`)
    },
    [navigate, pathname, lastPath, onChange],
  )

  return <Tabs {...props} activeKey={`/${lastPath}`} onChange={handleChange} />
}

export const LinkTabs = typedMemo(LinkTabsBase)
