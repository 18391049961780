import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { EntityId } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { prepareVendorDetailsPayload } from '../routes/VendorDetails/utils/prepareVendorDetailsPayload'
import { VendorDetailsFormData } from '../routes/VendorDetails/types/vendorDetailsForm'

import { patchVendor } from '../routes/VendorDetails/queryApi'

interface UseUpdateVendorProps {
  id: EntityId
  onSettled?: () => void
  revalidateQueries?: boolean
}

export const useUpdateVendor = ({ id, onSettled, revalidateQueries = true }: UseUpdateVendorProps) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation({
    mutationFn: patchVendor,
    onSuccess: (data, payload) => {
      if (revalidateQueries) {
        dispatchAppEvent(AppEvent.VendorUpdate)
      }
      if (payload.data.carrierSettings) {
        queryClient.setQueryData([QueryKey.GetCarrierByVendorId, payload.id], payload.data.carrierSettings)
      }
      if (payload.data.containerSupplierSettings) {
        queryClient.setQueryData(
          [QueryKey.GetContainerVendorByVendorId, payload.id],
          payload.data.containerSupplierSettings,
        )
      }
      if (payload.data.depotSettings) {
        queryClient.invalidateQueries({ queryKey: [QueryKey.GetDepotByVendorId, payload.id] })
      }

      queryClient.setQueryData([QueryKey.GetVendorById, id], data)
      showNotification({ title: 'Changes saved', type: 'success' })
    },
    onSettled,
  })

  const update = useCallback(
    (payload: VendorDetailsFormData) => {
      const data = prepareVendorDetailsPayload(payload)
      mutate({ id, data })
    },
    [mutate, id],
  )

  return {
    update,
    ...rest,
  }
}
