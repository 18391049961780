import { create } from 'zustand'

import { EntityId, ItemCategory } from 'api'

import { insertCategory, removeCategory, renameCategory, searchCategories } from './utils'

interface ItemCreateStore {
  search: string
  setSearch: (search: string) => void
  searchCategories: ItemCategory[]
  activeCategoryId?: EntityId
  itemCategories: ItemCategory[]
  setItemCategories: (itemCategories: ItemCategory[]) => void
  removeCategoryItem: (id: number) => void
  updateCategoryName: (name: string, id: number) => void
  setActiveCategoryId: (id: EntityId) => void
  removeActiveCategoryId: () => void
  addCategory: (category: ItemCategory) => void
}

const useItemCreateStore = create<ItemCreateStore>((set) => ({
  search: '',
  activeCategoryId: undefined,
  itemCategories: [],
  searchCategories: [],
  setSearch: (search: string) =>
    set((state) => {
      const updatedSearchCategories = searchCategories(state.itemCategories, search)
      return { searchCategories: updatedSearchCategories, search }
    }),
  setActiveCategoryId: (activeCategoryId?: EntityId) => set({ activeCategoryId }),
  removeActiveCategoryId: () => set({ activeCategoryId: undefined }),
  setItemCategories: (itemCategories?: ItemCategory[]) => set({ itemCategories }),
  removeCategoryItem: (id: number) =>
    set((state) => {
      const updatedItemCategories = removeCategory(state.itemCategories, id)
      if (state.search) {
        const updatedSearchCategories = removeCategory(state.searchCategories, id)
        return { itemCategories: updatedItemCategories, searchCategories: updatedSearchCategories }
      } else {
        return { itemCategories: updatedItemCategories }
      }
    }),
  updateCategoryName: (name: string, id: number) =>
    set((state) => {
      const updatedItemCategories = renameCategory(state.itemCategories, name, id)
      if (state.search) {
        const updatedSearchCategories = renameCategory(state.searchCategories, name, id)
        const filterSearchCategories = searchCategories(updatedSearchCategories, state.search)
        return { itemCategories: updatedItemCategories, searchCategories: filterSearchCategories }
      } else {
        return { itemCategories: updatedItemCategories }
      }
    }),
  addCategory: (newCategory: ItemCategory) =>
    set((state) => {
      const updatedItemCategories = insertCategory(state.itemCategories, newCategory)
      if (newCategory.name.toLowerCase().includes(state.search.toLowerCase())) {
        const updatedSearchCategories = insertCategory(state.searchCategories, newCategory)
        return { itemCategories: updatedItemCategories, searchCategories: updatedSearchCategories }
      } else {
        return { itemCategories: updatedItemCategories }
      }
    }),
}))

export const useItemActiveCategoryId = () => useItemCreateStore((state) => state.activeCategoryId)
export const useItemSetActiveCategoryId = () => useItemCreateStore((state) => state.setActiveCategoryId)
export const useItemRemoveActiveCategoryId = () => useItemCreateStore((state) => state.removeActiveCategoryId)
export const useItemCategories = () => useItemCreateStore((state) => state.itemCategories)
export const useItemSetCategories = () => useItemCreateStore((state) => state.setItemCategories)
export const useItemRemoveCategory = () => useItemCreateStore((state) => state.removeCategoryItem)
export const useItemUpdateCategoryName = () => useItemCreateStore((state) => state.updateCategoryName)
export const useItemAddCategory = () => useItemCreateStore((state) => state.addCategory)
export const useItemSearch = () => useItemCreateStore((state) => state.search)
export const useItemSetSearch = () => useItemCreateStore((state) => state.setSearch)
export const useItemSearchCategories = () => useItemCreateStore((state) => state.searchCategories)
