import { useCallback } from 'react'

import type { ZohoLinks } from 'api'
import { useUserCompanyZohoLinks } from 'store'

export interface GetZohoLinkProps {
  module: keyof ZohoLinks
  id?: number | string
}

export const useZohoLink = () => {
  const userCompanyZohoLinks = useUserCompanyZohoLinks()

  const getZohoLink = useCallback(
    ({ module, id }: GetZohoLinkProps): string | undefined => {
      if (userCompanyZohoLinks?.[module] && id) {
        return `${userCompanyZohoLinks[module]}/${id}`
      }
      return undefined
    },
    [userCompanyZohoLinks],
  )

  return {
    getZohoLink,
    zohoLinks: userCompanyZohoLinks,
  }
}
