import { FC } from 'react'

import { FontSize, Typography } from 'designSystem'

interface StorageFeeTitleProps {
  titleSize?: FontSize
}

export const StorageFeeTitle: FC<StorageFeeTitleProps> = ({ titleSize = 'm' }) => {
  return (
    <>
      <Typography fontWeight="xl" size={titleSize}>
        Storage Fee
      </Typography>
      <Typography size="xs" fontWeight="l" color="secondary">
        Select Containers to setup Daily Rates
      </Typography>
    </>
  )
}
