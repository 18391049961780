import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import isEmpty from 'lodash.isempty'
import type { FilterValue, SorterResult } from 'antd/es/table/interface'
import { TablePaginationConfig } from 'antd/lib/table'
import isEqual from 'react-fast-compare'

import { UserSettingsService } from 'services'
import { usePagination } from 'hooks/usePagination'
import { AppModule } from 'types'

import type { TableFilterOption } from 'components'

import { getFiltersQueryParams } from './utils/getFiltersQueryParams'
import { getTableSortQuery } from './utils/getTableSortQuery'
import { TableField } from './types/tableField'
import { QueryFilter, TableFilter } from './types/tableFilter'
import { TableSearch } from './types/tableSearch'
import { TableSorter } from './types/tableSorter'
import { TableSortingOrder } from './types/tableSortingOrder'

import { TableContext, useTableContextValue } from './contexts/tableContext'

interface UseTableProps {
  defaultSorters?: TableSorter[]
  defaultFilters?: TableFilter
  module?: AppModule
}

export const useTable = <T,>({ defaultSorters, defaultFilters, module }: UseTableProps) => {
  const pagination = usePagination({ module })
  const [sorters, setSorters] = useState<TableSorter[]>([])
  const [filters, setFilters] = useState<TableFilter | undefined>({
    ...UserSettingsService.getModuleTableSettings(module)?.filters,
    ...defaultFilters,
  })
  const [search, setSearch] = useState<TableSearch>()

  const [filterOptions, setFilterOptions] = useState<TableFilterOption['value'][]>(
    UserSettingsService.getModuleTableSettings(module)?.selectedFilters || [],
  )

  const filtersRef = useRef(filters) // Need to update ref before setFilters
  const searchRef = useRef(search)
  const filterOptionsRef = useRef(filterOptions)
  const [queryFilters, setQueryFilters] = useState<QueryFilter | undefined>(getFiltersQueryParams(filtersRef.current))

  const resetDefaultFilters = useCallback(() => {
    const currentFilters = UserSettingsService.getModuleTableSettings(module)?.filters
    filtersRef.current = currentFilters
    setFilters(currentFilters)
    setFilterOptions(UserSettingsService.getModuleTableSettings(module)?.selectedFilters || [])
  }, [module])

  const onChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<T> | SorterResult<T>[],
    ) => {
      if (sorter) {
        if (Array.isArray(sorter)) {
          setSorters(sorter.map(({ field, order }) => ({ field: field as TableField, order })))
        } else {
          setSorters([{ field: sorter.field as TableField, order: sorter.order }])
        }
      }
    },
    [],
  )

  const applyFilters = useCallback(() => {
    const queryFilters = getFiltersQueryParams(filtersRef.current)
    setQueryFilters(queryFilters)

    if (module) {
      UserSettingsService.setModuleTableSettings(module, {
        filters: filtersRef.current,
        selectedFilters: filterOptionsRef.current,
      })
    }
  }, [module])

  useEffect(() => {
    applyFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSetFilterOptions = useCallback(
    (options: TableFilterOption['value'][]) => {
      const newFilters = {} as TableFilter

      options.forEach((option) => {
        if (filters?.[option as keyof TableFilter]?.value) {
          newFilters[option] = filters[option as keyof TableFilter]
        }
      })

      setFilterOptions(options)
      setFilters(newFilters)
      filtersRef.current = newFilters
      filterOptionsRef.current = options
    },
    [filters],
  )

  const getFilterValue = useCallback((name: string) => {
    return filtersRef.current?.[name]?.value
  }, [])

  const hasFiltersChanged = useCallback(() => {
    const currentFilters = {
      filters: filtersRef.current,
      selectedFilters: filterOptionsRef.current,
    }

    const appliedFilters = {
      filters: UserSettingsService.getModuleTableSettings(module)?.filters,
      selectedFilters: UserSettingsService.getModuleTableSettings(module)?.selectedFilters,
    }

    return !isEqual(currentFilters, appliedFilters)
  }, [module])

  const getIsFilterApplied = useCallback(() => {
    return Object.values(filtersRef.current || {}).some((v: any) => {
      if (v.value && Object.prototype.hasOwnProperty.call(v.value, 'value')) {
        return v.value?.value
      }
      return !isEmpty(v.value)
    })
  }, [])

  const getFieldSorter = useCallback(
    (dataIndex: TableField): TableSortingOrder => {
      return sorters.find(({ field }) => isEqual(field, dataIndex))?.order
    },
    [sorters],
  )

  const sortQueryParams: string[] | undefined = useMemo(() => {
    return getTableSortQuery(sorters) || getTableSortQuery(defaultSorters)
  }, [sorters, defaultSorters])

  const updateFilters = useCallback((newFilters: TableFilter) => {
    const transformedFilters: TableFilter = {}

    Object.keys(newFilters).forEach((key) => {
      transformedFilters[key] = {
        value: newFilters[key].value,
        condition: newFilters[key].condition,
      }
    })

    const filtersState = {
      ...(filtersRef.current && filtersRef.current),
      ...transformedFilters,
    }

    filtersRef.current = filtersState
    setFilters(filtersState)
  }, [])

  const searchQueryParams: string | undefined = useMemo(() => {
    const searchParams = [search?.value, queryFilters].filter(Boolean)

    if (searchParams.length) {
      if (pagination.page !== 1) {
        pagination.onChange(1) // reset page to get correct data
      }

      return JSON.stringify({ $and: searchParams })
    }

    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, queryFilters])

  const TableContextProvider: FC<PropsWithChildren> = useCallback(
    ({ children }) => (
      <TableContext.Provider
        value={{
          filters: filtersRef.current,
          updateFilters,
          search: searchRef.current,
          setSearch,
          applyFilters,
          clearFilters,
          getFilterValue,
          getIsFilterApplied,
          resetDefaultFilters,
          hasFiltersChanged,
        }}
      >
        {children}
      </TableContext.Provider>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersRef, searchRef, getFilterValue, getIsFilterApplied],
  )

  // Using ref to avoid rerender Context Provider
  useEffect(() => {
    filtersRef.current = filters
  }, [filters])

  const clearFilters = useCallback(() => {
    filtersRef.current = undefined
    setFilters(undefined)
    setQueryFilters(undefined)
    if (module) {
      UserSettingsService.setModuleTableSettings(module, { filters: undefined })
    }
  }, [module])

  return {
    applyFilters,
    pagination,
    sortQueryParams,
    searchQueryParams,
    getFieldSorter,
    onChange,
    TableContextProvider,
    search,
    setSearch,
    filters,
    filterOptions,
    setFilterOptions: handleSetFilterOptions,
    clearFilters,
  }
}

export const useTableContext = useTableContextValue
