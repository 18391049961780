import { FC } from 'react'

import { Contact } from 'api'
import { useMemoCompare } from 'hooks'
import { typedMemo } from 'types'

import { ModalProps, Tabs } from 'designSystem'

import { getTabItems } from './utils/getTabItems'
import { AddContactsFormData } from './types/addContactsFormData'
import * as Styled from './styles'

export interface AddContactsModalProps extends Omit<ModalProps, 'onCancel' | 'onOk'> {
  contacts?: Contact[] | null
  entityContacts?: Contact[] | null
  showAddNew?: boolean
  contactsCanAddCount?: number
  onConfirm: (payload: AddContactsFormData) => void
  onCancel: () => void
}

export const AddContactsModalBase: FC<AddContactsModalProps> = ({
  title = 'Add Contacts',
  open,
  footer = null,
  width = 440,
  contacts,
  entityContacts,
  onConfirm,
  onCancel,
  confirmLoading,
  showAddNew,
  contactsCanAddCount,
}) => {
  const entityContactsOptions = entityContacts
    ?.filter((entityContact) => !contacts?.map(({ email }) => email).includes(entityContact.email))
    .map(({ id, firstName, lastName }) => ({
      value: id,
      label: `${firstName} ${lastName}`,
    }))

  const tabsProps = useMemoCompare({
    contacts,
    entityContactsOptions,
    onConfirm,
    onCancel,
    confirmLoading,
    showAddNew,
    contactsCanAddCount,
  })

  const tabItems = getTabItems(tabsProps)

  return (
    <Styled.Modal title={title} open={open} footer={footer} width={width} onCancel={onCancel} destroyOnClose>
      <Tabs items={tabItems} destroyInactiveTabPane />
    </Styled.Modal>
  )
}

export const AddContactsModal = typedMemo(AddContactsModalBase)
