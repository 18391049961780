import { styled } from 'styled-components'

import { Button as BaseButton } from 'designSystem'

export const WarningWrapper = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.warning[400]};
  padding: 8px 12px 18px 12px;
  margin-bottom: -10px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  gap: 10px;
`
export const WarningIcon = styled.div`
  margin-top: ${({ theme }) => theme.spacers[1]};
`

export const WarningText = styled.div`
  max-width: 290px;
`

export const Button = styled(BaseButton)`
  &.ant-btn.ant-btn-loading {
    opacity: 1;
  }
`
