export const referenceNumberByIdJoinParams = {
  join: [
    'locationCode||id,code',
    'depotSetting||id,code,vendorId',
    'contacts',
    'purchaseOrder||id,number,status,createdAt,allQty',
    'salesOrder||id,number',
    'vendorRelease||id,number,purchaseOrder,items',
    'vendorRelease.items||itemId,qty',
    'vendorRelease.purchaseOrder||id,number,status,createdAt,allQty',
    'customer||id,companyName',
    'vendor||id,companyName',
    'dispatcher||id,firstName,lastName',
    'availableAssets||id,assetNumber',
    'customerAddress',
    'bookingNumbers||id,number,status,createdAt,allQty',
    'bookingNumber||id,number,status,createdAt,allQty',
    'redeliveryNumbers||id,number,status,createdAt,allQty',
    'gateInLocationCode||id,code',
    'gateInDepotSetting||id,code,vendorId',
  ],
}
