import { Navigate } from 'react-router-dom'

import { profileRoute } from 'constant'

import { LinkTabs, NotFoundPage } from 'components'

import { profileTabs } from './profileTabs'

export const profileRoutes = [
  {
    path: '/',
    element: <Navigate to={profileRoute.mainInfo.replace('/', '')} replace />,
  },
  {
    path: profileRoute.mainInfo,
    element: <LinkTabs items={profileTabs} />,
  },
  {
    path: profileRoute.password,
    element: <LinkTabs items={profileTabs} destroyInactiveTabPane={true} />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
