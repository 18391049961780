import * as Yup from 'yup'

import { emailValidation, phoneNumberValidation } from 'utils'

export const validationSchema = Yup.object({
  firstName: Yup.string().max(50, 'Max length of 50 characters').required('First name is required'),
  lastName: Yup.string().max(50, 'Max length of 50 characters').required('Last name is required'),
  phoneNumber: phoneNumberValidation,
  phoneExtension: Yup.string().max(5, 'Max length of 5 characters'),
  email: emailValidation,
})
