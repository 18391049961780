import { VendorCarrierSettingsEquipment } from 'api'
import { useTruckCodes } from 'hooks'

import { EditFormItem, FormItem, Input, InputNumber } from 'designSystem'
import {
  EditableTableActions,
  EditableTableIndex,
  FormItemEditInput,
  FormItemEditInputNumber,
  FormItemEditSelectSingleAsync,
  FormItemSelectSingleAsync,
  getEditableColumnFormItemName,
  isEditableTableAddNewIndex,
  TableColumnKey,
  TableColumnsType,
} from 'components'

import { Accessor } from '../types/accessor'

import { getAccessorTitle } from './getAccessorTitle'

interface EquipmentTableColumnsProps {
  offsetCount: number
  isAddNew: boolean
  onCancelNew: () => void
  onDelete: (id?: number) => void
  triggerSubmit: () => void
}

export const getTableColumns = ({
  offsetCount,
  isAddNew,
  onCancelNew,
  onDelete,
  triggerSubmit,
}: EquipmentTableColumnsProps): TableColumnsType<Partial<VendorCarrierSettingsEquipment>> => [
  {
    title: getAccessorTitle(Accessor.Index),
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => (
      <EditableTableIndex isAddNew={isAddNew} index={index} offsetCount={offsetCount} />
    ),
  },
  {
    title: getAccessorTitle(Accessor.Title),
    key: Accessor.Title,
    dataIndex: Accessor.Title,
    width: '30%',
    ellipsis: true,
    render: (title, record, index) =>
      isEditableTableAddNewIndex(index, isAddNew) ? (
        <FormItem name="item.title">
          <Input placeholder="Title" />
        </FormItem>
      ) : (
        <EditFormItem
          name={getEditableColumnFormItemName('list', Accessor.Title, index, isAddNew)}
          render={({ field }) => (
            <FormItemEditInput size="small" align="left" placeholder="Title" {...field} onBlur={triggerSubmit} />
          )}
        />
      ),
  },
  {
    title: getAccessorTitle(Accessor.TruckCode),
    key: Accessor.TruckCode,
    dataIndex: Accessor.TruckCode,
    width: '15%',
    ellipsis: true,
    render: (truckCodeId, record, index) =>
      isEditableTableAddNewIndex(index, isAddNew) ? (
        <FormItemSelectSingleAsync name="item.truckCodeId" getItems={useTruckCodes} />
      ) : (
        <FormItemEditSelectSingleAsync
          name={getEditableColumnFormItemName('list', Accessor.TruckCode, index, isAddNew)}
          size="small"
          fullwidth
          onSubmit={triggerSubmit}
          defaultOption={{
            value: truckCodeId,
            label: record.truckCode?.code as string,
          }}
          getItems={useTruckCodes}
        />
      ),
  },
  {
    title: getAccessorTitle(Accessor.Qty),
    key: Accessor.Qty,
    dataIndex: Accessor.Qty,
    width: '10%',
    ellipsis: true,
    render: (title, record, index) =>
      isEditableTableAddNewIndex(index, isAddNew) ? (
        <FormItem name="item.qty">
          <InputNumber placeholder="Qty" />
        </FormItem>
      ) : (
        <EditFormItem
          name={getEditableColumnFormItemName('list', Accessor.Qty, index, isAddNew)}
          render={({ field }) => (
            <FormItemEditInputNumber size="small" align="left" placeholder="Qty" {...field} onBlur={triggerSubmit} />
          )}
        />
      ),
  },
  {
    title: getAccessorTitle(Accessor.PhoneNumber),
    key: Accessor.PhoneNumber,
    dataIndex: Accessor.PhoneNumber,
    width: '15%',
    ellipsis: true,
    render: (title, record, index) =>
      isEditableTableAddNewIndex(index, isAddNew) ? (
        <FormItem name="item.phoneNumber">
          <Input placeholder="+" type="phone" />
        </FormItem>
      ) : (
        <EditFormItem
          name={getEditableColumnFormItemName('list', Accessor.PhoneNumber, index, isAddNew)}
          render={({ field }) => (
            <FormItemEditInput
              size="small"
              align="left"
              placeholder="+"
              type="phone"
              {...field}
              onBlur={triggerSubmit}
            />
          )}
        />
      ),
  },
  {
    title: getAccessorTitle(Accessor.Email),
    key: Accessor.Email,
    dataIndex: Accessor.Email,
    width: '15%',
    ellipsis: true,
    render: (title, record, index) =>
      isEditableTableAddNewIndex(index, isAddNew) ? (
        <FormItem name="item.email">
          <Input placeholder="Email" />
        </FormItem>
      ) : (
        <EditFormItem
          name={getEditableColumnFormItemName('list', Accessor.Email, index, isAddNew)}
          render={({ field }) => (
            <FormItemEditInput size="small" align="left" placeholder="Email" {...field} onBlur={triggerSubmit} />
          )}
        />
      ),
  },
  {
    title: getAccessorTitle(Accessor.Actions),
    key: TableColumnKey.Actions,
    width: '15%',
    render: (value, record, index) => (
      <EditableTableActions
        isAddNew={isAddNew}
        index={index}
        onCancelNew={onCancelNew}
        onDelete={() => onDelete(record.id)}
      />
    ),
  },
]
