import { Input as BaseInput } from 'antd'
import { css, styled } from 'styled-components'

import type { InputProps } from './Input'

interface StyledInputsProps extends Omit<InputProps, 'onChange'> {
  $isPrefix?: boolean
}

export const Wrapper = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}px;` : '100%')};
  position: relative;
`

const InputStyles = css`
  padding-left: ${({ theme }) => theme.spacers[3]};
  padding-right: ${({ theme }) => theme.spacers[3]};

  .ant-input {
    font-weight: ${({ theme }) => theme.fontWeight.l};
  }
  &&&.ant-input-affix-wrapper {
    &:hover,
    &:focus {
      z-index: ${({ theme }) => theme.zIndexLevel[1]};
      border-color: ${({ theme }) => theme.colors.accent[400]};
      box-shadow: ${({ theme }) => theme.boxShadow.input};
    }
  }
  &&&& .ant-input-suffix,
  &&&& .ant-input-prefix {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.fontWeight.l};
  }
`

export const Input = styled(BaseInput)<StyledInputsProps>`
  ${InputStyles};

  .ant-input {
    text-align: ${({ align }) => align || 'left'};
    direction: unset;
  }

  ${({ $isPrefix }) =>
    !$isPrefix &&
    css`
      &&&&& .ant-input-prefix {
        margin-inline-end: 0;
      }
    `}
`

export const InputPassword = styled(BaseInput.Password)<StyledInputsProps>`
  ${InputStyles}
`
