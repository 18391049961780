import { css, styled } from 'styled-components'

import { Input as BaseInput } from '../../Input'
import type { EditInputProps } from './EditInput'

export const Input = styled(BaseInput)<EditInputProps>`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacers[1]} ${theme.spacers[2]}`} !important;

  && .ant-input-prefix {
    display: none;
  }
  && .ant-input {
    font-weight: ${({ theme }) => theme.fontWeight.l};
  }

  ${({ size }) => {
    if (size === 'small') {
      return css`
        font-size: ${({ theme }) => theme.fontSize.xs};
        height: ${({ theme }) => theme.inlineEdit.size.s};
        line-height: 14px;
      `
    } else if (size === 'large') {
      return css`
        font-size: ${({ theme }) => theme.fontSize.l};
        height: ${({ theme }) => theme.inlineEdit.size.l};
        && .ant-input {
          font-weight: ${({ theme }) => theme.fontWeight.xl};
        }
      `
    }

    return css`
      font-size: ${({ theme }) => theme.fontSize.s};
      height: ${({ theme }) => theme.inlineEdit.size.m};
    `
  }}
`
