import { CustomerStatus } from 'api'
import { customerStatusOptions } from 'constant'

import { TagType } from 'designSystem'

interface CustomerStatusTagDataProps {
  status: TagType
  text?: string
}

export const getTagDataByCustomerStatus = (status: CustomerStatus): CustomerStatusTagDataProps => {
  const text = customerStatusOptions.find(({ value }) => value === status)?.label

  const tagStatus = {
    [CustomerStatus.Inactive]: TagType.Warning,
    [CustomerStatus.Active]: TagType.Success,
    [CustomerStatus.Blocked]: TagType.Disabled,
  }[status]

  return { status: tagStatus, text }
}
