import { route } from 'constant'

export const breadcrumbsData = [
  {
    title: 'Assets',
    url: route.assets,
  },
  {
    title: 'Assets Details',
  },
]
