import { purchaseOrderLabels } from 'constant'
import { PurchaseOrderItemAccessor } from 'types'

export const getAccessorItemTitle = (accessor: PurchaseOrderItemAccessor): string => {
  return {
    [PurchaseOrderItemAccessor.Index]: '#',
    [PurchaseOrderItemAccessor.Sku]: purchaseOrderLabels.items.itemId,
    [PurchaseOrderItemAccessor.ItemName]: purchaseOrderLabels.items.title,
    [PurchaseOrderItemAccessor.Qty]: purchaseOrderLabels.items.qty,
    [PurchaseOrderItemAccessor.Rate]: purchaseOrderLabels.items.rate,
    [PurchaseOrderItemAccessor.ReceivedQty]: purchaseOrderLabels.items.receivedQty,
    [PurchaseOrderItemAccessor.BilledQty]: purchaseOrderLabels.items.billedQty,
    [PurchaseOrderItemAccessor.TotalAmount]: purchaseOrderLabels.items.totalAmount,
  }[accessor]
}
