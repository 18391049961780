import { companyRoute } from 'constant'

import { Tab } from 'designSystem'

import { GeneralInformation } from '../routes/GeneralInformation'

export const companyTabs: Tab[] = [
  {
    key: companyRoute.generalInformation,
    label: 'General Information',
    children: <GeneralInformation />,
  },
]
