import { FC } from 'react'

import { Container } from 'designSystem'
import { ZohoIntegration } from 'components'

interface PurchaseOrderIntegrationsProps {
  zohoId: number
}

export const PurchaseOrderIntegrations: FC<PurchaseOrderIntegrationsProps> = ({ zohoId }) => {
  return (
    <Container bg="white">
      <ZohoIntegration module="zohoPurchaseOrdersLink" id={zohoId} />
    </Container>
  )
}
