import { FC } from 'react'
import type { ModalProps as ModalPropsBase } from 'antd/lib/modal'

import { typedMemo } from 'types'
import theme from 'styles/theme'

import { Spinner } from 'components/Spinner'

import * as Styled from './styles'

import { Icon } from '../Icon'
import { SpacerLevel } from '../Spacer'

export type ModalProps = ModalPropsBase & {
  isTabsBody?: boolean
  pa?: SpacerLevel
  isLoading?: boolean
}

export const ModalBase: FC<ModalProps> = ({ children, isLoading, ...props }) => {
  return (
    <Styled.Modal closeIcon={<Icon icon="cross" size={20} />} zIndex={theme.zIndexLevel.modal} {...props}>
      {isLoading && (
        <Styled.SpinnerWrapper>
          <Spinner />
        </Styled.SpinnerWrapper>
      )}
      {children}
    </Styled.Modal>
  )
}

export const Modal = typedMemo(ModalBase)
