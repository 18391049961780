export enum QueryCondition {
  Equal = '$eq',
  NotEqual = '$ne',
  Between = '$between',
  LessThanEqual = '$lte',
  GraterThanEqual = '$gte',
  Includes = '$in',
  NotIncludes = '$notin',
  ContainsLike = '$contL',
  ContainsArray = '$contArr',
  NotNull = '$notnull',
  IntersectsArray = '$intersectsArr',
}
