import { FC } from 'react'
import { Col, Row } from 'antd'

import { purchaseOrderLabels } from 'constant'
import { PurchaseOrderFormAccessor } from 'types'

import { FormItem, Input, Spacer } from 'designSystem'

import { useValueChange } from '../../../AdditionalInformation/hooks/useValueChange'

import { DateFields } from '../DateFields/DateFields'
import { LocationVsDepotFields } from '../LocationVsDepotFields'
import { PaymentTermsField } from '../PaymentTermsField'

export const VendorReleaseFields: FC = () => {
  const { handleBlur } = useValueChange(PurchaseOrderFormAccessor.VendorReleaseNumber)
  return (
    <>
      <Row gutter={8}>
        <Col span={24}>
          <FormItem
            name={PurchaseOrderFormAccessor.VendorReleaseNumber}
            label={purchaseOrderLabels.vendorReleaseNumber}
          >
            <Input placeholder={purchaseOrderLabels.vendorReleaseNumber} onBlur={handleBlur} />
          </FormItem>
        </Col>
      </Row>
      <Spacer mb={3} />
      <LocationVsDepotFields />
      <Spacer mb={3} />
      <DateFields />
      <Spacer mb={3} />
      <Row gutter={8}>
        <Col span={12}>
          <PaymentTermsField />
        </Col>
      </Row>
    </>
  )
}
