import { FC } from 'react'

import { AsyncSelectGetItemsProps, typedMemo } from 'types'

import { FormItem, SelectSingle, SelectSingleOption, SelectSingleProps } from 'designSystem'

interface FormItemSelectSingleAsyncProps extends SelectSingleProps, Omit<AsyncSelectGetItemsProps, 'id'> {
  id?: string
  name: string
  disabledValues?: (number | string)[]
  selectedOption?: SelectSingleOption
  index?: number
  getItems: (props: AsyncSelectGetItemsProps) => any
}

export const FormItemSelectSingleAsyncBase: FC<FormItemSelectSingleAsyncProps> = ({
  id,
  name,
  label,
  disabledValues,
  selectedOption,
  index,
  getItems,
  defaultOption,
  valuePropName,
  nestedEntityId,
  queryParams,
  disabled,
  withFullDataOption,
  fields,
  join,
  allowClear = true,
  ...props
}) => {
  const { options, onSearch, onPopupScrollEnd, isLoading, onChangeEnabled } = getItems({
    id: id || name,
    valuePropName,
    nestedEntityId,
    queryParams,
    disabled,
    withFullDataOption,
    fields,
    join,
  })

  const optionsFiltered =
    index !== undefined
      ? options
      : [
          ...(selectedOption ? [selectedOption] : []),
          ...(options || []).filter((option: SelectSingleOption) => option?.value !== selectedOption?.value),
        ]

  return (
    <FormItem name={name} label={label}>
      <SelectSingle
        {...props}
        name={name}
        allowClear={allowClear}
        id={id}
        defaultOption={defaultOption}
        disabledValues={disabledValues}
        options={optionsFiltered}
        onSearch={onSearch}
        onPopupScrollEnd={onPopupScrollEnd}
        onFocusChange={onChangeEnabled}
        loading={isLoading}
        disabled={disabled}
        showSearch
      />
    </FormItem>
  )
}

export const FormItemSelectSingleAsync = typedMemo(FormItemSelectSingleAsyncBase)
