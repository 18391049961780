import { api, Asset, EntityId, getAssetsJoinQueryParams, getAssetsSortQueryParams } from 'api'

import { AssetPayload } from 'modules/assets/types/assetPayload'

export const getAssetById = (assetId?: EntityId): Promise<Asset> => {
  return api({
    method: 'get',
    url: `/api/v1/assets/${assetId}`,
    params: {
      join: getAssetsJoinQueryParams,
      sort: getAssetsSortQueryParams,
    },
  })
}

export const patchAsset = ({ id, data }: { id: EntityId; data: AssetPayload }): Promise<Asset> => {
  return api({
    method: 'patch',
    url: `/api/v1/assets/${id}`,
    data,
    params: {
      join: getAssetsJoinQueryParams,
      sort: getAssetsSortQueryParams,
    },
  })
}
