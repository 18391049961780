import { cloneElement, FC, FocusEvent, forwardRef, ReactElement, ReactNode, useCallback, useState } from 'react'
import isEqual from 'react-fast-compare'

import { useFormContext } from 'hooks/useForm'
import { typedMemo } from 'types'

import type { EditSelectMultiProps } from 'designSystem'
import { EditSelectMulti, EditView, SelectSingleOption } from 'designSystem'

import * as Styled from './styles'

export interface FormItemEditSelectMultiProps extends Omit<EditSelectMultiProps, 'name'> {
  name: string
  href?: string
  customPreview?: ReactNode
  isFieldViewOnly?: boolean
  popover?: ReactElement
  onConfirm?: () => void
}

const FormItemEditSelectMultiBase: FC<FormItemEditSelectMultiProps> = forwardRef(
  (
    {
      disabled,
      href,
      size = 'middle',
      mode = 'multiple',
      width = 160,
      onBlur,
      onSearch,
      name,
      value,
      onChange,
      onClear,
      customPreview,
      placement = 'bottomRight',
      isFieldViewOnly,
      defaultOptions,
      popover,
      onConfirm,
      ...props
    },
    ref,
  ) => {
    const [isEdit, setIsEdit] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState(defaultOptions)
    const [openPopover, setOpenPopover] = useState(false)
    const {
      getFieldState,
      formState: { defaultValues },
    } = useFormContext()

    const { error } = getFieldState(name)
    const defaultValue = defaultValues?.[name]

    const handleChange = useCallback(
      (currentValue?: (string | number)[], options?: SelectSingleOption[]) => {
        setSelectedOptions(options)
        onChange?.(currentValue)
      },
      [onChange],
    )

    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        setIsEdit(false)

        if (popover) {
          if (!isEqual(value, defaultValue)) {
            setTimeout(() => {
              setOpenPopover(true)
            }, 100)
          }
        } else {
          onBlur?.(event)
        }

        if (error) {
          onChange?.(defaultValue)
        }
      },
      [onBlur, onChange, defaultValue, error, popover, value],
    )

    const handleViewClick = useCallback(() => {
      setIsEdit(true)
    }, [setIsEdit])

    const valuesFromOptions =
      onSearch && value
        ? selectedOptions
        : props.options
            ?.filter((option) => value?.includes(option.value as string | number))
            .map(({ label }) => label as string)

    const handleClosePopover = useCallback(() => {
      setOpenPopover(false)
      popover?.props?.onCancel?.()
      const selectedValues = selectedOptions?.map(({ value }) => value)
      if (!isEqual(defaultValue, selectedValues)) {
        const prevOptions = props.options?.filter((option) => defaultValue?.includes(option.value as string | number))
        setSelectedOptions(prevOptions as SelectSingleOption[])
        onChange?.(defaultValue)
      }
    }, [popover?.props, onChange, selectedOptions, defaultValue, props.options])

    const onOpenChange = useCallback(
      (isOpen: boolean) => {
        setOpenPopover(isOpen)
        if (!isOpen) {
          handleClosePopover()
        }
      },
      [handleClosePopover],
    )

    const onPopoverConfirm = useCallback(() => {
      if (error) return

      const optionsValues = selectedOptions?.map(({ value }) => value)
      onChange?.(optionsValues)
      onConfirm?.()
      setOpenPopover(false)
    }, [error, onChange, selectedOptions, onConfirm])

    return (
      <Styled.Wrapper $isLinkAndViewOnly={isFieldViewOnly && !!href && !!value?.length}>
        {isEdit ? (
          <EditSelectMulti
            {...props}
            defaultOptions={defaultOptions}
            width={width}
            onClear={onClear}
            onSearch={onSearch}
            mode={mode}
            size={size}
            onChange={handleChange}
            popupMatchSelectWidth
            name={name}
            placement={placement}
            value={onSearch ? selectedOptions?.map(({ value }) => value) : value}
            error={error?.message}
            autoFocus
            onBlur={handleBlur}
            ref={ref}
            disabled={disabled}
          />
        ) : (
          <>
            {customPreview ? (
              <Styled.CustomPreview
                disabled={disabled}
                onClick={() => {
                  !disabled && handleViewClick()
                }}
              >
                {customPreview}
              </Styled.CustomPreview>
            ) : (
              <EditView
                align="end"
                as={href ? 'select-link' : 'select'}
                withEdit={!!href && !isFieldViewOnly}
                href={href}
                size={size}
                disabled={isFieldViewOnly ? false : disabled}
                value={valuesFromOptions}
                onClick={handleViewClick}
              />
            )}
          </>
        )}
        {popover &&
          cloneElement(popover, {
            ...popover.props,
            open: openPopover || popover.props.open,
            onCancel: handleClosePopover,
            disabled: !!error,
            onOk: onPopoverConfirm,
            onOpenChange,
          })}
      </Styled.Wrapper>
    )
  },
)

export const FormItemEditSelectMulti = typedMemo(FormItemEditSelectMultiBase)
