import { SalesOrderAccountingStatus } from 'api'
import { salesOrderAccountingStatusOptionsLabel } from 'constant'

import { TagType } from 'designSystem'

interface SalesOrderAccountingStatusTagData {
  status: TagType
  text?: string
}

export const getTagDataBySalesOrderAccountingStatus = (
  status: SalesOrderAccountingStatus,
): SalesOrderAccountingStatusTagData => {
  const text = salesOrderAccountingStatusOptionsLabel[status]

  const tagStatus = {
    // green
    [SalesOrderAccountingStatus.ApprovedPo]: TagType.Success,
    [SalesOrderAccountingStatus.ApprovedPaid]: TagType.Success,
    [SalesOrderAccountingStatus.ApprovedDepositPaid]: TagType.Success,
    [SalesOrderAccountingStatus.ApprovedCod]: TagType.Success,
    // yellow
    [SalesOrderAccountingStatus.PendingVerification]: TagType.Warning,
    [SalesOrderAccountingStatus.Pending]: TagType.Warning,
    [SalesOrderAccountingStatus.HoldDelivery]: TagType.Warning,
    [SalesOrderAccountingStatus.ContactAccountingForBalance]: TagType.Warning,
    [SalesOrderAccountingStatus.ApprovedProcurement]: TagType.Warning,
    // red
    [SalesOrderAccountingStatus.BeforeLaunching]: TagType.Error,
    [SalesOrderAccountingStatus.OnHoldWaitingForCheck]: TagType.Error,
    [SalesOrderAccountingStatus.OnHoldCreditCardDeclined]: TagType.Error,
    [SalesOrderAccountingStatus.Cancelled]: TagType.Error,
  }[status]

  return { status: tagStatus, text }
}
