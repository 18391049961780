import { styled } from 'styled-components'

import { Button as BaseButton } from 'designSystem'

import type { SingleCategoryProps } from './SingleCategory'

export const Wrapper = styled.div<{
  isDragging: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: ${({ isDragging }) => (isDragging ? 0.5 : 1)};
  max-width: 100%;
`

export const Content = styled.div<Pick<SingleCategoryProps, 'draggable'>>`
  padding: 7px 0 7px 7px;
  border-bottom: ${({ theme, draggable }) => (draggable ? 'none' : `1px solid ${theme.colors.lines}`)};
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Applied = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacers[2]};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.success[400]};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  height: ${({ theme }) => theme.spacers[9]};
  padding: 0 ${({ theme }) => theme.spacers[2]};
  font-weight: ${({ theme }) => theme.fontWeight.l};
  user-select: none;
`
export const Button = styled(BaseButton)`
  &&& .ant-btn-icon {
    margin-inline-end: 0;
  }
`
