import { FC } from 'react'
import { Col, Row } from 'antd'

import { referenceLabels } from 'constant'
import { ReferenceFormAccessor } from 'types'

import { FormItem, TextArea } from 'designSystem'

export const BookingNumberDetails: FC = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <FormItem name={ReferenceFormAccessor.Notes} label={referenceLabels.notes}>
            <TextArea size="small" placeholder="Type notes" autoSize={{ minRows: 4 }} />
          </FormItem>
        </Col>
      </Row>
    </>
  )
}
