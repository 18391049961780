import type { ReferenceNumberItem } from 'api'

import { itemLabels } from '../item'
import { referenceItemSubLineLabels } from './referenceItemSubLineLabels'

interface ReferenceItemLabels extends Record<keyof Omit<ReferenceNumberItem, 'subLines' | 'item'>, string> {
  subLines: typeof referenceItemSubLineLabels
  item: typeof itemLabels
}

export const referenceItemLabels: ReferenceItemLabels = {
  id: '',
  itemId: 'SKU',
  item: itemLabels,
  qty: 'Qty',
  subLines: referenceItemSubLineLabels,
}
