import { api, SalesOrder } from 'api'

import { salesOrderJoinParams } from 'modules/salesOrders/constants/salesOrderJoinParams'
import { SalesOrderCreatePayload } from './types/salesOrderCreateForm'

export const postSalesOrder = (data: SalesOrderCreatePayload): Promise<SalesOrder> => {
  return api({
    method: 'post',
    url: '/api/v1/sales-orders',
    data,
    params: {
      join: salesOrderJoinParams,
    },
  })
}
