import { CustomerState, getCustomersStates } from 'api'
import { QueryKey } from 'enums'
import { AsyncSelectGetItemsProps } from 'types'

import type { SelectSingleOption } from 'designSystem'

import { useAsyncList } from './useAsyncList'

export const useCustomersStates = (props: AsyncSelectGetItemsProps) => {
  const { list, ...asyncListProps } = useAsyncList<CustomerState>({
    ...props,
    queryFn: getCustomersStates,
    queryKey: QueryKey.GetCustomersStatesList,
    searchFieldName: 'state',
    limit: 10,
  })

  const options = list.map(({ state }) => ({
    value: state,
    label: state,
  })) satisfies SelectSingleOption[]

  return {
    options,
    ...asyncListProps,
  }
}
