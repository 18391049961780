import { keyframes, styled } from 'styled-components'

const striped = keyframes` 
    0% {
        background-position: 0 100%;
    }
    100% {
        background-position: 40px 100%;
    }
`

export const Strip = styled.span<{ $noBorderRadius?: boolean }>`
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: ${({ theme }) => theme.zIndexLevel[2]};
  height: 10px;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.small};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.small};
  border-radius: ${({ $noBorderRadius }) => $noBorderRadius && 0};
  background-size: 100%;
  background: ${({ theme }) => theme.colors.accent[400]}
    linear-gradient(to bottom, ${({ theme }) => theme.colors.accent[400]}, ${({ theme }) => theme.colors.accent[300]});

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background-image: linear-gradient(
      -45deg,
      #ffffff 25%,
      rgba(0, 0, 0, 0) 25%,
      rgba(0, 0, 0, 0) 50%,
      #ffffff 50%,
      #ffffff 75%,
      rgba(0, 0, 0, 0) 75%,
      rgba(0, 0, 0, 0)
    );
    background-size: 20px 20px;
    opacity: 0.2;
    animation: ${striped} 0.5s infinite linear;
  }
`
