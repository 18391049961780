import { getUsers, User } from 'api'
import { QueryKey } from 'enums'
import { AsyncSelectGetItemsProps } from 'types'

import type { SelectSingleOption } from 'designSystem'

import { useAsyncList } from './useAsyncList'

export const useUsers = ({
  valuePropName, // keyof User - the value of it should be of type string | number
  withFullDataOption,
  fields,
  ...props
}: AsyncSelectGetItemsProps) => {
  const { list, ...asyncListProps } = useAsyncList<User>({
    ...props,
    queryFn: getUsers,
    queryKey: QueryKey.GetUsersList,
    searchFieldName: ['firstName', 'lastName'],
    limit: 10,
    fields: fields || 'id,firstName,lastName',
  })

  const options = list.map((user) => ({
    value: valuePropName ? (user[valuePropName as keyof User] as string | number) : user.id,
    label: `${user.firstName} ${user.lastName}`,
    ...(withFullDataOption && { data: user }),
  })) satisfies SelectSingleOption[]

  return {
    options,
    ...asyncListProps,
  }
}
