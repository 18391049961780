import { Accessor } from '../types/accessor'

export const getAccessorTitle = (accessor: Accessor): string => {
  return {
    [Accessor.Index]: '#',
    [Accessor.CompanyName]: 'Company Name',
    [Accessor.CreditLimit]: 'Current Credit Limit',
    [Accessor.Status]: 'Status',
    [Accessor.Notes]: 'Notes',
    [Accessor.BillingAddresses]: 'Billing Address',
  }[accessor]
}
