import { PurchaseOrderItemsSubLine } from 'api'

import { DeleteSubLinesPayload } from '../../../routes/PurchaseOrder/elements/PurchaseOrderTabs/elements/PurchaseOrderDetails/elements/PurchaseOrderDetailsItems/types/DeleteSubLinesPayload'

export const prepareDeleteSubLinesPayload = (
  selectedRows: string[],
  subLines: PurchaseOrderItemsSubLine[] | undefined,
): DeleteSubLinesPayload => {
  const filteredSubLines = subLines?.reduce<{ id: number }[]>((acc, item) => {
    if (selectedRows.includes(item.id.toString())) {
      return acc
    } else {
      acc.push({ id: item.id })
    }
    return acc
  }, [])
  return {
    subLines: filteredSubLines || [],
  }
}
