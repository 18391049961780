import { css } from 'styled-components'

export const DatePickerStyles = css<{ width?: number; error?: string }>`
  &.ant-picker {
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    border: ${({ error, theme }) => !!error && `1px solid ${theme.colors.error[400]}`};
    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.colors.accent[400]};
      box-shadow: ${({ theme }) => theme.boxShadow.input};
    }
  }
  .ant-picker-input input {
    font-weight: ${({ theme }) => theme.fontWeight.l};
    font-size: ${({ theme }) => theme.fontSize.xs};
    color: ${({ theme }) => theme.colors.primary};
  }
`
