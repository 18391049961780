import { Link } from 'react-router-dom'

import { VendorType } from 'api'
import { route, vendorViewRoute } from 'constant'

export const getSidebarItems = (id: string, type?: VendorType[]) => {
  const vendorRoute = `${route.vendors}/${id}`

  const isModificationsType = type?.includes(VendorType.Modifications)
  const isContainerVendorType = type?.includes(VendorType.ContainerVendor)
  const isCarrierType = type?.includes(VendorType.Carrier)
  const isDepotType = type?.includes(VendorType.Depot)
  const isPartsType = type?.includes(VendorType.Parts)

  return [
    {
      label: <Link to={`${vendorRoute}${vendorViewRoute.details}`}>Vendor Details</Link>,
      key: `${vendorRoute}${vendorViewRoute.details}`,
    },
    {
      label: <Link to={`${vendorRoute}${vendorViewRoute.po}`}>Purchase Orders</Link>,
      key: `${vendorRoute}${vendorViewRoute.po}`,
    },
    ...(isModificationsType
      ? [
          {
            label: <Link to={`${vendorRoute}${vendorViewRoute.modifications}`}>Modifications</Link>,
            key: `${vendorRoute}${vendorViewRoute.modifications}`,
          },
        ]
      : []),
    ...(isContainerVendorType
      ? [
          {
            label: <Link to={`${vendorRoute}${vendorViewRoute.containerVendor}`}>Container Vendor</Link>,
            key: `${vendorRoute}${vendorViewRoute.containerVendor}`,
          },
        ]
      : []),
    ...(isCarrierType
      ? [
          {
            label: <Link to={`${vendorRoute}${vendorViewRoute.carrier}`}>Carrier</Link>,
            key: `${vendorRoute}${vendorViewRoute.carrier}`,
          },
        ]
      : []),
    ...(isPartsType
      ? [
          {
            label: <Link to={`${vendorRoute}${vendorViewRoute.parts}`}>Parts</Link>,
            key: `${vendorRoute}${vendorViewRoute.parts}`,
          },
        ]
      : []),
    ...(isDepotType
      ? [
          {
            label: <Link to={`${vendorRoute}${vendorViewRoute.depot}`}>Depot</Link>,
            key: `${vendorRoute}${vendorViewRoute.depot}`,
          },
        ]
      : []),
    {
      label: <Link to={`${vendorRoute}${vendorViewRoute.integrations}`}>Integrations</Link>,
      key: `${vendorRoute}${vendorViewRoute.integrations}`,
    },
    //TODO temporary hide
    // ...(isModificationsType || isContainerVendorType
    //   ? [
    //       {
    //         label: <Link to={`${vendorRoute}${vendorViewRoute.dataMapping}`}>Data mapping</Link>,
    //         key: `${vendorRoute}${vendorViewRoute.dataMapping}`,
    //       },
    //     ]
    //   : []),
  ]
}
