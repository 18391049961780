import { FC } from 'react'

import { Contact } from 'api'

import { Container, Divider, Typography } from 'designSystem'
import { LabelValueMarkup } from 'components/LabelValueMarkup'

interface VendorDetailsCardContactsProps {
  data: Contact[]
}

export const VendorDetailsCardContacts: FC<VendorDetailsCardContactsProps> = ({ data }) => {
  if (!data?.length) {
    return null
  }

  return (
    <>
      <Container fd="column" gap={16}>
        {data.map(({ id, firstName, lastName, phoneNumber, phoneExtension, email, notes }, index) => (
          <Container fd="column" key={id} gap={8}>
            <Typography fontWeight="xl">Contact Person {index + 1}</Typography>
            <Container fd="column" gap={4}>
              <LabelValueMarkup label="Name" value={`${firstName} ${lastName}`} />
              <LabelValueMarkup
                label="Phone Number"
                value={
                  phoneExtension || phoneNumber ? (
                    <span>
                      <Typography size="s" color="secondary">
                        {phoneExtension}
                      </Typography>{' '}
                      {phoneNumber}
                    </span>
                  ) : (
                    '-'
                  )
                }
              />
              <LabelValueMarkup label="Email" value={email} />
              <LabelValueMarkup label="Notes" value={notes || '-'} />
            </Container>
          </Container>
        ))}
      </Container>
      <Divider my={4} />
    </>
  )
}
