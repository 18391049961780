import { purchaseOrderLabels } from 'constant'

import { Accessor } from '../types/accessor'

export const getAccessorTitle = (accessor: Accessor): string => {
  return {
    [Accessor.Index]: '#',
    [Accessor.Number]: purchaseOrderLabels.number,
    [Accessor.Type]: purchaseOrderLabels.type,
    [Accessor.LocationCode]: purchaseOrderLabels.locationCode,
    [Accessor.Depot]: purchaseOrderLabels.depot,
    [Accessor.ExpectedDate]: purchaseOrderLabels.expectedDate,
    [Accessor.CreatedAt]: 'PO Creation Date',
    [Accessor.ExpirationDate]: purchaseOrderLabels.expirationDate,
    [Accessor.Qty]: 'Qty.',
    [Accessor.ItemsTotal]: 'Items Total',
    [Accessor.Status]: purchaseOrderLabels.status,
    [Accessor.Notes]: purchaseOrderLabels.notes,
  }[accessor]
}
