import type { ApiQueryParams, EntityId, Vendor } from 'api'
import { Pagination } from 'types'

import { api } from '../api'

export interface GetVendorsResponse extends Pagination {
  data?: Vendor[]
}

export type GetVendorsQueryParams = ApiQueryParams

export const getVendorsJoinQueryParams = ['locationCodes']
export const getVendorsSortQueryParams = ['locationCodes.id,ASC']

export const getVendors = (params?: GetVendorsQueryParams): Promise<GetVendorsResponse> => {
  return api({
    method: 'get',
    url: '/api/v1/vendors/',
    params,
  })
}

export const getVendorById = (id?: EntityId, params?: GetVendorsQueryParams): Promise<Vendor> => {
  return api({
    method: 'get',
    url: `/api/v1/vendors/${id}`,
    params: {
      join: getVendorsJoinQueryParams,
      ...params,
      sort: [...(params?.sort || []), ...getVendorsSortQueryParams],
    },
  })
}
