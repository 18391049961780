import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { route } from 'constant'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { postAsset, PostAssetPayload } from '../queryApi'

export const useCreateAsset = () => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const navigate = useNavigate()

  const { mutate, ...rest } = useMutation({
    mutationFn: postAsset,
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.AssetCreate)

      queryClient.setQueryData([QueryKey.GetAssetById, data.id], data)
      showNotification({ title: 'Asset created', type: 'success' })
      navigate(`${route.assets}/${data.id}`)
    },
  })

  const create = useCallback(
    (payload: PostAssetPayload) => {
      mutate(payload)
    },
    [mutate],
  )

  return {
    create,
    ...rest,
  }
}
