import { ReferenceNumberType } from 'api/types/referenceNumber/referenceNumberType'

export const referenceNumberTypeOptions = [
  { value: ReferenceNumberType.Booking, label: 'Booking' },
  { value: ReferenceNumberType.Redelivery, label: 'Redelivery' },
  { value: ReferenceNumberType.VendorRelease, label: 'Vendor Release' },
  { value: ReferenceNumberType.Pickup, label: 'Pickup' },
]

export const getReferenceTypeOptionLabel = (type: ReferenceNumberType): string | undefined =>
  referenceNumberTypeOptions.find(({ value }) => value === type)?.label
