import { FC } from 'react'
import { DragOverlay as BaseDragOverlay, useDndContext } from '@dnd-kit/core'

import { ItemCategory } from 'api'

import { SingleCategory } from '../CategoryItem/elements/SingleCategory'
import * as Styled from './styles'

interface DragOverlayProps {
  item: ItemCategory | null
}

export const DragOverlay: FC<DragOverlayProps> = ({ item }) => {
  const dndContext = useDndContext()
  // add padding to the width of the item
  const itemWidth = dndContext?.active?.data?.current?.width + 16

  return (
    <BaseDragOverlay style={{ width: itemWidth || 450, cursor: 'grab' }}>
      {item && (
        <Styled.DraggableItem>
          <SingleCategory item={item} draggable />
        </Styled.DraggableItem>
      )}
    </BaseDragOverlay>
  )
}
