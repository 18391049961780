import { create } from 'zustand'

import { SendEmailAction } from 'types'

interface SendEmailActionsStore {
  actions: SendEmailAction[]
  add: (type: SendEmailAction[]) => void
  remove: (type: SendEmailAction) => void
}

const useSendEmailActionsStore = create<SendEmailActionsStore>((set) => ({
  actions: [],
  add: (actions: SendEmailAction[]) => set((prev) => ({ actions: [...prev.actions, ...actions] })),
  remove: (action: SendEmailAction) =>
    set((prev) => ({ actions: prev.actions.filter((activeAction) => activeAction !== action) })),
}))

export const useSendEmailActions = () => useSendEmailActionsStore((state) => state.actions)
export const useAddSendEmailActions = () => useSendEmailActionsStore((state) => state.add)
export const useRemoveSendEmailAction = () => useSendEmailActionsStore((state) => state.remove)
