import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { PurchaseOrderStatus } from 'api'
import { useMemoCompare } from 'hooks'
import { purchaseOrderLabels, purchaseOrderStatusOptions } from 'constant'
import { PurchaseOrderFormAccessor } from 'types'

import { EditFormItem, Icon } from 'designSystem'
import { FormItemEditSelectSingle, PurchaseOrderStatusTag } from 'components'

import { useGetPurchaseOrderItems } from '../../../PurchaseOrderDetailsItems/hooks/useGetPurchaseOrderItems'

interface POStatusProps {
  handleSubmitStatus: (value: string | number | null | undefined) => void
}

export const POStatus: FC<POStatusProps> = ({ handleSubmitStatus }) => {
  const [options, setOptions] = useState(purchaseOrderStatusOptions)
  const { hash } = useLocation()
  const hashRouteKey = Number(hash.split('/')[1])

  const { data: POItems } = useGetPurchaseOrderItems({
    purchaseOrderId: hashRouteKey,
  })

  const memorizedPOItems = useMemoCompare(POItems)
  const memorizedPurchaseOrderStatusOptions = useMemoCompare(purchaseOrderStatusOptions)

  useEffect(() => {
    const shouldDisableVoidedStatus = memorizedPOItems?.some((item) => item.billedQty > 0 || item.receivedQty > 0)
    if (shouldDisableVoidedStatus) {
      setOptions(
        purchaseOrderStatusOptions.map((option) => {
          if (option.value === PurchaseOrderStatus.Voided) {
            return {
              ...option,
              disabled: shouldDisableVoidedStatus,
            }
          }
          return option
        }),
      )
    } else {
      setOptions(memorizedPurchaseOrderStatusOptions)
    }
  }, [memorizedPOItems, setOptions, memorizedPurchaseOrderStatusOptions])

  return (
    <EditFormItem
      name={PurchaseOrderFormAccessor.Status}
      label={purchaseOrderLabels.status}
      render={({ field }) => (
        <FormItemEditSelectSingle
          {...field}
          customPreview={<PurchaseOrderStatusTag status={field.value} icon={<Icon icon="arrowDown" size={16} />} />}
          disableOnChange
          width={100}
          placeholder="Status"
          onSubmit={handleSubmitStatus}
          options={options}
        />
      )}
    />
  )
}
