import { Navigate } from 'react-router-dom'

import { logisticsRoot, logisticsRoute } from 'constant'

import { LinkTabs, NotFoundPage } from 'components'

import { logisticsTabs } from './logisticsTabs'

export const logisticsRoutes = [
  {
    path: '/',
    element: <Navigate to={`${logisticsRoot}${logisticsRoute.events}`} replace />,
  },
  {
    path: logisticsRoute.events,
    element: <LinkTabs items={logisticsTabs} />,
  },
  {
    path: logisticsRoute.planning,
    element: <LinkTabs items={logisticsTabs} />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
