export enum AppModule {
  Assets = 'assets',
  AssetBookingNumbers = 'asset_booking_numbers',
  AssetRedeliveryNumbers = 'asset_redelivery_numbers',
  AssetRepairs = 'asset_repairs',
  Customers = 'customers',
  GlobalInventoryMatrix = 'global_inventory_matrix',
  GlobalInventoryVendors = 'global_inventory_vendors',
  Items = 'items',
  PurchaseOrders = 'purchase_orders',
  PurchaseOrderItems = 'purchase_order_items',
  PurchaseOrderRedeliverySubLines = 'purchase_order_redelivery_sub_lines',
  PurchaseOrderVendorReleaseSubLines = 'purchase_order_vendor_release_sub_lines',
  References = 'references',
  Vendors = 'vendors',
  VendorPurchaseOrders = 'vendor_purchase_orders',
  VendorModifications = 'vendor_modifications',
  VendorContainerInventory = 'vendor_container_supplier_inventory',
  VendorPartsInventory = 'vendor_parts_inventory',
  VendorCarrierEquipment = 'vendor_carrier_equipment',
  AssetCodes = 'asset_codes',
  AccessManagement = 'access_management',
  SalesOrders = 'sales_orders',
}
