import { Navigate } from 'react-router-dom'

import { locationCodesRoute } from 'constant'

import { NotFoundPage } from 'components'

import { LocationCode } from '../routes/LocationCode'
import { LocationCodesCreate } from '../routes/LocationCodesCreate'
import { LocationCodesList } from '../routes/LocationCodesList'

export const locationCodesRoutes = [
  {
    path: locationCodesRoute.create,
    element: <LocationCodesCreate />,
  },
  {
    path: locationCodesRoute.view,
    element: <Navigate to={locationCodesRoute.details.replace('/', '')} replace />,
  },
  {
    path: `${locationCodesRoute.view}/*`,
    element: <LocationCode />,
  },
  {
    path: locationCodesRoute.list,
    element: <LocationCodesList />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
