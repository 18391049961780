import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { Tooltip } from 'antd'

import { EntityId } from 'api'
import theme from 'styles/theme'

import { Button } from 'designSystem'
import { useResetPOAssetNumber } from 'modules/purchaseOrders/hooks/useResetPOAssetNumber'

interface ResetAssetNumberButtonProps {
  subLineId: EntityId
  itemId?: EntityId
  isDisabled: boolean
}

export const ResetAssetNumberButton: FC<ResetAssetNumberButtonProps> = ({ subLineId, itemId, isDisabled }) => {
  const { hash } = useLocation()
  const purchaseOrderId = Number(hash.split('/')[1])

  const { patchPOAssetNumber, isPending } = useResetPOAssetNumber({ purchaseOrderId, itemId, subLineId })

  return (
    <Tooltip title="Reset Asset Number" color={theme.colors.primary} arrow={false}>
      <Button icon="repeat" extraSmall onClick={patchPOAssetNumber} loading={isPending} disabled={isDisabled} />
    </Tooltip>
  )
}
