import { FC, useEffect } from 'react'

import { useFormContext } from 'hooks'
import { typeOfEquipmentOptions } from 'constant'

import { CheckboxGroup, FormItem, Spacer, Typography } from 'designSystem'
import { Card } from 'components'
interface ContainerVendorSettingsProps {
  isEdit?: boolean
}
export const ContainerVendorSettings: FC<ContainerVendorSettingsProps> = ({ isEdit }) => {
  const { unregister } = useFormContext()

  useEffect(() => {
    return () => {
      unregister('containerSupplierSettings')
    }
  }, [unregister])

  return (
    <Card bordered={!isEdit} pa={isEdit ? 0 : undefined}>
      {!isEdit && (
        <>
          <Typography size="l">
            <Typography size="l" fontWeight="xl">
              Container Vendor
            </Typography>{' '}
            Settings
          </Typography>
          <Spacer mb={4} />
        </>
      )}
      <Typography size="s" fontWeight="xl">
        Type Of Equipment
      </Typography>
      <Spacer mb={3} />
      <FormItem name="containerSupplierSettings.typeOfEquipment">
        <CheckboxGroup options={typeOfEquipmentOptions} />
      </FormItem>
    </Card>
  )
}
