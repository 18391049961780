import { api, Customer, EntityId } from 'api'

import { CustomerDetailsPayload } from './types/customerDetailsPayload'

export const getCustomerById = (id?: EntityId): Promise<Customer> => {
  return api({
    method: 'get',
    url: `/api/v1/customers/${id}`,
    params: { join: ['billingAddresses'] },
  })
}

interface PatchCustomerParams {
  id: EntityId
  data: CustomerDetailsPayload
}

export const patchCustomer = ({ id, data }: PatchCustomerParams): Promise<Customer> => {
  return api({
    method: 'patch',
    url: `/api/v1/customers/${id}`,
    data,
    params: { join: ['billingAddresses'] },
  })
}
