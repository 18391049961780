import type { Item } from 'api'

export const itemLabels: Record<keyof Item, string> = {
  id: '',
  zohoId: 'Zoho ID',
  name: 'Item Name',
  description: 'Description',
  sku: 'SKU',
  type: 'Type',
  categoryId: 'Categories',
  price: 'Selling Price',
  groupedSKUs: 'Grouped SKU',
  category: '',
  weight: 'Weight',
  width: 'Width',
  length: 'Length',
  grade: 'Grade',
  additionalAttributes: 'Additional Attributes',
  height: 'Height',
  assetType: 'Asset Type',
  createdAt: 'Created Date',
  createdBy: 'Created By',
}
