import { salesOrderLabels } from 'constant'
import { SalesOrderLineItemsAccessor } from 'types'

export const getAccessorLineItemTitle = (accessor: SalesOrderLineItemsAccessor): string => {
  return {
    [SalesOrderLineItemsAccessor.Sku]: salesOrderLabels.lineItems.itemId,
    [SalesOrderLineItemsAccessor.ItemName]: salesOrderLabels.lineItems.title,
    [SalesOrderLineItemsAccessor.Modification]: '',
    [SalesOrderLineItemsAccessor.Qty]: salesOrderLabels.lineItems.qty,
    [SalesOrderLineItemsAccessor.Price]: salesOrderLabels.lineItems.price,
    [SalesOrderLineItemsAccessor.Discount]: salesOrderLabels.lineItems.discount,
    [SalesOrderLineItemsAccessor.Taxes]: salesOrderLabels.lineItems.taxes,
    [SalesOrderLineItemsAccessor.SubTotal]: salesOrderLabels.lineItems.subtotal,
    [SalesOrderLineItemsAccessor.Total]: salesOrderLabels.lineItems.total,
  }[accessor]
}
