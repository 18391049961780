import { api, Company, EntityId, Warehouse } from 'api'

import { PostWarehousePayload, UpdateWarehousePayload } from './types/warehouses'

export type UpdateMyCompanyPayload = Partial<Company>

export const getMyCompany = (): Promise<Company> => {
  return api({
    method: 'get',
    url: '/api/v1/companies/my',
  })
}

export const patchMyCompany = (data: UpdateMyCompanyPayload): Promise<Company> => {
  return api({
    method: 'patch',
    url: '/api/v1/companies/my',
    data,
  })
}

export const postWarehouse = (data: PostWarehousePayload): Promise<Warehouse> => {
  return api({
    method: 'post',
    url: '/api/v1/warehouses',
    data,
  })
}

export const patchWarehouse = ({ id, data }: UpdateWarehousePayload): Promise<Warehouse> => {
  return api({
    method: 'patch',
    url: `/api/v1/warehouses/${id}`,
    data,
  })
}

export const deleteWarehouse = (id: EntityId): Promise<void> => {
  return api({
    method: 'delete',
    url: `/api/v1/warehouses/${id}`,
  })
}
