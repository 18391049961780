// TODO: Reuse accessors for reference when will be moved to global level
export enum AssetBookingReferenceAccessor {
  Index = 'index',
  CreatedAt = 'createdAt',
  Number = 'number',
  Type = 'type',
  TransactionType = 'transactionType',
  RelatedReference = 'relatedReference',
  GateOutDate = 'gateOutDate',
  Notes = 'notes',
  Status = 'status',
}
