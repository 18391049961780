import { FC } from 'react'
import { Route, Routes, useLocation, useParams } from 'react-router-dom'
import { Layout } from 'antd'

import { assetsRoute, route } from 'constant'

import { Breadcrumbs, Sidebar, Spinner } from 'components'

import { useGetAssetById } from './routes/AssetDetails/hooks/useGetAssetById'
import { getAssetRoutes } from './utils/getAssetRoutes'
import { getAssetsSidebarItems } from './utils/getAssetsSidebarItems'
import { breadcrumbsData } from './constants/breadcrumbsData'
import { routesWithoutSidebar } from './constants/routesWithoutSidebar'
import { ActionButtons } from './elements/ActionButtons'

export const Asset: FC = () => {
  const { id } = useParams()
  const location = useLocation()

  const { data, isLoading } = useGetAssetById(Number(id))

  const assetsSidebarItems = getAssetsSidebarItems(id as string)
  const sidebarItems = routesWithoutSidebar.includes(location.pathname) ? [] : assetsSidebarItems

  const showActions = location.pathname === `${route.assets}/${id}${assetsRoute.details}`

  return (
    <Layout>
      <Sidebar sidebarItems={sidebarItems} />
      <Layout.Content>
        <Breadcrumbs breadcrumbItems={breadcrumbsData} actions={showActions && <ActionButtons />} />
        {isLoading ? (
          <Spinner />
        ) : (
          <Routes>
            {getAssetRoutes(data).map((assetRoute) => (
              <Route key={assetRoute.path} path={assetRoute.path} element={assetRoute.element} />
            ))}
          </Routes>
        )}
      </Layout.Content>
    </Layout>
  )
}
