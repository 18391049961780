import { FC } from 'react'

import { TruckCode } from 'api'
import { route } from 'constant'

import { Table, TableTitle } from 'components'

import { useTruckCodes } from './hooks/useTruckCodes'
import { getTableColumns } from './utils/getTableColumns'
import { ListSearch } from './elements/ListSearch'

export const TruckCodesList: FC = () => {
  const { data, isLoading, pagination, onTableChange, getFieldSorter, offsetCount, TableContextProvider } =
    useTruckCodes()
  const columns = getTableColumns(getFieldSorter, offsetCount)

  const truckCodesList: TruckCode[] = data.map(({ id, ...rest }) => ({
    key: id.toString(),
    id,
    ...rest,
  }))

  return (
    <TableContextProvider>
      <TableTitle
        title="Truck Codes"
        total={pagination?.total}
        addRoute={route.truckCodesCreate}
        search={<ListSearch />}
      />
      <Table<TruckCode>
        withBorderRadius={false}
        dataSource={truckCodesList}
        loading={isLoading}
        columns={columns}
        pagination={pagination}
        onChange={onTableChange}
        scroll={{ x: 1000 }}
      />
    </TableContextProvider>
  )
}
