import { FC } from 'react'
import { Link } from 'react-router-dom'

import { route } from 'constant'
import { typedMemo } from 'types'

import { Button, Container, Icon, Modal, Typography } from 'designSystem'

import { VendorDetailsCard } from './elements/VendorDetailsCard'

interface VendorDetailsModalProps {
  id?: number
  open: boolean
  onCancel: () => void
}

export const VendorDetailsModalBase: FC<VendorDetailsModalProps> = ({ id, open, onCancel }) => {
  return (
    <Modal
      centered
      width={440}
      open={open}
      footer={null}
      onCancel={onCancel}
      title={
        <Container ai="center" gap={16}>
          <Typography size="l" fontWeight="xl">
            Vendor Details
          </Typography>
          <Link to={`${route.vendors}/${id}`}>
            <Button size="small" px={4}>
              Open Vendor Page <Icon icon="open" size={16} />
            </Button>
          </Link>
        </Container>
      }
    >
      {id && <VendorDetailsCard id={id} />}
    </Modal>
  )
}

export const VendorDetailsModal = typedMemo(VendorDetailsModalBase)
