import { css, styled } from 'styled-components'

import { SpacerLevel } from '../Spacer'

interface DividerProps {
  vertical?: boolean
  my?: SpacerLevel
  mx?: SpacerLevel
  mt?: SpacerLevel
  mb?: SpacerLevel
}

export const Divider = styled.div<DividerProps>`
  background-color: ${({ theme }) => theme.colors.lines};
  margin-top: ${({ mt, theme }) => mt && theme.spacers[mt]};
  margin-bottom: ${({ mb, theme }) => mb && theme.spacers[mb]};
  margin: ${({ my, mx, theme }) => {
    if (my) {
      return `${theme.spacers[my]} 0`
    }
    if (mx) {
      return `0 ${theme.spacers[mx]}`
    }
  }};

  ${({ vertical }) =>
    vertical
      ? css`
          width: 1px;
        `
      : css`
          height: 1px;
        `}
`
