import { FC } from 'react'

import type { ReferenceNumberAssetStatus } from 'api'

import { Tag, TagProps } from 'designSystem'

import { getTagDataByReferenceNumberAssetStatus } from './utils/getTagDataByReferenceNumberAssetStatus'

interface ReferenceNumberAssetStatusTagProps extends Omit<TagProps, 'status'> {
  status: ReferenceNumberAssetStatus
}

export const ReferenceNumberAssetStatusTag: FC<ReferenceNumberAssetStatusTagProps> = ({
  status: assetStatus,
  ...props
}) => {
  const { status, text } = getTagDataByReferenceNumberAssetStatus(assetStatus)
  return <Tag {...props} status={status} text={text} />
}
