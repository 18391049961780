import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { EntityId } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { deleteCustomerUccAddress, GetCustomerUccAddressesResponse } from '../queryApi'

export const useRemoveCustomerUccAddress = (customerId: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation({
    mutationFn: deleteCustomerUccAddress,
    onSuccess: (data, payload) => {
      dispatchAppEvent(AppEvent.CustomerUccAddressDelete)
      queryClient.setQueryData(
        [QueryKey.GetCustomerUccAddresses, customerId],
        (old: GetCustomerUccAddressesResponse | undefined) =>
          ({
            ...old,
            data: old?.data.filter((address) => address.id !== payload.id),
          }) as GetCustomerUccAddressesResponse,
      )
      showNotification({ title: 'UCC Address deleted', type: 'success' })
    },
  })

  const remove = useCallback(
    (id: number) => {
      mutate({ customerId, id })
    },
    [mutate, customerId],
  )

  return {
    remove,
    ...rest,
  }
}
