import type { MarginSetting } from 'api'

export const getResaleMarginPayload = (resaleMargin: MarginSetting | null | undefined) => {
  if (!resaleMargin) {
    return undefined
  }
  if (resaleMargin?.operand) {
    return { resaleMargin }
  }
  return { resaleMargin: null }
}
