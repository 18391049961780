import { Layout as BaseLayout, Menu as BaseMenu } from 'antd'
import { styled } from 'styled-components'

export const Sidebar = styled(BaseLayout.Sider)`
  padding: ${({ theme }) => `${theme.spacers[3]}`};
  border-right: 1px solid ${({ theme }) => theme.colors.lines};
  &.ant-layout-sider {
    background: ${({ theme }) => theme.colors.white};
  }
`

export const Menu = styled(BaseMenu)<any>`
  &&.ant-menu {
    border-inline-end: unset;
    & .ant-menu-item-selected {
      background: ${({ theme }) => theme.colors.accent[80]};
      .ant-menu-title-content {
        color: ${({ theme }) => theme.colors.accent[400]};
      }
    }
    &&& .ant-menu-item {
      width: 100%;
      height: auto;
      padding: ${({ theme }) => `${theme.spacers[3]} 12px`};
      font-weight: ${({ theme }) => theme.fontWeight.l};
      font-size: ${({ theme }) => theme.fontSize.s};
      line-height: ${({ theme }) => theme.lineHeight.m};
      color: ${({ theme }) => theme.colors.primary};
      margin-inline: 0;

      .ant-menu-title-content {
        white-space: normal;
      }
    }
  }
`
