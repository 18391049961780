import { Dispatch, FC, SetStateAction, useCallback, useEffect } from 'react'

import { useFieldArray, useFormContext } from 'hooks'
import { SalesOrderFormAccessor } from 'types'

import { Table } from 'components'

import { getExpandableColumns } from '../../utils/getExpandableColumns'
import { LineItemModifications, LineItemSku, SalesOrderSubLineRow } from '../../types'

interface SalesOrderSubLinesProps {
  index: number
  setLineItemSku: Dispatch<SetStateAction<LineItemSku>>
  lineItemSku: LineItemSku
  setLineItemModifications: Dispatch<SetStateAction<LineItemModifications>>
  recordId: string
  hasSubLines: boolean
}

export const SalesOrderSubLines: FC<SalesOrderSubLinesProps> = ({
  setLineItemSku,
  index,
  lineItemSku,
  setLineItemModifications,
  recordId,
  hasSubLines,
}) => {
  const rowSubLinesFieldName = `${SalesOrderFormAccessor.LineItems}.${index}.${SalesOrderFormAccessor.SubLines}`
  const { watch } = useFormContext()
  const { fields, remove, append } = useFieldArray({ name: rowSubLinesFieldName, shouldUnregister: true })
  const dataSource = fields?.map((field) => ({ ...field, key: field.id })) as SalesOrderSubLineRow[]
  const itemsCount = dataSource.length

  const handleRemove = useCallback(
    (index: number) => {
      remove(index)
      if (itemsCount === 1) {
        setLineItemModifications((prev) => ({ ...prev, [recordId]: false }))
      }
    },
    [remove, setLineItemModifications, recordId, itemsCount],
  )

  const columns = getExpandableColumns({
    setLineItemSku,
    lineItemSku,
    watch,
    onDelete: handleRemove,
    rowSubLinesFieldName,
  })

  const insertRow = useCallback(() => {
    append({}, { shouldFocus: false })
  }, [append])

  const allowToAddNewRow = itemsCount > 0

  useEffect(() => {
    if (!itemsCount && hasSubLines) {
      insertRow()
    }
  }, [hasSubLines, insertRow, itemsCount])

  if (!itemsCount) {
    return null
  }

  return (
    <Table<SalesOrderSubLineRow>
      scroll={{ x: 800, ...(dataSource.length > 10 && { y: 390 }) }}
      dataSource={dataSource as SalesOrderSubLineRow[]}
      columns={columns as SalesOrderSubLineRow[]}
      onAddNewRow={allowToAddNewRow ? insertRow : undefined}
    />
  )
}
