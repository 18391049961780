import { css, styled } from 'styled-components'

import type { EditViewProps } from './EditView'

interface ItemsWrapperProps {
  layout: 'vertical' | 'horizontal'
  align?: EditViewProps['align']
  isDirectionColumn?: boolean
}

export const ItemsWrapper = styled.div<ItemsWrapperProps>`
  text-align: ${({ layout, align }) => (layout === 'vertical' || align === 'left' ? 'left' : 'right')};
  align-items: ${({ layout, align }) => (layout === 'vertical' || align === 'left' ? 'flex-start' : 'flex-end')};
  flex-direction: ${({ isDirectionColumn }) => isDirectionColumn && 'column'};
  display: flex;
  min-width: 0;
`

export const SuffixWrapper = styled.div`
  display: none;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacers[1]};
`

export const ClearWrapper = styled.div`
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  right: -${({ theme }) => theme.spacers[4]};
`

export const SuffixOutsideWrapper = styled(SuffixWrapper)`
  margin-left: ${({ theme }) => theme.spacers[2]};

  > * {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};

    &:hover {
      background-color: ${({ theme }) => theme.colors.accent[80]};
      color: ${({ theme }) => theme.colors.accent[400]};
    }
  }
`

interface WrapperProps extends Pick<EditViewProps, 'error' | 'disabled' | 'size'> {
  $isSelectLink?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  min-width: 0;
  width: fit-content;
  position: relative;

  ${({ disabled, error, $isSelectLink, theme }) => css`
    ${ContentWrapper} {
      background-color: ${error ? theme.colors.error[90] : disabled ? theme.colors.lines : undefined};
    }

    &:hover {
      cursor: ${disabled ? 'not-allowed' : 'pointer'};

      ${SuffixWrapper},
      ${ClearWrapper} {
        display: flex;
      }

      ${ContentWrapper} {
        background-color: ${disabled ? theme.colors.lines : !$isSelectLink ? theme.colors.accent[80] : undefined};
      }
    }
  `}

  ${({ size }) => {
    if (size === 'small') {
      return css`
        font-size: ${({ theme }) => theme.fontSize.xs};
        min-height: ${({ theme }) => theme.inlineEdit.size.s};
        line-height: 20px;
      `
    } else if (size === 'large') {
      return css`
        font-size: ${({ theme }) => theme.fontSize.l};
        min-height: ${({ theme }) => theme.inlineEdit.size.l};
      `
    }

    return css`
      font-size: ${({ theme }) => theme.fontSize.s};
      min-height: ${({ theme }) => theme.inlineEdit.size.m};
    `
  }}
    &:hover {
    ${SuffixOutsideWrapper},${ClearWrapper} {
      display: flex;
    }
  }
`

export const ContentWrapper = styled.div<{ $isSelectLink: boolean }>`
  display: flex;
  min-width: 0;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.l};
  padding: ${({ theme }) => `0 ${theme.spacers[2]}`};

  ${({ $isSelectLink }) =>
    $isSelectLink &&
    css`
      padding: 0;
      margin: ${({ theme }) => `0 ${theme.spacers[2]}`};
    `}

  border-radius: ${({ theme }) => theme.borderRadius.small};
`
