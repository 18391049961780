import { FC } from 'react'

import { ActivityStreamlineType } from 'api'
import { QueryKey } from 'enums'

import { ActivityStreamLineTable } from 'components'

interface ReferenceHistoryProps {
  id: number
}

export const ReferenceHistory: FC<ReferenceHistoryProps> = ({ id }) => {
  const filters = {
    $and: [
      {
        $or: [
          {
            topLevelEntityId: { $eq: id },
          },
        ],
      },
      { topLevelType: { $eq: ActivityStreamlineType.ReferenceEntity } },
    ],
  }

  return (
    <ActivityStreamLineTable
      queryKey={QueryKey.GetReferenceNumberActivityStreamline}
      filters={JSON.stringify(filters)}
    />
  )
}
