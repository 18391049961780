import { FC, useCallback, useEffect, useState } from 'react'

import { QueryCondition } from 'api'
import { TableFilterRecordValue, useTableContext } from 'hooks'
import { currency } from 'constant'
import { getRangeFilter } from 'utils'

import { Container, InputNumber, InputNumberProps, InputNumberValue } from 'designSystem'

interface TableFilterRangeProps extends Omit<InputNumberProps, 'name'> {
  name: string
}

export const TableFilterRange: FC<TableFilterRangeProps> = ({
  name,
  width,
  placeholder = '00.00',
  precision = 2,
  ...inputProps
}) => {
  const { updateFilters, filters } = useTableContext()
  const { value, condition } = (filters?.[name] as TableFilterRecordValue) || {}

  const [fromRange, setFromRange] = useState<InputNumberValue>(
    (condition === QueryCondition.Between && Array.isArray(value) && (value?.[0]?.value as number)) ||
      (condition === QueryCondition.GraterThanEqual && !Array.isArray(value) && (value?.value as number)) ||
      null,
  )
  const [toRange, setToRange] = useState<InputNumberValue>(
    (condition === QueryCondition.Between && Array.isArray(value) && (value?.[1]?.value as number)) ||
      (condition === QueryCondition.LessThanEqual && !Array.isArray(value) && (value?.value as number)) ||
      null,
  )

  const handleFilterChange = (field: string) => (value: InputNumberValue) => {
    const isFromRange = field === 'from'
    if (isFromRange) {
      setFromRange(value)
    } else {
      setToRange(value)
    }
  }

  const updateFilter = useCallback(() => {
    updateFilters?.(getRangeFilter(name, fromRange, toRange))
  }, [updateFilters, name, fromRange, toRange])

  useEffect(() => {
    if (!value) {
      setFromRange(null)
      setToRange(null)
    }
  }, [value])

  return (
    <Container gap={4} ai="center">
      <InputNumber
        {...inputProps}
        prefix={currency}
        width={width}
        placeholder={placeholder}
        precision={precision}
        onChange={handleFilterChange('from')}
        onPressEnter={updateFilter}
        onBlur={updateFilter}
        value={Number(fromRange) || null}
      />
      -
      <InputNumber
        {...inputProps}
        prefix={currency}
        width={width}
        placeholder={placeholder}
        precision={precision}
        onChange={handleFilterChange('to')}
        onPressEnter={updateFilter}
        onBlur={updateFilter}
        value={Number(toRange) || null}
      />
    </Container>
  )
}
