import type { Warehouse } from 'api'

import { api } from '../api'

export const getWarehouses = (): Promise<Warehouse[]> => {
  return api({
    method: 'get',
    url: '/api/v1/warehouses',
  })
}
