import { ReferenceNumberType } from 'api'

export const getReferenceNumberPrefix = (type: ReferenceNumberType) => {
  return {
    [ReferenceNumberType.Booking]: 'BK',
    [ReferenceNumberType.VendorRelease]: 'VR',
    [ReferenceNumberType.Pickup]: 'PU',
    [ReferenceNumberType.Redelivery]: 'RD',
  }[type]
}
