import { UploadFile } from 'antd/lib/upload'
import * as Yup from 'yup'

import { VendorType } from 'api'

export const validationSchema = Yup.object({
  vendorType: Yup.mixed<VendorType>().required('Vendor type is required'),
  files: Yup.array(Yup.mixed<UploadFile<File>>().required('File is required'))
    .required('File is required')
    .min(1, 'File is required'),
})
