import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { EntityId } from 'api'
import { useDispatchAppEvent } from 'hooks'
import { route } from 'constant'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { deleteUser } from '../queryApi'

export const useRemoveUser = (userId?: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const navigate = useNavigate()

  const { mutate, ...rest } = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      dispatchAppEvent(AppEvent.UserDelete)

      showNotification({ title: 'User deleted', type: 'success' })
      navigate(route.userManagement)
    },
  })

  const removeUser = useCallback(() => {
    mutate(userId)
  }, [mutate, userId])

  return {
    removeUser,
    ...rest,
  }
}
