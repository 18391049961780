import { api, EntityId, VendorCarrierPriceListItem } from 'api'

import { CarrierPriceListPayload } from '../../types/carrierPriceListPayload'

export type PostCarrierPriceListResponse = {
  option: VendorCarrierPriceListItem
}

export const postCarrierPriceList = ({
  vendorId,
  data,
}: {
  vendorId?: EntityId
  data?: CarrierPriceListPayload
}): Promise<PostCarrierPriceListResponse> => {
  return api({
    method: 'post',
    url: `/api/v1/vendors/${vendorId}/types/carrier/price-list`,
    data,
    params: {
      join: ['truckCode', 'locationCode'],
    },
  })
}
