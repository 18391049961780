import { ReferenceNumberItem } from 'api'

export const getVendorReleaseItemsQty = (items?: ReferenceNumberItem[]) =>
  items?.reduce(
    (acc, item) => {
      if (acc?.[item.itemId]) {
        acc[item.itemId] += item.qty
      } else {
        acc = {
          ...acc,
          [item.itemId]: item.qty,
        }
      }

      return acc
    },
    {} as Record<string, number>,
  ) || {}
