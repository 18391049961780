export enum AssetStatus {
  Create = 'created',
  Available = 'available',
  NotAvailable = 'not_available',
  Split = 'split',
  Recycle = 'recycle',
  Booked = 'booked',
  Sold = 'sold',
  Rented = 'rented',
  PendingDelete = 'pending_delete',
  VendorAccount = 'vendor_account',
  PendingVendorRelease = 'pending_vendor_release',
  PendingRedelivery = 'pending_redelivery',
}
