import { api, EntityId, SalesOrder } from 'api'

import { salesOrderJoinParams } from './constants/salesOrderJoinParams'

export const getSalesOrderById = (id?: EntityId): Promise<SalesOrder> => {
  return api({
    method: 'get',
    url: `/api/v1/sales-orders/${id}`,
    params: {
      join: salesOrderJoinParams,
    },
  })
}
