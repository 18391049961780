import { PurchaseOrder, PurchaseOrderEmail, PurchaseOrderType } from 'api'
import {
  PurchaseOrderFormAccessor,
  PurchaseOrderItemAccessor,
  PurchaseOrderSubLineAccessor,
  SendEmailAction,
} from 'types'
import { PurchaseOrderDataStorePayload } from 'store'

export const getPurchaseOrderEmailTypes = (
  action: SendEmailAction,
  data?: PurchaseOrder,
  payload?: PurchaseOrderDataStorePayload,
) => {
  const poTypes: PurchaseOrderEmail[] = []

  if (data?.isEmailRequired) {
    if (action === SendEmailAction.PurchaseOrderCreate) {
      if (data?.type === PurchaseOrderType.Redelivery) {
        poTypes.push(
          PurchaseOrderEmail.CreatePurchaseOrderRedeliveryToVendor,
          PurchaseOrderEmail.CreatePurchaseOrderRedeliveryToDepot,
        )
      } else if (data?.type === PurchaseOrderType.VendorRelease) {
        poTypes.push(PurchaseOrderEmail.CreatePurchaseOrderVendorRelease)
      }
    }
    if (action === SendEmailAction.PurchaseOrderUpdate) {
      const payloadFieldKeys = Object.keys(payload || {}) as PurchaseOrderFormAccessor[]

      const shouldSendToVendorMain = payloadFieldKeys.some((fieldKey) =>
        [
          PurchaseOrderFormAccessor.LocationCodeId,
          PurchaseOrderFormAccessor.DepotId,
          PurchaseOrderFormAccessor.RedeliveryNumber,
        ].includes(fieldKey as PurchaseOrderFormAccessor),
      )

      const shouldSendToVendorItems = payload?.items?.some((item) =>
        Object.keys(item).some((itemKey) =>
          [PurchaseOrderItemAccessor.Sku, PurchaseOrderItemAccessor.Qty, PurchaseOrderItemAccessor.Rate].includes(
            itemKey as PurchaseOrderItemAccessor,
          ),
        ),
      )

      const shouldSendToVendor = shouldSendToVendorMain || shouldSendToVendorItems

      if (data?.type === PurchaseOrderType.Redelivery) {
        const shouldSendToDepot = payloadFieldKeys.some((fieldKey) =>
          [PurchaseOrderFormAccessor.VendorName, PurchaseOrderFormAccessor.RedeliveryNumber].includes(
            fieldKey as PurchaseOrderFormAccessor,
          ),
        )
        const shouldSendToDepotItemsFields = payload?.items?.some(
          (item) =>
            Object.keys(item).some((itemKey) =>
              [PurchaseOrderItemAccessor.Sku, PurchaseOrderItemAccessor.Qty].includes(
                itemKey as PurchaseOrderItemAccessor,
              ),
            ) ||
            item.subLines?.some((subLine) =>
              Object.keys(subLine).some((subLineKey) => PurchaseOrderSubLineAccessor.AssetNumber === subLineKey),
            ),
        )

        if (shouldSendToVendor) {
          poTypes.push(PurchaseOrderEmail.UpdatePurchaseOrderRedeliveryToVendor)
        }

        if (shouldSendToDepot || shouldSendToDepotItemsFields) {
          poTypes.push(PurchaseOrderEmail.UpdatePurchaseOrderRedeliveryToDepot)
        }
      } else if (data?.type === PurchaseOrderType.VendorRelease) {
        if (shouldSendToVendor) {
          poTypes.push(PurchaseOrderEmail.UpdatePurchaseOrderVendorRelease)
        }
      }
    }
  }

  return poTypes
}
