import theme from 'styles/theme'

import { TagType } from 'designSystem/components/Tag/types/tagType'

export const getTooltipTagColors = (status: TagType) => {
  switch (status) {
    case TagType.Success:
      return {
        color: theme.colors.white,
        backgroundColor: theme.colors.success[400],
        borderColor: theme.colors.success[400],
      }
    case TagType.Disabled:
      return {
        color: theme.colors.secondary,
        backgroundColor: theme.colors.lines,
        borderColor: theme.colors.placeholder,
      }
    case TagType.Warning:
      return {
        color: theme.colors.white,
        backgroundColor: theme.colors.warning[400],
        borderColor: theme.colors.warning[400],
      }
    case TagType.Error:
      return {
        color: theme.colors.white,
        backgroundColor: theme.colors.error[400],
        borderColor: theme.colors.error[400],
      }
    case TagType.Pending:
      return {
        color: theme.colors.white,
        backgroundColor: theme.colors.contrast[400],
        borderColor: theme.colors.contrast[400],
      }
    case TagType.InProgress:
      return {
        color: theme.colors.white,
        backgroundColor: theme.colors.accent[400],
        borderColor: theme.colors.accent[400],
      }
    case TagType.Done:
      return {
        color: theme.colors.primary,
        backgroundColor: theme.colors.lines,
        borderColor: theme.colors.lines,
      }
    default:
      return {
        color: theme.colors.white,
        backgroundColor: theme.colors.primary,
        borderColor: theme.colors.primary,
      }
  }
}
