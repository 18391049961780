import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { EntityId, getReferenceNumberById } from 'api'
import { QueryKey } from 'enums'

import { referenceNumberByIdJoinParams } from 'modules/references/constants/referenceNumberByIdJoinParams'

export const useGetReferenceNumberById = (id?: EntityId) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { data, isError, ...rest } = useQuery({
    queryKey: [QueryKey.GetReferenceNumberById, id],
    queryFn: () => getReferenceNumberById(id, referenceNumberByIdJoinParams),
    enabled: !!id,
  })

  useEffect(() => {
    if (isError) {
      navigate(pathname, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  return {
    data: data?.reference,
    isError,
    ...rest,
  }
}
