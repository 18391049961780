import type { ActivityStreamline, ApiQueryParams } from 'api'
import type { Pagination } from 'types'

import { api } from '../api'

export interface GetActivityStreamlineResponse extends Pagination {
  data?: ActivityStreamline[]
}

export type GetActivityStreamlineQueryParams = ApiQueryParams

export const getActivityStreamline = (
  params?: GetActivityStreamlineQueryParams,
): Promise<GetActivityStreamlineResponse> => {
  return api({
    method: 'get',
    url: '/api/v1/activity-streamline/',
    params,
  })
}
