import { AssetRepairStatus } from 'api/types/asset/assetRepairStatus'

export const assetRepairStatusOptionLabel = {
  [AssetRepairStatus.WaitingForRepair]: 'Waiting for Repair',
  [AssetRepairStatus.Done]: 'Done',
  [AssetRepairStatus.Canceled]: 'Canceled',
}

export const assetRepairStatusOptions = Object.keys(assetRepairStatusOptionLabel).map((key) => ({
  value: key,
  label: assetRepairStatusOptionLabel[key as AssetRepairStatus],
}))
