import { FC, useCallback } from 'react'

import { useFormContext, useWatch } from 'hooks'
import { assetLabels } from 'constant'
import { isDateBeforeCurrentDateEndOfDay } from 'utils'

import { Container, EditFormItem } from 'designSystem'
import { FormItemEditDatePicker, FormItemEditInput } from 'components'

export const LoanFieldsEdit: FC = () => {
  const { setValue, triggerSubmit } = useFormContext()
  const loanNumber = useWatch({ name: 'loanNumber' })

  const handleLoanNumberBlur = useCallback(() => {
    if (!loanNumber) {
      setValue('loanExpirationDate', null)
    }
    triggerSubmit?.()
  }, [loanNumber, setValue, triggerSubmit])

  return (
    <Container fd="column" gap={4}>
      <EditFormItem
        name="loanNumber"
        label={assetLabels.loanNumber}
        render={({ field }) => (
          <FormItemEditInput placeholder="Type Loan Number" {...field} onBlur={handleLoanNumberBlur} />
        )}
      />
      {!!loanNumber && (
        <EditFormItem
          name="loanExpirationDate"
          label={assetLabels.loanExpirationDate}
          render={({ field }) => (
            <FormItemEditDatePicker
              {...field}
              allowClear={false}
              placeholder="Type Loan Expiration Date"
              disabledDate={isDateBeforeCurrentDateEndOfDay}
              onBlur={triggerSubmit}
            />
          )}
        />
      )}
    </Container>
  )
}
