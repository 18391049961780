import { getAccessorTitle } from '../utils/getAccessorTitle'
import { Accessor } from '../types/accessor'

export const vendorPurchaseOrdersRequiredColumns = [Accessor.Index, Accessor.Status, Accessor.Number]

export const vendorPurchaseOrdersDefaultColumns = [
  Accessor.Index,
  Accessor.Number,
  Accessor.Type,
  Accessor.LocationCode,
  Accessor.Depot,
  Accessor.ExpectedDate,
  Accessor.ExpirationDate,
  Accessor.CreatedAt,
  Accessor.Qty,
  Accessor.ItemsTotal,
  Accessor.Status,
  Accessor.Notes,
]

export const vendorPurchaseOrdersVisibleColumnsOptions = Object.values(Accessor).map((accessor) => ({
  value: accessor,
  label: getAccessorTitle(accessor),
  disabled: vendorPurchaseOrdersRequiredColumns.includes(accessor),
}))
