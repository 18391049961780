import { FC, useCallback, useEffect, useState } from 'react'
import { Popover, Tooltip } from 'antd'

import theme from 'styles/theme'

import { Button, Container, Divider, IconName } from 'designSystem'

import type { TableFilterOption } from '../../types/tableFilterOption'
import { TableFilterOptionsColumn } from './elements/TableFilterOptionsColumns'
export interface TableFilterOptionsProps {
  title: string
  subLineTitle?: string
  icon: IconName
  options: TableFilterOption[]
  subLineOptions?: TableFilterOption[]
  onChange?: (values: TableFilterOption['value'][]) => void
  onChangeSubLines?: (values: TableFilterOption['value'][]) => void
  value?: TableFilterOption['value'][]
  subLineValue?: TableFilterOption['value'][]
  width?: number
  showAll?: boolean
  tooltipText?: string
  showTooltip?: boolean
}

export const TableFilterOptions: FC<TableFilterOptionsProps> = ({
  title,
  subLineTitle,
  icon,
  width = 200,
  onChange,
  onChangeSubLines,
  options,
  subLineOptions,
  value,
  subLineValue,
  showAll,
  tooltipText,
  showTooltip,
}) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  useEffect(() => {
    if (showAll) {
      selectAll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAll])

  const selectAll = useCallback(() => {
    onChange?.(options?.map(({ value }) => value))
    if (onChangeSubLines && subLineOptions) {
      onChangeSubLines(subLineOptions.map(({ value }) => value))
    }
  }, [onChange, onChangeSubLines, options, subLineOptions])

  const handleOpenChange = useCallback((visible: boolean) => {
    setIsPopoverVisible(visible)
  }, [])

  return (
    <Container>
      <Popover
        arrow={false}
        trigger="click"
        placement="bottomRight"
        onOpenChange={handleOpenChange}
        content={
          <Container pa={4} width={width} display="block">
            <Container display="flex" jc="space-between">
              <TableFilterOptionsColumn title={title} onChange={onChange} options={options} value={value} />
              {subLineOptions && (
                <>
                  <Divider mx={4} vertical />
                  <TableFilterOptionsColumn
                    title={subLineTitle}
                    onChange={onChangeSubLines}
                    options={subLineOptions}
                    value={subLineValue}
                  />
                </>
              )}
            </Container>
          </Container>
        }
      >
        <Tooltip
          color={theme.colors.primary}
          title={tooltipText}
          getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
          placement="bottomLeft"
          open={(!isPopoverVisible && !!tooltipText && showTooltip) || undefined}
        >
          <Button icon={icon} size="small" disabled={showAll} />
        </Tooltip>
      </Popover>
    </Container>
  )
}
