import { Accessor } from '../types/accessor'

export const getAccessorTitle = (accessor: Accessor): string => {
  return {
    [Accessor.Index]: '#',
    [Accessor.Title]: 'Title',
    [Accessor.Sku]: 'Sku',
    [Accessor.Qty]: 'Qty.',
    [Accessor.Price]: 'Cost',
    [Accessor.ResaleMargin]: 'Margin',
    [Accessor.TotalCost]: 'Subtotal Price',
    [Accessor.LocationCodeId]: 'Location',
    [Accessor.Actions]: '',
  }[accessor]
}
