import { styled } from 'styled-components'

const tooltipSpacer = '-4px'

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;

  &&&& .ant-tooltip-arrow {
    bottom: ${tooltipSpacer};
    right: 0;
  }

  &&&& .ant-tooltip-content {
    bottom: ${tooltipSpacer};
    right: ${tooltipSpacer};
  }
`

export const ControllerWrapper = styled.div<{ $hidden?: boolean; $isFieldViewOnly?: boolean }>`
  ${({ $hidden }) => $hidden && 'display: none;'}
  ${({ $isFieldViewOnly }) => $isFieldViewOnly && 'pointer-events: none'}
`
