import { FC, ReactNode } from 'react'
import { Popover } from 'antd'
import { PopoverProps } from 'antd/lib/popover'

import { isValidLink } from 'utils'

import { Container, EditFormItem, ReactLink, Typography } from 'designSystem'

interface PopoverForBillNumberProps extends PopoverProps {
  show: boolean
  popoverName: string
  children: ReactNode
}

export const PopoverForBillNumber: FC<PopoverForBillNumberProps> = ({ show, popoverName, children, ...props }) => {
  return (
    <Popover
      content={
        show ? (
          <Container pa={4}>
            <EditFormItem
              name={popoverName}
              render={({ field }) => {
                return isValidLink(field.value) ? (
                  <ReactLink to={field.value}>
                    <Typography color="info" size="xs" fontWeight="l" lineEllipsis={1}>
                      {field.value}
                    </Typography>
                  </ReactLink>
                ) : (
                  <Typography size="xs" fontWeight="l" lineEllipsis={1}>
                    {field.value}
                  </Typography>
                )
              }}
            />
          </Container>
        ) : null
      }
      arrow={false}
      {...props}
    >
      <Container>{children}</Container>
    </Popover>
  )
}
