import { css, keyframes, styled } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

interface IconWrapperProps {
  $clickable?: boolean
  spin?: boolean
}

export const IconWrapper = styled.span<IconWrapperProps>`
  display: inline-flex;
  ${({ spin }) =>
    spin &&
    css`
      animation: ${rotate} 1s linear infinite;
    `}

  cursor: ${({ $clickable }) => $clickable && 'pointer'};
`
