import { css, styled } from 'styled-components'

import { Container } from 'designSystem'

export const ItemContainer = styled(Container)<{ $applyOverlay: boolean }>`
  ${({ $applyOverlay }) =>
    $applyOverlay &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
        background: white;
        opacity: 0.5;
        pointer-events: painted;
        cursor: not-allowed;
      }
    `}
`
