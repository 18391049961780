import { PurchaseOrder, PurchaseOrderStatus, PurchaseOrderType } from 'api'
import { getPurchaseOrderTypeLabel, hash, purchaseOrderLabels, route } from 'constant'
import { PurchaseOrderFormAccessor } from 'types'
import { formatDate } from 'utils'

import { ReactLink } from 'designSystem'
import { PurchaseOrderStatusTag, TableColumnsType } from 'components'

export const assetPurchaseOrderColumns: TableColumnsType<PurchaseOrder> = [
  {
    title: purchaseOrderLabels[PurchaseOrderFormAccessor.CreatedAt],
    key: PurchaseOrderFormAccessor.CreatedAt,
    dataIndex: PurchaseOrderFormAccessor.CreatedAt,
    width: 150,
    ellipsis: true,
    render: (value: Date) => formatDate(value),
  },
  {
    title: purchaseOrderLabels[PurchaseOrderFormAccessor.Number],
    key: PurchaseOrderFormAccessor.Number,
    dataIndex: PurchaseOrderFormAccessor.Number,
    width: 150,
    ellipsis: true,
    render: (value: string, record: PurchaseOrder) => (
      <ReactLink to={`${hash.po}/${record.id}`} target="_self">
        {value}
      </ReactLink>
    ),
  },
  {
    title: purchaseOrderLabels[PurchaseOrderFormAccessor.Type],
    key: PurchaseOrderFormAccessor.Type,
    dataIndex: PurchaseOrderFormAccessor.Type,
    width: 150,
    ellipsis: true,
    render: (value: PurchaseOrderType) => getPurchaseOrderTypeLabel(value),
  },
  {
    title: purchaseOrderLabels[PurchaseOrderFormAccessor.VendorName],
    key: PurchaseOrderFormAccessor.VendorName,
    dataIndex: PurchaseOrderFormAccessor.VendorName,
    width: 200,
    ellipsis: true,
    render: (value: string, record: PurchaseOrder) => (
      <ReactLink to={`${route.vendors}/${record.vendorId}`}>{value}</ReactLink>
    ),
  },
  {
    title: purchaseOrderLabels[PurchaseOrderFormAccessor.ExpirationDate],
    key: PurchaseOrderFormAccessor.ExpirationDate,
    dataIndex: PurchaseOrderFormAccessor.ExpirationDate,
    width: 150,
    ellipsis: true,
    render: (value: Date) => formatDate(value),
  },
  {
    title: purchaseOrderLabels[PurchaseOrderFormAccessor.Status],
    key: PurchaseOrderFormAccessor.Status,
    dataIndex: PurchaseOrderFormAccessor.Status,
    width: 150,
    ellipsis: true,
    render: (value: PurchaseOrderStatus) => <PurchaseOrderStatusTag status={value} />,
  },
  {
    title: purchaseOrderLabels[PurchaseOrderFormAccessor.Notes],
    key: PurchaseOrderFormAccessor.Notes,
    dataIndex: PurchaseOrderFormAccessor.Notes,
    width: 200,
    ellipsis: true,
    render: (notes) => notes || '-',
  },
]
