import { FC } from 'react'

import { typedMemo } from 'types'

import * as Styled from './styles'

interface StripLoaderProps {
  isLoading?: boolean
  noBorderRadius?: boolean
}

export const StripLoaderBase: FC<StripLoaderProps> = ({ isLoading, noBorderRadius }) => {
  if (!isLoading) return null
  return <Styled.Strip $noBorderRadius={noBorderRadius} />
}

export const StripLoader = typedMemo(StripLoaderBase)
