import { api, EntityId, User } from 'api'

export interface UpdateUserDataPayload {
  firstName?: string
  lastName?: string
  phoneNumber?: string | null
  phoneExtension?: string | null
  rolesIds?: number[]
  email?: string
}

export interface UpdateUserPayload {
  userId?: EntityId
  data: UpdateUserDataPayload
}

export const getUserById = (userId?: EntityId): Promise<User> => {
  return api({
    method: 'get',
    url: `/api/v1/users/${userId}`,
    params: {
      join: 'roles',
    },
  })
}

export const patchUser = ({ userId, data }: UpdateUserPayload): Promise<User> => {
  return api({
    method: 'patch',
    url: `/api/v1/users/${userId}`,
    data,
    params: {
      join: 'roles',
    },
  })
}

export const deleteUser = (userId?: EntityId): Promise<void> => {
  return api({
    method: 'delete',
    url: `/api/v1/users/${userId}`,
  })
}
