import { FC, useCallback, useEffect, useState } from 'react'

import { TableFilterSingleValue, useTableContext } from 'hooks'
import { getDateRangeFilter, toDate } from 'utils'

import { Container, DateRangePicker, DateRangePickerProps, DateRangePickerValue } from 'designSystem'

interface TableFilterRangeProps extends Omit<DateRangePickerProps, 'name'> {
  name: string
}

export const TableFilterDateRange: FC<TableFilterRangeProps> = ({ name, ...props }) => {
  const { updateFilters, getFilterValue } = useTableContext()

  const filterValue = getFilterValue?.(name) as TableFilterSingleValue[] | undefined
  const filterValueAsDate = filterValue?.map(({ value }) => toDate(value as string)) as DateRangePickerValue | undefined

  const [values, setValues] = useState<DateRangePickerValue>(filterValueAsDate)

  const handleFilterChange = useCallback(
    (values: DateRangePickerValue) => {
      setValues(values)
      updateFilters?.(getDateRangeFilter(name, values))
    },
    [name, updateFilters],
  )

  useEffect(() => {
    if (filterValue === undefined) {
      setValues(undefined)
    }
  }, [filterValue])

  return (
    <Container gap={4} ai="center">
      <DateRangePicker {...props} onChange={handleFilterChange} value={values} />
    </Container>
  )
}
