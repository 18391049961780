import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { Tabs } from 'designSystem'

import { useGetDepotByVendorId } from './hooks/useGetDepotByVendorId'
import { getDepotTabs } from './utils/getDepotTabs'

export const Depot: FC = () => {
  const { id } = useParams()

  const { data, isLoading } = useGetDepotByVendorId(Number(id))

  return <Tabs items={getDepotTabs({ data, isLoading })} />
}
