export enum SalesOrderFormAccessor {
  CustomerId = 'customerId',
  CustomerPurchaseOrder = 'customerPurchaseOrder',
  ShippingAddressId = 'shippingAddressId',
  BillingAddressId = 'billingAddressId',
  IsEmailRequired = 'isEmailRequired',
  ContactsIds = 'contactsIds',
  Invoice = 'invoice',
  SalesRepresentativeId = 'salesRepresentativeId',
  QuoteUrl = 'quoteUrl',
  DispatcherId = 'dispatcherId',
  Yard = 'yard',
  ExpectedDate = 'expectedDate',
  EarliestDate = 'earliestDate',
  LatestDate = 'latestDate',
  SpecificDate = 'specificDate',
  IsWeekendDelivery = 'isWeekendDelivery',
  DayWeekendDelivery = 'dayWeekendDelivery',
  AvailableHours = 'availableHours',
  IsReceivingSeparately = 'isReceivingSeparately',
  OnSiteContactsIds = 'onSiteContactsIds',
  GeneralNotes = 'generalNotes',
  DispatchNotes = 'dispatchNotes',
  ProcurementNotes = 'procurementNotes',
  ProcurementId = 'procurementId',

  // Sales Order Line Items
  LineItem = 'lineItem',
  LineItems = 'lineItems',
  LineItemQty = 'qty',
  LineItemId = 'itemId',
  LineItemPrice = 'price',
  LineItemDiscount = 'discount',
  LineItemTaxes = 'taxes',

  // Sales Order SubLine Fields
  SubLines = 'subLines',
}
