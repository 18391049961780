import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { EntityId } from 'api'
import { useDispatchAppEvent } from 'hooks'
import { route } from 'constant'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { deleteVendor } from '../queryApi'

export const useRemoveVendor = (id: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const navigate = useNavigate()

  const { mutate, ...rest } = useMutation({
    mutationFn: deleteVendor,
    onSuccess: () => {
      dispatchAppEvent(AppEvent.VendorDelete)

      showNotification({ title: 'Vendor deleted', type: 'success' })
      navigate(route.vendors)
    },
  })

  const remove = useCallback(() => {
    mutate(id)
  }, [mutate, id])

  return {
    remove,
    ...rest,
  }
}
