import { FC } from 'react'

import { flatFeeOptions } from 'constant'

import { Container, FormItem, SelectSingle, Typography } from 'designSystem'

import { storageFeeList } from './constants/storageFeeList'
import { StorageFeeTitle } from 'modules/vendors/elements/StorageFeeTitle'

import { FeeItem } from '../FeeItem'

export const StorageFeeList: FC = () => {
  return (
    <>
      <Container fd="column" mb={4}>
        <StorageFeeTitle titleSize="s" />
      </Container>
      <Container fd="column" gap={8} maxWidth={220}>
        {storageFeeList.map(({ name, label }) => (
          <FeeItem name={name} label={label} key={name} />
        ))}
        <Container ai="center" jc="space-between">
          <Typography size="xs" fontWeight="l">
            Flat Fee
          </Typography>
          <FormItem name="depotSettings.flatFee">
            <SelectSingle allowClear width={140} placeholder="Select Flat fee" options={flatFeeOptions} />
          </FormItem>
        </Container>
      </Container>
    </>
  )
}
