import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { ReferenceNumber } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { postPickupCustomerContacts, PostPickupCustomerContactsPayload } from '../queryApi'

export const usePostPickupCustomerContacts = (referenceId?: number, customerId?: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation({
    mutationFn: postPickupCustomerContacts,
    onSuccess: (contacts) => {
      dispatchAppEvent(AppEvent.ReferenceNumberPickupContactsUpdate)
      queryClient.setQueryData(
        [QueryKey.GetReferenceNumberById, referenceId],
        (prev: { reference: ReferenceNumber } | undefined) =>
          prev
            ? {
                reference: {
                  ...prev.reference,
                  contacts: [...(prev.reference.contacts || []), ...contacts],
                },
              }
            : undefined,
      )
      showNotification({ title: 'Contacts added', type: 'success' })
    },
  })

  const post = useCallback(
    (payload: PostPickupCustomerContactsPayload) => {
      mutate({ referenceId, customerId, data: payload })
    },
    [mutate, referenceId, customerId],
  )

  return {
    post,
    ...rest,
  }
}
