import { styled } from 'styled-components'

import { Container } from 'designSystem'

export const FieldArrayItem = styled(Container)`
  width: 100%;
  > *:first-child {
    width: inherit;
  }
`
