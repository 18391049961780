import { getLocationCodes, LocationCode } from 'api'
import { QueryKey } from 'enums'
import { AsyncSelectGetItemsProps } from 'types'

import type { SelectSingleOption } from 'designSystem'

import { useAsyncList } from './useAsyncList'

export const useLocationCodes = ({
  valuePropName, // keyof Location Code - the value of it should be of type string | number,
  withFullDataOption,
  fields,
  ...props
}: AsyncSelectGetItemsProps) => {
  const { list, ...asyncListProps } = useAsyncList<LocationCode>({
    ...props,
    queryFn: getLocationCodes,
    queryKey: QueryKey.GetLocationCodesList,
    searchFieldName: 'code',
    limit: 10,
    fields: fields || 'id,code',
  })

  const options = list.map((locationCode) => ({
    value: valuePropName ? (locationCode[valuePropName as keyof LocationCode] as string | number) : locationCode.id,
    label: locationCode.code,
    ...(withFullDataOption && { data: locationCode }),
  })) satisfies SelectSingleOption[]

  return {
    options,
    ...asyncListProps,
  }
}
