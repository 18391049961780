import { FC, useCallback } from 'react'

import { Warehouse } from 'api'
import { useFieldArray, useFormContext } from 'hooks'
import { getIsFormFieldViewOnly } from 'utils'

import { Button, Container, Spacer } from 'designSystem'

import { EditWarehouse } from './elements/EditWarehouse'

interface FormItemEditWarehousesProps {
  name: string
  onSubmitPost: (index: number) => void
  onSubmitPatch: (id: number) => void
  onDelete: (index: number) => void
  isLoading?: boolean
}

export const FormItemEditWarehouses: FC<FormItemEditWarehousesProps> = ({
  name,
  onSubmitPost,
  onSubmitPatch,
  onDelete,
  isLoading,
}) => {
  const { watch, viewOnlyFields } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name,
  })

  const values: Warehouse[] = watch(name) || []

  const isFieldViewOnly = getIsFormFieldViewOnly(viewOnlyFields, name)

  const showAddButton = !isFieldViewOnly && values.filter(({ id }) => !id).length < 1

  const handleDelete = useCallback(
    (index: number) => {
      remove(index)
      onDelete?.(index)
    },
    [remove, onDelete],
  )

  const handleCancel = useCallback(
    (index: number) => {
      remove(index)
    },
    [remove],
  )

  const addNewItem = () => append({})

  return (
    <Container display="flex" fd="column" ai="flex-start" gap={8}>
      {fields.map((field, index) => (
        <EditWarehouse
          name={name}
          index={index}
          key={field.id}
          onSubmitPost={onSubmitPost}
          onSubmitPatch={onSubmitPatch}
          onDelete={handleDelete}
          onCancel={handleCancel}
          isLoading={isLoading}
        />
      ))}
      <Spacer mt={2} />
      {showAddButton && (
        <Button type="link" icon="plus" onClick={addNewItem}>
          Add Warehouse
        </Button>
      )}
    </Container>
  )
}
