import { useEffect, useState } from 'react'

import { UserSettingsService } from 'services'
import { AppModule } from 'types'

import type { TableColumnsType } from 'components'

import { TableColumnKey } from '../types/tableColumnKey'
import type { TableFilterOption } from '../types/tableFilterOption'

interface UseTableColumnsProps<T extends object> {
  module?: AppModule
  allColumns: TableColumnsType<T>
  defaultColumns?: string[]
  withActions?: boolean
}

export const useTableColumns = <T extends object>({
  module,
  allColumns,
  defaultColumns = [],
  withActions,
}: UseTableColumnsProps<T>) => {
  const defaults = (module && UserSettingsService.getModuleTableSettings(module)?.columns) || defaultColumns
  const [visibleColumns, setVisibleColumns] = useState<TableFilterOption['value'][]>(defaults)

  const columns: TableColumnsType<T> = [
    ...visibleColumns.map((column) => ({
      ...allColumns.find(({ key }) => key === column),
    })),
    ...(withActions
      ? [allColumns.find(({ key }) => key === TableColumnKey.Actions || key === TableColumnKey.HoverActions) || {}]
      : []),
  ]

  useEffect(() => {
    if (module) {
      UserSettingsService.setModuleTableSettings(module, { columns: visibleColumns })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleColumns])

  return { visibleColumns, setVisibleColumns, columns }
}
