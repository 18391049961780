import { create } from 'zustand'

import { User } from 'api'

interface UserStore {
  user?: User
  setUser: (user?: User) => void
  removeUser: () => void
  isAdmin: boolean
  isSuperAdmin: boolean
}

const useUserStore = create<UserStore>((set) => ({
  user: undefined,
  setUser: (user?: User) =>
    set({
      user,
      isAdmin: user?.roles?.some((role) => role.name === 'Admin'),
      isSuperAdmin: user?.roles?.some((role) => role.name === 'Super Admin'),
    }),
  removeUser: () => set({ user: undefined, isAdmin: false, isSuperAdmin: false }),
  isAdmin: false,
  isSuperAdmin: false,
}))

export const useUser = () => useUserStore((state) => state.user)
export const useSetUser = () => useUserStore((state) => state.setUser)
export const useRemoveUser = () => useUserStore((state) => state.removeUser)
export const useUserCompanyZohoLinks = () => useUserStore((state) => state.user?.company)
export const useIsUserAdmin = () => useUserStore((state) => state.isAdmin)
export const useIsUserSuperAdmin = () => useUserStore((state) => state.isSuperAdmin)
