import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { VendorInventory, VendorType } from 'api'

import { getPatchPayload } from '../utils/getPatchPayload'
import { InventoryFormPayload } from '../types/inventoryCreateForm'

import { useDeleteInventory } from './useDeleteInventory'
import { usePatchInventory } from './usePatchInventory'
import { usePostInventory } from './usePostInventory'

export const useInventoryRequests = (
  setIsAddNew: (isAddNew: boolean) => void,
  data: VendorInventory[],
  type: VendorType.ContainerVendor | VendorType.Parts,
) => {
  const { id: vendorId } = useParams()

  const {
    post,
    isSuccess: isPostSuccess,
    isError: isPostError,
    isPending: isPostLoading,
  } = usePostInventory(Number(vendorId), type)

  const {
    patch,
    isSuccess: isPatchSuccess,
    isError: isPatchError,
    isPending: isPatchLoading,
  } = usePatchInventory(Number(vendorId), type)

  const {
    remove,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
    isPending: isDeleteLoading,
  } = useDeleteInventory(Number(vendorId), type)

  const onSubmitPost = useCallback(
    (payload: InventoryFormPayload) => {
      if (payload.item) {
        const payloadData = {
          ...payload.item,
          ...(!payload.item.resaleMargin?.operand && { resaleMargin: null }),
        }
        post(payloadData)
        setIsAddNew(false)
      }
    },
    [post, setIsAddNew],
  )

  const onSubmitPatch = useCallback(
    (payload: InventoryFormPayload) => {
      const patchData = getPatchPayload(payload, data)
      patchData?.forEach(({ id, payload }) => {
        patch(id as number, payload)
      })
    },
    [patch, data],
  )

  const onDelete = useCallback(
    (inventoryId?: number) => {
      if (inventoryId) {
        remove(inventoryId)
      }
    },
    [remove],
  )

  return {
    isSubmitSuccess: isPostSuccess || isPatchSuccess || isDeleteSuccess,
    isSubmitError: isPostError || isPatchError || isDeleteError,
    isEditLoading: isPostLoading || isPatchLoading || isDeleteLoading,
    onSubmitPost,
    onSubmitPatch,
    onDelete,
  }
}
