import { getEditableColumnFormItemName } from './getEditableColumnFormItemName'
import { isEditableTableAddNewIndex } from './isEditableTableAddNewIndex'

export const getTableFieldName = (
  fieldName: string,
  itemName: string,
  listName: string,
  index: number,
  isAddNew: boolean,
): string => {
  return isEditableTableAddNewIndex(index, isAddNew)
    ? `${itemName}.${fieldName}`
    : getEditableColumnFormItemName(listName, fieldName, index, isAddNew)
}
