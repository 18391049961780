import {
  api,
  apiInstance,
  ApiInstanceResponse,
  Contact,
  EntityId,
  PurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderItemsSubLine,
} from 'api'

import { purchaseOrderItemsParams } from './constants/purchaseOrderItemsParams'
import { purchaseOrderJoinParams } from './constants/purchaseOrderJoinParams'
import { ContactFormData } from './routes/PurchaseOrderCreate/types/purchaseOrderCreateForm'

export const getPurchaseOrderById = (id?: EntityId): Promise<PurchaseOrder> => {
  return api({
    method: 'get',
    url: `/api/v1/purchase-orders/${id}`,
    params: {
      join: purchaseOrderJoinParams,
    },
  })
}

interface PostContactForPOProps {
  id?: EntityId
  data?: ContactFormData
}

export const postNewContactToPO = ({ id, data }: PostContactForPOProps): Promise<ApiInstanceResponse<Contact>> => {
  return apiInstance({
    method: 'post',
    url: `/api/v1/purchase-orders/${id}/contacts`,
    data,
  })
}

interface DeleteContactFromPOProps {
  purchaseOrderId?: EntityId
  contactId?: EntityId
}

export const deletePOContact = ({
  purchaseOrderId,
  contactId,
}: DeleteContactFromPOProps): Promise<ApiInstanceResponse<undefined>> => {
  return apiInstance({
    method: 'delete',
    url: `/api/v1/purchase-orders/${purchaseOrderId}/contacts/${contactId}`,
  })
}

export interface PostPickupCustomerContactsPayload {
  vendorContactsIds: number[]
}

export interface PostPOContactProps {
  purchaseOrderId?: number
  vendorId?: number
  data: PostPickupCustomerContactsPayload
}

export const postPOContact = ({
  purchaseOrderId,
  vendorId,
  data,
}: PostPOContactProps): Promise<ApiInstanceResponse<Contact[]>> => {
  return apiInstance({
    method: 'post',
    url: `/api/v1/purchase-orders/${purchaseOrderId}/contacts/copy/vendors/${vendorId}/contacts`,
    data,
  })
}

export interface ResetAssetNumberProps {
  purchaseOrderId: EntityId
  itemId?: EntityId
  subLineId: EntityId
}

export const resetAssetNumber = ({
  purchaseOrderId,
  itemId,
  subLineId,
}: ResetAssetNumberProps): Promise<ApiInstanceResponse<PurchaseOrderItemsSubLine>> => {
  return apiInstance({
    method: 'patch',
    url: `/api/v1/purchase-orders/${purchaseOrderId}/items/${itemId}/sub-lines/${subLineId}/action/reset-number`,
  })
}

export const patchPurchaseOrderItem = ({
  purchaseOrderId,
  itemId,
  data,
}: {
  purchaseOrderId?: EntityId
  itemId?: EntityId
  data?: Partial<PurchaseOrderItem>
}): Promise<ApiInstanceResponse<PurchaseOrderItem>> => {
  return apiInstance({
    method: 'patch',
    url: `/api/v1/purchase-orders/${purchaseOrderId}/items/${itemId}`,
    data,
    params: purchaseOrderItemsParams,
  })
}
