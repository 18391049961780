import { AssetBookingReferenceAccessor } from '../types/assetBookingReferenceAccessor'

// TODO: Reuse accessor titles for reference when will be moved to global level
export const getAssetBookingReferenceAccessorTitle = (accessor: AssetBookingReferenceAccessor): string => {
  return {
    [AssetBookingReferenceAccessor.Index]: '#',
    [AssetBookingReferenceAccessor.CreatedAt]: 'Created Date',
    [AssetBookingReferenceAccessor.Number]: 'Number',
    [AssetBookingReferenceAccessor.Type]: 'Type',
    [AssetBookingReferenceAccessor.TransactionType]: 'Transaction Type',
    [AssetBookingReferenceAccessor.RelatedReference]: 'Related Reference',
    [AssetBookingReferenceAccessor.GateOutDate]: 'Gate Out Date',
    [AssetBookingReferenceAccessor.Notes]: 'Notes',
    [AssetBookingReferenceAccessor.Status]: 'Status',
  }[accessor]
}
