import { ItemCategory } from '../types/item/itemCategory'

import { api } from '../api'

export const getItemsCategories = (): Promise<ItemCategory[]> => {
  return api({
    method: 'get',
    url: `/api/v1/items/categories`,
  })
}
