export enum SalesOrderAccountingStatus {
  BeforeLaunching = 'before_launching',
  // Green
  ApprovedPo = 'approved_po',
  ApprovedPaid = 'approved_paid',
  ApprovedDepositPaid = 'approved_deposit_paid',
  ApprovedCod = 'approved_cod',
  // Yellow
  PendingVerification = 'pending_verification',
  Pending = 'pending',
  HoldDelivery = 'hold_delivery',
  ContactAccountingForBalance = 'contact_accounting_for_balance',
  ApprovedProcurement = 'approved_procurement',
  // Red
  OnHoldWaitingForCheck = 'on_hold_waiting_for_check',
  OnHoldCreditCardDeclined = 'on_hold_credit_card_declined',
  Cancelled = 'cancelled',
}
