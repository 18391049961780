import { AssetCodeLength } from 'api/types/assetCode/assetCodeLength'

import { distanceUnits } from '../../distanceUnits'

export const assetCodeLengthOptions: { value: AssetCodeLength; label: string }[] = [
  {
    value: AssetCodeLength.XS,
    label: `${AssetCodeLength.XS}${distanceUnits.Ft}`,
  },
  {
    value: AssetCodeLength.S,
    label: `${AssetCodeLength.S}${distanceUnits.Ft}`,
  },
  {
    value: AssetCodeLength.M,
    label: `${AssetCodeLength.M}${distanceUnits.Ft}`,
  },
  {
    value: AssetCodeLength.L,
    label: `${AssetCodeLength.L}${distanceUnits.Ft}`,
  },
  {
    value: AssetCodeLength.XL,
    label: `${AssetCodeLength.XL}${distanceUnits.Ft}`,
  },
  {
    value: AssetCodeLength.XXL,
    label: `${AssetCodeLength.XXL}${distanceUnits.Ft}`,
  },
  {
    value: AssetCodeLength.XXXL,
    label: `${AssetCodeLength.XXXL}${distanceUnits.Ft}`,
  },
]
