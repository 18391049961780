import { api, Contact, EntityId } from 'api'

export const postVendorContact = ({ vendorId, data }: { vendorId?: EntityId; data?: Contact }): Promise<Contact> => {
  return api({
    method: 'post',
    url: `/api/v1/vendors/${vendorId}/contacts`,
    data,
  })
}

export const patchVendorContact = ({
  vendorId,
  contactId,
  data,
}: {
  vendorId?: EntityId
  contactId?: EntityId
  data?: Contact
}): Promise<Contact> => {
  return api({
    method: 'patch',
    url: `/api/v1/vendors/${vendorId}/contacts/${contactId}`,
    data,
  })
}

export const deleteVendorContact = ({
  vendorId,
  contactId,
}: {
  vendorId?: EntityId
  contactId?: EntityId
}): Promise<{ deleted: boolean }> => {
  return api({
    method: 'delete',
    url: `/api/v1/vendors/${vendorId}/contacts/${contactId}`,
  })
}
