import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useSetUser } from 'store'

import { showNotification } from 'designSystem'

import { patchMe, UpdateMePayload } from '../queryApi'

export const useUpdateMe = () => {
  const setUserData = useSetUser()
  const { mutate, ...rest } = useMutation({
    mutationFn: patchMe,
    onSuccess: (data) => {
      setUserData(data)
      queryClient.setQueryData([QueryKey.UserMe], data)
      queryClient.setQueryData([QueryKey.GetUserById, data.id], data)
      queryClient.invalidateQueries({ queryKey: [QueryKey.GetUsers] })
      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const updateMe = useCallback(
    (payload: UpdateMePayload) => {
      mutate(payload)
    },
    [mutate],
  )

  return {
    updateMe,
    ...rest,
  }
}
