import { SalesOrder } from 'api'

import { Tab } from 'designSystem'

import { SalesOrderIntegrations } from '../elements/SalesOrderIntegrations'

export const getSalesOrderTabs = (data: SalesOrder): Tab[] => [
  // {
  //   label: 'SO Details',
  //   icon: 'gridNoOutline',
  //   children: (
  //     <Container bg="white" pa={4}>
  //       <Typography size="s" fontWeight="l">
  //         Coming soon...
  //       </Typography>
  //     </Container>
  //   ),
  // },
  {
    label: 'Integrations',
    icon: 'linked',
    children: <SalesOrderIntegrations zohoId={data.zohoId} />,
  },
]
