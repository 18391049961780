import * as Sentry from '@sentry/react'

export const sentryInit = () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'staging' || process.env.REACT_APP_ENVIRONMENT === 'production') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      environment: process.env.REACT_APP_ENVIRONMENT,
      tracesSampleRate: 1.0,
    })
  }
}
