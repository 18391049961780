import { MarginSettingMeasure } from 'api/types/marginSetting/marginSettingMeasure'

import { currency } from '../../currency'

export const marginSettingsMeasureOptions = [
  {
    label: '%',
    value: MarginSettingMeasure.Percentage,
  },
  {
    label: currency,
    value: MarginSettingMeasure.Amount,
  },
]

export const getMarginSettingsMeasureLabel = (measure: MarginSettingMeasure) =>
  marginSettingsMeasureOptions.find(({ value }) => value === measure)?.label || ''
