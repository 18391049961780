import { FC } from 'react'

import { ItemCategory, ItemCategoryType } from 'api'

import { CategoryGroup } from './elements/CategoryGroup'
import { SingleCategory } from './elements/SingleCategory'

interface CategoryItemProps {
  item: ItemCategory
}

export const CategoryItem: FC<CategoryItemProps> = ({ item }) => {
  return <>{item.type === ItemCategoryType.Folder ? <CategoryGroup item={item} /> : <SingleCategory item={item} />}</>
}
