import * as Yup from 'yup'

import { VendorType } from 'api'
import { resaleMarginValidation, scrappingSettingsValidation } from 'utils'

export const validationSchema = Yup.object({
  vendorType: Yup.mixed<VendorType>().required('Vendor type is required'),
  scrappingSettings: scrappingSettingsValidation,
  resaleMargin: resaleMarginValidation,
})
