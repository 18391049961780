import { FC, ReactNode } from 'react'

import { typedMemo } from 'types'

import { Container } from 'designSystem/components/Container'

import { TagType } from '../../types/tagType'
import * as Styled from './styles'

interface DefaultTagProps {
  onClick?: () => void
  icon?: ReactNode
  status: TagType
  text?: string | ReactNode
}

const DefaultTagBase: FC<DefaultTagProps> = ({ onClick, icon, status, text }) => {
  return (
    <Styled.Tag status={status} onClick={onClick}>
      {icon ? (
        <Container>
          {text} <Styled.IconWrapper>{icon}</Styled.IconWrapper>
        </Container>
      ) : (
        text
      )}
    </Styled.Tag>
  )
}

export const DefaultTag = typedMemo(DefaultTagBase)
