import { useCallback, useEffect, useRef, useState } from 'react'

import { addHighlightMarkup, removeHighlightMarkup } from './utils/highlightedMarkup'

export const useHighlightedHtmlString = (defaultValue?: string) => {
  const [defaultString, setDefaultString] = useState(addHighlightMarkup(defaultValue || ''))
  const currentStringRef = useRef(defaultString)

  const onChange = useCallback((str: string) => {
    currentStringRef.current = str
  }, [])

  const getCurrentString = useCallback(() => {
    return removeHighlightMarkup(currentStringRef.current)
  }, [])

  useEffect(() => {
    if (defaultValue) {
      const newDefaultString = addHighlightMarkup(defaultValue)
      currentStringRef.current = newDefaultString
      setDefaultString(newDefaultString)
    }
  }, [defaultValue])

  return {
    defaultString,
    onChange,
    getCurrentString,
  }
}
