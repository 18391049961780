import { FC } from 'react'

import { useFormContext, useItems } from 'hooks'
import { confirmationText } from 'constant'

import { Spacer, Typography } from 'designSystem'
import { Card, ConfirmationPopover, FormItemEditSelectSingleAsyncGroup } from 'components'

import * as Styled from '../../styles'

export const EditGroupSKUs: FC = () => {
  const { watch, triggerSubmit } = useFormContext()
  const mainItemId = watch('id')

  return (
    <>
      <Styled.Divider />
      <Card bordered={false} pa={0}>
        <Typography fontWeight="xl" size="m">
          Grouped SKU
        </Typography>
        <Spacer mt={3} />
        <FormItemEditSelectSingleAsyncGroup
          name="groupedSKUs"
          label="Item SKU"
          disabledValues={[mainItemId]}
          onSubmit={triggerSubmit}
          getItems={useItems}
          popover={
            <ConfirmationPopover
              titleText="Confirmation Required"
              placement="bottomLeft"
              text={confirmationText.confirmationRequired}
              okText="Yes, Save"
              okType="primary"
            />
          }
        />
      </Card>
    </>
  )
}
