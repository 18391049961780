import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import { isAuthenticated } from 'api'
import { UserSettingsService } from 'services'
import { QueryKey } from 'enums'
import { useSetUser, useUser } from 'store'

import { getUserMe } from '../queryApi'
import { useGetMySettings } from './useGetMySettings'

interface UseGetMeProps {
  enabled?: boolean
}

export const useGetMe = ({ enabled }: UseGetMeProps) => {
  const isToken = isAuthenticated()
  const isLoggedIn = !!useUser()
  const setUserData = useSetUser()
  const [loading, setLoading] = useState(false)

  const queryEnabled = Boolean(enabled && isToken)

  const { data, isSuccess, isFetching, ...rest } = useQuery({
    queryKey: [QueryKey.UserMe],
    queryFn: getUserMe,
    enabled: queryEnabled,
  })

  const { isSuccess: isSettingsSuccess, data: settingsData } = useGetMySettings(isLoggedIn)

  useEffect(() => {
    if (isSuccess) {
      UserSettingsService.setUserSettings(data.settings)
      setUserData(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  useEffect(() => {
    if (isSettingsSuccess && settingsData) {
      UserSettingsService.setInitialTableSettings(settingsData)
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSettingsSuccess])

  useEffect(() => {
    if (queryEnabled && !isLoggedIn) {
      setLoading(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryEnabled])

  return {
    ...rest,
    isLoggedIn,
    isFetching: isFetching || loading,
  }
}
