import { Dispatch, SetStateAction } from 'react'
import { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form'

import { ItemType, PurchaseOrderItem, PurchaseOrderType } from 'api'
import { useItems } from 'hooks'
import { PurchaseOrderFormAccessor, PurchaseOrderItemAccessor } from 'types'
import { getFormattedPriceWithCurrency } from 'utils'

import { Container, EditFormItem, IconDelete, Typography } from 'designSystem'
import {
  EditableTableActions,
  FormItemEditInputNumber,
  FormItemEditSelectSingleAsync,
  TableColumnKey,
  TableColumnsType,
} from 'components'
import { getTableFieldName } from 'components/Table/elements/EditableTable/utils/getTableFieldName'
import { ItemAssets } from 'modules/purchaseOrders/types/itemAssets'
import { PurchaseOrderRow } from 'modules/purchaseOrders/types/purchaseOrderRow'
import { getAccessorItemTitle } from 'modules/purchaseOrders/utils/getAccessorItemTitle'

import { ChangeQuantity } from '../elements/ChangeQuantity'

import { getSkuDefaultOption } from './getSkuDefaultOption'

export type PurchaseOrderRowType = (Partial<PurchaseOrderItem> & { key: number; id: number }) | PurchaseOrderRow

interface PurchaseOrderItemsColumnsProps {
  onDelete: (index: number) => void
  setItemAssets: Dispatch<SetStateAction<ItemAssets>>
  setExpandedRowKeys: Dispatch<SetStateAction<(string | number)[] | undefined>>
  skuAsset: ItemAssets
  type: PurchaseOrderType | undefined
  isAddNew: boolean
  triggerSubmit?: () => void
  onCancelNew?: () => void
  isEditMode?: boolean
  itemsCount?: number
  allFieldsDisabled?: boolean
  watch: UseFormWatch<FieldValues>
  setValue: UseFormSetValue<FieldValues>
}

export const getTableColumns = ({
  onDelete,
  type,
  isAddNew,
  skuAsset,
  setExpandedRowKeys,
  setItemAssets,
  triggerSubmit,
  onCancelNew,
  isEditMode = false,
  itemsCount,
  watch,
  setValue,
}: PurchaseOrderItemsColumnsProps): TableColumnsType<PurchaseOrderRowType> => [
  {
    title: getAccessorItemTitle(PurchaseOrderItemAccessor.Index),
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => index + 1,
  },
  {
    title: getAccessorItemTitle(PurchaseOrderItemAccessor.Sku),
    key: PurchaseOrderItemAccessor.Sku,
    dataIndex: PurchaseOrderItemAccessor.Sku,
    width: 160,
    ellipsis: true,
    render: (title, record, index) => {
      const isEditDisabled = record.subLines?.some((subLine) => subLine.asset?.isBilled || subLine.asset?.isReceived)

      const defaultOption = getSkuDefaultOption(record, isAddNew, skuAsset)
      return (
        <FormItemEditSelectSingleAsync
          withFullDataOption
          fullwidth
          isCreateMode={!isEditMode || record.id === -1}
          name={getTableFieldName(
            PurchaseOrderFormAccessor.ItemId,
            PurchaseOrderFormAccessor.Item,
            PurchaseOrderFormAccessor.Items,
            index,
            isAddNew,
          )}
          size="small"
          onSubmit={isEditMode && !isAddNew ? triggerSubmit : undefined}
          disabled={isEditMode && isEditDisabled}
          defaultOption={defaultOption}
          getItems={useItems}
          queryParams={{ type: { $eq: ItemType.Assets } }}
          onChange={(value, option) => {
            if (option?.data) {
              const isContainAsset = option?.data?.type === ItemType.Assets
              if (isContainAsset) {
                setExpandedRowKeys((prev: (string | number)[] | undefined) => [...(prev ?? []), record.key])
                if (type !== PurchaseOrderType.Standard || isAddNew) {
                  const fieldRowName = isAddNew
                    ? PurchaseOrderFormAccessor.Item
                    : `${PurchaseOrderFormAccessor.Items}.${index}`

                  setValue(`${fieldRowName}.${PurchaseOrderFormAccessor.ItemBilledQty}`, 0)
                  setValue(`${fieldRowName}.${PurchaseOrderFormAccessor.ItemReceivedQty}`, 0)
                }
              }
              setItemAssets((prev) => ({
                ...prev,
                [record.id as number]: {
                  containAsset: isContainAsset,
                  name: option?.data?.name,
                  sku: option?.data?.sku,
                  id: option?.data?.id,
                },
              }))
            } else if (option === undefined) {
              setItemAssets((prev) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { [String(record.id)]: _, ...rest } = prev
                return rest
              })
            }
          }}
        />
      )
    },
  },
  {
    title: getAccessorItemTitle(PurchaseOrderItemAccessor.ItemName),
    key: PurchaseOrderItemAccessor.ItemName,
    dataIndex: PurchaseOrderItemAccessor.ItemName,
    ellipsis: true,
    width: 180,
    render: (value, record) => {
      const title = record.item?.name || skuAsset[record.id]?.name
      if (!title) return null
      return (
        <Container px={2}>
          <Typography lineEllipsis={1} fontWeight="l" size="xs">
            {title}
          </Typography>
        </Container>
      )
    },
  },
  {
    title: getAccessorItemTitle(PurchaseOrderItemAccessor.Qty),
    key: PurchaseOrderItemAccessor.Qty,
    dataIndex: PurchaseOrderItemAccessor.Qty,
    width: 120,
    ellipsis: true,
    render: (value, record, index) => (
      <ChangeQuantity index={index} isAddNew={isAddNew} isEditMode={isEditMode} record={record} />
    ),
  },
  {
    title: getAccessorItemTitle(PurchaseOrderItemAccessor.Rate),
    key: PurchaseOrderItemAccessor.Rate,
    dataIndex: PurchaseOrderItemAccessor.Rate,
    width: 100,
    render: (value, record, index) => {
      return (
        <EditFormItem
          name={getTableFieldName(
            PurchaseOrderFormAccessor.ItemRate,
            PurchaseOrderFormAccessor.Item,
            PurchaseOrderFormAccessor.Items,
            index,
            isAddNew,
          )}
          render={({ field }) => (
            <FormItemEditInputNumber
              {...field}
              size="small"
              align="left"
              placeholder="Rate"
              onBlur={isEditMode && !isAddNew ? triggerSubmit : undefined}
            />
          )}
        />
      )
    },
  },
  {
    title: getAccessorItemTitle(PurchaseOrderItemAccessor.ReceivedQty),
    key: PurchaseOrderItemAccessor.ReceivedQty,
    dataIndex: PurchaseOrderItemAccessor.ReceivedQty,
    width: 140,
    render: (value, record, index) => {
      const isDisabledInput =
        type !== PurchaseOrderType.Standard && (skuAsset[record.id]?.containAsset || !!record.subLines?.length)
      return (
        <EditFormItem
          name={getTableFieldName(
            PurchaseOrderFormAccessor.ItemReceivedQty,
            PurchaseOrderFormAccessor.Item,
            PurchaseOrderFormAccessor.Items,
            index,
            isAddNew,
          )}
          render={({ field }) => (
            <FormItemEditInputNumber
              {...field}
              size="small"
              align="left"
              disabled={isDisabledInput}
              placeholder="Received Qty"
              onBlur={isEditMode && !isAddNew ? triggerSubmit : undefined}
            />
          )}
        />
      )
    },
  },
  {
    title: getAccessorItemTitle(PurchaseOrderItemAccessor.BilledQty),
    key: PurchaseOrderItemAccessor.BilledQty,
    dataIndex: PurchaseOrderItemAccessor.BilledQty,
    width: 100,
    render: (value, record, index) => {
      const isDisabledInput =
        type !== PurchaseOrderType.Standard && (skuAsset[record.id]?.containAsset || !!record.subLines?.length)
      return (
        <EditFormItem
          name={getTableFieldName(
            PurchaseOrderFormAccessor.ItemBilledQty,
            PurchaseOrderFormAccessor.Item,
            PurchaseOrderFormAccessor.Items,
            index,
            isAddNew,
          )}
          render={({ field }) => (
            <FormItemEditInputNumber
              {...field}
              size="small"
              align="left"
              disabled={isDisabledInput}
              placeholder="Billed Qty"
              onBlur={isEditMode && !isAddNew ? triggerSubmit : undefined}
            />
          )}
        />
      )
    },
  },
  {
    title: getAccessorItemTitle(PurchaseOrderItemAccessor.TotalAmount),
    key: PurchaseOrderItemAccessor.TotalAmount,
    dataIndex: PurchaseOrderItemAccessor.TotalAmount,
    width: 120,
    render: (value, record, index) => {
      const isNewRow = record.id === -1
      let totalAmount
      if (isEditMode && !isNewRow) {
        totalAmount = record.totalAmount
      } else {
        const currentItemQty = watch?.(
          isNewRow
            ? `${PurchaseOrderFormAccessor.Item}.${PurchaseOrderFormAccessor.ItemQty}`
            : `${PurchaseOrderFormAccessor.Items}.${index}.${PurchaseOrderFormAccessor.ItemQty}`,
        )
        const currentItemRate = watch?.(
          isNewRow
            ? `${PurchaseOrderFormAccessor.Item}.${PurchaseOrderFormAccessor.ItemRate}`
            : `${PurchaseOrderFormAccessor.Items}.${index}.${PurchaseOrderFormAccessor.ItemRate}`,
        )
        totalAmount = (currentItemQty || 0) * (currentItemRate || 0)
      }
      return (
        <Container px={2}>
          <Typography fontWeight="xl" size="xs">
            {getFormattedPriceWithCurrency(totalAmount)}
          </Typography>
        </Container>
      )
    },
  },
  {
    key: TableColumnKey.HoverActions,
    width: isAddNew && isEditMode ? 120 : 30,
    render: (value, record, index) => {
      if (isEditMode) {
        const disableDelete = record.subLines?.some((subLine) => subLine.asset?.isBilled || subLine.asset?.isReceived)
        if (disableDelete) return null
        return (
          <EditableTableActions
            name={PurchaseOrderFormAccessor.Items}
            size="small"
            isAddNew={isAddNew}
            index={index}
            icon={<IconDelete />}
            onCancelNew={onCancelNew}
            onDelete={() => onDelete(record.id as number)}
          />
        )
      }
      return itemsCount === 1 ? null : (
        <EditableTableActions
          name={PurchaseOrderFormAccessor.Items}
          icon={<IconDelete />}
          index={index}
          size="small"
          onDelete={() => onDelete(index)}
        />
      )
    },
  },
]
