import { useCallback, useEffect, useState } from 'react'

export const useDebounce = <T>(value: T, delay?: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)
    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])
  return debouncedValue
}

export const useDebouncedValue = (valueControlled?: string, onChange?: (params?: any) => void) => {
  const [value, setValue] = useState(valueControlled)
  const debouncedValue = useDebounce(value)

  const handleChange = useCallback((value?: string) => {
    setValue(value)
  }, [])

  useEffect(() => {
    onChange?.(debouncedValue)
  }, [debouncedValue, onChange])

  return {
    value,
    handleChange,
    setValue,
  }
}
