import { userManagementRoute } from 'constant'

import { NotFoundPage } from 'components'

import { CreateUser } from '../routes/CreateUser'
import { UserView } from '../routes/UserView'

export const userManagementRoutes = [
  {
    path: userManagementRoute.view,
    element: <UserView />,
  },
  {
    path: userManagementRoute.create,
    element: <CreateUser />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
