import { ReferenceNumberType } from 'api'

import {
  bookingItemsValidationSchema,
  pickupItemsValidationSchema,
  redeliveryItemsValidationSchema,
  vendorReleaseItemsValidationSchema,
} from 'modules/references/constants/fieldsValidation'

export const getFormPropsByReferenceType = (type?: ReferenceNumberType) => {
  switch (type) {
    case ReferenceNumberType.VendorRelease: {
      return {
        validationSchema: vendorReleaseItemsValidationSchema,
        canChangeQty: false,
        isFieldViewOnly: (field: string) => ['itemId', 'qty', 'assetId'].includes(field),
      }
    }
    case ReferenceNumberType.Booking: {
      return {
        validationSchema: bookingItemsValidationSchema,
        isFieldViewOnly: (field: string) => ['itemId'].includes(field),
      }
    }
    case ReferenceNumberType.Pickup: {
      return {
        validationSchema: pickupItemsValidationSchema,
        isFieldViewOnly: (field: string) => ['itemId'].includes(field),
      }
    }
    default: {
      return {
        validationSchema: redeliveryItemsValidationSchema,
        canChangeQty: false,
        isFieldViewOnly: (field: string) => ['itemId', 'qty', 'assetId'].includes(field),
      }
    }
  }
}
