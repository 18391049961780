import { Drawer as DrawerBase } from 'antd'
import { styled } from 'styled-components'

export const Drawer = styled(DrawerBase)`
  .ant-drawer-wrapper-body {
    background-color: ${({ theme }) => theme.colors.underline};
  }
  .ant-drawer-header {
    border-bottom: 0;
    padding: ${({ theme }) => theme.spacers[4]};
  }
  .ant-drawer-body {
    padding: 0;
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    align-items: flex-start;

    .ant-drawer-close {
      margin-right: 0;
      margin-left: ${({ theme }) => theme.spacers[3]};
    }
  }
  .ant-drawer-close {
    margin-top: ${({ theme }) => theme.spacers[2]};
  }
`
