import { FC, useCallback, useState } from 'react'
import { Col, Row } from 'antd'

import { ItemCategory, ItemCategoryType } from 'api'
import { useIsUserAdmin, useIsUserSuperAdmin } from 'store'
import theme from 'styles/theme'

import { Button, Container, Icon, Typography } from 'designSystem'
import { Droppable } from 'components'

import * as Styled from './styles'

import { CategoryForm } from '../../../../../CategoryForm'
import { DeleteCategory } from '../DeleteCategory'
import { FormName } from '../FormName'
import { SingleCategory } from '../SingleCategory'
interface CategoryGroupProps {
  item: ItemCategory
}

export const CategoryGroup: FC<CategoryGroupProps> = ({ item }) => {
  const { children } = item
  const [showChildren, setShowChildren] = useState(false)
  const [showCategoryForm, setShowCategoryForm] = useState(false)
  const isAdmin = useIsUserAdmin()
  const isSuperAdmin = useIsUserSuperAdmin()

  const toggleShowChildren = useCallback(() => {
    if (item.type === ItemCategoryType.Folder) {
      setShowChildren((prev) => !prev)
    }
  }, [item.type])

  const handleShowCategoryForm = useCallback(() => {
    setShowCategoryForm(true)
    setShowChildren(true)
  }, [])

  return (
    <Container fd="column">
      <Droppable id={item.id} data={item}>
        <Styled.Content showCategoryForm={showCategoryForm}>
          <Row justify="space-between" align="middle" style={{ width: '100%' }} wrap={true}>
            <Col span={15} onClick={toggleShowChildren}>
              <Container ai="center">
                <Icon icon="folder" color={theme.colors.secondary} />
                <Container style={{ minWidth: 0, flex: 1 }} ml={1}>
                  <FormName
                    item={item}
                    isFieldViewOnly={!isAdmin || !isSuperAdmin}
                    icon={
                      <Icon
                        icon={showChildren ? 'caretUp' : 'caretDown'}
                        color={theme.colors.secondary}
                        size={14}
                        onClick={toggleShowChildren}
                      />
                    }
                  />
                </Container>
              </Container>
            </Col>
            {(isAdmin || isSuperAdmin) && (
              <Col span={9}>
                <Container ai="center" gap={4} jc="flex-end">
                  <Button icon="plus" type="link" onClick={handleShowCategoryForm}>
                    Add category
                  </Button>
                  <DeleteCategory item={item} />
                </Container>
              </Col>
            )}
          </Row>
        </Styled.Content>
      </Droppable>

      {showCategoryForm && (
        <CategoryForm
          parentId={item.id}
          categoryType={ItemCategoryType.Single}
          setShowCategoryForm={setShowCategoryForm}
        />
      )}
      {showChildren && (
        <>
          {children?.length ? (
            item.children?.map((child) => (
              <Container display="block" key={child.id} pl={4}>
                <SingleCategory item={child} />
              </Container>
            ))
          ) : (
            <Droppable id={item.id} data={item}>
              <Container py={8} jc="center">
                <Typography color="placeholder" fontWeight="l">
                  There no categories inside this group
                </Typography>
              </Container>
            </Droppable>
          )}
        </>
      )}
    </Container>
  )
}
