export const fontSizeOptions = [
  {
    title: '10px',
    model: '10px',
  },
  {
    title: '12px',
    model: '12px',
  },
  {
    title: '14px',
    model: '14px',
  },
  {
    title: '16px',
    model: '16px',
  },
  {
    title: '18px',
    model: '18px',
  },
  {
    title: '20px',
    model: '20px',
  },
]
