import { FC, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReferenceNumberStatus, ReferenceNumberType } from 'api'
import { useForm } from 'hooks'
import { getReferenceTypeOptionLabel, route } from 'constant'

import { Button, Container, Divider, Typography } from 'designSystem'
import { NewAddress, NewContact } from 'components'
import { PickupNumberForm } from 'modules/references/types'

import { useCreatePickupNumber } from './hooks/useCreatePickupNumber'
import { pickupFieldsValidation } from 'modules/references/constants/fieldsValidation'
import { PickupContacts } from '../../types/pickupContacts'
import { PickupAddContact } from './elements/PickupAddContact'
import { PickupNumberDetails } from './elements/PickupNumberDetails'
import { ReferenceItems } from 'modules/references/elements/ReferenceItems'

import { ReferenceNumberDetailsFields } from '../ReferenceNumberDetailsFields'

export const PickupNumber: FC = () => {
  const navigate = useNavigate()
  const title = getReferenceTypeOptionLabel(ReferenceNumberType.Pickup)

  const [newContacts, setNewContacts] = useState<NewContact[]>([])
  const [newAddresses, setNewAddresses] = useState<NewAddress[]>([])
  const { create, isPending } = useCreatePickupNumber()

  const { Form, handleSubmit } = useForm<Omit<PickupNumberForm, 'contacts'> & PickupContacts>({
    mode: 'onChange',
    validationSchema: pickupFieldsValidation,
    defaultValues: {
      type: ReferenceNumberType.Pickup,
      status: ReferenceNumberStatus.Open,
      items: [{}],
    },
  })

  const onClickCancel = useCallback(() => {
    navigate(route.referencesCreateChooseType)
  }, [navigate])

  const handleFormSubmit = useCallback(
    (payload: Omit<PickupNumberForm, 'contacts'> & PickupContacts) => {
      const { contacts } = payload

      const payloadContacts = (contacts || []).map((value) => {
        const isCustomerContact = typeof value === 'number'
        return isCustomerContact
          ? {
              customerContactId: value,
            }
          : (newContacts.find(({ email }) => email === value) as NewContact)
      })

      create({ ...payload, contacts: payloadContacts })
    },
    [create, newContacts],
  )

  return (
    <Form onSubmit={handleSubmit(handleFormSubmit)}>
      <Container pa={4} ai="center" jc="space-between" gap={8} minHeight={64}>
        <Typography size="l" fontWeight="xl">
          Create {title} Number
        </Typography>
        <PickupAddContact contacts={newContacts} setContacts={setNewContacts} />
      </Container>
      <Container pa={4} bg="white" fd="column">
        <Container mb={9}>
          <Container fd="column" width="100%">
            <Typography size="m" fontWeight="xl">
              {title} Details
            </Typography>
            <ReferenceNumberDetailsFields
              type={ReferenceNumberType.Pickup}
              setNewAddresses={setNewAddresses}
              newAddresses={newAddresses}
            />
          </Container>
          <Divider vertical mx={4} />
          <Container fd="column" width="100%" gap={16}>
            <Typography size="m" fontWeight="xl">
              Related Records
            </Typography>
          </Container>
          <Divider vertical mx={4} />
          <PickupNumberDetails newContacts={newContacts} setNewContacts={setNewContacts} />
        </Container>
        <ReferenceItems type={ReferenceNumberType.Pickup} />
      </Container>
      <Container pa={4} jc="space-between" gap={8}>
        <Button onClick={onClickCancel}>Cancel</Button>
        <Button type="primary" htmlType="submit" loading={isPending}>
          Save
        </Button>
      </Container>
    </Form>
  )
}
