import { PurchaseOrderItem } from 'api'

export type SubLineCounts = {
  totalBilledSubLines: number
  totalReceivedSubLines: number
  totalSubLines: number
}

export const getSubLineCounts = (data: PurchaseOrderItem[] | undefined): SubLineCounts => {
  const initialCounts: SubLineCounts = {
    totalBilledSubLines: 0,
    totalReceivedSubLines: 0,
    totalSubLines: 0,
  }
  return (
    data?.reduce<SubLineCounts>((acc, item: PurchaseOrderItem) => {
      item.subLines?.forEach((subLine) => {
        if (subLine.asset?.isBilled) {
          acc.totalBilledSubLines += 1
        }

        if (subLine.asset?.isReceived) {
          acc.totalReceivedSubLines += 1
        }

        acc.totalSubLines += 1
      })
      return acc
    }, initialCounts) || initialCounts
  )
}
