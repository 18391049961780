import {
  cloneElement,
  FC,
  FocusEvent,
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { RefSelectProps } from 'antd/lib/select'
import isEqual from 'react-fast-compare'

import { useFirstRender, useFormContext } from 'hooks'
import { typedMemo } from 'types'

import type { SelectSingleOption, SelectSingleProps } from 'designSystem'
import { Container, EditSelectSingle, EditView } from 'designSystem'

import * as Styled from './styles'

export interface FormItemEditSelectSingleProps extends Omit<SelectSingleProps, 'name'> {
  name: string
  href?: string
  nestedName?: string
  customPreview?: ReactNode
  defaultOption?: SelectSingleOption
  onSubmit?: (value?: string | number | null, option?: SelectSingleOption) => void
  fullwidth?: boolean
  isCreateMode?: boolean
  disableOnChange?: boolean
  // view props
  isFieldViewOnly?: boolean
  lineEllipsis?: number
  nowrap?: boolean
  popover?: ReactElement
}

export const FormItemEditSelectSingleBase: FC<FormItemEditSelectSingleProps> = forwardRef(
  (
    {
      disabled,
      size = 'middle',
      href,
      onBlur,
      onSubmit,
      name,
      nestedName,
      value,
      onChange,
      onSearch,
      customPreview,
      placement = 'bottomRight',
      defaultOption,
      width = 150,
      fullwidth,
      returnDefaultValueOnBlur = true,
      isCreateMode,
      options,
      loading,
      isFieldViewOnly,
      lineEllipsis,
      nowrap,
      disableOnChange = false,
      popover,
      ...props
    },
    ref: Ref<RefSelectProps>,
  ) => {
    const isFirstRender = useFirstRender()
    const [isEdit, setIsEdit] = useState(false)
    const [selectedOption, setSelectedOption] = useState(defaultOption)
    const [openPopover, setOpenPopover] = useState(false)
    const prevSelectedOption = useRef<SelectSingleOption | undefined>(undefined)

    const handleOpenPopover = useCallback(() => {
      setOpenPopover(true)
    }, [])

    const handleClosePopover = useCallback(() => {
      setOpenPopover(false)
      popover?.props?.onCancel?.()
      if (prevSelectedOption.current && !isEqual(prevSelectedOption.current, selectedOption)) {
        setSelectedOption(prevSelectedOption.current)
        onChange?.(prevSelectedOption.current?.value, prevSelectedOption.current)
      }

      prevSelectedOption.current = undefined
    }, [popover?.props, onChange, selectedOption, prevSelectedOption])

    const { getFieldState } = useFormContext()
    const error = getFieldState(nestedName ? `${name}.${nestedName}` : name)?.error?.message

    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        if (value === defaultOption?.value) {
          if (onSearch) {
            setSelectedOption(defaultOption)
          }
          onChange?.(defaultOption?.value, defaultOption)
        }
        setIsEdit(false)
        onBlur?.(event)
      },
      [onBlur, value, defaultOption, onChange, onSearch],
    )

    const handleViewClick = useCallback(() => {
      setIsEdit(true)
    }, [setIsEdit])

    const handleChange = useCallback(
      (currentValue?: string | number | null, option?: SelectSingleOption) => {
        if (popover) {
          handleOpenPopover()
          prevSelectedOption.current = defaultOption ? defaultOption : options?.find((option) => option.value === value)
          setIsEdit(false)
          onChange?.(currentValue, option)
          setSelectedOption(option)
          return
        }

        if (disableOnChange) {
          onSubmit?.(currentValue, option)
          setIsEdit(false)
        } else {
          if (onSearch) {
            setSelectedOption(option)
          }
          onChange?.(currentValue, option)

          if (currentValue !== undefined) {
            onSubmit?.(currentValue, option)
            setIsEdit(false)
          }
        }
      },
      [onChange, onSubmit, disableOnChange, onSearch, popover, handleOpenPopover, options, defaultOption, value],
    )

    useEffect(() => {
      if (!!onSearch && !isFirstRender && value !== selectedOption?.value && !value) {
        setSelectedOption(undefined)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
      if (!!onSearch && !isEqual(defaultOption, selectedOption)) {
        setSelectedOption(defaultOption)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultOption])

    useEffect(() => {
      if (!!onSearch && isEdit && !isCreateMode) {
        setSelectedOption(undefined)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit, isCreateMode])

    const handleClear = useCallback(() => {
      if (popover) {
        handleOpenPopover()
        prevSelectedOption.current = undefined
        setSelectedOption(undefined)
        return
      }
      if (!disableOnChange) {
        onChange?.(null, undefined)
      }

      onSubmit?.(null, undefined)
    }, [onChange, onSubmit, disableOnChange, handleOpenPopover, popover])

    // For not async list we can take value from the options
    const viewValue =
      onSearch && value
        ? selectedOption?.label
        : options?.find(({ value: optionValue }) => optionValue === value)?.label

    const onPopoverConfirm = useCallback(() => {
      if (error) return

      onChange?.(selectedOption?.value, selectedOption)
      onSubmit?.(selectedOption?.value, selectedOption)
      setOpenPopover(false)
    }, [error, onChange, onSubmit, selectedOption])

    const onOpenChange = useCallback(
      (isOpen: boolean) => {
        setOpenPopover(isOpen)
        if (!isOpen) {
          handleClosePopover()
        }
      },
      [handleClosePopover],
    )

    return (
      <Styled.Wrapper $fullwidth={fullwidth} $isLinkAndViewOnly={isFieldViewOnly && !!href && !!value}>
        {isEdit ? (
          <EditSelectSingle
            {...props}
            options={loading && selectedOption && !options?.length ? [selectedOption] : options}
            loading={loading}
            status={error ? 'error' : undefined}
            defaultOption={defaultOption}
            size={size}
            onChange={handleChange}
            onSearch={onSearch}
            popupMatchSelectWidth
            name={name}
            placement={placement}
            value={onSearch ? selectedOption?.value : value}
            error={error}
            onBlur={handleBlur}
            ref={ref}
            disabled={disabled}
            width={fullwidth ? undefined : width}
            returnDefaultValueOnBlur={returnDefaultValueOnBlur}
          />
        ) : (
          <Container>
            {customPreview ? (
              <Styled.CustomPreview
                disabled={disabled}
                onClick={() => {
                  !disabled && handleViewClick()
                }}
              >
                {customPreview}
              </Styled.CustomPreview>
            ) : (
              <EditView
                as={href ? 'select-link' : 'select'}
                withEdit={!!href && !isFieldViewOnly}
                href={href && value ? `${href}/${value}` : undefined}
                error={!!error}
                size={size}
                disabled={isFieldViewOnly ? false : disabled}
                value={viewValue}
                onClick={handleViewClick}
                onClear={!isFieldViewOnly && !returnDefaultValueOnBlur && viewValue ? handleClear : undefined}
                lineEllipsis={lineEllipsis}
                nowrap={nowrap}
              />
            )}
          </Container>
        )}
        {popover &&
          cloneElement(popover, {
            ...popover.props,
            open: openPopover || popover.props.open,
            onCancel: handleClosePopover,
            disabled: !!error,
            onOk: onPopoverConfirm,
            onOpenChange,
          })}
      </Styled.Wrapper>
    )
  },
)

export const FormItemEditSelectSingle = typedMemo(FormItemEditSelectSingleBase)
