export enum AppEvent {
  AssetCreate = 'ASSET__CREATE',
  AssetUpdate = 'ASSET__UPDATE',
  AssetDelete = 'ASSET__DELETE',
  AssetCodeCreate = 'ASSET_CODE__CREATE',
  AssetCodeUpdate = 'ASSET_CODE__UPDATE',
  AssetCodeDelete = 'ASSET_CODE__DELETE',
  LocationCodeCreate = 'LOCATION_CODE__CREATE',
  LocationCodeUpdate = 'LOCATION_CODE__UPDATE',
  LocationCodeDelete = 'LOCATION_CODE__DELETE',
  TruckCodeCreate = 'TRUCK_CODE__CREATE',
  TruckCodeUpdate = 'TRUCK_CODE__UPDATE',
  TruckCodeDelete = 'TRUCK_CODE__DELETE',
  UserCreate = 'USER__CREATE',
  UserUpdate = 'USER__UPDATE',
  UserDelete = 'USER__DELETE',
  ItemCreate = 'ITEM__CREATE',
  ItemUpdate = 'ITEM__UPDATE',
  ItemDelete = 'ITEM__DELETE',
  CategoryUpdate = 'CATEGORY__UPDATE',
  VendorCreate = 'VENDOR__CREATE',
  VendorUpdate = 'VENDOR__UPDATE',
  VendorDelete = 'VENDOR__DELETE',
  VendorContactCreate = 'VENDOR_CONTACT__CREATE',
  VendorContactUpdate = 'VENDOR_CONTACT__UPDATE',
  VendorContactDelete = 'VENDOR_CONTACT__DELETE',
  VendorCarrierDetailsUpdate = 'VENDOR_CARRIER_DETAILS__UPDATE',
  VendorCarrierLocationCreate = 'VENDOR_CARRIER_LOCATION__CREATE',
  VendorCarrierLocationUpdate = 'VENDOR_CARRIER_LOCATION__UPDATE',
  VendorCarrierLocationDelete = 'VENDOR_CARRIER_LOCATION__DELETE',
  VendorUploadModifications = 'VENDOR_MODIFICATIONS__UPLOAD',
  VendorContainerInventoryUpload = 'VENDOR_CONTAINER_SUPPLIER_INVENTORY__UPLOAD',
  VendorPartsInventoryUpload = 'VENDOR_PARTS_INVENTORY__UPLOAD',
  VendorModificationFeed = 'VENDOR_MODIFICATION_FEED',
  EquipmentCreate = 'EQUIPMENT__CREATE',
  EquipmentUpdate = 'EQUIPMENT__UPDATE',
  EquipmentDelete = 'EQUIPMENT__DELETE',
  ResaleRuleContainerVendorDelete = 'RESALE_RULE_CONTAINER_SUPPLIER__DELETE',
  ResaleRulePartsDelete = 'RESALE_RULE_PARTS__DELETE',
  ResaleRuleContainerVendorCreate = 'RESALE_RULE_CONTAINER_SUPPLIER__CREATE',
  ResaleRulePartsCreate = 'RESALE_RULE_PARTS__CREATE',
  ResaleRuleContainerVendorUpdate = 'RESALE_RULE_CONTAINER_SUPPLIER__UPDATE',
  ResaleRulePartsUpdate = 'RESALE_RULE_PARTS__UPDATE',
  DataMappingCreate = 'DATA_MAPPING__CREATE',
  DataMappingUpdate = 'DATA_MAPPING__UPDATE',
  DataMappingDelete = 'DATA_MAPPING__DELETE',
  AssetRepairCreate = 'ASSET_REPAIR__CREATE',
  AssetRepairUpdate = 'ASSET_REPAIR__UPDATE',
  AssetRepairDelete = 'ASSET_REPAIR__DELETE',
  ModificationCreate = 'MODIFICATION__CREATE',
  ModificationUpdate = 'MODIFICATION__UPDATE',
  ModificationDelete = 'MODIFICATION__DELETE',
  InventoryContainerVendorDelete = 'INVENTORY_CONTAINER_SUPPLIER__DELETE',
  InventoryPartsDelete = 'INVENTORY_PARTS__DELETE',
  InventoryContainerVendorCreate = 'INVENTORY_CONTAINER_SUPPLIER__CREATE',
  InventoryPartsCreate = 'INVENTORY_PARTS__CREATE',
  InventoryContainerVendorUpdate = 'INVENTORY_CONTAINER_SUPPLIER__UPDATE',
  InventoryPartsUpdate = 'INVENTORY_PARTS__UPDATE',
  VendorDepotUpdate = 'VENDOR_DEPOT__UPDATE',
  WarehouseCreate = 'WAREHOUSE__CREATE',
  WarehouseUpdate = 'WAREHOUSE__UPDATE',
  WarehouseDelete = 'WAREHOUSE__DELETE',
  VendorCarrierPriceListCreate = 'VENDOR_CARRIER_PRICE_LIST__CREATE',
  VendorCarrierPriceListUpdate = 'VENDOR_CARRIER_PRICE_LIST__UPDATE',
  VendorCarrierPriceListDelete = 'VENDOR_CARRIER_PRICE_LIST__DELETE',
  PurchaseOrderCreated = 'PURCHASE_ORDER__CREATE',
  PurchaseOrderUpdated = 'PURCHASE_ORDER__UPDATE',
  PurchaseOrderDeleted = 'PURCHASE_ORDER__DELETE',
  PurchaseOrderItemDeleted = 'PURCHASE_ORDER_ITEM__DELETE',
  PurchaseOrderItemCreated = 'PURCHASE_ORDER_ITEM__CREATE',
  PurchaseOrderItemUpdated = 'PURCHASE_ORDER_ITEM__UPDATE',
  PurchaseOrderSubLineDeleted = 'PURCHASE_ORDER_SUB_LINE__DELETE',
  CustomerCreate = 'CUSTOMER__CREATE',
  CustomerUpdate = 'CUSTOMER__UPDATE',
  CustomerDeleted = 'CUSTOMER__DELETE',
  CustomerContactCreate = 'CUSTOMER_CONTACT__CREATE',
  CustomerContactUpdate = 'CUSTOMER_CONTACT__UPDATE',
  CustomerContactDelete = 'CUSTOMER_CONTACT__DELETE',
  CustomerShippingAddressCreate = 'CUSTOMER_SHIPPING_ADDRESS__CREATE',
  CustomerShippingAddressUpdate = 'CUSTOMER_SHIPPING_ADDRESS__UPDATE',
  CustomerShippingAddressDelete = 'CUSTOMER_SHIPPING_ADDRESS__DELETE',
  CustomerUccAddressCreate = 'CUSTOMER_UCC_ADDRESS__CREATE',
  CustomerUccAddressUpdate = 'CUSTOMER_UCC_ADDRESS__UPDATE',
  CustomerUccAddressDelete = 'CUSTOMER_UCC_ADDRESS__DELETE',
  PurchaseOrderCreateContact = 'PURCHASE_ORDER_CONTACT__CREATE',
  PurchaseOrderDeleteContact = 'PURCHASE_ORDER_CONTACT__DELETE',
  PurchaseOrderPostContactFromVendorToPO = 'PURCHASE_ORDER_CONTACT__CREATE_FROM_VENDOR',
  PurchaseOrderPatchAssetNumber = 'PURCHASE_ORDER__PATCH_ASSET_NUMBER',
  ReferenceNumberCreate = 'REFERENCE_NUMBER__CREATE',
  ReferenceNumberUpdate = 'REFERENCE_NUMBER__UPDATE',
  ReferenceNumberDelete = 'REFERENCE_NUMBER__DELETE',
  ReferenceNumberPatchAssetNumber = 'REFERENCE_NUMBER__PATCH_ASSET_NUMBER',
  ReferenceNumberPickupContactsUpdate = 'REFERENCE_NUMBER__PICKUP_CONTACTS__UPDATE',
  ReferenceNumberItemsUpdate = 'REFERENCE_NUMBER__ITEMS__UPDATE',
  SalesOrderCreated = 'SALES_ORDER__CREATE',
}
