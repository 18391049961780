import { TagType } from 'designSystem/components/Tag'

import { UserStatus } from 'api/types/user'

interface UserStatusTagData {
  status: TagType
  text: string
}

export const getTagDataByUserStatus = (status: UserStatus): UserStatusTagData => {
  switch (status) {
    case UserStatus.Active:
      return {
        status: TagType.Success,
        text: 'Active',
      }
    case UserStatus.Inactive:
      return {
        status: TagType.Disabled,
        text: 'Inactive',
      }
    default:
      return {
        status: TagType.Default,
        text: 'Invited',
      }
  }
}
