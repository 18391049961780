import { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form'

import { AssetNumberType, EntityId, PurchaseOrderItemsSubLine, PurchaseOrderType } from 'api'
import { useDepotCodes, useLocationCodes } from 'hooks'
import { route } from 'constant'
import { PurchaseOrderFormAccessor, PurchaseOrderSubLineAccessor } from 'types'
import { isDateBeforeCurrentDateEndOfDay } from 'utils'
import { OptionsById, PurchaseOrderOptionType } from 'store'

import { Checkbox, Container, EditFormItem, FormItem, IconDelete, SelectSingleOption } from 'designSystem'
import {
  EditableTableActions,
  FormItemCheckbox,
  FormItemEditDatePicker,
  FormItemEditInput,
  FormItemEditSelectSingleAsync,
  PopoverForBillNumber,
  TableColumnKey,
  TableColumnsType,
} from 'components'

import { PurchaseOrderSubLineRow } from '../types'
import { ReferenceNumber } from '../elements/ReferenceNumber'
import { ReferenceType } from '../elements/ReferenceType'
import { ResetAssetNumberButton } from '../elements/ResetAssetNumberButton'

import { getAccessorSubLineTitle } from './getAccessorSubLineTitle'
import { getTableExpandableFieldName } from './getTableExpandableFieldName'

interface PurchaseOrderSubLineColumnsProps {
  onDelete: (id: number) => void
  rowSubLinesFieldName: string
  setValue: UseFormSetValue<FieldValues>
  locationOptionsById?: OptionsById
  depotOptionsById?: OptionsById
  isEditMode?: boolean
  triggerSubmit?: () => void
  setOptionsById: (optionType: PurchaseOrderOptionType, data?: SelectSingleOption) => void
  watch: UseFormWatch<FieldValues>
  onChangeIsBilled?: (name: string) => void
  vendorId?: EntityId
  poType?: PurchaseOrderType
  itemId?: EntityId
  allFieldsDisabled?: boolean
  isAddNew?: boolean
}

export const getTableExpandableColumns = ({
  onDelete,
  rowSubLinesFieldName,
  setValue,
  locationOptionsById,
  depotOptionsById,
  isEditMode,
  triggerSubmit,
  setOptionsById,
  watch,
  vendorId,
  onChangeIsBilled,
  poType,
  itemId,
  allFieldsDisabled,
  isAddNew,
}: PurchaseOrderSubLineColumnsProps): TableColumnsType<
  Partial<PurchaseOrderItemsSubLine> | PurchaseOrderSubLineRow
> => [
  {
    title: getAccessorSubLineTitle(PurchaseOrderSubLineAccessor.ExpectedDeliveryDate),
    key: PurchaseOrderSubLineAccessor.ExpectedDeliveryDate,
    dataIndex: PurchaseOrderSubLineAccessor.ExpectedDeliveryDate,
    width: 120,
    render: (value, record, index) => {
      const isFieldDisabled = isEditMode && !!record.asset?.isReceived
      return (
        <EditFormItem
          name={`${rowSubLinesFieldName}.${index}.${PurchaseOrderFormAccessor.SubLineExpectedDeliveryDate}`}
          render={({ field }) => (
            <FormItemEditDatePicker
              {...field}
              disabledDate={isDateBeforeCurrentDateEndOfDay}
              align="left"
              size="small"
              disabled={isFieldDisabled}
              onBlur={triggerSubmit}
            />
          )}
        />
      )
    },
  },
  {
    title: getAccessorSubLineTitle(PurchaseOrderSubLineAccessor.LocationCodeId),
    key: PurchaseOrderSubLineAccessor.LocationCodeId,
    dataIndex: PurchaseOrderSubLineAccessor.LocationCodeId,
    width: 150,
    ellipsis: true,
    render: (value, record, index) => {
      const isFieldDisabled = isEditMode && !!record.asset?.isReceived
      const currentLocationId = (record as PurchaseOrderSubLineRow).locationCodeId
      let defaultOption: SelectSingleOption | undefined

      if (record.locationCode) {
        defaultOption = {
          value: record.locationCode.id,
          label: record.locationCode.code,
        }
      } else if (currentLocationId && locationOptionsById?.[currentLocationId]?.label) {
        defaultOption = {
          value: currentLocationId,
          label: locationOptionsById[currentLocationId].label,
        }
      }

      return (
        <FormItemEditSelectSingleAsync
          size="small"
          fullwidth
          returnDefaultValueOnBlur={false}
          disabled={isFieldDisabled}
          name={`${rowSubLinesFieldName}.${index}.${PurchaseOrderFormAccessor.LocationCodeId}`}
          onSubmit={triggerSubmit}
          onChange={(value, option) => {
            setOptionsById?.('locations', option)
            setValue(`${rowSubLinesFieldName}.${index}.${PurchaseOrderFormAccessor.SubLineVendorDepotId}`, null)
          }}
          defaultOption={defaultOption}
          nestedEntityId={vendorId}
          getItems={useLocationCodes}
        />
      )
    },
  },
  {
    title: getAccessorSubLineTitle(PurchaseOrderSubLineAccessor.VendorDepotId),
    key: PurchaseOrderSubLineAccessor.VendorDepotId,
    dataIndex: PurchaseOrderSubLineAccessor.VendorDepotId,
    width: 120,
    render: (value, record, index) => {
      const currentLocation = watch?.(`${rowSubLinesFieldName}.${index}.${PurchaseOrderFormAccessor.LocationCodeId}`)
      const isFieldDisabled = (isEditMode && record.asset?.isReceived) || !currentLocation
      const currentVendorDepotId = (record as PurchaseOrderSubLineRow).vendorDepotId
      let defaultOption: SelectSingleOption | undefined

      if (record.vendorDepot) {
        defaultOption = {
          value: record.vendorDepot.id,
          label: record.vendorDepot.code,
        }
      } else if (currentVendorDepotId && depotOptionsById?.[currentVendorDepotId]?.label) {
        defaultOption = {
          value: currentVendorDepotId,
          label: depotOptionsById[currentVendorDepotId].label,
        }
      }

      return (
        <Container display="block" maxWidth={'100%'}>
          <FormItemEditSelectSingleAsync
            size="small"
            fullwidth
            returnDefaultValueOnBlur={false}
            name={`${rowSubLinesFieldName}.${index}.${PurchaseOrderFormAccessor.SubLineVendorDepotId}`}
            placeholder={currentLocation ? 'Start Typing' : 'Select location first'}
            onSubmit={triggerSubmit}
            disabled={isFieldDisabled || !vendorId}
            queryParams={{ ...(currentLocation && { locationCodeId: { $eq: currentLocation } }) }}
            defaultOption={defaultOption}
            getItems={useDepotCodes}
          />
        </Container>
      )
    },
  },
  // only available on view mode
  {
    title: getAccessorSubLineTitle(PurchaseOrderSubLineAccessor.AssetNumber),
    key: PurchaseOrderSubLineAccessor.AssetNumber,
    dataIndex: PurchaseOrderSubLineAccessor.AssetNumber,
    width: 170,
    render: (value, record, index) => {
      const isFieldDisabled = !!isEditMode && !!record.asset?.isReceived
      return (
        <Container ai="center" jc="flex-start" gap={4}>
          <Container>
            <EditFormItem
              name={`${rowSubLinesFieldName}.${index}.asset.${PurchaseOrderFormAccessor.SubLineAssetNumber}`}
              render={({ field }) => (
                <FormItemEditInput
                  {...field}
                  href={`${route.assets}/${record?.asset?.id}`}
                  align="left"
                  size="small"
                  placeholder="Asset Number"
                  disabled={isFieldDisabled}
                  onBlur={triggerSubmit}
                />
              )}
            />
          </Container>
          {record.asset?.assetNumberType === AssetNumberType.Original && (
            <ResetAssetNumberButton
              subLineId={Number(record.id)}
              itemId={itemId}
              isDisabled={isFieldDisabled || !!allFieldsDisabled}
            />
          )}
        </Container>
      )
    },
  },
  // only available on view mode
  {
    title: getAccessorSubLineTitle(PurchaseOrderSubLineAccessor.IsReceived),
    key: PurchaseOrderSubLineAccessor.IsReceived,
    dataIndex: PurchaseOrderSubLineAccessor.IsReceived,
    width: 90,
    render: (value, record, index) => {
      return (
        <Container px={2}>
          <FormItemCheckbox
            name={`${rowSubLinesFieldName}.${index}.asset.${PurchaseOrderFormAccessor.SubLineIsReceived}`}
            disabled
            label=""
          />
        </Container>
      )
    },
  },
  // only available on view mode
  {
    title: getAccessorSubLineTitle(PurchaseOrderSubLineAccessor.IsBilled),
    key: PurchaseOrderSubLineAccessor.IsBilled,
    dataIndex: PurchaseOrderSubLineAccessor.IsBilled,
    width: 80,
    render: (value, record, index) => {
      const billingReferenceName = getTableExpandableFieldName({
        isEditMode,
        rowSubLinesFieldName,
        index,
        fieldName: PurchaseOrderFormAccessor.SubLineBillingReference,
      })

      return (
        <PopoverForBillNumber show={!!record.asset?.isBilled} popoverName={billingReferenceName}>
          <Container px={2}>
            <FormItem
              disableOnChange={!!onChangeIsBilled}
              name={`${rowSubLinesFieldName}.${index}.asset.${PurchaseOrderFormAccessor.SubLineIsBilled}`}
            >
              <Checkbox
                disabled={record.asset?.isBilled || false}
                onChange={() => onChangeIsBilled?.(`${rowSubLinesFieldName}.${index}`)}
              />
            </FormItem>
          </Container>
        </PopoverForBillNumber>
      )
    },
  },
  {
    title: getAccessorSubLineTitle(PurchaseOrderSubLineAccessor.ReferenceType),
    key: PurchaseOrderSubLineAccessor.ReferenceType,
    dataIndex: PurchaseOrderSubLineAccessor.ReferenceType,
    width: 200,
    ellipsis: true,
    render: (value, record, index) => {
      const isFieldDisabled = isEditMode && !!record.asset?.isReceived
      const rowSubLineFieldIndexName = `${rowSubLinesFieldName}.${index}`
      return <ReferenceType isFieldDisabled={isFieldDisabled} rowSubLineFieldIndexName={rowSubLineFieldIndexName} />
    },
  },
  {
    title: getAccessorSubLineTitle(PurchaseOrderSubLineAccessor.ReferenceNumber),
    key: PurchaseOrderSubLineAccessor.ReferenceNumber,
    dataIndex: PurchaseOrderSubLineAccessor.ReferenceNumber,
    width: 200,
    ellipsis: true,
    render: (value, record, index) => {
      return (
        <ReferenceNumber
          isAddNew={isAddNew}
          itemId={itemId}
          record={record}
          index={index}
          watch={watch}
          rowSubLinesFieldName={rowSubLinesFieldName}
          isEditMode={isEditMode}
          poType={poType}
        />
      )
    },
  },
  {
    key: TableColumnKey.HoverActions,
    render: (value, record, index) => {
      if (isEditMode) {
        return record.asset?.isReceived || record.asset?.isBilled ? null : (
          <EditableTableActions
            index={index}
            onDelete={() => onDelete(record.id as number)}
            name={PurchaseOrderFormAccessor.SubLines}
            icon={<IconDelete />}
          />
        )
      }
      return (
        <Container jc="flex-end">
          <IconDelete onClick={() => onDelete(index)} />
        </Container>
      )
    },
  },
]
