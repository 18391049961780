import { cloneElement, FC, Key, ReactElement, useCallback, useEffect, useState } from 'react'

import { typedMemo } from 'types'

import { Button, Container, Modal, Spacer, Typography } from 'designSystem'

export interface DecreaseAssetsQtyModalProps {
  open: boolean
  onSubmit: (selectedRows: string[]) => void
  onCancel: () => void
  title?: string
  desiredQty: number | null | undefined
  table: ReactElement
  rowsCount: number
  itemName?: string
}

const DecreaseAssetsQtyModalBase: FC<DecreaseAssetsQtyModalProps> = ({
  open: openControlled,
  onSubmit,
  onCancel,
  title = 'Assets quantity decrease',
  desiredQty,
  table,
  rowsCount: currentQty,
  itemName,
}) => {
  const [open, setOpen] = useState(openControlled)
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  useEffect(() => {
    setOpen(openControlled)
  }, [openControlled])

  const handleClose = useCallback(() => {
    setOpen(false)
    onCancel()
  }, [onCancel])

  const handleSubmit = useCallback(() => {
    onSubmit?.(selectedRows)
  }, [onSubmit, selectedRows])

  const removeCount = currentQty - (desiredQty || 0)
  const disabledRemoveButton = selectedRows.length !== removeCount

  const handleChangeSelection = useCallback((selectedRowKeys: Key[]) => {
    setSelectedRows(selectedRowKeys as string[])
  }, [])

  const shouldClearSelections = !open && !!selectedRows.length

  useEffect(() => {
    if (shouldClearSelections) {
      setSelectedRows([])
    }
  }, [shouldClearSelections])

  return (
    <Modal width={712} open={open} onCancel={handleClose} title={title} footer={null} destroyOnClose centered>
      <Typography as="h1" size="m" fontWeight="xl">
        You are near to decrease Assets amount in Item - {itemName} from {currentQty} to {desiredQty}
      </Typography>
      <Spacer mb={2} />
      <Typography color="secondary">
        Select
        <Container display="inline-flex" px={2}>
          <Typography fontWeight="l">{removeCount}</Typography>
        </Container>
        assets that should be removed
      </Typography>
      <Spacer mb={4} />
      {cloneElement(table, {
        rowSelection: {
          selectedRowKeys: selectedRows,
          onChange: handleChangeSelection,
        },
      })}
      <Container display="flex" jc="space-between" gap={8} mt={4}>
        <Button type="default" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={disabledRemoveButton} danger onClick={handleSubmit} px={9}>
          Remove Selected Assets
        </Button>
      </Container>
    </Modal>
  )
}

export const DecreaseAssetsQtyModal = typedMemo(DecreaseAssetsQtyModalBase)
