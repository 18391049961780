import * as Yup from 'yup'

import { VendorCarrierPriceListItemType } from 'api'
import { addressValidation, numberBasicValidation } from 'utils'

export const itemValidationSchema = Yup.object().shape(
  {
    locationCodeId: Yup.number()
      .when('type', {
        is: (type: VendorCarrierPriceListItemType) => type === VendorCarrierPriceListItemType.One,
        then: (schema) => schema.required('Location is required'),
        otherwise: (schema) => schema.nullable(),
      })
      .default(null),
    type: Yup.mixed<VendorCarrierPriceListItemType>().required('Type is required'),
    truckCodeId: Yup.number().required('Truck Code is required'),
    city: addressValidation.city.default(null),
    state: addressValidation.state.default(null),
    zipCode: addressValidation.zipCode
      .when('type', {
        is: (type: VendorCarrierPriceListItemType) => type === VendorCarrierPriceListItemType.One,
        then: (schema) => schema.required('ZIP is required'),
        otherwise: (schema) => schema.nullable(),
      })
      .default(null),
    minimumMiles: numberBasicValidation
      .when('type', {
        is: (type: VendorCarrierPriceListItemType) => type === VendorCarrierPriceListItemType.Two,
        then: (schema) => schema.required('Minimum Miles is required'),
        otherwise: (schema) => schema.nullable(),
      })
      .default(null),
    perMilePrice: numberBasicValidation
      .when('type', ([type], schema) => {
        if ([VendorCarrierPriceListItemType.Two, VendorCarrierPriceListItemType.Four].includes(type)) {
          return schema.required('Price per Mile After is required')
        }
        return schema.nullable()
      })
      .default(null),
    fromMiles: numberBasicValidation
      .when(['type', 'toMiles'], ([type, toMiles], schema) => {
        if (type === VendorCarrierPriceListItemType.Three) {
          return schema
            .positive('Should be positive')
            .lessThan(toMiles, 'To miles should be bigger than From miles')
            .required('From Miles is required')
        }
        return schema.nullable()
      })
      .default(null),
    toMiles: numberBasicValidation
      .when(['type', 'fromMiles'], ([type, fromMiles], schema) => {
        if (type === VendorCarrierPriceListItemType.Three) {
          return schema
            .positive('Should be positive')
            .moreThan(fromMiles, 'To miles should be bigger than From miles')
            .required('To Miles is required')
        }
        return schema.nullable()
      })
      .default(null),
    flatFeePrice: numberBasicValidation.required('Price is required'),
  },
  [['toMiles', 'fromMiles']],
)

export const validationSchema = Yup.object({
  list: Yup.array(itemValidationSchema).required().default([]),
})
