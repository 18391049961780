import { Pagination as PaginationBasic } from 'antd'
import { styled } from 'styled-components'

export const Pagination = styled(PaginationBasic)`
  .ant-pagination-item {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.lines};

    &-active,
    &-active:hover {
      background-color: ${({ theme }) => theme.colors.primary};
      border-color: ${({ theme }) => theme.colors.primary};

      > a {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    border: 1px solid ${({ theme }) => theme.colors.lines};
    color: ${({ theme }) => theme.colors.primary};

    &:not(.ant-pagination-disabled) {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`
