export const vendorsRoot = '/vendors'

export const vendorsRoute = {
  list: '/',
  create: '/create',
  view: '/:id',
}

export const vendorViewRoute = {
  details: '/details',
  modifications: '/modifications',
  containerVendor: '/container-vendor',
  carrier: '/carrier',
  depot: '/depot',
  dataMapping: '/data-mapping',
  parts: '/parts',
  po: '/po',
  integrations: '/integrations',
}

export const vendorCarrierRoute = {
  details: '/details',
  priceList: '/price-list',
  equipment: '/equipment',
}

export const vendorContainerRoute = {
  details: '/details',
  inventory: '/inventory',
  settings: '/settings',
}

export const vendorPartsRoute = {
  details: '/details',
  inventory: '/inventory',
  settings: '/settings',
}
