import dayjs, { Dayjs } from 'dayjs'

import type { TimeRangeValue, TimeValue } from 'api'

export const getTimeValueFromDate = (value: Dayjs | null) => {
  return value
    ? {
        hours: value.hour(),
        minutes: value.minute(),
      }
    : null
}
export const getDateValueFromTimeValue = (value: TimeValue | null | undefined): Dayjs | null => {
  return value ? dayjs().set('hour', value.hours).set('minute', value.minutes) : null
}

export const getDateValueFromTimeRangeValue = (obj: TimeRangeValue): [Dayjs, Dayjs] | null => {
  const fromDate = getDateValueFromTimeValue(obj?.from)
  const toDate = getDateValueFromTimeValue(obj?.to)

  return fromDate && toDate ? [fromDate, toDate] : null
}

export const getTimeRangeValueFromDate = (values: (Dayjs | null)[] | null): TimeRangeValue | null => {
  if (!values || !Array.isArray(values) || values.length !== 2) {
    return null
  }

  const [from, to] = values
  return {
    from: {
      hours: (from as Dayjs).hour(),
      minutes: (from as Dayjs).minute(),
    },
    to: {
      hours: (to as Dayjs).hour(),
      minutes: (to as Dayjs).minute(),
    },
  }
}
