import { FC, useCallback, useState } from 'react'

import { Button } from 'designSystem'

import { AddContactModal, NewContact } from '../modals'

interface AddNewContactActionButtonProps {
  contacts: NewContact[]
  onSubmit: (values: NewContact) => void
  buttonText?: string
}

export const AddNewContactActionButton: FC<AddNewContactActionButtonProps> = ({
  contacts,
  onSubmit,
  buttonText = 'Add Contact',
}) => {
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false)

  const toggleContactModal = useCallback(() => {
    setIsAddContactModalOpen((prev) => !prev)
  }, [])

  const handleSubmit = useCallback(
    (values: NewContact) => {
      onSubmit(values)
      toggleContactModal()
    },
    [onSubmit, toggleContactModal],
  )
  return (
    <>
      <Button size="small" px={3} onClick={toggleContactModal}>
        {buttonText}
      </Button>
      <AddContactModal
        open={isAddContactModalOpen}
        onCancel={toggleContactModal}
        onSubmit={handleSubmit}
        contacts={contacts}
      />
    </>
  )
}
