import { FC, useCallback, useState } from 'react'

import { Contact } from 'api'

import { Typography } from 'designSystem'
import { ContactDetailsModal } from 'components/modals/ContactDetailsModal'

import * as Styled from './styles'

interface ContactViewProps {
  contact: Contact
  showDelete?: boolean
  onDelete?: () => void
}

export const ContactView: FC<ContactViewProps> = ({ contact, showDelete, onDelete }) => {
  const [open, setOpen] = useState(false)

  const toggleOpen = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  const handleDelete = useCallback(() => {
    onDelete?.()
    toggleOpen()
  }, [onDelete, toggleOpen])

  return (
    <>
      <Styled.Wrapper onClick={toggleOpen}>
        <Typography
          color="info"
          fontWeight="l"
          lineEllipsis={1}
          nowrap
        >{`${contact.firstName} ${contact.lastName}`}</Typography>
      </Styled.Wrapper>
      <ContactDetailsModal
        open={open}
        contact={contact}
        onClose={toggleOpen}
        onDelete={handleDelete}
        showDelete={showDelete}
      />
    </>
  )
}
