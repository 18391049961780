import { css, styled } from 'styled-components'

export const Content = styled.div<{
  showCategoryForm: boolean
}>`
  padding: 7px 0 7px 0;

  cursor: pointer;
  position: relative;

  ${({ showCategoryForm }) =>
    !showCategoryForm &&
    css`
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 18px;
        right: 0;
        height: 1px;
        background: ${({ theme }) => theme.colors.lines};
      }
    `}
`
