import { SalesOrderStatus } from 'api/types/salesOrder/salesOrderStatus'

export const salesOrderStatusOptionsLabel = {
  [SalesOrderStatus.New]: 'New',
  [SalesOrderStatus.PendingAccountingApproval]: 'Pending Accounting Approval',
  [SalesOrderStatus.PaymentApproved]: 'Payment Approved',
  [SalesOrderStatus.WeCarryAssets]: 'We Carry Assets',
  [SalesOrderStatus.AssetProduced]: 'Asset Produced',
  [SalesOrderStatus.BookingCreated]: 'Booking Created',
  [SalesOrderStatus.ReadyToShipping]: 'Ready To Shipping',
  [SalesOrderStatus.Completed]: 'Completed',
  [SalesOrderStatus.Cancelled]: 'Cancelled',
}

export const salesOrderStatusOptions = Object.values(SalesOrderStatus).map((status) => ({
  label: salesOrderStatusOptionsLabel[status],
  value: status,
}))
