import { FC } from 'react'

import { useFormContext, useItems } from 'hooks'
import { itemLabels } from 'constant'

import { Spacer, Typography } from 'designSystem'
import { Card, FormItemSelectSingleAsyncGroup } from 'components'

export const GroupSKUs: FC = () => {
  const { watch } = useFormContext()
  const disInCalculator = watch('disInCalculator')

  return (
    <Card hidden={!disInCalculator}>
      <Typography fontWeight="xl" size="l">
        {itemLabels.groupedSKUs}
      </Typography>
      <Spacer mb={5} />
      <FormItemSelectSingleAsyncGroup name="groupedSKUs" getItems={useItems} shouldClear={!disInCalculator} />
    </Card>
  )
}
