import { FC, useCallback, useEffect, useState } from 'react'

import { Modal, Tabs } from 'designSystem'

import { getTabItems } from './utils/getTabItems'

interface BilledItemModalProps {
  open: boolean
  onSubmit: (data: string) => void
  onCancel: () => void
}

export const BilledItemModal: FC<BilledItemModalProps> = ({ open: defaultOpen, onSubmit, onCancel }) => {
  const [open, setOpen] = useState(defaultOpen)

  useEffect(() => {
    setOpen(defaultOpen)
  }, [defaultOpen])

  const handleClose = useCallback(() => {
    setOpen(false)
    onCancel()
  }, [onCancel])

  const tabItems = getTabItems({
    onClose: handleClose,
    onSubmit,
  })

  return (
    <Modal
      open={open}
      width={438}
      onCancel={handleClose}
      destroyOnClose
      title="Do you want to mark Item Billed?"
      footer={null}
      isTabsBody
    >
      <Tabs items={tabItems} destroyInactiveTabPane />
    </Modal>
  )
}
