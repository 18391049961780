import { FC } from 'react'

import * as Styled from './styles'

interface EditableTableIndexProps {
  isAddNew: boolean
  index: number
  offsetCount: number
}

export const EditableTableIndex: FC<EditableTableIndexProps> = ({ isAddNew, index, offsetCount }) => {
  if (isAddNew) {
    if (index === 0) {
      return (
        <Styled.EditableTableIndexText size="xs" fontWeight="xl">
          NEW
        </Styled.EditableTableIndexText>
      )
    }
    return <>{offsetCount + index}</>
  }

  return <>{offsetCount + index + 1}</>
}
