import { VendorCarrierPriceListItem } from 'api'
import { useEditableTable, useTable } from 'hooks'

import { useGetCarrierPriceList } from './useGetCarrierPriceList'

export const useCarrierPriceList = (vendorId: number) => {
  const { pagination, onChange, sortQueryParams, searchQueryParams, ...props } = useTable<
    Partial<VendorCarrierPriceListItem>
  >({})

  const {
    data = [],
    total,
    isLoading,
    isFetching,
  } = useGetCarrierPriceList({
    vendorId,
    limit: pagination.limit,
    page: pagination.page,
    sort: sortQueryParams,
    s: searchQueryParams,
  })

  const { dataSource } = useEditableTable<VendorCarrierPriceListItem>({
    data,
  })

  return {
    dataSource,
    data: data,
    pagination: {
      ...pagination,
      current: pagination.page,
      pageSize: pagination.limit,
      total,
    },
    onTableChange: onChange,
    isLoading: isLoading || isFetching,
    offsetCount: (pagination.page - 1) * pagination.limit,
    ...props,
  }
}
