import type { Role } from './role'
import type { UserSettings } from './userSettings'
import type { ZohoLinks } from './zohoLinks'

export enum UserStatus {
  Active = 'active',
  Inactive = 'inactive',
  Invited = 'invited',
}

export type User = {
  id: number
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  phoneExtension: string
  status: UserStatus
  companyId: number
  avatarPath: string
  createAt: Date
  updatedAt: Date
  roles: Role[]
  settings: UserSettings
  company?: ZohoLinks
}
