import * as Yup from 'yup'

import { CustomerAccountValue, CustomerBuys, CustomerIndustry, CustomerStatus, CustomerUCC } from 'api'
import {
  addressValidation,
  contactValidationSchema,
  dateValidation,
  emailValidation,
  numberBasicValidation,
  urlValidation,
} from 'utils'

import {
  maxBillingAddressesCount,
  maxContactsCount,
  maxShippingAddressesCount,
  maxUccAddressesCount,
} from './maxRecordsCount'

export const customerStatusValidation = {
  status: Yup.mixed<CustomerStatus>().oneOf(Object.values(CustomerStatus)).required('Status is required'),
}

export const customerValidation = {
  companyName: Yup.string().max(100, 'Max length of 100 characters').required('Company Name is required'),
  accountNumber: Yup.string().max(30, 'Max length of 30 characters').nullable().default(null),
  creditLimit: numberBasicValidation.positive('Credit Limit should be positive').nullable().default(null),
  creditLimitExpirationDate: dateValidation.nullable().default(null),
  notes: Yup.string().max(100, 'Max length of 100 characters').nullable().default(null),
  industry: Yup.mixed<CustomerIndustry>().oneOf(Object.values(CustomerIndustry)).required('Industry is required'),
  accountValue: Yup.mixed<CustomerAccountValue>()
    .oneOf(Object.values(CustomerAccountValue))
    .required('Account Value is required'),
  buys: Yup.mixed<CustomerBuys>().oneOf(Object.values(CustomerBuys)).required('Buys is required'),
  website: urlValidation.nullable().default(null),
}

export const customerUccAddressesValidation = {
  addresses: Yup.array(Yup.object(addressValidation)).max(
    maxUccAddressesCount,
    `Max length of ${maxUccAddressesCount} addresses`,
  ),
}

export const customerUCCValidation = {
  isUcc: Yup.boolean().default(false),
  ucc: Yup.mixed<CustomerUCC>()
    .when('isUcc', {
      is: (isUcc: boolean) => isUcc,
      then: () =>
        Yup.object({
          balance: numberBasicValidation.positive('Balance should be positive').nullable().default(null),
          name: Yup.string().max(100, 'Max length of 100 characters').required('Full Name is required'),
          email: emailValidation,
          details: Yup.string().max(100, 'Max length of 100 characters').nullable().default(null),
          ...customerUccAddressesValidation,
        }),
      otherwise: (schema) => schema.nullable().default(null),
    })
    .nullable()
    .default(null),
}

export const customerContactsValidation = {
  contacts: Yup.array(contactValidationSchema.required())
    .min(1, 'At least one contact is required')
    .max(maxContactsCount, `Max length of ${maxContactsCount} contacts`)
    .required(),
}

export const customerBillingAddressesValidation = {
  billingAddresses: Yup.array(Yup.object(addressValidation)).max(
    maxBillingAddressesCount,
    `Max length of ${maxBillingAddressesCount} addresses`,
  ),
}

export const customerShippingAddressesValidation = {
  shippingAddresses: Yup.array(Yup.object(addressValidation)).max(
    maxShippingAddressesCount,
    `Max length of ${maxShippingAddressesCount} addresses`,
  ),
}
