import { SalesOrderFormAccessor } from 'types'

interface GetTableFieldNameProps {
  fieldName: string
  index: number
}

export const getTableFieldName = ({ fieldName, index }: GetTableFieldNameProps) => {
  return `${SalesOrderFormAccessor.LineItems}.${index}.${fieldName}`
}
