import { DecimalDigitsRegexp } from 'constant'
import { replaceTextValueWhiteSpaces } from 'utils'

import { InputType } from '../types/inputType'

export const getValueByInputType = (value: string, type: InputType, pattern?: RegExp) => {
  switch (type) {
    case 'phone': {
      const firstChar = value.length > 0 ? '+' : ''
      const phone = value.replace(DecimalDigitsRegexp, '')
      return `${firstChar}${phone}`
    }
    case 'digits':
      return String(value).replace(DecimalDigitsRegexp, '')
    default: {
      const valueReplacedWhiteSpaces = replaceTextValueWhiteSpaces(value)
      if (pattern) {
        return valueReplacedWhiteSpaces.replace(pattern, '')
      }
      return valueReplacedWhiteSpaces
    }
  }
}
