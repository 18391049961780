import { PurchaseOrder, PurchaseOrderItem, ReferenceNumber } from 'api'

import { SelectSingleOption } from 'designSystem'
import { PurchaseOrderReferenceNumberType, ReferenceNumberOptions } from 'modules/purchaseOrders/types'

import { referenceNotAllowedStatuses } from 'modules/purchaseOrders/constants/referenceNotAllowedStatuses'

const addPoDataReferenceNumberOption = (
  options: SelectSingleOption[],
  referenceNumber?: Pick<ReferenceNumber, 'id' | 'number' | 'status'>,
): SelectSingleOption[] => {
  return [...options, ...(referenceNumber ? [prepareReferenceNumberOption(referenceNumber)] : [])]
}

const prepareReferenceNumberOption = (
  referenceNumber: Pick<ReferenceNumber, 'id' | 'number' | 'status'>,
): SelectSingleOption => {
  return {
    label: referenceNumber.number,
    value: referenceNumber.id,
    disabled: referenceNotAllowedStatuses.includes(referenceNumber.status),
  }
}

export const getReferenceNumberOptions = (
  items: PurchaseOrderItem[],
  poData?: PurchaseOrder,
): ReferenceNumberOptions => {
  const uniqueReferenceNumbers: string[] = [
    ...(poData?.redeliveryReferenceNumber ? [poData.redeliveryReferenceNumber.number] : []),
    ...(poData?.vendorReleaseReferenceNumber ? [poData.vendorReleaseReferenceNumber.number] : []),
  ]

  const { redeliveryReferenceNumber, vendorReleaseReferenceNumber } = items.reduce(
    (acc, item) => {
      item.subLines?.forEach((subLine) => {
        Object.values(PurchaseOrderReferenceNumberType).forEach((type) => {
          const referenceNumber = subLine.asset?.[type] as ReferenceNumber
          if (referenceNumber && referenceNumber.number && !uniqueReferenceNumbers.includes(referenceNumber.number)) {
            uniqueReferenceNumbers.push(referenceNumber.number)
            acc[type].push(prepareReferenceNumberOption(referenceNumber))
          }
        })
      })
      return acc
    },
    {
      redeliveryReferenceNumber: [] as SelectSingleOption[],
      vendorReleaseReferenceNumber: [] as SelectSingleOption[],
    },
  )

  return {
    redeliveryNumberOptions: addPoDataReferenceNumberOption(
      redeliveryReferenceNumber,
      poData?.redeliveryReferenceNumber,
    ),
    vendorReleaseNumberOptions: addPoDataReferenceNumberOption(
      vendorReleaseReferenceNumber,
      poData?.vendorReleaseReferenceNumber,
    ),
  }
}
