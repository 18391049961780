import { FC, MouseEvent, useCallback, useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'

import type { AddressFields as AddressFieldsType } from 'api'
import { useFormContext } from 'hooks/useForm'
import { countriesOptions } from 'constant'

import { ConfirmationButtons, Container, EditFormItem, Icon, Typography } from 'designSystem'

import * as Styled from './styles'

import { GooglePlacesAutocomplete } from '../../../../GooglePlacesAutocomplete'
import { FormItemEditInput } from '../../../FormItemEditInput'
import { FormItemEditSelectSingle } from '../../../FormItemEditSelectSingle'

interface AddressFieldsProps {
  onClose: () => void
  onConfirm?: () => void
  name?: string
  hideFields?: (keyof AddressFieldsType)[]
}

export const AddressFields: FC<AddressFieldsProps> = ({ onClose, onConfirm, name, hideFields }) => {
  const {
    reset,
    setValue,
    getFieldState,
    formState: { defaultValues, errors },
  } = useFormContext()

  const isError = Boolean(
    name
      ? getFieldState(name)?.error
      : errors?.country || errors?.state || errors?.city || errors?.street || errors?.zipCode,
  )

  const { country, city, state, zipCode, street } = (name ? defaultValues?.[name] : defaultValues) || {}
  const [addressFields, setAddressFields] = useState<AddressFieldsType>({ city, country, state, street, zipCode })
  const [search, setSearch] = useState('')
  const isValuesSame = isEqual(addressFields, { city, country, state, street, zipCode })

  const handleConfirm = useCallback(() => {
    onConfirm?.()
    onClose()
  }, [onConfirm, onClose])

  const handleCancel = useCallback(() => {
    onClose()
    reset(defaultValues)
  }, [reset, defaultValues, onClose])

  const handlePopoverContentClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }, [])

  const shouldShowField = useCallback(
    (fieldName: keyof AddressFieldsType) => {
      return !hideFields?.length || !hideFields.includes(fieldName)
    },
    [hideFields],
  )

  const onChangeAutocomplete = useCallback(
    (values: AddressFieldsType) => {
      Object.keys(values).forEach((key) => {
        if (shouldShowField(key as keyof AddressFieldsType)) {
          setValue(name ? `${name}.${key}` : key, values[key as keyof AddressFieldsType])
        }
      })
      setAddressFields(values)
      setSearch('')
    },
    [name, setValue, shouldShowField],
  )

  const onFieldChange = useCallback(
    (field: string, value?: string | number) => {
      setAddressFields({ ...addressFields, [field]: value })
    },
    [addressFields],
  )

  const handleSearchChange = useCallback((value?: string) => {
    setSearch(value || '')
  }, [])

  useEffect(() => {
    window.addEventListener('click', handleCancel)

    return () => {
      window.removeEventListener('click', handleCancel)
    }
  }, [handleCancel])

  return (
    <Container width={230} onClick={handlePopoverContentClick} fd="column">
      <Styled.AutocompleteWrapper>
        <GooglePlacesAutocomplete
          width={214}
          ml={-26}
          onChange={handleSearchChange}
          value={search}
          placeholder="Search address"
          onChangeAutocomplete={onChangeAutocomplete}
          prefix={<Icon icon="search" size={14} />}
        />
      </Styled.AutocompleteWrapper>
      <Container pa={3} display="flex" fd="column" gap={8}>
        <Typography size="xs" fontWeight="l" color="placeholder">
          Or Enter Manually
        </Typography>
        <EditFormItem
          size="small"
          name={name ? `${name}.country` : 'country'}
          label="Country"
          render={({ field }) => (
            <FormItemEditSelectSingle
              {...field}
              onChange={(value) => {
                field.onChange(value || '')
                onFieldChange('country', value || '')
              }}
              allowClear
              showSearch
              options={countriesOptions}
              placeholder="Type country"
              size="small"
              width={150}
            />
          )}
        />
        {shouldShowField('city') && (
          <EditFormItem
            size="small"
            name={name ? `${name}.city` : 'city'}
            label="City"
            render={({ field }) => (
              <FormItemEditInput
                {...field}
                onChange={(value?: string) => {
                  field.onChange(value)
                  onFieldChange('city', value)
                }}
                placeholder="Type city"
                size="small"
              />
            )}
          />
        )}
        {shouldShowField('state') && (
          <EditFormItem
            size="small"
            name={name ? `${name}.state` : 'state'}
            label="State"
            render={({ field }) => (
              <FormItemEditInput
                {...field}
                onChange={(value?: string) => {
                  field.onChange(value)
                  onFieldChange('state', value)
                }}
                placeholder="Type state"
                size="small"
              />
            )}
          />
        )}
        {shouldShowField('street') && (
          <EditFormItem
            size="small"
            name={name ? `${name}.street` : 'street'}
            label="Street"
            render={({ field }) => (
              <FormItemEditInput
                {...field}
                onChange={(value?: string) => {
                  field.onChange(value)
                  onFieldChange('street', value)
                }}
                placeholder="Type Street"
                size="small"
              />
            )}
          />
        )}
        {shouldShowField('zipCode') && (
          <EditFormItem
            size="small"
            name={name ? `${name}.zipCode` : 'zipCode'}
            label="Zip"
            render={({ field }) => (
              <FormItemEditInput
                {...field}
                onChange={(value?: string) => {
                  field.onChange(value)
                  onFieldChange('zipCode', value)
                }}
                placeholder="Type zip"
                size="small"
              />
            )}
          />
        )}
        <ConfirmationButtons onConfirm={handleConfirm} onCancel={handleCancel} disabled={isError || isValuesSame} />
      </Container>
    </Container>
  )
}
