import { FC, useCallback } from 'react'

import { Button } from '../../../Button'
import { Container } from '../../../Container'

interface CreateNewProps {
  onClickCreateNew?: () => void
}

export const CreateNew: FC<CreateNewProps> = ({ onClickCreateNew }) => {
  // Use onMouseDown because select dropdown closes onMouseDown event
  const handleMouseDown = useCallback(() => {
    // Delay for select dropdown first close
    setTimeout(() => {
      onClickCreateNew?.()
    }, 300)
  }, [onClickCreateNew])

  return (
    <Container>
      <Button size="small" icon="plus" fullwidth onMouseDown={handleMouseDown}>
        Create New
      </Button>
    </Container>
  )
}
