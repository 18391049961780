import { api, EntityId } from 'api'

export interface ResetAssetNumberProps {
  referenceId: EntityId
  itemId?: EntityId
  subLineId: EntityId
}

export const resetReferenceAssetNumber = ({ referenceId, itemId, subLineId }: ResetAssetNumberProps): Promise<any> => {
  return api({
    method: 'patch',
    url: `/api/v1/references/${referenceId}/items/${itemId}/sub-lines/${subLineId}/action/reset-number`,
  })
}
