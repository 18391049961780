import type { ApiQueryParams, Contact, EntityId } from 'api'
import { api } from 'api/api'
import { Pagination } from 'types'

export interface GetVendorContactsResponse extends Pagination {
  data: Contact[]
}

export const getVendorContacts = (id?: EntityId, params?: ApiQueryParams): Promise<GetVendorContactsResponse> => {
  return api({
    method: 'get',
    url: `/api/v1/vendors/${id}/contacts`,
    params: {
      sort: 'id,ASC',
      ...params,
    },
  })
}
