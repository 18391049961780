import { api, Contact } from 'api'

interface PostPickupNewContactParams {
  referenceId?: number
  data: Omit<Contact, 'id'>
}

export const postPickupNewContact = ({ referenceId, data }: PostPickupNewContactParams): Promise<Contact> => {
  return api({
    method: 'post',
    url: `/api/v1/references/${referenceId}/contacts`,
    data,
  })
}

export interface PostPickupCustomerContactsPayload {
  customerContactsIds: number[]
}

export interface PostPickupCustomerContactsParams {
  referenceId?: number
  customerId?: number
  data: PostPickupCustomerContactsPayload
}

export const postPickupCustomerContacts = ({
  referenceId,
  customerId,
  data,
}: PostPickupCustomerContactsParams): Promise<Contact[]> => {
  return api({
    method: 'post',
    url: `/api/v1/references/${referenceId}/contacts/copy/customers/${customerId}/contacts`,
    data,
  })
}

export interface DeletePickupContactParams {
  referenceId?: number
  contactId?: number
}

export const deletePickupContact = ({ referenceId, contactId }: DeletePickupContactParams): Promise<void> => {
  return api({
    method: 'delete',
    url: `/api/v1/references/${referenceId}/contacts/${contactId}`,
  })
}
