import { api, EntityId, getVendorsJoinQueryParams, getVendorsSortQueryParams, Vendor } from 'api'

import { VendorDetailsPayload } from './types/vendorDetailsPayload'

export const patchVendor = ({ id, data }: { id: EntityId; data: VendorDetailsPayload }): Promise<Vendor> => {
  return api({
    method: 'patch',
    url: `/api/v1/vendors/${id}`,
    data,
    params: {
      join: getVendorsJoinQueryParams,
      sort: getVendorsSortQueryParams,
    },
  })
}
