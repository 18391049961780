import { FC, useEffect } from 'react'
import { Col, Row } from 'antd'
import { useFormContext } from 'react-hook-form'

import { ItemType } from 'api'
import {
  assetCodeAdditionalAttributesOptions,
  assetCodeGradeOptions,
  assetCodeHeightOptions,
  assetCodeLengthOptions,
  assetCodeTypeOptions,
  assetCodeWidthOptions,
  currency,
  itemLabels,
  itemTypeOptions,
} from 'constant'

import {
  Divider,
  FormItem,
  Input,
  InputNumber,
  SelectMulti,
  SelectSingle,
  Spacer,
  TextArea,
  Typography,
} from 'designSystem'
import { Card } from 'components'

export const ItemFields: FC = () => {
  const { watch, unregister } = useFormContext()
  const type = watch('type')

  const isAssetsType = type === ItemType.Assets

  useEffect(() => {
    if (!isAssetsType) {
      unregister(['assetType', 'length', 'width', 'height', 'grade', 'additionalAttributes', 'weight'])
    }
  }, [isAssetsType, unregister])

  return (
    <Card>
      <Typography fontWeight="xl" size="l">
        Create Item
      </Typography>
      <Spacer mb={5} />
      <Row gutter={8}>
        <Col span={12}>
          <FormItem name="name" label={itemLabels.name}>
            <Input placeholder="Enter Item Name" />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem name="sku" label={itemLabels.sku}>
            <Input placeholder="Enter SKU" uppercase />
          </FormItem>
        </Col>
      </Row>
      <Spacer mb={3} />
      <Row gutter={8}>
        <Col span={12}>
          <FormItem name="type" label={itemLabels.type}>
            <SelectSingle placeholder="Select" options={itemTypeOptions} />
          </FormItem>
        </Col>
      </Row>
      <Spacer mb={3} />
      {isAssetsType && (
        <>
          <Row gutter={8}>
            <Col span={12}>
              <FormItem name="assetType" label={itemLabels.assetType}>
                <SelectSingle placeholder={itemLabels.assetType} options={assetCodeTypeOptions} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem name="length" label={itemLabels.length}>
                <SelectSingle placeholder={itemLabels.length} options={assetCodeLengthOptions} />
              </FormItem>
            </Col>
          </Row>
          <Spacer mb={3} />
          <Row gutter={8}>
            <Col span={12}>
              <FormItem name="width" label={itemLabels.width}>
                <SelectSingle placeholder={itemLabels.width} options={assetCodeWidthOptions} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem name="height" label={itemLabels.height}>
                <SelectSingle placeholder={itemLabels.height} options={assetCodeHeightOptions} />
              </FormItem>
            </Col>
          </Row>
          <Spacer mb={3} />
          <Row gutter={8}>
            <Col span={12}>
              <FormItem name="grade" label={itemLabels.grade}>
                <SelectSingle placeholder={itemLabels.grade} options={assetCodeGradeOptions} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem name="additionalAttributes" label={itemLabels.additionalAttributes}>
                <SelectMulti
                  placeholder={itemLabels.additionalAttributes}
                  options={assetCodeAdditionalAttributesOptions}
                />
              </FormItem>
            </Col>
          </Row>
          <Spacer mb={3} />
        </>
      )}
      <Row gutter={8}>
        {isAssetsType && (
          <Col span={12}>
            <FormItem name="weight" label={itemLabels.weight}>
              <InputNumber placeholder={itemLabels.weight} precision={2} />
            </FormItem>
          </Col>
        )}
        <Col span={12}>
          <FormItem name="price" label={itemLabels.price}>
            <InputNumber placeholder="00.00" precision={2} prefix={currency} />
          </FormItem>
        </Col>
      </Row>
      <Divider my={4} />
      <FormItem name="description" label={itemLabels.description}>
        <TextArea size="small" placeholder="Enter Description" autoSize={{ minRows: 5 }} />
      </FormItem>
    </Card>
  )
}
