import { styled } from 'styled-components'

export const BreadcrumbsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacers[4]};
  height: 40px;
  .ant-breadcrumb {
    font-weight: ${({ theme }) => theme.fontWeight.l};
    font-size: ${({ theme }) => theme.fontSize.s};
    line-height: ${({ theme }) => theme.lineHeight.m};
    ol {
      display: flex;
      align-items: center;
    }
  }
  .ant-breadcrumb-link {
    color: ${({ theme }) => theme.colors.primary};
    a {
      min-height: ${({ theme }) => theme.spacers[7]};
      color: ${({ theme }) => theme.colors.secondary};
      display: flex;
      align-items: center;
      &:hover {
        color: ${({ theme }) => theme.colors.primary};
        background: ${({ theme }) => theme.colors.underline};
      }
    }
  }
  .ant-breadcrumb-separator {
    color: ${({ theme }) => theme.colors.placeholder};
  }
`
