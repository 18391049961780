import { AssetRepair } from 'api'
import { assetRepairStatusOptions } from 'constant'

import { EditFormItem, FormItem, Icon, Input, Typography } from 'designSystem'
import {
  AssetRepairStatusTag,
  EditableTableActions,
  EditableTableIndex,
  FormItemEditDatePicker,
  FormItemEditInput,
  FormItemEditSelectSingle,
  getEditableColumnFormItemName,
  isEditableTableAddNewIndex,
  TableColumnKey,
  TableColumnsType,
} from 'components'

import { Accessor } from '../types/accessor'

import { getAccessorTitle } from './getAccessorTitle'

interface RepairsTableColumnsProps {
  offsetCount: number
  isAddNew: boolean
  onCancelNew: () => void
  onDelete: (id?: number) => void
  triggerSubmit: () => void
}

export const getTableColumns = ({
  offsetCount,
  isAddNew,
  onCancelNew,
  onDelete,
  triggerSubmit,
}: RepairsTableColumnsProps): TableColumnsType<Partial<AssetRepair>> => [
  {
    title: getAccessorTitle(Accessor.Index),
    key: TableColumnKey.Index,
    width: isAddNew ? 50 : undefined,
    ellipsis: true,
    render: (value, record, index) => (
      <EditableTableIndex isAddNew={isAddNew} index={index} offsetCount={offsetCount} />
    ),
  },
  {
    title: getAccessorTitle(Accessor.RepairIssued),
    key: Accessor.RepairIssued,
    dataIndex: Accessor.RepairIssued,
    width: 150,
    ellipsis: true,
    render: (value, record, index) => {
      return (
        <EditFormItem
          name={getEditableColumnFormItemName('list', Accessor.RepairIssued, index, isAddNew)}
          isViewOnly
          render={({ field }) => <FormItemEditDatePicker {...field} align="left" size="small" />}
        />
      )
    },
  },
  {
    title: getAccessorTitle(Accessor.Id),
    key: Accessor.Id,
    dataIndex: Accessor.Id,
    width: 150,
    ellipsis: true,
    render: (value, record, index) => {
      return (
        <EditFormItem
          name={getEditableColumnFormItemName('list', Accessor.Id, index, isAddNew)}
          isViewOnly
          render={({ field }) => <FormItemEditInput {...field} size="small" align="left" />}
        />
      )
    },
  },
  {
    title: getAccessorTitle(Accessor.Description),
    key: Accessor.Description,
    dataIndex: Accessor.Description,
    width: 400,
    ellipsis: true,
    render: (value, record, index) =>
      isEditableTableAddNewIndex(index, isAddNew) ? (
        <FormItem name="item.description">
          <Input placeholder="Description" />
        </FormItem>
      ) : (
        <EditFormItem
          name={getEditableColumnFormItemName('list', Accessor.Description, index, isAddNew)}
          render={({ field }) => (
            <FormItemEditInput size="small" align="left" placeholder="Description" {...field} onBlur={triggerSubmit} />
          )}
        />
      ),
  },
  {
    title: getAccessorTitle(Accessor.Cost),
    key: Accessor.Cost,
    dataIndex: Accessor.Cost,
    width: 150,
    ellipsis: true,
    render: (value, record, index) =>
      isEditableTableAddNewIndex(index, isAddNew) ? (
        <FormItem name="item.cost">
          <Input placeholder="Cost" />
        </FormItem>
      ) : (
        <EditFormItem
          name={getEditableColumnFormItemName('list', Accessor.Cost, index, isAddNew)}
          render={({ field }) => (
            <FormItemEditInput size="small" align="left" placeholder="Cost" {...field} onBlur={triggerSubmit} />
          )}
        />
      ),
  },
  {
    title: getAccessorTitle(Accessor.RepairStatus),
    key: Accessor.RepairStatus,
    dataIndex: Accessor.RepairStatus,
    width: 200,
    ellipsis: true,
    render: (value, record, index) => {
      return isEditableTableAddNewIndex(index, isAddNew) ? (
        <Typography size="xs" fontWeight="l" color="secondary">
          Empty
        </Typography>
      ) : (
        <EditFormItem
          name={getEditableColumnFormItemName('list', Accessor.RepairStatus, index, isAddNew)}
          render={({ field }) => (
            <FormItemEditSelectSingle
              {...field}
              fullwidth
              size="small"
              placeholder="Status"
              customPreview={<AssetRepairStatusTag status={field.value} icon={<Icon icon="arrowDown" size={16} />} />}
              onSubmit={triggerSubmit}
              options={assetRepairStatusOptions}
            />
          )}
        />
      )
    },
  },
  {
    title: getAccessorTitle(Accessor.Actions),
    key: TableColumnKey.Actions,
    render: (value, record, index) => (
      <EditableTableActions
        isAddNew={isAddNew}
        index={index}
        onCancelNew={onCancelNew}
        onDelete={() => onDelete(record.id)}
      />
    ),
  },
]
