import { keepPreviousData, useQuery } from '@tanstack/react-query'

import type { EmailHistory } from 'api'
import { getEmailHistory } from 'api'
import { QueryKey } from 'enums'

import { useTable } from './useTable'

export const useEmailHistory = (queryKey: QueryKey, filters?: string) => {
  const { pagination, onChange, getFieldSorter, sortQueryParams, searchQueryParams, ...props } = useTable<EmailHistory>(
    {},
  )
  const params = {
    join: ['sender||id,firstName,lastName'],
    limit: pagination.limit,
    page: pagination.page,
    sort: sortQueryParams,
    s: (searchQueryParams || '') + (filters || ''),
  }

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [queryKey, params],
    queryFn: () => getEmailHistory(params),
    placeholderData: keepPreviousData,
  })

  const { data: emailHistoryData, total } = data || {}

  return {
    data: emailHistoryData || [],
    pagination: {
      ...pagination,
      current: pagination.page,
      pageSize: pagination.limit,
      total,
    },
    getFieldSorter,
    onTableChange: onChange,
    isLoading: isLoading || isFetching,
    offsetCount: (pagination.page - 1) * pagination.limit,
    ...props,
  }
}
