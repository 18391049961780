import { FC, MouseEvent, useCallback, useState } from 'react'

import { EmailHistory } from 'api'
import { QueryKey } from 'enums'
import { useEmailHistory } from 'hooks'
import { formatDate } from 'utils'

import { Container, Divider, Modal, Spacer, Typography } from 'designSystem'

import { getTableColumns } from './utils/getTableColumns'

import { Table, TableTitle } from '../Table'
import { TextEditor } from '../TextEditor'

interface EmailHistoryTableProps {
  queryKey: QueryKey
  filters: string
}

export const EmailHistoryTable: FC<EmailHistoryTableProps> = ({ queryKey, filters }) => {
  const [currentData, setCurrentData] = useState<EmailHistory | undefined>()

  const { data, pagination, isLoading, offsetCount } = useEmailHistory(queryKey, filters)

  const dataSource = data?.map((dataItem, index) => ({ ...dataItem, key: index }))

  const onClickTableRow = useCallback((record: EmailHistory) => {
    const onClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement

      if (target.tagName === 'A') {
        return
      }

      event.preventDefault()
      setCurrentData(record)
    }
    return { onClick }
  }, [])

  const handleCloseModal = useCallback(() => {
    setCurrentData(undefined)
  }, [])

  return (
    <Container bg="white" fd="column" pa={4} gap={16} width="100%">
      <TableTitle withoutDefaultPadding title="Email History" />
      <Table
        loading={isLoading}
        pagination={pagination}
        dataSource={dataSource}
        columns={getTableColumns(offsetCount)}
        scroll={{ x: 950 }}
        rowClassName="table"
        onRow={onClickTableRow}
      />
      <Modal
        pa={0}
        title="Email Summary"
        width={850}
        open={Boolean(currentData)}
        onCancel={handleCloseModal}
        destroyOnClose
        footer={null}
        centered
      >
        <Container px={7} py={4}>
          <Container jc="space-between" gap={16} width="100%">
            <Container fd="column" gap={4}>
              <Typography size="xs" fontWeight="l" color="secondary">
                Date
              </Typography>
              <Typography size="xs" fontWeight="l">
                {formatDate(currentData?.createdAt)}
              </Typography>
            </Container>
            <Container fd="column" gap={4}>
              <Typography size="xs" fontWeight="l" color="secondary">
                Sender
              </Typography>
              <Typography size="xs" fontWeight="l">
                {currentData?.sender?.firstName} {currentData?.sender?.lastName}
              </Typography>
            </Container>
            <Container fd="column" gap={4}>
              <Typography size="xs" fontWeight="l" color="secondary">
                Recipient
              </Typography>
              <Typography size="xs" fontWeight="l">
                {currentData?.to?.map((recipient) => <p key={recipient}>{recipient}</p>)}
              </Typography>
            </Container>
            <Container />
          </Container>
        </Container>
        <Divider />
        <Container px={7} py={4}>
          <Container fd="column">
            <Typography size="xs" fontWeight="l" color="secondary">
              Subject
            </Typography>
            <Typography fontWeight="xl">{currentData?.subject}</Typography>
          </Container>
        </Container>
        <Container fd="column" px={7}>
          <TextEditor data={currentData?.htmlContent} maxHeight={400} isReadOnly />
        </Container>
        <Spacer pb={7} />
      </Modal>
    </Container>
  )
}
