import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { getUsers, GetUsersQueryParams } from 'api'
import { QueryKey } from 'enums'

export const useGetUsers = (params: GetUsersQueryParams) => {
  const { data, ...rest } = useQuery({
    queryKey: [QueryKey.GetUsers, params],
    queryFn: () => getUsers({ join: ['roles'], ...params }),
    placeholderData: keepPreviousData,
  })

  const { total, data: users } = data || {}

  return {
    data: users,
    total,
    ...rest,
  }
}
