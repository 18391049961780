import { styled } from 'styled-components'

import { Modal as BaseModal } from 'designSystem'

export const Modal = styled(BaseModal)`
  .ant-modal-body {
    padding: 0;
    min-height: 320px;
  }

  .ant-tabs-content {
    padding: ${({ theme }) => `${theme.spacers[4]} ${theme.spacers[7]} ${theme.spacers[7]}`};
  }
`
