import { styled } from 'styled-components'

import { Container } from 'designSystem'

export const LabelValueContainer = styled(Container)`
  padding-right: ${({ theme }) => theme.spacers[2]};
  .ant-tooltip-inner {
    font-weight: ${({ theme }) => theme.fontWeight.l};
  }
`
