import { PurchaseOrderStatus } from 'api'
import { purchaseOrderStatusLabel } from 'constant'

import { TagType } from 'designSystem'

interface PurchaseOrderTagData {
  status: TagType
  text?: string
}

export const getTagDataByPurchaseOrderStatus = (status: PurchaseOrderStatus): PurchaseOrderTagData => {
  switch (status) {
    case PurchaseOrderStatus.Open:
      return {
        status: TagType.InProgress,
        text: purchaseOrderStatusLabel[PurchaseOrderStatus.Open],
      }
    case PurchaseOrderStatus.Closed:
      return {
        status: TagType.Done,
        text: purchaseOrderStatusLabel[PurchaseOrderStatus.Closed],
      }
    case PurchaseOrderStatus.Cancelled:
      return {
        status: TagType.Error,
        text: purchaseOrderStatusLabel[PurchaseOrderStatus.Cancelled],
      }
    case PurchaseOrderStatus.Voided:
      return {
        status: TagType.Error,
        text: purchaseOrderStatusLabel[PurchaseOrderStatus.Voided],
      }
    default:
      return {
        status: TagType.Default,
        text: purchaseOrderStatusLabel[PurchaseOrderStatus.Draft],
      }
  }
}
