import { api, Token } from 'api'

export interface SubmitLoginPayload {
  email: string
  password: string
}

export interface SubmitLoginResponse {
  accessToken: Token
  refreshToken: Token
}

export const submitLogin = (data: SubmitLoginPayload): Promise<SubmitLoginResponse> => {
  return api({
    method: 'post',
    url: '/api/v1/auth/local',
    data,
    headers: { withAuth: false },
  })
}
