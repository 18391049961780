import { keepPreviousData, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { getAssets, GetAssetsQueryParams } from 'api'
import { QueryKey } from 'enums'

interface UseGetAssetsProps {
  queryKey: QueryKey
  enabled?: boolean
  params: GetAssetsQueryParams
  props?: Pick<UseQueryOptions, 'gcTime' | 'staleTime'>
}

export const useGetAssets = ({ queryKey, enabled, params, props = {} }: UseGetAssetsProps) => {
  const { data, isLoading, isFetching, fetchStatus, ...rest } = useQuery({
    ...props,
    queryKey: [queryKey, params],
    queryFn: () => getAssets(params),
    enabled,
    placeholderData: keepPreviousData,
  })

  return {
    data: data?.data,
    total: data?.total,
    isLoading: (isLoading || isFetching) && fetchStatus !== 'idle',
    ...rest,
  }
}
