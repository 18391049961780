import { getAssetRedeliveryReferenceAccessorTitle } from '../utils/getAssetRedeliveryReferenceAccessorTitle'
import { AssetRedeliveryReferenceAccessor } from '../types/assetRedeliveryReferenceAccessor'

export const assetRedeliveryReferencesRequiredColumns = [
  AssetRedeliveryReferenceAccessor.Index,
  AssetRedeliveryReferenceAccessor.Number,
]

export const assetRedeliveryReferencesDefaultColumns = [
  AssetRedeliveryReferenceAccessor.Index,
  AssetRedeliveryReferenceAccessor.CreatedAt,
  AssetRedeliveryReferenceAccessor.Number,
  AssetRedeliveryReferenceAccessor.Type,
  AssetRedeliveryReferenceAccessor.TransactionType,
  AssetRedeliveryReferenceAccessor.RelatedReference,
  AssetRedeliveryReferenceAccessor.ReceivedDate,
  AssetRedeliveryReferenceAccessor.Condition,
  AssetRedeliveryReferenceAccessor.Notes,
  AssetRedeliveryReferenceAccessor.Status,
]

export const assetRedeliveryReferencesVisibleColumnsOptions = Object.values(AssetRedeliveryReferenceAccessor).map(
  (accessor) => ({
    value: accessor,
    label: getAssetRedeliveryReferenceAccessorTitle(accessor),
    disabled: assetRedeliveryReferencesRequiredColumns.includes(accessor),
  }),
)
