import type { ApiQueryParams, EmailHistory } from 'api'
import type { Pagination } from 'types'

import { api } from '../api'

export interface GetEmailHistoryResponse extends Pagination {
  data?: EmailHistory[]
}

export type GetEmailHistoryQueryParams = ApiQueryParams

export const getEmailHistory = (params?: GetEmailHistoryQueryParams): Promise<GetEmailHistoryResponse> => {
  return api({
    method: 'get',
    url: '/api/v1/email-history/',
    params,
  })
}
