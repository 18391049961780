import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { AssetRepair } from 'api'
import { useForm } from 'hooks'

import { Button, Container } from 'designSystem'
import { Table, TableSearchSingle, TableTitle } from 'components'

import { useRepairs } from './hooks/useRepairs'
import { useRepairsRequests } from './hooks/useRepairsRequests'
import { getTableColumns } from './utils/getTableColumns'
import { validationSchema } from './constants/validationSchema'
import { RepairsForm } from './types/repairsForm'

export const AssetRepairsTable: FC = () => {
  const { id } = useParams()

  const {
    data,
    isLoading,
    pagination,
    onTableChange,
    offsetCount,
    TableContextProvider,
    dataSource,
    isAddNew,
    setIsAddNew,
    onAddNew,
    onCancelNew,
  } = useRepairs(Number(id))
  const { onSubmitPost, onSubmitPatch, onDelete, isSubmitError, isEditLoading } = useRepairsRequests(setIsAddNew, data)

  const { Form, handleSubmit, triggerSubmit } = useForm<RepairsForm>({
    validationSchema: validationSchema(isAddNew),
    mode: 'onChange',
    isTableEditForm: true,
    isLoading,
    onSubmit: onSubmitPatch,
    isSubmitError,
    defaultValues: {
      list: data,
    },
    shouldFocusError: false,
  })

  const columns = getTableColumns({
    offsetCount,
    isAddNew,
    onCancelNew,
    onDelete,
    triggerSubmit,
  })

  return (
    <Container pa={4} fd="column" gap={16}>
      <TableContextProvider>
        <TableTitle
          withoutDefaultPadding
          title="Repairs"
          total={pagination.total}
          search={<TableSearchSingle searchFieldName={['description', 'cost']} />}
        >
          <Button icon="plus" type="primary" size="small" onClick={onAddNew} disabled={isAddNew}>
            Add
          </Button>
        </TableTitle>

        <Form onSubmit={handleSubmit(onSubmitPost)}>
          <Table<Partial<AssetRepair>>
            dataSource={dataSource}
            columns={columns}
            viewOnlyExceptFirstRow={isAddNew}
            loading={isLoading || isEditLoading}
            pagination={pagination}
            onChange={onTableChange}
            isEditLoading={isEditLoading}
            scroll={{ x: 600 }}
            editableTable
          />
        </Form>
      </TableContextProvider>
    </Container>
  )
}
