import * as Yup from 'yup'

import { contactValidationSchema } from 'utils'

export const newContactValidationSchema = Yup.object({
  contacts: Yup.array().of(contactValidationSchema.required()).default(undefined),
})

export const entityContactsValidationSchema = (maxContacts: number) =>
  Yup.object({
    entityContacts: Yup.array()
      .of(Yup.number().required())
      .default(undefined)
      .max(maxContacts, `You can add ${maxContacts} contact(s)`),
  })
