import { FC } from 'react'
import { Route, Routes, useLocation, useParams } from 'react-router-dom'
import { Layout } from 'antd'

import { customerViewRoute, route } from 'constant'

import { Breadcrumbs, Sidebar } from 'components'

import { getSidebarItems } from './utils/getSidebarItems'
import { breadcrumbsData } from './constants/breadcrumbsData'
import { routes } from './constants/routes'
import { ActionButtons } from './elements/ActionButtons'

export const Customer: FC = () => {
  const { id } = useParams()
  const { pathname } = useLocation()

  const showActions = pathname === `${route.customers}/${id}${customerViewRoute.details}`

  const sidebarItems = getSidebarItems(id)

  return (
    <Layout>
      <Sidebar sidebarItems={sidebarItems} />
      <Layout.Content>
        <Breadcrumbs breadcrumbItems={breadcrumbsData} actions={showActions && <ActionButtons />} />
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Layout.Content>
    </Layout>
  )
}
