import { FC } from 'react'

import { SalesOrderAccountingStatus } from 'api'

import { Tag, TagProps } from 'designSystem'

import { getTagDataBySalesOrderAccountingStatus } from './utils/getTagDataBySalesOrderAccountingStatus'

interface SalesOrderAccountingStatusTagProps extends Omit<TagProps, 'status'> {
  status: SalesOrderAccountingStatus
}

export const SalesOrderAccountingStatusTag: FC<SalesOrderAccountingStatusTagProps> = ({ status, ...props }) => {
  const { status: tagStatus, text } = getTagDataBySalesOrderAccountingStatus(status)
  return <Tag {...props} status={tagStatus} text={text} />
}
