import { EntityId } from 'api'

import { useDeleteReferenceItem } from './useDeleteReferenceItem'
import { usePatchReferenceItem } from './usePatchReferenceItem'
import { usePostReferenceItem } from './usePostReferenceItem'

export const useReferenceItemRequests = (referenceId?: EntityId) => {
  const { post, isError: isPostError, isPending: isPostLoading } = usePostReferenceItem(referenceId)
  const { update, isError: isPatchError, isPending: isPatchLoading } = usePatchReferenceItem(referenceId)
  const { remove, isError: isDeleteError, isPending: isDeleteLoading } = useDeleteReferenceItem(referenceId)

  return {
    isError: isPostError || isPatchError || isDeleteError,
    isLoading: isPostLoading || isPatchLoading || isDeleteLoading,
    post,
    update,
    remove,
  }
}
