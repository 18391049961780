import { FC, useCallback } from 'react'

import { Address } from 'api'
import { useForm } from 'hooks'

import { FormItemEditAddressGroup } from 'components'

import { usePatchCustomerBillingAddress } from './hooks/usePatchCustomerBillingAddress'
import { usePostCustomerBillingAddress } from './hooks/usePostCustomerBillingAddress'
import { useRemoveCustomerBillingAddress } from './hooks/useRemoveCustomerBillingAddress'
import { validationSchema } from './constants/validationSchema'
import { maxBillingAddressesCount } from 'modules/customers/constants/maxRecordsCount'
import { CustomerBillingAddressForm } from './types/customerBillingAddressForm'

interface CustomerBillingAddressProps {
  customerId: number
  billingAddresses?: Address[]
}

export const CustomerBillingAddress: FC<CustomerBillingAddressProps> = ({ customerId, billingAddresses }) => {
  const { post, isError: isPostError, isPending: isPostLoading } = usePostCustomerBillingAddress(customerId)
  const { patch, isError: isPatchError, isPending: isPatchLoading } = usePatchCustomerBillingAddress(customerId)
  const { remove, isError: isRemoveError, isPending: isRemoveLoading } = useRemoveCustomerBillingAddress(customerId)

  const { Form } = useForm<CustomerBillingAddressForm>({
    validationSchema,
    mode: 'onChange',
    isSubmitError: isRemoveError || isPostError || isPatchError,
    isLoading: isRemoveLoading || isPostLoading || isPatchLoading,
    defaultValues: { billingAddresses },
  })

  const onSubmitPost = useCallback(
    (payload: Address) => {
      post(payload)
    },
    [post],
  )

  const onSubmitPatch = useCallback(
    (payload: Address) => {
      patch(payload)
    },
    [patch],
  )

  const onDelete = useCallback(
    (id: number) => {
      remove(id)
    },
    [remove],
  )

  return (
    <Form>
      <FormItemEditAddressGroup
        title="Billing Address"
        name="billingAddresses"
        maxCount={maxBillingAddressesCount}
        onSubmitPost={onSubmitPost}
        onSubmitPatch={onSubmitPatch}
        onDelete={onDelete}
        isLoading={isPostLoading || isPatchLoading}
      />
    </Form>
  )
}
