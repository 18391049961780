import { Component, CSSProperties, FC, forwardRef, Ref } from 'react'
import type { RangePickerDateProps } from 'antd/es/date-picker/generatePicker'

import { DateFormat, typedMemo } from 'types'
import type { Dayjs } from 'utils'
import theme from 'styles/theme'

import * as Styled from './styles'

import { Icon } from '../Icon'

export type DateRangePickerProps = Omit<RangePickerDateProps<Dayjs>, 'format'> & {
  ref?: Ref<Component<DateRangePickerProps>>
  align?: CSSProperties['textAlign']
  width?: number
  error?: string
  format?: keyof typeof DateFormat
}

export const DateRangePickerBase: FC<DateRangePickerProps> = forwardRef(
  ({ placeholder, suffixIcon, format = 'Standard', ...props }, ref: Ref<Component<DateRangePickerProps>>) => {
    const dateFormat = DateFormat[format]

    return (
      <Styled.DateRangePicker
        {...props}
        ref={ref}
        format={dateFormat}
        placeholder={placeholder || [dateFormat.toLocaleLowerCase(), dateFormat.toLowerCase()]}
        suffixIcon={suffixIcon ? suffixIcon : <Icon icon="calendar" size={12} color={theme.colors.primary} />}
      />
    )
  },
)

export const DateRangePicker = typedMemo(DateRangePickerBase)
