import { styled } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  min-width: 0;
  cursor: pointer;
  padding-left: ${({ theme }) => theme.spacers[3]};
`
