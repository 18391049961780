import { PurchaseOrderType } from 'api/types/purchaseOrder'

export const purchaseOrderTypeOptions: {
  value: PurchaseOrderType
  label: string
}[] = [
  {
    value: PurchaseOrderType.VendorRelease,
    label: 'Vendor Release',
  },
  {
    value: PurchaseOrderType.Redelivery,
    label: 'Redelivery',
  },
  {
    value: PurchaseOrderType.Standard,
    label: 'Standard',
  },
]

export const getPurchaseOrderTypeLabel = (purchaseOrderType: PurchaseOrderType): string | undefined =>
  purchaseOrderTypeOptions.find(({ value }) => value === purchaseOrderType)?.label
