import { logisticsRoute } from 'constant'

import { Tab } from 'designSystem'

export const logisticsTabs: Tab[] = [
  {
    key: logisticsRoute.events,
    label: 'Events List',
    children: <>Coming soon</>,
  },
  {
    key: logisticsRoute.planning,
    label: 'Events Planing',
    children: <>Coming soon</>,
  },
]
