import { VendorType } from 'api'
import { getScrappingSettingsPayload } from 'utils/getScrappingSettingsPayload'

import { PostVendorPayload } from '../routes/VendorCreate/types/vendorCreateForm'

export const prepareVendorPayload = (data: PostVendorPayload): PostVendorPayload => {
  const { type } = data

  const isModificationOn = type?.includes(VendorType.Modifications)
  const isContainerVendorOn = type?.includes(VendorType.ContainerVendor)
  const isCarrierOn = type?.includes(VendorType.Carrier)
  const isDepotOn = type?.includes(VendorType.Depot)
  const isPartOn = type?.includes(VendorType.Parts)

  const partResaleMargin = data.partSettings?.resaleMargin

  return {
    ...data,
    ...(data.websiteUrl !== undefined && { websiteUrl: data.websiteUrl || null }),
    ...(isContainerVendorOn && {
      containerSupplierSettings: {
        typeOfEquipment: data.containerSupplierSettings?.typeOfEquipment ?? [],
      },
    }),
    ...(isCarrierOn &&
      data?.carrierSettings && {
        carrierSettings: {
          ...data.carrierSettings,
          type: data.carrierSettings.type || [],
        },
      }),
    ...(isModificationOn && {
      modificationsSettings: {},
    }),
    ...(isDepotOn &&
      data?.depotSettings && {
        depotSettings: {
          ...data.depotSettings,
          availableInCalculator: data.depotSettings.availableInCalculator || false,
          acceptTransload: data.depotSettings.acceptTransload || false,
          accountNumber: data.depotSettings.accountNumber || null,
        },
      }),
    ...(isPartOn &&
      data?.partSettings && {
        partSettings: {
          ...getScrappingSettingsPayload(data.partSettings.scrappingSettings),
          ...(partResaleMargin?.operand && {
            resaleMargin: partResaleMargin,
          }),
        },
      }),
  }
}
