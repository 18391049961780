import { route } from 'constant'

export const breadcrumbsData = [
  {
    title: `Sales Orders`,
    url: route.salesOrders,
  },
  {
    title: 'Create Sales Order',
  },
]
