export enum PurchaseOrderItemAccessor {
  Index = 'index',
  Sku = 'itemId',
  ItemName = 'itemName',
  Qty = 'qty',
  Rate = 'rate',
  ReceivedQty = 'receivedQty',
  BilledQty = 'billedQty',
  TotalAmount = 'totalAmount',
}
