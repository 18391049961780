import { Dispatch, FC, SetStateAction } from 'react'
import { Tooltip } from 'antd'

import { useGetVendorContacts } from 'hooks'
import { purchaseOrderLabels } from 'constant'
import { PurchaseOrderFormAccessor } from 'types'
import theme from 'styles/theme'

import { Container, FormItem, Icon, SelectMulti } from 'designSystem'
import { ContactFormData } from 'modules/purchaseOrders/routes/PurchaseOrderCreate/types/purchaseOrderCreateForm'

import * as Styled from './styles'

interface VendorContactsByIdProps {
  vendorId?: number
  contacts: ContactFormData[]
  setContacts: Dispatch<SetStateAction<ContactFormData[]>>
}

export const VendorContactsById: FC<VendorContactsByIdProps> = ({
  vendorId,
  contacts: selectedContacts,
  setContacts,
}) => {
  const { data: contacts = [], isLoading } = useGetVendorContacts(vendorId)
  const newContacts = selectedContacts.filter((contact) => !contact.id)

  const contactOptions = [...contacts, ...newContacts]?.map(({ email, firstName, lastName }) => ({
    label: `${firstName} ${lastName}`,
    value: email,
  }))

  const handleChange = (values: any) => {
    const allContacts = new Set([...contacts, ...selectedContacts])
    setContacts(Array.from(allContacts).filter((contact) => values.includes(contact.email)))
  }

  return (
    <>
      <FormItem name={PurchaseOrderFormAccessor.ContactsIds} label={purchaseOrderLabels.contacts}>
        <SelectMulti
          options={vendorId ? contactOptions : []}
          loading={isLoading}
          placeholder={vendorId ? 'Start typing' : 'Select vendor name first'}
          disabled={!vendorId}
          onChange={handleChange}
        />
      </FormItem>
      <Container jc="flex-end" ai="center" mt={2}>
        <FormItem name={PurchaseOrderFormAccessor.IsEmailRequired}>
          <Styled.Checkbox>{purchaseOrderLabels.isEmailRequired}</Styled.Checkbox>
        </FormItem>
        <Tooltip title="Email will be send to selected contacts">
          <Icon icon="info" size={16} color={theme.colors.placeholder} />
        </Tooltip>
      </Container>
    </>
  )
}
