import { getAccessorTitle } from '../utils/getAccessorTitle'
import { Accessor } from '../types/accessor'

export const customersRequiredColumns = [Accessor.Index, Accessor.CompanyName]

export const customersDefaultColumns = [
  Accessor.Index,
  Accessor.CompanyName,
  Accessor.CreditLimit,
  Accessor.Status,
  Accessor.Notes,
]

export const customersVisibleColumnsOptions = Object.values(Accessor).map((accessor) => ({
  value: accessor,
  label: getAccessorTitle(accessor),
  disabled: customersRequiredColumns.includes(accessor),
}))
