import { FC, useCallback } from 'react'
import { Col, Row } from 'antd'
import { useFormContext } from 'react-hook-form'

import { useDepotCodes, useLocationCodes, useWatch } from 'hooks'
import { purchaseOrderLabels } from 'constant'
import { PurchaseOrderFormAccessor } from 'types'
import { PurchaseOrderOptionType, useGetPurchaseOrderOptions, useSetPurchaseOrderOptions } from 'store'

import type { SelectSingleOption } from 'designSystem'
import { FormItemSelectSingleAsync } from 'components'

export const LocationVsDepotFields: FC = () => {
  const { setValue } = useFormContext()
  const setOptionsById = useSetPurchaseOrderOptions()
  const depotOptions = useGetPurchaseOrderOptions('depots')
  const locationOptions = useGetPurchaseOrderOptions('locations')
  const [locationCodeId, depotId] = useWatch({
    name: [PurchaseOrderFormAccessor.LocationCodeId, PurchaseOrderFormAccessor.DepotId],
  })

  const handleOnChange = useCallback(
    (optionType: PurchaseOrderOptionType) =>
      (value: string | number | null | undefined, option: SelectSingleOption | undefined) => {
        setOptionsById?.(optionType, option)
        if (optionType === 'locations') {
          setValue(PurchaseOrderFormAccessor.DepotId, null)
        }
      },
    [setOptionsById, setValue],
  )

  const defaultDepotOption =
    locationCodeId && depotId && depotOptions?.[depotId]
      ? {
          value: depotId,
          label: depotOptions[depotId].label,
        }
      : undefined

  const defaultLocationOption =
    locationCodeId && locationOptions?.[locationCodeId]
      ? {
          value: locationCodeId,
          label: locationOptions[locationCodeId].label,
        }
      : undefined

  return (
    <Row gutter={8}>
      <Col span={12}>
        <FormItemSelectSingleAsync
          withFullDataOption
          selectedOption={defaultLocationOption}
          name={PurchaseOrderFormAccessor.LocationCodeId}
          label={purchaseOrderLabels.locationCodeId}
          getItems={useLocationCodes}
          placeholder="Start Typing"
          onChange={handleOnChange('locations')}
        />
      </Col>
      <Col span={12}>
        <FormItemSelectSingleAsync
          selectedOption={defaultDepotOption}
          name={PurchaseOrderFormAccessor.DepotId}
          label={purchaseOrderLabels.depotId}
          getItems={useDepotCodes}
          disabled={!locationCodeId}
          fields={'code,id,accountNumber'}
          placeholder={locationCodeId ? 'Start Typing' : 'Select location first'}
          queryParams={{
            ...(locationCodeId && {
              locationCodeId: { $eq: locationCodeId },
              accountNumber: { $notnull: 'true' },
            }),
          }}
          onChange={handleOnChange('depots')}
        />
      </Col>
    </Row>
  )
}
