import { AssetRedeliveryReferenceAccessor } from '../types/assetRedeliveryReferenceAccessor'

// TODO: Reuse accessor titles for reference when will be moved to global level
export const getAssetRedeliveryReferenceAccessorTitle = (accessor: AssetRedeliveryReferenceAccessor): string => {
  return {
    [AssetRedeliveryReferenceAccessor.Index]: '#',
    [AssetRedeliveryReferenceAccessor.CreatedAt]: 'Created Date',
    [AssetRedeliveryReferenceAccessor.Number]: 'Number',
    [AssetRedeliveryReferenceAccessor.Type]: 'Type',
    [AssetRedeliveryReferenceAccessor.TransactionType]: 'Transaction Type',
    [AssetRedeliveryReferenceAccessor.RelatedReference]: 'Related Reference',
    [AssetRedeliveryReferenceAccessor.ReceivedDate]: 'Received Date',
    [AssetRedeliveryReferenceAccessor.Condition]: 'Condition',
    [AssetRedeliveryReferenceAccessor.Notes]: 'Notes',
    [AssetRedeliveryReferenceAccessor.Status]: 'Status',
  }[accessor]
}
