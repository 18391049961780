import { FC } from 'react'

import { Asset } from 'api'
import { QueryKey } from 'enums'
import { useAssetsTable } from 'hooks'
import { route } from 'constant'
import { AppModule } from 'types'

import {
  Table,
  TableFilterDrawer,
  TableFilterOptions,
  TableSearchSingle,
  TableTitle,
  useTableColumns,
} from 'components'

import { getTableColumns } from './utils/getTableColumns'
import { assetsFilterOptions } from './constants/assetsFilterOptions'
import { assetsVisibleColumnsOptions } from './constants/assetsVisibleColumns'

export const AssetsList: FC = () => {
  const {
    dataSource,
    loading,
    pagination,
    onChange,
    total,
    getFieldSorter,
    offsetCount,
    TableContextProvider,
    filterOptions,
    setFilterOptions,
  } = useAssetsTable({
    module: AppModule.Assets,
    queryKey: QueryKey.GetAssets,
  })
  const allColumns = getTableColumns(getFieldSorter, offsetCount)
  const { visibleColumns, setVisibleColumns, columns } = useTableColumns<Asset>({
    module: AppModule.Assets,
    allColumns,
  })

  return (
    <TableContextProvider>
      <TableTitle
        title="Assets"
        total={total}
        addRoute={route.assetsCreate}
        search={<TableSearchSingle searchFieldName={['assetNumber']} />}
        filter={
          <TableFilterDrawer
            setFilterOptions={setFilterOptions}
            filterOptions={filterOptions}
            options={assetsFilterOptions}
            module={AppModule.Assets}
          />
        }
        columns={
          <TableFilterOptions
            title="Columns"
            icon="columns"
            options={assetsVisibleColumnsOptions}
            value={visibleColumns}
            onChange={setVisibleColumns}
          />
        }
      />
      <Table<Asset>
        withBorderRadius={false}
        dataSource={dataSource}
        loading={loading}
        columns={columns}
        pagination={pagination}
        onChange={onChange}
        scroll={{ x: 750 }}
      />
    </TableContextProvider>
  )
}
