export enum SalesOrderLineItemsAccessor {
  Sku = 'itemId',
  ItemName = 'itemName',
  Modification = 'modification',
  Qty = 'qty',
  Price = 'price',
  Discount = 'discount',
  Taxes = 'taxes',
  SubTotal = 'subtotal',
  Total = 'total',
}
