import { styled } from 'styled-components'

export const TitleTag = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 6px;
  font-weight: ${({ theme }) => theme.fontWeight.xl};
  font-size: ${({ theme }) => theme.fontSize.xs};
  line-height: ${({ theme }) => theme.lineHeight.s};
  padding: ${({ theme }) => theme.spacers[3]} 10px;
  background: ${({ theme }) => theme.colors.underline};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`
