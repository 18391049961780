import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { RepairsPayload } from '../types/repairsForm'

import { postRepairs } from '../queryApi'

export const usePostRepairs = (assetId: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation({
    mutationFn: postRepairs,
    onSuccess: () => {
      dispatchAppEvent(AppEvent.AssetRepairCreate)
      showNotification({ title: 'Repair is added', type: 'success' })
    },
  })

  const post = useCallback(
    (payload: RepairsPayload) => {
      mutate({ assetId, data: payload })
    },
    [mutate, assetId],
  )

  return {
    post,
    ...rest,
  }
}
