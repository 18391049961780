import {
  api,
  Contact,
  ReferenceNumber,
  ReferenceNumberBookingAsset,
  ReferenceNumberItem,
  ReferenceNumberPickupAsset,
  ReferenceNumberRedeliveryAsset,
  ReferenceNumberVendorReleaseAsset,
} from 'api'

import { referenceNumberByIdJoinParams } from '../../constants/referenceNumberByIdJoinParams'

export type PostReferenceNumberPayload = Partial<Omit<ReferenceNumber, 'contacts' | 'address' | 'items'>> & {
  contacts?: (Omit<Contact, 'id'> | { customerContactId?: number })[]
  items?: (Omit<ReferenceNumberItem, 'id' | 'item' | 'subLines'> & {
    subLines?:
      | Omit<ReferenceNumberBookingAsset, 'id' | 'asset'>[]
      | Omit<ReferenceNumberVendorReleaseAsset, 'id' | 'asset' | 'assetId'>[]
      | Omit<ReferenceNumberRedeliveryAsset, 'id' | 'asset' | 'assetId'>[]
      | Omit<ReferenceNumberPickupAsset, 'id' | 'asset'>[]
  })[]
}

export const postReferenceNumber = (data: PostReferenceNumberPayload): Promise<{ reference: ReferenceNumber }> => {
  return api({
    method: 'post',
    url: '/api/v1/references',
    data: {
      reference: data,
    },
    params: referenceNumberByIdJoinParams,
  })
}
