import { FC } from 'react'
import { Link } from 'react-router-dom'

import { UserNotification } from 'api'
import { getReferenceTypeOptionLabel, hashRoute } from 'constant'
import { formatDate, isDateAfter } from 'utils'

import { Container, Icon, Spacer, Typography } from 'designSystem'

import * as Styled from './styles'

interface ReferenceNotificationProps {
  notification: UserNotification
}

export const ReferenceNotification: FC<ReferenceNotificationProps> = ({ notification }) => {
  if (!notification.data.reference?.expirationDate) return null

  const hasReferenceExpired = isDateAfter(new Date(), notification.data.reference?.expirationDate)

  return (
    <Container width="100%" gap={8}>
      <Container fd="column" ai="center" gap={8}>
        <Styled.IconWrapper $hasExpired={hasReferenceExpired}>
          <Icon icon="info" size={16} />
        </Styled.IconWrapper>
        <Styled.Line />
      </Container>
      <Container fd="column">
        <Styled.Header gap={4} width="100%">
          {getReferenceTypeOptionLabel(notification.data.reference.type)}
          <Link to={hashRoute.reference(notification.data.reference.id)} target="_blank">
            <Styled.ReferenceNumber ai="center" jc="center" gap={2} px={2} py={1}>
              <Icon icon="file" />
              <Typography color="inherit" fontWeight="l" size="xs">
                {notification.data.reference?.number}
              </Typography>
            </Styled.ReferenceNumber>
          </Link>
          will be expired on {formatDate(notification.data.reference.expirationDate)}
        </Styled.Header>
        <Spacer mb={2} />
        <Typography color="secondary" fontWeight="l" size="xs">
          {formatDate(notification.createdAt, 'StandardWithHours')}
        </Typography>
      </Container>
    </Container>
  )
}
