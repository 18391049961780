import { styled } from 'styled-components'

import type { FormItemEditSelectSingleProps } from './FormItemEditSelectSingle'

interface WrapperProps {
  $fullwidth?: FormItemEditSelectSingleProps['fullwidth']
  $isLinkAndViewOnly?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  max-width: 100%;
  width: ${({ $fullwidth }) => $fullwidth && '100%'};
  ${({ $isLinkAndViewOnly }) => $isLinkAndViewOnly && 'pointer-events: initial'};
`

export const CustomPreview = styled.div<{
  disabled?: boolean
}>`
  height: 100%; // place custom content centered
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`
