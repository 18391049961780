import { FC, useCallback } from 'react'

import { useFieldArray, useFormContext } from 'hooks'

import { Button, Container, Spacer } from 'designSystem'

import { CarrierLocationField } from '../../types/carrierLocationsForm'
import { EditLocationFields } from './elements/EditLocationFields'

interface CarrierLocationsFieldsProps {
  name: string
  onSubmitPost: (index: number) => void
  onSubmitPatch: (index: number) => void
  onDelete: (index: number) => void
  maxFields?: number
  isLoading?: boolean
}

export const CarrierLocationsFields: FC<CarrierLocationsFieldsProps> = ({
  name,
  maxFields,
  onSubmitPost,
  onSubmitPatch,
  onDelete,
  isLoading,
}) => {
  const { watch } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name,
  })

  const values: CarrierLocationField[] = watch(name) || []

  const showAddButton =
    (maxFields === undefined || fields.length < maxFields) && values.filter(({ id }) => !id).length < 1

  const handleDelete = useCallback(
    (index: number) => {
      remove(index)
      onDelete?.(index)
    },
    [remove, onDelete],
  )

  const handleCancel = useCallback(
    (index: number) => {
      remove(index)
    },
    [remove],
  )

  const addNewItem = () => append({})

  return (
    <Container display="flex" fd="column" ai="flex-start" gap={8}>
      {fields.map((field, index) => (
        <EditLocationFields
          name={name}
          index={index}
          key={field.id}
          onSubmitPost={onSubmitPost}
          onSubmitPatch={onSubmitPatch}
          showDelete={values.filter(({ id }) => !!id)?.length > 1}
          onDelete={handleDelete}
          onCancel={handleCancel}
          isLoading={isLoading}
          disabledValues={values?.map((v) => v?.locationCode?.value)}
        />
      ))}
      <Spacer mt={4} />
      {showAddButton && (
        <Button type="link" icon="plus" onClick={addNewItem}>
          Add
        </Button>
      )}
    </Container>
  )
}
