import { VendorType } from 'api'

export const getTypeUrlByVendorType = (type: VendorType): string | undefined => {
  return {
    [VendorType.ContainerVendor]: 'container-supplier',
    [VendorType.Parts]: 'parts',
    [VendorType.Carrier]: undefined,
    [VendorType.Depot]: undefined,
    [VendorType.Modifications]: undefined,
  }[type]
}
