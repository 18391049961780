import { FC } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'

import { Container } from 'designSystem'

import { truckCodesRoutes } from './constants/truckCodesRoutes'

export const TruckCodes: FC = () => {
  return (
    <>
      <Outlet />
      <Container fd="column">
        <Routes>
          {truckCodesRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Container>
    </>
  )
}
