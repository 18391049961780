import { styled } from 'styled-components'

export const NotificationButton = styled.div<{
  $isNotificationsExist: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background: ${({ theme, $isNotificationsExist }) =>
    $isNotificationsExist ? theme.colors.error[300] : theme.colors.underline};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeight.l};
  gap: ${({ theme }) => theme.spacers[2]} ${({ theme }) => theme.spacers[2]};
  padding: ${({ theme }) => theme.spacers[2]} ${({ theme }) => theme.spacers[3]};
  height: ${({ theme }) => theme.spacers[9]};
  svg {
    fill: ${({ theme, $isNotificationsExist }) =>
      $isNotificationsExist ? theme.colors.white : theme.colors.secondary};
  }
`
