import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { getSalesOrders, GetSalesOrdersQueryParams } from 'api'
import { QueryKey } from 'enums'

export const useGetSalesOrders = (params: GetSalesOrdersQueryParams) => {
  const { data, ...rest } = useQuery({
    queryKey: [QueryKey.GetSalesOrders, params],
    queryFn: () => getSalesOrders(params),
    placeholderData: keepPreviousData,
  })

  const { total, data: salesOrders } = data || {}

  return {
    data: salesOrders,
    total,
    ...rest,
  }
}
