import { FC, useCallback, useRef } from 'react'

import { useTableContext } from 'hooks'

import { Search } from 'designSystem'

import { usersSearchOptions } from './constants/usersSearchOptions'

export const ListSearch: FC = () => {
  const { setSearch: setTableSearch, search: tableSearch } = useTableContext()
  const searched = useRef(tableSearch?.search || '')
  const searchedField = useRef(tableSearch?.field || 'email')

  const handleSearch = (value: string) => {
    searched.current = value
  }

  const handleSelect = (value: string) => {
    searchedField.current = value
    updateSearch()
  }

  const handleClear = () => {
    setTableSearch?.()
  }

  const updateSearch = useCallback(() => {
    if (searched.current) {
      if (searchedField.current === 'name') {
        setTableSearch?.({
          field: 'name',
          search: searched.current,
          value: { $or: [{ firstName: { $contL: searched.current } }, { lastName: { $contL: searched.current } }] },
        })
      } else {
        setTableSearch?.({
          field: searchedField.current,
          search: searched.current,
          value: { [searchedField.current]: { $contL: searched.current } },
        })
      }
    } else {
      setTableSearch?.()
    }
  }, [setTableSearch])

  return (
    <Search
      allowClear
      searchType="type"
      width={250}
      onSelect={handleSelect}
      onSearch={handleSearch}
      onClickClear={handleClear}
      onClickSearchIcon={updateSearch}
      onPressEnter={updateSearch}
      onBlur={updateSearch}
      defaultOption={tableSearch?.field}
      defaultValue={tableSearch?.search}
      options={usersSearchOptions}
    />
  )
}
