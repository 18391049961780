import { LocationCode } from 'api'
import { useTable } from 'hooks'

import { useGetLocationCodes } from './useGetLocationCodes'

export const useLocationCodes = () => {
  const { pagination, onChange, getFieldSorter, sortQueryParams, searchQueryParams, ...props } = useTable<LocationCode>(
    {},
  )

  const { data, total, isLoading, isFetching } = useGetLocationCodes({
    limit: pagination.limit,
    page: pagination.page,
    sort: sortQueryParams,
    s: searchQueryParams,
    join: ['createdBy'],
  })

  return {
    data: data || [],
    pagination: {
      ...pagination,
      current: pagination.page,
      pageSize: pagination.limit,
      total,
    },
    getFieldSorter,
    onTableChange: onChange,
    isLoading: isLoading || isFetching,
    offsetCount: (pagination.page - 1) * pagination.limit,
    ...props,
  }
}
