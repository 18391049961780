// TODO: Reuse accessors for reference when will be moved to global level
export enum AssetRedeliveryReferenceAccessor {
  Index = 'index',
  CreatedAt = 'createdAt',
  Number = 'number',
  Type = 'type',
  TransactionType = 'transactionType',
  RelatedReference = 'relatedReference',
  ReceivedDate = 'receivedDate',
  Condition = 'condition',
  Notes = 'notes',
  Status = 'status',
}
