export const calculatorFeeList = [
  {
    label: 'Tilt bet per mile',
    name: 'depotSettings.tiltBetPerMile',
  },
  {
    label: 'Flat bet per mile',
    name: 'depotSettings.flatBetPerMile',
  },
  {
    label: 'Minimum delivery fee',
    name: 'depotSettings.minimumDeliveryFee',
  },
]
