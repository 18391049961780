import { CustomerBuys } from 'api/types/customer'

export const customerBuyLabel = {
  [CustomerBuys.SingleBuy]: 'Single Buy',
  [CustomerBuys.MultiBuyRental]: 'Multi Buy/Rental',
}

export const customerBuyOptions = Object.values(CustomerBuys).map((buy) => ({
  value: buy,
  label: customerBuyLabel[buy],
}))
