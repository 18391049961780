import { css, styled } from 'styled-components'

import { EditFormItemSize } from './types/editFormItemSize'

import type { EditFormItemProps } from './EditFormItem'

type FormItemWrapperProps = Pick<EditFormItemProps, 'layout'> & {
  $isFieldViewOnly?: boolean
}

export interface LabelProps {
  size?: EditFormItemSize
}

export const FormItemWrapper = styled.div<FormItemWrapperProps>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacers[2]};

  ${({ $isFieldViewOnly }) =>
    $isFieldViewOnly &&
    css`
      pointer-events: none;
    `}

  ${({ layout }) =>
    layout === 'vertical' &&
    css`
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0;
    `}
`

export const Label = styled.label<LabelProps>`
  display: flex;
  align-items: center;

  label {
    line-height: 28px;
  }
  ${({ size }) =>
    size === 'small' &&
    css`
      label {
        line-height: 20px;
      }
    `}
  gap: ${({ theme }) => theme.spacers[2]};
`

export const LabelWrapper = styled.div<FormItemWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ layout }) =>
    layout === 'vertical' &&
    css`
      width: 100%;
    `}
`

export const Container = styled.div<FormItemWrapperProps & { noLabel?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ layout, noLabel }) => (layout === 'vertical' || noLabel ? 'flex-start' : 'flex-end')};
  flex-grow: 1;
  min-width: 0;
  gap: ${({ theme }) => theme.spacers[2]};
  height: 100%; // for inline components to be placed vertically centered to the label

  ${({ layout }) =>
    layout === 'vertical' &&
    css`
      width: 100%;
      word-wrap: break-word;
    `}
`
