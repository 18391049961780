import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { getReferenceNumbers, GetReferenceNumbersQueryParams } from 'api'
import { QueryKey } from 'enums'

interface UseGetReferenceNumbersProps {
  queryKey: QueryKey
  enabled?: boolean
  params: GetReferenceNumbersQueryParams
}

export const useGetReferenceNumbers = ({ queryKey, enabled, params }: UseGetReferenceNumbersProps) => {
  const { data, isLoading, isFetching, fetchStatus, ...rest } = useQuery({
    queryKey: [queryKey, params],
    queryFn: () => getReferenceNumbers(params),
    enabled,
    placeholderData: keepPreviousData,
  })

  const { total, data: items } = data || {}

  const formattedItems = items?.map((item) => item.reference)

  return {
    data: formattedItems,
    total,
    isLoading: (isLoading || isFetching) && fetchStatus !== 'idle',
    ...rest,
  }
}
