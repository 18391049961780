import { FC } from 'react'

import { Icon, Typography } from 'designSystem'

import * as Styled from './styles'

interface ConfirmItemProps {
  isLoading: boolean
  isDisabled?: boolean
}

export const ConfirmItem: FC<ConfirmItemProps> = ({ isLoading, isDisabled }) => {
  return (
    <>
      <Styled.WarningWrapper>
        <Styled.WarningIcon>
          <Icon icon="warning" size={20} />
        </Styled.WarningIcon>
        <Styled.WarningText>
          <Typography fontWeight="xl" size="xs">
            All previous items will be swapped
          </Typography>
          <Typography fontWeight="l" size="xs">
            After You will upload new data file or link old items will be swapped
          </Typography>
        </Styled.WarningText>
      </Styled.WarningWrapper>
      <Styled.Button htmlType="submit" type="primary" block loading={isLoading} disabled={isDisabled}>
        Confirm
      </Styled.Button>
    </>
  )
}
