import { FC, useState } from 'react'

import { useGetWarehouses } from 'hooks'
import { PurchaseOrderFormAccessor } from 'types'

import { FormItem, SelectSingle, SelectSingleOption, Spacer, Typography } from 'designSystem'

export const WarehouseSelect: FC = () => {
  const [warehouseAddress, setWarehouseAddress] = useState<string | undefined>(undefined)
  const { options } = useGetWarehouses()

  const handleChange = (value?: number | string | null, option?: SelectSingleOption) => {
    const addressToString = [...(Object.values(option?.data) || [])].filter(Boolean).join(', ')
    setWarehouseAddress(addressToString)
  }

  return (
    <>
      <FormItem name={PurchaseOrderFormAccessor.WarehouseId}>
        <SelectSingle options={options} placeholder="Select" onChange={handleChange} />
      </FormItem>
      {warehouseAddress && (
        <>
          <Spacer mb={3} />
          <Typography size="xs" color="secondary">
            {warehouseAddress}
          </Typography>
        </>
      )}
    </>
  )
}
