import { FC } from 'react'

import { PurchaseOrderItem, PurchaseOrderStatus } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useFormContext } from 'hooks'
import { purchaseOrderLabels } from 'constant'
import { PurchaseOrderFormAccessor } from 'types'

import { Button, Container, Modal } from 'designSystem'
import { LabelValueMarkup } from 'components'

interface ChangeStatusModalProps {
  open: boolean
  onCancel: () => void
  onOk: () => void
  poStatus?: PurchaseOrderStatus
}

export const ChangeStatusModal: FC<ChangeStatusModalProps> = ({ open, onCancel, onOk, poStatus }) => {
  const { getValues } = useFormContext()
  const [poNumber, id] = getValues([PurchaseOrderFormAccessor.Number, PurchaseOrderFormAccessor.Id])

  const items: PurchaseOrderItem[] | undefined = queryClient.getQueryData([QueryKey.GetPurchaseOrderItems, id])

  return (
    <Modal
      centered
      width={460}
      open={open}
      title={`Do you want to change PO status to ${poStatus}?`}
      footer={false}
      onCancel={onCancel}
    >
      <Container fd="column" gap={8} mb={7}>
        <LabelValueMarkup label={purchaseOrderLabels.number} value={poNumber} />
        {!!items?.length && <LabelValueMarkup label="Amount of Purchased Items" value={items.length} />}
      </Container>
      <Container mt={4} display="flex" ai="center" jc="space-between" gap={8}>
        <Button size="small" onClick={onCancel}>
          Cancel
        </Button>
        <Button fullwidth size="small" type="primary" onClick={onOk}>
          Confirm
        </Button>
      </Container>
    </Modal>
  )
}
