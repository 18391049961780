import { CSSProperties, ReactElement, ReactNode } from 'react'

import { typedMemo } from 'types'
import { getTruncatedText } from 'utils'

import { ColorVariant } from './types/colorVariant'
import { FontSize } from './types/fontSize'
import { FontWeight } from './types/fontWeight'
import * as Styled from './styles'

const defaultProps = {
  size: 's',
  as: 'span',
  fontWeight: 'm',
  color: 'primary',
}

export interface TypographyProps {
  size?: FontSize
  as?: keyof JSX.IntrinsicElements
  children: ReactNode
  color?: ColorVariant
  fontWeight?: FontWeight
  display?: 'block' | 'flex' | 'inline' | 'inline-flex' | 'inline-block'
  lineEllipsis?: number
  nowrap?: boolean
  truncateAfterCharAt?: number
  wordBreak?: CSSProperties['wordBreak']
}

export const TypographyBase = ({ children, ...props }: TypographyProps): ReactElement => {
  let child = children

  if (props.truncateAfterCharAt && typeof children === 'string') {
    child = getTruncatedText(children, props.truncateAfterCharAt)
  }

  return <Styled.Typography {...props}>{child}</Styled.Typography>
}

TypographyBase.defaultProps = defaultProps

export const Typography = typedMemo(TypographyBase)
