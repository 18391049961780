import { profileRoute } from 'constant'

import { Tab } from 'designSystem'

import { ChangePassword } from '../routes/ChangePassword'
import { MainInfo } from '../routes/MainInfo'

export const profileTabs: Tab[] = [
  {
    key: profileRoute.mainInfo,
    label: 'Main info',
    children: <MainInfo />,
  },
  {
    key: profileRoute.password,
    label: 'Password',
    children: <ChangePassword />,
  },
]
