import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { Contact, EntityId, PurchaseOrder } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { postNewContactToPO } from '../queryApi'

export const usePostPONewContact = (id?: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation({
    mutationFn: postNewContactToPO,
    onSuccess: ({ data }) => {
      dispatchAppEvent(AppEvent.PurchaseOrderCreateContact)

      queryClient.setQueryData([QueryKey.GetPurchaseOrderById, id], (old: PurchaseOrder | undefined) => {
        return old
          ? {
              ...old,
              contacts: old?.contacts.length ? [...old.contacts, data] : [data],
            }
          : undefined
      })
      showNotification({ title: 'New PO contact added', type: 'success' })
    },
  })

  const post = useCallback(
    (payload: Omit<Contact, 'id'>) => {
      mutate({ id, data: payload })
    },
    [mutate, id],
  )

  return {
    post,
    ...rest,
  }
}
