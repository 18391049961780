import { api, Vendor } from 'api'

import { PostVendorPayload } from './types/vendorCreateForm'

export const postVendor = (data: PostVendorPayload): Promise<Vendor> => {
  return api({
    method: 'post',
    url: '/api/v1/vendors',
    data,
    params: {
      join: ['locationCodes'],
    },
  })
}
