import type { ApiQueryParams, EntityId, ReferenceNumberItem } from 'api'

import { api } from '../api'

export type GetReferenceItemsQueryParams = ApiQueryParams

export type GetReferenceItemsPayload = { data: ReferenceNumberItem[] }

export const referenceItemsDefaultQueryParams = {
  join: [
    'item||id,sku',
    'subLines',
    'subLines.asset||id,assetNumber,assetNumberType',
    'subLines.pickupReference||id,number',
    'subLines.redeliveryReference||id,number',
  ],
  sort: ['id,ASC', 'subLines.id,ASC'],
}

export const getReferenceItems = (
  referenceId?: EntityId,
  params?: GetReferenceItemsQueryParams,
): Promise<GetReferenceItemsPayload> => {
  return api({
    method: 'get',
    url: `/api/v1/references/${referenceId}/items`,
    params: {
      ...referenceItemsDefaultQueryParams,
      ...params,
    },
  })
}
