import { FC } from 'react'

import { ReferenceNumberStatus } from 'api'

import { Tag, TagProps } from 'designSystem'

import { getTagDataByReferenceStatus } from './utils/getTagDataByReferenceStatus'

interface ReferenceStatusTagProps extends Omit<TagProps, 'status'> {
  status: ReferenceNumberStatus
}

export const ReferenceStatusTag: FC<ReferenceStatusTagProps> = ({ status, ...props }) => {
  const { status: tagStatus, text } = getTagDataByReferenceStatus(status)
  return <Tag {...props} status={tagStatus} text={text} />
}
