import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { useFieldArray } from 'hooks'

import { Button, Container, FormItem, Input, Spacer, Typography } from 'designSystem'
import { FieldArrayItem } from 'components'

interface EmailArrayProps {
  name: string
  title: string
}

export const EmailArray: FC<EmailArrayProps> = ({ name, title }) => {
  const { watch } = useFormContext()
  const { fields, remove, append } = useFieldArray({
    name,
  })

  const values = watch(name) || []

  const showAddButton = !values?.some((v: string) => v === undefined)

  const addNewItem = () => append(undefined)
  return (
    <div>
      <Typography fontWeight="xl" size="s">
        {title}
      </Typography>
      <Spacer mb={3} />
      <Container fd="column" maxWidth={260} gap={8}>
        {fields.map((field, index) => (
          <FieldArrayItem key={field.id} onDelete={() => remove(index)} showDelete>
            <FormItem name={`${name}.${index}`}>
              <Input placeholder="Type Email Address" />
            </FormItem>
          </FieldArrayItem>
        ))}
      </Container>
      {showAddButton && (
        <>
          <Spacer mt={3} />
          <Button type="link" icon="plus" onClick={addNewItem}>
            Add
          </Button>
        </>
      )}
    </div>
  )
}
