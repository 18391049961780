import { PurchaseOrderReferenceType } from 'api/types/purchaseOrder'

export const purchaseOrderReferenceTypeOptionsLabel = {
  [PurchaseOrderReferenceType.VendorRelease]: 'Vendor Release',
  [PurchaseOrderReferenceType.Redelivery]: 'Redelivery',
}

export const purchaseOrderReferenceTypeOptions = Object.values(PurchaseOrderReferenceType).map((type) => ({
  label: purchaseOrderReferenceTypeOptionsLabel[type],
  value: type,
}))
