import { Button as BaseButton } from 'antd'
import { css, styled } from 'styled-components'

import { SpacerLevel } from '../Spacer'

interface StyledButtonProps {
  $px?: SpacerLevel
  $width?: number
  $fullwidth?: boolean
  $extraSmall?: boolean
}

export const Button = styled(BaseButton)<StyledButtonProps>`
  display: inline-flex;
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease,
    color 0.3s ease;
  justify-content: center;
  align-items: center;
  line-height: ${({ theme }) => theme.lineHeight.m};
  font-weight: ${({ theme }) => theme.fontWeight.l};
  gap: ${({ theme }) => theme.spacers[2]};
  width: ${({ $width }) => $width && `${$width}px`};

  ${({ $px, theme }) =>
    $px &&
    `padding-left: ${theme.spacers[$px]};
     padding-right: ${theme.spacers[$px]}`};

  ${({ $fullwidth }) => $fullwidth && 'width: 100%'};

  svg {
    display: flex;
  }
  &&&&.ant-btn-dangerous:not(.ant-btn-icon-only) {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.error[400]};
    &:hover {
      background-color: ${({ theme }) => theme.colors.error[300]};
    }
    ${({ disabled, theme }) =>
      disabled &&
      css`
        color: ${theme.colors.placeholder};
        background-color: ${theme.colors.lines};
        &:hover {
          background-color: ${theme.colors.lines};
          border-color: ${theme.colors.lines};
        }
      `}
  }
  &.ant-btn-default:not([disabled]) {
    &:hover {
      color: ${({ theme }) => theme.colors.accent[400]};
      background-color: ${({ theme }) => theme.colors.accent[80]};
      border-color: ${({ theme }) => theme.colors.accent[400]};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.accent[80]};
      border-color: ${({ theme }) => theme.colors.accent[300]};
    }
  }

  &.ant-btn-sm {
    min-height: ${({ theme }) => theme.spacers[9]};
    padding: ${({ theme, $px }) => `${theme.spacers[3]} 
    ${$px ? theme.spacers[$px] : theme.spacers[9]}`};
    font-size: ${({ theme }) => theme.fontSize.xs};
    line-height: ${({ theme }) => theme.lineHeight.s};
  }

  &.ant-btn-icon-only {
    padding: ${({ theme }) => theme.spacers[3]};
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.lines};
    width: ${({ theme }) => theme.spacers[10]};
    height: ${({ theme }) => theme.spacers[10]};
    &:disabled {
      background-color: ${({ theme }) => theme.colors.lines};
    }
    &.ant-btn-default,
    &.ant-btn-primary,
    &.ant-btn-link {
      &:hover {
        background-color: ${({ theme }) => theme.colors.accent[80]};
        border-color: ${({ theme }) => theme.colors.accent[400]};
      }
      &:active {
        background-color: ${({ theme }) => theme.colors.accent[90]};
        border-color: ${({ theme }) => theme.colors.accent[400]};
      }
    }
    &.ant-btn-dangerous {
      &:hover {
        background-color: ${({ theme }) => theme.colors.error[80]};
        border-color: ${({ theme }) => theme.colors.error[400]};
        color: ${({ theme }) => theme.colors.error[400]};
      }
    }
    &&&.ant-btn-sm {
      padding: ${({ theme }) => theme.spacers[3]};
      width: ${({ theme }) => theme.spacers[9]};
      height: ${({ theme }) => theme.spacers[9]};
      svg {
        scale: 0.8;
      }
    }

    &.ant-btn-default {
      color: ${({ theme }) => theme.colors.primary};
      border-color: ${({ theme }) => theme.colors.lines};
      width: ${({ theme }) => theme.spacers[10]};
      height: ${({ theme }) => theme.spacers[10]};
    }
    &.ant-btn-link {
      height: ${({ theme }) => theme.spacers[10]};
      &.ant-btn-sm {
        height: ${({ theme }) => theme.spacers[7]};
        width: ${({ theme }) => theme.spacers[7]};
      }
    }
  }

  &.ant-btn-link {
    padding: ${({ theme }) => `0 ${theme.spacers[2]}`};
    height: ${({ theme }) => theme.spacers[7]};
    &.ant-btn-sm {
      min-height: ${({ theme }) => theme.spacers[4]};
    }
    &:hover {
      color: ${({ theme }) => theme.colors.accent[400]};
      background: ${({ theme }) => theme.colors.accent[80]};
    }
    &:active {
      background: ${({ theme }) => theme.colors.accent[90]};
    }
  }

  &&&.ant-btn-link:disabled {
    background: transparent;
    border: none;
  }
  &&&&.ant-btn-link.ant-btn-dangerous {
    background: transparent;
    color: ${({ theme }) => theme.colors.error[400]};
    &:hover {
      background: ${({ theme }) => theme.colors.error[80]};
    }
    &:active {
      background: ${({ theme }) => theme.colors.error[90]};
    }
  }
  & > .button-icon {
    display: inline-flex;
  }

  ${({ $extraSmall }) =>
    $extraSmall &&
    css`
      &&&&&&.ant-btn {
        height: 16px;
        min-height: 16px;
        min-width: 16px;
        padding: 2px;
      }
      &&&&&&.ant-btn-icon-only {
        width: 16px;
        border: none;
      }
    `}
`
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacers[2]};
`
