import { FC } from 'react'

import { EmailHistoryModule } from 'api'
import { QueryKey } from 'enums'

import { EmailHistoryTable } from 'components'

interface PurchaseOrderHistoryProps {
  id: number
}

export const PurchaseOrderEmailHistory: FC<PurchaseOrderHistoryProps> = ({ id }) => {
  const filters = {
    $and: [
      {
        $or: [
          {
            entityId: { $eq: id },
          },
        ],
      },
      { module: { $eq: EmailHistoryModule.PurchaseOrders } },
    ],
  }

  return <EmailHistoryTable queryKey={QueryKey.GetPOEmailHistory} filters={JSON.stringify(filters)} />
}
