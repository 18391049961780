import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { QueryKey } from 'enums'

import { getPurchaseOrderItems, GetPurchaseOrderItemsPayload } from '../queryApi'

export const useGetPurchaseOrderItems = ({ purchaseOrderId }: GetPurchaseOrderItemsPayload) => {
  const { data, isLoading, fetchStatus, ...rest } = useQuery({
    queryKey: [QueryKey.GetPurchaseOrderItems, purchaseOrderId],
    queryFn: () => getPurchaseOrderItems({ purchaseOrderId }),
    placeholderData: keepPreviousData,
    enabled: !!purchaseOrderId,
  })

  return {
    data,
    isLoading: isLoading && fetchStatus !== 'idle',
    ...rest,
  }
}
