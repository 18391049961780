import { FC } from 'react'
import { Tooltip } from 'antd'

import theme from 'styles/theme'

import { Container, Icon, Typography } from 'designSystem'

interface AllowedItemQtyErrorProps {
  isError?: boolean
  allowedQty?: number
}

export const AllowedItemQty: FC<AllowedItemQtyErrorProps> = ({ isError, allowedQty }) => {
  return (
    !!allowedQty && (
      <Container ai="center" gap={4} width="100%">
        <Container bg="underline" br="small" pa={3} gap={4} nowrap>
          <Typography size="xs" fontWeight="xl">
            {allowedQty}
          </Typography>
          <Typography size="xs" fontWeight="l">
            assets can be booked
          </Typography>
        </Container>
        {isError && (
          <Container ai="center" gap={4}>
            <Tooltip
              title={`You have added qty bigger than is available in Vendor release for current SKU. Total available qty for current SKU: ${allowedQty}`}
            >
              <Icon size={16} icon="errorFilled" color={theme.colors.error[400]} />
            </Tooltip>
          </Container>
        )}
      </Container>
    )
  )
}
