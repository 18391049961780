import { api, User } from 'api'

export type UpdateMePayload = Partial<User>

export const patchMe = (data: UpdateMePayload): Promise<User> => {
  return api({
    method: 'patch',
    url: '/api/v1/users/me',
    data,
    params: {
      join: 'roles',
    },
  })
}
