import * as Yup from 'yup'

import {
  PaymentTerms,
  SalesOrderAccountingStatus,
  SalesOrderPaymentStatus,
  SalesOrderPaymentType,
  SalesOrderStatus,
  TimeRangeValue,
  WeekendDay,
} from 'api'
import { dateValidation, notesValidation, numberBasicValidation, urlValidation } from 'utils'

import {
  maxContactsCount,
  maxInvoiceLength,
  maxItemQty,
  minContactsCount,
  minDepositPaid,
  minItemPrice,
  minItemQty,
} from './recordsCount'

export const salesOrderCreateFormFields = {
  shouldSendEmail: Yup.boolean().default(false),
  customerId: numberBasicValidation.required('Customer is required'),
  customerPurchaseOrder: Yup.string().nullable().default(null),
  shippingAddressId: numberBasicValidation.required('Ship to is required'),
  billingAddressId: numberBasicValidation.required('Bill to is required'),
  quoteUrl: urlValidation.nullable().default(null),
  salesRepresentativeId: numberBasicValidation.nullable().default(null),
  procurementId: numberBasicValidation.nullable().default(null),
  dispatcherId: numberBasicValidation.nullable().default(null),
  expectedDate: dateValidation.nullable().default(null),
  earliestDate: dateValidation.nullable().default(null),
  latestDate: dateValidation.nullable().default(null),
  specificDate: dateValidation.nullable().default(null),
  isWeekendDelivery: Yup.boolean().default(false),
  dayWeekendDelivery: Yup.mixed<WeekendDay[]>().nullable().default(null),
  availableHours: Yup.mixed<TimeRangeValue>().nullable().default(null),
  isReceivingSeparately: Yup.boolean().default(false),
  generalNotes: notesValidation,
  dispatchNotes: notesValidation,
  procurementNotes: notesValidation,
  contactsIds: Yup.array()
    .of(Yup.string().required('Contact required'))
    .min(minContactsCount, `Min ${minContactsCount} contact`)
    .max(maxContactsCount, `Max ${maxContactsCount} contacts`),
  onSiteContactsIds: Yup.array()
    .of(Yup.string().required('Contact required'))
    .default(undefined)
    .max(maxContactsCount, `Max ${maxContactsCount} contacts`),
  // attachments
}

export const salesOrderEditFieldsValidation = {
  ...salesOrderCreateFormFields,
  status: Yup.mixed<SalesOrderStatus>().oneOf(Object.values(SalesOrderStatus)).required('Status is required'),
  depositPaid: numberBasicValidation
    .min(minDepositPaid, `Should be bigger than ${minDepositPaid}`)
    .required('Deposit paid is required'),
  accountingStatus: Yup.mixed<SalesOrderAccountingStatus>()
    .oneOf(Object.values(SalesOrderAccountingStatus))
    .required('Accounting status is required'),
  paymentStatus: Yup.mixed<SalesOrderPaymentStatus>()
    .oneOf(Object.values(SalesOrderPaymentStatus))
    .required('Payment status is required'),
  paymentType: Yup.mixed<SalesOrderPaymentType>()
    .oneOf(Object.values(SalesOrderPaymentType))
    .required('Payment type is required'),
  paymentTerms: Yup.mixed<PaymentTerms>().oneOf(Object.values(PaymentTerms)).required('Payment terms is required'),
  invoice: Yup.string()
    .max(maxInvoiceLength, `Max length of ${maxInvoiceLength} characters`)
    .required('Invoice is required'),
}

export const salesOrderItemValidation = {
  itemId: numberBasicValidation.required('SKU is required'),
  qty: numberBasicValidation
    .min(minItemQty, `Min ${minItemQty} items`)
    .max(maxItemQty, `Max ${maxItemQty} items`)
    .required('Qty is required'),
  price: numberBasicValidation.min(minItemPrice, `Should be bigger than ${minItemPrice}`).required('Price is required'),
  discount: numberBasicValidation.nullable().default(null),
  taxes: numberBasicValidation.required('Taxes is required'),
}

export const salesOrderLineItemFieldsValidation = {
  ...salesOrderItemValidation,
  subLines: Yup.array().of(Yup.object(salesOrderItemValidation)),
}

export const salesOrderCreateSchema = Yup.object({
  ...salesOrderCreateFormFields,
  lineItems: Yup.array()
    .of(Yup.object(salesOrderLineItemFieldsValidation))
    .min(1, 'At least one line item is required')
    .required('Line items are required'),
})
