import { getAccessorTitle } from '../utils/getAccessorTitle'
import { Accessor } from '../types/accessor'

export const globalInventoryVendorRequiredColumns = [Accessor.Index, Accessor.VendorName, Accessor.LocationCode]

export const globalInventoryVendorDefaultColumns = Object.values(Accessor)

export const globalInventoryVendorVisibleColumnsOptions = Object.values(Accessor).map((accessor) => ({
  value: accessor,
  label: getAccessorTitle(accessor),
  disabled: globalInventoryVendorRequiredColumns.includes(accessor),
}))
