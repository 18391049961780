import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react'
import { Col, Row } from 'antd'

import { Contact } from 'api'
import { useFormContext, useGetCustomerContacts, useWatch } from 'hooks'
import { salesOrderLabels, weekendDayOptions, yesNoOptions } from 'constant'
import { SalesOrderFormAccessor } from 'types'

import { Container, DatePicker, FormItem, RadioGroup, SelectMulti, TimeRangePicker, Typography } from 'designSystem'
import { AddContactModal, NewContact } from 'components'
import { ContactFormData } from 'modules/salesOrders/routes/SalesOrderCreate/types/salesOrderCreateForm'

import { maxContactsCount } from 'modules/salesOrders/constants/recordsCount'

interface DateInformationProps {
  onSiteContacts: ContactFormData[]
  setOnSiteContacts: Dispatch<SetStateAction<ContactFormData[]>>
}

export const DateInformation: FC<DateInformationProps> = ({ onSiteContacts, setOnSiteContacts }) => {
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false)

  const isWeekendDelivery = useWatch({ name: SalesOrderFormAccessor.IsWeekendDelivery })
  const customerId = useWatch({ name: SalesOrderFormAccessor.CustomerId })
  const { setValue, getValues } = useFormContext()

  const { data: customerContacts = [], isLoading } = useGetCustomerContacts(customerId)

  const contactsWithNew = [...onSiteContacts, ...customerContacts] as Contact[]

  const contactOptions = contactsWithNew.map((contact) => ({
    value: contact.id || contact.email,
    label: `${contact.firstName} ${contact.lastName}`,
  }))

  const toggleContactModal = useCallback(() => {
    setIsAddContactModalOpen((prev) => !prev)
  }, [])

  const handleCreateNewContact = useCallback(
    (payload: NewContact) => {
      setOnSiteContacts((prev) => [...prev, payload])
      setValue(SalesOrderFormAccessor.OnSiteContactsIds, [
        ...(getValues(SalesOrderFormAccessor.OnSiteContactsIds) || []),
        payload.email,
      ])
      toggleContactModal()
    },
    [getValues, setOnSiteContacts, setValue, toggleContactModal],
  )

  return (
    <Container fd="column" width="100%" gap={8}>
      <Row gutter={8}>
        <Col span={12}>
          <FormItem name={SalesOrderFormAccessor.ExpectedDate} label={salesOrderLabels.expectedDate}>
            <DatePicker />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem name={SalesOrderFormAccessor.EarliestDate} label={salesOrderLabels.earliestDate}>
            <DatePicker />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <FormItem name={SalesOrderFormAccessor.LatestDate} label={salesOrderLabels.latestDate}>
            <DatePicker />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem name={SalesOrderFormAccessor.SpecificDate} label={salesOrderLabels.specificDate}>
            <DatePicker />
          </FormItem>
        </Col>
      </Row>
      <Typography size="xs" fontWeight="l">
        Weekend
      </Typography>
      <FormItem name={SalesOrderFormAccessor.IsWeekendDelivery}>
        <RadioGroup wrap direction="horizontal" spaceSize="small" options={yesNoOptions} />
      </FormItem>
      <Row gutter={8}>
        <Col span={isWeekendDelivery ? 12 : 24}>
          <FormItem name={SalesOrderFormAccessor.AvailableHours}>
            <TimeRangePicker />
          </FormItem>
        </Col>
        {isWeekendDelivery && (
          <Col span={12}>
            <FormItem name={SalesOrderFormAccessor.DayWeekendDelivery}>
              <SelectMulti options={weekendDayOptions} placeholder={'Select Weekend Date'} />
            </FormItem>
          </Col>
        )}
      </Row>
      <Typography size="xs" fontWeight="l">
        {salesOrderLabels.isReceivingSeparately}
      </Typography>
      <FormItem name={SalesOrderFormAccessor.IsReceivingSeparately}>
        <RadioGroup wrap direction="horizontal" spaceSize="small" options={yesNoOptions} />
      </FormItem>
      <Row>
        <Col span={24}>
          <FormItem name={SalesOrderFormAccessor.OnSiteContactsIds} label={salesOrderLabels.onSiteContacts}>
            <SelectMulti
              options={customerId ? contactOptions : []}
              loading={isLoading}
              placeholder={customerId ? 'Start typing' : 'Select customer first'}
              disabled={!customerId}
              withCreateNew={contactsWithNew.length < maxContactsCount}
              onClickCreateNew={toggleContactModal}
            />
          </FormItem>
        </Col>
      </Row>
      <AddContactModal
        open={isAddContactModalOpen}
        onCancel={toggleContactModal}
        onSubmit={handleCreateNewContact}
        contacts={contactsWithNew}
      />
    </Container>
  )
}
