import { styled } from 'styled-components'

export const List = styled.ul`
  margin-inline-start: ${({ theme }) => theme.spacers[4]};
  li {
    font-size: ${({ theme }) => theme.fontSize.xs};
    line-height: ${({ theme }) => theme.lineHeight.s};
    color: ${({ theme }) => theme.colors.secondary};
  }
`
