import { purchaseOrdersRoute } from 'constant'

import { NotFoundPage } from 'components'

import { PurchaseOrderCreate } from '../routes/PurchaseOrderCreate'
import { PurchaseOrdersList } from '../routes/PurchaseOrdersList'

export const routes = [
  {
    path: purchaseOrdersRoute.list,
    element: <PurchaseOrdersList />,
  },
  {
    path: purchaseOrdersRoute.create,
    element: <PurchaseOrderCreate />,
  },

  {
    path: '*',
    element: <NotFoundPage />,
  },
]
