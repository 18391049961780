import Cookies, { CookieAttributes } from 'js-cookie'

import { CookieKey } from 'types'

export const getCookie = (key: keyof typeof CookieKey, parse?: boolean) => {
  const cookieValue = Cookies.get(CookieKey[key])

  if (parse && cookieValue) {
    return JSON.parse(cookieValue)
  }

  return cookieValue
}

export const setCookie = (key: keyof typeof CookieKey, value: string, attrs?: CookieAttributes) =>
  Cookies.set(CookieKey[key], value, attrs)

export const removeCookie = (key: keyof typeof CookieKey) => Cookies.remove(CookieKey[key], { path: '' })
