import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'

import {
  EntityId,
  PurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderItemsSubLine,
  PurchaseOrderType,
  ReferenceNumberType,
} from 'api'
import { useFormContext } from 'hooks'

import { BilledItemModal, Table, TableColumnKey, TableColumnsType } from 'components'
import { prepareNewSubLineData } from 'modules/purchaseOrders/utils/prepareNewSubLineData'

import { useGetExpandableColumns } from '../../hooks/useGetExpandableColumns'
import { maxSubLineCount } from 'modules/purchaseOrders/constants/maxRecordsCount'

interface ExpandableRowProps {
  index: number
  record: Partial<PurchaseOrderItem>
  type: PurchaseOrderType | undefined
  setSubLines: Dispatch<SetStateAction<TableColumnsType<Partial<PurchaseOrderItem>>>>
  allVisibleColumns: string[]
  onDeleteSubLine: ({ itemId, subLineId }: { itemId: EntityId; subLineId: EntityId }) => void
  isAddNew: boolean
  vendorId?: EntityId
  poData?: PurchaseOrder
  allFieldsDisabled: boolean
}

export const ExpandableEditRow: FC<ExpandableRowProps> = ({
  index,
  record,
  type,
  allVisibleColumns,
  allFieldsDisabled,
  onDeleteSubLine,
  setSubLines,
  isAddNew,
  vendorId,
  poData,
}) => {
  const [subLineFieldName, setSubLineFieldName] = useState<string | undefined>(undefined)
  const { setValue, triggerSubmit, getValues } = useFormContext()
  const itemQty = getValues(`items.${index}.qty`) || 0

  const dataSource = record?.subLines?.map(({ id, ...rest }) => ({
    key: id.toString(),
    id,
    type: ReferenceNumberType.VendorRelease,
    ...rest,
  })) as PurchaseOrderItemsSubLine[]

  const onChangeIsBilled = useCallback((name: string) => {
    setSubLineFieldName(name)
  }, [])

  const { columns } = useGetExpandableColumns({
    allFieldsDisabled,
    index,
    record,
    type,
    onDeleteSubLine,
    onChangeIsBilled,
    vendorId,
  })

  const onSubmit = useCallback(
    (data: string) => {
      setValue(`${subLineFieldName}.asset.isBilled`, true)
      setValue(`${subLineFieldName}.asset.billingReference`, data)
      setSubLineFieldName(undefined)
      triggerSubmit?.()
    },
    [setValue, subLineFieldName, triggerSubmit],
  )

  const onCancel = useCallback(() => {
    setSubLineFieldName(undefined)
  }, [])

  useEffect(() => {
    if (columns) {
      setSubLines(columns)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filteredColumns = columns.filter(
    ({ key }) => (typeof key === 'string' && allVisibleColumns.includes(key)) || key === TableColumnKey.HoverActions,
  )

  const onAddNewRow = () => {
    const prevSubLines = getValues(`items.${index}.subLines`)
    const newSubLine = prepareNewSubLineData(poData)
    setValue(`items.${index}.qty`, itemQty + 1)
    setValue(`items.${index}.subLines`, [...prevSubLines, newSubLine])
    triggerSubmit?.()
  }

  const allowToAddNewRow = itemQty < maxSubLineCount && !allFieldsDisabled
  return (
    <>
      <Table<Partial<PurchaseOrderItemsSubLine>>
        viewOnly={isAddNew}
        scroll={{ x: 800, ...(!!record.subLines && record.subLines.length > 10 && { y: 390 }) }}
        dataSource={dataSource}
        columns={filteredColumns}
        onAddNewRow={allowToAddNewRow ? onAddNewRow : undefined}
      />
      <BilledItemModal open={!!subLineFieldName} onCancel={onCancel} onSubmit={onSubmit} />
    </>
  )
}
