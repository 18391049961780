import { ReferenceNumberAssetStatus } from 'api'
import { referenceNumberAssetStatusLabel } from 'constant'

import { TagType } from 'designSystem'

interface ReferenceNumberAssetTagData {
  status: TagType
  text?: string
}

export const getTagDataByReferenceNumberAssetStatus = (
  status: ReferenceNumberAssetStatus,
): ReferenceNumberAssetTagData => {
  const text = referenceNumberAssetStatusLabel[status]

  switch (status) {
    case ReferenceNumberAssetStatus.Closed:
      return {
        status: TagType.Done,
        text,
      }
    case ReferenceNumberAssetStatus.Voided:
    case ReferenceNumberAssetStatus.Rejected:
      return {
        status: TagType.Error,
        text,
      }
    case ReferenceNumberAssetStatus.Pending:
      return {
        status: TagType.Warning,
        text,
      }
    case ReferenceNumberAssetStatus.PendingRedelivery:
      return {
        status: TagType.Pending,
        text,
      }
    case ReferenceNumberAssetStatus.Received:
      return {
        status: TagType.Success,
        text,
      }
    default:
      return {
        status: TagType.Default,
        text,
      }
  }
}
