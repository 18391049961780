import { Divider as BaseDivider } from 'antd'
import { styled } from 'styled-components'

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 12px ${({ theme }) => theme.spacers[3]};
  background: ${({ theme }) => theme.colors.underline};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  margin-bottom: ${({ theme }) => theme.spacers[4]};
`
export const UserContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacers[3]};
  max-width: 200px;
`

export const Divider = styled(BaseDivider)`
  &&.ant-divider {
    margin: 0;
  }
`
