import { FC } from 'react'

import { Asset } from 'api'
import { QueryKey } from 'enums'
import { useAssetsTable } from 'hooks'
import { getEqualsFilter } from 'utils'

import { Container } from 'designSystem'
import { Table, TableSearchSingle, TableTitle } from 'components'

import { getTableColumns } from './utils/getTableColumns'

interface DepotAssetsProps {
  vendorDepotId?: number
}

export const DepotAssets: FC<DepotAssetsProps> = ({ vendorDepotId }) => {
  const { dataSource, loading, pagination, onChange, total, getFieldSorter, offsetCount, TableContextProvider } =
    useAssetsTable({
      queryKey: QueryKey.GetDepotAssets,
      enabled: !!vendorDepotId,
      params: {
        join: ['items||id,sku'],
        fields: 'id,assetNumber,items,cost,status,createdAt,vendorDepotId',
      },
      defaultFilters: {
        ...getEqualsFilter('vendorDepotId', { value: vendorDepotId }),
      },
    })
  const columns = getTableColumns(getFieldSorter, offsetCount)

  return (
    <Container pa={4} fd="column" gap={16}>
      <TableContextProvider>
        <TableTitle
          withoutDefaultPadding
          title="Depot Assets"
          total={total}
          search={<TableSearchSingle searchFieldName={['assetNumber']} />}
        />
        <Table<Asset>
          dataSource={dataSource}
          loading={loading}
          columns={columns}
          pagination={pagination}
          onChange={onChange}
          scroll={{ x: 900 }}
        />
      </TableContextProvider>
    </Container>
  )
}
