import type { EntityId, VendorContainerSettings, VendorInventory, VendorPartsSettings, VendorType } from 'api'
import { api } from 'api'
import { Pagination } from 'types'

import { getTypeUrlByVendorType } from 'modules/vendors/utils/getTypeUrlByVendorType'

import { InventoryPayload } from './types/inventoryCreateForm'

export interface GetInventoryResponse extends Pagination {
  data?: VendorInventory[]
}

export const postInventory = ({
  vendorId,
  data,
  type,
}: {
  vendorId?: EntityId
  data?: InventoryPayload
  type: VendorType
}): Promise<VendorContainerSettings['inventory'] | VendorPartsSettings['inventory']> => {
  const typeUrl = getTypeUrlByVendorType(type)

  return api({
    method: 'post',
    url: `/api/v1/vendors/${vendorId}/types/${typeUrl}/inventory`,
    data,
    params: {
      join: ['locationCode'],
    },
  })
}

export const patchInventory = ({
  vendorId,
  inventoryId,
  data,
  type,
}: {
  vendorId?: EntityId
  inventoryId?: EntityId
  data?: InventoryPayload
  type: VendorType
}): Promise<VendorContainerSettings['inventory'] | VendorPartsSettings['inventory']> => {
  const typeUrl = getTypeUrlByVendorType(type)

  return api({
    method: 'patch',
    url: `/api/v1/vendors/${vendorId}/types/${typeUrl}/inventory/${inventoryId}`,
    data,
    params: {
      join: ['locationCode'],
    },
  })
}

export const deleteInventory = ({
  vendorId,
  inventoryId,
  type,
}: {
  vendorId?: EntityId
  inventoryId?: EntityId
  type: VendorType
}): Promise<void> => {
  const typeUrl = getTypeUrlByVendorType(type)

  return api({
    method: 'delete',
    url: `/api/v1/vendors/${vendorId}/types/${typeUrl}/inventory/${inventoryId}`,
  })
}
