import { Link } from 'react-router-dom'

import { ReferenceNumber, ReferenceNumberItem } from 'api'
import type { TableField, TableSortingOrder } from 'hooks'
import { getReferenceTypeOptionLabel, hashRoute, vendorViewRoute } from 'constant'
import { formatDate } from 'utils'

import { Typography } from 'designSystem'
import { ReferenceStatusTag, RelatedLinksList, TableColumnKey, TableColumnsType } from 'components'

import { Accessor } from '../types/accessor'

import { getAccessorTitle } from './getAccessorTitle'
import { getItemsAssets } from './getItemsAssets'

export const getTableColumns = (
  getFieldSorter: (field: TableField) => TableSortingOrder,
  offsetCount: number,
): TableColumnsType<ReferenceNumber> => [
  {
    title: getAccessorTitle(Accessor.Index),
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => offsetCount + index + 1,
  },
  {
    title: getAccessorTitle(Accessor.Number),
    key: Accessor.Number,
    dataIndex: Accessor.Number,
    width: 500,
    sorter: true,
    sortOrder: getFieldSorter(Accessor.Number),
    ellipsis: true,
    render: (number: string, record) => (
      <Link to={hashRoute.reference(record.id)}>
        <Typography color="primary" size="xs" fontWeight="l" lineEllipsis={1}>
          {number}
        </Typography>
      </Link>
    ),
  },
  {
    title: getAccessorTitle(Accessor.Type),
    key: Accessor.Type,
    dataIndex: Accessor.Type,
    width: 150,
    sorter: true,
    sortOrder: getFieldSorter(Accessor.Type),
    ellipsis: true,
    render: (type) => getReferenceTypeOptionLabel(type),
  },
  {
    title: getAccessorTitle(Accessor.CreatedAt),
    key: Accessor.CreatedAt,
    dataIndex: Accessor.CreatedAt,
    width: 120,
    sorter: true,
    sortOrder: getFieldSorter(Accessor.CreatedAt),
    ellipsis: true,
    render: (createdAt) => formatDate(createdAt),
  },
  {
    title: getAccessorTitle(Accessor.LocationCode),
    key: Accessor.LocationCode,
    dataIndex: Accessor.LocationCode,
    width: 200,
    sorter: true,
    sortOrder: getFieldSorter(Accessor.LocationCode),
    ellipsis: true,
    render: (locationCode) =>
      locationCode ? (
        <RelatedLinksList entity="locationCodes" data={{ id: locationCode?.id, title: locationCode?.code }} />
      ) : (
        '-'
      ),
  },
  {
    title: getAccessorTitle(Accessor.DepotSetting),
    key: Accessor.DepotSetting,
    dataIndex: Accessor.DepotSetting,
    width: 200,
    sorter: true,
    sortOrder: getFieldSorter(Accessor.DepotSetting),
    ellipsis: true,
    render: (depot) =>
      depot ? (
        <RelatedLinksList
          entity="vendors"
          subRoute={vendorViewRoute.depot}
          data={{ id: depot?.vendorId, title: depot?.code }}
        />
      ) : (
        '-'
      ),
  },
  {
    title: getAccessorTitle(Accessor.Items),
    key: Accessor.Items,
    dataIndex: Accessor.Items,
    width: 200,
    ellipsis: true,
    render: (items: ReferenceNumberItem[]) => {
      const data = items?.map(({ itemId, item }) => ({
        id: itemId,
        title: item?.sku,
      }))
      return <RelatedLinksList entity="items" data={data} />
    },
  },
  {
    title: getAccessorTitle(Accessor.Assets),
    key: Accessor.Assets,
    width: 200,
    ellipsis: true,
    render: (_, record) => {
      const data = getItemsAssets(record?.items)
      return <RelatedLinksList entity="assets" data={data} />
    },
  },
  {
    title: getAccessorTitle(Accessor.Status),
    key: Accessor.Status,
    dataIndex: Accessor.Status,
    width: 180,
    sorter: true,
    sortOrder: getFieldSorter(Accessor.Status),
    ellipsis: true,
    render: (status) => <ReferenceStatusTag status={status} />,
  },
  {
    title: getAccessorTitle(Accessor.Notes),
    key: Accessor.Notes,
    dataIndex: Accessor.Notes,
    width: 180,
    ellipsis: true,
    render: (notes) => notes || '-',
  },
  {
    title: getAccessorTitle(Accessor.RelatedReference),
    key: Accessor.RelatedReference,
    width: 200,
    ellipsis: true,
    render: (_, record) => {
      const relatedRecords = [
        record.vendorRelease,
        record.bookingNumber,
        ...(record.bookingNumbers || []),
        ...(record.redeliveryNumbers || []),
      ]
        .filter(Boolean)
        .map((ref) => ({
          id: ref?.id as number,
          title: ref?.number as string,
        }))
      return relatedRecords.length ? (
        <RelatedLinksList entity="reference" linkProps={{ target: '_self' }} data={relatedRecords} />
      ) : (
        '-'
      )
    },
  },
]
