import { AssetCodeAdditionalAttribute } from 'api/types/assetCode/assetCodeAdditionalAttribute'

export const assetCodeAdditionalAttributesOptions: { value: AssetCodeAdditionalAttribute; label: string }[] = [
  {
    value: AssetCodeAdditionalAttribute.OpenSide,
    label: 'Open Side',
  },
  {
    value: AssetCodeAdditionalAttribute.DoubleDoor,
    label: 'Double Door',
  },
  {
    value: AssetCodeAdditionalAttribute.HardTop,
    label: 'Hard Top',
  },
  {
    value: AssetCodeAdditionalAttribute.SoftTop,
    label: 'Soft Top',
  },
]
