import { FC } from 'react'

import { useFieldArrayItemWithMultipleEndpoints } from 'hooks'

import { ConfirmationButtons, Container, EditFormItem } from 'designSystem'
import { Card } from 'components/Card'
import { FormItemEditInput } from 'components/FormElements/FormItemEditInput'

interface EditContactProps {
  index: number
  name: string
  onCancel: (index: number) => void
  onDelete: (index: number) => void
  onSubmitPost: (index: number) => void
  onSubmitPatch: (index: number) => void
  showDelete?: boolean
  isLoading?: boolean
}

export const EditContact: FC<EditContactProps> = ({
  name,
  index,
  onCancel,
  onDelete,
  onSubmitPost,
  onSubmitPatch,
  showDelete,
  isLoading,
}) => {
  const {
    id,
    value,
    isError,
    isLoading: isCurrentLoading,
    showDeleteButton,
    handleSubmitPost,
    handleSubmitPatch,
    handleDelete,
  } = useFieldArrayItemWithMultipleEndpoints({
    name,
    isLoading,
    onSubmitPost,
    onSubmitPatch,
    onDelete,
    showDelete,
    index,
  })

  const { firstName, lastName, email } = value || {}
  const isConfirmationDisabled = !firstName || !lastName || !email || isError || isCurrentLoading

  return (
    <Card pa={4} isLoading={isCurrentLoading} showDelete={showDeleteButton} onClickDelete={handleDelete}>
      <Container gap={4} fd="column">
        <EditFormItem
          name={`${name}.${index}.firstName`}
          label="First Name"
          render={({ field }) => (
            <FormItemEditInput placeholder="Type First Name" {...field} onBlur={handleSubmitPatch} />
          )}
        />
        <EditFormItem
          name={`${name}.${index}.lastName`}
          label="Last Name"
          render={({ field }) => (
            <FormItemEditInput placeholder="Type Last Name" {...field} onBlur={handleSubmitPatch} />
          )}
        />
        <EditFormItem
          name={`${name}.${index}.phoneExtension`}
          label="Phone Number Extension"
          render={({ field }) => (
            <FormItemEditInput placeholder="Type Extension" {...field} onBlur={handleSubmitPatch} />
          )}
        />
        <EditFormItem
          name={`${name}.${index}.phoneNumber`}
          label="Phone Number"
          render={({ field }) => (
            <FormItemEditInput placeholder="Type Phone Number" {...field} onBlur={handleSubmitPatch} type="phone" />
          )}
        />
        <EditFormItem
          name={`${name}.${index}.email`}
          label="Email"
          render={({ field }) => <FormItemEditInput placeholder="Type Email" {...field} onBlur={handleSubmitPatch} />}
        />
        <EditFormItem
          name={`${name}.${index}.notes`}
          label="Notes"
          render={({ field }) => <FormItemEditInput placeholder="Type Notes" {...field} onBlur={handleSubmitPatch} />}
        />
      </Container>
      {!id && (
        <ConfirmationButtons
          trigger="click"
          onConfirm={handleSubmitPost}
          onCancel={() => onCancel(index)}
          disabled={isConfirmationDisabled}
        />
      )}
    </Card>
  )
}
