import { FC } from 'react'

import { PurchaseOrder } from 'api'

import { Container, Typography } from 'designSystem'
import { Table } from 'components'

import { assetPurchaseOrderColumns } from './constants/assetPurchaseOrderColumns'

interface AssetPurchaseOrdersProps {
  data?: PurchaseOrder
}

export const AssetPurchaseOrder: FC<AssetPurchaseOrdersProps> = ({ data }) => {
  const dataSource = data ? [{ ...data, key: data.id }] : []

  return (
    <Container fd="column" gap={16} pa={4}>
      <Typography size="l" fontWeight="xl" as="h2">
        Purchase Order
      </Typography>
      <Table<PurchaseOrder> dataSource={dataSource} columns={assetPurchaseOrderColumns} scroll={{ x: 900 }} />
    </Container>
  )
}
