import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'
import { useItemUpdateCategoryName } from 'store'

import { showNotification } from 'designSystem'

import { patchCategoryItem, PatchCategoryItemPayload } from '../queryApi'

export const usePatchCategoryItem = () => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const updateCategoryName = useItemUpdateCategoryName()

  const { mutate, ...rest } = useMutation({
    mutationFn: patchCategoryItem,
    onSuccess: (_, variables) => {
      if (variables.name) {
        updateCategoryName(variables.name, variables.id)
        dispatchAppEvent(AppEvent.CategoryUpdate)
      }

      showNotification({ title: 'Saved', type: 'success' })
    },
  })

  const updateCategoryItem = useCallback(
    (payload: PatchCategoryItemPayload) => {
      mutate(payload)
    },
    [mutate],
  )

  return {
    updateCategoryItem,
    ...rest,
  }
}
