import { FC } from 'react'

import { ReferenceNumber, ReferenceNumberBooking, ReferenceNumberBookingType, ReferenceNumberType } from 'api'
import { useForm, useReferences, useSalesOrders } from 'hooks'
import {
  bookingStatusOptions,
  hashRoute,
  referenceLabels,
  referenceNumberBookingTransactionTypeOptions,
  referenceNumberBookingTypeOptions,
  route,
  vendorViewRoute,
} from 'constant'
import { ReferenceFormAccessor, typedMemo } from 'types'

import { Container, EditFormItem, EditView, GridLayout, Icon, Spacer, Typography } from 'designSystem'
import {
  FormItemEditDatePicker,
  FormItemEditInput,
  FormItemEditSelectSingle,
  FormItemEditSelectSingleAsync,
  FormItemEditTextArea,
  LabelValueMarkup,
  ReferenceStatusTag,
  StripLoader,
} from 'components'
import { BookingNumberForm, ReferenceNumberByTypeProps } from 'modules/references/types'
import { getVendorReleaseItemsQty } from 'modules/references/utils/getVendorReleaseItemsQty'
import { getViewOnlyFields } from 'modules/references/utils/getViewOnlyFields'

import { useUpdateBookingNumber } from './hooks/useUpdateBookingNumber'
import { bookingNumberFieldsValidation } from 'modules/references/constants/fieldsValidation'
import { AvailableAssets } from 'modules/references/elements/AvailableAssets'
import { ReferenceItemsEdit } from 'modules/references/elements/ReferenceItemsEdit'

import { RelatedPOs } from '../RelatedPOs'
import { RelatedReferenceNumber } from '../RelatedReferenceNumber'

interface BookingProps extends ReferenceNumberByTypeProps {
  data: ReferenceNumberBooking
}

const BookingBase: FC<BookingProps> = ({ canEditOnlyStatus, data }) => {
  const { update, isError, isPending } = useUpdateBookingNumber(data.id)

  const { Form, triggerSubmit } = useForm<Omit<BookingNumberForm, 'items'>>({
    mode: 'onChange',
    onSubmit: update,
    isSubmitError: isError,
    validationSchema: bookingNumberFieldsValidation,
    defaultValues: data,
    viewOnlyFields: canEditOnlyStatus
      ? getViewOnlyFields(ReferenceFormAccessor.Status)
      : [
          ReferenceFormAccessor.CreatedAt,
          ReferenceFormAccessor.PurchaseOrderId,
          ReferenceFormAccessor.SalesOrderId,
          ReferenceFormAccessor.TransactionType,
          ReferenceFormAccessor.LocationCodeId,
          ReferenceFormAccessor.DepotSettingId,
          ReferenceFormAccessor.BookingType,
          ReferenceFormAccessor.VendorReleaseId,
        ],
  })

  const poData = data?.vendorRelease?.purchaseOrder
  const salesOrderDefaultOption = data?.salesOrder
    ? { value: data.salesOrder.id, label: data.salesOrder.number }
    : undefined
  const vendorReleaseDefaultOption = data?.vendorRelease
    ? { value: data.vendorRelease.id, label: data.vendorRelease.number }
    : undefined
  const vendorReleaseItems = getVendorReleaseItemsQty(data?.vendorRelease?.items)
  const isVendorReleaseBookingType = data?.bookingType && data.bookingType === ReferenceNumberBookingType.VendorRelease
  const showGateInLocationAndDepot = data?.gateInLocationCode && data?.gateInDepotSetting
  return (
    <>
      <Form>
        <StripLoader noBorderRadius isLoading={isPending} />
        <GridLayout columns={3} gap={16}>
          <Container fd="column" width="100%">
            <Typography fontWeight="xl">Booking Details</Typography>
            <Container mt={4} gap={4} fd="column">
              <EditFormItem
                name={ReferenceFormAccessor.Status}
                label={referenceLabels.status}
                render={({ field }) => (
                  <FormItemEditSelectSingle
                    {...field}
                    customPreview={
                      <ReferenceStatusTag status={field.value} icon={<Icon icon="arrowDown" size={16} />} />
                    }
                    options={bookingStatusOptions}
                    onSubmit={triggerSubmit}
                  />
                )}
              />
              <EditFormItem
                name={ReferenceFormAccessor.CreatedAt}
                label={referenceLabels.createdAt}
                render={({ field }) => <FormItemEditDatePicker {...field} />}
              />
              <EditFormItem
                name={ReferenceFormAccessor.BookingType}
                label={referenceLabels.bookingType}
                render={({ field }) => (
                  <FormItemEditSelectSingle {...field} options={referenceNumberBookingTypeOptions} />
                )}
              />
              <EditFormItem
                name={ReferenceFormAccessor.TransactionType}
                label={referenceLabels.transactionType}
                render={({ field }) => (
                  <FormItemEditSelectSingle {...field} options={referenceNumberBookingTransactionTypeOptions} />
                )}
              />
              <LabelValueMarkup
                showTooltip={false}
                label={referenceLabels.gateOutLocationId}
                typographyPropsValue={data?.locationCode ? undefined : { color: 'secondary' }}
                value={data?.locationCode ? data.locationCode.code : 'Empty'}
                href={data?.locationCode ? `${route.locationCodes}/${data?.locationCode.id}` : undefined}
              />
              <LabelValueMarkup
                showTooltip={false}
                label={referenceLabels.gateOutDepotId}
                typographyPropsValue={data?.depotSetting ? undefined : { color: 'secondary' }}
                value={data?.depotSetting ? data.depotSetting.code : 'Empty'}
                href={
                  data?.depotSetting
                    ? `${route.vendors}/${data.depotSetting.vendorId}${vendorViewRoute.depot}`
                    : undefined
                }
              />
              {showGateInLocationAndDepot && (
                <>
                  <LabelValueMarkup
                    showTooltip={false}
                    label={referenceLabels.gateInLocationCodeId}
                    typographyPropsValue={data?.gateInLocationCode ? undefined : { color: 'secondary' }}
                    value={data?.gateInLocationCode ? data.gateInLocationCode.code : 'Empty'}
                    href={
                      data?.gateInLocationCode ? `${route.locationCodes}/${data?.gateInLocationCode.id}` : undefined
                    }
                  />
                  <LabelValueMarkup
                    showTooltip={false}
                    label={referenceLabels.gateInDepotSettingId}
                    typographyPropsValue={data?.gateInDepotSetting ? undefined : { color: 'secondary' }}
                    value={data?.gateInDepotSetting ? data.gateInDepotSetting.code : 'Empty'}
                    href={
                      data?.gateInDepotSetting
                        ? `${route.vendors}/${data.gateInDepotSetting.vendorId}${vendorViewRoute.depot}`
                        : undefined
                    }
                  />
                </>
              )}
              <FormItemEditSelectSingleAsync
                name={ReferenceFormAccessor.SalesOrderId}
                label={referenceLabels.salesOrderId}
                getItems={useSalesOrders}
                defaultOption={salesOrderDefaultOption}
                onSubmit={triggerSubmit}
                href={route.so}
              />
            </Container>
          </Container>
          <Container fd="column" width="100%" pt={7} mt={4}>
            <Container gap={4} fd="column">
              {isVendorReleaseBookingType && (
                <FormItemEditSelectSingleAsync
                  name={ReferenceFormAccessor.VendorReleaseId}
                  label={referenceLabels.vendorRelease}
                  getItems={useReferences}
                  defaultOption={vendorReleaseDefaultOption}
                  onSubmit={triggerSubmit}
                  href={route.reference}
                />
              )}
              <LabelValueMarkup
                label={referenceLabels.purchaseOrderId}
                value={poData?.number || 'Empty'}
                href={poData?.number ? hashRoute.po(poData.id) : undefined}
                typographyPropsValue={!poData?.number ? { color: 'secondary' } : undefined}
              />
              {!isVendorReleaseBookingType && (
                <EditFormItem
                  name={ReferenceFormAccessor.AssetPrefix}
                  label={referenceLabels.assetPrefix}
                  render={({ field }) => (
                    <FormItemEditInput placeholder="Type asset prefix" {...field} onBlur={triggerSubmit} />
                  )}
                />
              )}
              <AvailableAssets referenceId={data.id} />
              {!isVendorReleaseBookingType && (
                <EditFormItem
                  name={ReferenceFormAccessor.DepotNotes}
                  label={referenceLabels.depotNotes}
                  layout="vertical"
                  render={({ field }) => (
                    <FormItemEditTextArea placeholder="Type depot notes" {...field} onConfirm={triggerSubmit} />
                  )}
                />
              )}
              <EditFormItem
                name={ReferenceFormAccessor.ExpirationDate}
                label={referenceLabels.expirationDate}
                render={({ field }) => <FormItemEditDatePicker {...field} onBlur={triggerSubmit} />}
              />
            </Container>
          </Container>
          <Container fd="column" width="100%">
            <Typography fontWeight="xl">Details</Typography>
            <Container mt={4} gap={4} fd="column">
              <EditFormItem
                name={ReferenceFormAccessor.Notes}
                label={referenceLabels.notes}
                layout="vertical"
                render={({ field }) => (
                  <FormItemEditTextArea placeholder="Type notes" {...field} onConfirm={triggerSubmit} />
                )}
              />
            </Container>
          </Container>
        </GridLayout>
      </Form>
      <Spacer mt={9} />
      <ReferenceItemsEdit
        id={data?.id}
        type={data?.type}
        data={data?.items}
        isFormViewOnly={canEditOnlyStatus}
        referenceData={{
          vendorReleaseId: data?.vendorReleaseId,
          depotSettingId: data?.depotSettingId,
          transactionType: data?.transactionType,
          bookingType: data?.bookingType,
        }}
        vendorReleaseItems={vendorReleaseItems}
      >
        {data?.vendorReleaseId && (
          <Container gap={4} ai="center">
            <Typography size="s" fontWeight="l" color="secondary">
              Vendor Release:
            </Typography>
            <EditView
              as="select-link"
              value={data?.vendorRelease?.number}
              href={hashRoute.reference(data?.vendorReleaseId)}
            />
          </Container>
        )}
      </ReferenceItemsEdit>

      <Container gap={16} mt={4} fd="column">
        {poData && <RelatedPOs data={poData} />}
        {!!data?.redeliveryNumbers?.length && (
          <RelatedReferenceNumber
            type={ReferenceNumberType.Redelivery}
            data={data?.redeliveryNumbers as ReferenceNumber[]}
          />
        )}
      </Container>
    </>
  )
}

export const Booking = typedMemo(BookingBase)
