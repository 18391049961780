import { FlatFee } from 'api/types/vendor/flatFee'

export const flatFeeOptionLabel = {
  [FlatFee.PerContainer]: 'Per Container',
  [FlatFee.PerMonth]: 'Per Month',
  [FlatFee.PerWeek]: 'Per Week',
}

export const flatFeeOptions = Object.keys(flatFeeOptionLabel).map((key) => ({
  value: key as FlatFee,
  label: flatFeeOptionLabel[key as FlatFee],
}))
