import { api, User } from 'api'

export interface CreateUserPayload {
  firstName: string
  lastName: string
  phoneNumber?: string
  phoneExtension?: string
  rolesIds?: number[]
  email: string
}

export const postUser = (data: CreateUserPayload): Promise<User> => {
  return api({
    method: 'post',
    url: '/api/v1/users',
    data,
    params: {
      join: 'roles',
    },
  })
}
