export enum ReferenceFormAccessor {
  Number = 'number',
  Type = 'type',
  Status = 'status',
  CreatedAt = 'createdAt',
  ExpirationDate = 'expirationDate',
  Notes = 'notes',
  LocationCodeId = 'locationCodeId',
  GateInLocationCodeId = 'gateInLocationCodeId',
  GateInDepotSettingId = 'gateInDepotSettingId',
  DepotSettingId = 'depotSettingId',
  PurchaseOrderId = 'purchaseOrderId',
  AvailableAssetsIds = 'availableAssetsIds',
  TransactionType = 'transactionType',
  BookingNumbersIds = 'bookingNumbersIds',
  BookingType = 'bookingType',
  AssetPrefix = 'assetPrefix',
  DepotNotes = 'depotNotes',
  Assets = 'assets',
  VendorReleaseId = 'vendorReleaseId',
  BookingNumberId = 'bookingNumberId',
  SalesOrderId = 'salesOrderId',
  Dpp = 'dpp',
  CustomerId = 'customerId',
  CustomerAddressId = 'customerAddressId',
  Contacts = 'contacts',
  Urgency = 'urgency',
  PickupDate = 'pickupDate',
  ScheduledPickupDate = 'scheduledPickupDate',
  DispatchedDate = 'dispatchedDate',
  VendorId = 'vendorId',
  DispatcherId = 'dispatcherId',
  TransportationPrice = 'transportationPrice',
  TransportationCost = 'transportationCost',
  Items = 'items',
}
