import { PurchaseOrder } from 'api'
import { useTable } from 'hooks'
import { AppModule } from 'types'

import { useGetVendorPurchaseOrders } from './useGetVendorPurchaseOrders'

interface UseVendorPurchaseOrdersProps {
  vendorId: number
}

export const useVendorPurchaseOrders = ({ vendorId }: UseVendorPurchaseOrdersProps) => {
  const { pagination, onChange, getFieldSorter, sortQueryParams, searchQueryParams, ...props } =
    useTable<PurchaseOrder>({
      module: AppModule.VendorPurchaseOrders,
    })

  const parsedSearchQueryParams = searchQueryParams ? JSON.parse(searchQueryParams) : undefined

  const updatedSearchQuery = {
    $and: [
      ...(parsedSearchQueryParams?.$and || []),
      {
        vendorId: { $in: [vendorId] },
      },
    ],
  }

  const { data, total, isLoading, isFetching } = useGetVendorPurchaseOrders({
    limit: pagination.limit,
    page: pagination.page,
    sort: sortQueryParams,
    s: JSON.stringify(updatedSearchQuery),
  })

  return {
    data: data || [],
    pagination: {
      ...pagination,
      current: pagination.page,
      pageSize: pagination.limit,
      total,
    },
    getFieldSorter,
    onTableChange: onChange,
    isLoading: isLoading || isFetching,
    offsetCount: (pagination.page - 1) * pagination.limit,
    ...props,
  }
}
