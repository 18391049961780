import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { VendorCarrierPriceListItemType } from 'api'
import { useForm } from 'hooks'

import { Button, Container, FormItem, Modal, RadioGroup, Typography } from 'designSystem'

import { usePostCarrierPriceList } from './hooks/usePostCarrierPriceList'
import { carrierPriceListItemTypeOptions } from './constants/carrierPriceListItemTypeOptions'
import { validationSchema } from './constants/validationSchema'
import { CarrierPriceListPayload } from '../../types/carrierPriceListPayload'
import { OptionFour } from './elements/OptionFour'
import { OptionOne } from './elements/OptionOne'
import { OptionThree } from './elements/OptionThree'
import { OptionTwo } from './elements/OptionTwo'

interface AddPriceListItemModalProps {
  open: boolean
  closeModal: () => void
}

export const AddPriceListItemModal: FC<AddPriceListItemModalProps> = ({ open, closeModal }) => {
  const { id } = useParams()

  const { post, isPending, isSuccess } = usePostCarrierPriceList(Number(id))

  const { Form, handleSubmit, watch, reset } = useForm<CarrierPriceListPayload>({
    validationSchema,
    mode: 'onChange',
    isLoading: isPending,
    onSubmit: post,
    shouldFocusError: false,
    defaultValues: {
      option: { type: VendorCarrierPriceListItemType.One },
    },
  })

  const type = watch('option.type')

  useEffect(() => {
    if (isSuccess) {
      closeModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  useEffect(() => {
    if (!open) {
      reset()
    }
  }, [open, reset])

  return (
    <Modal
      centered
      width={680}
      open={open}
      footer={null}
      onCancel={closeModal}
      destroyOnClose
      title="New Pricelist Option"
    >
      <Form onSubmit={handleSubmit(post)}>
        <Container fd="column" gap={16}>
          <Typography fontWeight="xl">Select Pricelist Option Type</Typography>
          <FormItem name="option.type">
            <RadioGroup spaceSize="small" options={carrierPriceListItemTypeOptions} />
          </FormItem>
          <Typography fontWeight="xl">Complete Fields</Typography>
          {type === VendorCarrierPriceListItemType.One && <OptionOne />}
          {type === VendorCarrierPriceListItemType.Two && <OptionTwo />}
          {type === VendorCarrierPriceListItemType.Three && <OptionThree />}
          {type === VendorCarrierPriceListItemType.Four && <OptionFour />}
        </Container>
        <Container mt={9}>
          <Button fullwidth type="primary" htmlType="submit" loading={isPending}>
            Save
          </Button>
        </Container>
      </Form>
    </Modal>
  )
}
