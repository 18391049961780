import { FC, useEffect } from 'react'

import { useFormContext } from 'hooks'

import { Container, Typography } from 'designSystem'
import { Card } from 'components'

import { FeedSettings } from '../FeedSettings'

interface ModificationsSettingsProps {
  isEdit?: boolean
}
export const ModificationsSettings: FC<ModificationsSettingsProps> = ({ isEdit }) => {
  const { unregister } = useFormContext()

  useEffect(() => {
    return () => {
      unregister('modificationsSettings')
    }
  }, [unregister])

  return (
    <Card bordered={!isEdit} pa={isEdit ? 0 : undefined}>
      {!isEdit && (
        <Typography size="l">
          <Typography size="l" fontWeight="xl">
            Modifications
          </Typography>{' '}
          Settings
        </Typography>
      )}
      <Container maxWidth={390} pt={2} pb={4}>
        <Typography as="p" color="secondary" size="xs">
          You will set up 1 rule for all modifications now, but later each modification can be rewritten
        </Typography>
      </Container>
      <FeedSettings name="modificationsSettings" />
    </Card>
  )
}
