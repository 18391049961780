import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { useGetRoles } from 'hooks'

import { accessManagementRoutes } from './constants/accessManagementRoutes'

export const AccessManagement: FC = () => {
  useGetRoles()

  return (
    <>
      <Routes>
        {accessManagementRoutes.map((accessManagementRoute) => (
          <Route
            key={accessManagementRoute.path}
            path={accessManagementRoute.path}
            element={accessManagementRoute.element}
          />
        ))}
      </Routes>
    </>
  )
}
