import { getAccessorTitle } from '../utils/getAccessorTitle'
import { Accessor } from '../types/accessor'

export const equipmentNotSwitchableColumns = [Accessor.Actions]
export const equipmentRequiredColumns = [Accessor.Index, Accessor.Title, Accessor.TruckCode]

export const equipmentDefaultColumns = [
  Accessor.Index,
  Accessor.Title,
  Accessor.TruckCode,
  Accessor.Qty,
  Accessor.PhoneNumber,
  Accessor.Email,
]

export const equipmentVisibleColumnsOptions = Object.values(Accessor)
  .filter((accessor) => !equipmentNotSwitchableColumns.includes(accessor))
  .map((accessor) => ({
    value: accessor,
    label: getAccessorTitle(accessor),
    disabled: equipmentRequiredColumns.includes(accessor),
  }))
