import { TableSorter } from '../types/tableSorter'

export const getTableSortQuery = (sorters?: TableSorter[]): string[] | undefined => {
  if (!sorters?.length) {
    return undefined
  }

  const sortParams: string[] = []

  sorters?.forEach(({ field, order }) => {
    const orderPayload = (order === 'ascend' && 'ASC') || (order === 'descend' && 'DESC') || undefined

    if (!orderPayload) {
      return undefined
    }

    if (Array.isArray(field)) {
      field.forEach((key) => {
        sortParams.push(`${key},${orderPayload}`)
      })
    } else {
      sortParams.push(`${field},${orderPayload}`)
    }
  })

  return sortParams.length ? sortParams : undefined
}
