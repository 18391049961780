import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { EntityId } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'
import { getMutationPatchPayload } from 'utils'

import { SelectSingleOption, showNotification } from 'designSystem'

import { ItemFormData } from '../../../../../types/itemPayload'

import { patchItem } from '../queryApi'

export const useUpdateItem = (id: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation({
    mutationFn: patchItem,
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.ItemUpdate)

      queryClient.setQueryData([QueryKey.GetItemById, id], data)
      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const update = useCallback(
    (payload: ItemFormData) => {
      const data = getMutationPatchPayload<ItemFormData>(payload)
      const { groupedSKUs: groupedSKUsForm, ...rest } = data

      const groupedSKUs = groupedSKUsForm?.reduce((acc: (number | string)[], cur: SelectSingleOption) => {
        if (cur?.value === undefined) {
          return acc
        }
        acc.push(cur.value)
        return acc
      }, [])

      mutate({
        id,
        data: { ...rest, ...(groupedSKUs && { groupedSKUs }) },
      })
    },
    [mutate, id],
  )

  return {
    update,
    ...rest,
  }
}
