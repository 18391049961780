import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReferenceNumberType } from 'api'
import { route } from 'constant'

import { Button, Container, Spacer, Typography } from 'designSystem'
import { Breadcrumbs } from 'components'

import { breadcrumbsData } from '../../constants/breadcrumbsData'
import { referenceTypesData } from './constants/referenceTypesData'
import { ChooseTypeCard } from './elements/ChooseTypeCard'
import * as Styled from './styles'

export const ReferenceCreateChooseType: FC = () => {
  const navigate = useNavigate()
  const [checkedType, setCheckedType] = useState<ReferenceNumberType>()

  const onClickCancel = () => {
    navigate(route.references)
  }

  const onClickContinue = () => {
    navigate(`${route.referencesCreateChooseType}/${checkedType}`)
  }

  return (
    <>
      <Breadcrumbs breadcrumbItems={breadcrumbsData} />
      <Container ai="center" jc="center" mt={8}>
        <Container fd="column" ai="center">
          <Typography size="xl" fontWeight="xl">
            Select type Reference Numbers
          </Typography>
          <Spacer mb={6} />
          <Container fd="column">
            <Styled.TypeCardsWrapper>
              {referenceTypesData.map(({ type, ...typeData }) => (
                <ChooseTypeCard
                  key={type}
                  {...typeData}
                  type={type}
                  onClick={() => setCheckedType(type)}
                  checked={type === checkedType}
                />
              ))}
            </Styled.TypeCardsWrapper>
            <Container mt={6} fg={1} jc="space-between">
              <Button width={200} onClick={onClickCancel}>
                Cancel
              </Button>
              <Button width={200} type="primary" disabled={!checkedType} onClick={onClickContinue}>
                Continue
              </Button>
            </Container>
          </Container>
        </Container>
      </Container>
    </>
  )
}
