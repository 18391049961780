import { FC } from 'react'

import { LocationCode } from 'api'

import { Container, Divider, Typography } from 'designSystem'
import { LabelValueMarkup } from 'components/LabelValueMarkup'

interface VendorDetailsCardLocationsProps {
  data?: LocationCode[]
}

export const VendorDetailsCardLocations: FC<VendorDetailsCardLocationsProps> = ({ data }) => {
  if (!data?.length) {
    return null
  }

  return (
    <>
      <Container gap={8} fd="column">
        <Typography size="s" fontWeight="xl">
          Locations
        </Typography>

        <Container fd="column" gap={4}>
          {data.map(({ code }, index) => (
            <LabelValueMarkup key={code} label={`Location ${index + 1}`} value={code} />
          ))}
        </Container>
      </Container>

      <Divider my={4} />
    </>
  )
}
