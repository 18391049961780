import * as Yup from 'yup'

import {
  purchaseOrderItemCreateValidation,
  purchaseOrderItemViewValidation,
} from 'modules/purchaseOrders/constants/validationSchema'

export const validationSchema = (isAddNew?: boolean) =>
  Yup.object({
    item: isAddNew ? purchaseOrderItemCreateValidation : Yup.object(),
    items: Yup.array(purchaseOrderItemViewValidation),
  })
