import { useQuery } from '@tanstack/react-query'

import { EntityId } from 'api'
import { QueryKey } from 'enums'

import { getContainerVendorByVendorId } from '../queryApi'

type UseGetContainerVendorByVendorIdProps = {
  id: EntityId
  enabled?: boolean
}

export const useGetContainerVendorByVendorId = ({ id, enabled = true }: UseGetContainerVendorByVendorIdProps) => {
  const { data, isFetching, isLoading, fetchStatus, ...rest } = useQuery({
    queryKey: [QueryKey.GetContainerVendorByVendorId, id],
    queryFn: () => getContainerVendorByVendorId(id),
    enabled: !!id && enabled,
  })

  return {
    data,
    isLoading: (isLoading || isFetching) && fetchStatus !== 'idle',
    ...rest,
  }
}
