import { api, EntityId, ReferenceNumberRedelivery } from 'api'

import { RedeliveryNumberForm } from 'modules/references/types'

import { referenceNumberByIdJoinParams } from 'modules/references/constants/referenceNumberByIdJoinParams'

export const patchRedeliveryNumber = ({
  id,
  data,
}: {
  id: EntityId
  data: Partial<Omit<RedeliveryNumberForm, 'items'>>
}): Promise<{ reference: ReferenceNumberRedelivery }> => {
  return api({
    method: 'patch',
    url: `/api/v1/references/redelivery/${id}`,
    data,
    params: referenceNumberByIdJoinParams,
  })
}
