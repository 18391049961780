import { api, UserSettings } from 'api'
import type { UserTableSettings } from 'types'

export const saveModuleTableSettings = (data: UserTableSettings): Promise<UserTableSettings> => {
  return api({
    method: 'put',
    url: `/api/v1/table-filters/${data.module}`,
    data,
  })
}

export const saveUserSettings = (data: Partial<UserSettings>): Promise<UserSettings> => {
  return api({
    method: 'patch',
    url: `/api/v1/users/me/settings`,
    data,
  })
}
