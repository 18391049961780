import { FC } from 'react'

import { Customer } from 'api'
import { route } from 'constant'
import { AppModule } from 'types'

import {
  Table,
  TableFilterDrawer,
  TableFilterOptions,
  TableSearchSingle,
  TableTitle,
  useTableColumns,
} from 'components'

import { useCustomers } from './hooks/useCustomers'
import { getTableColumns } from './utils/getTableColumns'
import { customersFilterOptions } from './constants/customersFilterOptions'
import { customersVisibleColumnsOptions } from './constants/customersVisibleColumns'

export const CustomersList: FC = () => {
  const {
    data,
    isLoading,
    pagination,
    onTableChange,
    getFieldSorter,
    offsetCount,
    TableContextProvider,
    filterOptions,
    setFilterOptions,
  } = useCustomers()

  const allColumns = getTableColumns(getFieldSorter, offsetCount)
  const { visibleColumns, setVisibleColumns, columns } = useTableColumns<Customer>({
    module: AppModule.Customers,
    allColumns,
  })

  const customersList: Customer[] = data.map(({ id, ...rest }) => ({
    key: id.toString(),
    id,
    ...rest,
  }))

  return (
    <TableContextProvider>
      <TableTitle
        title="Customers"
        total={pagination?.total}
        addRoute={route.customersCreate}
        search={<TableSearchSingle searchFieldName="companyName" />}
        filter={
          <TableFilterDrawer
            setFilterOptions={setFilterOptions}
            filterOptions={filterOptions}
            options={customersFilterOptions}
            module={AppModule.Customers}
          />
        }
        columns={
          <TableFilterOptions
            title="Columns"
            icon="columns"
            options={customersVisibleColumnsOptions}
            value={visibleColumns}
            onChange={setVisibleColumns}
          />
        }
      />
      <Table<Customer>
        withBorderRadius={false}
        scroll={{ x: 750 }}
        dataSource={customersList}
        loading={isLoading}
        columns={columns}
        pagination={pagination}
        onChange={onTableChange}
      />
    </TableContextProvider>
  )
}
