import { FC, useCallback, useState } from 'react'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { DndContext } from '@dnd-kit/core'

import { ItemCategory } from 'api'
import { useItemCategories, useItemSearch, useItemSearchCategories, useItemSetCategories } from 'store'

import { usePatchCategoryItem } from '../../hooks/usePatchCategoryItem'
import { getDragPayload } from './utils/getDragPayload'
import { insertItemToCategories } from './utils/insertItemToCategories'
import { removeCategory } from './utils/removeCategory'
import { CategoryItem } from './elements/CategoryItem'
import { DragOverlay } from './elements/DragOverlay'

export const CategoryList: FC = () => {
  const itemCategories = useItemCategories()
  const searchCategories = useItemSearchCategories()
  const search = useItemSearch()
  const { updateCategoryItem } = usePatchCategoryItem()
  const setCategories = useItemSetCategories()
  const [activeItem, setActiveItem] = useState<ItemCategory | null>(null)
  const currentCategories = search ? searchCategories : itemCategories

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveItem(event.active.data.current?.item as ItemCategory)
  }, [])

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      setActiveItem(null)
      const currentId = event.active.id
      const currentCategory = event.active.data.current?.item as ItemCategory
      const overCategory = event.over?.data.current as ItemCategory

      if (currentId === overCategory?.id) return

      const removedItemCategories = removeCategory(itemCategories, currentCategory?.id, currentCategory?.parentId)
      const updatedItemCategories = insertItemToCategories(removedItemCategories, currentCategory, overCategory)

      const updatePayload = getDragPayload(currentCategory, overCategory, itemCategories.length)
      setCategories(updatedItemCategories)
      updateCategoryItem(updatePayload)
    },
    [itemCategories, updateCategoryItem, setCategories],
  )

  return (
    <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragCancel={() => setActiveItem(null)}>
      {currentCategories.map((item) => (
        <CategoryItem item={item} key={item.id} />
      ))}
      <DragOverlay item={activeItem} />
    </DndContext>
  )
}
