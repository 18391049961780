import { AssetCodeType } from 'api/types/assetCode/assetCodeType'

export const assetCodeTypeOptionsLabel = {
  [AssetCodeType.Dry]: 'Dry',
  [AssetCodeType.Reefer]: 'Reefer',
  [AssetCodeType.Office]: 'Office',
  [AssetCodeType.FlatRack]: 'Flat Rack',
  [AssetCodeType.OpenTop]: 'Open Top',
  [AssetCodeType.Mini]: 'Mini',
  [AssetCodeType.Combo]: 'Combo',
}

export const assetCodeTypeOptions = Object.values(AssetCodeType).map((type) => ({
  value: type,
  label: assetCodeTypeOptionsLabel[type],
}))
