import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { EntityId } from 'api'
import { useItemRemoveCategory } from 'store'

import { showNotification } from 'designSystem'

import { deleteCategoryItem } from '../elements/SingleCategory/queryApi'

export const useDeleteCategoryItem = () => {
  const removeCategoryItem = useItemRemoveCategory()
  const { mutate, ...rest } = useMutation({
    mutationFn: deleteCategoryItem,
    onSuccess: (_, id) => {
      removeCategoryItem(id)
      showNotification({ title: 'Item deleted', type: 'success' })
    },
  })

  const deleteItem = useCallback(
    (payload: EntityId) => {
      mutate(payload)
    },
    [mutate],
  )

  return {
    deleteItem,
    ...rest,
  }
}
