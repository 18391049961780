import { css, styled } from 'styled-components'

import { Card } from 'components'

export const TypeCard = styled(Card)<{ $active: boolean }>`
  cursor: pointer;
  width: 324px;

  ${({ $active }) =>
    $active &&
    css`
      border-color: ${({ theme }) => theme.colors.accent[300]};
    `}

  &:hover {
    border-color: ${({ theme }) => theme.colors.accent[300]};
  }
`
