import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'

import { VendorCarrierPriceListItem } from 'api'
import { useForm } from 'hooks'

import { Button, Container } from 'designSystem'
import { Table, TableTitle } from 'components'

import { useCarrierPriceList } from './hooks/useCarrierPriceList'
import { useCarrierPriceListRequests } from './hooks/useCarrierPriceListRequests'
import { getTableColumns } from './utils/getTableColumns'
import { validationSchema } from './constants/validationSchema'
import { CarrierPriceListForm } from './types/carrierPriceListForm'
import { AddPriceListItemModal } from './elements/AddPriceListItemModal'

export const CarrierPriceList: FC = () => {
  const { id } = useParams()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { data, isLoading, pagination, onTableChange, offsetCount, TableContextProvider, dataSource } =
    useCarrierPriceList(Number(id))

  const { onSubmitPatch, isSubmitError, isEditLoading, onDelete } = useCarrierPriceListRequests(data)

  const { Form } = useForm<CarrierPriceListForm>({
    validationSchema,
    mode: 'onChange',
    isTableEditForm: true,
    isLoading,
    onSubmit: onSubmitPatch,
    isSubmitError,
    defaultValues: {
      list: data,
    },
  })

  const columns = getTableColumns({ offsetCount, onDelete })

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  return (
    <Container ma={4} fd="column" gap={16}>
      <TableContextProvider>
        <TableTitle title="Pricelists" total={pagination?.total} withoutDefaultPadding>
          <Button icon="plus" type="primary" size="small" onClick={openModal}>
            Add
          </Button>
          <AddPriceListItemModal open={isModalOpen} closeModal={closeModal} />
        </TableTitle>

        <Form>
          <Table<Partial<VendorCarrierPriceListItem>>
            dataSource={dataSource}
            columns={columns}
            loading={isLoading || isEditLoading}
            pagination={pagination}
            onChange={onTableChange}
            isEditLoading={isEditLoading}
            scroll={{ x: 600 }}
          />
        </Form>
      </TableContextProvider>
    </Container>
  )
}
