import { useQuery } from '@tanstack/react-query'

import { getWarehouses } from 'api'
import { QueryKey } from 'enums'

import type { SelectSingleOption } from 'designSystem'

export const useGetWarehouses = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QueryKey.GetWarehouses],
    queryFn: getWarehouses,
  })

  const options = data?.map(({ title, id, ...address }) => ({
    value: id,
    label: title,
    data: { ...address },
  })) as SelectSingleOption[]

  return {
    options,
    ...rest,
  }
}
