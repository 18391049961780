import { FC } from 'react'

import { AssetRepairStatus } from 'api'

import { Tag, TagProps } from 'designSystem'

import { getTagDataByAssetRepairStatus } from './utils/getTagDataByAssetRepairStatus'

interface AssetRepairStatusTagProps extends Omit<TagProps, 'status'> {
  status: AssetRepairStatus
}

export const AssetRepairStatusTag: FC<AssetRepairStatusTagProps> = ({ status, ...props }) => {
  const { status: tagStatus, text } = getTagDataByAssetRepairStatus(status)
  return <Tag {...props} status={tagStatus} text={text} />
}
