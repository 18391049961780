import { FC } from 'react'

import { useForm } from 'hooks'

import { Button, Container } from 'designSystem'
import { Contact as ContactData } from 'components/Contacts/elements/Contact'

import { newContactValidationSchema } from '../../constants/validationSchema'
import { AddContactsFormData } from '../../types/addContactsFormData'

import type { AddContactsModalProps } from '../../AddContactsModal'

type NewContactPayload = Pick<AddContactsFormData, 'contacts'>

type NewContactProps = Pick<AddContactsModalProps, 'contacts' | 'onConfirm' | 'onCancel' | 'confirmLoading'>

export const NewContact: FC<NewContactProps> = ({ contacts, onConfirm, onCancel, confirmLoading }) => {
  const { Form, handleSubmit } = useForm<NewContactPayload>({
    validationSchema: newContactValidationSchema,
    context: { contacts },
  })

  return (
    <Form onSubmit={handleSubmit(onConfirm)}>
      <ContactData name="contacts" index={0} showTag={false} />
      <Container display="flex" jc="space-between" gap={8} mt={7}>
        <Button type="default" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" fullwidth htmlType="submit" loading={confirmLoading}>
          Confirm
        </Button>
      </Container>
    </Form>
  )
}
