import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ReferenceNumberStatus, ReferenceNumberType } from 'api'
import { useForm } from 'hooks'
import { getReferenceTypeOptionLabel, route } from 'constant'

import { Container, Divider, SelectSingleOption, Typography } from 'designSystem'
import { BookingNumberForm } from 'modules/references/types'
import { getVendorReleaseItemsQty } from 'modules/references/utils/getVendorReleaseItemsQty'

import { useCreateBookingNumber } from './hooks/useCreateBookingNumber'
import { bookingNumberFieldsValidation } from 'modules/references/constants/fieldsValidation'
import { BookingNumberButtons } from './elements/BookingNumberButtons'
import { BookingNumberDetails } from './elements/BookingNumberDetails'
import { BookingNumberMainDetails } from './elements/BookingNumberMainDetails'
import { BookingNumberSecondaryDetails } from './elements/BookingNumberSecondaryDetails'
import { ReferenceItems } from 'modules/references/elements/ReferenceItems'

export const BookingNumber: FC = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  const { items: defaultItems, ...restDefaultData } = state?.data || {}
  const title = getReferenceTypeOptionLabel(ReferenceNumberType.Booking)

  const [vendorReleaseItems, setVendorReleaseItems] = useState<Record<string, number> | undefined>(
    getVendorReleaseItemsQty(defaultItems),
  )
  const depotSettingSelectedOption = useRef<SelectSingleOption | undefined>()
  const { create, isPending } = useCreateBookingNumber()

  const { Form, handleSubmit, reset } = useForm<BookingNumberForm>({
    mode: 'onChange',
    validationSchema: bookingNumberFieldsValidation,
  })

  useEffect(() => {
    reset({
      type: ReferenceNumberType.Booking,
      status: ReferenceNumberStatus.Open,
      items: [{}],
      ...(restDefaultData || {}),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClickCancel = useCallback(() => {
    navigate(route.referencesCreateChooseType)
  }, [navigate])

  const setDepotSettingSelectedOption = useCallback((option?: SelectSingleOption) => {
    depotSettingSelectedOption.current = option
  }, [])

  return (
    <Form onSubmit={handleSubmit(create)}>
      <Container pa={4}>
        <Typography size="l" fontWeight="xl">
          Create {title} Number
        </Typography>
      </Container>
      <Container pa={4} bg="white" fd="column">
        <Container mb={9}>
          <Container fd="column" width="100%">
            <Typography size="m" fontWeight="xl">
              {title} Details
            </Typography>
            <Container fd="column" gap={8} pt={4}>
              <BookingNumberMainDetails
                defaultData={restDefaultData}
                depotSettingSelectedOption={depotSettingSelectedOption.current}
                setDepotSettingSelectedOption={setDepotSettingSelectedOption}
              />
            </Container>
          </Container>
          <Divider vertical mx={4} />
          <Container fd="column" width="100%" gap={8} pt={4} mt={7}>
            <BookingNumberSecondaryDetails
              defaultData={restDefaultData}
              setVendorReleaseItems={setVendorReleaseItems}
              setDepotSettingSelectedOption={setDepotSettingSelectedOption}
            />
          </Container>
          <Divider vertical mx={4} />
          <Container fd="column" width="100%">
            <Typography size="m" fontWeight="xl">
              Details
            </Typography>
            <Container fd="column" gap={8} pt={4}>
              <BookingNumberDetails />
            </Container>
          </Container>
        </Container>
        <ReferenceItems type={ReferenceNumberType.Booking} vendorReleaseItems={vendorReleaseItems} />
      </Container>
      <BookingNumberButtons
        isLoading={isPending}
        onClickCancel={onClickCancel}
        vendorReleaseItems={vendorReleaseItems}
      />
    </Form>
  )
}
