import { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import isEmpty from 'lodash.isempty'

import { EntityId, PurchaseOrderItem, PurchaseOrderItemsSubLine } from 'api'

import { usePatchPurchaseOrderItem } from 'modules/purchaseOrders/hooks/usePatchPurchaseOrderItem'

import { getPatchPayload } from '../utils/getPatchPayload'
import { PurchaseOrderItemsFormPayload } from '../types/purchaseOrderItemsForm'
import { prepareDeleteSubLinesPayload } from 'modules/purchaseOrders/elements/ChangeQuantity/utils/prepareDeleteSubLinesPayload'

import { useDeletePurchaseOrderItem } from './useDeletePurchaseOrderItem'
import { useDeletePurchaseOrderSubLine } from './useDeletePurchaseOrderSubLine'
import { useDeleteSubLines } from './useDeleteSubLines'
import { usePostPurchaseOrderItem } from './usePostPurchaseOrderItem'

interface usePurchaseOrderItemsRequestsProps {
  setIsAddNew?: (isAddNew: boolean) => void
  data?: PurchaseOrderItem[]
  purchaseOrderId: number
  setExpandedRowKeys?: Dispatch<SetStateAction<(string | number)[] | undefined>>
}

export const usePurchaseOrderItemsRequests = ({
  setIsAddNew,
  data,
  purchaseOrderId,
  setExpandedRowKeys,
}: usePurchaseOrderItemsRequestsProps) => {
  const {
    post,
    data: postData,
    isSuccess: isPostSuccess,
    isError: isPostError,
    isPending: isPostLoading,
  } = usePostPurchaseOrderItem(Number(purchaseOrderId))

  const newlyCreatedItemId = postData?.data.id

  useEffect(() => {
    if (newlyCreatedItemId) {
      setExpandedRowKeys?.((prev) => [...(prev || []), String(newlyCreatedItemId)])
    }
  }, [newlyCreatedItemId, setExpandedRowKeys])

  const {
    patch,
    isSuccess: isPatchSuccess,
    isError: isPatchError,
    isPending: isPatchLoading,
  } = usePatchPurchaseOrderItem(Number(purchaseOrderId))

  const {
    removeItem,
    isSuccess: isDeleteItemSuccess,
    isError: isDeleteItemError,
    isPending: isDeleteItemLoading,
  } = useDeletePurchaseOrderItem(Number(purchaseOrderId))

  const {
    removeSubLine,
    isSuccess: isDeleteSubLineSuccess,
    isError: iisDeleteSubLineError,
    isPending: isDeleteSubLineLoading,
  } = useDeletePurchaseOrderSubLine(Number(purchaseOrderId))

  const {
    removeSubLines,
    isSuccess: isDeleteSubLinesSuccess,
    isError: isDeleteSubLinesError,
    isPending: isDeleteSubLinesLoading,
  } = useDeleteSubLines(Number(purchaseOrderId))

  const onSubmitPost = useCallback(
    (payload: PurchaseOrderItemsFormPayload) => {
      if (payload.item) {
        post(payload.item)
        setIsAddNew?.(false)
      }
    },
    [post, setIsAddNew],
  )

  const onSubmitPatch = useCallback(
    (payload: PurchaseOrderItemsFormPayload) => {
      if (data) {
        const patchData = getPatchPayload(payload, data)

        patchData?.forEach(({ id, payload }) => {
          if (!isEmpty(payload)) {
            patch(id as EntityId, payload)
          }
        })
      }
    },
    [patch, data],
  )

  const onDeleteItem = useCallback(
    (itemId?: EntityId) => {
      if (itemId) {
        removeItem(itemId)
      }
    },
    [removeItem],
  )

  const onDeleteSubLine = useCallback(
    ({ itemId, subLineId }: { itemId: EntityId; subLineId: EntityId }) => {
      if (itemId) {
        removeSubLine({ itemId, subLineId })
      }
    },
    [removeSubLine],
  )

  const onDeleteSubLines = useCallback(
    (itemId: EntityId, selectedRows: string[], prevSubLines?: PurchaseOrderItemsSubLine[]) => {
      const payload = prepareDeleteSubLinesPayload(selectedRows, prevSubLines)
      removeSubLines(itemId, payload)
    },
    [removeSubLines],
  )

  return {
    isSubmitSuccess:
      isPostSuccess || isPatchSuccess || isDeleteItemSuccess || isDeleteSubLineSuccess || isDeleteSubLinesSuccess,
    isSubmitError: isPostError || isPatchError || isDeleteItemError || iisDeleteSubLineError || isDeleteSubLinesError,
    isEditLoading:
      isPostLoading || isPatchLoading || isDeleteItemLoading || isDeleteSubLineLoading || isDeleteSubLinesLoading,
    onSubmitPost,
    onSubmitPatch,
    onDeleteItem,
    removeSubLine,
    onDeleteSubLine,
    onDeleteSubLines,
  }
}
