import { ReactElement, ReactNode } from 'react'
import { Tooltip } from 'antd'

import theme from 'styles/theme'

import { Icon } from '../../Icon'

interface FormItemPrefixProps {
  error?: string
  label?: string
  prefix?: ReactNode
}

export const getFormItemPrefix = ({ error, label, prefix }: FormItemPrefixProps): ReactElement | ReactNode => {
  return error && !label ? (
    <Tooltip title={error} trigger="hover" placement="top">
      <Icon icon="errorFilled" size={16} color={theme.colors.error[400]} />
    </Tooltip>
  ) : (
    prefix
  )
}
