import { FC } from 'react'

import type { UserStatus } from 'api'

import { Tag, TagProps } from 'designSystem'

import { getTagDataByUserStatus } from './utils/getTagDataByUserStatus'

interface UserStatusTagProps extends Omit<TagProps, 'status'> {
  userStatus: UserStatus
}

export const UserStatusTag: FC<UserStatusTagProps> = ({ userStatus, ...props }) => {
  const { status, text } = getTagDataByUserStatus(userStatus)
  return <Tag {...props} status={status} text={text} />
}
