import { User } from 'api'
import { useTable } from 'hooks'
import { AppModule } from 'types'

import { useGetUsers } from './useGetUsers'

export const useUsers = () => {
  const { pagination, onChange, getFieldSorter, sortQueryParams, searchQueryParams, ...props } = useTable<User>({
    module: AppModule.AccessManagement,
  })

  const { data, total, isLoading, isFetching } = useGetUsers({
    limit: pagination.limit,
    page: pagination.page,
    sort: sortQueryParams,
    s: searchQueryParams,
  })

  return {
    data: data || [],
    pagination: {
      ...pagination,
      current: pagination.page,
      pageSize: pagination.limit,
      total,
    },
    getFieldSorter,
    onTableChange: onChange,
    isLoading: isLoading || isFetching,
    offsetCount: (pagination.page - 1) * pagination.limit,
    ...props,
  }
}
