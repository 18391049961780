import { FC } from 'react'

import { MarginSettingMeasure, MarginSettingOperator, VendorModificationsSettings, VendorType } from 'api'
import { useForm } from 'hooks'
import { AppModule } from 'types'
import { getMarginDefaultValues } from 'utils'

import { Button, Container } from 'designSystem'
import { Table, TableFilterOptions, TableSearchSingle, TableTitle, useTableColumns } from 'components'

import { useModifications } from './hooks/useModifications'
import { useModificationsRequests } from './hooks/useModificationsRequests'
import { getTableColumns } from './utils/getTableColumns'
import { modificationsVisibleColumnsOptions } from './constants/modificationsVisibleColumns'
import { validationSchema } from './constants/validationSchema'
import { ModificationForm } from './types/modificationCreateForm'
import { UploadCSV } from '../../../../elements/UploadCSV'

interface ModificationTableProps {
  id: number
}

export const ModificationTable: FC<ModificationTableProps> = ({ id }) => {
  const {
    data,
    isLoading,
    pagination,
    onTableChange,
    offsetCount,
    TableContextProvider,
    // editable table
    dataSource,
    isAddNew,
    setIsAddNew,
    onAddNew,
    onCancelNew,
  } = useModifications(Number(id))

  const { onSubmitPost, onSubmitPatch, onDelete, isSubmitError, isEditLoading } = useModificationsRequests(
    setIsAddNew,
    data,
  )

  const { Form, handleSubmit, triggerSubmit, watch } = useForm<ModificationForm>({
    validationSchema: validationSchema(isAddNew),
    mode: 'onChange',
    isTableEditForm: true,
    isLoading,
    onSubmit: onSubmitPatch,
    isSubmitError,
    defaultValues: {
      list: data.map((item) => ({
        ...item,
        resaleMargin: getMarginDefaultValues(item?.resaleMargin),
      })),
      item: {
        resaleMargin: {
          measure: MarginSettingMeasure.Percentage,
          operator: MarginSettingOperator.Plus,
          operand: 25,
        },
      },
    },
  })

  const addNewValues = watch('item')
  const allColumns = getTableColumns({ offsetCount, isAddNew, onCancelNew, onDelete, triggerSubmit, addNewValues })
  const { visibleColumns, setVisibleColumns, columns } = useTableColumns<Partial<VendorModificationsSettings>>({
    module: AppModule.VendorModifications,
    allColumns,
    withActions: true,
  })

  return (
    <Container fd="column" gap={16}>
      <TableContextProvider>
        <TableTitle
          withoutDefaultPadding
          title="Modifications"
          total={pagination?.total}
          search={<TableSearchSingle searchFieldName={['name', 'sku']} />}
          columns={
            <TableFilterOptions
              title="Columns"
              icon="columns"
              options={modificationsVisibleColumnsOptions}
              value={visibleColumns}
              onChange={setVisibleColumns}
            />
          }
        >
          <UploadCSV vendorId={Number(id)} vendorType={VendorType.Modifications} />
          <Button icon="plus" type="primary" size="small" onClick={onAddNew} disabled={isAddNew}>
            Add
          </Button>
        </TableTitle>

        <Form onSubmit={handleSubmit(onSubmitPost)}>
          <Table<Partial<VendorModificationsSettings>>
            scroll={{ x: 800 }}
            dataSource={dataSource}
            columns={columns}
            viewOnlyExceptFirstRow={isAddNew}
            loading={isLoading || isEditLoading}
            pagination={pagination}
            onChange={onTableChange}
            isEditLoading={isEditLoading}
            editableTable
          />
        </Form>
      </TableContextProvider>
    </Container>
  )
}
