import { getTruckCodes, TruckCode } from 'api'
import { QueryKey } from 'enums'
import { AsyncSelectGetItemsProps } from 'types'

import type { SelectSingleOption } from 'designSystem'

import { useAsyncList } from './useAsyncList'

export const useTruckCodes = ({ id, defaultOption }: AsyncSelectGetItemsProps) => {
  const { list, ...props } = useAsyncList<TruckCode>({
    id,
    defaultOption,
    queryFn: getTruckCodes,
    queryKey: QueryKey.GetTruckCodesList,
    searchFieldName: 'code',
    limit: 10,
  })

  const options = list.map((item) => ({ value: item.id, label: item.code })) satisfies SelectSingleOption[]

  return {
    options,
    ...props,
  }
}
