export enum Accessor {
  Index = 'index',
  CompanyName = 'companyName',
  Type = 'type',
  LocationCodes = 'locationCodes',
  BillingAddress = 'billingAddress',
  PaymentTerms = 'paymentTerms',
  Contacts = 'contacts',
  UploadSheet = 'uploadSheet',
}
