import { create } from 'zustand'

import { Role } from 'api'

interface RolesStore {
  roles?: Role[]
  setRoles: (roles?: Role[]) => void
}

const useRolesStore = create<RolesStore>((set) => ({
  roles: [],
  setRoles: (roles?: Role[]) => set({ roles }),
}))

export const useRoles = () => useRolesStore((state) => state.roles)
export const useSetRoles = () => useRolesStore((state) => state.setRoles)
