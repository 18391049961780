import { FC, useCallback, useEffect } from 'react'
import isEqual from 'react-fast-compare'

import { VendorType } from 'api'
import { useForm } from 'hooks'
import { getMarginDefaultValues, getScrappingSettingsDefaultValues } from 'utils'

import { Spacer } from 'designSystem'
import { Spinner } from 'components'
import { useGetPartsByVendorId } from 'modules/vendors/hooks/useGetPartsByVendorId'
import { useUpdatePartsFeed } from 'modules/vendors/hooks/useUpdatePartsFeed'

import { getDefaultValues } from '../../utils/getDefaultValues'
import { validationSchema } from './utils/validationSchema'
import { FeedUrlForm } from './types/FeedUrlForm'
import { PatchFeedPayload } from './types/PatchFeedPayload'
import { FeedSettings } from 'modules/vendors/elements/FeedSettings'
import { ConfirmItem } from 'modules/vendors/elements/UploadLocalFile/elements/ConfirmItem'
import { VendorTypeItem } from 'modules/vendors/elements/UploadLocalFile/elements/VendorTypeItem'
import * as Styled from './styles'
interface FeedUrlTabProps {
  vendorId: number
  handleCloseModal: () => void
}

export const FeedUrlTab: FC<FeedUrlTabProps> = ({ vendorId, handleCloseModal }) => {
  const { update: updatePartsFeed, isPending } = useUpdatePartsFeed({
    id: vendorId,
    onSettled: handleCloseModal,
  })
  const { data: partsData, isLoading: isLoadingPartsData } = useGetPartsByVendorId({
    id: Number(vendorId),
  })

  const onSubmit = useCallback(
    (data: PatchFeedPayload) => {
      updatePartsFeed?.(data)
    },
    [updatePartsFeed],
  )

  const { Form, handleSubmit, setValue, watch } = useForm<FeedUrlForm>({
    validationSchema,
    mode: 'onChange',
    onSubmit,
    defaultValues: getDefaultValues({
      type: VendorType.Parts,
      feedData: partsData,
    }),
  })
  const type = watch('vendorType')

  const checkIfDataChanged = () => {
    const allFieldValues = watch()

    const defaultValues = getDefaultValues({
      type,
      feedData: partsData,
    })

    return !isEqual(allFieldValues, defaultValues)
  }

  useEffect(() => {
    setValue('scrappingSettings', getScrappingSettingsDefaultValues(partsData?.scrappingSettings))
    setValue('resaleMargin', getMarginDefaultValues(partsData?.resaleMargin))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const isDataChanged = checkIfDataChanged()

  if (isLoadingPartsData) {
    return (
      <Styled.SpinnerWrapper>
        <Spinner size="large" />
      </Styled.SpinnerWrapper>
    )
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <VendorTypeItem isPartsOnly />
      <Spacer mb={4} />
      <FeedSettings />
      <Spacer mb={7} />
      <ConfirmItem isLoading={isPending} isDisabled={!isDataChanged} />
    </Form>
  )
}
