import { api, EntityId, ReferenceNumberBooking } from 'api'

import { BookingNumberForm } from 'modules/references/types'

import { referenceNumberByIdJoinParams } from 'modules/references/constants/referenceNumberByIdJoinParams'

export const patchBookingNumber = ({
  id,
  data,
}: {
  id: EntityId
  data: Partial<Omit<BookingNumberForm, 'items'>>
}): Promise<{ reference: ReferenceNumberBooking }> => {
  return api({
    method: 'patch',
    url: `/api/v1/references/booking/${id}`,
    data,
    params: referenceNumberByIdJoinParams,
  })
}
