import { FC } from 'react'

import { Contact } from 'api'

import { Button, Container, Modal, ModalProps, Typography } from 'designSystem'

import { LabelValueMarkup } from '../../LabelValueMarkup'

interface ContactDetailsModalProps extends ModalProps {
  contact: Contact
  onDelete?: () => void
  onClose: () => void
  showDelete?: boolean
}

export const ContactDetailsModal: FC<ContactDetailsModalProps> = ({
  open,
  width = 440,
  footer = null,
  title = 'Contact Details',
  contact,
  onClose,
  onDelete,
  showDelete,
}) => {
  const { firstName, lastName, phoneExtension, phoneNumber, email, notes } = contact
  const isEmptyPhoneValues = !phoneExtension && !phoneNumber
  const phoneTooltip = isEmptyPhoneValues
    ? undefined
    : [phoneExtension ? `#${phoneExtension}` : '', phoneNumber].filter(Boolean).join(', ')

  return (
    <Modal open={open} width={width} onCancel={onClose} destroyOnClose title={title} footer={footer}>
      <Container display="flex" fd="column" gap={4}>
        <Container fd="column">
          <LabelValueMarkup label="Name" value={`${firstName} ${lastName}`} />
          {notes && <LabelValueMarkup label="Notes" value={notes} />}
          <LabelValueMarkup
            label="Phone number"
            tooltipValue={phoneTooltip}
            value={
              isEmptyPhoneValues ? (
                '-'
              ) : (
                <Container gap={8}>
                  {phoneExtension && (
                    <Typography fontWeight="l" color="secondary">
                      #{phoneExtension}
                    </Typography>
                  )}
                  {phoneNumber && <Typography fontWeight="l">{phoneNumber}</Typography>}
                </Container>
              )
            }
          />
          <LabelValueMarkup label="Email" value={email} />
        </Container>
        <Container display="flex" jc="space-between" gap={8} mt={7}>
          {showDelete && (
            <Button type="default" fullwidth onClick={onDelete}>
              Remove Contact
            </Button>
          )}
          <Button type="primary" fullwidth onClick={onClose}>
            Close
          </Button>
        </Container>
      </Container>
    </Modal>
  )
}
