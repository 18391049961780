import { Navigate } from 'react-router-dom'

import { accessManagementRoute } from 'constant'

import { NotFoundPage } from 'components'

import { UsersList } from '../routes/UserManagement/routes/UsersList'
import { UserManagement } from '../routes/UserManagement/UserManagement'

export const accessManagementRoutes = [
  {
    path: '/',
    element: <Navigate to={accessManagementRoute.userManagement.replace('/', '')} replace />,
  },
  {
    path: accessManagementRoute.userManagement,
    element: <UsersList />,
  },
  {
    path: `${accessManagementRoute.userManagement}/*`,
    element: <UserManagement />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
