import { FC } from 'react'

import { hashRoute } from 'constant'
import { HashRouterComponentProps } from 'types'

import { PurchaseOrder } from 'modules/purchaseOrders/routes/PurchaseOrder'
import { Reference } from 'modules/references/routes/Reference'
import { SalesOrder } from 'modules/salesOrders/routes/SalesOrder'

export const hashRouteComponents: Record<keyof typeof hashRoute, FC<HashRouterComponentProps>> = {
  reference: Reference,
  po: PurchaseOrder,
  so: SalesOrder,
}
