import { FC, PropsWithChildren } from 'react'

import { typedMemo } from 'types'

import { IconDelete } from 'designSystem'

import * as Styled from './styles'

interface FieldArrayItemProps {
  onDelete?: () => void
  showDelete?: boolean
}

export const FieldArrayItemBase: FC<PropsWithChildren<FieldArrayItemProps>> = ({ children, onDelete, showDelete }) => (
  <Styled.FieldArrayItem display="flex" gap={8}>
    {children}
    {showDelete && <IconDelete onClick={onDelete} />}
  </Styled.FieldArrayItem>
)

export const FieldArrayItem = typedMemo(FieldArrayItemBase)
