import { FC } from 'react'

import { VendorCarrierPriceListItem, VendorCarrierPriceListItemType } from 'api'
import { useFormContext, useTruckCodes } from 'hooks'

import { FormItemEditSelectSingleAsync } from 'components'

import { TableOptionFour } from './elements/TableOptionFour'
import { TableOptionOne } from './elements/TableOptionOne'
import { TableOptionThree } from './elements/TableOptionThree'
import { TableOptionTwo } from './elements/TableOptionTwo'
import * as Styled from './styles'

interface TablePriceListItemProps extends Partial<VendorCarrierPriceListItem> {
  index: number
}

export const TablePriceListItem: FC<TablePriceListItemProps> = ({ index, truckCode, locationCode, type }) => {
  const { triggerSubmit } = useFormContext()

  // TODO: change condition to '===' when type will be changed on BE
  const isTypeOne = type == VendorCarrierPriceListItemType.One
  const isTypeTwo = type == VendorCarrierPriceListItemType.Two
  const isTypeThree = type == VendorCarrierPriceListItemType.Three
  const isTypeFour = type == VendorCarrierPriceListItemType.Four

  const fieldName = `list.${index}`

  return (
    <Styled.Wrapper display="flex" gap={4} ai="center">
      <FormItemEditSelectSingleAsync
        name={`list.${index}.truckCodeId`}
        onSubmit={triggerSubmit}
        size="small"
        width={120}
        defaultOption={{
          value: truckCode?.id as number,
          label: truckCode?.code as string,
        }}
        getItems={useTruckCodes}
      />
      {isTypeOne && <TableOptionOne name={fieldName} locationCode={locationCode} />}
      {isTypeTwo && <TableOptionTwo name={fieldName} />}
      {isTypeThree && <TableOptionThree name={fieldName} />}
      {isTypeFour && <TableOptionFour name={fieldName} />}
    </Styled.Wrapper>
  )
}
