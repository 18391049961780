import { AssetNumberType } from 'api/types/asset/assetNumberType'

export const assetNumberTypeLongLabel = {
  [AssetNumberType.Original]: 'Original Container Number',
  [AssetNumberType.Temporary]: 'Temporary Container Number',
  [AssetNumberType.Rental]: 'Rental Container Number',
}

export const assetNumberTypeLabel = {
  [AssetNumberType.Original]: 'Original',
  [AssetNumberType.Temporary]: 'Temporary',
  [AssetNumberType.Rental]: 'Rental',
}

export const assetNumberTypeOptions = [
  {
    label: assetNumberTypeLabel[AssetNumberType.Original],
    value: AssetNumberType.Original,
  },
  {
    label: assetNumberTypeLabel[AssetNumberType.Temporary],
    value: AssetNumberType.Temporary,
  },
  {
    label: assetNumberTypeLabel[AssetNumberType.Rental],
    value: AssetNumberType.Rental,
  },
]
