import { styled } from 'styled-components'

import { Checkbox as BaseCheckbox } from 'designSystem'

export const Checkbox = styled(BaseCheckbox)`
  &.ant-checkbox-wrapper span:last-child {
    padding-inline-end: ${({ theme }) => theme.spacers[2]};
  }
`

export const CreatedDateWrapper = styled.div`
  margin-top: auto;
`
