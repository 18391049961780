import { FC, useEffect } from 'react'

import { PurchaseOrder, PurchaseOrderStatus, PurchaseOrderType } from 'api'
import { useForm } from 'hooks'
import { PurchaseOrderFormAccessor } from 'types'
import { useGetIsAddNew, useSetPartialPurchaseOrderData } from 'store'

import { Container, Spacer } from 'designSystem'
import { StripLoader } from 'components'

import { useUpdatePurchaseOrder } from './hooks/useUpdatePurchaseOrder'
import { getViewOnlyFieldsPODetails } from './utils/getViewOnlyFieldsPODetails'
import { hasPoContainDifferentReferenceNumbers } from './utils/hasPoContainDifferentReferenceNumbers'
import { validationSchema } from './constants/validationSchema'
import { PurchaseOrderDetailsPayload } from './types/purchaseOrderDetailsPayload'
import { PurchaseOrderDetailsForm } from './elements/PurchaseOrderDetailsForm'
import { PurchaseOrderDetailsItems } from './elements/PurchaseOrderDetailsItems'
import { useGetPurchaseOrderItems } from './elements/PurchaseOrderDetailsItems/hooks/useGetPurchaseOrderItems'

interface PurchaseOrderDetailsProps {
  data: PurchaseOrder
}

export const PurchaseOrderDetails: FC<PurchaseOrderDetailsProps> = ({ data }) => {
  const { update, isError, isPending } = useUpdatePurchaseOrder(data.id)
  const setPartialPurchaseOrderData = useSetPartialPurchaseOrderData()
  const isAddNew = useGetIsAddNew()

  const { data: purchaseOrderItems, isLoading: isPurchaseOrderItemsLoading } = useGetPurchaseOrderItems({
    purchaseOrderId: data.id,
  })

  const isPOContainDifferentReferenceNumbers = hasPoContainDifferentReferenceNumbers(data, purchaseOrderItems)

  const { Form, triggerSubmit, setValue, trigger, getValues } = useForm<PurchaseOrderDetailsPayload>({
    mode: 'onChange',
    validationSchema,
    onSubmit: update,
    isSubmitError: isError,
    defaultValues: {
      ...data,
      vendorReleaseNumber: data?.vendorReleaseReferenceNumber?.number,
      redeliveryNumber: data?.redeliveryReferenceNumber?.number,
    },
    viewOnlyFields: getViewOnlyFieldsPODetails({
      isAddNew,
      isPOContainDifferentReferenceNumbers,
      isLoading: isPurchaseOrderItemsLoading,
      status: data?.status,
    }),
  })

  const handleSubmit = () => {
    if (data?.type === PurchaseOrderType.Standard) {
      triggerSubmit()
    } else {
      const currentLocationCodeId = getValues?.(PurchaseOrderFormAccessor.LocationCodeId)
      if (currentLocationCodeId && currentLocationCodeId !== data?.locationCodeId) {
        setValue(PurchaseOrderFormAccessor.DepotId, undefined)
        trigger(PurchaseOrderFormAccessor.DepotId)
      }
    }
  }

  useEffect(() => {
    setPartialPurchaseOrderData({ contacts: data?.contacts })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.contacts])

  const isStatusClosed = data?.status === PurchaseOrderStatus.Closed

  return (
    <Container fd="column" position="unset" bg="white" pa={4}>
      <StripLoader isLoading={isPending} noBorderRadius />
      <Form>
        <PurchaseOrderDetailsForm
          data={data}
          handleSubmit={handleSubmit}
          poStatus={data?.status || PurchaseOrderStatus.Open}
        />
      </Form>
      <Spacer mb={4} />
      <PurchaseOrderDetailsItems poData={data} allFieldsNotEditable={isStatusClosed} purchaseOrderId={data.id} />
    </Container>
  )
}
