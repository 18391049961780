import { ItemCategory, ItemCategoryType } from 'api'

export const searchCategories = (categories: ItemCategory[], search: string): ItemCategory[] => {
  return categories.reduce((acc, cur) => {
    if (cur.name.toLowerCase().includes(search.toLowerCase()) && cur.type === ItemCategoryType.Single) {
      acc.push(cur)
    } else {
      if (cur.type === ItemCategoryType.Single) return acc
      const isExistChildren = cur.children.some(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
      if (isExistChildren) {
        acc.push({
          ...cur,
          children: cur.children.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase())),
        })
      }
    }
    return acc
  }, [] as ItemCategory[])
}
