import type { ApiQueryParams, VendorDepotSettings } from 'api'
import { Pagination } from 'types'

import { api } from '../api'

export interface GetDepotCodesResponse extends Pagination {
  data?: VendorDepotSettings[]
}

export type GetDepotCodesQueryParams = ApiQueryParams

export const getDepotCodes = (params?: GetDepotCodesQueryParams): Promise<GetDepotCodesResponse> => {
  return api({
    method: 'get',
    url: '/api/v1/vendors/types/depots',
    params,
  })
}
