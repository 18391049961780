import { FC } from 'react'
import { Tooltip } from 'antd'

import { GetZohoLinkProps, useZohoLink } from 'hooks'

import { ReactLink, Typography, TypographyProps } from 'designSystem'

interface ZohoLinkProps extends GetZohoLinkProps {
  showLink?: boolean
  typographyProps?: Omit<TypographyProps, 'children'>
}

export const ZohoLink: FC<ZohoLinkProps> = ({ module, id, showLink, typographyProps }) => {
  const { getZohoLink } = useZohoLink()

  const zohoLink = getZohoLink({ module, id })

  return zohoLink ? (
    <ReactLink to={zohoLink}>
      <Tooltip title={zohoLink} destroyTooltipOnHide>
        <Typography color="inherit" size="xs" fontWeight="l" lineEllipsis={1} {...typographyProps}>
          {showLink ? zohoLink : id}
        </Typography>
      </Tooltip>
    </ReactLink>
  ) : (
    '-'
  )
}
