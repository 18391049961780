import { PurchaseOrderReceivingStatus } from 'api/types/purchaseOrder'

export const purchaseOrderReceivingStatusOptions: {
  value: PurchaseOrderReceivingStatus
  label: string
}[] = [
  {
    value: PurchaseOrderReceivingStatus.NotReceived,
    label: 'Not Received',
  },
  {
    value: PurchaseOrderReceivingStatus.PartiallyReceived,
    label: 'Partially Received',
  },
  {
    value: PurchaseOrderReceivingStatus.Received,
    label: 'Received',
  },
]
