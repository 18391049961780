import React, { CSSProperties, FC, forwardRef, KeyboardEvent, ReactNode, Ref, useCallback } from 'react'
import { InputNumberProps as BaseInputNumberProps } from 'antd/lib/input-number'

import { typedMemo } from 'types'
import { numberFormatter } from 'utils'

import { InputNumberValue } from './types'
import * as Styled from './styles'

export interface InputNumberProps extends Omit<BaseInputNumberProps, 'value' | 'onChange'> {
  error?: string
  width?: number
  ref?: Ref<HTMLInputElement>
  value?: InputNumberValue
  onChange?: (value: InputNumberValue) => void
  align?: CSSProperties['textAlign']
  suffix?: ReactNode
  suffixWidth?: number
  positive?: boolean
}

const InputNumberBase: FC<InputNumberProps> = forwardRef(
  (
    {
      error,
      width,
      prefix,
      suffix,
      suffixWidth,
      precision = 0,
      positive = true,
      onChange,
      formatter = numberFormatter,
      onPressEnter,
      ...props
    },
    ref: Ref<HTMLInputElement>,
  ) => {
    const handleChange = useCallback(
      (value?: string | number | null) => {
        if (typeof value !== 'string') {
          if (positive && value) {
            onChange?.(Math.abs(value))
          } else {
            onChange?.(value)
          }
        }
      },
      [onChange, positive],
    )

    const handlePressEnter = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        event.preventDefault()
        event.stopPropagation()
        onPressEnter?.(event)
      },
      [onPressEnter],
    )

    return (
      <Styled.Wrapper width={width} suffixExist={!!suffix} disabled={props.disabled} suffixWidth={suffixWidth}>
        <Styled.InputNumber
          {...props}
          onPressEnter={handlePressEnter}
          onChange={handleChange}
          status={error ? 'error' : undefined}
          ref={ref}
          precision={precision}
          formatter={formatter}
          prefix={prefix || <span />}
          $isPrefix={!!prefix}
          controls={false}
        />
        {suffix && <Styled.Suffix>{suffix}</Styled.Suffix>}
      </Styled.Wrapper>
    )
  },
)

const InputNumber = typedMemo(InputNumberBase)

InputNumber.displayName = 'InputNumber'

export { InputNumber }
