import { FC, useEffect } from 'react'

import { useFormContext } from 'hooks'

import { Spacer, Typography } from 'designSystem'
import { Card } from 'components'

import { FeedSettings } from '../FeedSettings'

interface PartsSettingsProps {
  isEdit?: boolean
}
export const PartsSettings: FC<PartsSettingsProps> = ({ isEdit }) => {
  const { unregister } = useFormContext()

  useEffect(() => {
    return () => {
      unregister('partSettings')
    }
  }, [unregister])

  return (
    <Card bordered={!isEdit} pa={isEdit ? 0 : undefined}>
      {!isEdit && (
        <Typography size="l">
          <Typography size="l" fontWeight="xl">
            Vendor Parts
          </Typography>{' '}
          Settings
        </Typography>
      )}
      <Spacer mb={4} />
      <FeedSettings name="partSettings" />
    </Card>
  )
}
