import { css } from 'styled-components'

export const MenuStyles = css`
  .ant-menu-submenu.ant-menu-submenu-horizontal {
    &.ant-menu-overflow-item-rest {
      display: flex;

      .ant-menu-submenu-title {
        color: ${({ theme }) => theme.colors.secondary};
        display: flex;
        align-items: center;
      }
      &.ant-menu-submenu-selected {
        .ant-menu-submenu-title {
          color: ${({ theme }) => theme.colors.accent[400]};
        }
      }
    }

    &.ant-menu-submenu-popup {
      .ant-menu-item {
        color: ${({ theme }) => theme.colors.secondary};
        font-weight: ${({ theme }) => theme.fontWeight.l};
      }

      .ant-menu-item-selected {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`
