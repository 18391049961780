import { FC, useCallback, useState } from 'react'

import { VendorType } from 'api'

import { Button, Modal } from 'designSystem'

import { UploadLocalFile } from 'modules/vendors/elements/UploadLocalFile'

interface UploadCSVProps {
  vendorId: number
  vendorType: VendorType
}

export const UploadCSV: FC<UploadCSVProps> = ({ vendorId, vendorType }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleCloseModal = useCallback(() => {
    setModalOpen(false)
  }, [])

  const handleOpenModal = useCallback(() => {
    setModalOpen(true)
  }, [])

  return (
    <>
      <Modal open={modalOpen} width={460} onCancel={handleCloseModal} destroyOnClose title="Upload" footer={null}>
        <UploadLocalFile vendorId={vendorId} handleCloseModal={handleCloseModal} vendorType={vendorType} />
      </Modal>
      <Button icon="upload" type="default" size="small" onClick={handleOpenModal}>
        Upload CSV
      </Button>
    </>
  )
}
