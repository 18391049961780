import { Radio as RadioBase } from 'antd'
import { styled } from 'styled-components'

export const Radio = styled(RadioBase)`
  &&.ant-radio-wrapper {
    font-weight: ${({ theme }) => theme.fontWeight.l};
    line-height: ${({ theme }) => theme.lineHeight.s};
  }
  & .ant-radio-checked .ant-radio-inner {
    border-color: ${({ theme }) => theme.colors.primary};
    &:after {
      transform: scale(0.575);
    }
  }
  &&.ant-radio-wrapper:hover .ant-radio-inner {
    border-color: ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.lines};
  }
`
