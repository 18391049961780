import * as Yup from 'yup'

import { Warehouse } from 'api'
import { addressValidation, emailValidation, isValueUniqueInArray, phoneNumberValidation } from 'utils'

export const validationSchema = Yup.object({
  name: Yup.string().max(100, 'Max length of 100 characters').required('Company name is required!'),
  phoneNumber: phoneNumberValidation.notRequired().nullable().default(null),
  contactName: Yup.string().max(100, 'Max length of 100 characters').required('Contact name is required!'),
  email: emailValidation,
  description: Yup.string().max(500, 'Max length of 100 characters').notRequired().nullable().default(null),
  ...addressValidation,
})

const warehouseSchema = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test('unique', 'Title should be unique', (value, record: any) => {
      const titles = record?.from?.[1].value?.warehouses?.map((el: Warehouse) => el.title)
      return isValueUniqueInArray(value, titles)
    }),
  ...addressValidation,
})

export const validationSchemaWarehouses = Yup.object().shape({
  warehouses: Yup.array().of(warehouseSchema).default([]),
})
