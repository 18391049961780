import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { deleteRepairs } from '../queryApi'

export const useDeleteRepairs = (assetId: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation({
    mutationFn: deleteRepairs,
    onSuccess: () => {
      dispatchAppEvent(AppEvent.AssetRepairDelete)

      showNotification({ title: 'Repair is deleted', type: 'success' })
    },
  })

  const remove = useCallback(
    (id: number) => {
      mutate({ assetId, id })
    },
    [mutate, assetId],
  )

  return {
    remove,
    ...rest,
  }
}
