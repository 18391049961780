import { FC, MouseEvent } from 'react'

import { ReferenceNumberType } from 'api'

import { Container, Radio, Typography } from 'designSystem'

import * as Styled from './styles'

interface ChooseTypeCardProps {
  type: ReferenceNumberType
  label?: string
  description?: string
  checked: boolean
  onClick: (type: ReferenceNumberType) => void
}

export const ChooseTypeCard: FC<ChooseTypeCardProps> = ({ label, description, type, checked, onClick }) => {
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    onClick(type)
  }

  return (
    <Styled.TypeCard pa={4} onClick={handleClick} $active={checked}>
      <Container ai="center" jc="space-between" gap={4}>
        <Container fd="column">
          <Typography size="s" fontWeight="xl" color={checked ? 'info' : undefined}>
            {label}
          </Typography>
          <Typography size="xs" fontWeight="l">
            {description}
          </Typography>
        </Container>
        <Radio checked={checked} />
      </Container>
    </Styled.TypeCard>
  )
}
