import theme from 'styles/theme'

const regex = /(?<!\{)(\{{2}[^{}\n]*}{2})(?!})/gi

export const addHighlightMarkup = (str: string): string =>
  str.replace(regex, (match) => `<span style="color:${theme.colors.accent[400]};"><strong>${match}</strong></span>`)

export const removeHighlightMarkup = (textHtml: string): string => {
  const nodeCopy = document.createElement('div')
  nodeCopy.innerHTML = textHtml

  const matches = nodeCopy.querySelectorAll(`[style="color:${theme.colors.accent[400]};"]`)
  matches.forEach((m) => {
    const content = m.textContent || ''
    m.insertAdjacentText('beforebegin', content)
    m.remove()
  })

  return nodeCopy.innerHTML
}
