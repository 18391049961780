import { Checkbox as CheckboxBase } from 'antd'
import { CheckboxGroupProps as CheckboxGroupPropsBase, CheckboxProps } from 'antd/lib/checkbox'
import { css, styled } from 'styled-components'

export const BaseStyle = css<CheckboxProps | CheckboxGroupProps>`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.l};

  .ant-checkbox {
    top: unset;
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        .ant-checkbox-checked .ant-checkbox-inner {
          border-color: ${({ theme }) => theme.colors.accent[200]};
          background: ${({ theme }) => theme.colors.accent[400]};
        }
        .ant-checkbox-inner {
          background: ${({ theme }) => theme.colors.accent[80]};
        }
      }
    `}
`

export const Checkbox = styled(CheckboxBase)<CheckboxProps>`
  &&&&.ant-checkbox-wrapper {
    ${BaseStyle}
  }
  &&& .ant-checkbox-input {
    margin: 0;
  }
`

export interface CheckboxGroupProps extends CheckboxGroupPropsBase {
  $vertical?: boolean
  $gridColumns?: number
  $gridGap?: number
}

export const CheckboxGroup = styled(CheckboxBase.Group)<CheckboxGroupProps>`
  ${({ $vertical }) => {
    if ($vertical) {
      return css`
        display: flex;
        flex-direction: column;
        .ant-checkbox-wrapper {
          margin-inline-start: 0;
        }
        .ant-checkbox-wrapper:not(:last-child) {
          margin-bottom: 16px;
        }
      `
    }
  }}

  ${({ $gridColumns, $gridGap }) => {
    if ($gridColumns) {
      return css`
        display: grid;
        grid-template-columns: repeat(${$gridColumns}, 1fr);
        gap: ${$gridGap}px;

        .ant-checkbox-wrapper {
          margin-inline-start: 0;
        }
      `
    }
  }}

  &&&&.ant-checkbox-group .ant-checkbox-wrapper {
    ${BaseStyle}
  }
`
