import isEqual from 'react-fast-compare'

import { PurchaseOrderItem, PurchaseOrderItemsSubLine } from 'api'

import { PurchaseOrderItemsFormPayload } from '../types/purchaseOrderItemsForm'

const getUpdatedSubLines = (prevSubLines?: PurchaseOrderItemsSubLine[], subLines?: PurchaseOrderItemsSubLine[]) => {
  const updatedSubLine = subLines?.reduce<Partial<PurchaseOrderItemsSubLine>[]>((acc, subLine, index) => {
    if (!isEqual(subLine, prevSubLines?.[index])) {
      const { id, expectedDeliveryDate, asset, locationCodeId, referenceType, vendorDepotId } = subLine
      const {
        assetNumber,
        containerColor,
        isBilled,
        isReceived,
        billingReference,
        vendorReleaseRefId,
        redeliveryRefId,
      } = asset || {}
      const payload = {
        id,
        ...(prevSubLines?.[index]?.asset?.vendorReleaseRefId !== vendorReleaseRefId && {
          vendorReleaseRefId,
        }),
        ...(prevSubLines?.[index]?.asset?.redeliveryRefId !== redeliveryRefId && { redeliveryRefId }),
        ...(prevSubLines?.[index]?.asset?.assetNumber !== assetNumber && { assetNumber }),
        ...(prevSubLines?.[index]?.asset?.containerColor !== containerColor && { containerColor }),
        ...(prevSubLines?.[index]?.asset?.isBilled !== isBilled && { isBilled }),
        ...(prevSubLines?.[index]?.asset?.isReceived !== isReceived && { isReceived }),
        ...(prevSubLines?.[index]?.asset?.billingReference !== billingReference && { billingReference }),
        ...(prevSubLines?.[index]?.expectedDeliveryDate !== expectedDeliveryDate && { expectedDeliveryDate }),
        ...(prevSubLines?.[index]?.locationCodeId !== locationCodeId && { locationCodeId }),
        ...(prevSubLines?.[index]?.vendorDepotId !== vendorDepotId && { vendorDepotId }),
        ...(prevSubLines?.[index]?.referenceType !== referenceType && { referenceType }),
      }
      acc.push(payload)
    } else {
      // Need to send id to backend, otherwise it will delete subLine
      acc.push({
        id: subLine.id,
      })
    }
    return acc
  }, [])

  return updatedSubLine
}

export const getPatchPayload = (payload: PurchaseOrderItemsFormPayload, defaultData: PurchaseOrderItem[]) => {
  return payload.items?.map((item) => {
    const { id, itemId, qty, rate, subLines, billedQty, receivedQty } = item
    const prev = defaultData.find((item) => item.id === id)
    const updatedSubLines = getUpdatedSubLines(prev?.subLines, subLines)
    const atLeastOneFieldUpdated = updatedSubLines?.some((subLine) => Object.keys(subLine).length > 1)
    const shouldUpdateSubLines = !isEqual(prev?.subLines, subLines) && atLeastOneFieldUpdated

    const payload = {
      ...(prev?.itemId !== itemId && { itemId }),
      ...(prev?.qty !== qty && { qty }),
      ...(prev?.rate !== rate && { rate }),
      ...(prev?.billedQty !== billedQty && { billedQty }),
      ...(prev?.receivedQty !== receivedQty && { receivedQty }),
      ...(shouldUpdateSubLines && {
        subLines: updatedSubLines as PurchaseOrderItemsSubLine[],
      }),
    }

    return {
      id,
      payload,
    }
  })
}
