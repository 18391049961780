import { getSalesOrders, SalesOrder } from 'api'
import { QueryKey } from 'enums'
import { AsyncSelectGetItemsProps } from 'types'

import type { SelectSingleOption } from 'designSystem'

import { useAsyncList } from './useAsyncList'

export const useSalesOrders = ({
  valuePropName, // keyof SO - the value of it should be of type string | number
  withFullDataOption,
  fields,
  ...props
}: AsyncSelectGetItemsProps) => {
  const { list, ...asyncSelectProps } = useAsyncList<SalesOrder>({
    ...props,
    queryFn: getSalesOrders,
    queryKey: QueryKey.GetSalesOrderList,
    searchFieldName: 'number',
    limit: 10,
    fields: fields || 'id,number',
  })

  const options = list.map((po) => ({
    value: valuePropName ? (po[valuePropName as keyof SalesOrder] as string | number) : po.id,
    label: po.number,
    ...(withFullDataOption && { data: po }),
  })) satisfies SelectSingleOption[]

  return {
    options,
    ...asyncSelectProps,
  }
}
