import { FC, useMemo } from 'react'

import {
  ReferenceNumber,
  ReferenceNumberBooking,
  ReferenceNumberPickup,
  ReferenceNumberRedelivery,
  ReferenceNumberType,
  ReferenceNumberVendorRelease,
} from 'api'

import { Container } from 'designSystem'

import { Booking } from './elements/Booking'
import { Pickup } from './elements/Pickup'
import { Redelivery } from './elements/Redelivery'
import { VendorRelease } from './elements/VendorRelease'

interface ReferenceDetailsProps {
  data?: ReferenceNumber
  canEditOnlyStatus?: boolean
}

export const ReferenceDetails: FC<ReferenceDetailsProps> = ({ data, canEditOnlyStatus }) => {
  const component = useMemo(() => {
    if (!data) {
      return null
    }

    return {
      [ReferenceNumberType.Booking]: (
        <Booking data={data as ReferenceNumberBooking} canEditOnlyStatus={canEditOnlyStatus} />
      ),
      [ReferenceNumberType.Redelivery]: (
        <Redelivery data={data as ReferenceNumberRedelivery} canEditOnlyStatus={canEditOnlyStatus} />
      ),
      [ReferenceNumberType.VendorRelease]: (
        <VendorRelease data={data as ReferenceNumberVendorRelease} canEditOnlyStatus={canEditOnlyStatus} />
      ),
      [ReferenceNumberType.Pickup]: (
        <Pickup data={data as ReferenceNumberPickup} canEditOnlyStatus={canEditOnlyStatus} />
      ),
    }[data.type]
  }, [data, canEditOnlyStatus])

  return (
    <Container fd="column" bg="white" pa={4}>
      {component}
    </Container>
  )
}
