import { forwardRef, Ref, useCallback, useState } from 'react'
import { Radio, Space } from 'antd'
import type { SpaceSize } from 'antd/es/space'
import { RadioChangeEvent } from 'antd/lib/radio'

import { typedMemo } from 'types'

import { RadioGroupDirection } from './types/radioGroupDirection'
import { RadioOption } from './types/radioOption'
import * as Styled from './styles'

interface RadioGroupProps<T> {
  defaultValue?: T
  options: RadioOption<T>[]
  onChange?: (value: T) => void
  direction?: RadioGroupDirection
  spaceSize?: SpaceSize
  wrap?: boolean
}

export const RadioGroupBase = forwardRef(
  <T,>(
    {
      defaultValue,
      onChange,
      options,
      direction = 'vertical',
      spaceSize = 'middle',
      wrap,
      ...props
    }: RadioGroupProps<T>,
    ref: Ref<HTMLDivElement>,
  ) => {
    const [value, setValue] = useState<T | undefined>(defaultValue || undefined)

    const handleChange = useCallback(
      (e: RadioChangeEvent) => {
        setValue(e.target.value)
        onChange?.(e.target.value)
      },
      [onChange],
    )

    return (
      <Radio.Group value={value} {...props} onChange={handleChange} ref={ref}>
        <Space wrap={wrap} direction={direction} size={spaceSize}>
          {options.map((option, index) => (
            <Styled.Radio key={index} value={option.value} disabled={option.disabled}>
              {option.label}
            </Styled.Radio>
          ))}
        </Space>
      </Radio.Group>
    )
  },
)

export const RadioGroup = typedMemo(RadioGroupBase)
