import { PurchaseOrderFormAccessor } from 'types'

interface GetTableExpandableFieldNameProps {
  rowSubLinesFieldName: string
  index: number
  fieldName: PurchaseOrderFormAccessor
  isEditMode: boolean | undefined
}

export const getTableExpandableFieldName = ({
  rowSubLinesFieldName,
  index,
  fieldName,
  isEditMode,
}: GetTableExpandableFieldNameProps): string => {
  return isEditMode
    ? `${rowSubLinesFieldName}.${index}.asset.${fieldName}`
    : `${rowSubLinesFieldName}.${index}.${fieldName}`
}
