import { SelectSingleOption, Tab } from 'designSystem'

import { EntityContacts } from '../elements/EntityContacts'
import { NewContact } from '../elements/NewContact'

import type { AddContactsModalProps } from '../AddContactsModal'

interface GetTabItemsProps
  extends Pick<
    AddContactsModalProps,
    'contacts' | 'onConfirm' | 'onCancel' | 'confirmLoading' | 'showAddNew' | 'contactsCanAddCount'
  > {
  entityContactsOptions?: SelectSingleOption[]
}

export const getTabItems = ({
  contacts,
  entityContactsOptions,
  showAddNew = true,
  contactsCanAddCount,
  ...props
}: GetTabItemsProps): Tab[] => [
  ...(showAddNew
    ? [
        {
          label: 'New Contact',
          children: <NewContact {...props} contacts={contacts} />,
        },
      ]
    : []),
  ...(entityContactsOptions?.length
    ? [
        {
          label: 'Contacts',
          children: (
            <EntityContacts
              {...props}
              entityContactsOptions={entityContactsOptions}
              contactsCanAddCount={contactsCanAddCount}
            />
          ),
        },
      ]
    : []),
]
