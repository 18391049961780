import { Navigate } from 'react-router-dom'

import { companyRoute } from 'constant'

import { LinkTabs, NotFoundPage } from 'components'

import { companyTabs } from './companyTabs'

export const companyRoutes = [
  {
    path: '/',
    element: <Navigate to={companyRoute.generalInformation.replace('/', '')} replace />,
  },
  {
    path: companyRoute.generalInformation,
    element: <LinkTabs items={companyTabs} />,
  },

  {
    path: '*',
    element: <NotFoundPage />,
  },
]
