import { VendorResalePriceOverwriteRule } from 'api'

import { ResaleRuleFormPayload } from '../types/resaleRuleCreateForm'

export const getPatchPayload = (payload: ResaleRuleFormPayload, defaultData: VendorResalePriceOverwriteRule[]) => {
  return payload.list?.map((resaleRule) => {
    const { id, statementOperator, statementQuantity, statementMeasure, condQuantity, condOperator, itemId, vendorId } =
      resaleRule

    const prev = defaultData.find((item) => item.id === id)

    const payload = {
      ...(prev?.itemId !== itemId && { itemId }),
      ...(prev?.vendorId !== vendorId && { vendorId }),
      ...(prev?.statementOperator !== statementOperator && { statementOperator }),
      ...(prev?.statementQuantity !== statementQuantity && { statementQuantity }),
      ...(prev?.statementMeasure !== statementMeasure && { statementMeasure }),
      ...(prev?.condQuantity !== condQuantity && { condQuantity }),
      ...(prev?.condOperator !== condOperator && { condOperator }),
    }

    return {
      id,
      payload,
    }
  })
}
