import { FC } from 'react'

import type { PurchaseOrderReceivingStatus } from 'api'

import { Tag, TagProps } from 'designSystem'

import { getTagDataByPurchaseOrderReceivingStatus } from './utils/getTagDataByPurchaseOrderReceivingStatus'

interface PurchaseOrderReceivingStatusTagProps extends Omit<TagProps, 'status'> {
  status: PurchaseOrderReceivingStatus
}

export const PurchaseOrderReceivingStatusTag: FC<PurchaseOrderReceivingStatusTagProps> = ({
  status: poStatus,
  ...props
}) => {
  const { status, text } = getTagDataByPurchaseOrderReceivingStatus(poStatus)
  return <Tag {...props} status={status} text={text} />
}
