import { FC } from 'react'
import { Col, Row } from 'antd'

import { PurchaseOrderStatus, ReferenceNumberStatus, ReferenceNumberType } from 'api'
import { usePOs, useReferences } from 'hooks'
import { referenceLabels } from 'constant'
import { ReferenceFormAccessor } from 'types'

import { Container, FormItem, SelectMultiAsync, Typography } from 'designSystem'
import { FormItemSelectSingleAsync } from 'components'

export const RelatedFields: FC = () => {
  return (
    <Container fd="column" gap={16}>
      <Typography size="m" fontWeight="xl">
        Related Records
      </Typography>
      <Container fd="column" gap={8}>
        <Row>
          <Col span={24}>
            <FormItemSelectSingleAsync
              name={ReferenceFormAccessor.PurchaseOrderId}
              label={referenceLabels.purchaseOrderId}
              getItems={usePOs}
              queryParams={{ status: { $notin: [PurchaseOrderStatus.Draft, PurchaseOrderStatus.Closed] } }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem name={ReferenceFormAccessor.BookingNumbersIds} label={referenceLabels.bookingNumbers}>
              <SelectMultiAsync
                showSearch
                fullwidth
                name={ReferenceFormAccessor.BookingNumbersIds}
                getItems={useReferences}
                placeholder="Select"
                queryParams={{
                  [ReferenceFormAccessor.Type]: { $eq: ReferenceNumberType.Booking },
                  [ReferenceFormAccessor.Status]: {
                    $notin: [ReferenceNumberStatus.Closed, ReferenceNumberStatus.Voided],
                  },
                }}
              />
            </FormItem>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
