import { SalesOrderLineItem } from 'api'

interface LineItemsTotal {
  taxSum: number
  subtotalSum: number
  totalSum: number
}

export const getLineItemsTotal = (currentItemsValues: SalesOrderLineItem[] | undefined): LineItemsTotal | undefined => {
  return (
    currentItemsValues?.reduce(
      (acc, { price, qty, taxes, discount }: SalesOrderLineItem) => {
        acc.taxSum += taxes || 0
        acc.subtotalSum += price * qty || 0 - (discount || 0)
        acc.totalSum += (price * qty || 0) + (taxes || 0) - (discount || 0)
        return acc
      },
      {
        taxSum: 0,
        subtotalSum: 0,
        totalSum: 0,
      },
    ) || undefined
  )
}
