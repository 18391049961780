import { FC, useCallback, useEffect, useState } from 'react'

import { typedMemo } from 'types'

import { Button } from 'designSystem'

import { AddContactsModal, AddContactsModalProps } from '../modals'

interface AddContactsActionButtonProps extends Omit<AddContactsModalProps, 'open' | 'onCancel'> {
  isConfirmSuccess: boolean
  onModalOpenChange?: (open: boolean) => void
  buttonText?: string
  entityContactsCount: number
  maxContactsCount: number
}

const AddContactsActionButtonBase: FC<AddContactsActionButtonProps> = ({
  isConfirmSuccess,
  confirmLoading,
  onModalOpenChange,
  buttonText = 'Add Contacts',
  contacts,
  entityContactsCount,
  maxContactsCount,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const showAddNew = entityContactsCount < maxContactsCount
  const contactsCanAddCount = maxContactsCount - (contacts?.length || 0)

  const openModal = useCallback(() => {
    setOpen(true)
    onModalOpenChange?.(true)
  }, [onModalOpenChange])

  const closeModal = useCallback(() => {
    setOpen(false)
    onModalOpenChange?.(false)
  }, [onModalOpenChange])

  useEffect(() => {
    if (isConfirmSuccess && !confirmLoading) {
      closeModal()
    }
  }, [isConfirmSuccess, confirmLoading, closeModal])

  return (
    <>
      <Button size="small" px={3} onClick={openModal}>
        {buttonText}
      </Button>
      <AddContactsModal
        {...props}
        open={open}
        onCancel={closeModal}
        confirmLoading={confirmLoading}
        contacts={contacts}
        showAddNew={showAddNew}
        contactsCanAddCount={contactsCanAddCount}
      />
    </>
  )
}

export const AddContactsActionButton = typedMemo(AddContactsActionButtonBase)
