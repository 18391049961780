import { FC } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { Layout } from 'antd'

import { Sidebar } from 'components'

import { settingsRoutes, settingsSidebarItems } from './constants'

export const Settings: FC = () => {
  return (
    <Layout>
      <Outlet />
      {!!settingsSidebarItems.length && <Sidebar sidebarItems={settingsSidebarItems} />}
      <Layout.Content>
        <Routes>
          {settingsRoutes.map((settingRoute) => (
            <Route key={settingRoute.path} path={settingRoute.path} element={settingRoute.element} />
          ))}
        </Routes>
      </Layout.Content>
    </Layout>
  )
}
