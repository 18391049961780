import { PurchaseOrder, PurchaseOrderItemsSubLine } from 'api'

import { prepareNewSubLineData } from 'modules/purchaseOrders/utils/prepareNewSubLineData'

interface GetUpdatedSubLinesProps {
  prevSubLines: PurchaseOrderItemsSubLine[]
  poData?: PurchaseOrder
  increaseQty: number
}

export const getUpdatedSubLines = ({ prevSubLines, poData, increaseQty }: GetUpdatedSubLinesProps) => {
  const newSubLine = prepareNewSubLineData(poData)

  const newSubLines = []

  for (let i = 0; i < increaseQty; i++) {
    newSubLines.push(newSubLine)
  }

  return [...prevSubLines, ...newSubLines]
}
