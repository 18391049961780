import { FC } from 'react'

import { useGetItemCategories } from 'hooks/useGetItemCategories'
import { getSingleCategoriesOptions } from 'utils'

import { TableFilterSelectMulti } from 'components'

export const ItemCategoryFilter: FC = () => {
  const { data: categories, isLoading } = useGetItemCategories()
  const categoriesOptions = getSingleCategoriesOptions(categories)

  return (
    <TableFilterSelectMulti name="categoryId" loading={isLoading} options={categoriesOptions} placeholder="Category" />
  )
}
