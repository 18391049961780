import { CSSProperties, ElementRef, forwardRef, ReactNode, useCallback, useImperativeHandle, useRef } from 'react'
import type { DatePickerProps as BaseDatePickerProps } from 'antd'
import { DatePicker as DatePickerBase } from 'antd'

import { DateFormat, typedMemo } from 'types'
import { Dayjs, toDate } from 'utils'
import theme from 'styles/theme'

import * as Styled from './styles'

import { Icon } from '../Icon'

export type DatePickerProps = Omit<BaseDatePickerProps, 'format' | 'value'> & {
  value?: Dayjs | Date | null
  align?: CSSProperties['textAlign']
  width?: number
  error?: string
  format?: keyof typeof DateFormat
  prefix?: ReactNode
}

export type DatePickerRef = ElementRef<typeof DatePickerBase> & {
  blur: () => void
}

export const DatePickerBaseComponent = forwardRef<DatePickerRef, DatePickerProps>(
  ({ placeholder, suffixIcon, onChange, value, format = 'Standard', prefix, ...props }, ref) => {
    const datePickerRef = useRef<DatePickerRef>(null)

    useImperativeHandle(ref, () => datePickerRef.current as DatePickerRef)

    const handleChange = useCallback(
      (value: Dayjs | null, dateString: string) => {
        onChange?.(value, dateString)
        datePickerRef.current?.blur()
      },
      [onChange],
    )

    return (
      <Styled.Container $prefix={!!prefix}>
        <Styled.DatePicker
          {...props}
          value={value ? toDate(value) : undefined}
          ref={datePickerRef}
          format={DateFormat[format]}
          onChange={handleChange}
          placeholder={placeholder || DateFormat[format].toLowerCase()}
          suffixIcon={suffixIcon ? suffixIcon : <Icon icon="calendar" size={12} color={theme.colors.primary} />}
        />
        {prefix ? <Styled.PrefixIcon>{prefix}</Styled.PrefixIcon> : null}
      </Styled.Container>
    )
  },
)

export const DatePicker = typedMemo(DatePickerBaseComponent)
