import { FC, PropsWithChildren, useCallback, useEffect, useRef } from 'react'
import isEqual from 'react-fast-compare'

import { ReferenceNumberStatus, ReferenceNumberType } from 'api'
import { useForm, useMemoCompare } from 'hooks'
import { typedMemo } from 'types'

import { Container } from 'designSystem'
import { Spinner, StripLoader } from 'components'
import {
  BookingNumberForm,
  PickupNumberForm,
  RedeliveryNumberForm,
  VendorReleaseNumberForm,
} from 'modules/references/types'

import { useGetReferenceItems } from './hooks/useGetReferenceItems'
import { useReferenceItemRequests } from './hooks/useReferenceItemRequests'
import { getFormPropsByReferenceType } from './utils/getFormPropsByReferenceType'

import { ReferenceItems, ReferenceItemsProps } from '../ReferenceItems'

interface ReferenceItemsEditProps extends Omit<ReferenceItemsProps, 'pa'> {
  id?: number
  status?: ReferenceNumberStatus
}

type ReferenceItemsForm =
  | Pick<VendorReleaseNumberForm, 'items'>
  | Pick<RedeliveryNumberForm, 'items'>
  | Pick<BookingNumberForm, 'items'>
  | Pick<PickupNumberForm, 'items'>

const ReferenceItemsEditBase: FC<PropsWithChildren<ReferenceItemsEditProps>> = ({
  id,
  status,
  type,
  isFormViewOnly, // use to make fields view only outside
  vendorReleaseItems,
  ...props
}) => {
  const shouldCheckReferenceStatusChange = useRef(false)
  const shouldCheckAllReceived =
    status &&
    type === ReferenceNumberType.Redelivery &&
    status !== ReferenceNumberStatus.Received &&
    shouldCheckReferenceStatusChange.current

  const { data, isLoading } = useGetReferenceItems(id, shouldCheckAllReceived)
  const { post, update, remove, isError, isLoading: isItemLoading } = useReferenceItemRequests(id)

  const { validationSchema, ...currentTypeProps } = getFormPropsByReferenceType(type)

  const defaultItems = useMemoCompare(
    data?.map(({ id, qty, itemId, subLines = [] }) => ({
      id,
      qty,
      itemId,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      subLines: subLines.map(({ id: subLineId, asset, redeliveryReference, ...rest }) => ({
        id: subLineId,
        assetNumber: asset?.assetNumber,
        assetNumberType: asset?.assetNumberType,
        ...rest,
      })),
    })),
  )

  const onSubmit = useCallback(
    (payload: ReferenceItemsForm) => {
      payload.items?.forEach((item) => {
        const defaultItem = defaultItems?.find(({ id }) => item?.id === id)
        if (!isEqual(item, defaultItem)) {
          if (item?.id) {
            shouldCheckReferenceStatusChange.current = true
            update(item.id as number, item)
          } else {
            post(item)
          }
        }
      })
    },
    [update, post, defaultItems],
  )

  const handleRemoveItem = useCallback(
    (index: number) => {
      const item = defaultItems?.[index]
      if (item?.id) {
        remove(item.id)
      }
    },
    [remove, defaultItems],
  )

  const { Form } = useForm<ReferenceItemsForm>({
    mode: 'onChange',
    validationSchema,
    context: {
      defaultItems,
    },
    onSubmit,
    isTableEditForm: true,
    isSubmitError: isError,
    viewOnlyFields: isFormViewOnly ? ['items'] : undefined,
    defaultValues: {
      items: defaultItems,
    },
  })

  useEffect(() => {
    if (shouldCheckReferenceStatusChange.current) {
      shouldCheckReferenceStatusChange.current = false
    }
  }, [data])

  if (!type) {
    return null
  }

  return (
    <Container fd="column" minHeight={isLoading ? 100 : undefined} position="unset">
      {isLoading ? (
        <Spinner />
      ) : (
        <Form>
          <StripLoader isLoading={isItemLoading} noBorderRadius />
          <ReferenceItems
            {...props}
            isFormViewOnly={isFormViewOnly}
            type={type}
            data={data}
            {...currentTypeProps}
            onDeleteItem={handleRemoveItem}
            vendorReleaseItems={vendorReleaseItems}
          />
        </Form>
      )}
    </Container>
  )
}

export const ReferenceItemsEdit = typedMemo(ReferenceItemsEditBase)
