import { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { ItemType } from 'api'
import { useForm, useGetItemCategories } from 'hooks'
import {
  assetCodeAdditionalAttributesOptions,
  assetCodeGradeOptions,
  assetCodeHeightOptions,
  assetCodeLengthOptions,
  assetCodeTypeOptions,
  assetCodeWidthOptions,
  confirmationText,
  currency,
  itemLabels,
  itemTypeOptions,
} from 'constant'
import { formatDate, getSingleCategoriesOptions } from 'utils'

import { Container, Divider, EditFormItem, SelectSingleOption, Spacer, Typography } from 'designSystem'
import {
  Card,
  ConfirmationPopover,
  ConfirmationRequiredPopover,
  FormItemEditInput,
  FormItemEditSelectMulti,
  FormItemEditSelectSingle,
  FormItemEditTextArea,
  LabelValueMarkup,
  Spinner,
  StripLoader,
} from 'components'
import { FormItemEditInputNumber } from 'components/FormElements/FormItemEditInputNumber'

import { useGetItemById } from './hooks/useGetItemById'
import { useUpdateItem } from './hooks/useUpdateItem'
import { validationSchema } from '../../../../utils/validationSchema'
import { ItemForm } from '../../../../types/itemForm'
import { ItemFormData } from '../../../../types/itemPayload'
import { EditGroupSKUs } from './elements/EditGroupSKUs'
import * as Styled from './styles'

export const ItemDetails: FC = () => {
  const { id } = useParams()

  const { data, isLoading: isGetLoading } = useGetItemById(Number(id))
  const { update, isError, isPending } = useUpdateItem(Number(id))
  const { data: categories, isLoading: isCategoriesLoading } = useGetItemCategories()

  const type = data?.type
  const isAssetsType = type === ItemType.Assets

  const categoriesOptions = getSingleCategoriesOptions(categories)

  const onSubmit = useCallback(
    (data: ItemFormData) => {
      update(data)
    },
    [update],
  )

  const { groupedSKUs, ...restData } = data || {}

  const { Form, triggerSubmit } = useForm<ItemForm>({
    validationSchema,
    context: { itemView: true },
    mode: 'onChange',
    onSubmit,
    isSubmitError: isError,
    defaultValues: data
      ? {
          ...restData,
          groupedSKUs: groupedSKUs?.map(({ id, sku }) => ({
            value: id,
            label: sku,
          })),
        }
      : {},
    viewOnlyFields: ['type'],
  })

  if (isGetLoading) {
    return <Spinner />
  }

  const categoryDefaultOption = {
    value: data?.category?.id,
    label: data?.category?.name,
  } as SelectSingleOption

  return (
    <>
      {data && (
        <Form>
          <Container pa={4} display="flex" gap={8} fw="wrap">
            <Container display="flex" fd="column" gap={8}>
              <Card maxWidth={390}>
                <StripLoader isLoading={isPending} />

                <EditFormItem
                  name="name"
                  render={({ field }) => (
                    <FormItemEditInput
                      size="large"
                      align="left"
                      {...field}
                      onConfirm={triggerSubmit}
                      popover={<ConfirmationRequiredPopover withPopover />}
                      placeholder="Enter Item Name"
                    />
                  )}
                />
                <Divider mt={3} mb={4} />
                <Container gap={4} fd="column">
                  <EditFormItem
                    name="sku"
                    label={itemLabels.sku}
                    render={({ field }) => (
                      <FormItemEditInput
                        uppercase
                        placeholder="Enter SKU"
                        {...field}
                        onConfirm={triggerSubmit}
                        popover={<ConfirmationRequiredPopover withPopover placement="bottomLeft" />}
                      />
                    )}
                  />
                  <EditFormItem
                    name="type"
                    label={itemLabels.type}
                    render={({ field }) => (
                      <FormItemEditSelectSingle {...field} options={itemTypeOptions} onSubmit={triggerSubmit} />
                    )}
                  />
                  {isAssetsType && (
                    <>
                      <EditFormItem
                        name="assetType"
                        label={itemLabels.assetType}
                        render={({ field }) => (
                          <FormItemEditSelectSingle
                            {...field}
                            width={120}
                            options={assetCodeTypeOptions}
                            onSubmit={triggerSubmit}
                            popover={
                              <ConfirmationPopover
                                titleText="Confirmation Required"
                                placement="bottomLeft"
                                text={confirmationText.confirmationRequired}
                                okText="Yes, Save"
                                okType="primary"
                              />
                            }
                          />
                        )}
                      />
                      <EditFormItem
                        name="length"
                        label={itemLabels.length}
                        render={({ field }) => (
                          <FormItemEditSelectSingle
                            {...field}
                            options={assetCodeLengthOptions}
                            onSubmit={triggerSubmit}
                            popover={
                              <ConfirmationPopover
                                titleText="Confirmation Required"
                                placement="bottomLeft"
                                text={confirmationText.confirmationRequired}
                                okText="Yes, Save"
                                okType="primary"
                              />
                            }
                          />
                        )}
                      />
                      <EditFormItem
                        name="width"
                        label={itemLabels.width}
                        render={({ field }) => (
                          <FormItemEditSelectSingle
                            {...field}
                            options={assetCodeWidthOptions}
                            onSubmit={triggerSubmit}
                            popover={
                              <ConfirmationPopover
                                titleText="Confirmation Required"
                                placement="bottomLeft"
                                text={confirmationText.confirmationRequired}
                                okText="Yes, Save"
                                okType="primary"
                              />
                            }
                          />
                        )}
                      />
                      <EditFormItem
                        name="height"
                        label={itemLabels.height}
                        render={({ field }) => (
                          <FormItemEditSelectSingle
                            {...field}
                            options={assetCodeHeightOptions}
                            onSubmit={triggerSubmit}
                            popover={
                              <ConfirmationPopover
                                titleText="Confirmation Required"
                                placement="bottomLeft"
                                text={confirmationText.confirmationRequired}
                                okText="Yes, Save"
                                okType="primary"
                              />
                            }
                          />
                        )}
                      />
                      <EditFormItem
                        name="grade"
                        label={itemLabels.grade}
                        render={({ field }) => (
                          <FormItemEditSelectSingle
                            {...field}
                            options={assetCodeGradeOptions}
                            onSubmit={triggerSubmit}
                            popover={
                              <ConfirmationPopover
                                titleText="Confirmation Required"
                                placement="bottomLeft"
                                text={confirmationText.confirmationRequired}
                                okText="Yes, Save"
                                okType="primary"
                              />
                            }
                          />
                        )}
                      />
                      <EditFormItem
                        name="additionalAttributes"
                        label={itemLabels.additionalAttributes}
                        render={({ field }) => (
                          <FormItemEditSelectMulti
                            {...field}
                            width={150}
                            options={assetCodeAdditionalAttributesOptions}
                            onConfirm={triggerSubmit}
                            popover={
                              <ConfirmationPopover
                                titleText="Confirmation Required"
                                placement="bottomLeft"
                                text={confirmationText.confirmationRequired}
                                okText="Yes, Save"
                                okType="primary"
                              />
                            }
                          />
                        )}
                      />
                      <EditFormItem
                        name="weight"
                        label={itemLabels.weight}
                        render={({ field }) => (
                          <FormItemEditInputNumber
                            placeholder={itemLabels.weight}
                            {...field}
                            onConfirm={triggerSubmit}
                            precision={2}
                            popover={<ConfirmationRequiredPopover withPopover />}
                          />
                        )}
                      />
                    </>
                  )}

                  <Styled.Divider />
                  <EditFormItem
                    name="price"
                    label={itemLabels.price}
                    render={({ field }) => (
                      <FormItemEditInputNumber
                        placeholder="Enter Price"
                        {...field}
                        onConfirm={triggerSubmit}
                        precision={2}
                        suffix={currency}
                        popover={<ConfirmationRequiredPopover withPopover />}
                      />
                    )}
                  />
                  <Styled.Divider />
                  <EditFormItem
                    name="categoryId"
                    label={itemLabels.categoryId}
                    render={({ field }) => (
                      <FormItemEditSelectSingle
                        {...field}
                        width={200}
                        showSearch
                        options={categoriesOptions || [categoryDefaultOption]}
                        onSubmit={triggerSubmit}
                        loading={isCategoriesLoading}
                        defaultOption={categoryDefaultOption}
                        popover={
                          <ConfirmationPopover
                            titleText="Confirmation Required"
                            placement="bottomLeft"
                            text={confirmationText.confirmationRequired}
                            okText="Yes, Save"
                            okType="primary"
                          />
                        }
                      />
                    )}
                  />
                  <EditGroupSKUs />
                </Container>
              </Card>
              <Container display="block" width={390}>
                <Card>
                  <LabelValueMarkup
                    label={itemLabels.createdBy}
                    value={data?.createdBy ? `${data?.createdBy.firstName} ${data?.createdBy.firstName}` : '-'}
                  />
                  <LabelValueMarkup label={itemLabels.createdAt} value={formatDate(data?.createdAt)} />
                </Card>
              </Container>
            </Container>
            <Container display="block" width={390}>
              <Card maxWidth={390}>
                <Typography size="m" fontWeight="xl">
                  {itemLabels.description}
                </Typography>
                <Spacer mb={4} />
                <EditFormItem
                  name="description"
                  render={({ field }) => (
                    <FormItemEditTextArea
                      placeholder="Type description"
                      {...field}
                      onConfirm={triggerSubmit}
                      popover={<ConfirmationRequiredPopover withPopover />}
                    />
                  )}
                />
              </Card>
            </Container>
          </Container>
        </Form>
      )}
    </>
  )
}
