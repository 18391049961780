import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { Tooltip } from 'antd'

import { EntityId } from 'api'
import theme from 'styles/theme'

import { Button } from 'designSystem'

import { useResetReferenceAssetNumber } from '../../hooks/useResetAssetNumber'

interface ResetAssetNumberButtonProps {
  subLineId: EntityId
  itemId?: EntityId
  disabled?: boolean
}

export const ResetAssetNumberButton: FC<ResetAssetNumberButtonProps> = ({ subLineId, itemId, disabled }) => {
  const { hash } = useLocation()
  const referenceId = Number(hash.split('/')[1])

  const { patchReferenceAssetNumber, isPending } = useResetReferenceAssetNumber({ referenceId, itemId, subLineId })

  return (
    <Tooltip title="Reset Asset Number" color={theme.colors.primary} arrow={false}>
      <Button icon="repeat" extraSmall onClick={patchReferenceAssetNumber} loading={isPending} disabled={disabled} />
    </Tooltip>
  )
}
