import * as Yup from 'yup'

import { passwordValidation } from 'utils'

export const validationSchema = Yup.object({
  newPassword: passwordValidation.required('New Password is required'),
  oldPassword: passwordValidation.required('Old Password is required'),
  confirmPassword: passwordValidation
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('Confirm Password is required'),
})
