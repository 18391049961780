import { InputNumber as BaseInputNumber } from 'antd'
import { css, styled } from 'styled-components'

import type { InputNumberProps } from './InputNumber'

interface WrapperProps {
  width?: number
  suffixExist: boolean
  suffixWidth?: number
  disabled?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  width: ${({ width }) => (width ? `${width}px;` : '100%')};
  position: relative;
  display: flex;
  ${({ suffixExist, suffixWidth }) =>
    suffixExist &&
    css`
      &&&& .ant-input-number-affix-wrapper {
        padding-right: ${({ theme }) => (suffixWidth ? `${suffixWidth}px` : theme.spacers[5])};
      }
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      &&&& .ant-input-number-affix-wrapper {
        background-color: ${({ theme }) => theme.colors.underline};
      }
    `}
`

export const InputNumber = styled(BaseInputNumber)<InputNumberProps & { $isPrefix?: boolean }>`
  width: 100%;
  line-height: unset;
  .ant-input-number-disabled {
    background-color: ${({ theme }) => theme.colors.underline};
  }
  .ant-input-number-input {
    text-align: ${({ align }) => align || 'left'};
    font-weight: ${({ theme }) => theme.fontWeight.l};
  }
  &&&.ant-input-number-affix-wrapper {
    padding: 0 ${({ theme }) => theme.spacers[3]};

    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.colors.accent[400]};
      box-shadow: ${({ theme }) => theme.boxShadow.input};
    }
  }
  &&&& .ant-input-number-suffix,
  &&&& .ant-input-number-prefix {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.fontWeight.l};
    pointer-events: all;
    line-height: normal;
  }

  ${({ $isPrefix }) =>
    !$isPrefix &&
    css`
      &&&&& .ant-input-number-prefix {
        margin-inline-end: 0;
      }
    `}
`

export const Suffix = styled.div`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.spacers[3]};
  transform: translateY(-50%);
  z-index: ${({ theme }) => theme.zIndexLevel[1]};
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.xs};
`
