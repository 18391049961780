import { FC, useCallback } from 'react'

import { useTableContext } from 'hooks'
import { getNotNullFilter } from 'utils'

import { SelectSingleOption, Switch } from 'designSystem'

interface TableFavoriteProps {
  name: string
}

export const TableFavorite: FC<TableFavoriteProps> = ({ name }) => {
  const { updateFilters, getFilterValue } = useTableContext()
  const value = getFilterValue?.(name) as SelectSingleOption | undefined

  const handleFilterChange = useCallback(
    (checked: boolean) => {
      updateFilters?.(getNotNullFilter(name, { value: checked ? `${checked}` : undefined }))
    },
    [updateFilters, name],
  )

  return <Switch value={value?.value === 'true'} onChange={handleFilterChange} text="Show Only Favorites" />
}
