export const itemsRoot = '/items'

export const itemsRoute = {
  list: '/',
  create: '/create',
  view: '/:id',
  details: '/details',
  integrations: '/integrations',
  history: '/history',
}
