import { FC } from 'react'

import theme from 'styles/theme'

import { Container } from '../../../Container'
import { Icon } from '../../../Icon'

export const SelectSpinner: FC = () => (
  <Container jc="center">
    <Icon icon="load" spin color={theme.colors.secondary} size={14} />
  </Container>
)
