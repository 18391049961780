import { FC } from 'react'

import { ReferenceNumber, ReferenceNumberType } from 'api'
import { QueryKey } from 'enums'
import { useReferencesTable } from 'hooks'
import { getEqualsFilter } from 'utils'

import { Container } from 'designSystem'
import { Table, TableSearchSingle, TableTitle } from 'components'

import { getTableColumns } from './utils/getTableColumns'

interface DepotBookingsProps {
  vendorDepotId?: number
}

export const DepotBookings: FC<DepotBookingsProps> = ({ vendorDepotId }) => {
  const { dataSource, loading, pagination, onChange, total, getFieldSorter, offsetCount, TableContextProvider } =
    useReferencesTable({
      queryKey: QueryKey.GetDepotBookings,
      enabled: !!vendorDepotId,
      params: {
        join: [],
        fields: 'id,number,bookingType,transactionType,createdAt,expirationDate,status,depotSettingId,type',
      },
      defaultFilters: {
        ...getEqualsFilter('depotSettingId', { value: vendorDepotId }),
        ...getEqualsFilter('type', { value: ReferenceNumberType.Booking }),
      },
    })
  const columns = getTableColumns(getFieldSorter, offsetCount)

  return (
    <Container pa={4} fd="column" gap={16}>
      <TableContextProvider>
        <TableTitle
          withoutDefaultPadding
          title="Depot Bookings"
          total={total}
          search={<TableSearchSingle searchFieldName="number" />}
        />
        <Table<ReferenceNumber>
          dataSource={dataSource}
          loading={loading}
          columns={columns}
          pagination={pagination}
          onChange={onChange}
          scroll={{ x: 900 }}
        />
      </TableContextProvider>
    </Container>
  )
}
