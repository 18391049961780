import { forwardRef } from 'react'

import { typedMemo } from 'types'

import type { DatePickerProps, DatePickerRef } from 'designSystem'

import * as Styled from './styles'

export type EditDatePickerProps = DatePickerProps

export const EditDatePickerBase = forwardRef<DatePickerRef, EditDatePickerProps>(
  ({ size = 'middle', align = 'right', ...props }, ref) => {
    return <Styled.EditDatePicker size={size} align={align} {...props} ref={ref} />
  },
)

export const EditDatePicker = typedMemo(EditDatePickerBase)
