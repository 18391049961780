import type { AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import axios from 'axios'

import { getErrorMessageFromErrorCode } from 'utils'

import { showNotification } from 'designSystem'

import {
  getAccessToken,
  getRefreshToken,
  RefreshTokenPayload,
  RefreshTokenResponse,
  setAccessToken,
  setRefreshToken,
} from './utils'
import { apiConfig, ApiError } from './constants'

let isRefreshTokenInProgress = false

export const refreshTokenRequest = (payload: RefreshTokenPayload): Promise<RefreshTokenResponse> => {
  return api({
    method: 'post',
    url: '/api/v1/auth/refresh-token',
    data: payload,
  })
}

export const interceptorsRequest = async (config: InternalAxiosRequestConfig) => {
  const accessToken = getAccessToken()

  config.headers = {
    ...config.headers,
    ...(config.headers?.withAuth && { Authorization: `Bearer ${accessToken}` }),
  }

  return config
}

export const interceptorsRequestError = (error: ApiError) => {
  Promise.reject(error)
}

export const interceptorsResponseError = (apiInstance: AxiosInstance) =>
  async function (error: any) {
    const originalRequest = error.config

    if (originalRequest.headers.Authorization && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      if (!isRefreshTokenInProgress) {
        isRefreshTokenInProgress = true

        const refreshToken = getRefreshToken()

        if (refreshToken) {
          try {
            const response = await refreshTokenRequest({ refreshToken })
            setAccessToken(response.accessToken)
            setRefreshToken(response.refreshToken)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.accessToken.token
            return apiInstance(originalRequest)
          } catch (refreshError) {
            return Promise.reject(error)
          } finally {
            isRefreshTokenInProgress = false
          }
        }
      }
    }

    const responseCode = error.response?.data.code
    const responseMessage = error.response?.data.message
    const message = getErrorMessageFromErrorCode(responseCode)

    // TODO: we should ask BE about correct code for this error
    if (error.response.data.statusCode === 401 && responseMessage === 'Unauthorized') {
      showNotification({ title: 'Email or password is invalid', type: 'error' })
    } else {
      showNotification({ title: message || responseMessage, type: 'error' })
    }

    return Promise.reject(error)
  }

export const api = axios.create(apiConfig)
api.interceptors.request.use(interceptorsRequest, interceptorsRequestError)
api.interceptors.response.use((response) => {
  return response.data
}, interceptorsResponseError(api))

export const apiInstance = axios.create(apiConfig)
apiInstance.interceptors.request.use(interceptorsRequest, interceptorsRequestError)
apiInstance.interceptors.response.use((response) => {
  return response
}, interceptorsResponseError(apiInstance))
