import { AssetCodeHeight } from 'api/types/assetCode/assetCodeHeight'

import { distanceUnits } from '../../distanceUnits'

export const assetCodeHeightOptions: { value: AssetCodeHeight; label: string }[] = [
  {
    value: AssetCodeHeight.S,
    label: `${AssetCodeHeight.S}${distanceUnits.Ft}`,
  },
  {
    value: AssetCodeHeight.M,
    label: `${AssetCodeHeight.M}${distanceUnits.Ft}`,
  },
]
