import { FC } from 'react'

import { useTruckCodes } from 'hooks'
import { currency, distanceUnits } from 'constant'

import { Container, FormItem, InputNumber, Typography } from 'designSystem'
import { FormItemSelectSingleAsync } from 'components'

export const OptionTwo: FC = () => {
  return (
    <Container gap={8} ai="center">
      <FormItemSelectSingleAsync
        name="option.truckCodeId"
        getItems={useTruckCodes}
        width={120}
        placeholder="Truck Code"
      />
      <Typography size="s" color="secondary">
        Flat Fee
      </Typography>
      <FormItem name="option.flatFeePrice">
        <InputNumber placeholder="Price" precision={2} prefix={currency} width={80} />
      </FormItem>
      <FormItem name="option.minimumMiles">
        <InputNumber placeholder="Minimum miles" suffix={distanceUnits.Mi} suffixWidth={40} width={160} />
      </FormItem>
      <Typography size="s" color="secondary">
        and
      </Typography>
      <FormItem name="option.perMilePrice">
        <InputNumber placeholder="Price per Mile After" precision={2} prefix={currency} width={120} />
      </FormItem>
    </Container>
  )
}
