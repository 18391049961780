export const getMutationPatchPayload = <T extends Record<string, any>>(payload: T) => {
  const updateKeys = Object.keys(payload)
  const updateValues = Object.values(payload)
  const data: Record<string, any> = {}

  updateKeys.forEach((key, index) => {
    const value = updateValues[index]
    data[key] = value === undefined ? null : value
  })
  return data
}
