import { Navigate } from 'react-router-dom'

import { vendorCarrierRoute } from 'constant'

import { LinkTabs, NotFoundPage } from 'components'

import { carrierTabs } from './carrierTabs'

export const carrierRoutes = [
  {
    path: '/',
    element: <Navigate to={vendorCarrierRoute.details.replace('/', '')} replace />,
  },
  {
    path: vendorCarrierRoute.details,
    element: <LinkTabs items={carrierTabs} />,
  },
  {
    path: vendorCarrierRoute.priceList,
    element: <LinkTabs items={carrierTabs} />,
  },
  {
    path: vendorCarrierRoute.equipment,
    element: <LinkTabs items={carrierTabs} />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
