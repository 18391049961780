import { FC, useCallback } from 'react'

import { ItemCategory } from 'api'

import { Button, Typography } from 'designSystem'
import { ConfirmationPopover } from 'components'

import { useDeleteCategoryItem } from '../../hooks/useDeleteCategoryItem'

interface DeleteCategoryProps {
  item: ItemCategory
}

export const DeleteCategory: FC<DeleteCategoryProps> = ({ item }) => {
  const { deleteItem, isPending: isDeleteLoading } = useDeleteCategoryItem()

  const handleRemoveItem = useCallback(() => {
    deleteItem(item.id)
  }, [deleteItem, item.id])
  return (
    <div>
      <ConfirmationPopover
        text={
          <>
            <Typography fontWeight="m" size="xs" lineEllipsis={2}>
              Are you sure you want to delete <b>{item.name}</b>?
            </Typography>
          </>
        }
        onOk={handleRemoveItem}
        isLoading={isDeleteLoading}
      >
        <Button icon="delete" danger size="small" />
      </ConfirmationPopover>
    </div>
  )
}
