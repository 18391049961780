import { VendorType, VendorTypeNames } from 'api/types/vendor/vendorType'

export const vendorTypeOptions = [
  {
    label: VendorTypeNames[VendorType.ContainerVendor],
    value: VendorType.ContainerVendor,
  },
  {
    label: VendorTypeNames[VendorType.Carrier],
    value: VendorType.Carrier,
  },
  {
    label: VendorTypeNames[VendorType.Depot],
    value: VendorType.Depot,
  },
  {
    label: VendorTypeNames[VendorType.Modifications],
    value: VendorType.Modifications,
  },
  {
    label: VendorTypeNames[VendorType.Parts],
    value: VendorType.Parts,
  },
]

export const getVendorTypeOptionLabel = (type: VendorType): string =>
  vendorTypeOptions.find(({ value }) => value === type)?.label || ''
