import { FC, Key, useCallback, useEffect, useState } from 'react'

import { useFieldArray, useFormContext, useTable } from 'hooks'
import { SalesOrderFormAccessor } from 'types'

import { Button, Container, Typography } from 'designSystem'
import { Table } from 'components'

import { getTableColumns } from './utils/getTableColumns'
import { LineItemModifications, LineItemSku, SalesOrderLineItemRow } from './types'
import { LineItemsTotal } from './elements/LineItemsTotal'
import { SalesOrderSubLines } from './elements/SalesOrderSubLines'

export const SalesOrderLineItems: FC = () => {
  const { TableContextProvider } = useTable({})
  const { watch } = useFormContext()
  const [lineItemModifications, setLineItemModifications] = useState<LineItemModifications>({})
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])
  const { fields, append, remove } = useFieldArray({ name: SalesOrderFormAccessor.LineItems })
  const dataSource = fields?.map((field) => ({ ...field, key: field.id })) as SalesOrderLineItemRow[]

  const [lineItemSku, setLineItemSku] = useState<LineItemSku>({})

  const itemsCount = dataSource.length

  const handleRemove = useCallback(
    (index: number) => {
      remove(index)
    },
    [remove],
  )

  const handleAddModification = useCallback((index: string) => {
    setLineItemModifications((prev) => ({ ...prev, [index]: true }))
    setExpandedRowKeys((prev) => [...prev, index])
  }, [])

  const columns = getTableColumns({
    lineItemSku,
    setLineItemSku,
    itemsCount,
    onDelete: handleRemove,
    watch,
    handleAddModification,
    lineItemModifications,
  })

  const insertRow = useCallback(() => {
    append({}, { shouldFocus: false })
  }, [append])

  useEffect(() => {
    insertRow()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Container mb={4}>
        <Typography fontWeight="xl" size="l">
          Line Items
        </Typography>
      </Container>
      <TableContextProvider>
        <Table<SalesOrderLineItemRow>
          scroll={{ x: 1350 }}
          dataSource={dataSource}
          columns={columns}
          expandable={{
            expandedRowKeys,
            expandedRowRender: (record: SalesOrderLineItemRow, index: number) => (
              <SalesOrderSubLines
                recordId={record.id}
                index={index}
                lineItemSku={lineItemSku}
                setLineItemSku={setLineItemSku}
                hasSubLines={lineItemModifications[record.id]}
                setLineItemModifications={setLineItemModifications}
              />
            ),
            rowExpandable: (record: SalesOrderLineItemRow) => {
              return !!record.id && !!lineItemModifications[record.id]
            },
            onExpandedRowsChange: (expandedRows: readonly Key[]) => {
              setExpandedRowKeys([...(expandedRows as string[])])
            },
          }}
        />
        <Container ai="center" jc="space-between" mt={4}>
          <Button icon="plus" type="link" onClick={insertRow}>
            Add
          </Button>
          <LineItemsTotal />
        </Container>
      </TableContextProvider>
    </>
  )
}
