import { AssetHolder } from 'api/types/asset/assetHolder'

export const assetHolderOptions = [
  {
    label: 'Organization',
    value: AssetHolder.Organization,
  },
  {
    label: 'Vendor account',
    value: AssetHolder.VendorAccount,
  },
]

export const getAssetHolderLabel = (assetHolder: AssetHolder): string | undefined =>
  assetHolderOptions.find(({ value }) => value === assetHolder)?.label
