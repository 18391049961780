import { FC, useCallback } from 'react'
import { Col, Row } from 'antd'

import { ReferenceNumber, ReferenceNumberType } from 'api'
import { useDepotCodes, useFormContext, useLocationCodes, useWatch } from 'hooks'
import { referenceLabels } from 'constant'
import { ReferenceFormAccessor } from 'types'

import { SelectSingleOption } from 'designSystem'
import { FormItemSelectSingleAsync } from 'components'

interface LocationDepotFieldsProps {
  defaultData?: ReferenceNumber
  type: ReferenceNumberType
  depotSettingSelectedOption?: SelectSingleOption
  setDepotSettingSelectedOption?: (option?: SelectSingleOption) => void
}

export const LocationDepotFields: FC<LocationDepotFieldsProps> = ({
  type,
  defaultData,
  depotSettingSelectedOption,
  setDepotSettingSelectedOption,
}) => {
  const { setValue } = useFormContext()

  const locationCodeId = useWatch({ name: ReferenceFormAccessor.LocationCodeId })
  const depotSettingId = useWatch({ name: ReferenceFormAccessor.DepotSettingId })

  const handleLocationCodeChange = useCallback(() => {
    setValue(ReferenceFormAccessor.DepotSettingId, null)

    if (setDepotSettingSelectedOption) {
      setDepotSettingSelectedOption(undefined)
      setValue(ReferenceFormAccessor.VendorReleaseId, null)
    }
  }, [setValue, setDepotSettingSelectedOption])

  const handleDepotSettingChange = useCallback(
    (value?: string | number | null, option?: SelectSingleOption) => {
      if (setDepotSettingSelectedOption) {
        setValue(ReferenceFormAccessor.VendorReleaseId, null)
        setDepotSettingSelectedOption?.(option)
      }
    },
    [setValue, setDepotSettingSelectedOption],
  )
  const isBookingType = type === ReferenceNumberType.Booking
  return (
    <Row gutter={8}>
      <Col span={12}>
        <FormItemSelectSingleAsync
          name={ReferenceFormAccessor.LocationCodeId}
          label={isBookingType ? referenceLabels.gateOutLocationId : referenceLabels.locationCodeId}
          getItems={useLocationCodes}
          onChange={handleLocationCodeChange}
          selectedOption={
            locationCodeId && defaultData?.locationCode && locationCodeId === defaultData.locationCode.id
              ? { value: defaultData.locationCode.id, label: defaultData.locationCode.code }
              : undefined
          }
        />
      </Col>
      <Col span={12}>
        <FormItemSelectSingleAsync
          name={ReferenceFormAccessor.DepotSettingId}
          label={isBookingType ? referenceLabels.gateOutDepotId : referenceLabels.depotSettingId}
          getItems={useDepotCodes}
          disabled={!locationCodeId}
          placeholder={locationCodeId ? undefined : 'Select Location first'}
          onChange={handleDepotSettingChange}
          queryParams={{
            ...(locationCodeId && { [ReferenceFormAccessor.LocationCodeId]: { $eq: locationCodeId } }),
          }}
          selectedOption={
            depotSettingSelectedOption ||
            (depotSettingId && defaultData?.depotSetting && depotSettingId === defaultData.depotSetting?.id
              ? { value: defaultData.depotSetting.id, label: defaultData.depotSetting.code }
              : undefined)
          }
        />
      </Col>
    </Row>
  )
}
