import { FC } from 'react'

import { ActivityStreamline, ActivityStreamlineAction } from 'api'

import { Container, Typography } from 'designSystem'

import { formatUpdatedValue } from './utils/formatUpdatedValue'
import { getEntityData } from './utils/getEntityData'

interface ActivityStreamLineRecordProps {
  data: ActivityStreamline
}

export const ActivityStreamLineRecord: FC<ActivityStreamLineRecordProps> = ({ data: { type, action, data } }) => {
  const actionText = {
    [ActivityStreamlineAction.Create]: 'created',
    [ActivityStreamlineAction.Update]: 'changed',
    [ActivityStreamlineAction.Delete]: 'deleted',
    [ActivityStreamlineAction.CallExternalService]: 'unknown action',
  }[action]

  const { title, subTitle, labels } = getEntityData(type, data) || {}

  if (!labels) {
    return action
  }

  if (action === ActivityStreamlineAction.Create || action === ActivityStreamlineAction.Delete) {
    return (
      <Typography size="xs" fontWeight="xl">
        {!!title && `${title} `}
        {!!subTitle && `${subTitle} `}
        <Typography size="xs">{actionText}</Typography>
      </Typography>
    )
  }

  if (action === ActivityStreamlineAction.CallExternalService) {
    return <Typography size="xs">{actionText}</Typography>
  }

  return (
    <Container gap={4} fd="column">
      {data.input.updatedColumns?.map((column: any) => {
        const label = labels?.[column.propertyName as keyof typeof labels]
          ? labels[column.propertyName as keyof typeof labels]
          : column.propertyName

        return (
          <Typography size="xs" key={column.propertyName}>
            <Typography size="xs" fontWeight="xl">
              {label}
            </Typography>
            {` ${actionText} from `}
            <Typography size="xs" fontWeight="xl">
              {JSON.stringify(column.previousLabel || formatUpdatedValue(column.previousValue))}
            </Typography>
            {' to '}
            <Typography size="xs" fontWeight="xl">
              {JSON.stringify(column.updatedLabel || formatUpdatedValue(column.updatedValue))}
            </Typography>
          </Typography>
        )
      })}
    </Container>
  )
}
