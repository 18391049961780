import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { carrierRoutes } from './constants/carrierRoutes'

export const Carrier: FC = () => {
  return (
    <Routes>
      {carrierRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
    </Routes>
  )
}
