import { css, styled } from 'styled-components'

import { getStylesByColor } from './utils/getStylesByColor'
import { getStylesBySize } from './utils/getStylesBySize'

import type { TypographyProps } from './Typography'

export const Typography = styled.div<TypographyProps>`
  display: ${({ display = 'inline-block' }) => display};
  font-weight: ${({ fontWeight = 'm', theme }) => theme.fontWeight[fontWeight]};
  color: ${({ color = 'primary' }) => getStylesByColor(color)};
  word-break: ${({ wordBreak }) => wordBreak && wordBreak};
  ${({ size = 's' }) => {
    const { fontSize, lineHeight } = getStylesBySize(size)
    return `
        font-size: ${fontSize}; 
        line-height: ${lineHeight};
      `
  }};

  ${({ lineEllipsis }) =>
    lineEllipsis &&
    css`
      -webkit-line-clamp: ${lineEllipsis};
      -webkit-box-orient: vertical;
      display: block;
      min-width: 0;
      text-overflow: ellipsis;
      overflow: hidden;
    `}

  ${({ nowrap }) => nowrap && 'white-space: nowrap;'}
`
