import { getAccessorTitle } from '../utils/getAccessorTitle'
import { Accessor } from '../types/accessor'

export const referencesRequiredColumns = [Accessor.Index, Accessor.Number, Accessor.Type]

export const referencesDefaultColumns = [
  Accessor.Index,
  Accessor.Number,
  Accessor.Type,
  Accessor.CreatedAt,
  Accessor.RelatedReference,
  Accessor.Items,
  Accessor.Assets,
  Accessor.Status,
]

export const referencesVisibleColumnsOptions = Object.values(Accessor).map((accessor) => ({
  value: accessor,
  label: getAccessorTitle(accessor),
  disabled: referencesRequiredColumns.includes(accessor),
}))
