import { FC, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useForm, useGetSearchParams } from 'hooks'
import { route } from 'constant'

import { Button, FormItem, Input, Spacer, Typography } from 'designSystem'
import { PasswordRequirements, UnauthorizedLayout } from 'components'

import { useSubmitResetPassword } from './hooks/useSubmitResetPassword'
import { validationSchema } from './utils/validationSchema'
import { ResetPasswordData, ResetPasswordForm } from './types/ResetPasswordForm'
import * as Styled from './styles'

export const ResetPassword: FC = () => {
  const navigate = useNavigate()
  const { token } = useGetSearchParams()

  const { resetPassword, isPending } = useSubmitResetPassword()
  const { Form, handleSubmit, getValues, trigger } = useForm<ResetPasswordForm>({
    validationSchema,
  })

  const onSubmit = useCallback(
    (resetPasswordData: ResetPasswordData) => {
      resetPassword({
        data: {
          password: resetPasswordData.password,
        },
        params: {
          token: token as string,
        },
      })
    },
    [resetPassword, token],
  )

  useEffect(() => {
    if (!token) {
      navigate(route.login)
    }
  }, [token, navigate])

  const confirmPassword = getValues('confirmPassword')

  const handleBlurNewPassword = useCallback(() => {
    if (confirmPassword) {
      trigger('confirmPassword')
    }
  }, [confirmPassword, trigger])

  return (
    <UnauthorizedLayout>
      <Typography display="block" as="h1" fontWeight="xl" size="xl">
        Reset password
      </Typography>
      <Spacer mb={3} />
      <Styled.Description>
        <Typography display="block" as="h2" size="xs" fontWeight="l" color="secondary">
          To reset your password enter new password and confirm it
        </Typography>
      </Styled.Description>
      <Spacer mb={4} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormItem name="password" label="New password">
          <Input placeholder="New password" hideChars onBlur={handleBlurNewPassword} />
        </FormItem>
        <Spacer mb={6} />
        <FormItem name="confirmPassword" label="Confirm password">
          <Input placeholder="Confirm password" hideChars />
        </FormItem>
        <Spacer mb={4} />
        <PasswordRequirements />
        <Spacer mb={7} />
        <Button type="primary" htmlType="submit" block loading={isPending}>
          Apply
        </Button>
      </Form>
    </UnauthorizedLayout>
  )
}
