import { FC, useEffect } from 'react'
import { Col, Row } from 'antd'

import {
  ReferenceNumber,
  ReferenceNumberBooking,
  ReferenceNumberBookingTransactionType,
  ReferenceNumberBookingType,
  ReferenceNumberType,
} from 'api'
import { useFormContext, useSalesOrders, useWatch } from 'hooks'
import {
  referenceLabels,
  referenceNumberBookingTransactionTypeOptions,
  referenceNumberBookingTypeOptions,
} from 'constant'
import { ReferenceFormAccessor } from 'types'

import { FormItem, SelectSingle, SelectSingleOption } from 'designSystem'
import { FormItemSelectSingleAsync } from 'components'

import { GateInLocationDepotFields } from '../../../GateInLocationDepotFields'
import { LocationDepotFields } from '../../../LocationDepotFields'

interface BookingNumberMainDetailsProps {
  defaultData?: ReferenceNumberBooking
  depotSettingSelectedOption?: SelectSingleOption
  setDepotSettingSelectedOption: (option?: SelectSingleOption) => void
}

export const BookingNumberMainDetails: FC<BookingNumberMainDetailsProps> = ({
  defaultData,
  depotSettingSelectedOption,
  setDepotSettingSelectedOption,
}) => {
  const { unregister } = useFormContext()

  const bookingType = useWatch({ name: ReferenceFormAccessor.BookingType })
  const transactionType = useWatch({ name: ReferenceFormAccessor.TransactionType })
  useEffect(() => {
    if (
      bookingType !== ReferenceNumberBookingType.Release ||
      transactionType !== ReferenceNumberBookingTransactionType.Transfer
    ) {
      unregister([ReferenceFormAccessor.GateInLocationCodeId, ReferenceFormAccessor.GateInDepotSettingId])
    }
  }, [bookingType, transactionType, unregister])

  const showGateInLocationAndDepot =
    bookingType === ReferenceNumberBookingType.Release &&
    transactionType === ReferenceNumberBookingTransactionType.Transfer

  return (
    <>
      <Row>
        <Col span={24}>
          <FormItem name={ReferenceFormAccessor.BookingType} label={referenceLabels.bookingType}>
            <SelectSingle options={referenceNumberBookingTypeOptions} />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem name={ReferenceFormAccessor.TransactionType} label={referenceLabels.transactionType}>
            <SelectSingle options={referenceNumberBookingTransactionTypeOptions} />
          </FormItem>
        </Col>
      </Row>
      <LocationDepotFields
        defaultData={defaultData as ReferenceNumber}
        depotSettingSelectedOption={depotSettingSelectedOption}
        setDepotSettingSelectedOption={setDepotSettingSelectedOption}
        type={ReferenceNumberType.Booking}
      />
      {showGateInLocationAndDepot && <GateInLocationDepotFields />}
      <Row>
        <Col span={24}>
          <FormItemSelectSingleAsync
            name={ReferenceFormAccessor.SalesOrderId}
            label={referenceLabels.salesOrderId}
            getItems={useSalesOrders}
          />
        </Col>
      </Row>
    </>
  )
}
