export enum ReferenceNumberAssetStatus {
  Open = 'open',
  OpenPendingModification = 'open_pending_modification',
  OpenModificationDone = 'open_modification_done',
  Pending = 'pending',
  Closed = 'closed',
  Voided = 'voided',
  PendingRedelivery = 'pending_redelivery',
  Received = 'received',
  Rejected = 'rejected',
}
