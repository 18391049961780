import { FC, useEffect } from 'react'

import { useFormContext } from 'hooks'

export const withResetFormValues = (Component: FC<any>) => () => {
  const { reset, getValues, setValue } = useFormContext()

  useEffect(() => {
    const values = getValues('option')
    Object.keys(values).forEach((key) => {
      if (key !== 'type') {
        setValue(`option.${key}`, null)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])

  return <Component />
}
