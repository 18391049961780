import { css, styled } from 'styled-components'

import { InputNumber as BaseInputNumber } from '../../InputNumber'
import type { EditInputNumberProps } from './EditInputNumber'

export const InputNumber = styled(BaseInputNumber)<EditInputNumberProps>`
  width: 100%;
  .ant-input-number-input {
    text-align: ${({ align }) => align || 'left'};
    font-size: ${({ theme }) => theme.fontSize.s};
    height: 26px;
  }

  ${({ size }) => {
    if (size === 'small') {
      return css`
        &&& .ant-input-number-input {
          font-size: ${({ theme }) => theme.fontSize.xs};
          height: 18px;
          line-height: 14px;
        }
        &&&.ant-input-number-affix-wrapper {
          height: 20px;
        }
      `
    } else if (size === 'large') {
      return css`
        &&& .ant-input-number-input {
          font-size: ${({ theme }) => theme.fontSize.l};
          height: 30px;
        }
      `
    }
  }}
`
