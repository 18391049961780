import { Dispatch, FC, SetStateAction, useCallback } from 'react'

import { useFormContext, useGetCustomerContacts, useWatch } from 'hooks'
import { SalesOrderFormAccessor } from 'types'

import { AddNewContactActionButton, NewContact } from 'components'

import { maxContactsCount } from 'modules/purchaseOrders/constants/maxRecordsCount'

interface SalesOrderAddContactProps {
  contacts: NewContact[]
  setContacts: Dispatch<SetStateAction<NewContact[]>>
  buttonText?: string
  contactType: SalesOrderFormAccessor.ContactsIds | SalesOrderFormAccessor.OnSiteContactsIds
}

export const SalesOrderAddContact: FC<SalesOrderAddContactProps> = ({
  contacts,
  setContacts,
  buttonText,
  contactType,
}) => {
  const { setValue, getValues } = useFormContext()

  const customerId = useWatch({ name: SalesOrderFormAccessor.CustomerId })
  const { data: customerContacts = [], isLoading } = useGetCustomerContacts(customerId)

  const handleAddNewContact = useCallback(
    (contact: NewContact) => {
      setContacts((prev) => [...prev, contact])
      setValue(contactType, [...(getValues(contactType) || []), contact.email])
    },
    [setContacts, setValue, contactType, getValues],
  )

  const contactsWithNew = [...contacts, ...customerContacts]

  const showAdd = !isLoading && customerId && contactsWithNew.length < maxContactsCount

  return showAdd ? (
    <AddNewContactActionButton contacts={contacts} onSubmit={handleAddNewContact} buttonText={buttonText} />
  ) : null
}
