import { api, User } from 'api'

import { PasswordPayload } from './types/passwordPayload'

export interface ChangePasswordPayload {
  password: PasswordPayload
}

export const patchPassword = (data: ChangePasswordPayload): Promise<User> => {
  return api({
    method: 'patch',
    url: '/api/v1/users/me',
    data,
  })
}
