import { FC } from 'react'

import { SalesOrderStatus } from 'api'

import { Tag, TagProps } from 'designSystem'

import { getTagDataBySalesOrderStatus } from './utils/getTagDataBySalesOrderStatus'

interface SalesOrderStatusTagProps extends Omit<TagProps, 'status'> {
  status: SalesOrderStatus
}

export const SalesOrderStatusTag: FC<SalesOrderStatusTagProps> = ({ status, ...props }) => {
  const { status: tagStatus, text } = getTagDataBySalesOrderStatus(status)
  return <Tag {...props} status={tagStatus} text={text} />
}
