import { PurchaseOrderVendorReleaseStatus } from 'api/types/purchaseOrder'

export const purchaseOrderVendorReleaseStatusOptions: {
  value: PurchaseOrderVendorReleaseStatus
  label: string
}[] = [
  {
    value: PurchaseOrderVendorReleaseStatus.Open,
    label: 'Open',
  },
  {
    value: PurchaseOrderVendorReleaseStatus.Close,
    label: 'Close',
  },
  {
    value: PurchaseOrderVendorReleaseStatus.Voided,
    label: 'Voided',
  },
]
