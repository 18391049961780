import { ScrappingSettings, VendorFeedSettings } from 'api'

export const getScrappingSettingsPayload = (scrappingSettings?: ScrappingSettings): VendorFeedSettings | undefined => {
  if (!scrappingSettings) {
    return undefined
  }

  if (scrappingSettings?.feedUrl === '') {
    return {
      scrappingSettings: {
        ...scrappingSettings,
        feedUrl: null,
      },
    }
  }
  return { scrappingSettings }
}
