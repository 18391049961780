import { ItemCategory, ItemCategoryType } from 'api'

const insertCategory = (categories: ItemCategory[], currentCategory: ItemCategory, overCategory?: ItemCategory) => {
  return categories.reduce((acc, curr, index) => {
    if (curr.position === overCategory?.position) {
      if (overCategory.position === index + 1) {
        return acc.concat(currentCategory, curr)
      } else {
        return acc.concat(curr, currentCategory)
      }
    } else {
      return acc.concat(curr)
    }
  }, [] as ItemCategory[])
}

export const insertItemToCategories = (
  categories: ItemCategory[],
  currentCategory: ItemCategory,
  overCategory?: ItemCategory,
): ItemCategory[] => {
  if (overCategory?.type === ItemCategoryType.Folder) {
    return categories.map((category) => {
      if (category.id === overCategory.id) {
        const updatedChildren = category.children.map((item, index) => ({
          ...item,
          position: index + 1,
        }))
        const updatedCurrentCategory = {
          ...currentCategory,
          position: updatedChildren.length + 1,
          parentId: overCategory.id,
        }
        return {
          ...category,
          children: [...updatedChildren, updatedCurrentCategory],
        }
      } else {
        return category
      }
    })
  }
  if (overCategory?.parentId) {
    return categories.map((category) => {
      if (category.id === overCategory.parentId) {
        const updatedChildren = insertCategory(category.children, currentCategory, overCategory)

        const childrenPosition = updatedChildren?.map((item, index) => ({
          ...item,
          position: index + 1,
          parentId: overCategory.parentId,
        }))

        return {
          ...category,
          children: childrenPosition,
        }
      } else {
        return category
      }
    })
  }
  if (!overCategory) {
    return [
      ...categories.map((item, index) => ({
        ...item,
        position: index + 1,
      })),
      { ...currentCategory, position: categories.length + 1, parentId: null },
    ]
  }

  const updatedCategories = insertCategory(categories, currentCategory, overCategory)

  const updatedCategoriesPosition = updatedCategories.map((item, index) => ({
    ...item,
    position: index + 1,
    parentId: overCategory?.parentId || null,
  }))

  return updatedCategoriesPosition
}
