import { route } from 'constant'

export const breadcrumbsData = [
  {
    title: `Po's`,
    url: route.pos,
  },
  {
    title: 'New PO',
  },
]
