import { AssetStatus } from 'api/types/asset/assetStatus'

export const assetStatusOptionLabel = {
  [AssetStatus.Create]: 'Created',
  [AssetStatus.Available]: 'Available',
  [AssetStatus.NotAvailable]: 'Not available',
  [AssetStatus.Split]: 'Split',
  [AssetStatus.Recycle]: 'Recycle',
  [AssetStatus.Booked]: 'Booked',
  [AssetStatus.Sold]: 'Sold',
  [AssetStatus.Rented]: 'Rented',
  [AssetStatus.PendingDelete]: 'Pending delete',
  [AssetStatus.VendorAccount]: 'Vendor account',
  [AssetStatus.PendingVendorRelease]: 'Pending vendor release',
  [AssetStatus.PendingRedelivery]: 'Pending redelivery',
}

export const assetStatusOptions = [
  {
    label: assetStatusOptionLabel[AssetStatus.Create],
    value: AssetStatus.Create,
  },
  {
    label: assetStatusOptionLabel[AssetStatus.Available],
    value: AssetStatus.Available,
  },
  {
    label: assetStatusOptionLabel[AssetStatus.NotAvailable],
    value: AssetStatus.NotAvailable,
  },
  {
    label: assetStatusOptionLabel[AssetStatus.Split],
    value: AssetStatus.Split,
  },
  {
    label: assetStatusOptionLabel[AssetStatus.Recycle],
    value: AssetStatus.Recycle,
  },
  {
    label: assetStatusOptionLabel[AssetStatus.Booked],
    value: AssetStatus.Booked,
  },
  {
    label: assetStatusOptionLabel[AssetStatus.Sold],
    value: AssetStatus.Sold,
  },
  {
    label: assetStatusOptionLabel[AssetStatus.Rented],
    value: AssetStatus.Rented,
  },
  {
    label: assetStatusOptionLabel[AssetStatus.PendingDelete],
    value: AssetStatus.PendingDelete,
  },
  {
    label: assetStatusOptionLabel[AssetStatus.VendorAccount],
    value: AssetStatus.VendorAccount,
  },
  {
    label: assetStatusOptionLabel[AssetStatus.PendingVendorRelease],
    value: AssetStatus.PendingVendorRelease,
  },
  {
    label: assetStatusOptionLabel[AssetStatus.PendingRedelivery],
    value: AssetStatus.PendingRedelivery,
  },
]
