import { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { Button, Typography } from 'designSystem'
import { ConfirmationPopover } from 'components'

import { useRemoveCustomer } from './hooks/useRemoveCustomer'

export const ActionButtons: FC = () => {
  const { id } = useParams()
  const { remove, isPending } = useRemoveCustomer(Number(id))

  const handleRemove = useCallback(() => {
    remove()
  }, [remove])

  return (
    <ConfirmationPopover
      text={<Typography size="xs">Are you sure you want to delete this Customer?</Typography>}
      onOk={handleRemove}
      isLoading={isPending}
    >
      <Button icon="delete" danger size="small" />
    </ConfirmationPopover>
  )
}
