import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { EntityId } from 'api'
import { QueryKey } from 'enums'
import { route } from 'constant'

import { getUserById } from '../queryApi'

export const useGetUserById = (userId?: EntityId) => {
  const navigate = useNavigate()

  const { data, isError, ...rest } = useQuery({
    queryKey: [QueryKey.GetUserById, userId],
    queryFn: () => getUserById(userId),
    enabled: !!userId,
  })

  useEffect(() => {
    if (isError) {
      navigate(route.userManagement)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  return {
    data,
    isError,
    ...rest,
  }
}
