import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { EntityId } from 'api'
import { useDispatchAppEvent } from 'hooks'
import { route } from 'constant'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { deleteCustomer } from '../queryApi'

export const useRemoveCustomer = (id: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const navigate = useNavigate()
  const { mutate, ...rest } = useMutation({
    mutationFn: deleteCustomer,
    onSuccess: () => {
      dispatchAppEvent(AppEvent.CustomerDeleted)

      showNotification({ title: 'Customer deleted', type: 'success' })
      navigate(route.customers)
    },
  })

  const remove = useCallback(() => {
    mutate(id)
  }, [mutate, id])

  return {
    remove,
    ...rest,
  }
}
