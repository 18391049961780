import { styled } from 'styled-components'

export const Content = styled.div`
  max-width: 252px;
  padding: 16px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
`
