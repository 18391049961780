import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { Spinner, ZohoIntegration } from 'components'

import { useGetAssetById } from '../AssetDetails/hooks/useGetAssetById'

export const AssetIntegrations: FC = () => {
  const { id } = useParams()

  const { data, isLoading } = useGetAssetById(Number(id))

  if (isLoading) {
    return <Spinner />
  }

  return <ZohoIntegration module="zohoAssetsLink" id={data?.zohoId} />
}
