import * as Yup from 'yup'

import { ContainerColor, PaymentTerms, PurchaseOrderDepartment, PurchaseOrderType } from 'api'

import { maxContactsCount, minContactsCount } from 'modules/purchaseOrders/constants/maxRecordsCount'
import {
  basePurchaseOrderValidation,
  purchaseOrderItemCreateValidation,
} from 'modules/purchaseOrders/constants/validationSchema'

export const validationSchema = Yup.object({
  ...basePurchaseOrderValidation,
  vendorId: Yup.number().required('Vendor name is required'),
  contactsIds: Yup.array()
    .of(Yup.string().required('Contact required'))
    .min(minContactsCount, `Min ${minContactsCount} contact`)
    .max(maxContactsCount, `Max ${maxContactsCount} contacts`),
  department: Yup.mixed<PurchaseOrderDepartment>()
    .oneOf(Object.values(PurchaseOrderDepartment))
    .required('Delivery type is required'),

  paymentTerms: Yup.mixed<PaymentTerms>().oneOf(Object.values(PaymentTerms)).required('Payment terms is required'),
  type: Yup.mixed<PurchaseOrderType>().oneOf(Object.values(PurchaseOrderType)).required('Delivery type is required'),
  containerColor: Yup.mixed<ContainerColor>().oneOf(Object.values(ContainerColor)).notRequired().nullable(),
  items: Yup.array().of(purchaseOrderItemCreateValidation),
})
