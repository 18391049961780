import { FC, forwardRef, ReactElement, ReactNode, Ref } from 'react'
import { ButtonProps as BaseButtonProps } from 'antd/lib/button'

import { typedMemo } from 'types'

import { ButtonSize, ButtonType } from './types'
import * as Styled from './styles'

import { Icon, IconName } from '../Icon'
import { SpacerLevel } from '../Spacer'

export interface ButtonProps extends Omit<BaseButtonProps, 'size' | 'type' | 'icon'> {
  children?: ReactNode
  iconRight?: IconName
  icon?: IconName
  size?: ButtonSize
  type?: ButtonType
  px?: SpacerLevel
  fullwidth?: boolean
  width?: number
  extraSmall?: boolean // can't add new size because of antd size type
}

export const ButtonBase: FC<ButtonProps> = forwardRef(
  (
    { children, size = 'middle', type = 'default', icon, iconRight, px, fullwidth, width, extraSmall, ...rest },
    ref: Ref<HTMLElement>,
  ): ReactElement => {
    return (
      <Styled.Button
        ref={ref}
        size={size}
        type={type}
        $px={px}
        $fullwidth={fullwidth}
        $width={width}
        $extraSmall={extraSmall}
        icon={icon && <Icon className="button-icon" size={16} icon={icon} />}
        {...rest}
      >
        {children && (
          <Styled.Wrapper>
            {children} {iconRight && <Icon className="button-icon" size={16} icon={iconRight} />}
          </Styled.Wrapper>
        )}
      </Styled.Button>
    )
  },
)

export const Button = typedMemo(ButtonBase)
