import { PurchaseOrderBillingStatus } from 'api'

import { TagType } from 'designSystem'

interface PurchaseOrderTagData {
  status: TagType
  text?: string
}

export const getTagDataByPurchaseOrderStatus = (status: PurchaseOrderBillingStatus): PurchaseOrderTagData => {
  switch (status) {
    case PurchaseOrderBillingStatus.Billed:
      return {
        status: TagType.Done,
        text: 'Billed',
      }
    case PurchaseOrderBillingStatus.NotBilled:
      return {
        status: TagType.Warning,
        text: 'Not Billed',
      }
    default:
      return {
        status: TagType.InProgress,
        text: 'Partially Billed',
      }
  }
}
