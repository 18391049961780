import { prepareVendorPayload } from 'modules/vendors/utils/prepareVendorPayload'

import { VendorDetailsFormData } from '../types/vendorDetailsForm'
import { VendorDetailsPayload } from '../types/vendorDetailsPayload'

export const prepareVendorDetailsPayload = ({
  locationCodeIds,
  ...rest
}: VendorDetailsFormData): VendorDetailsPayload => {
  const data = prepareVendorPayload(rest)

  return {
    ...data,
    ...(locationCodeIds && { locationCodeIds: locationCodeIds?.map(({ value }) => value) }),
  }
}
