import { PurchaseOrderStatus } from 'api/types/purchaseOrder'

export const purchaseOrderStatusLabel = {
  [PurchaseOrderStatus.Open]: 'Open',
  [PurchaseOrderStatus.Closed]: 'Closed',
  [PurchaseOrderStatus.Draft]: 'Draft',
  [PurchaseOrderStatus.Cancelled]: 'Cancelled',
  [PurchaseOrderStatus.Voided]: 'Voided',
}

export const purchaseOrderStatusOptions: {
  value: PurchaseOrderStatus
  label: string
}[] = [
  {
    value: PurchaseOrderStatus.Open,
    label: purchaseOrderStatusLabel[PurchaseOrderStatus.Open],
  },
  {
    value: PurchaseOrderStatus.Closed,
    label: purchaseOrderStatusLabel[PurchaseOrderStatus.Closed],
  },
  {
    value: PurchaseOrderStatus.Draft,
    label: purchaseOrderStatusLabel[PurchaseOrderStatus.Draft],
  },
  {
    value: PurchaseOrderStatus.Cancelled,
    label: purchaseOrderStatusLabel[PurchaseOrderStatus.Cancelled],
  },
  {
    value: PurchaseOrderStatus.Voided,
    label: purchaseOrderStatusLabel[PurchaseOrderStatus.Voided],
  },
]

export const getPurchaseOrderStatusLabel = (status?: PurchaseOrderStatus): string =>
  purchaseOrderStatusOptions.find(({ value }) => value === status)?.label || ''
