import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { getItems, GetItemsQueryParams } from 'api'
import { QueryKey } from 'enums'

export const useGetItems = (params: GetItemsQueryParams) => {
  const { data, ...rest } = useQuery({
    queryKey: [QueryKey.GetItems, params],
    queryFn: () => getItems(params),
    placeholderData: keepPreviousData,
  })

  const { total, data: items } = data || {}

  return {
    data: items,
    total,
    ...rest,
  }
}
