import type { ApiQueryParams, User } from 'api'
import { Pagination } from 'types'

import { api } from '../api'

export interface GetUserResponse extends Pagination {
  data?: User[]
}

export type GetUsersQueryParams = ApiQueryParams

export const getUsers = (params?: GetUsersQueryParams): Promise<GetUserResponse> => {
  return api({
    method: 'get',
    url: '/api/v1/users/',
    params,
  })
}
