import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { VendorInventory, VendorType } from 'api'
import { useForm } from 'hooks'
import { AppModule } from 'types'
import { getMarginDefaultValues } from 'utils'

import { Button, Container } from 'designSystem'
import { Table, TableFilterOptions, TableSearchSingle, TableTitle, useTableColumns } from 'components'

import { useInventory } from './hooks/useInventory'
import { useInventoryRequests } from './hooks/useInventoryRequests'
import { getTableColumns } from './utils/getTableColumns'
import { inventoryVisibleColumnsOptions } from './constants/inventoryVisibleColumns'
import { validationSchema } from './constants/validationSchema'
import { InventoryForm } from './types/inventoryCreateForm'
import { UploadCSV } from 'modules/vendors/routes/Vendor/elements/UploadCSV'

export interface InventoryProps {
  type: VendorType.ContainerVendor | VendorType.Parts
  module: AppModule.VendorContainerInventory | AppModule.VendorPartsInventory
}

export const Inventory: FC<InventoryProps> = ({ type, module }) => {
  const { id } = useParams()

  const {
    data,
    isLoading,
    pagination,
    onTableChange,
    offsetCount,
    TableContextProvider,
    // editable table
    dataSource,
    isAddNew,
    setIsAddNew,
    onAddNew,
    onCancelNew,
  } = useInventory(Number(id), type)

  const { onSubmitPost, onSubmitPatch, onDelete, isSubmitError, isEditLoading } = useInventoryRequests(
    setIsAddNew,
    data,
    type,
  )

  const { Form, handleSubmit, triggerSubmit, watch } = useForm<InventoryForm>({
    validationSchema: validationSchema(isAddNew),
    mode: 'onChange',
    isTableEditForm: true,
    isLoading,
    onSubmit: onSubmitPatch,
    isSubmitError,
    defaultValues: {
      list: data.map((item) => ({
        ...item,
        resaleMargin: getMarginDefaultValues(item?.resaleMargin),
      })),
    },
  })

  const addNewValues = watch('item')
  const allColumns = getTableColumns({ offsetCount, isAddNew, onCancelNew, onDelete, triggerSubmit, addNewValues })
  const { visibleColumns, setVisibleColumns, columns } = useTableColumns<Partial<VendorInventory>>({
    module,
    allColumns,
    withActions: true,
  })

  return (
    <Container pa={4} fd="column" gap={16}>
      <TableContextProvider>
        <TableTitle
          withoutDefaultPadding
          title="Inventory"
          total={pagination?.total}
          search={<TableSearchSingle searchFieldName={['title', 'sku']} />}
          columns={
            <TableFilterOptions
              title="Columns"
              icon="columns"
              options={inventoryVisibleColumnsOptions}
              value={visibleColumns}
              onChange={setVisibleColumns}
            />
          }
        >
          <UploadCSV vendorId={Number(id)} vendorType={type} />
          <Button icon="plus" type="primary" size="small" onClick={onAddNew} disabled={isAddNew}>
            Add
          </Button>
        </TableTitle>

        <Form onSubmit={handleSubmit(onSubmitPost)}>
          <Table<Partial<VendorInventory>>
            scroll={{ x: 1000 }}
            dataSource={dataSource}
            columns={columns}
            viewOnlyExceptFirstRow={isAddNew}
            loading={isLoading || isEditLoading}
            pagination={pagination}
            onChange={onTableChange}
            isEditLoading={isEditLoading}
            editableTable
          />
        </Form>
      </TableContextProvider>
    </Container>
  )
}
