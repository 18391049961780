import { FC, FocusEvent, forwardRef, Ref, useCallback } from 'react'
import { RefSelectProps } from 'antd/lib/select'

import { useDebouncedValue } from 'hooks/useDebouncedValue'
import { AsyncSelectGetItemsProps, typedMemo } from 'types'

import { SelectMulti, SelectMultiProps } from 'designSystem/components/SelectMulti'

import { SelectSingleOption } from '../SelectSingle'

export interface SelectMultiAsyncProps extends Omit<SelectMultiProps, 'mode'>, Omit<AsyncSelectGetItemsProps, 'id'> {
  name: string
  id?: string
  getItems: (props: AsyncSelectGetItemsProps) => any
  onFocusChange?: (focused: boolean) => void
}

const SelectMultiAsyncBase: FC<SelectMultiAsyncProps> = forwardRef(
  (
    {
      name,
      getItems,
      showSearch = false,
      id,
      onChange,
      onBlur,
      onFocus,
      onFocusChange,
      valuePropName,
      width = 160,
      fullwidth,
      queryParams,
      fields,
      join,
      ...props
    },
    ref: Ref<RefSelectProps>,
  ) => {
    const {
      options,
      isLoading,
      onChangeEnabled,
      search: searchValue,
      onSearch,
      onPopupScrollEnd,
    } = getItems({ id: id || name, valuePropName, queryParams, fields, join })

    const { value: search, handleChange: handleChangeDebouncedSearch } = useDebouncedValue(searchValue, onSearch)

    const handleFocus = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        onFocus?.(event)
        onFocusChange?.(true)
      },
      [onFocus, onFocusChange],
    )

    const handleChange = useCallback(
      (value?: (string | number)[], options?: SelectSingleOption[]) => {
        if (Array.isArray(options)) {
          onChange?.(value || [], options)
        }
      },
      [onChange],
    )

    // on blur we shouldn't debounce setting search
    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        onBlur?.(event)
        onFocusChange?.(false)
      },
      [onBlur, onFocusChange],
    )

    const handleSearch = useCallback(
      (search: string) => {
        if (onSearch) {
          handleChangeDebouncedSearch?.(search)
        }
      },
      [onSearch, handleChangeDebouncedSearch],
    )

    return (
      <SelectMulti
        ref={ref}
        options={options}
        onBlur={handleBlur}
        showSearch={showSearch}
        onSearch={showSearch ? handleSearch : undefined}
        onChange={handleChange}
        width={fullwidth ? undefined : width}
        allowClear
        onFocus={handleFocus}
        loading={isLoading}
        searchValue={search}
        onFocusChange={onChangeEnabled}
        onPopupScrollEnd={onPopupScrollEnd}
        {...props}
      />
    )
  },
)

export const SelectMultiAsync = typedMemo(SelectMultiAsyncBase)
