import { FC, useCallback } from 'react'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'

import { VendorCarrierSettingsType } from 'api'
import { useFormContext, useMemoCompare, useWatch } from 'hooks'
import {
  vendorCarrierFlatBetOptions,
  vendorCarrierFlatBetOptionsLabels,
  vendorCarrierTiltBetOptions,
  vendorCarrierTiltBetOptionsLabels,
} from 'constant'

import { Container, FormItem, Typography } from 'designSystem'

import { InfoGroup } from './elements/InfoGroup'
import * as Styled from './styles'

interface CarrierInfoProps {
  name: string
  isEdit?: boolean
}

export const CarrierInfo: FC<CarrierInfoProps> = ({ name, isEdit }) => {
  const { setValue, triggerSubmit } = useFormContext()
  const type = useWatch({ name }) || []

  const memoType = useMemoCompare(type)

  const handleChangeCheckboxGroup = useCallback(
    (values: CheckboxValueType[]) => {
      if (memoType.includes(VendorCarrierSettingsType.TiltBet) && !values.includes(VendorCarrierSettingsType.TiltBet)) {
        setValue(
          name,
          values.filter((value) => Object.keys(vendorCarrierFlatBetOptionsLabels).includes(value as any)),
        )
      } else if (
        memoType.includes(VendorCarrierSettingsType.FlatBet) &&
        !values.includes(VendorCarrierSettingsType.FlatBet)
      ) {
        setValue(
          name,
          values.filter((value) => Object.keys(vendorCarrierTiltBetOptionsLabels).includes(value as any)),
        )
      } else {
        setValue(name, values)
      }

      if (isEdit) {
        triggerSubmit?.()
      }
    },
    [setValue, memoType, name, triggerSubmit, isEdit],
  )

  return (
    <>
      <Typography fontWeight="xl" size="s">
        Info
      </Typography>
      <FormItem disableOnChange name={name}>
        <Styled.CheckboxGroup onChange={handleChangeCheckboxGroup}>
          <Container jc="flex-start" width="100%" gap={12}>
            <InfoGroup
              options={vendorCarrierTiltBetOptions}
              disabled={!memoType.includes(VendorCarrierSettingsType.TiltBet)}
              mainOption={VendorCarrierSettingsType.TiltBet}
              withDivider
            />
            <InfoGroup
              options={vendorCarrierFlatBetOptions}
              disabled={!memoType.includes(VendorCarrierSettingsType.FlatBet)}
              mainOption={VendorCarrierSettingsType.FlatBet}
            />
          </Container>
        </Styled.CheckboxGroup>
      </FormItem>
    </>
  )
}
