import { FC, ReactNode } from 'react'
import { Spin } from 'antd'

import { typedMemo } from 'types'

import { Container } from '../../../Container'
import { Divider } from '../../../Divider'
import { CreateNew } from '../CreateNew'
import { SelectSpinner } from '../SelectSpinner'

interface SelectDropdownBaseProps {
  menu: ReactNode
  withCreateNew?: boolean
  onClickCreateNew?: () => void
  isLoading?: boolean
}

const SelectDropdownBase: FC<SelectDropdownBaseProps> = ({ menu, isLoading, withCreateNew, onClickCreateNew }) => {
  return (
    <Container fd="column">
      <Spin
        spinning={!!isLoading}
        indicator={
          <div>
            <SelectSpinner />
          </div>
        }
      >
        {menu}
      </Spin>
      {withCreateNew && (
        <Container py={2} fd="column" bg="white">
          <Divider mb={3} />
          <CreateNew onClickCreateNew={onClickCreateNew} />
        </Container>
      )}
    </Container>
  )
}

export const SelectDropdown = typedMemo(SelectDropdownBase)
