import { createContext, useContext } from 'react'

import { TableFilter, TableFilterValue } from '../types/tableFilter'
import { TableSearch } from '../types/tableSearch'

export interface TableContextValue {
  filters?: TableFilter
  updateFilters?: (filters: TableFilter) => void
  clearFilters?: () => void
  search?: TableSearch
  setSearch?: (search?: TableSearch) => void
  getFilterValue?: (name: string) => TableFilterValue
  getIsFilterApplied?: () => boolean
  applyFilters?: () => void
  resetDefaultFilters?: () => void
  hasFiltersChanged?: () => boolean
}

const defaultTableValue: TableContextValue = { filters: undefined, updateFilters: undefined }

export const TableContext = createContext(defaultTableValue)

export const useTableContextValue = () => useContext(TableContext)
