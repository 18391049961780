import { Link as BaseLink } from 'react-router-dom'
import { styled } from 'styled-components'

import { Card as BaseCard } from 'components'

export const Link = styled(BaseLink)`
  width: fit-content;
`

export const Card = styled(BaseCard)`
  padding: 12px ${({ theme }) => theme.spacers[4]};
  background: ${({ theme }) => theme.colors.accent[80]};
  border-color: ${({ theme }) => theme.colors.accent[300]};
`

export const IconWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacers[3]};
  min-width: ${({ theme }) => theme.spacers[10]};
  height: ${({ theme }) => theme.spacers[10]};
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.accent[400]};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
`
