import { Col, Row } from 'antd'

import { SystemRoles } from 'api'
import { useUsers } from 'hooks'
import { salesOrderLabels } from 'constant'
import { SalesOrderFormAccessor } from 'types'

import { Container, FormItem, Input } from 'designSystem'
import { FormItemSelectSingleAsync } from 'components'

const useUsersFields = 'id,firstName,lastName,roles,roles.systemRole'
const useUsersJoin = ['roles']

export const InvoiceInformation = () => {
  return (
    <Container fd="column" width="100%" gap={8}>
      <Row>
        <Col span={24}>
          <FormItem name={SalesOrderFormAccessor.QuoteUrl} label={salesOrderLabels.quoteUrl}>
            <Input placeholder="https://" />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItemSelectSingleAsync
            name={SalesOrderFormAccessor.SalesRepresentativeId}
            label={salesOrderLabels.salesRepresentativeId}
            getItems={useUsers}
            queryParams={{ 'roles.systemRole': { $eq: SystemRoles.SalesRepresentative } }}
            join={useUsersJoin}
            fields={useUsersFields}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItemSelectSingleAsync
            name={SalesOrderFormAccessor.ProcurementId}
            label={salesOrderLabels.procurementId}
            getItems={useUsers}
            queryParams={{ 'roles.systemRole': { $eq: SystemRoles.Procurement } }}
            join={useUsersJoin}
            fields={useUsersFields}
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <FormItemSelectSingleAsync
            name={SalesOrderFormAccessor.DispatcherId}
            label={salesOrderLabels.dispatcherId}
            getItems={useUsers}
            queryParams={{ 'roles.systemRole': { $eq: SystemRoles.Dispatcher } }}
            join={useUsersJoin}
            fields={useUsersFields}
          />
        </Col>
      </Row>
    </Container>
  )
}
