import { api, EntityId, ItemCategory } from 'api'

import { CategoryPayload } from './types/categoryPayload'

export interface PatchCategoryItemPayload extends CategoryPayload {
  id: EntityId
}

export const postCategoryItem = (data: CategoryPayload): Promise<ItemCategory> => {
  return api({
    method: 'post',
    url: `/api/v1/items/categories`,
    data,
  })
}

export const patchCategoryItem = ({ id, ...data }: PatchCategoryItemPayload): Promise<ItemCategory> => {
  return api({
    method: 'patch',
    url: `/api/v1/items/categories/${id}`,
    data,
  })
}
