import { FC } from 'react'

import { SalesOrder } from 'api'
import { route } from 'constant'

// import { route } from 'constant'
import { Table, TableSearchSingle, TableTitle } from 'components'

import { useSalesOrders } from './hooks/useSalesOrders'
import { getTableColumns } from './utils/getTableColumns'
import { Accessor } from './types/accessor'

export const SalesOrdersList: FC = () => {
  const { data, isLoading, pagination, onTableChange, getFieldSorter, offsetCount, TableContextProvider } =
    useSalesOrders()

  const allColumns = getTableColumns(getFieldSorter, offsetCount)

  const salesOrdersList: SalesOrder[] = data.map(({ id, ...rest }) => ({
    key: id.toString(),
    id,
    ...rest,
  }))

  return (
    <TableContextProvider>
      <TableTitle
        title="Sales Orders"
        total={pagination?.total}
        search={<TableSearchSingle searchFieldName={Accessor.Number} />}
        addRoute={route.salesOrdersCreate}
      />
      <Table<SalesOrder>
        withBorderRadius={false}
        scroll={{ x: 750 }}
        dataSource={salesOrdersList}
        loading={isLoading}
        columns={allColumns}
        pagination={pagination}
        onChange={onTableChange}
      />
    </TableContextProvider>
  )
}
