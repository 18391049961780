import { FC } from 'react'

import { User } from 'api'
import { route } from 'constant'
import { AppModule } from 'types'
import { useRoles } from 'store'

import { Container } from 'designSystem'
import { Table, TableFilterDrawer, TableTitle } from 'components'

import { useUsers } from './hooks/useUsers'
import { getTableColumns } from './utils/getTableColumns'
import { accessManagementFilterOptions } from './constants/accessManagementFilterOptions'
import { ListSearch } from './elements/ListSearch'

export const UsersList: FC = () => {
  const roles = useRoles()
  const {
    data: users,
    isLoading,
    pagination,
    onTableChange,
    getFieldSorter,
    offsetCount,
    TableContextProvider,
    filterOptions,
    setFilterOptions,
  } = useUsers()
  const columns = getTableColumns(getFieldSorter, offsetCount)

  const usersList: User[] = users.map(({ id, ...rest }) => ({
    key: id.toString(),
    id,
    ...rest,
  }))

  return (
    <TableContextProvider>
      <Container pa={4} display="flex" fd="column" gap={16}>
        <TableTitle
          withoutDefaultPadding
          title="User Management"
          total={pagination?.total}
          addRoute={route.userManagementCreate}
          search={<ListSearch />}
          filter={
            <TableFilterDrawer
              setFilterOptions={setFilterOptions}
              filterOptions={filterOptions}
              options={accessManagementFilterOptions(roles)}
              module={AppModule.AccessManagement}
            />
          }
        />
        <Table<User>
          scroll={{ x: 1000 }}
          dataSource={usersList}
          loading={isLoading}
          columns={columns}
          pagination={pagination}
          onChange={onTableChange}
        />
      </Container>
    </TableContextProvider>
  )
}
