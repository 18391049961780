import { FC } from 'react'

import { useFormContext } from 'hooks'

import { Typography, TypographyProps } from 'designSystem'

interface CityStateViewProps extends Omit<TypographyProps, 'children'> {
  name: string
}

export const CityStateView: FC<CityStateViewProps> = ({ name, ...props }) => {
  const { watch } = useFormContext()
  const [city, state] = watch([`${name}.city`, `${name}.state`])

  if (!city && !state) {
    return null
  }

  const cityState = Array.from(new Set([city, state]))
    .filter(Boolean)
    .join(', ')

  return (
    <Typography nowrap {...props}>
      [{cityState}]
    </Typography>
  )
}
