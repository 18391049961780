import { ReferenceNumberType } from 'api'
import { getReferenceTypeOptionLabel } from 'constant'

export const referenceTypesData = [
  {
    type: ReferenceNumberType.Booking,
    label: getReferenceTypeOptionLabel(ReferenceNumberType.Booking),
  },
  {
    type: ReferenceNumberType.Redelivery,
    label: getReferenceTypeOptionLabel(ReferenceNumberType.Redelivery),
  },
]
