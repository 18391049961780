import { FC } from 'react'

import { CustomerStatus } from 'api'

import { Tag, TagProps } from 'designSystem'

import { getTagDataByCustomerStatus } from './utils/getTagDataByCustomerStatus'

interface CustomerStatusTagProps extends Omit<TagProps, 'status'> {
  status: CustomerStatus
}

export const CustomerStatusTag: FC<CustomerStatusTagProps> = ({ status: customerStatus, ...props }) => {
  const { status, text } = getTagDataByCustomerStatus(customerStatus)
  return <Tag {...props} status={status} text={text} />
}
